/**
* Component for displaying customer profile.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles,MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import PageTitle from '../../../../app/components/common/PageTitle';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import UserMessage from '../../../../app/components/messages/UserMessages';
import Person from '@material-ui/icons/Person';
import Fab from '@material-ui/core/Fab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//Redux related imports
import { connect } from 'react-redux';
import AccountService from '../../../../app/modules/account/AccountService';
import getConfigValue from '../../../../app/framework/config/configHandler';
import GetLanguage from '../../../../library/GetLanguage'
import { Redirect } from 'react-router';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
    emailStyle: {
        backgroundColor: '#F7FAFB',
        borderRadius: 3,
        borderColor: '#A8A8A8',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    emailIconStyle: {
        marginRight: '0.625rem',
        paddingLeft: '0.875rem',
        textAlign: 'center'
    },
    verticalAlign: {
        horizontalAlign: 'center',
    },
    emailDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    emailText: {
        textAlign: 'center'
    },
    spaceChangePassword: {
        marginTop: '3.125rem',
    },
    headerFont: {
        fontSize: '1rem',
        fontWeight: '600'
    },
    chagepasswordwrapper:{
        width:"100%"
    },
    pageHeading:{
        color:'#1d2d5f',
        margin:'10px 0 0 0',
    },
    headerStyle:{
        background:'none !important',
    },
    accountHeading:{
        textTransform:'uppercase',
        margin:'10px 0 25px 0',
        padding:'10px 0',
        borderBottom:'1px solid #ccc',
    },
    submitBtn : {
        background:'#f65e5d',
        color:'#fff',
        boxShadow:'none',
        marginTop:'15px',
    },
});

const theme = createMuiTheme({
    overrides: {
        MuiFormControl:{
            marginNormal:{
                margin:'0px !important',
            }
        },
        MuiInput: {
            root: {
                border:'1px solid #ebebeb',
                padding:'5px 10px',
            },
            underline: {
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: 'none',
                },
                '&:after': {
                    borderBottom: 'none',
                },
                '&:before': {
                    borderBottom: 'none',
                },
            }
        },
    }
  });
/***************************************************************
 * Component class for displaying My profile page.
 * @author   Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        const accountParameters = getConfigValue("account");
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            currentPassword: "",
            newPassword: "",
            retypePassword: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            logoutOnPasswordChange: accountParameters.customer.logoutOnPasswordChange,
            isConfirmPassword:true,
             profiledetailsUpdated:true,
             //  passwordValidation:{
            upperCase: false,
            lowerCase: false,
            specialCharacter: false,
            number: false,
            length: false,
            resetClicked: false,
        }
    }

    componentDidMount() {
        /***************************************************************************** 
         * Check if the user is authenticated. 
         * If yes, display my profile page.
         * If not, redirect to Sign-In page and once
         * signed-in, redirect back to this my profile
         * page.
         *****************************************************************************/
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        }
        //Call API to get customer data
        if (typeof (this.props.get_user_details) === 'undefined' || this.props.get_user_details.length === 0) {
            this.props.getUserDetails();
        } else {
            this.setState({
                firstName: this.props.get_user_details.firstname,
                lastName: this.props.get_user_details.lastname,
                email: this.props.get_user_details.email,
            });
        }
    }

    handleChange = name => event => {
        var regexUpper = /^.*[A-Z].*/;
        var regexlower = /^.*[a-z].*/;
        var regexNumber = /^.*[1-9].*/;
        var specialCharacter = /^.*[^`!@#$%^&*\-_=+'\/.,]*[`!@#$%^&*\-_=+'\/.,].*/;
        //var specialCharacter=/^.*[$&+,:;=?@#|'<>.-^*()%!].*/;
        var regexLength = /^.{8,}$/;

        var info = event.target.value.match(regexUpper);

        if (event.target.value.match(regexUpper) === null) {
            this.setState({ upperCase: true });
        }
        else {
            this.setState({ upperCase: false });
        }
        if (event.target.value.match(regexlower) === null) {
            this.setState({ lowerCase: true });
        }
        else { this.setState({ lowerCase: false }); }
        if (event.target.value.match(regexNumber) === null) {
            this.setState({ number: true });
        }
        else { this.setState({ number: false }); }
        if (event.target.value.match(specialCharacter) === null) {
            this.setState({ specialCharacter: true });
        }
        else { this.setState({ specialCharacter: false }); }
        if (event.target.value.match(regexLength) === null) {
            this.setState({ length: true });
        }
        else { this.setState({ length: false }); }

        this.setState({ resetClicked: false });

        this.setState({ [name]: event.target.value });
    };

    handleNameChange = (event) => {
        event.preventDefault();
        //Dispatch action to change name.
        const {
            firstName,
            lastName,
            email
        } = this.state;
        if (
            firstName !== "" &&
            lastName !== "" &&
            email !== ""
        ) {
            //dispatch to save name
            this.props.onSubmitSaveNameDispatch(firstName, lastName, email);
        }
    };

    //call dispatch function if all fields are not empty
    handleSavePassword = (event) => {
        event.preventDefault();
        const {
            currentPassword,
            newPassword,
            retypePassword,
        } = this.state;

        if (currentPassword !== "" && newPassword !== "" && retypePassword !== "") {
            if (!this.state.upperCase && !this.state.lowerCase && !this.state.specialCharacter && !this.state.number && !this.state.length) {
                if (newPassword !== retypePassword) {
                    this.setState({ isConfirmPassword: false });

                }
                else {
                    this.setState({ isConfirmPassword: true });
                    var passwordObject = {
                        "currentPassword": currentPassword,
                        "newPassword": retypePassword
                    };
                    //dispatch to change password
                    this.props.onSubmitChangePassword(passwordObject);
                }
            }
        }
        this.setState({ resetClicked: true });
    }

    resetFormFields = () => {
        this.setState({ currentPassword: '', newPassword: '', retypePassword: '' });
    }

    componentDidUpdate(prevProps) {
        if (this.props.get_user_details !== prevProps.get_user_details) {
            this.setState({
                firstName: this.props.get_user_details.firstname,
                lastName: this.props.get_user_details.lastname,
                email: this.props.get_user_details.email,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        //if password change is sucessfull then signout and clear user data
        if (nextProps.changePasswordSuccess === true && this.state.logoutOnPasswordChange) {
            //reset signed in state
            this.props.resetSignedInUserSuccess();
            //Clear browser storage on User SignOut.
            AccountService.clearOnSignOut();
            //redirect to signin form
            this.props.history.push({
                pathname: '/account',
                state: { changePasswordSuccess: true }
            });
        }
        //reset password form only when there is a edit
        //done or api call processed
        if (nextProps.changePasswordSuccess !== null) {
            this.resetFormFields();
        }
    }

    //hide/show text current password
    handleClickShowCurrentPassword = () => {
        this.setState(state => ({ showCurrentPassword: !state.showCurrentPassword }));
    };
    //hide/show text new password
    handleClickShowNewPassword = () => {
        this.setState(state => ({ showNewPassword: !state.showNewPassword }));
    };
    //hide/show text confirm password
    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };

    render() {
        const { classes } = this.props;
        const { isSalesmanUser } = this.props;
        const { get_user_details, customerNameChangeStatus } = this.props;
        const { changePasswordSuccess, changePasswordError, isChangePwdButtonDisabled,profileNameUpdateStatus } = this.props;
  
        if(profileNameUpdateStatus)
        {
           
           this.props.setSubMenu(0);
          // this.setState({profiledetailsUpdated:false})

           this.props.resetNameChange();
            // this.props.history.push({
            //     pathname: '/myaccount',
            //      state: { submenu: 0 },
            // });
           //  window.location.reload();
           return <Redirect to={{ pathname: '/myaccount', state: { submenu: 0 } }} />;
        }

        if (get_user_details === []) return null;
        if (get_user_details === {}) return null;
        if (typeof (get_user_details) === "undefined") return null;
        var profileNameChangeMessage;
        //If profile name is changed successfully
        if (customerNameChangeStatus) {
            const object = { messageType: 'sucess', message: "Customer profile name change is saved" }
            profileNameChangeMessage = <UserMessage {...object} />
        }
        //If User Sign In is not success, stay in same page
        if (customerNameChangeStatus === false) {
            const object = { messageType: 'error', message: this.props.customerNameChangeError }
            profileNameChangeMessage = <UserMessage {...object} />
        }
        var changePasswordStatus;
        //If change password is not success
        if (changePasswordSuccess === false) {
            const object = { messageType: 'error', message: changePasswordError }
            changePasswordStatus = <UserMessage {...object} />
        }
        else if (changePasswordSuccess === true && !this.state.logoutOnPasswordChange) {
            const object = { messageType: 'sucess', message: 'Password updated successfully', autoHideDuration: 10000 }
            changePasswordStatus = <UserMessage {...object} />
        }
        return (
            <React.Fragment>
                <Grid item xs={12} classes={classes.headerStyle}>
                <Typography variant="h6" align="left" className={classes.pageHeading}>
                <GetLanguage value='Account Information'/>
                </Typography>
                </Grid>
                <Grid container>
                <MuiThemeProvider theme={theme}>
                    <Grid md={6}>
                        <Grid item xs={10}>
                        <Typography gutterBottom className={classes.accountHeading}>{<GetLanguage value="Account Information" />}</Typography>               
                        <Grid item>
                        {profileNameChangeMessage}
                         {/* <Card className={classes.cardStyle}>
                            <CardContent> */}
                                <form onSubmit={this.handleNameChange}>
                                    <Grid container spacing={8} direction="column">
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-firstName">{<GetLanguage value='First Name'/>}</InputLabel>
                                                <Input id="adornment-firstName" type='text' value={this.state.firstName}
                                                    onChange={this.handleChange('firstName')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton disableRipple style={{ cursor: 'default' }}><Person /></IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-lastName">{<GetLanguage value='Last Name'/>}</InputLabel>
                                                <Input id="adornment-lastName" type='text' value={this.state.lastName}
                                                    onChange={this.handleChange('lastName')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton disableRipple style={{ cursor: 'default' }}><Person /></IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-email">Email</InputLabel>
                                                <Input id="adornment-email" type='text' value={this.state.email} readOnly={true}
                                                    onChange={this.handleChange('email')} disableUnderline={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><Email /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid> */}
                                        <Grid item md={5}>
                                            <FormControl required fullWidth>
                                                <Fab size="large" aria-label="Save name" variant="extended" type="submit"
                                                  className={classes.submitBtn}  disabled={isSalesmanUser}>{<GetLanguage value="Save name"/>}</Fab>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            {/* </CardContent>
                        </Card> */}
                    </Grid>
                        </Grid>
                    </Grid>
                    <Grid md={6}>
                        <Grid item xs={10}>
                        <Typography gutterBottom className={classes.accountHeading}>{<GetLanguage value="Change Password" />}</Typography>               
                        <Grid item>
                        {changePasswordStatus}
                         {/* <Card className={classes.cardStyle}>
                            <CardContent> */}
                                <form onSubmit={this.handleSavePassword}>
                                    <Grid container spacing={8} direction="column">
                                        <Grid item className={classes.chagepasswordwrapper}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-currentPassword">{<GetLanguage value="Current Password"/>}</InputLabel>
                                                <Input id="adornment-currentPassword" type={this.state.showCurrentPassword ? 'text' : 'password'} value={this.state.currentPassword}
                                                    onChange={this.handleChange('currentPassword')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowCurrentPassword}>
                                                    //             {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                    //         </IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.chagepasswordwrapper}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-newPassword">{<GetLanguage value="New Password"/>}</InputLabel>
                                                <Input id="adornment-newPassword" type={this.state.showNewPassword ? 'text' : 'password'} value={this.state.newPassword}
                                                    onChange={this.handleChange('newPassword')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowNewPassword}>
                                                    //             {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                    //         </IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.state.upperCase && this.state.resetClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    {<GetLanguage value="1 Uppercase is required" />}
                                                </Typography>
                                            </Grid>
                                        }
                                        {this.state.lowerCase && this.state.resetClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    {<GetLanguage value="1 Lowercase is required" />}
                                                </Typography>
                                            </Grid>
                                        }
                                        {this.state.number && this.state.resetClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    {<GetLanguage value="1 Number is required" />}
                                                </Typography>
                                            </Grid>
                                        }
                                        {this.state.specialCharacter && this.state.resetClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    {<GetLanguage value="1 Special character is required" />}
                                                </Typography>
                                            </Grid>
                                        }
                                        {this.state.length && this.state.resetClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    {<GetLanguage value="Password is minimum of 8 characters" />}
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item className={classes.chagepasswordwrapper}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-retypePassword">{<GetLanguage value="Retype Password"/>}</InputLabel>
                                                <Input id="adornment-retypePassword" type={this.state.showConfirmPassword ? 'text' : 'password'} value={this.state.retypePassword}
                                                    onChange={this.handleChange('retypePassword')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowConfirmPassword}>
                                                    //             {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    //         </IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {!(this.state.isConfirmPassword) &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    New password and Retyped password must match
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item md={5}>
                                            <FormControl required fullWidth>
                                            <Fab className={classes.submitBtn} size="large" aria-label="Save password" variant="extended" type="submit"
                                                disabled={isSalesmanUser}>{<GetLanguage value="Save Password"/>}</Fab>
                                                </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            {/* </CardContent>
                        </Card> */}
                    </Grid>
                        </Grid>
                    </Grid>
                    </MuiThemeProvider>
                </Grid>
            </React.Fragment >
        );
    }
    componentWillUnmount() {
        /*********************************************************************************************
         *Reset the customerNameChangeStatus to null so that the success message is displayed properly. 
         *********************************************************************************************/
        this.props.resetPutNameChange();
        /********************************************************************************************* 
         *Reset the changePasswordStatus to null so that the success message is displayed properly. 
         *********************************************************************************************/
        this.props.resetPutPasswordChange();
    }
}

const mapStateToProps = state => ({
    get_user_details: state.get_user_details_state,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
    customerNameChangeStatus: state.signedInUserNameChangeState.profileNameChangeStatus,
    customerNameChangeError: state.signedInUserNameChangeState.errorMessage ? state.signedInUserNameChangeState.errorMessage : '',
    changePasswordSuccess: state.put_change_password_state.changePasswordSuccess,
    changePasswordError: state.put_change_password_state.errorMessage ? state.put_change_password_state.errorMessage : '',
    profileNameUpdateStatus:state.get_updated_user_details_state.profileNameUpdateStatus,
});


const mapDispatchToProps = dispatch => ({
    getUserDetails: () => {
        const as = new AccountService();
        dispatch(as.GetCustomerDetailsActionCreator());
    },
    onSubmitSaveNameDispatch: (firstName, lastName, email) => {
        const systemParameters = getConfigValue("system");
        let customerProfileObject = {
            "customer": {
                "email": email,
                "firstname": firstName,
                "lastname": lastName,
                "store_id": systemParameters.store.id,
                "website_id": systemParameters.store.websiteId
            }
        };
        let accountService = new AccountService();
        dispatch(accountService.updateSignedInUserProfileActionCreator(customerProfileObject));
    },

    resetNameChange: () => {
        let accountService = new AccountService();
        dispatch(accountService.resetNameChangeActionCreator());
    },
    resetPutNameChange: () => {
        let accountService = new AccountService();
        dispatch(accountService.resetPutNameChangeActionCreator());
    },
    onSubmitChangePassword: (passwordObject) => {
        let accountService = new AccountService();
        dispatch(accountService.changePasswordActionCreator(passwordObject));
    },
    resetPutPasswordChange: () => {
        let accountService = new AccountService();
        dispatch(accountService.resetPutPasswordChangeActionCreator());
    },
    resetSignedInUserSuccess: () => {
        let accountService = new AccountService();
        dispatch(accountService.resetSignedInActionCreator());
    },
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    },
});

MyProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

const MyProfileWithStyles = withStyles(styles)(MyProfile);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfileWithStyles));