/**
* Address Form Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Ruchi Verma <ruchi.verma@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PageTitle from '../../../app/components/common/PageTitle';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import UserMessage from '../../../app/components/messages/UserMessages';
import CheckoutService from '../../../app/modules/checkout/CheckoutService';
import GetLanguage from '../../../library/GetLanguage';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
});

/***************************************************************
 * Component class for address form
 * @author   Ruchi Verma <ruchi.verma@embitel.com>
 ***************************************************************/
class AddressForm extends React.Component {

    constructor(props) {
        super(props);
        var editaddressItem = this.props.location.state.editaddressItem;
        if (editaddressItem === undefined) {
            this.state = {
                firstName: "",
                lastName: "",
                company: "",
                postcode: "",
                street: "",
                city: "",
                telephone: "",
                region: "",
                country: "",
                statesInSelectedCountry: [],
                shippingAddressObject: this.props.shippingaddress,
                isAddressSaved:false,
                isEdit:false,
            };
        }
        else {
            this.state = {
                id: editaddressItem.id,
                firstName: editaddressItem.firstname,
                lastName: editaddressItem.lastname,
                company: editaddressItem.company,
                postcode: editaddressItem.postcode,
                street: editaddressItem.street[0],
                city: editaddressItem.city,
                telephone: editaddressItem.telephone,
                country: editaddressItem.country_id,
                region: editaddressItem.region.region_id,
                statesInSelectedCountry: [],
                shippingAddressObject: this.props.shippingaddress,
                isAddressSaved:false,
                isEdit:true,
            };
        }
    }

    componentDidMount() {
        this.props.getCountriesList();
        this.props.getShippingAddress();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.coutrieslist !== nextProps.coutrieslist) {
            nextProps.coutrieslist.forEach((countryItem) => {
                if (countryItem.id === this.state.country)
                    this.setState({ statesInSelectedCountry: countryItem.available_regions })
            })
        }
    }

    handleChange = name => event => {
        if (name === 'country') {
            this.props.coutrieslist.forEach((countryItem) => {
                if (countryItem.id === event.target.value)
                    this.setState({ statesInSelectedCountry: countryItem.available_regions })
            })
        }
        this.setState({ [name]: event.target.value });
    };

    //call dispatch function if all fields are not empty
    handleAddAddress = (event) => {
        event.preventDefault();
        //Dispatch action to Add Address of the user.
        const {
            firstName,
            lastName,
            company,
            postcode,
            street,
            city,
            telephone,
            region,
            country,
        } = this.state;

        var shippingaddress = this.props.shippingaddress;
        if (shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) &&
            (shippingaddress.length !== 0)) {
            let addressObject = {};
            let addressesItem = {};
            if (this.state.id !== undefined) {
                addressesItem.id = this.state.id;
            }
            else {
                addressesItem.id = shippingaddress.addresses.length + 1;
            }
            addressesItem.customer_id = shippingaddress.id;
            addressesItem.firstname = firstName;
            addressesItem.lastname = lastName;
            addressesItem.company = company;
            addressesItem.postcode = postcode;
            addressesItem.city = city;
            addressesItem.telephone = telephone;
            addressesItem.street = new Array(street);
            addressesItem.country_id = country;
            addressesItem.region_id = parseInt(region, 10);
            addressObject.addresses = addressesItem;
            //dispatch save address
            this.props.onSubmitAddAddressDispatch(addressObject);
            this.setState({isAddressSaved:true});
        }
    };

    render() {
        const { classes } = this.props;
        const { coutrieslist, addressAdded } = this.props;
        const { redirectTo } = this.props.location.state;
        var addressAddError;

        if (addressAdded.addressSaved === true && this.state.isAddressSaved === true) {

            return <Redirect push to={{pathname:redirectTo,state: { addressSavedStatus:true }}} />;
        }
        if(addressAdded.addressSaved === false && this.props.addressAddError !== undefined)
        {
            const object = { messageType: 'error', message: this.props.addressAddError }
            addressAddError = <UserMessage {...object} />
        }
        return (
            <React.Fragment>
                {this.state.isEdit ? <PageTitle title={<GetLanguage value="Edit Address"/>} /> :<PageTitle title={<GetLanguage value="Add New Address"/>}/>} 
                {addressAddError}
                <Grid container alignItems="center" justify="center">
                    <Grid item xs sm={8} md={5} lg={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleAddAddress}>
                                    <Grid container spacing={16} direction="column">
                                          <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="firstName">{<GetLanguage value="First Name"/>}</InputLabel>
                                                <Input id="firstName" type='text' value={this.state.firstName}
                                                    onChange={this.handleChange('firstName')}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="lastName">{<GetLanguage value="Last Name"/>}</InputLabel>
                                                <Input id="lastName" type='text' value={this.state.lastName}
                                                    onChange={this.handleChange('lastName')}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="company">{<GetLanguage value="Company"/>}</InputLabel>
                                                <Input id="company" type='text' value={this.state.company}
                                                    onChange={this.handleChange('company')}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <TextField id="street-address" label="Street Address" margin="normal" value={this.state.street}
                                                    required multiline onChange={this.handleChange('street')}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="City">{<GetLanguage value="City"/>}</InputLabel>
                                                <Input id="City" type='text' value={this.state.city}
                                                    onChange={this.handleChange('city')}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl required fullWidth>
                                                <InputLabel htmlFor="country">{<GetLanguage value="Country"/>}</InputLabel>
                                                <Select
                                                    value={this.state.country}
                                                    onChange={this.handleChange('country')}
                                                    name="country"
                                                    inputProps={{
                                                        id: 'country',
                                                    }}
                                                >
                                                    <MenuItem value="">{<GetLanguage value="Country"/>}</MenuItem>
                                                    {coutrieslist && coutrieslist.map((countryItem, index) => {
                                                        return (
                                                            <MenuItem key={countryItem.id} value={countryItem.id}>{countryItem.full_name_english}</MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl required fullWidth>
                                                <InputLabel htmlFor="state">{<GetLanguage value="State/Province"/>}</InputLabel>
                                                <Select
                                                    value={this.state.region}
                                                    text="Karnataka"
                                                    onChange={this.handleChange('region')}
                                                    inputProps={{
                                                        name: 'region',
                                                        id: 'state',
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em>{<GetLanguage value="State/Province"/>}</em>
                                                    </MenuItem>
                                                    {this.state.statesInSelectedCountry.map((item, index) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                        );
                                                    })
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                          <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="postcode">{<GetLanguage value="Zip/Postal Address"/>}</InputLabel>
                                                <Input id="postcode" type='text' value={this.state.postcode}
                                                    onChange={this.handleChange('postcode')}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="telephone">{<GetLanguage value="Phone Number"/>}</InputLabel>
                                                <Input id="telephone" type='text' value={this.state.telephone}
                                                    onChange={this.handleChange('telephone')} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item >
                                            <Button variant="contained" fullWidth type="submit" color="primary"
                                                size="large" aria-label="Save Address">{<GetLanguage value="Save Address"/>}</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <Typography align="center" gutterBottom>
                            <span>{<GetLanguage value="Do you want to go back?"/>}
                            <Link to={redirectTo ? redirectTo : null} style={{ textDecoration: 'none', color: '#2196f3' }}> {<GetLanguage value="Cancel"/>} </Link>
                            </span>
                        </Typography>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

AddressForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    shippingaddress: state.get_shipping_address,
    coutrieslist: state.get_countries_list,
    addressAdded: state.put_address_list,
    addressAddError:state.put_address_list.errorMessage,
});

const mapDispatchToProps = dispatch => ({

    getCountriesList: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.getCountriesListActionCreator())
    },
    getShippingAddress: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.getShippingAddressActionCreator())
    },
    onSubmitAddAddressDispatch: (shippingaddressObj) => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.setShippingAddressActionCreator(shippingaddressObj));
    },
});

const AddressFormWithStyles = withStyles(styles)(AddressForm);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressFormWithStyles));