/**
* Component for displaying customer wishlist.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WishlistItem from './WishlistItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
//Redux related imports
import { connect } from 'react-redux';
import PageTitle from '../../../app/components/common/PageTitle';
import AccountService from '../../../app/modules/account/AccountService';
import CategoryService from '../../../app/modules/category/CategoryService';
import WishlistService from '../../../app/modules/wishlist/WishlistService';
import { Divider } from '@material-ui/core';

const styles = theme => ({
});

/***************************************************************
 * Component to display customer wishlist
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 **************************************************************/
class CustomerWishlist extends React.Component {

    componentDidMount() {
        /**********************************************************
         * Check if the user is authenticated. 
         * If yes, display wishlist page.
         * If not, redirect to Sign-In page and once
         * signed-in, redirect back to wishlist page.
         *********************************************************/
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        }
        //Dispatch props to get wishlist info
        // this.props.displayWishlist();
        if (!this.props.loader_status && !Object.keys(this.props.customerWishlistedProducts).length) {
            this.props.loader();
        }
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.customerWishlistedProducts.items && nextprops.loader_status) {
            this.props.loader();
        }
    }
    getImageURL = (customAttributes) => {
        let image_url = null;
        for (let index = 0; index < customAttributes.length; index++) {
            if (customAttributes[index].attribute_code === "thumbnail") {
                image_url = customAttributes[index].value;
            }
            else {
                continue;
            }
        }
        return image_url;
    }

    getWishlistProductPrice = (regular_price, price) => {
        if (typeof (regular_price) === 'number' && typeof (price) === 'number') {
            if (regular_price !== 0 && price === 0) return parseFloat(regular_price.toString()).toFixed(2);
            if (price !== 0 && regular_price === 0) return parseFloat(price.toString()).toFixed(2);
        }
        else {
            return "";
        }
    }

    render() {
        const { customerWishlistedProducts } = this.props;

        if (customerWishlistedProducts === []) return null;
        if (customerWishlistedProducts === {}) return null;
        if (typeof (customerWishlistedProducts.items) === "undefined") return null;

        if (customerWishlistedProducts.items && customerWishlistedProducts.items.length > 0) {
            return (
                <React.Fragment>
                    <PageTitle title='Wishlist' />
                    {
                        customerWishlistedProducts.items.map((wishlistItem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <WishlistItem
                                        product={wishlistItem.product} itemIndex={index}
                                        item_id={wishlistItem.id} productName={wishlistItem.product.name}
                                        image_url={this.getImageURL(wishlistItem.product.custom_attributes)}
                                        product_url={CategoryService.getProductDetailURL(wishlistItem.product.custom_attributes)}
                                        itemPrice={this.getWishlistProductPrice(wishlistItem.product.regular_price, wishlistItem.product.price)}
                                        onDeleteItemFromWishlist={() => { }} {...this.props}>
                                    </WishlistItem>
                                    <Divider />
                                </React.Fragment>
                            );

                        }
                        )
                    }
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <PageTitle title='Wishlist' />
                    <Grid container alignItems="center" direction="column">
                        <Grid item xs>
                            <Typography variant="subtitle1" gutterBottom align='center'>
                                YOUR WISHLIST IS EMPTY</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom align='center'>
                                Don't let it stay empty. Add items to wishlist</Typography>
                        </Grid>
                        <CardContent>
                            <CardActions>
                                <Button size="large" component={Link} to="/" variant="contained" color="primary" aria-label="Checkout">CONTINUE SHOPPING</Button>
                            </CardActions>
                        </CardContent>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => ({
    loader_status: state.get_loader_status,
    customerWishlistedProducts: state.get_wishlisted_products,
});


const mapDispatchToProps = dispatch => ({
    displayWishlist: () => {
        let wishlistService = new WishlistService();
        dispatch(wishlistService.GetWishlistedProductsActionCreator());
    },
    loader: () => {
        dispatch({ type: 'SET_LOADER_STATUS' })
    }
});

CustomerWishlist.propTypes = {
    classes: PropTypes.object.isRequired,
};

const CustomerWishlistWithStyles = withStyles(styles)(CustomerWishlist);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerWishlistWithStyles));