/**
* Headerbar Component with Logo.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from './Logo';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import SearchInputDesktop from '../../search/searchbar/searchbardesktop/SearchbarDesktop';
import SignInButton from '../../myaccount/signin/SignInButton';
import SalesManInfo from '../../myaccount/accountinformation/SalesManInfo';
import CartButton from '../../cart/CartButton';
import Grid from '@material-ui/core/Grid';
import NavigationMobile from '../../navigation/navigationmobile/NavigationMobile';
import Typography from '@material-ui/core/Typography';
import AlarmOn from '@material-ui/icons/AlarmOn';
import LocationOn from '@material-ui/icons/LocationOn';
import Languages from '../../layouts/headerbar/Languages';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import UpdateIcon from '@material-ui/icons/Update';
import ViewList from '@material-ui/icons/ViewList';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import CategoryService from '../../../../app/modules/category/CategoryService';
import BulkOrder from './BulkOrder';
import GetLanguage from '../../../../library/GetLanguage';
import barcodeImage from '../../../../assets/images/barcode-scanner.png';
import RequisitionImage from '../../../../assets/images/Requisition_List.svg';
import Reorder from '../../../../assets/images/Reorder.svg';
import QuickOrderHeader from '../../../../assets/images/Quick_Order_header.svg';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { withRouter } from "react-router-dom";
const styles = theme => ({
    headerBarStylesDesktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        width: '1280px',
        margin: '0 auto',
        minHeight: '140px',
    },
    headerBarStylesMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    mdDeviceDownDisplayNone: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    mdDeviceUpDisplayNone: {
        position: 'sticky',
        top: '65px',
        zIndex: 9,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    root: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    searchOpen: {
        display: 'block',
        background: '#000000',
    },
    searchClose: {
        display: 'none',
        position: 'absolute',
        left: 0,
        right: 0,
        padding: '20px 15px',
        marginTop: '50px',
        background: '#000000',
    },
    paddingInHeaderItems:
        {
            padding: '0px 25px 0px 0px',
            [theme.breakpoints.down('md')]: {
                padding: '0px',
            },
        },
    verticalLine:
        {
            borderLeft: '2px solid #717D7E',
            height: '20px',
        },
    conatctUs: {
        color: '#1d2d5f',
        fontSize: '15px',
        padding: '0 20px',
    },
    RequisitionBg: {
        padding: '10px 20px',
        background: '#f65e5d',
        borderRadius: '20px',
        color: '#fff',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '600',
        display: 'flex',
    },
    headerCustomHeight: {
        height: '65px'
    },
    iconPaddingLeft: {
        padding: '8px 2px 8px 5px',
    },
    textPaddingRight: {
        paddingRight: '9px',
        fontSize: '14px'
    },
    staticPadding: {
        paddingRight: '15px',
        paddingLeft: '5px'
    },
    ulIcon: {
        fontSize: '20px',
        background: '#e6f4ff',
        padding: '7px',
        borderRadius: '50%',
        background: '#e6f4ff',
        borderRadius: '50%',
        height: '25px',
        width: '25px',
        textAlign: 'center',
        margin: '0 auto'
    },
    callText: {
        fontSize: '8px'
    },
    phoneNumber: {
        fontSize: '12px',
        textDecoration: 'none',
    },
    title: {
        flexGrow: 1,
    },
    customFont: {
        fontSize: '12px'
    },
    changeStoreColor: {
        color: '#F73455',
    },
    gusetSearchInput: {
        marginLeft: '15%'
    },
    closeButton: {
        color: "red",
        position: "absolute",
        right: "0",
        zIndex: "99"
    },
    mycartText: {
        color: 'black',
        margin: '2px',
        fontSize: '12px',
    },
    flexb: {
        flexBasis: '40%',
        justifyContent:'center',
    },
    displayflex: {
        display: 'flex'
    },
    customQuickorder:{
        fontSize:'12px',
        margin:'0'
    },
    cartSingle:{
        fontSize:'12px',
        marginTop:'15px'
    }
});

/***************************************************************
 * Component class for Header Bar
 * @author   Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class Headerbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSearchInputShown: false,
            mobileCategoryMenuOpen: false,
            salesmanLoggedInAsCustomerName: ''
        };
        this.scanClick = this.scanClick.bind(this);
    }

    //For Category Navigation in mobile mode
    handleCategoryNavigationToggle = () => {
        this.setState((prevState) => ({
            mobileCategoryMenuOpen: !prevState.mobileCategoryMenuOpen
        }));
    };
    //toggle searchbar in devices down md
    toggleSearch = () => {
        this.setState((prevState) => ({
            isSearchInputShown: !prevState.isSearchInputShown
        }));
    }
    scanClick = () => {
        this.props.history.push('/barcodescanner')
    }
    componentDidMount() {
        let CustomerExist = getItemFromLocalStorage('shopce-user');
        if (CustomerExist)
            this.setState({ salesmanLoggedInAsCustomerName: CustomerExist.firstname + ' ' + CustomerExist.lastname })

        /**
         * When the app is loaded for first time, the category
         * navigation menu data is fetched. Subsequent page transitions
         * will not fetch the category data again and the redux stored
         * menu data is used. 
         * Dispatch action to Redux to fetch category data
         */
        if (typeof (this.props.categoryData) === 'undefined') {
            this.props.getCategoryData();
        }
    }
    render() {
        const { classes, numOfItems } = this.props;
        const { isSalesmanUser, get_user_details } = this.props;
        let userInfo = localStorage.getItem('shopce-user');
        // let salesmanUser = getItemFromLocalStorage('sales-man');

        return (
            <React.Fragment>
                <AppBar position="fixed">
                    <Grid container className={classes.headerBarStylesDesktop}>
                        <Grid container spacing={3}>
                            <Grid item xs={3} style={{display:'flex'}} >
                                <Logo />
                            </Grid>
                            {!window.location.href.includes('/checkout') &&
                            <Grid item xs={9}>
                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                    <Grid item >
                                        {/* <Grid container direction="row" justify="center" spacing={8} alignItems="center">
                                                <img src={NewLogoImage} alt="NewLogo"  height="40" style={{padding:'5px'}} />
                                        </Grid> */}
                                    </Grid>

                                    <Grid item>
                                        <Grid container direction="row">
                                            <Grid item>
                                                <Grid container direction="row" justify="space-between" alignItems="center">
                                                    <Grid item className={classes.textPaddingRight}>
                                                        <Languages />
                                                    </Grid>
                                                    {/* <Grid item >
                                                        <div className={classes.verticalLine}></div>
                                                    </Grid> */}
                                                    {/* <Grid item>
                                                        <Grid container justify="center" alignItems="center">
                                                            <Grid item>
                                                                <IconButton className={classes.iconPaddingLeft}>
                                                                    <LocationSearchingIcon color="primary" />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.textPaddingRight}>
                                                                {<GetLanguage value="Help"/>}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    <Grid item>
                                                        <p className={classes.conatctUs}>Conatct Us: <span>848384039</span></p>
                                                    </Grid>
                                                    {userInfo ?
                                                        <React.Fragment>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="center" alignItems="center">
                                                                    {/* <Grid item>
                                                                        <IconButton className={classes.iconPaddingLeft}>
                                                                            <ViewList color="primary" />
                                                                        </IconButton>
                                                                    </Grid> */}
                                                                    <Grid item >
                                                                        <Link className={classes.RequisitionBg} to={{ pathname: '/myaccount', state: { submenu: 4 } }}
                                                                            style={{ textDecoration: 'none', color: '#00000' }}>

                                                                            <img style={{ width: '15px', marginRight: '10px' }} src={RequisitionImage} />{<GetLanguage value="Requisition List" />}({numOfItems})

                                                                        </Link>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {/* <Grid item>
                                                                <div className={classes.verticalLine}></div>
                                                            </Grid> */}
                                                        </React.Fragment> : <Grid item>
                                                            <Link className={classes.RequisitionBg} to={'/account'}>
                                                                <img style={{ width: '15px', marginRight: '10px' }} src={RequisitionImage} />
                                                                Requisition List
                                                            </Link>
                                                        </Grid>
                                                    }
                                                    <Grid item>
                                                        <Grid container justify="center" alignItems="center">
                                                            {/* <Grid item>
                                                                <SignInButton userInfo={userInfo} />
                                                            </Grid> */}
                                                            {isSalesmanUser && get_user_details && get_user_details.id &&
                                                                <React.Fragment>
                                                                    {/* <Grid item>
                                                                        <div className={classes.verticalLine}></div>
                                                                    </Grid> */}
                                                                    <Grid item>
                                                                        <Link to='/customersList' style={{ textDecoration: 'none' }}>
                                                                            <Grid container justify="center" alignItems="center">
                                                                                <Grid item>
                                                                                    <IconButton className={classes.iconPaddingLeft}>
                                                                                        <LocationOn color="primary" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography className={classes.textPaddingRight}>
                                                                                        {<GetLanguage value="Change Store" />}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Link>
                                                                    </Grid>
                                                                    {/* <Grid item>
                                                                        <div className={classes.verticalLine}></div>
                                                                    </Grid> */}
                                                                    <Grid item>
                                                                        <SalesManInfo salesmanUser={getItemFromLocalStorage('sales-man')} />
                                                                    </Grid>
                                                                </React.Fragment>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={3} style={{ marginTop: '20px' }}  >
                                    <Grid item xs={6} >
                                        {(window.location.pathname.includes('customersList')) ? '' :
                                            <Grid item className={userInfo ? classes.paddingInHeaderItems : classes.paddingInHeaderItems + " "} >
                                                <SearchInputDesktop isSearchMobile={false} />
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={6} className={classes.displayflex} >
                                        <Grid container className={classes.flexb}>
                                            <SignInButton userInfo={userInfo} />
                                        </Grid>
                                        {userInfo ?

                                            <Grid container justify={"space-evenly"} >
                                                <Grid item>
                                                    <div className={classes.ulIcon} ><img color="secondary" style={{ width: '20px' }} src={Reorder} /></div>
                                                    <Typography className={classes.customFont}>
                                                        <Link style={{ textDecoration: 'none', color: '#000' }} to={{ pathname: '/myaccount', state: { submenu: 3 } }}>
                                                            {<GetLanguage value='Reorder' />}</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.ulIcon} ><img color="secondary" style={{ width: '20px' }} src={QuickOrderHeader} /></div>
                                                    <BulkOrder />
                                                </Grid>
                                                <Grid item>
                                                    {!window.location.href.includes('/checkout') ? <CartButton /> : null}
                                                    <p className={classes.mycartText} >
                                                        My Cart
                                                </p>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container justify={"space-evenly"} >
                                                <Grid item>
                                                    <div className={classes.ulIcon} ><img color="secondary" style={{ width: '20px' }} src={Reorder} /></div>
                                                    <Typography className={classes.customFont}>
                                                        <Link style={{ textDecoration: 'none', color: '#000' }} to="/account">
                                                            {<GetLanguage value='Reorder' />}</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Link style={{ textDecoration: 'none', color: '#000' }} to="/account">
                                                        <div className={classes.ulIcon} ><img color="secondary" style={{ width: '20px' }} src={QuickOrderHeader} /></div>
                                                        <p className={classes.customQuickorder}>Quickorder</p>
                                                    </Link>
                                                </Grid>
                                                <Grid item>
                                                    <Link style={{ textDecoration: 'none', color: '#000' }} to="/account">
                                                        <IconButton className={classes.ulIcon}>
                                                            <Badge className={classes.badgeStyle} showZero={true} color="secondary"
                                                                badgeContent={0}>
                                                                <ShoppingCartIcon color='secondary' className={classes.cartIcon} />
                                                            </Badge>
                                                        </IconButton>
                                                        <p className={classes.cartSingle} >
                                                            My Cart
                                                        </p>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                            }

                        </Grid>

                    </Grid>



                    <Grid item xs={12} className={classes.staticPadding}>

                        <Grid item xs={12}></Grid>
                        {/* <Grid item xs={12} className={classNames(classes.staticPadding, classes.headerCustomHeight, classes.root)}>
                        
                        </Grid> */}
                    </Grid>
                    <Toolbar disableGutters={true} className={classNames(classes.mdDeviceUpDisplayNone, classes.staticPadding)}>
                        <IconButton color="default" className={classes.paddingInHeaderItems}
                            onClick={this.handleCategoryNavigationToggle}
                            aria-label="open mobile category navigation">
                            <MenuIcon color='secondary' />
                        </IconButton>
                        <div className={classNames(classes.title, classes.paddingInHeaderItems)}>
                            <Logo />
                        </div>
                        <Grid item>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {isSalesmanUser && get_user_details && get_user_details.id &&
                                    <React.Fragment>
                                        <Grid item>
                                            <Link to='/customersList' style={{ textDecoration: 'none' }}>
                                                <IconButton className={classes.iconPaddingLeft}>
                                                    <LocationOn color="primary" />
                                                </IconButton>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <SalesManInfo salesmanUser={getItemFromLocalStorage('sales-man')} />
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid item>
                                    <div style={{ color: "red", padding: "0 25px" }} onClick={this.scanClick}>
                                        <img style={{ height: '25px', width: '25px', marginTop: '-1px', display: 'inherit', border: '1px solid #ccc' }} src={barcodeImage} alt="barcodeImage" />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <SignInButton mobile={true} />
                                </Grid>

                                <Grid item>
                                    {userInfo &&
                                        <CartButton />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <NavigationMobile onClose={this.handleCategoryNavigationToggle}
                            mobileOpen={this.state.mobileCategoryMenuOpen} />
                    </Toolbar>
                </AppBar>
                <div className={classes.mdDeviceUpDisplayNone}>
                    <SearchInputDesktop isSearchMobile={true} />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    get_user_details: state.get_user_details_state,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
    numOfItems: (state.getRequisitionList && (Object.keys(state.getRequisitionList)).length) || 0,
    categoryData: state.categoryDataState.categoryData,
    profileNameUpdateStatus: state.set_updated_salesman_details_state.profileNameUpdateStatus,

});

const mapDispatchToProps = dispatch => ({
    getCategoryData: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getCategoryDataForMenuActionCreator());
    }
});

const HeaderbarWithStyles = withStyles(styles)(Headerbar);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderbarWithStyles));
