/**
* Common Component for adding a product to cart.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProductAddToCartPDP from '../../../extension/components/product/productaddtocart/ProductAddToCartPDP';
import ProductAddToCartList from '../../../extension/components/product/productaddtocart/ProductAddToCartList';
import { connect } from 'react-redux';

const styles = theme => ({
});

/***************************************************************
  * Component class for displaying Add to Cart
  * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
***************************************************************/
class ProductAddToCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productQuantity: 1,
            isProductOptionsSelected: true,
        }
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const {
            isProductConfigOptions,
            config_options,
            is_in_stock,
            isAddToCartButtonDisabled,
            sku,
            pdpURLKey,
            selectedConfigOptions,
            display_mode,
            productQuantity,
            parent,
            productDetail,
            isPremiumProduct
        } = this.props;

        if (display_mode === "pdp") 
        {
            return (
                <ProductAddToCartPDP
                    parent={parent}
                    isProductConfigOptions={isProductConfigOptions}
                    config_options={config_options}
                    is_in_stock={is_in_stock}
                    isAddToCartButtonDisabled={isAddToCartButtonDisabled}
                    sku={sku}
                    selectedConfigOptions={selectedConfigOptions}
                    productDetail={productDetail}
                    isPremiumProduct={isPremiumProduct}
                    isloading ={false}
                >
                </ProductAddToCartPDP>
            )
        }
        else if (display_mode === "list")
        {            
            return (
                <ProductAddToCartList
                    parent={parent}
                    isProductConfigOptions={isProductConfigOptions}
                    config_options={config_options}
                    is_in_stock={is_in_stock}
                    isAddToCartButtonDisabled={isAddToCartButtonDisabled}
                    sku={sku}
                    pdpURLKey={pdpURLKey}
                    selectedConfigOptions={selectedConfigOptions}
                    productQuantity={productQuantity}
                    productDetail={productDetail}
                    isPremiumProduct={isPremiumProduct}
                    isloading={false}
                >
                </ProductAddToCartList>
            )

        }
        else
        {
            return null;
        }
    }
}

ProductAddToCart.propTypes = {
};

/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({  
    isAddToCartButtonDisabled: state.result_add_to_cart_state.isAddToCartButtonDisabled,    
});

const mapDispatchToProps = dispatch => ({
});

const ProductAddToCartWithStyles = withStyles(styles)(ProductAddToCart);
export default connect(mapStateToProps, mapDispatchToProps)(ProductAddToCartWithStyles);

