/**
* Copyright block.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { Redirect } from 'react-router';
import CMSComponent from '../../cms/CMSComponent';
import FooterService from './../../../../app/modules/footer/FooterService';
import { getItemFromLocalStorage } from './../../../../app/framework/storage/local/localStorageHandler';
import GetLanguage from '../../../../library/GetLanguage'

const styles = theme => ({
    cardInFooter: {
        backgroundColor: '#1d2d5f',
        padding: '0.5rem',
        display: 'block',
        overflow: 'hidden',
        clear: 'both',
        textAlign: 'center',
        position: 'relative',
        bottom: 0,
        padding: '15px 40px',

    },
    cardInFooterStyle: {
        padding: 0,
        margin: 0,
        listStyleType: 'none',
        float: 'right',
        width: '60%',
    },
    cardInFooterliStyle: {
        float: 'left',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '0.75rem',
        marginRight: '3%',
        width: '17%',
        [theme.breakpoints.down('xs')]: {
            width: 'inherit',
        }
    },

    cardInFootertext: {
        float: 'left',
        textDecoration: 'none',
        textAlign: 'left',
        listStyle: 'none',
        fontSize: '0.75rem',
        padding: '0',
        margin: '0',
    }
});


/***************************************************************************** 
 * Footer bar with Copyright Notice.
 * @author Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
class CopyRightBar extends Component {

    onClickFooter = (block_id) => {
        this.props.history.push({
            pathname: '/cmsBlocks',
            state: { footerId: block_id }
        })
    }

    render() {
        const { classes, get_footer_details } = this.props;

        return (
            <div className={classes.cardInFooter}>
                <ul className={classes.cardInFootertext} >
                    <li style={{ textDecoration: 'none', color: '#ffffff' }} >
                        <Link to='' style={{ textDecoration: 'none', color: '#ffffff' }} >
                            {<GetLanguage value="@Uniliver2019" />}
                        </Link>
                    </li>
                </ul>
                {!window.location.href.includes('/checkout') &&
                    <ul className={classes.cardInFooterStyle}>
                        {get_footer_details && get_footer_details.map((data, index) => (
                            <li key={index} className={classes.cardInFooterliStyle} onClick={() => this.onClickFooter(data.block_id)}>{data.title}</li>
                        ))}
                        <li>
                            <Link to='/salesmanlogin' style={{ textDecoration: 'none', color: '#ffffff' }} className={classes.cardInFooterliStyle}>
                                {<GetLanguage value="Salesman" />}
                            </Link>
                        </li>
                    </ul>
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    get_footer_details: state.get_footer_details ? state.get_footer_details.footerDetails : '',
    getFooterCMSReducer: state.GetFooterCMSReducer,
});

const mapDispatchToProps = dispatch => ({
    getFooterDetails: () => {
        let footerService = new FooterService();
        dispatch(footerService.GetFooterDetailsActionCreator());
    },
    GetFooterCMS: (block_id) => {
        let footerService = new FooterService();
        dispatch(footerService.GetFooterCMS(block_id));
    }

});
const CopyRightBarWithStyles = withStyles(styles)(CopyRightBar);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CopyRightBarWithStyles));
