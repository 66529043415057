/**
* Filter Category Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Ruchi Verma <ruchi.verma@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import { decodeHTMLEntities } from '../../../../app/framework/common/helpers';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CategoryService from '../../../../app/modules/category/CategoryService';

/****************************************************************************** 
 * Filter Category Component.  To display filter
 * categories ( collapsible menu)
 * @author    Ruchi Verma <ruchi.verma@embitel.com>
 *****************************************************************************/
class SubmenuFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMoreObjectIndex: 7,
      viewMoreClicked: false
    };
  }
  componentWillMount() {
     //Code to display filter chips on refresh
    let isAnyParam = window.location.href.indexOf('=') > -1 ? true : false;// Check in the URL if any parameters exists
    let filterTags = [];
    let query = '';
    if (this.props.ParentCategory) {
      var parentCategoryKeys = Object.keys(this.props.ParentCategory);
      var parentCategoryValues = Object.values(this.props.ParentCategory);
    }

    if (isAnyParam) {
      var url = new URL(window.location.href);
      var filterName = '';
      var categoryValue = url.searchParams.get("Category");// Check if the filter already exists for Category
      if (categoryValue !== null) {
        query = 'Category=' + categoryValue;
        {
          parentCategoryKeys && parentCategoryKeys.map((element, index) => {
            if (element === categoryValue) {
              filterName = parentCategoryValues[index];
              filterTags.push({ filterName: filterName, queryArray: query });
              localStorage.setItem('categoryTags',JSON.stringify({ filterName: filterName, queryArray: query }))
              this.props.setFilterTags(filterTags);//if filter exists for Category before refershing apply the same
            }
          })
        }
      }
    }
  }

  handleClick = (filterOption, value, filterName) => event => {
    let filterTags = this.props.filter_tags.filterTags ? this.props.filter_tags.filterTags : [];//get existing filter tags
    filterTags.map((item,index)=>{
      let filterAlreadyExists = item.queryArray.includes(filterOption);
      let i;
      //To remove unchecked filter option from filterTags data
      if(filterAlreadyExists){
        i = index;
        filterTags.splice(i,1);
      }
    })
    let query = '' + filterOption + '=' + value;//format - Category=38
    filterTags.push({ filterName: filterName, queryArray: query });
    this.props.setFilterTags(filterTags);//data for filter tags
    let queryParams = new URLSearchParams(this.props.location.search);
    queryParams.set(filterOption, value);
    // Push the key/value pairs in an array
    let queryArray = [];
    for (var pair of queryParams.entries()) {
      queryArray.push(pair[0] + '=' + pair[1]);
    }

    let filterLocation = '?' + queryArray.join('&');
    this.props.history.push(filterLocation);
  };

  viewMore = () => {
    if (this.state.viewMoreObjectIndex === 7) {
      // let filterSubOptions = Object.values(this.props.optionValues);
      // let filterValues = Object.values(filterSubOptions[0]);

      var parentCategoryKeys = Object.keys(this.props.ParentCategory);

      this.setState({ viewMoreObjectIndex: parentCategoryKeys.length, viewMoreClicked: true });
    }
    else {
      this.setState({ viewMoreObjectIndex: 7, viewMoreClicked: false });
    }
    window.scrollTo(0, 0);
  }

  render() {
    const { Category, ParentCategory } = this.props;
    if (Category) {
      var filterSubOptions = Object.values(Category);
    }
    if (ParentCategory) {
      var parentCategoryKeys = Object.keys(ParentCategory);
      var parentCategoryValues = Object.values(ParentCategory);
    }
    var level = 0;
    let filterOptionCount = parentCategoryKeys.length
    return (
      <Grid container direction="column" justify="flex-start" alignItems="flex-start">
        {parentCategoryKeys && parentCategoryKeys.map((element, index) => {
          level++;
          return (
            <Grid item key={index} style={{ cursor: 'pointer' }}
              onClick={this.handleClick('Category', element, parentCategoryValues[index])} className="submenu">
              {(index > this.state.viewMoreObjectIndex) ? null :
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ padding: '3px 0' }} >
                  {/* <Grid item>
                    {(index >= 2) ? '' : <ChevronRight />}
                  </Grid> */}
                  <Grid item>
                  <Radio        
        color="default"
        value="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Typography style={{ fontSize: '12px' }} align="left" variant="body1">{decodeHTMLEntities(parentCategoryValues[index])}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          );
        })
        }
        <Button color={this.state.viewMoreObjectIndex > 7 ? 'primary' : 'secondary'} size="small"
          onClick={() => this.viewMore()}>
          {filterOptionCount <= 6 ? '' : filterOptionCount > 7 && !this.state.viewMoreClicked ? 'View More' : 'View Less'}
        </Button>

        {filterSubOptions && filterSubOptions.map((optionKey, index) => {
          let filterKeys = Object.keys(optionKey);
          let filterValues = Object.values(optionKey);
          //increment category level
          level++;
          return filterValues && filterValues.map((optionValue, indexValue) => {
            return (
              <Grid item key={indexValue} className="subMenu" style={{ paddingLeft: level * 1 + 'rem', cursor: 'pointer' }}
                onClick={this.handleClick('Category', filterKeys[indexValue])}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <Grid item>
                    <Typography style={{ fontSize: '12px' }} variant="body1">{decodeHTMLEntities(optionValue)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        })}
      </Grid>
    );
  }
}


const mapStateToProps = state => ({
  filter_tags: state.get_filter_tag_chips
})
const mapDispatchToProps = dispatch => ({
  setFilterTags: (filterTags) => {
    let cs = new CategoryService();
    dispatch(cs.getFilterTagsDataActionCreator(filterTags));
  }
});

SubmenuFilter.propTypes = {
  ParentCategory: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmenuFilter));
