/**
* Process String Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/


// Function to replace a character in a string with another.
export function replaceString(str,targetChar,replaceChar){
    if(typeof(str) !== 'undefined' && str !== '') {
        return str.split(targetChar).join(replaceChar);
    }
}

//To get the price of Product to two decimal places.
export function formatPriceOfProduct(price){
    return ("฿ "+parseFloat(price).toFixed(2));
}