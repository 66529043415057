/**
* Counter Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import CartService from './../../modules/cart/CartService';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  counterDiv: {
    border: '0.5px solid #a4a0a0',
    borderRadius: '2px',
    padding:'0px 10px',
    maxWidth: '100px',
  },
  counterButton: {
    padding: '5px 0px',
    minWidth: 'auto',
  },
  iconStyle: {
    padding: '0',
    fontSize: '1.25rem',
    color:'#F73455',
  },
  quantity: {
    width: '25px',
    padding:'0.25rem',
    textAlign: 'center',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    border: '0px',
    '&:focus': {
      outline: 'none'
    },
  }
});

/***************************************************************
  * Component class for displaying Counter
  * @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.value ? props.value : Object.keys(props.variantSelected).includes(props.productDetail.sku) ? props.variantSelected[props.productDetail.sku].selectedQuantity : 1,
      min_number: 1,
      loading_addToCart :false
    }
    this.loading_addToCart = false;
    this.changeQuantity = this.changeQuantity.bind(this);
  }
  componentDidMount(){
    if(isNaN(this.state.count))
    {
      this.setState({count:1})
    }
  }
  componentDidUpdate(){
    this.loading_addToCart = false;
    //this.setState({loading_addToCart:false})
  }
  componentWillReceiveProps(nextProps){
      if(nextProps.cartIndex >= 0 && nextProps.cartIndex !== this.props.cartIndex){
          this.setState({count:nextProps.value});
      }
      if(nextProps.value && (nextProps.value !== this.state.count)){
        this.setState({count:nextProps.value});
      }
      // else if(window.location.href.includes('/cart')){
      //  nextProps.miniCartDetails.miniCartDetails.map((item,index)=>{
      //    if(index===this.props.rowIndex){
      //      this.setState({loading_addToCart:false}) 
      //    }
      //  })
      // }
  }
  changeQuantity(method, value) {
    let count = method === 'add' ? this.state.count + 1 : method === 'sub' ? this.state.count - 1 : value;

    if (!(count >= this.props.max_number || count < this.state.min_number)) {
      if(this.props.requisitionList) {
        this.setState({ count });
        return false;
      }
      if (this.props.value) { 
        //this.setState({loading_addToCart:true})  
        this.loading_addToCart = true; 
        if(window.location.href.includes('/cart')){
          this.props.qtyChanged(this.props.rowIndex,true);
        }   
        // Cart and Minicart requried value and item_id 
        this.props.onEditItemFromCart(this.props.item_id,count,this.props.miniCartDetails.current_cart.label,this.props.miniCartDetails.current_cart.quote_id);

      } else {
        const variant_selected_data = { ...this.props.variantSelected };
        const { productDetail } = { ...this.props };
        const { sku } = { ...productDetail };
        variant_selected_data[sku] = variant_selected_data[sku] || productDetail;
        variant_selected_data[sku].selectedQuantity = count;
        variant_selected_data[sku].selectedUnit = variant_selected_data[sku].selectedUnit || 0;
        this.props.unitSelectedChange(variant_selected_data);
      }
      this.setState({ count },()=>
      {
        if(window.location.href.includes('/cart')){
          this.loading_addToCart=false;
          setTimeout(() => {
            this.props.qtyChanged(this.props.rowIndex,false);
          }, 2500);
        }
      });
    }
  }
  render() {
    const { classes, id } = this.props;
    return (
      <div>
      {this.loading_addToCart&&<div className="cart-loader"><CircularProgress size={24} /></div>}
      <Grid container direction='row' className={classes.counterDiv + " " + "counterDivPdp"} justify='space-around' alignItems='center'>
        <Grid item>
          <Button aria-label="Subtract" onClick={() => this.changeQuantity('sub')} className={classes.counterButton + " " + "countBtnPdp" }>
            <MinusIcon className={classes.iconStyle} color='action' />
          </Button>
        </Grid>        
        <Grid item>  
          <input id={id} type="number" min='1' max='100' value={this.state.count} onChange={(e) => this.changeQuantity('input', e.target.valueAsNumber)}
            className={classes.quantity + " " + "quantityPdp" }
             onClick={() => document.getElementById(id) && document.getElementById(id).focus()} 
            onBlur={(e) => this.setState({ count: e.target.valueAsNumber ? this.state.count : 1 })} />
        </Grid>
        <Grid item>
          <Button aria-label="Add" onClick={() => this.changeQuantity('add')} className={classes.counterButton + " " + "countBtnPdp" }>
            <AddIcon className={classes.iconStyle} color='action' />
          </Button>
        </Grid>
      </Grid>
      </div>
    )
  }
}

Counter.propTypes = {
  value: PropTypes.number,
  max_number: PropTypes.number,
  onCounterChange: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
  variantSelected: state.variant_selected,
  miniCartDetails: state.get_minicart_details,
  productDetail: props.product ||  (state.get_product_detail && Object.keys(state.get_product_detail).length && state.get_product_detail.productDetailData[0])
});
const mapDispatchToProps = dispatch => ({
  unitSelectedChange: (data) => {
    let cs = new CartService();
    dispatch(cs.selectedvariantActionCreator({ payload: data }));
  },
  onEditItemFromCart: (item_id, quantityChanged, label,quote_id) => {
    let cs = new CartService();
    dispatch(cs.EditQuantityInCartActionCreator(item_id, quantityChanged, label, quote_id));
  },
});
const CounterWithStyles = withStyles(styles)(Counter);
export default connect(mapStateToProps, mapDispatchToProps)(CounterWithStyles);
