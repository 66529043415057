import MagnaAPIHandler from '../api/MagnaAPIHandler';
import Cookies from 'js-cookie';
import magnaCookies from '../cookie/magnaCookies';
import {getItemFromLocalStorage} from '../storage/local/localStorageHandler';

// Common function to handle the relative URLs of
// Category Menu items. Substring method is used to 
// remove the "https://magece.int.embitel.io"(30) from the
// URL. Leading "/" is required to have relative URL
// For the menu items.
// As of now, for thailand website 
// "https://thailand-2kmze7y-sp5kooilu67bq.us-3.magentosite.cloud"(62)
// "https://thailand-pwa-ztnb5qy-sp5kooilu67bq.us-3.magentosite.cloud/index.php"(76)
export function handleURLsForMenu(menuURL) {
    const url=new URL(menuURL);
    return url.pathname;
}

//Server media URL base path (For images of products)
//Media Base URL is changed to point to AWS Magento Back End.
// export const mediaURLBasePath = "https://thailand-pwa-ztnb5qy-sp5kooilu67bq.us-3.magentosite.cloud/media/catalog/product";
   //export const mediaURLBasePath = "https://mcstaging.elmt.b2b.unileverservices.com/media/catalog/product";
   export const mediaURLBasePath = (window.location.hostname === "usmileshoppro.com") ?"https://usmileshoppro.com/media/catalog/product":"https://mcstaging.elmt.b2b.unileverservices.com/media/catalog/product";
   
//To get the price of Product for Category Listing to two decimal places.
export function formatPriceOfProductforCategoryListing(price) {
    let priceAssign = isNaN(price) ? "" : ("฿ " + parseFloat(price).toFixed(2));
    let priceNumberFormat = priceAssign.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    return priceNumberFormat;
}

//To get the price of Product to two decimal places.
export function formatPriceOfProduct(price) {
    return price && "฿ " + parseFloat(price);
}

//To decode HTML Special Characters (For example, in Product Name.)
// Thrid-party library is used ("html-entities")
export function decodeHTMLEntities(inputString) {
    const Entities = require('html-entities').AllHtmlEntities;
    const entities = new Entities();
    return entities.decode(inputString);

}

//Capitalize First Letter
export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

//Generate Guest Cart ID
export function getGuestCartID() {
    if (getSignedInCustomerCartID() === null) {
        if (typeof Cookies.get('guest-cart-id') !== 'undefined' &&!Cookies.get('guest-cart-id')) {
            let requestURL = '/guest-carts';
            let requestType = 'POST';
            let requestBody = {};
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    Cookies.set('guest-cart-id', response.data, { expires: 7, path: '/' });
                    return Cookies.get('guest-cart-id');
                },
                (error) => {
                }
            );
        }
        else {
            return Cookies.get('guest-cart-id');
        }
    }
}

//Get signed-in customer ID
export function getSignedInCustomerCartID() {
    let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
    if (userFromLocalStorage) {

            if ( typeof Cookies.get('customer-cart-id') === 'undefined' ||  Cookies.get('customer-cart-id') === undefined) {
            let requestURL = '/carts/mine';
            let requestType = 'POST';
            let requestBody = {};
            requestBody.data = {
                "customerId": userFromLocalStorage.customerId
            };
            requestBody.header = ("Bearer " + userFromLocalStorage.token);
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    Cookies.set('customer-cart-id', response.data, { expires: 7, path: '/' });
                    let requestURL = '/carts/mine/billing-address';
                    let requestType = 'POST';
                    let requestBody = {};
                    requestBody.header = ("Bearer " + userFromLocalStorage.token);
                    requestBody.data = {
                        "address" :  {

                        },
                        "cartId" : response.data
                    };
                    MagnaAPIHandler(requestURL, requestType, requestBody).then(
                        (response) => {                          
                            return Cookies.get('customer-cart-id');
                        },
                        (error) => {
                            console.log(error);  

                        });
                },
                (error) => {
                    console.log(error);  
                }
            );
        }
        else {
            return Cookies.get('customer-cart-id');
        }
    }
    else {
        return null;
    }
}

//Remove customer cart-id from Cookie after placing order
export function removeCustomerCartFromCookie() {
    //Remove customer cart id after placing the order.
    magnaCookies.removeCookie('customer-cart-id');
}

//Format the order number in Order Success page to have 9 digits
export function formatOrderNumber(orderNo){
    return orderNo.padStart(9,'0');
}



