/**
* Brands Carousel Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import Slider from "react-slick";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GetLanguage from '../../../../../library/GetLanguage';
import CategoryService from '../../../../../app/modules/category/CategoryService';
import { Link } from 'react-router-dom';

const styles = theme => ({
    carouselTitleText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1d2d5f',
        wordSpacing: '3px',
        paddingBottom: '40px',    },
    brandImgStyle : {
        cursor: 'pointer',
        maxHeight: '83px'
    },
    sectioncontainer: {
        width:'1280px',
        margin:'0 auto',
        padding:'60px 0',
        boxSizing: 'border-box',
    }
});


/***************************************************************
 * Component class for displaying Brands Container.
 * which contains recently viewed images and carousel
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class BrandsCarousel extends React.Component {

    componentDidMount() {
        this.props.getBrands();
    }

    // getImageURL = (url) => {
    //     if(url && url !=='') {
    //         let newURL = new URL(url);
    //         return newURL.pathname;
    //     }
    //     return '/';
    // }
    getImageURL = (url) => {  
        if(url && url !==' ') {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
          let isValidUrl = pattern.test(url);
          if(isValidUrl){
            let newURL = new URL(url);
            this.props.history.push(newURL.pathname);
          }
          else{
              this.props.history.push('/')
          }
        }
    }
    render() {
        const { classes, get_homepage_brands } = this.props;
        let brandImages = get_homepage_brands;

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 2,
            prevArrow: <KeyboardArrowLeft color='primary' fontSize='large' />,
            nextArrow: <KeyboardArrowRight color='primary' fontSize='large' />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ]
        }
        return (
            <Grid container direction='column' className="Brandslider" alignItems='stretch' style={{ backgroundColor: '#e6f4ff' }}>
                 <Grid container  className={classes.sectioncontainer}>
                 <Grid item xs={12}>
                        <Typography className={classes.carouselTitleText}>
                            {<GetLanguage value="Shop By Brand" />}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Slider ref={slider => this.slider = slider} {...settings}>
                            {brandImages && brandImages.length && brandImages.map((item, index) => {
                                if (item.is_enabled === '1') {
                                    return (
                                        <Grid container key={index}>
                                            <Grid item>
                                            {/* <div onClick={this.getImageURL.bind(this,item.url)} style={{ background: '#fff', padding: '1rem', textAlign: 'center', borderRadius: '12px' }}> */}
                                            <Link to={item.url?item.url:'/'}>
                                                        <img className={classes.brandImgStyle} src={item.image_path ? item.image_path: ''}
                                                        alt="Brand" />
                                                    </Link>
                                                {/* </div> */}
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            }
                            )}
                        </Slider>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    getBrands: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getBrandsDataForHomePageActionCreator());
    },
});

const mapStateToProps = state => ({
    get_homepage_brands: state.get_homepage_brands.homepageBrands,
});


const BrandsCarouselWithStyles = withStyles(styles)(BrandsCarousel);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandsCarouselWithStyles));
