/**
* Order Summary Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
//Redux related imports
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
//Price display formatting
import { formatPriceOfProductforCategoryListing, decodeHTMLEntities, mediaURLBasePath } from '../../../app/framework/common/helpers';
import { formatPriceOfProduct } from '../../../app/framework/common/processString';
import GetLanguage from '../../../library/GetLanguage';
import { getItemFromLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';
import CartService from '../../../app/modules/cart/CartService';
import UnitSelector from '../uom/UnitSelector';
import Counter from '../../../app/components/common/Counter';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import getConfigValue from '../../../app/framework/config/configHandler';
import '../../../../src/assets/css/common/checkout.css';
const orderParameters = getConfigValue("order");
const styles = theme => ({
  orderSummaryBg: {
    // backgroundColor: '#FBFBFF',
    // padding:'0.5rem 0rem',
    // border: '1px solid #E6E7ED',
    borderRadius: 2,
    marginTop: '30px',
    padding: '10px',
    background: '#fff',
  },
  orderTitle: {
    fontSize: '16px',
    color: '#424242',
    textTransform: 'uppercase',
    textAlign: 'left',
    borderBottom: '1px solid #ebebeb',
    margin: '0 0 10px 0',
    fontWeight: 'normal',
    width: '100%',
    padding: '15px 0',
  },
  checkoutBtn: {
    textAlign: 'center',
  },
  summeryTxt: {
    marginBottom: '10px',
    fontSize: '12px',
  },
  termsTxt: {
    fontSize: '12px',
  },
  subtotal: {
    fontSize: '14px',
  },
  totalPriceTxt: {
    fontWeight: 'bold',
    fontSize: '14px',
    paddingTop: '10px',
  },
  fontWeightTxt: {
    fontWeight: 'bold',
  },
  totalPrice: {
    fontSize: '16px',
  },
  orderSummaryFont: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  image: {
    width: 80,
    height: 90,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  paddingBtwRows: {
    padding: '0.5rem 0rem'
  },
  paddingBtwItems: {
    padding: '0rem 0.5rem'
  },
  reviewHeaderText: {
    color: '#424242',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  placeOrderStyle: {
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px'
    },
    priceWrapper: {
      width: '100%',
      textAlign: 'center'
    },
    labelName: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '1.5'
    },
  }
});
let salesmanInfo = getItemFromLocalStorage('salesman-user');
/***************************************************************
 * Component class for order summary in checkout flow.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedA: false,
      orderPlaced: false,
      placeOrderButtonDisabled: this.props.isPlaceOrderButtonDisabled ? this.props.isPlaceOrderButtonDisabled : false
    }
  }
  componentDidMount() {
    this.props.displayMiniCart();
  }
  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     placeOrderButtonDisabled: false
  //   })
  // }
  componentDidUpdate() {
    setTimeout(() => {
      if (this.state.orderPlaced === true && this.state.placeOrderButtonDisabled === true && window.location.href.includes('/checkout')) {
        this.setState({ placeOrderButtonDisabled: false })
      }
    }, 15000
    )
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  onDeleteLinkClick = (e, item_id) => {
    e.preventDefault();
    this.props.onDeleteItemFromCart(item_id, this.props.miniCartDetails.current_cart.quote_id);
  }
  handlePlaceOrder = () => {
    this.setState({
      placeOrderButtonDisabled: true,
      orderPlaced: true
    }, () => {
      this.props.nextStep();
    })

  }
  render() {
    const { classes } = this.props;
    const { targetStep, nextStep, continueDisabled } = this.props;
    const { order_summary_details, miniCartDetails } = this.props;
    console.log("minicart", miniCartDetails)
    const { isPlaceOrderButtonDisabled } = this.props;
    const { OrderConfirmation, order_summary_from_confirmation } = this.props;
    let itemsInfo;
    if (OrderConfirmation) {
      itemsInfo = order_summary_from_confirmation ? order_summary_from_confirmation : '';
    }
    else {
      itemsInfo = order_summary_details ? order_summary_details : '';
    }
    let productsInOrder = itemsInfo.items ? itemsInfo.items : '';
    let u2k2Qty = ''; let u2k2Total = ''; let additional_info = '';
    productsInOrder.length > 0 && productsInOrder.map((item, index) => {
      u2k2Qty = Math.round(item.extension_attributes.ecc_item_qty) === 0 ? false : true;
      u2k2Total = Math.round(item.extension_attributes.ecc_item_total) === 0 ? false : true;
      additional_info = item.extension_attributes.additional_skus;
    })

    let discountFlag = false;
    let discountTotal = 0;
    if (window.location.href.includes('/checkout')) {
      return (
        <React.Fragment>
          <div className="checkout-cartlist">
            <Grid container direction="row" justify="flex-end">
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="padding_15"
                >
                  <Typography className={classes.heading}>{productsInOrder.length} items in Cart</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="padding_0">
                  <Grid item md={12} className="checkout-list-container">
                    {miniCartDetails && miniCartDetails["miniCartDetails"] && miniCartDetails["miniCartDetails"].map((product, index) =>
                      <Grid container direction="row" justify="flex-end" className="list-item">
                        <Grid item md={2}>
                          <img className={classes.img}
                            alt={decodeHTMLEntities(product.name)}
                            src={mediaURLBasePath + product.extension_attributes.image_url} />
                        </Grid>
                        <Grid container direction="row" justify="flex-end" item md={10} className="product-right">
                          <Grid item md={9}>
                            <Link className="product-text" to={product.product_url}>
                              <Typography>
                                {decodeHTMLEntities(product.name)}
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item md={3}>
                            <Grid className="Produt-price-list">{formatPriceOfProductforCategoryListing(product.price * parseInt(product.qty))}</Grid>
                          </Grid>
                          <Grid container direction="row" justify='space-between' className="checkout-second-row">
                            <Grid item md={4}>
                              <UnitSelector unitSelector={this.state.unitSelector} />
                            </Grid>
                            <Grid item md={4}>
                              <Grid>
                                <Counter cartIndex={miniCartDetails.current_cart_index} rowIndex={index} qtyChanged={this.qtyChanged}
                                  value={parseInt(product.qty)} min_number={1}
                                  item_id={product.item_id} max_number={orderParameters.maxQuantity}
                                />
                              </Grid>
                            </Grid>
                            <Grid item md={4} className="checkout-del">
                              <Button aria-label="Add" onClick={(event) => this.onDeleteLinkClick(event, product.item_id)}>
                                <CloseIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                    }
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              {miniCartDetails.current_cart && miniCartDetails.current_cart.subtotal && miniCartDetails.current_cart.subtotal !== 0 &&
                <Grid container direction='column' className="order-details-chk">
                  <Grid item xs={12}>
                    <div className="checkout-detail-head">Order Summary</div>
                    <div className="clearFixes" >
                      <div className="text-chk-left fsize_14">{<GetLanguage value="Sub Total" />}</div>
                      <div className="text-chk-right fsize_14">{formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.subtotal : 0)}</div>
                    </div>
                    {discountFlag ?
                      <div className="clearFixes">
                        <div className="text-chk-left fsize_12">{<GetLanguage value="Discount" />}</div>
                        <div className="text-chk-right fsize_12">{formatPriceOfProduct(discountTotal)}</div>
                      </div>
                      : ""}
                    <div className="clearFixes">
                      <div className="text-chk-left fsize_12">{<GetLanguage value="Tax" />}</div>
                      <div className="text-chk-right fsize_12"> {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.tax_amount ? miniCartDetails.current_cart.tax_amount : 0 : 0)}</div>
                    </div>
                    <div className="clearFixes br_top">
                      <div className="text-chk-left  fsize_16">{<GetLanguage value="Total" />}</div>
                      <div className="text-chk-right  fsize_16">{formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.grand_total : 0)}
                      </div>
                    </div>
                    {!OrderConfirmation &&
                      <React.Fragment>
                        <Grid item xs={10} className="checkout-chk-box">
                          <FormControlLabel control={
                            <Checkbox checked={this.state.checkedA} onChange={this.handleChange('checkedA')} value="checkedA" />
                          } />
                          <label className="label-name">
                            {<GetLanguage value="I've read and accept terms & conditions" />}
                          </label>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" alignItems="center" className="chk-btn">
                            <Fab id='con' aria-label="Continue" variant="extended" type="submit"
                              size="large" disabled={(this.state.checkedA) ? this.state.placeOrderButtonDisabled : true} onClick={this.handlePlaceOrder}>{targetStep ? targetStep : <GetLanguage value='PROCEED TO CHECKOUT' />}</Fab>
                            {/* <Fab id='con' aria-label="Continue" variant="extended" type="submit" style={{width:'200px',marginBottom:'10px'}}
                    size="large"  disabled={continueDisabled||isPlaceOrderButtonDisabled} onClick={nextStep}>{targetStep ? targetStep : 'PROCEED TO CHECKOUT'}</Fab> */}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  </Grid>
                </Grid>}
            </Grid>
          </div>
        </React.Fragment>
      );
    }
    else {
      if (itemsInfo) {
        return (
          <React.Fragment>
            <Grid container className={classes.orderSummaryBg}>
              <Grid item xs={12} style={{ background: '#FBFBFF' }} className={classes.paddingBtwRows}>
                <Grid container direction='row' justify='space-between' className={classes.paddingBtwItems}>
                  <Grid item xs={3} sm={2} lg={1} style={{ textAlign: "left" }} className="print-order">
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="SKU" />}</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} lg={2} className="print-order">
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Product Name" />}</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={1} style={{ textAlign: "center" }} className="print-order">
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Ordered QTY" />}</Typography>
                  </Grid>
                  {window.location.href.includes('/vieworder') ?
                    productsInOrder && productsInOrder.length ?
                      u2k2Qty ?
                        <Grid item xs={2} sm={2} lg={1} style={{ textAlign: "center" }} className="print-order">
                          <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Confirmed Qty" />}</Typography>
                        </Grid> : '' : '' : ''}
                  <Grid item xs={2} sm={2} lg={2} style={{ textAlign: "center" }} className="print-order">
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Price" />}</Typography>
                  </Grid>
                  {window.location.href.includes('/vieworder') ?
                    productsInOrder && productsInOrder.length ?
                      u2k2Total ?
                        <Grid item xs={2} sm={3} lg={2} style={{ textAlign: "center" }} className="print-order">
                          <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Confirmed Total" />}</Typography>
                        </Grid> : '' : '' : ''}
                  <Grid item xs={2} sm={3} lg={2} style={{ textAlign: "right" }} className="print-order">
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Ordered Total" />}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.paddingBtwItems}>
                <ProductsInOrder itemsInfo={itemsInfo} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {additional_info && additional_info.length > 0 &&
                <Grid item xs={12} className={classes.paddingBtwItems}>
                  <AdditionalProducts itemsInfo={additional_info} />
                </Grid>
              }
              <Grid item xs={12} className={classes.paddingBtwRows}>
                <Grid container direction='row' justify='space-between' className={classes.paddingBtwItems}>
                  <Grid item>
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Subtotal" />}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.orderSummaryFont} gutterBottom>
                      {formatPriceOfProductforCategoryListing(itemsInfo.base_subtotal ? itemsInfo.base_subtotal : 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.paddingBtwRows}>
                <Grid container direction='row' justify='space-between' className={classes.paddingBtwItems}>
                  <Grid item>
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Tax" />}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.orderSummaryFont} gutterBottom>
                      {formatPriceOfProductforCategoryListing(itemsInfo.tax_amount ? itemsInfo.tax_amount : 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.paddingBtwRows}>
                <Grid container direction='row' justify='space-between' className={classes.paddingBtwItems}>
                  <Grid item>
                    <Typography className={classes.orderSummaryFont} gutterBottom>{<GetLanguage value="Total" />}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.orderSummaryFont} gutterBottom>
                      {formatPriceOfProductforCategoryListing(itemsInfo.base_grand_total ? itemsInfo.base_grand_total : 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!OrderConfirmation &&
                <React.Fragment>
                  <Grid item xs={10}>
                    <FormControlLabel style={{ margin: '0px 5px' }} control={
                      <Checkbox checked={this.state.checkedA} onChange={this.handleChange('checkedA')} value="checkedA" style={{ padding: '10px' }} />
                    } />
                    <label className="label-name">
                      {<GetLanguage value="I've read and accept terms & conditions" />}
                    </label>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Fab id='con' aria-label="Continue" variant="extended" type="submit" style={{ width: '200px', marginBottom: '10px' }}
                        size="large" disabled={(this.state.checkedA) ? this.state.placeOrderButtonDisabled : true} onClick={this.handlePlaceOrder}>{targetStep ? targetStep : <GetLanguage value='PROCEED TO CHECKOUT' />}</Fab>
                      {/* <Fab id='con' aria-label="Continue" variant="extended" type="submit" style={{width:'200px',marginBottom:'10px'}}
                      size="large"  disabled={continueDisabled||isPlaceOrderButtonDisabled} onClick={nextStep}>{targetStep ? targetStep : 'PROCEED TO CHECKOUT'}</Fab> */}
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </React.Fragment>
        );
      }
      else {
        return null;
      }
    }
  }
}


class ProductsInOrder extends React.Component {
  render() {
    let discountFlag = false;
    let discountTotal = 0;
    const { itemsInfo, classes } = this.props;
    let productsInOrder = itemsInfo.items ? itemsInfo.items : '';
    let additional_info = '';
    productsInOrder.length > 0 && productsInOrder.map((item, index) => {
      additional_info = item.extension_attributes.additional_skus;
    })
    if (productsInOrder && productsInOrder.length) {
      return (
        <React.Fragment>
          {productsInOrder.map((item, index) => {
            if (parseInt(item.base_discount_amount) > 0) {
              discountFlag = true;
              discountTotal = discountTotal + Number(item.base_discount_amount)
            }
            return (
              <div key={index}>
                <Grid container direction='row' justify='space-between' key={index}>
                  <Grid item xs={3} sm={2} lg={1} className="print-order">
                    {window.location.href.includes('/checkout') ?
                      <Typography variant="body2" gutterBottom style={{ textAlign: "left", fontSize: "0.800rem" }}>{parseInt(item.extension_attributes.sku)}</Typography>
                      : <Typography variant="body2" gutterBottom style={{ textAlign: "left", fontSize: "0.800rem" }}>{parseInt(item.sku)}</Typography>}
                  </Grid>
                  <Grid item xs={3} sm={3} lg={2} className="print-order">
                    <Typography variant="body2" style={{ fontSize: "0.800rem" }} gutterBottom >{item.name}</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={1} className="print-order">
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{item.qty_ordered ? item.qty_ordered : item.qty}</Typography>
                  </Grid>
                  {discountFlag && salesmanInfo ?
                    <Grid item xs={2} sm={2} lg={1} className="print-order">
                      <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{formatPriceOfProductforCategoryListing(item.base_discount_amount)}</Typography>
                    </Grid> :
                    <Grid item xs={2} sm={2} lg={1} className="print-order">
                      <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{formatPriceOfProductforCategoryListing(item.base_discount_amount)}</Typography>
                    </Grid>
                  }
                  {window.location.href.includes('/vieworder') ?
                    Math.round(item.extension_attributes.ecc_item_qty) === 0 ? '' :
                      <Grid item xs={2} sm={2} lg={1} className="print-order">
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center" }}>{parseInt(item.extension_attributes.ecc_item_qty)}</Typography>
                      </Grid> : ''}
                  <Grid item xs={2} sm={2} lg={2} className="print-order">
                    <Grid container >
                      <Grid item className="price-wrapper print-order">
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{formatPriceOfProductforCategoryListing(item.price)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {window.location.href.includes('/vieworder') ?
                    Math.round(item.extension_attributes.ecc_item_total) === 0 ? '' :
                      <Grid item xs={2} sm={2} lg={2} className="print-order">
                        <Grid container >
                          <Grid item className="price-wrapper print-order">
                            <Typography variant="body2" gutterBottom style={{ textAlign: "center" }}>{formatPriceOfProductforCategoryListing(item.extension_attributes.ecc_item_total)}</Typography>
                          </Grid>
                        </Grid>
                      </Grid> : ''}
                  <Grid item xs={2} sm={3} lg={2} className="print-order">
                    <Grid container justify='flex-end'>
                      <Grid item>
                        {window.location.pathname.includes('ordersuccess') || window.location.pathname.includes('vieworder') ?
                          <Typography variant="body2" style={{ fontSize: "0.800rem" }} gutterBottom>{formatPriceOfProductforCategoryListing(item.price * item.qty_ordered)}</Typography>
                          : <Typography variant="body2" style={{ fontSize: "0.800rem" }} gutterBottom>{formatPriceOfProductforCategoryListing(item.price * item.qty)}</Typography>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {index !== (productsInOrder.length - 1) ? <Divider /> : null}
              </div>
            )
          })}
        </React.Fragment>
      );
    }
    else {
      return null;
    }

  }
}
class AdditionalProducts extends React.Component {
  render() {
    let discountFlag = false;
    let discountTotal = 0;
    const { itemsInfo, classes } = this.props;
    if (itemsInfo && itemsInfo.length) {
      return (
        <React.Fragment>
          {itemsInfo.map((item, index) => {
            if (parseInt(item.base_discount_amount) > 0) {
              discountFlag = true;
              discountTotal = discountTotal + Number(item.base_discount_amount)
            }
            return (
              <div key={index}>
                <Grid container direction='row' justify='space-between' key={index} style={{ backgroundColor: '#D3D3D3' }}>
                  <Grid item xs={3} sm={2} lg={1} className="print-order">
                    {window.location.href.includes('/checkout') ?
                      <Typography variant="body2" gutterBottom style={{ textAlign: "left", fontSize: "0.800rem" }}>{parseInt(item.extension_attributes.sku)}</Typography>
                      : <Typography variant="body2" gutterBottom style={{ textAlign: "left", fontSize: "0.800rem" }}>{parseInt(JSON.parse(item).sku)}</Typography>}
                  </Grid>
                  <Grid item xs={3} sm={3} lg={2} className="print-order">
                    <Typography variant="body2" style={{ fontSize: "0.800rem" }} gutterBottom >{JSON.parse(item).name}</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={1} className="print-order">
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{JSON.parse(item).ordered_qty ? JSON.parse(item).ordered_qty : 0}</Typography>
                  </Grid>
                  {discountFlag && salesmanInfo ?
                    <Grid item xs={2} sm={2} lg={1} className="print-order">
                      <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{formatPriceOfProductforCategoryListing(item.base_discount_amount)}</Typography>
                    </Grid> :
                    <Grid item xs={2} sm={2} lg={1} className="print-order">
                      <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{formatPriceOfProductforCategoryListing(0)}</Typography>
                    </Grid>
                  }
                  {window.location.href.includes('/vieworder') ?
                    Math.round(JSON.parse(item).confirmed_qty === 0) ? '' :
                      <Grid item xs={2} sm={2} lg={1} className="print-order">
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center" }}>{parseInt(JSON.parse(item).confirmed_qty)}</Typography>
                      </Grid> : ''}
                  <Grid item xs={2} sm={2} lg={2} className="print-order">
                    <Grid container >
                      <Grid item className="price-wrapper print-order">
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", fontSize: "0.800rem" }}>{formatPriceOfProductforCategoryListing(JSON.parse(item).price)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {window.location.href.includes('/vieworder') ?
                    <Grid item xs={2} sm={2} lg={2} className="print-order">
                      <Grid container >
                        <Grid item className="price-wrapper print-order">
                          <Typography variant="body2" gutterBottom style={{ textAlign: "center" }}>{formatPriceOfProductforCategoryListing(JSON.parse(item).row_total)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid> : ''}
                  <Grid item xs={2} sm={3} lg={2} className="print-order">
                    <Grid container justify='flex-end'>
                      <Grid item>
                        {window.location.pathname.includes('ordersuccess') || window.location.pathname.includes('vieworder') ?
                          <Typography variant="body2" style={{ fontSize: "0.800rem" }} gutterBottom>{formatPriceOfProductforCategoryListing(JSON.parse(item).item_total)}</Typography>
                          : <Typography variant="body2" style={{ fontSize: "0.800rem" }} gutterBottom>{formatPriceOfProductforCategoryListing(JSON.parse(item).item_total)}</Typography>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {index !== (itemsInfo.length - 1) ? <Divider /> : null}
              </div>
            )
          })}
        </React.Fragment>
      );
    }
    else {
      return null;
    }

  }
}
//returns total product count
function getProductTotalCount(state_get_minicart_details) {
  if (state_get_minicart_details) {
    return state_get_minicart_details.cart_product_count
  }
  else {
    return 0;
  }
}

const mapStateToProps = state => ({
  miniCartDetails: state.get_minicart_details,
  order_summary_details: state.get_order_summary_details.orderSummaryDetails,
  cart_product_count: getProductTotalCount(state.get_minicart_details),
  //place order status
  isPlaceOrderButtonDisabled: state.put_place_order_state.isPlaceOrderButtonDisabled,
});
const mapDispatchToProps = dispatch => ({
  displayMiniCart: () => {
    let cartService = new CartService();
    dispatch(cartService.GetDisplayMiniCartActionCreator());
  },
  onDeleteItemFromCart: (item_id, cartId) => {
    let cs = new CartService();
    dispatch(cs.DeleteFromCartActionCreator(item_id, cartId));
  },
});


const OrderSummaryWithStyles = withStyles(styles)(OrderSummary);
export default connect(mapStateToProps, mapDispatchToProps)(OrderSummaryWithStyles);