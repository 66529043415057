/**
* RegisteredMobileNumberForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Swathy Sagar <swathy.sagar@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PageTitle from '../../../../app/components/common/PageTitle';
import Input from '@material-ui/core/Input';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import RegistrationService from '../../../../app/modules/registration/RegistrationService';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
    button: {
        borderRadius: '20px',
        width: '150px',
        alignItems: 'center',
        justify: 'center'
    },
    mobileNumField: {
        width: '200px',

    }
});

/***************************************************************
 * Component class for entering registered Mobile Number.
 * On submit user can able to create a new account
 * @author   Swathy Sagar <swathy.sagar@embitel.com>
 ***************************************************************/

class MobileNumberForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNumber: "9876543210",
            vaild: false,
            mobileValidationText: '',
            customerId: '5',
            token: "",
            resetPasswordToken: '',
        };
    }
    componentDidMount() {

        var queryParams = new URLSearchParams(this.props.location.search);
        let resetPasswordToken = queryParams.get('token');
        let resetPasswordId = queryParams.get('id');
        let resetPasswordMobile = queryParams.get('mobile_no');
        this.setState({ customerId: resetPasswordId });
        this.setState({ resetPasswordToken: resetPasswordToken });
        this.setState({ mobileNumber: resetPasswordMobile });

    }
    //call dispatch function if mobile number is valid and not empty
    handleMobileNumber = (event) => {
        event.preventDefault();
        if ((this.state.mobileNumber === "")) {
            this.setState({
                mobileValidationText: 'Invalid mobile number'
            })
        }
        else {
            this.setState({
                valid: true
            })
            //Dispatch action
            this.props.onSubmitMobileNumDispatch(
                this.state.mobileNumber,
                this.state.customerId
            );
        }
    };

    render() {
        const { classes, registeredMobileNumberSuccess, isGetOTPButtonDisabled } = this.props;
        if (localStorage.getItem('shopce-user')) {
            NotificationManager.error("You've already logged into your account. Please reset your password in My Account section");
            return <Redirect push to='/' />;
        }
        if (registeredMobileNumberSuccess) {
            return <Redirect replace to={{ pathname: '/mobileOTP', state: { id: this.state.customerId, token: this.state.resetPasswordToken,mobileNumber:this.state.mobileNumber, } }} />;
        }
        return (
            <React.Fragment>
                <PageTitle title="Registered Mobile Number" />
                <Grid container alignItems="center" justify="center">
                    <Grid item xs sm={8} md={5} lg={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleMobileNumber}>
                                    <Grid container spacing={16} direction="column" alignItems='center' justify='center'>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                {/* <InputLabel>Mobile Number</InputLabel> */}
                                                <Input id="adornment-signUpPassword" type='tel'
                                                    value={this.state.mobileNumber} disabled={true}
                                                    required={true}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab size="medium" disabled={isGetOTPButtonDisabled} variant="extended" type='submit'>Send OTP</Fab>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    registeredMobileNumberSuccess: state.post_registered_mobnum_state.registeredMobileNumberSuccess,
    isGetOTPButtonDisabled: state.post_registered_mobnum_state.isGetOTPButtonDisabled,
    registeredMobileNumberError: state.post_registered_mobnum_state.errorMessage ? state.post_registered_mobnum_state.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    onSubmitMobileNumDispatch: (
        mobileNumber,
        customerId
    ) => {
        var RegisteredMobNumObject = {
            "mobileNumber": mobileNumber,
            "customerId": customerId
        };
        let rs = new RegistrationService();
        dispatch(rs.RegisteredMobNumActionCreator(RegisteredMobNumObject));
    },
}
);

const MobileNumberFormWithStyles = withStyles(styles)(MobileNumberForm);
export default connect(mapStateToProps, mapDispatchToProps)(MobileNumberFormWithStyles);