/**
* Main Parent Component with theme applied.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//Theme configuration
import { MuiThemeProvider } from '@material-ui/core/styles';
//Theme configuration selection import 
import activeTheme from './theme/ThemeSelector';
//Redux related imports
import magnaStore from './app/framework/redux-store/MagnaStore';
import { Provider } from 'react-redux';
import errorHandler from '../src/app/framework/error/ErrorHandler';
import axiosClient from './app/framework/config/AxiosClient';
import ScrollToTop from './app/components/pages/ScrollToTop';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';
import ErrorBoundary from 'react-error-boundary';
import ErrorPage from './app/components/error/ErrorPage';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-notifications/lib/notifications.css';
import magnaCookies from './app/framework/cookie/magnaCookies';
import { NotificationContainer } from 'react-notifications';
import Loader from './app/components/common/Loader';
// import i18n (needs to be bundled ;))
import './i18n';


/***************************************************************************** 
 * Parent component with theme applied.
 * The BrowserRouter is passed forceReferesh={true} to enable 
 * proper navigation on click of <Link> in the App 
 * (Now removed the same).
 * Note:
 * - The Router attribute of forceRefresh is removed.
 * - The Main App component is included as Route to have 
 * browser back and forward buttons working.
 * With the above two changes the App should have only DOM that is 
 * changed is rendered and browser back and forward buttons working.
 * Error Handler (for status codes like 404 and 500 is added for
 * to the parent app component.)
 * - Scroll to Top wrapper component is placed so that page transitions 
 * work for whole of the app by going to the top of the page.
 *****************************************************************************/


if (!magnaCookies.getCookie('ECS_AC')) {
    localStorage.removeItem('shopce-user');
}

if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'en');
}

function AppWithTheme() {
    return (
        <Suspense fallback="loading">
            <Provider store={magnaStore}>
                <Router>
                    <ScrollToTop>
                        <MuiThemeProvider theme={activeTheme}>
                            <Loader />
                            <NotificationContainer />
                            <Route component={errorHandler(App, axiosClient)} />
                        </MuiThemeProvider>
                    </ScrollToTop>
                </Router>
            </Provider>
        </Suspense>
    );
}

/****
 * Main App Root component with Error Boundary
 */
function AppWithThemeWithErrorBoundary() {
    return (
        <ErrorBoundary FallbackComponent={AppErrorFallbackComponent}>
            <AppWithTheme />
        </ErrorBoundary>
    );
}

/***Error Boundary Fallback Component */
const AppErrorFallbackComponent = ({ componentStack, error }) => {
    return (
        <InternalServerError500 />
    );
}


/********************************************************************************************f 
* 50x Error Page Component for Main App Component
*******************************************************************************************/
const InternalServerError500 = (props) => (
    <div>
        <ErrorPage
            header="Oops!"
            text="Something went wrong in the application. We are working on the problem." />
    </div>
);

ReactDOM.render(<AppWithThemeWithErrorBoundary />, document.getElementById('root'));
registerServiceWorker();
