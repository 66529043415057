/**
* Template for preparing Pages without Header and Footer (Empty).
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

});

/**************************************************************
 * This component renders a Page with the props it receives. 
 * Acts as a template without Header and Footer.
 * Does not include a Header,Footer and Navigation Bar. 
 * Renders the page blocks passed as an array.   
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *************************************************************/
class Empty extends Component {

    render() {        
        const { blocks } = this.props;
        return (
            <Fragment>
                {blocks && blocks.map((Block, i) => {
                    return <Block key={i}>{this.props.children}</Block>
                })}
            </Fragment>
        );
    }
}
Empty.propTypes = {
    classes: PropTypes.object.isRequired,    
    blocks: PropTypes.array
};

export default withStyles(styles)(Empty);