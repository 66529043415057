/**
* MiniCart Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Counter from '../../../app/components/common/Counter';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { formatPriceOfProduct } from '../../../app/framework/common/processString';
import { Link } from 'react-router-dom';
//Redux related
import { connect } from 'react-redux';
import { decodeHTMLEntities } from '../../../app/framework/common/helpers';
//Image dimensions fetched from configuration.
import getConfigValue from '../../../app/framework/config/configHandler';
import { mediaURLBasePath } from '../../../app/framework/common/helpers';
//Price display formatting
import { formatPriceOfProductforCategoryListing } from '../../../app/framework/common/helpers';
import CartService from '../../../app/modules/cart/CartService';
import ViewCartButton from './ViewCartButton';
import CheckoutButton from './CheckoutButton';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import GetLanguage from '../../../library/GetLanguage';
import DeleteImg from '../../../../src/assets/images/Delete.svg';
const styles = theme => ({
    root: {
        padding: '0.5rem',
        minWidth: '350px',
        maxHeight:'285px',
        overflowY:"auto"
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: '9999',
    },
    viewminiCartButton: {
        whiteSpace: 'pre',
    },
    miniCartRoot: {
        padding: '0.5rem 0rem',
    },
    miniCartSubTotal: {
        padding: '0.4rem 0rem',
    },
    miniCartButtons: {
        padding: '0rem 0.4rem 0rem 0.4rem',
    },
    image: {
        width: 75,
        height: 80,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    noItems: {
        padding: '1rem',
    },
    textDecorationNone: {
        textDecoration: 'none',
    },
    productDescription: {
        maxWidth: '200px'
    },
    qtyTxt: {
        fontSize: '12px',
        padding: '5px 0',
        color: '#a4a0a0',
    },
    miniDropdown: {

    }
});
const theme = createMuiTheme({
    overrides: {
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            },
        },
        MuiBackdrop: {
            root: {
                background: 'none !important',
            },
        },
        MuiDrawer: {
            paper: {
                boxShadow: 'none',
                border: '1px solid #ebebeb',
                margin: '0 25px 0 0',
                top: '135px',
                height: 'inherit',
            },
        },
        MuiAppBar: {
            colorPrimary: {
                background: 'none !important',
            }
        },
        MuiFab: {
            label: {
                textTransform: 'uppercase',
                fontSize: '12px',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'uppercase !important',
                fontSize: '12px  !important',
            },
        },
        MuiTabs: {
            root: {

            },
            indicator: {
                height: '0',
            },
            flexContainer: {
                width: '100%',
                background: 'none !important',
            }
        },
        MuiTab: {
            root: {
                width: '50%',
            },
            selected: {
                color: '#1d2d5f !important',
                background: '#e6f4ff !important',
            }
        }
    }
});
/***************************************************************
 * Component class for displaying Mini Cart
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class MiniCart extends React.Component {
    state = {
        selectedCartTab: 0
    }
    handleMiniShoppingCartClose = () => {
        this.props.onCloseMiniShoppingCart();
    }
    render() {
        const { classes, miniShoppingCartOpen, onClose } = this.props;
        const { miniCartDetails, cart_product_count } = this.props;
        const hpcLabel = 'HPC(' + miniCartDetails.hpcCount + ')';
        const foodsLabel = 'Foods(' + miniCartDetails.foodsCount + ')';
        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Drawer className={classes.miniDropdown} xs={12} sm={8} md={6} anchor="right"
                        open={miniShoppingCartOpen} onClose={onClose}>
                        {miniCartDetails && miniCartDetails["miniCartDetails"] &&

                            <AppBar position="static">
                                <Tabs value={miniCartDetails.current_cart_index} onChange={(e, selectedCartTab) => this.props.changeCart(selectedCartTab)} aria-label="simple tabs example">
                                    <Tab style={{ background: "#f8f8fa", color: '#a4a0a0' }} label={hpcLabel} />
                                    <Tab style={{ background: "#f8f8fa", color: '#a4a0a0' }} label={foodsLabel} />
                                </Tabs>
                            </AppBar>
                        }
                        <div className={classes.root}>
                            {/* <div className={classes.closeIcon}>
                            <IconButton onClick={this.handleMiniShoppingCartClose}>
                                <CloseIcon />
                            </IconButton>
                        </div> */}
                            {
                                miniCartDetails && miniCartDetails["miniCartDetails"] && miniCartDetails["miniCartDetails"].map((product, index) =>

                                    <ShoppingCartItemsMiniCart key={index} productName={product.name}
                                        image_url={product.extension_attributes ? product.extension_attributes.image_url : ''}
                                        product_option={product.product_option ? product.product_option : null}
                                        product_url={product.product_url}
                                        cartIndex={miniCartDetails.current_cart_index}
                                        sku={product.sku} item_id={product.item_id}
                                        itemPrice={formatPriceOfProduct(product.price)}
                                        quantity={parseInt(product.qty)} onDeleteItemFromCart={() => { }}
                                        {...this.props}>
                                    </ShoppingCartItemsMiniCart>
                                )
                            }

                        </div>
                        <MiniCartSubTotal {...this.props} />
                        {(cart_product_count > 0) ? <ViewAndEditCart {...this.props} /> : ""}
                    </Drawer>
                </MuiThemeProvider>
            </div>
        );
    }
}

/***************************************************************
 * Component class for displaying Mini Cart Buttons
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ViewAndEditCart extends React.Component {
    render() {
        return (
            <Grid container direction='row' justify='space-between'>
                <Grid item>
                    <CheckoutButton />
                </Grid>
                <Grid item>
                    <ViewCartButton />
                </Grid>
            </Grid>
        );
    }
}

/***************************************************************
 * Component class for displaying Mini Cart Subtotal
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class MiniCartSubTotal extends React.Component {
    render() {
        let discountFlag = false;
        let discountTotal = 0;
        const { classes } = this.props;
        const { miniCartDetails } = this.props;
        if (Object.keys(miniCartDetails).length && miniCartDetails.miniCartDetails.length) {
            miniCartDetails.miniCartDetails.map(item => {
                if (parseInt(item.base_discount_amount) > 0) {
                    discountFlag = true;
                    discountTotal = discountTotal + Number(item.base_discount_amount);
                }

            })

            return (
                <React.Fragment>
                    <Grid container direction="row" justify='space-between'>
                        <Grid item>
                            <Typography gutterBottom>{<GetLanguage value="Subtotal" />}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom>
                                {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.subtotal : 0)}
                            </Typography>
                        </Grid>
                    </Grid>

                    {discountFlag ?

                        <Grid container direction="row" justify='space-between'>
                            <Grid item>
                                <Typography gutterBottom>{<GetLanguage value="Discount" />}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom>
                                    {formatPriceOfProduct(discountTotal)}
                                </Typography>
                            </Grid>
                        </Grid> : ''}
                    {discountFlag ? <Divider /> : ''}
                    <Grid container direction="row" justify='space-between'>
                        <Grid item>
                            <Typography gutterBottom>{<GetLanguage value="Tax" />}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom>
                                {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.tax_amount ? miniCartDetails.current_cart.tax_amount : 0 : 0)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container direction="row" justify='space-between'>
                        <Grid item>
                            <Typography gutterBottom>{<GetLanguage value="Total" />}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom>
                                {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.grand_total : 0)}
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        return (
            <Grid container direction="row" alignItems="flex-start" justify="center">
                <Typography style={{color:'#1d2d5f',fontSize:'15px'}}>:( Your cart is empty</Typography>
                <Typography className={classes.noItems}>{<GetLanguage value="To add a product to the cart, 
browse the store and select 
“Add to cart”" />}</Typography>
            </Grid>
        );
    }
}

/***************************************************************
 * Component class for displaying Mini Cart items
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ShoppingCartItemsMiniCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productQuantity: 1,
            qty: this.props.quantity
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ qty: nextProps.quantity })
    }
    //Callback after setting the value in Counter Component
    onQuantiyCounterChange = (quantityChanged, item_id) => {
        this.setState({ productQuantity: quantityChanged });

        //Dispatch to change the quantity in the cart.
        this.props.onEditItemFromCart(quantityChanged, item_id);
    }

    processProductOptions = (product_option) => {
        if (product_option === null) return "";
        let configurable_item_options_list = product_option.extension_attributes.configurable_item_options;
        let productOptionsString = [];
        for (let index = 0; index < configurable_item_options_list.length; index++) {
            productOptionsString.push(configurable_item_options_list[index].value);
        }
        //Return product option string with separators.
        return productOptionsString.join(" / ");
    }
    render() {
        const { classes } = this.props;
        const {
            productName,
            cartIndex,
            itemPrice,
            quantity,
            item_id,
            image_url,
            product_url,
            onDeleteItemFromCart
        } = this.props;
        const orderParameters = getConfigValue("order");
        return (
            <React.Fragment>
                <Grid container className={classes.miniCartRoot + " " + "counterDivMiniCart"} direction='row' justify='space-evenly'>
                    <Grid item>
                        <ButtonBase className={classes.image}>
                            <img className={classes.img}
                                alt={decodeHTMLEntities(productName)}
                                src={mediaURLBasePath + image_url} />
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify='space-between' alignItems='flex-start'>
                            <Grid item className={classes.productDescription} xs={12}>
                                <Link className={classes.textDecorationNone} to={product_url}>
                                    <Typography>
                                        {decodeHTMLEntities(productName)}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{formatPriceOfProductforCategoryListing(itemPrice)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify='space-between' alignItems='flex-start'>
                            <Grid item xs={3} className={classes.qtyTxt}>Quantity</Grid>
                            <Grid item xs={6}>
                                <Counter item_id={item_id} value={this.state.qty} min_number={1}
                                    cartIndex={cartIndex}
                                    max_number={orderParameters.maxQuantity}
                                    onCounterChange={(quantityChanged) => this.onQuantiyCounterChange(quantityChanged, item_id)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <img style={{ width: '18px', margin: '5px' }} onClick={() => onDeleteItemFromCart(item_id, this.props.miniCartDetails.current_cart.quote_id)} src={DeleteImg} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    order_summary_details: state.get_order_summary_details.orderSummaryDetails,
    miniCartDetails: state.get_minicart_details,
});

const mapDispatchToProps = dispatch => ({
    onDeleteItemFromCart: (item_id, cartId) => {
        let cs = new CartService();
        dispatch(cs.DeleteFromCartActionCreator(item_id, cartId));
    },
    onEditItemFromCart: (quantityChanged, item_id) => {
        let cs = new CartService();
        dispatch(cs.EditQuantityInCartActionCreator(item_id, quantityChanged));
    },
    changeCart: (index) => {

        dispatch({ type: "CHANGE_CART", index });
    },
}
);

const MiniCartWithStyles = withStyles(styles)(MiniCart);
export default connect(mapStateToProps, mapDispatchToProps)(MiniCartWithStyles);