/**
* CartButton Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { withStyles,MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GetLanguage from '../../../library/GetLanguage';

const styles = theme => ({
    viewminiCartButton: {
        whiteSpace: 'pre',        
        border: '1px solid #072D77',
        borderRadius: '20px',
        color:'#072D77',
        textTransform:'uppercase',
        fontSize:'12px',
    },
    
});
const theme = createMuiTheme({
    overrides: {
        
        MuiButton:{
            label:{
                textTransform:'uppercase !important',
                fontSize:'12px  !important',
            },
        },
        MuiSvgIcon:{
            colorSecondary:{
                color:'inherit',
            },
        }
    }
  });
/***************************************************************
 * Component class for displaying Cart Button and submenu 
 * on hover. Submenu includes items added to cart.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ViewCartButton extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
            <Button className={classes.viewminiCartButton}
                component={Link} to="/cart" aria-label="View Cart">
                <ShoppingCartIcon color='secondary' />{<GetLanguage value="View cart"/>}</Button>
                </MuiThemeProvider>
        );
    }
}
const ViewCartButtonWithStyles = withStyles(styles)(ViewCartButton);
export default ViewCartButtonWithStyles;