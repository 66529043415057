/**
* Footer bar including copyright block.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Loop from '@material-ui/icons/Loop';
import CallEnd from '@material-ui/icons/CallEnd';
import CopyRightBar from './CopyRightBar';
import Logo from '../../../../theme/base/assets/icons/magna.svg';
import SocialFb from '../../../../theme/base/assets/icons/socialFb.svg';
import SocialInstagram from '../../../../theme/base/assets/icons/socialInstagram.svg';
import SocialTwitter from '../../../../theme/base/assets/icons/socialTwitter.svg';
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";

const styles = theme => ({
    footerBgColor: {
        padding: '1.5rem 0rem',
        backgroundColor: '#e8e8e8',
    },
    footerDivStyle: {
        marginTop: '2rem',
        padding: '1.5rem 0rem',
        backgroundColor : '#F8F8F8',
        [theme.breakpoints.down('md')]: {
            display:'table'
      },
    },
    showCursor: {
        cursor: 'pointer',
    },
});

/***************************************************************************** 
 * Footer bar along with Copyright Notice.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class Footerbar extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container className={classes.footerDivStyle} alignItems="center" justify="space-evenly" direction="row">
                    <Grid item>
                        <Grid container alignItems="center" direction="column">
                            <Grid item>
                                <LocalShipping color="action"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1">Free Delivery
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">On all orders above $50
                            </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" direction="column">
                            <Grid item>
                                <Loop color="action"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1">Return & Exchange
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">100% money back on return
                            </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" direction="column">
                            <Grid item>
                                <CallEnd color="action"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1">24X7 Support
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">Online Customer Support
                            </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.footerBgColor} container alignItems="center" justify="space-between" direction="column">
                    <Grid item>
                        <Button style={{ backgroundColor: 'transparent' }} component={Link} to="/">
                            <img src={Logo} alt="Logo" width="60" height="60" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={32} alignItems="center" direction="row">
                            <Grid item>
                                <Link to='/about-us' style={{ textDecoration: 'none' }}>
                                    <Typography variant="caption" className={classes.showCursor}>
                                        About
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to='/customer-service' style={{ textDecoration: 'none' }}>
                                    <Typography variant="caption" className={classes.showCursor}>
                                        Service
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to='/privacy-policy-cookie-restriction-mode' style={{ textDecoration: 'none' }}>
                                    <Typography variant="caption" className={classes.showCursor}>
                                        Policy
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to='/faq' style={{ textDecoration: 'none' }}>
                                    <Typography variant="caption" className={classes.showCursor}>
                                        Faq
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                                    <Typography variant="caption" className={classes.showCursor}>
                                        Contact
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={16} alignItems="center" direction="row">
                            <Grid item>
                                <img src={SocialFb} alt="SocialFb" className={classes.showCursor} width="32" height="32" />
                            </Grid>
                            <Grid item>
                                <img src={SocialTwitter} alt="Instagram" className={classes.showCursor} width="32" height="32" />
                            </Grid>
                            <Grid item>
                                <img src={SocialInstagram} alt="Twitter" className={classes.showCursor} width="32" height="32" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CopyRightBar/>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Footerbar);