/**
* Border Properties dispaly component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GetLanguage from '../../../library/GetLanguage';

const styles = theme => ({
   primaryBorderStyle : theme.borderProperties.primary,
   secondaryBorderStyle: theme.borderProperties.secondary,
});

/***************************************************************************** 
 * Class Component to display border properties
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ****************************************************************************/
class BorderPropertiesDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: [],
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <h1>
                    {<GetLanguage value="Sample Border Properties Display"/>}
                </h1>
                <div style={{width:100,height:100}} className={classes.primaryBorderStyle}>
                    <h1>{<GetLanguage value="Tes"/>}t</h1>
                </div>
                <div style={{width:100,height:100}} className={classes.secondaryBorderStyle}>
                    <h1>{<GetLanguage value="Test"/>}</h1>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(BorderPropertiesDisplay);
