/**
* Checkbox Filter Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes, { string } from 'prop-types';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { decodeHTMLEntities } from '../../../../app/framework/common/helpers';
import { withRouter } from "react-router";
import CategoryService from '../../../../app/modules/category/CategoryService';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControlLabelStyle: {
    margin: "-8px",
    fontSize: '14px'
  },
  formControlLabelValue:{
    fontSize:'12px',    
  }
});

/***************************************************************
 * Checkbox Filter Component. To display filter options
 * with checkbox on left side.
 * @author Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CheckboxFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParamsState: new URLSearchParams(this.props.location.search),
      viewMoreObjectIndex: 3,
      isChecked: false,
      viewMoreClicked: false,
      filterTags: this.props.filter_tags.filterTags ? this.props.filter_tags.filterTags : []
    };
  }
  componentWillMount(){
    //Code to display filter chips on refresh
    var filterTags = this.props.filter_tags.filterTags ? this.props.filter_tags.filterTags : []; //get existing filter tags
    let isAnyParam = window.location.href.indexOf('=') > -1 ? true : false; // Check in the URL if any parameters exists
    if (isAnyParam) {
      var url = new URL(window.location.href);
      var BrandValue = url.searchParams.get("Brand");// Check if the filter already exists for Brands or Size
      var SizeValue = url.searchParams.get("Size");
      var categoryValue = url.searchParams.get("Category");// Check if the filter already exists for Category
      if (this.props.optionValues) {
        var filterSubOptions = Object.values(this.props.optionValues);
        var filterOptionKeys = Object.keys(filterSubOptions[0]);
        var filterOptionValues = Object.values(filterSubOptions[0]);
      }
      // if (categoryValue !== null) {
      //   let categoryTags = JSON.parse(localStorage.getItem('categoryTags'));
      //   filterTags.push(categoryTags)
      // }
      let flag =0;
      if (BrandValue !== null || SizeValue !== null) {
        //if filters is already applied update filter tag array with existing values
        filterOptionKeys.map((key, index) => {
          if (BrandValue !== null) {
            if (key === BrandValue) {
              var query = 'Brand' + '=' + key;
              filterTags.map(item=>{
                let to_compare = { filterName: filterOptionValues[index], queryArray: query };
                if(JSON.stringify(item)=== JSON.stringify(to_compare )){
                  flag = 1;
                }
                
              })
              if(flag ===0){
                filterTags.push({ filterName: filterOptionValues[index], queryArray: query });//data for filter tags
              }
              
            }
          }
          if (SizeValue !== null) {
            if (key === SizeValue) {
              var query = 'Size' + '=' + key;
              filterTags.map(item=>{
                if(item==={ filterName: filterOptionValues[index], queryArray: query }){
                  flag = 1;
                }
              })
              if(flag === 0){
                filterTags.push({ filterName: filterOptionValues[index], queryArray: query });//data for filter tags
              }
            }
          }

        })
      }
    }
    else{
      //if there is no filters at all clear all existing filter tags
      filterTags =[];
    }
    localStorage.removeItem('categoryTags')
    this.props.setFilterTags(filterTags);//if filter exists for 
  }
  componentDidMount() {
    Object.values(this.props.optionValues).map(value => {
      Object.values(value).map(key => {
        let searchTerm = this.props.location.state ? this.props.location.state.searchTerm : '';
        let search = localStorage.getItem('searchTerm');
        if (!(/\d/.test(key)) && typeof (key) == 'string') {
          if (search === key.toLowerCase() && !this.state.isChecked) {
            //this.setState({ isChecked: true })
          }
        }
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    Object.values(this.props.optionValues).map(value => {
      Object.values(value).map(key => {
        let searchTerm = nextprops.location.state ? nextprops.location.state.searchTerm : this.props.location.state ? this.props.location.state.searchTerm : '';
        let search = localStorage.getItem('searchTerm');
        if (!/\d/.test(key) && typeof (key) == 'string') {
          if (search === key.toLowerCase() && !this.state.isChecked) {
            //this.setState({ isChecked: true })
          }
        }
      })
    })
  }

  // To change checked option of checkboxes
  handleChange = (filterObject, filterName) => event => {
    let queryParams = new URLSearchParams(this.props.location.search);
    let filterTags = this.props.filter_tags.filterTags ? this.props.filter_tags.filterTags : []; //get existing filter tags
    let filterOptionName = filterObject.filterOptionName;
    let query = '' + filterOptionName + '=' + event.target.id;//format - Brand=35/Size=100g
    filterTags.map((item, index) => {
      let filterAlreadyExists = item.queryArray.includes(filterOptionName);
      let i;
      //To remove unchecked filter option from filterTags data
      if (filterAlreadyExists) {
        i = index;
        filterTags.splice(i, 1);
      }
    })
    //this.state.filterTags.push(filterName);
    // if (queryParams.has(filterObject.filterOptionName)) {
    //   //get all filter option values of filterOptionName
    //   let existingParamsArray = queryParams.get(filterObject.filterOptionName).split('_');
    //   //maintain separate array state
    //   let updatedParamsArray = existingParamsArray;
    //   //check for check/uncheck, if event.target.id already exists
    //   //in the array, then uncheck else check the filter option
    //   if (existingParamsArray.includes(event.target.id)) {
    //     updatedParamsArray = existingParamsArray.filter(item => {
    //       return (item !== event.target.id);
    //     });;
    //   }
    //   else {
    //     updatedParamsArray.push(event.target.id);
    //   }
    //   if (updatedParamsArray.length !== 0) {
    //     //updatedParamsArray contains all the checked filter options
    //     queryParams.set(filterObject.filterOptionName, updatedParamsArray.join('_'));
    //   }
    //   else {
    //     queryParams.delete(filterObject.filterOptionName);
    //   }
    // }
    // else {
    //   queryParams.set(filterObject.filterOptionName, event.target.id);
    // }
    queryParams.set(filterObject.filterOptionName, event.target.id);
    // Push the key/value pairs in an array
    let queryArray = [];
    for (var pair of queryParams.entries()) {
      queryArray.push(pair[0] + '=' + pair[1]);
    }
    filterTags.push({ filterName: filterName, queryArray: query });//data for filter tags
    let filterLocation = '?' + queryArray.join('&');
    this.props.setFilterTags(filterTags);//update the filter tags array in redux store
    this.props.history.push(filterLocation);
  };

  getChecked(key, value, filterName) {
    let url = new URL(window.location.href);
    let search = '';
    if(window.location.href.includes('/search')){
      search = localStorage.getItem('searchTerm');
    }
    let result = url.searchParams.get(key);
    //let filtervalue = result && result.split('_');
    let filterTags = [];
    if (result && result.includes(value)) {
      let query = '' + key + '=' + value;
      //filterTags.push({ filterName: filterName, queryArray: query });//data for filter Tags
      //localStorage.setItem('filterTags',JSON.stringify(filterTags));// storing this for getting the applied filters when refreshed for updating filtertags
      return result && result.includes(value)
    }
    else if(search === filterName.toLowerCase()){
      return(true);
    }
  }
  viewMore = () => {
    if (this.state.viewMoreObjectIndex === 3) {
      let filterSubOptions = Object.values(this.props.optionValues);
      let filterValues = Object.values(filterSubOptions[0]);
      this.setState({ viewMoreObjectIndex: filterValues.length, viewMoreClicked: true });
    }
    else {
      this.setState({ viewMoreObjectIndex: 3, viewMoreClicked: false });
    }
  }
  render() {
    const { classes, optionValues, filterObject } = this.props;
    if (optionValues) {
      var filterSubOptions = Object.values(optionValues);
    }
    let filterOptionCount = Object.values(filterSubOptions[0]).length
    return (
      <FormControl component="fieldset" className={classes.root}>
        <FormGroup>
          {filterSubOptions && filterSubOptions.map((optionKey) => {
            let filterValues = Object.keys(optionKey);
            return filterValues && filterValues.map((filterId, innerIndex) => {
              let filterName = optionKey[filterId];
              if (innerIndex > this.state.viewMoreObjectIndex)
                return null;
              return (
                <FormControlLabel className={classes.formControlLabelStyle} key={'mykey' + filterId}
                  control={
                    <Radio labelStyle={{fontSize: '12px'}} id={filterId} value={filterName} disableRipple={true} name='filters'
                      checked={this.getChecked(filterObject.filterOptionName, filterId, filterName) ? true : this.state.isChecked}
                      onChange={this.handleChange(filterObject, filterName)} style={{ padding: '1rem 0.5rem' }} />
                  }
                  label={decodeHTMLEntities(filterName)}
                />
              );
            })
          })}
        </FormGroup>
        <Button color={this.state.viewMoreObjectIndex >= 4 ? 'primary' : 'secondary'} size="small"
          onClick={() => this.viewMore()}>{filterOptionCount <= 4 ? '' : filterOptionCount > 4 && !this.state.viewMoreClicked ? 'View More' : 'View Less'}</Button>
      </FormControl>
    );
  }
}
const mapStateToProps = state => ({
  filter_tags: state.get_filter_tag_chips
})
const mapDispatchToProps = dispatch => ({
  setFilterTags: (filterTags) => {
    let cs = new CategoryService();
    dispatch(cs.getFilterTagsDataActionCreator(filterTags));
  }
});

CheckboxFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CheckboxFilterWithStyles = withStyles(styles)(CheckboxFilter);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckboxFilterWithStyles));