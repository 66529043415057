/**
* MagnaAPIHandler Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import api from '../../framework/config/AxiosClient';
import getConfigValue from '../../framework/config/configHandler';

/***************************************************************
 * This handler allows you to make Magna API requests 
 * and send back the response.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
const MagnaAPIHandler = (requestURL, requestType, requestBody, apiLevelConfig) => {
    //By default method will be GET unless untill there's some requestType
    let method = requestType ? requestType : 'GET';
    //only POST requests contain body
    let data = requestBody.data ? requestBody.data : '';
    //append user token if exists
    let AUTH_TOKEN = requestBody.header ? requestBody.header : '';
    if (AUTH_TOKEN !== '') {
        api.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    }
    const systemParameters = getConfigValue("system");
    //If apiLevelConfig is not passed as argument, it is taken as 'LOW' apiLevel.
    let apiLevelConfigParam = (typeof (apiLevelConfig) === 'undefined') ?
        { apiLevel: systemParameters.api.error.levels.LOW } : apiLevelConfig;
    return api({
        method,
        url: requestURL,
        data,
        validateStatus: (status) => {
            return status >= 200 && status < 300;
        },
        apiLevelConfigParam
    }).then((response) => {
        return response;
    })
}

export default MagnaAPIHandler;