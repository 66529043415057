/**
* Product Catalog Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { mediaURLBasePath } from '../../../../app/framework/common/helpers';
import { decodeHTMLEntities } from '../../../../app/framework/common/helpers';
import ImagePlaceHolder from '../../../../assets/images/Placeholder.jpg';
//Number of products per API call and Product Image dimensions are taken 
//from configuration.
import getConfigValue from '../../../../app/framework/config/configHandler';
import CategoryService from '../../../../app/modules/category/CategoryService';
//Redux related imports
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Counter from '../../../../app/components/common/Counter';
import ProductAddToCart from '../../../../app/components/common/ProductAddToCart';
import WishlistService from '../../../../app/modules/wishlist/WishlistService';
import UnitSelector from '../../uom/UnitSelector';
import PriceDisplay from './../../container/PriceDisplay';
import RequisitionButton from '../../requisition/RequisitionButton';
import classNames from 'classnames';
import LocalOffer from '@material-ui/icons/LocalOffer';
import GetLanguage from '../../../../library/GetLanguage';

/***************************************************************
 * Component class for Product Catalog
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
const styles = theme => ({
    productDiv: {
        position: 'relative',
        border: '1px solid #fff',
        flex: 1,
        margin: '8px',
        cursor: 'pointer',
        flex: '1',
        cursor: 'pointer',
        background: '#fff',
        // minHeight: '355px' //changing for thailand product tile
    },
    s_priceStyle: {
        fontWeight: 'bold',
        color: '#1d2d5f',
        fontSize: '20px'
    },
    titleText: {
        fontSize: '14px',
        color: '#424242',
        minHeight: '2.5rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        'display': '-webkit-box',
        padding: '5px',
        textAlign: 'center',
        fontWeight: 'normal',
 
    },
    titleAnchor: {
        textDecoration: 'none',
        marginTop: '0.5rem',
    },
    outOfStockMain: {
        position: 'absolute', top: '0px', width: '100%', height: '84%', cursor: 'no-drop', opacity: '0.888'
    },
    outOfStockInner: {
        color: '#fff',
        lineHeight: '1.5',
        fontWeight: 'bold',
        padding: '30px',
        fontSize: '14px'
    },
    outofstockoutside: {
        width: '120px',
        margin: 'auto',
        transform: 'translate(0%, 80%)',
        background: '#6a81ad',
        borderRadius: '50%',
        textAlign: 'center',
        height: '120px'
    },
    offerStyle: {
        padding: '0.2rem 0.1rem',
        background: '#69C1FC',
        borderRadius: '0px 8px 8px 0px',
    },
    soqStyle: {
        paddingRight: '10px',
        fontWeight: 'bold',
        color: '#1d2d5f',
        margin: '10px',
        fontSize: '15px'
    },
    maxImageWidth: {
        maxWidth: '150px',
        padding: '10px 0px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: "80px",
            height: "auto",
            maxHeight: "80px"
        },
    },
    counterAlign: {
        marginLeft: '5px'
    },
    productBtnSec :{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        maxWidth: '90%',
        margin: 'auto',
    },
    caseUnit:{
        fontSize: '.80rem',
    }
});


class ProductCatalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productQuantity: 1
        }
    }

    onQuantiyCounterChange = (quantity) => {
        this.setState({ productQuantity: quantity });
    }
    productDivClicked(url) {
        window.location = url
    }
    render() {
        let { classes, plpViewSelected, targetURLPath } = this.props;
        const { productTile } = this.props;
        let custom_attributes = productTile.custom_attributes;
        var conversion_units ='';
        custom_attributes.map((item)=>{
            if(item.attribute_code==='conversion_units'){
                conversion_units = item.value;
            }
        })
        const categoryImgDimensions = getConfigValue("category");
        const orderParameters = getConfigValue("order");
        let pdpURLKey = productTile.url_key ? productTile.url_key : CategoryService.getProductDetailURL(productTile.custom_attributes);
        /**
         * Check if Add to Cart feature is enabled in configuration
         */
        const is_in_stock = productTile && (productTile.extension_attributes && productTile.extension_attributes.is_in_stock === false || productTile.is_in_stock === false || productTile.extension_attributes && productTile.extension_attributes.is_in_stock === '0' ? false : true)
        const productParameters = getConfigValue("product");
        let configEnableAddtoCart = productParameters.enableAddtoCart;
        if (productTile && typeof productTile !== undefined) {
            let inStockStatus = productTile.extension_attributes ? productTile.extension_attributes.is_in_stock ? true : false : false;
            return (
                <div className={classes.productDiv}>
                    {/* <div className={classes.productDiv} onClick={this.productDivClicked.bind(this,pdpURLKey)}> */}
                    <div>
                        <Grid container justify='space-between'>
                        <Grid item  className={classes.soqStyle}  >
                                <RequisitionButton  sku={productTile.sku} plp={true} />
                            </Grid>
                        {
                             localStorage.getItem('shopce-user') && typeof productTile.soq !== 'undefined' &&
                             <Grid item>
                                 <Typography className={classes.soqStyle}>{<GetLanguage value="SOQ" />}: {productTile.soq !== 'NA' ? productTile.soq + ' Cases' : productTile.soq}</Typography>
                             </Grid>                           
                        }
                            
                        </Grid>
                        <Grid item className={classNames(productTile.special_price ? classes.offerStyle : '')}>
                                {productTile.special_price &&
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <LocalOffer color='disabled' />
                                        </Grid>
                                        <Grid item>
                                            <Typography color='textSecondary'>{<GetLanguage value="Offer" />}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>

                        {/* <Link to={productTile.url_key ? productTile.url_key : CategoryService.getProductDetailURL(productTile.custom_attributes)}> */}
                        <Grid container direction={plpViewSelected && !this.props.sliderView ? 'row' : 'column'}>
                        
                            <Grid item xs={plpViewSelected && !this.props.sliderView ? 5 : 12}>
                                <Link to={pdpURLKey}>
                                    <img height={categoryImgDimensions.thImage.dimension.height} className={classes.maxImageWidth}
                                        src={productTile.image ? mediaURLBasePath + productTile.image : getImageURL(productTile.custom_attributes)}
                                        alt={productTile.product_name ? productTile.product_name : productTile.name} />
                                </Link>
                            </Grid>
                            <Grid item xs={plpViewSelected && !this.props.sliderView ? 6 : 12}>
                                <Grid container direction='column' alignItems={plpViewSelected && !this.props.sliderView ? 'flex-start' : 'center'}>
                                    <Grid item>
                                    
                                        <Link to={productTile.url_key ? productTile.url_key : CategoryService.getProductDetailURL(productTile.custom_attributes)} className={classes.titleAnchor}>
                                            {/* Display product name after decoding HTML entities like "Trademark(TM)" */}
                                            <Typography className={classes.titleText}>
                                                {decodeHTMLEntities(productTile.product_name ? productTile.product_name : productTile.name)}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction={plpViewSelected && !this.props.sliderView ? 'column' : 'row'} alignItems='flex-start'>
                                            <Grid item xs={12}>
                                                <Grid container direction='row' justify='space-evenly' alignItems='center'>
                                                    <Grid item >
                                                        <PriceDisplay productDetail={productTile} />
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction='row' justify='space-evenly' alignItems='center'>
                                                    <Grid item>
                                                        <UnitSelector product={productTile} unitSelector={this.state.unitSelector} />
                                                    </Grid>
                                                    <Grid item className={classes.counterAlign}>
                                                        <Counter id={'product_' + productTile.sku}
                                                            product={productTile} min_number={1}
                                                            max_number={orderParameters.maxQuantity}
                                                            onCounterChange={this.onQuantiyCounterChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                          
                                            <Grid item xs={12} className={classes.productBtnSec} >
                                            {conversion_units !== '' ?
                                                <Grid item xs={5}>
                                                    <Typography className={classes.caseUnit} style={styles.uomStyle}>1 CS = {conversion_units} PC</Typography>
                                                </Grid>
                                                : <Grid item xs={5}>
                                                    <br></br>
                                                </Grid>
                                            }
                                            <Grid item xs={7} >
                                                {productTile && configEnableAddtoCart &&
                                                    <ProductAddToCart parent={this} display_mode="list" sku={productTile.sku}
                                                        isProductConfigOptions={productTile &&
                                                            productTile.configurable_options ? true : false
                                                        }
                                                        config_options={productTile &&
                                                            productTile.configurable_options ?
                                                            productTile.configurable_options : null
                                                        }
                                                        is_in_stock={is_in_stock}
                                                        pdpURLKey={pdpURLKey}
                                                        productQuantity={this.state.productQuantity}
                                                        productDetail={productTile}
                                                        isPremiumProduct={getPremiumProduct(productTile.custom_attributes)}
                                                    />
                                                }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                    {
                        !is_in_stock ?
                            <div className={classes.outOfStockMain}>
                                <div className={classes.outofstockoutside}>
                                    <Link style={{ textDecoration: 'none' }} to={pdpURLKey}>
                                        <div className={classes.outOfStockInner}>{<GetLanguage value="OUT" />}<br />{<GetLanguage value="OF" />}<br />{<GetLanguage value="STOCK" />}</div>
                                    </Link></div>
                            </div> : ''
                    }
                </div >
            );
        }
        return null;
    }
}

/*****************************************************************************
* @param {*} customAttributes - Custom attribute received in API Response.
            * mediaURLBasePath is configured in helpers.js.
* @author   Sameena Shaik <shaik.sameena@embitel.com>
                ****************************************************************************/
function getImageURL(customAttributes) {
    for (let index = 0; index < customAttributes.length; index++) {
        if (customAttributes[index].attribute_code === "small_image" && customAttributes[index].value) {
            return mediaURLBasePath + customAttributes[index].value;
        }
        else {
            continue;
        }
    }
    //if there's no image path
    return ImagePlaceHolder;
}

function getPremiumProduct(customAttributes) {
    for (let index = 0; index < customAttributes.length; index++) {
        if (customAttributes[index].attribute_code === "premium_goods" && customAttributes[index].value === "1") {
            return customAttributes[index].value;
        }
        else {
            continue;
        }
    }
    //if there's no image path
    return '';
}


ProductCatalog.propTypes = {
    classes: PropTypes.object.isRequired,
};



const mapStateToProps = state => ({
    wishlistedProducts: state.get_wishlisted_products,
    isFavButtonDisabled: state.put_product_in_wishlist.isFavButtonDisabled ?
        state.put_product_in_wishlist.isFavButtonDisabled :
        (state.delete_product_in_wishlist.isFavButtonDisabled ? state.delete_product_in_wishlist.isFavButtonDisabled : false),
    wishlistSuccess: state.put_product_in_wishlist.wishlistSuccess,
    item_id: state.put_product_in_wishlist.item_id,
    wishlistError: state.put_product_in_wishlist.errorMessage ? state.put_product_in_wishlist.errorMessage : '',
    isWishlistedItemDeleted: state.delete_product_in_wishlist.deleteWishlistItemSuccess,
    deleteWishlistError: state.delete_product_in_wishlist.errorMessage ?
        state.delete_product_in_wishlist.errorMessage : '',
    plpViewSelected: state.plpView,
    targetURLPath: state.targetPathState.targetURLPath
});


const mapDispatchToProps = dispatch => ({
    wishlistProduct: (sku) => {
        let wishlistServiceObject = new WishlistService();
        // dispatch(wishlistServiceObject.ResetDeleteWishlistActionCreator());
        dispatch(wishlistServiceObject.WishlistProductActionCreator(sku));
    },
    getWishlistedProducts: () => {
        let wishlistServiceObject = new WishlistService();
        dispatch(wishlistServiceObject.GetWishlistedProductsActionCreator());
    },
    deleteWishlistedProduct: (id) => {
        let wishlistServiceObject = new WishlistService();
        // dispatch(wishlistServiceObject.ResetPutWishlistActionCreator());
        dispatch(wishlistServiceObject.DeleteWishlistProductActionCreator(id));
    },
    resetPutWishlistInfo: () => {
        let wishlistServiceObject = new WishlistService();
        dispatch(wishlistServiceObject.ResetPutWishlistActionCreator());
    },
    resetDeleteWishlistInfo: () => {
        let wishlistServiceObject = new WishlistService();
        dispatch(wishlistServiceObject.ResetDeleteWishlistActionCreator());
    }

});

const ProductCatalogWithStyles = withStyles(styles)(ProductCatalog);
export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogWithStyles);
