/**
* Component for displaying customer wishlist.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router";
import { Translation } from 'react-i18next';
//Redux related imports
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import WishlistService from '../../../../app/modules/wishlist/WishlistService';
import ApplicationService from '../../../../app/modules/application/ApplicationService';

const styles = theme => ({
    root: {
        zIndex: '1000'
    },
    languagesFontSize: {
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px'
        }
    },
    formControl: {
        minWidth: '120px'
    },
    languagesSelectBox: {
        border: 0,
        [theme.breakpoints.down('sm')]: {
            border: "1px solid #ccc",
            margin: '10px 0',
        },

    }

});


/***************************************************************
 * Component to display customer wishlist
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 **************************************************************/
class Languages extends React.Component {

    constructor(props) {
        super(props);
        // const selectedLanguage = localStorage.getItem('i18nextLng') || 'en';
        this.state = {
            open: false,
            selectedLanguage: localStorage.getItem('i18nextLng') || 'en',
            // // selectedLanguage: localStorage.getItem('i18nextLng') || 'en-GB',
            // selectedLanguage: 'en'
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        // this.props.parseLanguageCSV(this.state.selectedLanguage);
    }
    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };
    //handles closing of menu
    handleClose = () => {
        this.setState({ open: false });
    };
    handleChange = (event, i18n) => {
        this.setState({ selectedLanguage: event.target.value });
        // this.props.parseLanguageCSV(event.target.value);
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('i18nextLng', event.target.value)
        window.location.reload();
    };

    render() {
        const { open, selectedLanguage } = this.state;
        const { classes } = this.props;
        
        return (
            <React.Fragment>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <FormControl>
                            <Translation>
                                {
                                    (t, { i18n }) => <Select open={open} disableUnderline={true} onClose={this.handleClose} onOpen={this.handleToggle}
                                        value={this.state.selectedLanguage}
                                        onChange={(e) => this.handleChange(e, i18n)} className={classes.languagesSelectBox}>
                                        <MenuItem value='en'>
                                            <Typography className={classes.languagesFontSize}>English</Typography>
                                        </MenuItem>
                                        <MenuItem value='th'>
                                            <Typography className={classes.languagesFontSize}>ไทย</Typography>
                                        </MenuItem>
                                    </Select>
                                }
                            </Translation>
                        </FormControl>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    customerWishlistedProducts: state.get_wishlisted_products,
});


const mapDispatchToProps = dispatch => ({
    displayWishlist: () => {
        let wishlistService = new WishlistService();
        dispatch(wishlistService.GetWishlistedProductsActionCreator());
    },
    parseLanguageCSV: () => {
        let appService = new ApplicationService();
        dispatch(appService.parseLanguageCSVActionCreator());
    },
});

Languages.propTypes = {
    classes: PropTypes.object.isRequired,
};

const CustomerWishlistWithStyles = withStyles(styles)(Languages);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerWishlistWithStyles));