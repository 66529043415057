/**
* Component for Image gallery for Product Detail.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';
import ReactImageMagnify from 'react-image-magnify';
import ReactPlayer from 'react-player';

//For React Image Gallery CSS.
import "react-image-gallery/styles/css/image-gallery.css";
import './ProductGallery.css';
import Lightbox from 'react-image-lightbox';
//This only needs to be imported once in your app
import 'react-image-lightbox/style.css';
import Placeholder from '../../../../assets/images/Placeholder.jpg';
import { mediaURLBasePath } from '../../../../app/framework/common/helpers';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    thumbnail: {
        width: '77px',
        marginBottom: '10px'
    },
});

/****************************************************************************** 
 * Component for displaying images of the product.
 * Receives configurable attributes from parent in props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class ProductGallery extends Component {
    constructor(props) {
        super(props);
        let images = {};
        if (props.simple_product_gallery) {
            props.simple_product_gallery.map(obj => {
                if (obj.media_gallery_entries && obj.media_gallery_entries.length) {
                    images[Object.keys(obj.custom_options.variant)[0]] = images[Object.keys(obj.custom_options.variant)[0]] || [];
                    images[Object.keys(obj.custom_options.variant)[0]].push({ original: mediaURLBasePath + obj.media_gallery_entries[0].file, thumbnail: mediaURLBasePath + obj.media_gallery_entries[0].file })
                }
            })
        } else if (props.product_gallery_entries) {
            let video_url;
            props.product_gallery_entries.map(obj => {
                images[0] = images[0] || [];
              
                if (this.props.videoData.length !== 0) {
                    for (const key in this.props.videoData) {
                        video_url = this.props.videoData[key].video_url;
              }
                }
                images[0].push({
                    original: mediaURLBasePath + obj.file,
                    thumbnail: mediaURLBasePath + obj.file,
                    thumbnailClass: this.props.classes.thumbnail,
                    originalClass: this.props.classes.galleryImage,
                });

            })
            if(video_url){
                images[0][images[0].length-1].video_url= video_url;
            }
        }
        this.state = {
            photoIndexLightBox: 0,
            isLightBoxOpen: false,
            imageIndex: 0,
            images: images,
            openModal: false
        }
        this.images_list = [];
        //  this.imagesLightBox_list = [];
    }
    componentWillReceiveProps(nextprops) {
        if (this.props.selectedColor !== nextprops.selectedColor) {
            this.images_list.find((obj, k) => {
                if (obj.color === nextprops.selectedColor) {
                    this.imagegallery.slideToIndex(k);
                }
                return 0;
            })
        }
    }
    // Thumbnails are not to be displayed if there is a single image.
    showThumbnails = (imagesList) => {
        if (typeof imagesList !== 'undefined')
            return (imagesList.length > 0);
    }
    onslide(index) {
        this.setState({ photoIndexLightBox: index });
    }
    handleLightBoxOpen = (e) => {
        // this.setState({ isLightBoxOpen: !this.state.isLightBoxOpen });
    };
    //function to zoom image on hover
    imageHover(item) {
        return (
            item.video_url ?
                (<div className='image-gallery-image'>
                    <div className='video-wrapper'>
                        <ReactPlayer
                            url={item.video_url}
                           width='auto'
                        />
                    </div>
                </div>)
                :
                (<ReactImageMagnify
                    {...{
                        smallImage: {
                            isFluidWidth: true,
                            src: item.thumbnail
                        },
                        largeImage: {
                            width: 550,
                            height: 550,
                            src: item.original
                        },
                        lensStyle: { backgroundColor: "rgba(0,0,0,.6)" }
                    }}
                    {...{
                        isHintEnabled: false,
                        enlargedImageContainerDimensions: { width: "100%", height: "100%" },
                        // shouldHideHintAfterFirstActivation: true,
                        enlargedImagePosition: "over",
                        enlargedImageContainerStyle: { Index: 1000 }
                    }}
                />)
        )

    }
   
    render() {
        let { product_gallery_entries, selectedVariant, videoData } = this.props;
        const { photoIndexLightBox, isLightBoxOpen } = this.state;
        //If there is no image for the product, display a placeholder image.
        if (product_gallery_entries.length === 0) {
            let placeholder_image_list = [{ original: Placeholder, thumbnail: Placeholder }]
            return (
                <div>
                    <div className="leftSlider slider">
                        <ImageGallery
                            thumbnailPosition="bottom"
                            items={placeholder_image_list}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className="leftSlider">
                    <ImageGallery ref={el => this.imagegallery = el}
                        thumbnailPosition={window.matchMedia('(min-width: 600px)').matches ? 'left' : 'bottom'}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                        renderItem={this.imageHover}
                        showThumbnails={this.showThumbnails(Object.keys(this.state.images) ? this.state.images[selectedVariant] : [])}
                        items={Object.keys(this.state.images) ? this.state.images[selectedVariant] : []}
                        onSlide={(slide) => this.onslide(slide)}
                    />

                    {isLightBoxOpen && (
                        <Lightbox
                            mainSrc={this.imagesLightBox_list[photoIndexLightBox]}
                            nextSrc={this.imagesLightBox_list[(photoIndexLightBox + 1) % this.imagesLightBox_list.length]}
                            prevSrc={this.imagesLightBox_list[(photoIndexLightBox + this.imagesLightBox_list.length - 1) % this.imagesLightBox_list.length]}
                            onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndexLightBox: (photoIndexLightBox + this.imagesLightBox_list.length - 1) % this.imagesLightBox_list.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndexLightBox: (photoIndexLightBox + 1) % this.imagesLightBox_list.length,
                                })
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}

ProductGallery.propTypes = {
    classes: PropTypes.object.isRequired,
    product_gallery_entries: PropTypes.array,
};

export default withStyles(styles)(ProductGallery);