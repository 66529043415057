/**
* CartButton Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import SvgIcon from '@material-ui/core/SvgIcon';
import CartSvgIcon from '../../../theme/base/assets/icons/cart.svg';
import MiniShoppingCart from './MiniCart';
import { connect } from 'react-redux';
import CartService from '../../modules/cart/CartService';
import ErrorBoundary from 'react-error-boundary';

const styles = theme => ({

});

/***************************************************************
 * Component class for displaying Cart Button and submenu 
 * on hover. Submenu includes items added to cart.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miniShoppingCartOpen: false,
      miniCartDetails: [],
    }
  }

  componentDidMount() {
    //Initialize the Badge with number of products in the Cart.
    this.props.displayMiniCart();
  }

  //For display of Mini Shopping Cart
  handleMiniShoppingCartToggle = () => {
    this.setState({ miniShoppingCartOpen: !this.state.miniShoppingCartOpen });
    //Dispatching a redux action, if shopping cart is open.
    if (!this.state.miniShoppingCartOpen) {
      this.props.displayMiniCart();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <IconButton
          onClick={this.handleMiniShoppingCartToggle}
        >
          <Badge
            className={classes.badgePosition}
            showZero={true}
            badgeContent={this.props.cart_product_count ? this.props.cart_product_count : 0}
            color="primary">            
            {/* pointerEvents is set to none, to enable mouse event on the svg icon */}
            <SvgIcon style={{ pointerEvents: 'none' }} component="div" color="primary">
            <div>
              <img src={CartSvgIcon} alt="Cart Icon" />
            </div>
            </SvgIcon> 
          </Badge>
        </IconButton>
        {/* For Mini Shopping Cart display */}
        <ErrorBoundary FallbackComponent={MiniCartErrorFallbackComponent}>
        <MiniShoppingCart
          miniCartDetails={this.props.miniCartDetails}
          cart_product_count={this.props.cart_product_count}
          cart_product_cost={this.props.cart_product_cost}
          onClose={this.handleMiniShoppingCartToggle}
          miniShoppingCartOpen={this.state.miniShoppingCartOpen}
          onCloseMiniShoppingCart={this.handleMiniShoppingCartToggle}
        />
        </ErrorBoundary>
      </div>
    );
  }
}

//returns total product count
function getProductTotalCount(state_get_minicart_details) {
  if (state_get_minicart_details) {
    return state_get_minicart_details.cart_product_count
  }
  else {
    return 0;
  }
}

//returns total product cost
function getProductTotalCost(state_get_minicart_details) {
  if (state_get_minicart_details) {
    return state_get_minicart_details.cart_product_cost
  }
  else {
    return 0;
  }
}

/***Error Boundary Fallback Component */
const MiniCartErrorFallbackComponent = ({ componentStack, error }) => {
  return null;
}

const mapStateToProps = state => ({
  totalProductCount: state.result_add_to_cart_state.totalProductCount,
  miniCartDetails: state.get_minicart_details,
  cart_product_count: getProductTotalCount(state.get_minicart_details),
  cart_product_cost: getProductTotalCost(state.get_minicart_details),
});

const mapDispatchToProps = dispatch => ({
  displayMiniCart: () => {
    let cs = new CartService();
    dispatch(cs.GetDisplayMiniCartActionCreator())
  }
});

const CartButtonWithStyles = withStyles(styles)(CartButton);
export default connect(mapStateToProps, mapDispatchToProps)(CartButtonWithStyles);