import React from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from "react-router";
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import ProductService from '../../../../app/modules/product/ProductService';

class NotifyOutOfStock extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            open: this.props.open,
            editEmail:true,
            email:"",
            customerId:""
        }
    }
    componentDidMount(){
        if(localStorage.getItem('shopce-user')){
            let value = localStorage.getItem('shopce-user');
            this.setState({email:JSON.parse(value).email,customerId:JSON.parse(value).customerId});
        }
    }
    handleClose() {
        this.setState({
            open: false
        })
        this.props.notifyOutOfStock(false);

    }
    subscribeNotification() {
        this.setState({
            open: false
        })
        if(this.state.email!=="example@mail.com"){
            this.props.onSubmitNotifySubscribedDispatch(
                this.props.productId,
                this.state.customerId
            );
        }
        this.props.notifyOutOfStock(false);
        
    }
    render() {
        const {post_notify_outofstock} = this.props.post_notify_outofstock
        //let isSubscribeButtonDisabled = post_notify_outofstock.isSubscribeButtonDisabled
        return (
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Subscribe for notification</DialogTitle>
                <DialogContent>
                    <div style={{ color: 'grey' }}>Please subscribe, to receive notification to the below email address when product is availabe</div>
                    <FormControl margin="normal" required>
                        <InputLabel style={{ fontSize: 18 }}>Email Address:</InputLabel>
                        <Input type='email' value={this.state.email}
                            disabled={true}
                            required={true} 
                            onChange={(event)=>this.setState({email:event.target.value}) } 
                            // endAdornment={
                            //     <InputAdornment position="end">
                            //         <IconButton disableRipple onClick={()=>this.setState({editEmail:!this.state.editEmail})} style={{ cursor: 'default' }}><EditIcon /></IconButton>
                            //     </InputAdornment>
                            // }
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this)} color="primary">
                        Cancel
                        </Button>
                    <Button  onClick={this.subscribeNotification.bind(this)} color="secondary">
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
const mapStateToProps = state => ({
    get_user_details: state.get_user_details_state,
    post_notify_outofstock:state.post_notify_outofstock_state
});
const mapDispatchToProps = dispatch => ({
    onSubmitNotifySubscribedDispatch: (
        productId,
        customerId
    ) => {
        var NotifyObject = {
           productId:productId,
           customerId:customerId
        };
        let ps = new ProductService();
        dispatch(ps.subscribeNotifyActionCreator(NotifyObject));
    },
}
);

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NotifyOutOfStock))