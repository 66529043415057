/**
* OrderService class with functions for handling Order History  related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Nirmal <nirmal.gandhi@embitel.com>
* @copyright 2019-2020 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    RECIEVE_ORDER_DETAILS, SET_LOADER_STATUS
} from '../../framework/common/ReduxConstants';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';


/**********************************************************************************
 * Order Service class which handles Order History
 *********************************************************************************/
class OrderService {
    /****************************************************************************** 
        * Action Creators
        *****************************************************************************/

    getOrderHistoryActionCreator(type) {
        return (dispatch) => {
            //dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let customerid = ""
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if(window.location.pathname == "/elmt/" || window.location.pathname == "/elmt/account") return;
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
                customerid = userFromLocalStorage.customerId;
                let requestType = 'GET';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                if (!userFromLocalStorage.multipleId) {
                    MagnaAPIHandler('/ecs/companycustomers/' + customerid, requestType, requestBody).then(
                        (response) => {
                            if (response.data[0]) {
                                userFromLocalStorage.multipleId = response.data[0];
                                localStorage.setItem('shopce-user', JSON.stringify(userFromLocalStorage));
                                this.currentOrders('HPC', response.data[0].HPC, dispatch);
                                this.currentOrders('Foods', response.data[0].Foods, dispatch);
                            }
                               //dispatch({ type: SET_LOADER_STATUS });
                              //dispatch({ type: RECIEVE_ORDER_DETAILS, success: true, response });
                            // dispatch({type:'SET_DEFAULT_PLACE_ORDER'})
                        },
                        (error) => {
                            dispatch({ type: RECIEVE_ORDER_DETAILS, success: false, error });
                        }
                    );
                }
                else if (type) {
                    this.currentOrders(type, userFromLocalStorage.multipleId[type], dispatch);
                }
                else {
                    // this.currentOrders('HPC', userFromLocalStorage.multipleId.HPC, dispatch);
                    // this.currentOrders('Foods', userFromLocalStorage.multipleId.Foods, dispatch);
                    if(window.location.pathname == '/elmt/vieworder' && (localStorage.getItem('tabName') || !localStorage.getItem('tabName'))){
                        if(localStorage.getItem('tabName') == 'Foods'){
                            this.currentOrders('Foods', userFromLocalStorage.multipleId.Foods, dispatch);
                        }else{
                            this.currentOrders('HPC', userFromLocalStorage.multipleId.HPC, dispatch);
                        }
                    }else{
                        this.currentOrders('HPC', userFromLocalStorage.multipleId.HPC, dispatch);
                        this.currentOrders('Foods', userFromLocalStorage.multipleId.Foods, dispatch);
                    }
                }
            }
        }

    }
    currentOrders = (type, customerid, dispatch) => {
        let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
        let userToken;
        if (userFromLocalStorage) {
            userToken = userFromLocalStorage.multiple[type];

        }
        dispatch({ type: SET_LOADER_STATUS });
        let requestType = 'GET';
        let requestBody = {};
        requestBody.header = ("Bearer " + userToken);
        let requestURL = `/ecs/orders?searchCriteria[filterGroups][0][filters][0][field]=customer_id&%20searchCriteria[filterGroups][0][filters][0][value]=${customerid}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq`;
        MagnaAPIHandler(requestURL, requestType, requestBody).then(
            (response) => {
                dispatch({ type: RECIEVE_ORDER_DETAILS, success: true, response, value: type });
                dispatch({ type: 'SET_DEFAULT_PLACE_ORDER' });
                dispatch({ type: SET_LOADER_STATUS });
            },
            (error) => {
                dispatch({ type: RECIEVE_ORDER_DETAILS, success: false, error });
            }
        );
    }
    static get_order_history = (state = [], action) => {
        switch (action.type) {
            case RECIEVE_ORDER_DETAILS:
                let retValue = OrderService.applyMyOrders(state, action);
                return retValue;
            case 'CURRENT_ORDER':
                const newValue = { ...state };
                newValue.total_count = newValue.allOrders[action.current].total_count;
                newValue.search_criteria = newValue.allOrders[action.current].search_criteria;
                newValue.items = newValue.allOrders[action.current].items;
                newValue.current = action.current;
                return newValue;

            default:
                return state;
        }
    };

    static applyMyOrders = (state, action) => {
        //Check if Order Data is present in the action variable.  
        if (action && action.response && action.response.data) {
            action.response.data.items = action.response.data.items.reverse();
            const data = { ...action.response.data };
            data.allOrders = {};
            if (Object.keys(state).length) {
                data.allOrders = { ...state.allOrders };
            }
            data.allOrders[action.value] = { ...data };
            data.current = action.value;
            return data;
        }
        return [];
    }

}

export default OrderService;