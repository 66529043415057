/**
* TemplateWrapper Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import HeaderBar from '../../../extension/components/layouts/headerbar/HeaderBar';
import FooterBar from '../../../extension/components/layouts/footerbar/FooterBar';
import NavigationMenuDesktop from '../../../extension/components/navigation/navigationdesktop/NavigationDesktop';

const templatesObject = {
    withHeaderFooter: {
        HeaderBar: {
            showfields: ['logo','search','profile','cart'], //you can include values as search, profile, cart
        },
        FooterBar: {
            showfields: ['all'], //you can include values as copyrights,cms page links
        },
        NavigationMenuDesktop: {
            showfields: ['all'], //as of now no constraints but later we may get
        }
    },
    noAuthHeaderFooter: {
        HeaderBar: {
            showfields: ['logo'], //you can include values as search, profile, cart
        },
        FooterBar: {
            showfields: ['about'], //you can include values as copyrights,cms page links
        }
    },
    empty: {
        //nothing in this template 
    }
}

/***************************************************************
 * Component class for defining templates for each page.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
const TemplateWrapper = (ComposedComponent, type) => {
    return class extends React.Component {
        render() {
            //headerExists varaible will define whether header should display or not
            let headerExists =Object.keys(templatesObject[type]).includes("HeaderBar");
            //footerExists varaible will define whether footer should display or not
            let footerExists =Object.keys(templatesObject[type]).includes("FooterBar");
            //menuExists varaible will define whether menu should display or not
            let menuExists =Object.keys(templatesObject[type]).includes("NavigationMenuDesktop");

            return (
                <React.Fragment>
                    {headerExists?<HeaderBar showfields={templatesObject[type].HeaderBar.showfields} />:''}
                    <ComposedComponent type={type} {...this.props} {...this.state} />
                    {footerExists?<FooterBar show={templatesObject[type].FooterBar} />:''}
                </React.Fragment>)
        }
    }
};
export default TemplateWrapper;
