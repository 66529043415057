import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MagnaAPIHandler from '../../../../app/framework/api/MagnaAPIHandler';
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import CartService from '../../../../app/modules/cart/CartService';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import GetLanguage from '../../../../library/GetLanguage';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateQuick from '../../../../assets/images/Quick_Order.svg';
import getConfigValue from '../../../../app/framework/config/configHandler';

const styles = theme => ({
  textPaddingRight: {
    cursor: 'pointer',
    fontSize: '12px'
  },
  downloadStyle: {
    cursor: 'pointer',
    color: '#57B3FF',
    textDecoration: 'none'
  },
  quickOrderInput: {
    width: "260px",
    [theme.breakpoints.down('xs')]: {
      width: "220px",
    },
  },
  textmodal2color: {
    color: '#000'
  },
  borderwrapper: {
    border: '1px solid #d1d1d1',
    padding: '10px',
    maxHeight: '150px',
    overflow: 'scroll',
    overflowX: 'auto'
  }
})

class BulkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isUpload: false,
      openErrormodal: false,
      errorListSKU: '',
      errorListEAN: '',
      uploading:false,
      openErrorEAN:false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseErrorEAN = this.handleCloseErrorEAN.bind(this);
  }
  //   const [open, setOpen] = React.useState(false);
  handleClose() {
    this.setState({ openErrormodal: false })
  }
  handleCloseErrorEAN() {
    this.setState({ openErrorEAN: false })
  }
  handleClick() {
    this.setState({ open: !this.state.open })
  }
  verifyData(evt) {

    this.setState({uploading:true})
    var file = evt.target.files[0];
    if (file) {

      var reader = new FileReader();
      reader.onloadend = (e) => {
        const csv = e.target.result;
        const [firstLine, ...lines] = csv.split('\n');
        let params = [];
        const headers = firstLine.split(',');

        if (headers && (headers.includes('sku') || headers.includes('barcode'))) {
          for (let i = 0; i < lines.length; i++) {
            let data = lines[i].split(',');
            let obj = {};
            for (var j = 0; j < data.length; j++) {
              if (headers[j].trim && data[j].trim) {
                obj[headers[j].trim()] = data[j].trim();
              }

            }
            if (Object.keys(obj).length) {
              params.push(obj);
            }
          }
         if(JSON.stringify(params[params.length-1])==='{"sku":""}'){
           //remove last item if last row is empty
            params.pop();
         }
         
          let requestBody = {};
          let userInfo = getItemFromLocalStorage('shopce-user');
          let userToken = userInfo ? userInfo.token : null;
          requestBody.data = { params };
          const systemParameters = getConfigValue("system");
          let websiteId = window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].websiteId:systemParameters.store[1].websiteId
          requestBody.header = ("Bearer " + userToken);
          MagnaAPIHandler('/carts/mine/verificationbysku/'+websiteId, 'POST', requestBody).then(res => {
            this.setState({uploading:true})
            let newParams = [];
            const oldParams = [...params];
            if (res.data && res.data[0].success.length) {
              newParams = res.data[0].success;
              // oldParams.map(store => {
              //   let updated_sku_array = [];
              //   if (res.data[0].success.includes(store.sku)) {
              //     newParams.push(store)
              //   }
              //   else {
              //     res.data[0].success.map((item) => {
              //       let s = item.replace(/^0+/, '');
              //       updated_sku_array.push(s);
              //     })
              //     if (updated_sku_array.includes(store.sku)) {
              //       newParams.push(store);
              //     }
              //   }
              // })
            }
            if (res.data && (res.data[0].failure.length|res.data[0].failure_ean.length)) {
              this.setState({
                errorListSKU: res.data[0].failure,
                errorListEAN: res.data[0].failure_ean,
                openErrormodal: true,
                openErrorEAN:true
              })
              // NotificationManager.error('Wrong Sku '+ res.data[0].failure)
            }
            this.setState({ isUpload: newParams.length, params: newParams,uploading:false});
          }).catch(error => {
            let genericError = 'Error, Please try again after some time';
            let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
            NotificationManager.error(errorMessage);
            this.setState({ isUpload: false,uploading:false });
          });
        } else {
          NotificationManager.error('Wrong File');
        }
      }

      reader.readAsText(file);
    }



  }


  render() {
    const { open, isUpload, openErrormodal,openErrorEAN } = this.state;
    const { classes } = this.props;
    if (!localStorage.getItem('shopce-user')) {
      return (<Redirect to="/account" />)
    }
    return (
      <div>
        <Typography variant="caption" className={classes.textPaddingRight + " " + "quickorder-heading"} onClick={() => this.setState({ open: true, isUpload: false, params: null })}>
         {<GetLanguage value="Quick Order" />}
        </Typography>
        <Dialog open={open} onClose={this.handleClick} aria-labelledby="form-dialog-title">
          {this.state.uploading?
          <div style={{justifyContent: 'center', alignItems: 'center' ,position:'absolute',zIndex:999, left:150, top:100}}>
                <CircularProgress />
          </div>:''}
          <DialogTitle id="form-dialog-title">{<GetLanguage value="Quick Order" />}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* <Typography> */}
                <span style={{color:'#000',fontSize:'14px'}}>{<GetLanguage value="Upload a CSV file with the fields as represented in the sample file" />}</span>
              {/* </Typography> */}
            </DialogContentText>
            <input
              className={classes.quickOrderInput}
              autoFocus
              margin="dense"
              id="quick-order"
              label=""
              type="file"
              accept=".csv"
              onChange={(e) => this.verifyData(e)}
            />
            <Typography gutterBottom>
              <a className={classes.downloadStyle} href='/media/sample.csv'>
                {<GetLanguage value="Download Template File" />}</a>
            </Typography>
            <div style={{color:'#666',fontSize:'14px'}}>
              Please Note: 
              <ul style={{margin:'6px',lineHeight:'1.5'}}>
                <li>Please enter either SKU Code / EAN (Bar Code) in each line item</li>
                <li>The UoM will be considered as Cases only</li>
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClick}>{<GetLanguage value="Cancel" />}</Button>
            <Button disabled={!isUpload} variant="contained" color="secondary"
              onClick={() => this.props.bulkorderupload(this.state.params, this)}>{<GetLanguage value="Submit" />}</Button>
          </DialogActions>
        </Dialog>
        {(this.state.errorListSKU && this.state.errorListSKU.length > 0)|(this.state.errorListEAN&&this.state.errorListEAN.length>0) ?
          <Dialog open={openErrormodal} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {this.state.errorListSKU && this.state.errorListSKU.length>0?
            <DialogTitle id="alert-dialog-title">{"Failure Sku"}</DialogTitle>:''}
            {this.state.errorListSKU && this.state.errorListSKU.length>0?<DialogContent>
              <DialogContentText id="alert-dialog-description" className={classes.borderwrapper}>
                {this.state.errorListSKU && this.state.errorListSKU.map((data, k) => {
                  return (<span key={k} className={classes.textmodal2color}>{data} </span>)
                })}
              </DialogContentText>
            </DialogContent>:''}
            {this.state.errorListEAN && this.state.errorListEAN.length>0?
            <DialogTitle id="alert-dialog-title">{"Failure EAN"}</DialogTitle>:''}
             {this.state.errorListEAN && this.state.errorListEAN.length>0?<DialogContent>
              <DialogContentText id="alert-dialog-description" className={classes.borderwrapper}>
                {this.state.errorListEAN && this.state.errorListEAN.map((data, k) => {
                  return (<span key={k} className={classes.textmodal2color}>{data} </span>)
                })}
              </DialogContentText>
            </DialogContent>:''}
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog> : ''}
      </div>
    );
  }

}
const mapDispatchToProps = dispatch => ({
  bulkorderupload: (params, component) => {
    let cartService = new CartService();
    dispatch(cartService.BulkUpload(params, component));
  }
});
const BulkOrderWithStyles = withStyles(styles)(BulkOrder);
export default withRouter(connect(null, mapDispatchToProps)(BulkOrderWithStyles));
