/**
* Shipping Address Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CheckoutLayout from '../../components/layouts/checkoutflow/CheckoutLayout';
import OrderSummary from '../checkout/OrderSummary';
import UserMessage from '../../../app/components/messages/UserMessages';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { getItemFromLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';
//Redux related imports
import { connect } from 'react-redux';
import CheckoutService from '../../../app/modules/checkout/CheckoutService';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import { Paper } from '@material-ui/core';
import GetLanguage from '../../../library/GetLanguage';

const styles = theme => ({
  image: {
    width: 75,
    height: 80,
  },
  formElementsPosition: {
    alignItems: 'flex-start',
    marginBottom: '1rem',
  },
  radioButton: {
    top: '-10px',
    left: '0',
    position: 'relative'
  },
  button: {
    margin: theme.spacing.unit,
  },
  editStyle: {
    cursor: 'pointer',
    color: '#2196f3',
  },
});

/***************************************************************
 * Component class for shipping address in checkout flow.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ShippingAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressDetails: '',
      shippingDetails: '',
      selectedAddress: '',
      continueDisabled: false,
      isAddAddressClicked: false,
    };
  }
  componentDidMount() {    
    if(typeof this.props.shippingaddress !== 'undefined' && this.props.shippingAddress) {
      this.props.getShippingAddress();
    }
  }
  generateShippingObject = (selectedAddress) => {
    //parse string to JSON
    let selectedAddressObj = JSON.parse(selectedAddress);
    //get user details from local storage
    var userInfo = getItemFromLocalStorage('shopce-user');

    if (selectedAddressObj && selectedAddressObj.length !== 0) {

      var addressObject = {
        "address": {
          "customerAddressId": selectedAddressObj.id,
          "customerId": selectedAddressObj.customer_id ? selectedAddressObj.customer_id : '',
          "countryId": selectedAddressObj.country_id,
          "regionId": selectedAddressObj.region_id,
          "regionCode": selectedAddressObj.region ? selectedAddressObj.region.region_code : '',
          "region": selectedAddressObj.region ? selectedAddressObj.region.region : '',
          "street": selectedAddressObj.street,
          "telephone": selectedAddressObj.telephone,
          "postcode": selectedAddressObj.postcode,
          "city": selectedAddressObj.city,
          "firstname": selectedAddressObj.first_name ? selectedAddressObj.first_name : 'Shaik',
          "lastname": selectedAddressObj.last_name ? selectedAddressObj.last_name : 'Sameena',
        }
      }

      var shippingOptionsObject = {
        "addressInformation":
        {
          "billingAddress":
          {
            "id": selectedAddressObj.id,
            "region": selectedAddressObj.region ? selectedAddressObj.region.region : '',
            "regionId": selectedAddressObj.region_id,
            "regionCode": selectedAddressObj.region ? selectedAddressObj.region.region_code : '',
            "countryId": selectedAddressObj.country_id,
            "street": selectedAddressObj.street,
            "telephone": selectedAddressObj.telephone,
            "postcode": selectedAddressObj.postcode,
            "city": selectedAddressObj.city,
            "firstname": selectedAddressObj.firstname ? selectedAddressObj.firstname : '',
            "lastname": selectedAddressObj.lastname ? selectedAddressObj.lastname : '',
            "customer_id": selectedAddressObj.customer_id ? selectedAddressObj.customer_id : '',
            "email": userInfo ? (userInfo.email ? userInfo.email : '') : '',
            "same_as_billing": 1,
            "save_in_address_book": 1
          },
          "shippingAddress":
          {
            "region": selectedAddressObj.region ? selectedAddressObj.region.region : '',
            "regionId": selectedAddressObj.region_id,
            "regionCode": selectedAddressObj.region ? selectedAddressObj.region.region_code : '',
            "countryId": selectedAddressObj.country_id,
            "street": selectedAddressObj.street,
            "telephone": selectedAddressObj.telephone,
            "postcode": selectedAddressObj.postcode,
            "city": selectedAddressObj.city,
            "firstname": selectedAddressObj.firstname ? selectedAddressObj.firstname : '',
            "lastname": selectedAddressObj.lastname ? selectedAddressObj.lastname : '',
            "customer_id": selectedAddressObj.customer_id ? selectedAddressObj.customer_id : '',
            "email": userInfo ? (userInfo.email ? userInfo.email : '') : '',
            "same_as_billing": 1,
            "save_in_address_book": 1
          },
          "shippingCarrierCode": "freeshipping",
          "shippingMethodCode": "freeshipping"
        }
      };
      //   this.setState({ shippingDetails: shippingOptionsObject });
      //  this.setState({ addressDetails: addressObject });
    }
  }
  componentWillReceiveProps(nextProps) {

    //getting back to delivery address screen from review order
    //preselect the address which was selected previously
    let { postupdateshippingdetails } = this.props;
    if (postupdateshippingdetails && postupdateshippingdetails.selectedDeliveryAddress) {
      let deliveryAddressFromReviewOrder = JSON.stringify(postupdateshippingdetails.selectedDeliveryAddress);
      this.setState({ selectedAddress: deliveryAddressFromReviewOrder });
    }
    //check for the shipping address and set the first as default selected
    if (nextProps.shippingaddress && nextProps.shippingaddress.addresses.length > 0) {
      let defaultAddress = JSON.stringify(nextProps.shippingaddress.addresses[0]);
      //check if loading initailly
      if (this.state.selectedAddress === '') {
        //set default address as selected
        this.setState({ selectedAddress: defaultAddress });
      }
      this.generateShippingObject(defaultAddress);
    }
    //after updating shipping details sucessfully, will proceed to next step
    if (nextProps.postupdateshippingdetails && nextProps.postupdateshippingdetails.isShippingAddressUpdated) {
      this.props.nextAction(); 
    }
  }
  //handles the change of address and generate object for the selected address
  handleChange = event => {
    this.setState({ selectedAddress: event.target.value });
    this.generateShippingObject(event.target.value);
  }
  //upon clicking continue, selected shipping info will be updated
  activeStepSubmit = (e) => {
    //disable button on continue to next step
    this.setState({ continueDisabled: true });
    this.props.onShippingOptionsSelect(this.state.addressDetails, this.state.shippingDetails, this.state.selectedAddress);
  }

  render() {
    return (
      <CheckoutLayout OrderDetails={<ThailandAddressView selectedAddress={this.state.selectedAddress} handleChange={this.handleChange}  {...this.props} />} OrderSummary={<OrderSummary continueDisabled={this.state.continueDisabled} nextStep={this.activeStepSubmit} targetStep={this.props.targetStep} />} ViewName={this.props.activeStepName} />
    );
  }

  componentWillUnmount() {
    // Commenting as of now because there are no steps available in checkout flow
    // this.props.resetShippingAddressUpdate();
  }
}

class ThailandAddressView extends React.Component {
  render() {

    const { addressSaved } = this.props.location.state ? this.props.location.state : false;
    const { shippingaddress, postupdateshippingdetails } = this.props;
    var addressAddedMessage;
    //If update is not success, show an error
    if (postupdateshippingdetails &&
      (postupdateshippingdetails.isShippingAddressUpdated === false || postupdateshippingdetails.isBillingAddressUpdated === false)) {
      const object = { messageType: 'error', message: postupdateshippingdetails.errorMessage ? postupdateshippingdetails.errorMessage : 'Please try again' };
      var updateShippingErrorMessage = <UserMessage {...object} />
    }
    if (addressSaved) {
      const object = { messageType: 'sucess', message: "Address is saved successfully" }
      addressAddedMessage = <UserMessage {...object} />
    }
    if (shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) &&
      (shippingaddress.length !== 0) && (shippingaddress.addresses.length !== 0)) {
      return (
        <React.Fragment>
          {updateShippingErrorMessage}
          {addressAddedMessage}
          {shippingaddress.addresses.map((addressItem, index) =>
            <AddressCardLayout key={index} addressItem={addressItem} />
          )}
          <AddAddressButton redirectTo="/address" />
        </React.Fragment>
      );
    }
    else if (shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) &&
      (shippingaddress.length !== 0) && (shippingaddress.addresses.length === 0)) {
      return (
        <React.Fragment>
          <Grid container direction="column" alignItems="flex-start">
            <CardContent>
              <Typography variant="body2" gutterBottom>
                {<GetLanguage value="You do not have any address added yet!"/>}
              </Typography>
            </CardContent>
            <AddAddressButton redirectTo="/address" />
          </Grid>
        </React.Fragment>
      );
    }
    else
      return "Loading...";
  }
}
class AddressCardLayout extends React.Component {

  render() {
    const { addressItem } = this.props;
    return (
      <Paper style={{ maxWidth: '300px', padding: '1rem', border: '1px solid #57B3FF' }}>
        <Typography variant="subtitle1">{addressItem.firstname} {addressItem.lastname}</Typography>
        <Typography variant="caption" gutterBottom>{addressItem.street}, {addressItem.city} {addressItem.postcode}, {addressItem.region ? addressItem.region.region : ''}, {addressItem.country_id}</Typography>
        <Typography>{<GetLanguage value="PhoneNumber:"/>} {addressItem.telephone}</Typography>
      </Paper>
    )
  }
}
/***************************************************************
* Component class for address layout
* @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class AddressLayout extends React.Component {

  render() {

    const { addressSaved } = this.props.location.state ? this.props.location.state : false;
    const { classes } = this.props;
    const { shippingaddress, postupdateshippingdetails } = this.props;
    var addressAddedMessage;
    //If update is not success, show an error
    if (postupdateshippingdetails &&
      (postupdateshippingdetails.isShippingAddressUpdated === false || postupdateshippingdetails.isBillingAddressUpdated === false)) {
      const object = { messageType: 'error', message: postupdateshippingdetails.errorMessage ? postupdateshippingdetails.errorMessage : 'Please try again' };
      var updateShippingErrorMessage = <UserMessage {...object} />
    }
    if (addressSaved) {
      const object = { messageType: 'sucess', message: "Address is saved successfully" }
      addressAddedMessage = <UserMessage {...object} />
    }
    if (shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) &&
      (shippingaddress.length !== 0) && (shippingaddress.addresses.length !== 0)) {
      return (
        <React.Fragment>
          {updateShippingErrorMessage}
          {addressAddedMessage}
          <FormControl component="fieldset">
            <RadioGroup aria-label="Delivery Address" name="address"
              value={this.props.selectedAddress} onChange={this.props.handleChange}>
              {shippingaddress.addresses.map((addressItem, index) =>
                <FormControlLabel key={index} value={JSON.stringify(addressItem)} control={
                  <Radio className={classes.radioButton} color="primary" />}
                  label={<AddressItem addressItem={addressItem} />}
                  className={classes.formElementsPosition} />
              )}
            </RadioGroup>
          </FormControl>
          <Grid>
            <CardContent>
              <AddAddressButton redirectTo="/address" />
            </CardContent>
          </Grid>
        </React.Fragment>
      );
    }
    else if (shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) &&
      (shippingaddress.length !== 0) && (shippingaddress.addresses.length === 0)) {
      return (
        <React.Fragment>
          <Grid container direction="column" alignItems="flex-start">
            <CardContent>
              <Typography variant="body2" gutterBottom>
              {<GetLanguage value="You do not have any address added yet!"/>}
              </Typography>
            </CardContent>
            <AddAddressButton redirectTo="/address" />
          </Grid>
        </React.Fragment>
      );
    }
    else
      return null;
  }
}

class AddressItem extends React.Component {
  state = {
    editClicked: false
  }

  onEditLinkClick = (e) => {
    e.preventDefault();
    this.setState({ editClicked: true });
  }

  render() {
    const { addressItem } = this.props;
    if (this.state.editClicked === true) {
      return <Redirect to={{ pathname: "/address", state: { redirectTo: "/checkout", editaddressItem: addressItem } }} />;
    }
    return (
      <React.Fragment>
        <Typography variant="subtitle1">{addressItem.firstname} {addressItem.lastname}</Typography>
        <Typography variant="caption" gutterBottom>{addressItem.street}, {addressItem.city} {addressItem.postcode}, {addressItem.region ? addressItem.region.region : ''}, {addressItem.country_id}</Typography>
        <Typography variant="caption" color="primary" onClick={(event) => this.onEditLinkClick(event)} >{<GetLanguage value="Edit"/>}</Typography>
      </React.Fragment>
    );
  }
}
class AddAddressButton extends React.Component {

  state = {
    isAddAddressClicked: false,
  }

  handleAddAddressToggle = () => {
    this.setState({ isAddAddressClicked: true });

  };
  render() {
    const { redirectTo } = this.props;
    if (this.state.isAddAddressClicked) {
      return <Redirect to={{ pathname: redirectTo, state: { redirectTo: "/checkout" } }} />;
    }
    else {
      return (
        <div style={{ marginTop: '1rem' }}>
          {/* <Fab aria-label="Proceed to Checkout" variant="extended" style={{ textTransform: 'capitalize',background:'#57B3FF' }}
          type="submit" size="medium" onClick={this.handleAddAddressToggle} >
          Add Address</Fab> */}
        </div>
      )
    }
  }

}

const mapStateToProps = state => ({
  shippingaddress: state.get_shipping_address,
  postupdateshippingdetails: state.post_update_shipping_details,
});
const mapDispatchToProps = dispatch => ({
  getShippingAddress: () => {
    let checkoutService = new CheckoutService();
    dispatch(checkoutService.getShippingAddressActionCreator());
  },
  onShippingOptionsSelect: (addressObject, shippingOptionsObject, selectedAddressObj) => {
    let checkoutService = new CheckoutService();
    dispatch(checkoutService.updateShippingDetailsActionCreator(addressObject, shippingOptionsObject, selectedAddressObj));
  },
  resetShippingAddressUpdate: () => {
    let checkoutService = new CheckoutService();
    dispatch(checkoutService.resetShippingAddressUpdateActionCreator());
  },
});

const ShippingAddressWithStyles = withStyles(styles)(ShippingAddress);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShippingAddressWithStyles));