/**
* Home Container Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Carousel from '../../../components/layouts/blocks/carousel/Carousel';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import toRenderProps from 'recompose/toRenderProps';
import Typography from '@material-ui/core/Typography';
import CategoryService from '../../../../app/modules/category/CategoryService';
import TemplateWrapper from '../../../../app/framework/hoc/templateWrapper';
import Hidden from '@material-ui/core/Hidden';
import BrandsCarousel from '../blocks/carousel/BrandsCarousel';
import CategoryCarousel from '../blocks/carousel/CategoryCarousel';
import ProductCarousel from '../blocks/carousel/ProductCatalogSlider';
import Button from '@material-ui/core/Button';
import ApplicationService from '../../../../app/modules/application/ApplicationService';
import stagingProduct from '../../../framework/categoryProducts/th/stagingCategoryProduct.json';
import productionProduct from '../../../framework/categoryProducts/th/productionCategoryProduct.json';
import CreateNewRequisition from '../../requisition/CreateNewRequisition';
import NavigationDesktop from '../../navigation/navigationdesktop/NavigationDesktop';
import BulkOrder from '../headerbar/BulkOrder';
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CreateNewList from '../../../../assets/images/Create_New_List.svg';
import CreateQuick from '../../../../assets/images/Quick_Order.svg';
import MyOrderList from '../../../../assets/images/My_Order_List.svg';
import squareImg from '../../../../../src/assets/images/Square.svg';

import ContentLoader from '../../../components/layouts/blocks/noncarousel/ContentLoader';
import { relative } from 'path';
const styles = theme => ({
    staticBlockStyle: {
        backgroundColor: '#60b2f7',
        margin: '1.25rem 0rem 0rem 0rem',
        padding: '1rem',
    },
    bannerPadding: {
        [theme.breakpoints.up('md')]: {
            // paddingTop: '1.2rem',
            // paddingRight: '20px',
            // paddingLeft: '20px'
        },
    },
    staticBannerCaptionText: {
        fontSize: '12px'
    },
    staticBannerTitleText: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    productCarouselStyle: {
        backgroundColor: '#f8f8fa',
        [theme.breakpoints.up('md')]: {
            padding: '0',
            marginTop: '6rem'
        },
        marginBottom: '0.8rem',
    },
    productCarousel: {
        '&:focus': {
            outline: 'none',
        },
    },
    carouselButtonStyle: {
        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
        },
    },
    sectioncontainer: {
        width: '1280px',
        margin: '0 auto',
        boxSizing: 'border-box',
    },
    bannerbotton: {
        padding: '16px',
        background: 'white',
        width: '32.5%',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        '& a': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            color: '#f65e5d',
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 'bold',
            position: 'relative',
            '&::before': {
                content: "''",
                borderRadius: '50%',
                background: '#F73455',
                position: 'absolute',
                height: '50px',
                width: '50px',
            },

        }
    },
    bannerbottonSec: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'space-between',
    },
    bannerbottonImg: {
        color: 'white',
        padding: '10px',
        margin: '0px 15px 0px 3px',
        zIndex: '1',
        width: '40px',


    },
    catHomepage: {
        display: 'flex',
        textTransform: 'uppercase',
        color: '#f65e5d',
        background: 'white',
        padding: '15px',
        margin: '0',
        fontSize: '15px',
        fontWeight: 'bold',
        width: '75%',
        position:'relative'
    },
    sectionHeading:{
        color:'#1d2d5f',
    },
    squareImgClass: {
        position: 'absolute',
        width: '12px',
        right: '10px',
        top: '20px',
    }
});

/***************************************************************
 * Component class for displaying Home Container.
 * which contains recently viewed images and carousel
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
let _this;
class HomeContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 0,
            categorySlideIndex: 3,
            brandSlideIndex: 0,
            carrouselindex: 0,
            carrousel: (window.location.hostname === "usmileshoppro.com") ? productionProduct : stagingProduct,
            // carrousel: [{ carrousel_id: 690, carrousel_name: 'Recommended Products' }, { carrousel_id: 699, carrousel_name: 'Best Sellers' },
            // { carrousel_id: 693, carrousel_name: 'New Arrivals' }
            // ]
        };

    }

    componentDidMount() {
        this.state.carrousel.map((data) => {
            this.props.getCategoryNames(data.carrousel_id);
        })

        // this.props.getCategoryNames(690);
        // this.props.getCategoryNames(699);catHomepage
        // this.props.getCategoryNames(693);
        //Call API to fetch CMS page widget data
        //CMS page id 13 to get cross selling products
        //  this.props.getCMSPageWidgetData3(5);
        if (window.innerWidth > 600) {
            this.props.getBanners(5);
        }
        else {
            this.props.getBanners(8);
        }
        this.state.carrousel.map((data) => {
            this.props.getCarrosel(data.carrousel_id, 'crosssellproducts');
        })
        // this.props.getCarrosel(690, 'crosssellproducts');
        // this.props.getCarrosel(699, 'crosssellproducts');
        // this.props.getCarrosel(693, 'crosssellproducts');
        this.props.setNull();
        this.props.getUSP();
        document.querySelector('body').classList.add('home-page');
    }
    componentWillUnmount() {
        document.querySelector('body').classList.remove('home-page');
    }
    getSlidesToShow = (width) => {
        switch (width) {
            case 'xs':
                return 2;
            case 'sm':
                return 3;
            case 'md':
                return 5;
            case 'lg':
                return 5;
            default:
                return 5;
        }
    }
    getBannersToShow = (width) => {
        if (this.props.get_homepage_banners && this.props.get_homepage_banners !== null) {
            return this.props.get_homepage_banners;
        }
    }
    //on clicking the banner, redirect to
    //respective category
    onBannerImageClick = (val) => {
        //redirect to url
        this.props.history.push(val);
    }

    render() {
        if (!this.props.get_carrousel || !this.props.get_homepage_banners || !this.props.getUSPDetails || !this.props.getCarouselNames) {
            return (<ContentLoader isLoading={true} />)
        }
        const { get_carrousel } = this.props;
        const { classes } = this.props;
        const WithWidth = toRenderProps(withWidth());
        if (typeof this.props.getCarouselNames === 'undefined' || this.props.getCarouselNames === '') {
            return null;
        }
        // const { carrousel_id } = this.state.carrousel[this.state.carrouselindex];
        let carouselIds = Object.keys(this.props.getCarouselNames);
        let carouselNames = Object.values(this.props.getCarouselNames);
        let carrousel_id = carouselIds[this.state.carrouselindex];
        let userInfo = localStorage.getItem('shopce-user')
        return (
            <React.Fragment>
                <Grid container justify='center' className={classes.bannerPadding + " " + "home_nav"}>
                    <Grid container className={classes.sectioncontainer}>
                        <Grid item xs={3}>
                            <p className={classes.catHomepage}>Category Menu<img src={squareImg} className={classes.squareImgClass} /></p>
                            <NavigationDesktop />
                        </Grid>
                        <Grid item xs={9}>
                            <WithWidth>
                                {({ width }) =>
                                    <Carousel autoplay={true} slidesToShow={1}
                                        bannerObject={this.getBannersToShow(width)}
                                        cmsPageWidgetDataObject={[]} {...this.props} />}
                            </WithWidth>
                            <div className="home-middele-block">
                                <Grid item className={classes.bannerbottonSec} >
                                    <Grid item className={classes.bannerbotton}>

                                        <CreateNewRequisition />
                                    </Grid>
                                    <Grid item className={classes.bannerbotton}>
                                        {userInfo ?
                                            <React.Fragment>
                                                <a href="JavaScript:void(0);" className="home-middle-quick-order">
                                                    <img className={classes.bannerbottonImg} style={{ width: '30px' }} src={CreateQuick} />
                                                    Create a quick order
                                                <BulkOrder />
                                                </a>
                                            </React.Fragment>
                                            :
                                            <Link to='/account'>
                                                <img className={classes.bannerbottonImg} style={{ width: '30px' }} src={CreateQuick} />Create a quick order
                                            </Link>
                                        }
                                    </Grid>

                                    <Grid item className={classes.bannerbotton}>
                                        <Link to='/myorders'>
                                            <img className={classes.bannerbottonImg} style={{ width: '30px' }} src={MyOrderList} />
                                            My order List</Link>
                                    </Grid>

                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden only={['sm', 'xs', 'md', 'lg']}>
                    <StaticBlocks {...this.props} />
                </Hidden>

                <div className={classes.productCarouselStyle}>
                    <div container className={classes.sectioncontainer}>
                    <Grid item xs={12} container >
                    
                    <Grid item xs={7} container ><h2 className={classes.sectionHeading}>Featured</h2>
                    </Grid>
                        <Grid item xs={5} container justify='space-around' alignItems='center' >
                            {get_carrousel && get_carrousel.crosssellproducts && get_carrousel.crosssellproducts[carrousel_id]
                                && carouselIds.length && carouselIds.map((item, carrouselindex) => (
                                    <Grid item key={carrouselindex}>
                                        <div
                                            onClick={() => this.setState({ carrouselindex })} value={carouselNames[carrouselindex]} className={carrouselindex === this.state.carrouselindex ? 'contained' : 'outlined'}>
                                            {carouselNames[carrouselindex]}
                                            </div>
                                    </Grid>
                                ))}
                        </Grid>
                        </Grid>
                        {get_carrousel && get_carrousel.crosssellproducts && get_carrousel.crosssellproducts[carrousel_id] && get_carrousel.crosssellproducts[carrousel_id].items &&
                            <WithWidth>
                                {({ width }) =>
                                    <ProductCarousel className={classes.productCarousel}
                                        slidesToShow={this.getSlidesToShow(width)}
                                        cmsPageWidgetDataObject={get_carrousel.crosssellproducts[carrousel_id].items}
                                    />
                                }
                            </WithWidth>
                        }
                    </div>
                </div>

                {/* {typeof cmsPageWidgetData3 !== 'undefined' && cmsPageWidgetData3.length &&
                    <div className={classes.productCarouselStyle}>
                        <Typography className={classes.carouselTitleText} gutterBottom>
                            Recommended Products
                                </Typography>
                        <WithWidth>
                            {({ width }) =>
                                <Carousel
                                    autoplay={false}
                                    slidesToShow={this.getSlidesToShow(width)}
                                    bannerObject={[]} framePadding="0"
                                    cellSpacing={0} slideWidth={1.0}
                                    renderBottomCenterControls={() => { }}
                                    cmsPageWidgetDataObject={cmsPageWidgetData3.reverse()}
                                />}
                        </WithWidth>
                    </div>
                } */}
                <CategoryCarousel />
                <BrandsCarousel />
            </React.Fragment>
        );
    }
}

/***********************************************************
 * Component class for displaying Static blocks
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 **********************************************************/
class StaticBlocks extends React.Component {
    render() {
        const { classes, getUSPDetails } = this.props;
        if (getUSPDetails.length === 0) {
            return null;
        }
        return (
            <Card className={classes.staticBlockStyle} square={true}>
                <Grid container alignItems='flex-start' justify='center' spacing={8}>
                    {getUSPDetails.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Grid container spacing={8} alignItems='center' justify='center'>
                                    <Grid item>
                                        <img src={item.image_path} alt='Free Delivery' width="40px" />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography className={classes.staticBannerTitleText} color="textSecondary">
                                                    {item.title}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.staticBannerCaptionText} color="textSecondary">
                                                    {item.description}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Card>
        );
    }
}

HomeContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

/******************************************************************************
 * Redux mapping of state and dispatches to props.
 * @author    Amaresh M <amaresh.m@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    is_salesman_user: state.is_salesman_user,
    cmsPageWidgetData3: state.get_cms_page_widget_data3_state.cmsPageWidgetData3Details,
    product: state.get_product_detail,
    get_carrousel: state.get_carrosel_products,
    get_homepage_banners: state.get_homepage_banners.homepageBanners,
    getUSPDetails: state.GetUSPDetails,
    getCarouselNames: state.GetCategoryName,
});

const mapDispatchToProps = dispatch => ({
    getBanners: (id) => {
        let categoryService = new CategoryService();
        dispatch(categoryService.GetHomePageBannerDetailsActionCreator(id));
    },
    getCarrosel: (id, typeOfCarousel) => {
        let categoryService = new CategoryService();
        dispatch(categoryService.GetCarroselProductsActionCreator(id, typeOfCarousel));
    },
    getUSP: () => {
        let appService = new ApplicationService();
        dispatch(appService.GetUSPActionCreator());
    },
    getCategoryNames: (categoryId) => {
        let cs = new CategoryService();
        dispatch(cs.GetCategoryNameActionCreator(categoryId));
    },
    setNull: () => {
        dispatch({ type: 'SET_TARGET_PATH_DEFAULT' })
    },

    resetsalesman: () => {
        dispatch({ type: 'SET_IS_SALESMAN_USER' })
    },
});

const HomeContainerWithStyles = withStyles(styles)(HomeContainer);
export default connect(mapStateToProps, mapDispatchToProps)(TemplateWrapper(HomeContainerWithStyles, 'withHeaderFooter'));
