/**
* Checkout Stepper Component. 
* Used in Category Product Listing, Proudct Display in Search
* and in Home Page Product Carousels
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Ruchi Verma <ruchi.verma@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import ShippingAddress from './ShippingAddress';
import { Redirect } from 'react-router';
import magnaCookies from '../../../app/framework/cookie/magnaCookies';
import ReviewOrder from './ReviewOrder';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../../components/error/ErrorPage';
import GetLanguage from '../../../library/GetLanguage';

const styles = theme => ({

});

/***************************************************************
 * Component class to display progress through a sequence of 
 * logical and numbered steps.
 * @author   Ruchi Verma <ruchi.verma@embitel.com>
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CheckoutStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      isLoggedIn: false
    };

  }

  componentWillMount() {
    let signInStatus = magnaCookies.getCookie('ECS_AC');
    //check if cookie exists and set the login status
    if (signInStatus) {
      this.setState({ isLoggedIn: true });
      this.handleNext();
    }
    else {
      this.setState({ isLoggedIn: false });
    }
  }

  //decrement the stepper
  handleBack = (numberOfSteps) => {
    this.setState(state => ({
      activeStep: state.activeStep - numberOfSteps,
    }));
  };

  // increments the stepper
  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  //place order button action
  handlePlaceOrder = () => {

  }

  // returns array of steps during checkout flow
  handleSteps = () => {
    let CheckoutComponentSelector = [
      {
        "type": "Sign In",
        "component": '',
      },

      {
        "type": "Review and Place Order",
        "component": <ReviewOrder activeStep={this.state.activeStep} activeStepName='Checkout' targetStep='PLACE ORDER' nextAction={this.handleNext} previousAction={this.handleBack} />,
      }
    ];
    return CheckoutComponentSelector;
  };

  //returns component based on the stepper index i.e, active step
  handleActiveComponent = (steps) => {
    const matchedComponent = steps.find((product, index) => {
      return (this.state.activeStep === index);
    });
    return matchedComponent.component;
  };

  render() {
    const steps = this.handleSteps();
    const CurrentComponent = this.handleActiveComponent(steps);
    if (this.state.isLoggedIn) {
      return (
        <div>
          {/* <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((product, index) => {
              return (
                <Step key={index}>
                  <StepLabel>
                    {product.type}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper> */}
          {CurrentComponent}
        </div>
      );
    }
    else {
      return <Redirect to={{ pathname: '/account', state: { redirectTo: this.props.location.pathname } }} />;

    }
  }
}

CheckoutStepper.propTypes = {
  classes: PropTypes.object,
};


/***Error Boundary Fallback Component */
const CheckoutStepperErrorFallbackComponent = ({ componentStack, error }) => {
  return (
    <InternalServerError500 />
  );
}


/********************************************************************************************f 
 * 50x Error Page Component for checkout error
 *******************************************************************************************/
const InternalServerError500 = (props) => (
  <div>
    <ErrorPage
      header={<GetLanguage value="Oops!"/>}
      text={<GetLanguage value="Something went wrong in checkout. We are working on the problem."/>} 
    />
  </div>
);


const CheckoutStepperWithErrorBoundary = withErrorBoundary(
  CheckoutStepper,
  CheckoutStepperErrorFallbackComponent
);



export default withStyles(styles)(CheckoutStepperWithErrorBoundary);
