/**
* Shopping Options Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckboxFilter from './CheckboxFilter';
import SubmenuFilter from './SubmenuFilter';
import SliderFilter from './SliderFilter';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoryService from '../../../../app/modules/category/CategoryService';
import { log } from 'util';
import { SET_LOADER_STATUS } from '../../../../app/framework/common/ReduxConstants';
// import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
    paper: {
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },    
    shoppingOptionsText: {
        paddingTop: "1rem",
        textTransform:'uppercase',
        fontSize:'13px',
        color:'#424242',
        fontWeight:'bold',
        marginBottom: '1em',
    },
    priceSlider: {
        padding: "2rem 0rem",
        textTransform:'uppercase',
        fontSize:'13px',
        color:'#424242',
        fontWeight:'bold',
    },
    progressStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progressDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

/***************************************************************
 * Component class for displaying all Shopping Options.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ShoppingOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: 'true'
        }
    }
    componentDidMount() {
        if (this.props.categoryId) {
            this.props.getCategoryFilters(this.props.categoryId);
        }
    }
    // componentWillReceiveProps(nextprops){
    //     if(nextprops.categoryId){
    //         this.props.getCategoryFilters(nextprops.categoryId);
    //     }
    // }

    render() {
        let { classes } = this.props;
        if (this.props.categoryFilter) {
            var filterOptions = Object.keys(this.props.categoryFilter);
        }
        if (this.props.categoryFilter.length === 0) {
            // return (
            //     <div style={{ zIndex: '999999999999', top: 0, position: 'fixed', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'auto' }}>
            //         <CircularProgress />
            //     </div>
            // )
            return null;
        }
        else {
            return (
                <Paper className={classes.paper} style={{ boxShadow: "none", backgroundColor:'#f8f8fa' }}>

                    {filterOptions && filterOptions.map((option, index) => {
                        //data of filter type will be stored in optionsObj
                        let optionObj = this.props.categoryFilter[option];
                        //array of filter type Id's will be stored in filterOptionIds
                        let filterOptionIds = Object.keys(Array.isArray(optionObj) ? optionObj[0] : optionObj);
                        //filterObj is sent to respective component which is used for selection purpose
                        let filterObj = { filterOptionName: option, filterOptionId: filterOptionIds[0], categoryId: this.props.categoryId };
                        return ((option === 'ParentCategory') ? '' : option === 'Category' ? (
                            <React.Fragment key={index}>
                                <Typography variant="h6" align="left" className={classes.shoppingOptionsText} gutterBottom>
                                    Category
                            </Typography>
                                <SubmenuFilter filterObject={filterObj} ParentCategory={Array.isArray(optionObj) ? optionObj[0] : optionObj} />
                            </React.Fragment>) : (option === 'Price') ? (
                                <React.Fragment key={index}>
                                    {localStorage.getItem('shopce-user') ?
                                        <div>
                                            <Typography variant="h6" align="left" className={classes.priceSlider}>
                                                {option}
                                            </Typography>
                                            <SliderFilter filterObject={filterObj} optionValues={optionObj} />
                                        </div>
                                        : ""
                                    }
                                </React.Fragment>) :
                                <React.Fragment key={index}>
                                    <Typography variant="h6" align="left" className={classes.shoppingOptionsText} gutterBottom>
                                        {option}
                                    </Typography>
                                    <CheckboxFilter filterObject={filterObj} optionValues={optionObj} />
                                </React.Fragment>);
                    }
                    )
                    }
                </Paper>
            );
        }
    }
}

/****************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    categoryFilter: state.get_category_filter_options
});

const mapDispatchToProps = dispatch => ({
    getCategoryFilters: (categoryId) => {
        let cs = new CategoryService();
        //dispatch(cs.getCategoryFilterOptionsActionCreator(categoryId));
    },
    callLoader: () => {
        // dispatch({ type: SET_LOADER_STATUS })
    }
});

const ShoppingOptionsWithStyles = withStyles(styles)(ShoppingOptions);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingOptionsWithStyles));