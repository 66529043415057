/**
* CartList Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles,MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import { Typography, withWidth } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Counter from '../../../app/components/common/Counter';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { formatPriceOfProduct } from '../../../app/framework/common/processString';
import PageTitle from '../../../app/components/common/PageTitle';
import { Link, Redirect } from 'react-router-dom';
//Redux related imports
import { connect } from 'react-redux';
import { decodeHTMLEntities } from '../../../app/framework/common/helpers';
import { mediaURLBasePath } from '../../../app/framework/common/helpers';
//Image dimensions fetched from configuration.
import getConfigValue from '../../../app/framework/config/configHandler';
//Price display formatting
import { formatPriceOfProductforCategoryListing } from '../../../app/framework/common/helpers';
import CartService from '../../../app/modules/cart/CartService';
import AccountService from '../../../app/modules/account/AccountService';
import UserMessage from '../../../app/components/messages/UserMessages';
import { withErrorBoundary } from 'react-error-boundary';
import Table from '@material-ui/core/Table';
import Store from '@material-ui/icons/Store';
import PersonOutline from '@material-ui/icons/PersonOutline';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import GetLanguage from '../../../library/GetLanguage';
import CategoryService from '../../../app/modules/category/CategoryService';
import ProductCatalogSlider from '../layouts/blocks/carousel/ProductCatalogSlider';
import toRenderProps from 'recompose/toRenderProps';
import ContentLoader from '../layouts/blocks/noncarousel/NonCarousel';
import UnitSelector from '../uom/UnitSelector';
import './cart.css'
const styles = theme => ({
  headerBarStylesDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mdDeviceUpDisplayNone: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  image: {
    width: 80,
    height: 90,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  shoppingCartGrid: {
    padding: '0rem 1rem',
  },
  textDecorationNone: {
    textDecoration: 'none',
    fontSize: '10px',
    marginBottom:'2px',
    width:'100%',
    float:'left',
  },
  productSummaryBg: {
    // backgroundColor: '#FBFBFF',
    margin: '10px',
    border: '1px solid #E6E7ED',
    borderRadius: 6
  },
  section1: {
    margin: `0px 0px ${theme.spacing.unit * 2}px 0px`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing.unit * 2}px 0px ${theme.spacing.unit * 2}px 0px`,
    },
  },
  deleteStyle: {
    cursor: 'pointer',
    color: '#2196f3',
    lineHeight: 0.5
  },
  moveToWishlistStyle: {
    cursor: 'pointer',
    color: '#2196f3',
    marginBottom: '10px',
    lineHeight: 0.5
  },
  subtotalDivider: {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 0}px`,
  },
  tableHeadStyle:
    {
      backgroundColor: '#E5E7E9',
      fontWeight: 'bold'
    },
  actionSection:
    {
      margin: '10px 0px 10px -30px'
    },
  fabContinueShopping:
    {
      backgroundColor: '#fff',
      border: '1px solid #4B61ED',
      color: '#4B61ED',
      borderRadius: 20,
      textTransform: 'capitalize'
    },
  iconStyle: {
    padding: '0',
    fontSize: '1.25rem'
  },
  titleText: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#072D77',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    'display': '-webkit-box'
  },
  indicator: {
    backgroundColor: 'white',
  },
  cartCounter: {
    position: 'relative',
    minWidth: '100px'
  },
  headerStyle:{
    background:'none !important',
  },
  pageHeading:{
    color:'#1d2d5f',
    margin:'10px 0 0 0',
  },
  appBarStyle:{
    background:'none !important',
    margin:'0 0 15px 0',
  },
  lineItem : {
    margin:'0 0 10px 0',
    background:'#fff',
    padding:'10px',
    border:'1px solid #dbdbdb',
    borderRadius:'5px',    
  },
  productName : {
    fontSize:'14px',
    fontWeight: '600',
  },
  lastUpdatedTxt:{
    fontSize:'12px',
  },
  lineItemBtm : {
    borderTop:'1px solid #e7e7e7',
    margin:'20px 0 0 0',
    padding:'10px 0 0 0',
  },
  removeBtn:{
    textTransform:'uppercase',
    fontSize:'12px',
    textDecoration:'underline',
    color:'#1d2d5f',
  },
  qtyPrice:{
    fontSize:'16px',
    fontWeight:'bold',
    color:'#1d2d5f',
  },
  unitPrice : {
    fontSize:'12px',
    color:'#a4a0a0',
  },
  qtyTxt : {
    fontSize:'12px',
    padding:'8px 0 0 0',
  },
  txtAlignRt : {
    textAlign:'right',
  },
  lineItemSec : {
    padding:'0 0 0 30px',
  },
  summarySec : {
    background:'#fff',
    margin:'0 10px',
    padding:'15px',
  },
  orderTitle : {
    fontSize:'16px',
    color:'#424242',
    textTransform:'uppercase',
    textAlign:'left',
    padding:'0 0 10px 0',
    borderBottom:'1px solid #ebebeb',
    margin:'0 0 15px 0',
    fontWeight:'normal',
  },
  checkoutBtn:{
    textAlign:'center',
  },
  summeryTxt:{
    marginBottom:'10px',
    fontSize:'12px',
  },
  subtotal : {
    fontSize:'14px',
  },
  totalPriceTxt:{
    fontWeight:'bold',
    fontSize:'14px',
    borderTop:'1px solid #ebebeb',
    paddingTop:'10px',
  },
  totalPrice:{
    fontSize:'16px',
  }, 
  itemQtyTxt : {
    fontSize:'14px',
    fontWeight:'normal',
    color:'#1d2d5f',
  }, 
});
const theme = createMuiTheme({
  overrides: {
      MuiPaper:{
          root:{
              boxShadow:'none !important',
          },
      },
     
      MuiTabs: {
        root:{
           
        },
        indicator: {
          backgroundColor: '#f65e5d !important',
          height:'3px',
        },
        flexContainer:{
            float:'right',
        }
      },
      MuiButton:{
        containedSecondary:{
            backgroundColor:'transparent !important',
            fontSize:'12px  !important',
            padding:'0 10px 0 0',
            float: 'right',
            boxShadow:'none',
            color:'#1d2d5f',
            textTransform:'inherit',
            textDecoration:'underline',
        },
    },
      MuiTab: {   
          root:{
            
          },     
        selected: {          
          color: '#1d2d5f !important',      
        }
      }
  }
});
/***************************************************************
 * Component class for displaying Cart List items added to cart.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CartList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      continueDisabled: false,
      selectedCartTab: 0,
      crosssell_products: '',
      isCounterUpdated: false,
      updatedRowIndex: -1
    };
    this.isCounterUpdated = false;
    this.qtyChanged = this.qtyChanged.bind(this);
  }

  componentDidMount() {
    //dispatch props to get cart info
    //this.props.displayMiniCart();
    // if (!this.props.loader_status && !Object.keys(this.props.miniCartDetails).length && localStorage.getItem('shopce-user')) {
    //   this.props.loader();
    // }
    if (this.props.cart_product_count !== 0) {
      this.props.GetCrossSellProducts();
    }
  }

  componentWillReceiveProps(nexprops) {
    // if (Object.keys(nexprops.miniCartDetails).length && nexprops.loader_status) {
    //   this.props.loader();
    // }
    this.setState({ crosssell_products: nexprops.crosssell_products })
  }
  getSlidesToShow = (width) => {
    switch (width) {
      case 'xs':
        return 2;
      case 'sm':
        return 3;
      case 'md':
        return 5;
      case 'lg':
        return 5;
      default:
        return 6;
    }
  }
  //place order button action
  handleProceedToCheckout = () => {
    //disable button on continue to next step
    this.setState({ continueDisabled: true });
  }

  // Set move_to_wishlist_state to null before the form unmounts.
  componentWillUnmount() {
    this.props.resetMoveToWishlistState();
  }


  //Callback after setting the value in Counter Component
  // onQuantiyCounterChange = (quantity) => {
  //   this.setState({ productQuantity: quantity });
  // }

  onDeleteLinkClick = (e, item_id) => {
    e.preventDefault();
    this.props.onDeleteItemFromCart(item_id, this.props.miniCartDetails.current_cart.quote_id);
  }

  onMoveToWishlistLinkClick = (e, item_id) => {
    e.preventDefault();
    this.props.onMoveToWishlistFromCart(item_id);
  }

  //Callback after setting the value in Counter Component
  onQuantiyCounterChange = (quantityChanged, item_id) => {
    this.setState({ productQuantity: quantityChanged });
    //Dispatch to change the quantity in the cart.
    this.props.onEditItemFromCart(quantityChanged, item_id);
  }
  processProductOptions = (product_option) => {
    if (product_option === null) return "";
    let configurable_item_options_list = product_option.extension_attributes.configurable_item_options;
    let productOptionsString = [];
    for (let index = 0; index < configurable_item_options_list.length; index++) {
      productOptionsString.push(configurable_item_options_list[index].value);
    }
    //Return product option string with separators.
    return productOptionsString.join(" / ");
  }
  qtyChanged(index, counterUpdated) {
    this.setState({
      isCounterUpdated: counterUpdated,
      updatedRowIndex: index
    })
  }
  handleEmptyCart() {
    this.props.onEmptyingCart()
  }
  render() {
    if (!this.props.miniCartDetails) {
      return (<ContentLoader isLoading={true} />)
    }
    const { miniCartDetails, cart_product_count, classes } = this.props;
    console.log('minicart '+JSON.stringify(miniCartDetails))
    const orderParameters = getConfigValue("order");
    const productImgDimensions = getConfigValue("product");
    const WithWidth = toRenderProps(withWidth());
    //Prepare messages for display after moving to Wishlist from Cart.
    var moveToWishlistStatusMessage;
    if (!localStorage.getItem('shopce-user')) {
      return (<Redirect to="/account" />)
    }
    if (this.props.move_to_wishlist_state === true) {
      const object = { messageType: 'sucess', message: 'Product is moved to Wishlist' }
      moveToWishlistStatusMessage = (this.props.move_to_wishlist_state !== null) ? <UserMessage {...object} /> : null;
    }
    else if (this.props.move_to_wishlist_state === false) {
      const object = { messageType: 'error', message: 'Product is not moved to Wishlist' }
      moveToWishlistStatusMessage = (this.props.move_to_wishlist_state !== null) ? <UserMessage {...object} /> : null;
    }
    const hpcLabel = 'HPC(' + miniCartDetails.hpcCount + ')';
    const foodsLabel = 'Foods(' + miniCartDetails.foodsCount + ')';
    let discountFlag = false;
    let discountTotal = 0;

    if (miniCartDetails.current_cart) {
      miniCartDetails.current_cart&& miniCartDetails.current_cart.items&&miniCartDetails.current_cart.items.map(item => {
        if (parseInt(item.base_discount_amount) > 0) {
          discountFlag = true;
          discountTotal = discountTotal + Number(item.base_discount_amount)
        }
      })
      return (
        <div className="cartPage">
          <Grid item xs={12} classes={classes.headerStyle}>
                <Typography variant="h6" align="left" className={classes.pageHeading}>
                <GetLanguage value='My Cart' />
                    </Typography>
                </Grid>
                {/* <MuiThemeProvider theme={theme}>
            <AppBar position="static" className={classes.appBarStyle}>
              <Tabs classes={{ indicator: classes.indicator }} value={miniCartDetails.current_cart_index} onChange={(e, selectedCartTab) => this.props.changeCart(selectedCartTab)} aria-label="simple tabs example">
                <Tab style={{ background: "#FFF",color:'#a4a0a0', }} label={hpcLabel} />
                <Tab style={{ background: "#FFF",color:'#a4a0a0', }} label={foodsLabel} />
              </Tabs>
            </AppBar>
          </MuiThemeProvider> */}
          {cart_product_count === 0 ?          
            <React.Fragment>
              {moveToWishlistStatusMessage}
              <PageTitle title='Shopping Cart' />
              <Grid container alignItems="center" direction="column">
                <Grid item xs>
                  <Typography variant="subtitle1" gutterBottom align='center'>
                    {<GetLanguage value="YOUR SHOPPING BAG IS EMPTY" />}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="caption" gutterBottom align='center'>
                    {<GetLanguage value="Don't let it stay empty. Add items from your wishlist" />}</Typography>
                </Grid>
                <CardContent>
                  <CardActions>
                    <Button size="large" component={Link} to="/" variant="contained" color="primary" aria-label="Checkout">
                      {<GetLanguage value="CONTINUE SHOPPING" />}
                    </Button>
                  </CardActions>
                </CardContent>
              </Grid>
            </React.Fragment>
            :
            <React.Fragment>
              <div className="cart-item-display">
                <Grid container direction='row' justify='space-between'>
                  <Grid item md={8}>
                  <MuiThemeProvider theme={theme}>
                  <AppBar position="static" className={classes.appBarStyle}>
                    <Tabs classes={{ indicator: classes.indicator }} value={miniCartDetails.current_cart_index} onChange={(e, selectedCartTab) => this.props.changeCart(selectedCartTab)} aria-label="simple tabs example">
                      <Tab style={{ background: "#FFF",color:'#a4a0a0', }} label={hpcLabel} />
                      <Tab style={{ background: "#FFF",color:'#a4a0a0', }} label={foodsLabel} />
                    </Tabs>
                  </AppBar>
                  </MuiThemeProvider>
                    {miniCartDetails && miniCartDetails["miniCartDetails"] && miniCartDetails["miniCartDetails"].map((product, index) =>
                      <Grid container direction="row" className={classes.lineItem}>
                        <Grid item md={2}>
                          <img className={classes.img}
                            alt={decodeHTMLEntities(product.name)}
                            src={mediaURLBasePath + product.extension_attributes.image_url} />
                        </Grid>
                        <Grid container direction="row" className={classes.lineItemSec} justify="flex-end" item md={10}>
                              <Grid item md={10}>
                                <Link className={classes.textDecorationNone} to={product.product_url}>
                                  <Typography className={classes.titleText}>
                                    {parseInt(product.sku)}
                                  </Typography>
                                </Link>
                                <Link className={classes.textDecorationNone} to={product.product_url}>
                                  <Typography className={classes.productName}>
                                    {decodeHTMLEntities(product.name)}
                                  </Typography>
                                </Link>
                                <Grid className={classes.lastUpdatedTxt}>
                                  <div>Last Updated By:{product.modified_by ? "Admin" : "Store"}</div>
                                </Grid>
                              </Grid>
                              <Grid item md={2} className={classes.txtAlignRt}>
                                <Grid className={classes.qtyPrice}>{formatPriceOfProductforCategoryListing(product.price * parseInt(product.qty))}</Grid>
                                <Grid className={classes.unitPrice}>{formatPriceOfProductforCategoryListing(product.price)}</Grid>
                              </Grid>
                              
                          <Grid md={12} container justify="flex-start" className={classes.lineItemBtm}>
                            <Grid item md={2}>
                              <UnitSelector unitSelector={this.state.unitSelector} />
                            </Grid>
                            <Grid item md={1} className={classes.qtyTxt}>Qty</Grid>
                            <Grid item md={3}>                                                           
                                <Counter cartIndex={miniCartDetails.current_cart_index} rowIndex={index} qtyChanged={this.qtyChanged}
                                  value={parseInt(product.qty)} min_number={1}
                                  item_id={product.item_id} max_number={orderParameters.maxQuantity}
                                />
                            </Grid>
                            <Grid item md={4}>
                              </Grid>
                            <Grid item md={2} className={classes.txtAlignRt}>
                            <Typography style={{ cursor: 'pointer', textAlign:'right' }} className={classes.removeBtn} onClick={(event) => this.onDeleteLinkClick(event, product.item_id)}>
                            Remove
                            </Typography>
                              
                            </Grid>
                          </Grid>


                          </Grid>                          
                      </Grid>
                    )
                    }
                    <MuiThemeProvider theme={theme}>
                      <Grid container>
                        <Grid md={6}>
                            <Typography className={classes.itemQtyTxt}>
                                    1 items
                            </Typography>
                        </Grid>
                        <Grid md={6}>
                        <Button variant="contained" color="secondary" component={Link} to="/cart" onClick={this.handleEmptyCart.bind(this)}
                      aria-label="Empty Cart">{<GetLanguage value={miniCartDetails.current_cart_index === 0 ? "Empty Cart HPC" : "Empty Cart Foods"} />}</Button>
                        </Grid>
                      </Grid>                    
                    </MuiThemeProvider>                    
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.summarySec}>
                      <h3 className={classes.orderTitle}>Order Summary</h3>
                      <Grid container className={classes.summeryTxt+' '+classes.subtotal}>
                        <Grid md={6}>{<GetLanguage value="Net Amount" />}</Grid>
                        <Grid md={6} className={classes.txtAlignRt}>{formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.subtotal : 0)}</Grid>
                      </Grid>
                      {discountFlag ?
                        <Grid container className={classes.summeryTxt}>
                        <Grid md={6}>{<GetLanguage value="Discount" />}</Grid>
                        <Grid md={6} className={classes.txtAlignRt}>{formatPriceOfProduct(discountTotal)}</Grid>  
                        </Grid>                      
                        : ""}
                        <Grid container className={classes.summeryTxt}>
                        <Grid md={6}>{<GetLanguage value="Tax after discount" />}</Grid>
                        <Grid md={6} className={classes.txtAlignRt}>{formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.tax_amount ? miniCartDetails.current_cart.tax_amount : 0 : 0)}</Grid>
                      </Grid>
                      <Grid container className={classes.totalPriceTxt}>
                        <Grid md={6}>{<GetLanguage value="Total Price" />}</Grid>
                        <Grid md={6} className={classes.txtAlignRt+' '+classes.totalPrice}>{formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.grand_total : 0)}
                      </Grid>
                      </Grid>
                      
                      <Grid container className={classes.checkoutBtn}>
                      <Button variant="contained" color="secondary" component={Link} to="/checkout"
                        aria-label="Proceed to Checkout" style={{ background: '#F73455',padding:'7px 20px',margin:'10px auto', textTransform:'uppercase' }}>{<GetLanguage value="PROCEED TO CHECKOUT" />}</Button>
                      </Grid>                      
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mdDeviceUpDisplayNone}>
                {
                  miniCartDetails && miniCartDetails["miniCartDetails"] && miniCartDetails["miniCartDetails"].map((product, index) =>
                    <Card className={classes.productSummaryBg} key={index}>
                      <CardContent>
                        <Grid container alignItems="flex-start" direction="column" spacing={16}>
                          <Grid item >
                            <Grid container direction="row" wrap="nowrap" justify="center" alignItems="center" spacing={24}>
                              <Grid item >
                                <ButtonBase className={classes.image}>
                                  <img className={classes.img}
                                    width={productImgDimensions.image.dimension.width}
                                    height={productImgDimensions.image.dimension.height}
                                    alt={decodeHTMLEntities(product.name)}
                                    src={mediaURLBasePath + product.extension_attributes.image_url} />
                                </ButtonBase>
                              </Grid>
                              <Grid item>
                                <Link className={classes.textDecorationNone} to={product.product_url}>
                                  <Typography variant="subtitle1">
                                    {parseInt(product.sku)}:{decodeHTMLEntities(product.name)}
                                  </Typography>
                                </Link>
                                <ProductOptions product_options={this.processProductOptions(product.product_option ? product.product_option : null)} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" spacing={16} justify="flex-start" alignItems="center">
                              <Grid item >
                                <Counter
                                  cartIndex={miniCartDetails.current_cart_index}
                                  item_id={product.item_id}
                                  value={parseInt(product.qty)}
                                  min_number={1}
                                  onCounterChange={(quantityChanged) => this.onQuantiyCounterChange(quantityChanged, product.item_id)}
                                  max_number={orderParameters.maxQuantity}
                                />                                  
                              </Grid>
                              <Grid item>
                                <Button aria-label="Add" onClick={(event) => this.onDeleteLinkClick(event, product.item_id)}>
                                  {/* <ClearIcon className={classes.iconStyle} color='secondary' /> */}
                                  <DeleteOutline style={{ cursor: 'pointer' }} />
                                </Button>
                              </Grid>
                              <Grid item>
                                {product.modified_by ? <PersonOutline /> : <Store />}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">{<GetLanguage value="Price" />} {formatPriceOfProductforCategoryListing(product.price)}</Typography>
                          </Grid>
                          <Grid item>
                            {/* <Typography variant="subtitle2"> Sub Total : ฿{formatPriceOfProduct(product.price * product.qty)}</Typography> */}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )
                }
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2" >
                            {<GetLanguage value="Sub Total" />}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" >
                            {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.subtotal : 0)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2" >
                            {<GetLanguage value="Tax" />}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.tax_amount ? miniCartDetails.current_cart.tax_amount : 0 : 0)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2" >
                            {<GetLanguage value="Total" />}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {formatPriceOfProductforCategoryListing(miniCartDetails.current_cart ? miniCartDetails.current_cart.grand_total : 0)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <Grid container direction="column" justify="center" alignItems="center" spacing={16} style={{ margin: '20px 0px' }}>
                  <Grid item>
                    <Button variant="contained" color="secondary" size="large" className={classes.fabContinueShopping+' clearcartTxt'}
                      onClick={this.handleEmptyCart.bind(this)}
                      aria-label="Empty Cart">{<GetLanguage value={miniCartDetails.current_cart_index === 0 ? "Empty Cart HPC" : "Empty Cart Foods"} />}</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="secondary" size="large" className={classes.fabContinueShopping}
                      component={Link} to="/"
                      aria-label="Continue Shopping">{<GetLanguage value="Continue Shopping" />}</Button>
                  </Grid>
                  <Grid item>
                    <Fab aria-label="Proceed to Checkout" variant="extended" style={{ textTransform: 'capitalize' }}
                      type="submit" size="large" component={Link} to="/checkout" >{<GetLanguage value="Proceed to Checkout" />}</Fab>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>}
          {cart_product_count !== 0 && this.state.crosssell_products !== '' && this.state.crosssell_products.length > 0 && this.state.crosssell_products[0].items !== undefined && this.state.crosssell_products[0].items.length > 0 ?
            <React.Fragment>
              <Typography variant="h5" align='center'>
                {<GetLanguage value="CrossSell Products" />}</Typography>
              <WithWidth>
                {({ width }) =>
                  <ProductCatalogSlider
                    autoplay={false}
                    slidesToShow={this.getSlidesToShow(width)}
                    bannerObject={[]} framePadding="0"
                    cellSpacing={0} slideWidth={1.0}
                    renderBottomCenterControls={() => { }}
                    cmsPageWidgetDataObject={this.state.crosssell_products[0].items}
                  />}
              </WithWidth>
            </React.Fragment>
            : ''
          }
        </div>
      );
    }
    return null;
  }

}



/***************************************************************
 * Component class for displaying configurable options
* @author   Sameena Shaik <shaik.sameena@embitel.com>
      ***************************************************************/
class ProductSummary extends React.Component {
  render() {
    const { classes } = this.props;
    const { cart_product_count } = this.props;

    const { order_summary_details, proceedToCheckout } = this.props;

    if (order_summary_details) {
      return (
        <Card className={classes.productSummaryBg}>
          <CardContent>
            <Grid container >
              <Grid item xs={6}>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={16}>
                  <Grid item xs>
                    <Typography variant="subtitle1">{<GetLanguage value="Price (" />}{cart_product_count} {<GetLanguage value="items)" />}</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">{<GetLanguage value="Tax" />}</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">{<GetLanguage value="Delivery Charges" />}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" justify="center" alignItems="flex-end" spacing={16}>
                  <Grid item xs>
                    <Typography variant="subtitle1">
                      {formatPriceOfProductforCategoryListing(order_summary_details.subtotal ? order_summary_details.subtotal : 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">
                      {formatPriceOfProductforCategoryListing(order_summary_details.shipping_tax_amount ? order_summary_details.shipping_tax_amount : 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">
                      {formatPriceOfProductforCategoryListing(order_summary_details.shipping_amount ? order_summary_details.shipping_amount : 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.subtotalDivider} />
            <Grid container>
              <Grid item xs>
                <Typography variant="body2" gutterBottom>{<GetLanguage value="Subtotal" />}</Typography>
              </Grid>
              <Grid item xs>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography variant="body2" gutterBottom>
                      {formatPriceOfProductforCategoryListing(order_summary_details.grand_total ? order_summary_details.grand_total : 0)}
                    </Typography>
                  </Grid>
                  <Divider className={classes.subtotalDivider} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="stretch">
              <Button size="large" variant="contained" color="primary" onClick={proceedToCheckout}
                fullWidth component={Link} to="/checkout" disabled={this.props.continueDisabled}
                aria-label="Proceed to Checkout">{<GetLanguage value="PROCEED TO CHECKOUT" />}</Button>
            </Grid>
          </CardContent>
        </Card>
      );
    }
    else {
      return null;
    }
  }
}

/***************************************************************
 * Component class for displaying Mini Cart items
* @author   Sameena Shaik <shaik.sameena@embitel.com>
      ***************************************************************/
class ShoppingCartItemsMiniCart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productQuantity: 1,
      qty: this.props.quantity
    };
  }

  // //Callback after setting the value in Counter Component
  // onQuantiyCounterChange = (quantity) => {
  //   this.setState({ productQuantity: quantity });
  // }
  componentWillReceiveProps(nextProps) {
    this.setState({ qty: nextProps.quantity })
  }
  onDeleteLinkClick = (e, item_id) => {
    e.preventDefault();
    this.props.onDeleteItemFromCart(item_id, this.props.miniCartDetails.current_cart.quote_id);
  }

  onMoveToWishlistLinkClick = (e, item_id) => {
    e.preventDefault();
    this.props.onMoveToWishlistFromCart(item_id);
  }

  //Callback after setting the value in Counter Component
  onQuantiyCounterChange = (quantityChanged, item_id) => {
    this.setState({ productQuantity: quantityChanged });

    //Dispatch to change the quantity in the cart.
    this.props.onEditItemFromCart(quantityChanged, item_id);
  }

  processProductOptions = (product_option) => {
    if (product_option === null) return "";
    let configurable_item_options_list = product_option.extension_attributes.configurable_item_options;
    let productOptionsString = [];
    for (let index = 0; index < configurable_item_options_list.length; index++) {
      productOptionsString.push(configurable_item_options_list[index].value);
    }
    //Return product option string with separators.
    return productOptionsString.join(" / ");
  }

  displayMoveToWishlistLink = (props) => {
    if (!AccountService.isSignedInUser()) {
      return null;
    }
    else {
      return (
        <Grid item>
          <Typography variant="caption" className={props.classes.moveToWishlistStyle} onClick={(event) => this.onMoveToWishlistLinkClick(event, props.item_id)}>Move to Wishlist
          </Typography>
        </Grid>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { miniCartDetails } = this.props;
    const {
      itemIndex,
      productName,
      itemPrice,
      quantity,
      item_id,
      image_url,
      product_url,
      product_option
    } = this.props;
    //calculate products length
    let itemsInCart = miniCartDetails["miniCartDetails"].length;
    //check if iterating item is not last item in the cart
    let nextItemExists = (itemIndex < itemsInCart - 1);
    //create separator between items
    if (nextItemExists) {
      var itemsDivider = <Divider className={classes.section1} />;
    }
    const orderParameters = getConfigValue("order");

    return (
      <Grid container direction="column" className={classes.shoppingCartGrid}>
        <Grid item xs>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={6} md={3} lg={2}>
                  <ButtonBase className={classes.image}>
                    <img className={classes.img}
                      alt={decodeHTMLEntities(productName)}
                      src={mediaURLBasePath + image_url} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={16}>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Link className={classes.textDecorationNone} to={product_url}>
                            <Typography variant="subtitle1">
                              {decodeHTMLEntities(productName)}
                            </Typography>
                          </Link>
                        </Grid>
                        <Grid item>
                          <ProductOptions product_options={this.processProductOptions(product_option)} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" className={classes.deleteStyle} onClick={(event) => this.onDeleteLinkClick(event, item_id)}>Delete
                    </Typography>
                    </Grid>
                    {
                      this.displayMoveToWishlistLink(this.props)
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={4}>
              <Grid container justify="flex-start" alignItems="flex-start">
                <Grid item>
                  <Hidden mdUp>
                    <Typography variant="body2">Price: {itemPrice}</Typography>
                  </Hidden>
                  <Hidden only={['sm', 'xs']}>
                    <Typography variant="body2">{itemPrice}</Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={2}>
              <Grid container justify-xs-flex-end="true" justify="flex-start" alignItems="flex-start">
                <Grid item>
                  <Counter value={this.state.qty} min_number={1} item_id={item_id} max_number={orderParameters.maxQuantity}
                    onCounterChange={(quantityChanged) => this.onQuantiyCounterChange(quantityChanged, item_id)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          {itemsDivider}
        </Grid>
      </Grid>
    );
  }
}

/***************************************************************
 * Component class for displaying configurable options
* @author   Sameena Shaik <shaik.sameena@embitel.com>
    ***************************************************************/
class ProductOptions extends React.Component {
  render() {
    let { product_options } = this.props;
    return (
      <div>
        <Typography color="textSecondary">{product_options}</Typography>
      </div>
    );
  }
}

//returns total product count
function getProductTotalCount(state_get_minicart_details) {
  if (state_get_minicart_details) {
    return state_get_minicart_details.cart_product_count
  }
  else {
    return 0;
  }
}

//returns total product cost
function getProductTotalCost(state_get_minicart_details) {
  if (state_get_minicart_details) {
    return state_get_minicart_details.cart_product_cost
  }
  else {
    return 0;
  }
}


/***Error Boundary Fallback Component */
const CartListErrorFallbackComponent = ({ componentStack, error }) => {
  return (
    <PageTitle title="Failed to load cart list, please try again." />
  );
}

const CartListWithErrorBoundary = withErrorBoundary(
  CartList,
  CartListErrorFallbackComponent
);


const mapStateToProps = state => ({
  loader_status: state.get_loader_status,
  miniCartDetails: state.get_minicart_details,
  cart_product_count: getProductTotalCount(state.get_minicart_details),
  cart_product_cost: getProductTotalCost(state.get_minicart_details),
  order_summary_details: state.get_order_summary_details.orderSummaryDetails,
  move_to_wishlist_state: state.move_to_wishlist_from_cart_state.moveToWishlistStatus,
  crosssell_products: state.get_crosssell_products,
});

const mapDispatchToProps = dispatch => ({
  displayMiniCart: () => {
    let cartService = new CartService();
    dispatch(cartService.GetDisplayMiniCartActionCreator());
  },
  onDeleteItemFromCart: (item_id, cartId) => {
    let cs = new CartService();
    dispatch(cs.DeleteFromCartActionCreator(item_id, cartId));
    let cas = new CategoryService();
    dispatch(cas.GetCrossSellProductsActionCreator());
  },
  onEmptyingCart: () => {
    let cs = new CartService();
    dispatch(cs.EmptyCartActionCreator());
    let cas = new CategoryService();
    dispatch(cas.GetCrossSellProductsActionCreator());
  },
  onMoveToWishlistFromCart: (item_id) => {
    let cs = new CartService();
    dispatch(cs.moveToWishlistActionCreator(item_id));
  },
  resetMoveToWishlistState: () => {
    let cs = new CartService();
    dispatch(cs.resetMoveToWishlistActionCreator());
  },
  onEditItemFromCart: (quantityChanged, item_id) => {
    let cs = new CartService();
    dispatch(cs.EditQuantityInCartActionCreator(item_id, quantityChanged));
  },
  loader: () => {
    dispatch({ type: 'SET_LOADER_STATUS' });
  },
  changeCart: (index) => {
    dispatch({ type: "CHANGE_CART", index });
    let cs = new CategoryService();
    dispatch(cs.GetCrossSellProductsActionCreator());
  },
  GetCrossSellProducts: () => {
    let cs = new CategoryService();
    dispatch(cs.GetCrossSellProductsActionCreator());
  }
});

const CartListWithStyles = withStyles(styles)(CartListWithErrorBoundary);
export default connect(mapStateToProps, mapDispatchToProps)(CartListWithStyles);