/**
* Order Success Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import dateformat from 'dateformat';
import { Print } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { Hidden } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Redux related imports
import { connect } from 'react-redux';
import getConfigValue from '../../../app/framework/config/configHandler';
import { decodeHTMLEntities } from '../../../app/framework/common/helpers';
import { mediaURLBasePath } from '../../../app/framework/common/helpers';
import AccountService from '../../../app/modules/account/AccountService';
import RequisitionButton from './RequisitionButton';
import { NotificationManager } from 'react-notifications';
import CartService from '../../../app/modules/cart/CartService';
import RequisitionService from '../../../app/modules/requisition/RequisitionService';
//Price display formatting
import { formatPriceOfProductforCategoryListing } from '../../../app/framework/common/helpers';
import { getItemFromLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';
import GetLanguage from '../../../library/GetLanguage';
import ContentLoader from '../layouts/blocks/noncarousel/ContentLoader';
const styles = theme => ({
    card: {
        width: '100%',
        padding: '10px 10px 10px 15px',
        // margin: "8px"
    },
    spaceTop: {
        padding: '1rem 0rem',
    },
    image: {
        width: 70,
        maxHeight: 80
    },
    tableFontStyle: {
        minWidth: '120px'
    },
    counterDiv: {
        border: '1px solid #A9A9A9',
        borderRadius: '18px',
        padding: '0px 10px',
        maxWidth: '100px',
    },
    counterButton: {
        padding: '5px 0px',
        minWidth: 'auto',
    },
    iconStyle: {
        padding: '0',
        fontSize: '1.25rem'
    },
    quantity: {
        width: '20px',
        padding: '0.45rem',
        textAlign: 'center',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        border: '0px',
        '&:focus': {
            outline: 'none'
        },
    },
    buttonalign:{
        marginLeft:'10px'
    }
});
/***************************************************************
 * Component class for View Requisition.
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ViewRequisition extends React.Component {

    handleDeleteReqList = () => {
        if (this.props.getReqListId) {
            this.props.deleteRequisitionList(this.props.getReqListId);
        } else {
            NotificationManager.error('Error, Please try after some time');
        }
    }

    handleAllItems = (e) => {
        let arrayItems = [];
        if (e.target.checked) {
            arrayItems = Object.keys(this.props.selectedEntity);
        }
        this.props.setSelectedItemsInReqList(arrayItems);
    }
    handleDeleteReqItems = () => {
        if (this.props.selectedItemInReq.length === 0) {
            NotificationManager.info('Select atleast one Item');
            return false;
        }
        if (this.props.getReqListId) {
            let deleteObj = {
                "itemIds": this.props.selectedItemInReq.join(),
                "requisitionListId": this.props.getReqListId
            }
            this.props.deleteItemsInReqList(deleteObj);
        }
    }
    handleAddToCartFromReq = () => {
        if (this.props.selectedItemInReq && this.props.selectedItemInReq.length
            && this.props.getReqListId && this.props.getReqListId) {
            let itemsInselectedEntity = Object.values(this.props.selectedEntity);
            let itemObj = itemsInselectedEntity.filter((row) => {
                if (this.props.selectedItemInReq.indexOf(row.item_id) !== -1) {
                    return {
                        "item_id": row.item_id,
                        "sku": row.sku,
                       // "requisition_list_id": row.requisition_list_id,
                        "qty": row.qty,
                       // "store_id": row.store_id,
                        //"added_at": null
                    };
                }
                return null;
            });
            this.props.addReqItemToCart(itemObj);
        }
        else if (this.props.selectedItemInReq && this.props.selectedItemInReq.length === 0) {
            NotificationManager.info('Select atleast 1 item for Adding to Cart');
        }

    }
    handleUpdateItemsInReq = () => {
        if (this.props.selectedItemInReq.length === 0) {
            NotificationManager.info('Select atleast one Item');
            return false;
        }
        if (this.props.getReqListId) {
            let currentReqList = this.props.requisitionList[this.props.getReqListId];
            let updatedProductDetails = [];
            let userInfo = getItemFromLocalStorage('shopce-user');
            let now = new Date();
            let itemsInselectedEntity = Object.values(this.props.selectedEntity);
            itemsInselectedEntity.map((row) => {
                if (this.props.selectedItemInReq.indexOf(row.item_id) !== -1) {
                    let productObject = {};
                    productObject.item_id = row.item_id;
                    productObject.sku = row.sku;
                    productObject.qty = 2;
                    updatedProductDetails.push(productObject);
                }
            });
            let createReqObject = {
                "requisitionList": {
                    "id": currentReqList.entity_id,
                    "customer_id": userInfo.customerId,
                    "name": currentReqList.name,
                    "updated_at": dateformat(now, 'isoDate') + ' ' + dateformat(now, 'isoTime'),
                    "description": currentReqList.description,
                    "items": updatedProductDetails
                }
            }
            this.props.createNewRequisite(createReqObject);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.requisitionList !== this.props.requisitionList) {
            this.props.setSubMenu(4);
        }
        if (nextProps.addedReqItemsToCart !== this.props.addedReqItemsToCart) {
            this.props.refreshMinicart();
        }
    }
    render() {
        if(!this.props.selectedEntity){
            return (<ContentLoader isLoading={true} />)
        }
        const { classes } = this.props;
        const { selectedEntity, selectedItemInReq, requisitionList } = this.props;
        if (selectedEntity === '') return null;
        let itemsCount = selectedEntity && Object.values(selectedEntity).length;

        let currentReqList = requisitionList[this.props.getReqListId];
        return (
            <React.Fragment>
                <Grid container direction='column' alignItems='flex-start'>
                    <Grid item>
                        <Typography variant='h4'>{currentReqList ? decodeHTMLEntities(currentReqList.name) : ''}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle1'>{currentReqList ? decodeHTMLEntities(currentReqList.description) : ''}</Typography>
                    </Grid>
                </Grid>
                {itemsCount !== 0 &&
                    <React.Fragment>
                        <Grid container direction='row' justify='flex-end'>
                            <Grid item>
                                <ReactToPrint trigger={() => <Button color='primary'><Print color='error' />
                                    {<GetLanguage value='Print' />}</Button>} content={() => this.componentRef} />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justify='space-between'>
                            <Grid item>
                                <Typography>{itemsCount === 1 ? itemsCount + 'item' : itemsCount + 'items'}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row'>
                                    <Grid item>
                                    <Button component='span'>
                                        <FormControlLabel control={
                                            <Checkbox onChange={(e) => this.handleAllItems(e)} className={classes.buttonalign}
                                                checked={selectedItemInReq.length === itemsCount} color="default" value={'itemsCount'} />
                                        } label="Select all" />
                                    </Button>
                                    </Grid>
                                    <Grid item><Button color='secondary' onClick={this.handleDeleteReqItems}>{<GetLanguage value='Remove Selected' />}</Button></Grid>
                                    <Grid item><RequisitionButton plp={false} buttonName={<GetLanguage value='Move Selected'/>} /></Grid>
                                    <Grid item><RequisitionButton plp={false} buttonName={<GetLanguage value='Copy Selected'/>} /></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <PrintReqComponentWithStyles ref={el => (this.componentRef = el)} />
                    </React.Fragment>
                }
                <Grid container direction='row' justify='space-between' className={classes.spaceTop}>
                    {itemsCount !== 0 &&
                        <Grid item>
                            <Fab aria-label="Add to cart" variant="extended" size="medium"
                                onClick={this.handleAddToCartFromReq}>{<GetLanguage value='Add To Cart' />}</Fab>
                        </Grid>
                    }
                    <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleDeleteReqList}>{<GetLanguage value='Delete Requisition List' />}</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

class PrintReqComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: [],
            productQuantity: 1,
        }
    }
    handleItemSelect = (e) => {
        let newArray = [...this.props.selectedItemInReq];
        let index = newArray.indexOf(e.target.value);
        if (index > -1) {
            newArray.splice(index, 1);
        }
        else {
            newArray.push(e.target.value);
        }
        this.props.setSelectedItemsInReqList(newArray);
    }

    onQuantiyCounterChange = (quantity) => {
        this.setState({ productQuantity: quantity });
    }

    render() {
        const { classes } = this.props;
        const { selectedEntity } = this.props;
        let itemsInEntity = selectedEntity ? Object.values(selectedEntity) : '';

        return (
            <React.Fragment>
                <Hidden only={['xs', 'sm']}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{<GetLanguage value='Item' />}</TableCell>
                                    <TableCell>{<GetLanguage value='Price' />}</TableCell>
                                    <TableCell>{<GetLanguage value='Qty(cases)' />}</TableCell>
                                    <TableCell>{<GetLanguage value='Subtotal' />}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itemsInEntity.length && itemsInEntity.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <FormControlLabel control={<Checkbox color="default" value={item.item_id}
                                                    checked={this.props.selectedItemInReq.includes(item.item_id)}
                                                    onClick={(e) => this.handleItemSelect(e)} />}
                                                    label={<Grid container alignItems='center'>
                                                        <Grid item>
                                                            <img className={classes.image}
                                                                alt={decodeHTMLEntities(item.name)}
                                                                src={mediaURLBasePath + item.imageSrc} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>{decodeHTMLEntities(item.name)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {formatPriceOfProductforCategoryListing(item.price)}
                                            </TableCell>
                                            <TableCell className={classes.tableFontStyle}>
                                                <RequisitionCounter item_id={item.item_id} value={item.qty} id={index}/>
                                            </TableCell>
                                            <TableCell>
                                                {formatPriceOfProductforCategoryListing(item.subtotal)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid container>
                        {itemsInEntity.length && itemsInEntity.map((item, index) => {
                            return (
                                <Paper className={classes.card} key={index}>
                                    <Grid container direction='column'>
                                        <Grid item style={{marginLeft:"26px"}}>
                                            <Typography style={{ fontWeight: 'bold' }}>{decodeHTMLEntities(item.name)}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item>
                                                    <FormControlLabel control={<Checkbox color="default" value={item.item_id}
                                                        checked={this.props.selectedItemInReq.includes(item.item_id)} />}
                                                        label={<img className={classes.image} alt={decodeHTMLEntities(item.name)}
                                                        src={mediaURLBasePath + item.imageSrc} />} onClick={(e) => this.handleItemSelect(e)}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{<GetLanguage value='Price' />}  : {formatPriceOfProductforCategoryListing(item.price)}</Typography>
                                                    <Typography>{<GetLanguage value='Qty(cases)' />} : {item.qty}</Typography>
                                                    <Typography>{<GetLanguage value='Subtotal' />}  : {formatPriceOfProductforCategoryListing(item.subtotal)}</Typography>
                                                    <RequisitionCounter item_id={item.item_id} value={item.qty} id={index}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            );
                        })
                        }
                    </Grid>
                </Hidden>
            </React.Fragment>
        )
    }
}
class ReqCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: props.value ? props.value : 1,
            min_number: 1
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.value) {
            this.setState({ count: nextProps.value });
        }
    }
    changeQuantity(method, value) {
        let count = method === 'add' ? this.state.count + 1 : method === 'sub' ? this.state.count - 1 : value;
        
        if (!(count >= this.props.max_number || count < this.state.min_number)) {
            if (this.props.getReqListId) {
                let currentReqList = this.props.requisitionList[this.props.getReqListId];
                let userInfo = getItemFromLocalStorage('shopce-user');
                let now = new Date();
                let itemsInselectedEntity = Object.values(this.props.selectedEntity);
                let updatedProductDetails = [];

                updatedProductDetails = itemsInselectedEntity.map((row) => {
                    let productObject = {};
                    let updatedQty = (row.item_id === this.props.item_id) ? count : row.qty;
                    productObject.id = row.item_id;
                    productObject.sku = row.sku;
                    productObject.qty = updatedQty;
                    return productObject;
                });

                let createReqObject = {
                    "requisitionList": {
                        "id": currentReqList.entity_id,
                        "customer_id": userInfo.customerId,
                        "name": currentReqList.name,
                        "updated_at": dateformat(now, 'isoDate') + ' ' + dateformat(now, 'isoTime'),
                        "description": currentReqList.description,
                        "items": updatedProductDetails
                    }
                }
                this.props.updateRequisite(createReqObject);
            }
        }
    }
    render() {
        const { classes, id } = this.props;

        return (
            <Grid container direction='row' className={classes.counterDiv} justify='space-around' alignItems='center'>
                <Grid item>
                    <Button aria-label="Subtract" onClick={() => this.changeQuantity('sub')} className={classes.counterButton}>
                        <MinusIcon className={classes.iconStyle} color='action' />
                    </Button>
                </Grid>
                <Grid item>
                    <input id={id} type="number" min='1' max='100' value={this.state.count} onChange={(e) => this.changeQuantity('input', e.target.valueAsNumber)}
                        className={classes.quantity}
                        onClick={() => document.getElementById(id) && document.getElementById(id).focus()}
                        onBlur={(e) => this.setState({ count: e.target.valueAsNumber ? this.state.count : 1 })} />
                </Grid>
                <Grid item>
                    <Button aria-label="Add" onClick={() => this.changeQuantity('add')} className={classes.counterButton}>
                        <AddIcon className={classes.iconStyle} color='action' />
                    </Button>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    requisitionList: state.getRequisitionList,
    selectedEntity: state.selectedEntity,
    deleteRequisitionListStatus: state.deleteRequisitionList,
    selectedItemInReq: state.selectedItemInReq,
    getReqListId: state.getReqListId,
    addedReqItemsToCart: state.addedReqItemsToCart,
});
const mapDispatchToProps = dispatch => ({
    deleteRequisitionList: (id) => {
        let reqService = new RequisitionService();
        dispatch(reqService.DeleteRequisitionListActionCreator(id));
    },
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    },
    setSelectedItemsInReqList: (items) => {
        let reqService = new RequisitionService();
        dispatch(reqService.SelectedItemInReqActionCreator(items));
    },
    deleteItemsInReqList: (obj) => {
        let reqService = new RequisitionService();
        dispatch(reqService.DeleteItemsInReqListActionCreator(obj));
    },
    addReqItemToCart: (Obj) => {
        let reqService = new CartService();
        dispatch(reqService.BulkUpload(Obj));
    },
    refreshMinicart: () => {
        let cart = new CartService();
        dispatch(cart.GetDisplayMiniCartActionCreator());
    },
    createNewRequisite: (createReqObject) => {
        let reqService = new RequisitionService();
        dispatch(reqService.createNewRequisitionListActionCreator(createReqObject));
    },
    updateRequisite: (createReqObject) => {
        let reqService = new RequisitionService();
        dispatch(reqService.UpdateRequisitionListActionCreator(createReqObject));
    }
});

const RequisitionCounter = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReqCounter));
const PrintReqComponentWithStyles = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrintReqComponent));

const ViewRequisitionWithStyles = withStyles(styles)(ViewRequisition);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewRequisitionWithStyles));