/**
* Content Loader Page.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progressStyle : {
        display:'flex',
        alignItems : 'center',
        justifyContent : 'center',        
    },
    progressDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

/***************************************************************
 * Component class for Progress indicator express 
 * wait time or display the length of a process. 
 * @author   Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
function ContentLoader(props) {
    const { classes, isLoading } = props;

    return (
        <Modal open={isLoading} disableAutoFocus={true} hideBackdrop={true} className={classes.progressStyle}>
            <div className={classes.progressDiv}>
                <CircularProgress className={classes.progress} color="secondary" />
            </div>
        </Modal>
    );
}

ContentLoader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentLoader);
