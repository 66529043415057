/**
* Component for displaying wishlist item.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import getConfigValue from '../../../app/framework/config/configHandler';
import { decodeHTMLEntities } from '../../../app/framework/common/helpers';
import { mediaURLBasePath } from '../../../app/framework/common/helpers';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Counter from '../../../app/components/common/Counter';
//Redux related imports
import { connect } from 'react-redux';
import WishlistService from '../../../app/modules/wishlist/WishlistService';
import PriceDisplay from '../container/PriceDisplay';
import UnitSelector from '../uom/UnitSelector';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ProductService from '../../../app/modules/product/ProductService';
import GetLanguage from '../../../library/GetLanguage';


const styles = theme => ({
    wishListGrid: {
        padding: '0rem 1rem',
    },
    image: {
        width: 75,
        [theme.breakpoints.up('md')]: {
            paddingBottom: '1rem'
        }
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    textDecorationNone: {
        textDecoration: 'none',
    },
    deleteStyle: {
        cursor: 'pointer',
        color: '#2196f3',
    },
    addToCartStyle: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10
        }
    },
    sectionDivider: {
        margin: `0px 0px ${theme.spacing.unit * 2}px 0px`,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing.unit * 2}px 0px ${theme.spacing.unit * 2}px 0px`,
        },
    },
    titleText: {
        fontFamily: "Open Sans",
        fontSize: '14px',
        fontWeight: '600',
        color: '#072D77',
        minHeight: '2.5rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        'display': '-webkit-box'
    },
});

/***************************************************************
  * Component class for displaying Wishlist item
  * @author    Yaramala Reddy <yaramala.reddy@embitel.com
 ***************************************************************/
class WishlistItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productQuantity: 1,
        };
    }

    //Callback after setting the value in Counter Component
    onQuantiyCounterChange = (quantity) => {
        this.setState({ productQuantity: quantity });
    }

    onDeleteLinkClick = (e, item_id) => {
        e.preventDefault();
        this.props.onDeleteItemFromWishlist(item_id);
    }

    //Callback after setting the value in Counter Component
    onQuantiyCounterChange = (quantityChanged, item_id) => {
        this.setState({ productQuantity: quantityChanged });
        //Dispatch to change the quantity in the cart.
        this.props.onEditItemFromCart(quantityChanged, item_id);
    }
    onAddToCartHandler = (sku, quantity, selectedConfigOptions) => {
        // let { config_options } = this.props;

        // let numberOfConfigOptions = config_options ? Object.keys(config_options).length : 0;
        // let numberOfSelectedConfigOptions = selectedConfigOptions ? selectedConfigOptions.length : 0;
        if (localStorage.getItem('shopce-user')) {


            const selectedQuantity = this.props.variant_selected[sku] ?
                this.props.variant_selected[sku].selectedQuantity * (this.props.variant_selected[sku].selectedUnit === 1 ?
                parseInt(this.props.variant_selected[sku].conversion_factor,10) : 1) : 1;

            this.props.onAddToCart(sku, selectedQuantity, selectedConfigOptions, this.props.item_id);
        }
        else {
            this.props.history.push('/account')
        }
    }
    processProductOptions = (product_option) => {
        if (product_option === null) return "";
        let configurable_item_options_list = product_option.extension_attributes.configurable_item_options;
        let productOptionsString = [];
        for (let index = 0; index < configurable_item_options_list.length; index++) {
            productOptionsString.push(configurable_item_options_list[index].value);
        }
        //Return product option string with separators.
        return productOptionsString.join(" / ");
    }

    render() {
        const { classes } = this.props;
        const {
            product,
            productName,
            item_id,
            image_url,
            product_url,
        } = this.props;

        //Get order Parameters from configuration.
        const orderParameters = getConfigValue("order");

        return (
            <React.Fragment>
                <Grid container className={classes.wishListGrid} justify='space-between' alignItems='center'>
                    <Grid item xs={6} sm={6} md={2}>
                        <ButtonBase className={classes.image}>
                            <img className={classes.img} alt={decodeHTMLEntities(productName)}
                                src={mediaURLBasePath + image_url} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Link className={classes.textDecorationNone} to={product_url}>
                            <Typography className={classes.titleText}>
                                {decodeHTMLEntities(productName)}
                            </Typography>
                        </Link>
                        <PriceDisplay productDetail={product} />
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <UnitSelector product={product} />
                        <Counter product={product} min_number={1}
                            max_number={orderParameters.maxQuantity} onCounterChange={() => { }} />
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <Grid container direction='column' alignItems='center'>
                            <Grid item>
                                <Fab aria-label="Add to cart" variant="extended" size="medium" type="submit"
                                onClick={() => this.onAddToCartHandler(product.sku)}>
                                {<GetLanguage value="ADD TO CART"/>}</Fab>
                            </Grid>
                            <Grid item>
                                <DeleteOutline className={classes.deleteStyle} onClick={(event) => this.onDeleteLinkClick(event, item_id)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    variant_selected: state.variant_selected
});

const mapDispatchToProps = dispatch => ({
    onDeleteItemFromWishlist: (item_id) => {
        let wishlistService = new WishlistService();
        dispatch(wishlistService.DeleteWishlistProductActionCreator(item_id));
    },
    onAddToCart: (sku, quantity, selectedConfigOptions, item_id) => {
        let ps = new ProductService();
        dispatch(ps.PostAddToCartActionCreator(sku, quantity, selectedConfigOptions, item_id));
    }
});

WishlistItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

const WishlistItemWithStyles = withStyles(styles)(WishlistItem);
export default connect(mapStateToProps, mapDispatchToProps)(WishlistItemWithStyles);