/**
* Product Listing Page and Home Page Carousel 
* Add to Cart Component for adding a product to cart.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
//For getting maximum quantiy allowed for Adding to Cart.
import getConfigValue from '../../../../app/framework/config/configHandler';
import ProductService from '../../../../app/modules/product/ProductService';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import NotifyOutOfStock from '../notifyOutofStock/NotifyPopup';
import GetLanguage from '../../../../library/GetLanguage'
import {getItemFromLocalStorage} from '../../../../app/framework/storage/local/localStorageHandler';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    configOptionsRow: {
        display: 'flex',
        justifyContent: 'center'
    },
    configOptionsSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    configOptionsTitle: {
        textAlign: 'left',
        marginTop: 20,
        marginBottom: 5,
    },
    optionsDiv: {
        padding: '.2rem',
        margin: '3px',
        minWidth: '22px',
        color: '#949494',
        cursor: 'pointer',
        backgroundColor: '#f0f0f0',
        border: '2px solid #D3D3D3',
        borderRadius: '5px',
        '&:hover': {
            outline: '0px solid transparent',
            border: '2px outset #D3D3D3',
            borderRadius: '5px',
        },
    },
    outofStockMessage: {
        textAlign: 'left',
    },
    customMargin: {
        marginTop: '16px',
        marginBottom: '12px',
    },
    productOptionSelectionMessage: {
        textAlign: 'left',
    },
    productQtyCounterDiv: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    productDetailLinkGrid: {
        textAlign: 'right',
    },
    productDetailLink: {
        textDecoration: 'none',
        color: '#1979c3',
    },
    addToCartButton: {
        maxHeight: '2 rem',
        minHeight: '2 rem',
        whiteSpace: 'nowrap'
    },
    extendedIcon: {
        marginLeft: '5px',
        color:'#fff',
      },
      customAddtoCartBtn:{
        backgroundColor: '#f65e5d',
        fontSize:'11px',
      }
});

/***************************************************************
  * Component class for PDP Page Add to Cart
  * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
***************************************************************/
class ProductAddToCartList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProductOptionsSelected: true,
            productConfigSelected: [],
            configurableItemOptions: [],
            open: false,
            productId:"",
            isAddToCartButtonDisabled : this.props.isAddToCartButtonDisabled
        }
        this.onAddToCartHandler = this.onAddToCartHandler.bind(this);
        this.notifyOutOfStock = this.notifyOutOfStock.bind(this);
        this.loading_addToCart = this.props.isloading;
    }
    componentDidMount(){
        this.setState({productId:this.props.productDetail.id})
    }
    componentWillReceiveProps(nextProps){
        this.loading_addToCart = false
        if(this.props.product_add_response!==nextProps.product_add_response){
            this.setState({isAddToCartButtonDisabled:false})

        }
    }
    
    notifyOutOfStock(isOpen) {
        if (localStorage.getItem('shopce-user')) {
            this.setState({
                open: isOpen
            })
        }
        else {
            this.props.history.push('/account');
        }
    }
    
    onAddToCartHandler = (sku, selectedConfigOptions) => {
        this.loading_addToCart = true
        this.setState({isAddToCartButtonDisabled:true})
        let { config_options } = this.props;
        
        let numberOfConfigOptions = config_options ? Object.keys(config_options).length : 0;
        let numberOfSelectedConfigOptions = selectedConfigOptions ? selectedConfigOptions.length : 0;
        if (localStorage.getItem('shopce-user')) {
            if (numberOfConfigOptions !== numberOfSelectedConfigOptions) {
                this.setState({ isProductOptionsSelected: false });

                const productParameters = getConfigValue("product");
                let messagePlacementForConfigOptions = productParameters.messagePlacementForConfigOptions || "pdp";

                if (messagePlacementForConfigOptions === "pdp") {
                    //Transition to PDP page
                    this.props.history.push({
                        pathname: this.props.pdpURLKey,
                        state: { pdpConfigOptionsMessage: "Please select all product options" }
                    });
                }
            }
            else {
                //Set the flag to true if all product options are selected.
                this.setState({ isProductOptionsSelected: true });

                //Send the dispath to Add the Product to Cart.

                const selectedQuantity = this.props.variant_selected[sku] ?
                    this.props.variant_selected[sku].selectedQuantity * (this.props.variant_selected[sku].selectedUnit === 1 ?
                        parseInt(this.props.variant_selected[sku].conversion_factor, 10) : 1) : 1;
                const selectedUnit = this.props.variant_selected[sku] ? (this.props.variant_selected[sku].selectedUnit === 1 ? 'Case' : 'Palette') : 'Case';  
                let isPremiumProduct=false;
        
                if(this.props.isPremiumProduct!== '')
                {
                    isPremiumProduct=true;
                }
                this.props.onAddToCart(sku, selectedQuantity, selectedUnit, selectedConfigOptions,isPremiumProduct);
                
            }
        } else {
            this.props.history.push('/account');
        }
        
    }

    isProductOptionExists(configOptions, optionId) {
        var checkCondition = false;
        configOptions.forEach(function (item) {
            if (item["optionId"] === optionId) {
                checkCondition = true;
            }
        });
        return checkCondition;
    }

    handleConfigurableOptions = (optionId, id) => {
        //Adding Configurable Options to State.        
        var configOptions = this.state.configurableItemOptions;
        if (this.isProductOptionExists(configOptions, optionId)) {
            //Replace existing Product Option of Same Type
            configOptions.forEach(function (item) {
                if (optionId === item.optionId) {
                    item.optionValue = id
                }
            });
        }
        else {
            configOptions.push({ "optionId": optionId, "optionValue": id, "extensionAttributes": {} });
        }
    }

    handleConfigOptionSelect = (e, optionId, id, sku) => {
        var configOptions = this.state.productConfigSelected;
        if (this.isProductOptionExists(configOptions, optionId)) {
            //Replace existing Product Option of Same Type
            configOptions.forEach(function (item) {
                if (optionId === item.optionId) {
                    item.optionValue = id;
                }
            });
        }
        else {
            configOptions.push({ "optionId": optionId, "optionValue": id, "extensionAttributes": {} });
        }

        //Set all Product Option borders to "No-Border"
        // This is dependent on data-* attributes of Product Options
        var a = document.querySelectorAll('[data-id]');
        for (let i = 0; i < a.length; i++) {
            a[i].style.border = "2px solid #D3D3D3";
        }

        //Set the Product Options which are selected and in the state of this Component.
        // This is dependent on data-* attributes of Product Options
        for (let i = 0; i < configOptions.length; i++) {
            let productOption = configOptions[i].optionValue;
            var productOptionElement = document.querySelectorAll("[data-id=" + CSS.escape(productOption) + CSS.escape(sku) + "]");
            productOptionElement[0].style.border = "2px solid #0011FF";
        }

        //Send the selected option to Parent Component
        //this.props.onSelectConfigurableOptions(optionId, id);

        //Set the state with new config options.
        this.handleConfigurableOptions(optionId, id);
    }

    render() {
        const { classes } = this.props;
        const {
            config_options,
            is_in_stock,
            isAddToCartButtonDisabled,
            sku,
            parent,
            isPremiumProduct
        } = this.props;
        return (
            <React.Fragment>
                {this.state.open===true&&<NotifyOutOfStock productId={this.state.productId} open={this.state.open} notifyOutOfStock={this.notifyOutOfStock} />}
                {
                    Object.keys(config_options || []).map((option_key, index) => {
                        return (
                            <div key={index} className={classes.configOptionsSection}>
                                <div className={classes.configOptionsRow}>
                                    {
                                        Object.keys(config_options[option_key] || []).map((option_detail_key, index_detail) => {
                                            return (
                                                Object.keys(config_options[option_key][option_detail_key] || []).map((option_entry_key, option_entry_index) => {
                                                    let configOptionLabel = config_options[option_key][option_detail_key][option_entry_key].toString();
                                                    // Specific Option Id, like the Id for Color "Blue"
                                                    let opton_id = option_entry_key;
                                                    // Option type Id, like the Id for Color type
                                                    let option_attribute_id = option_detail_key;
                                                    if (option_key === "Color") {
                                                        return (
                                                            <div key={option_entry_index}>
                                                                <Tooltip key={option_entry_index} id="tooltip-icon" title={configOptionLabel.toString()} placement="top">
                                                                    <div
                                                                        key={option_entry_index}
                                                                        className={classes.optionsDiv}
                                                                        data-label={configOptionLabel}
                                                                        data-id={opton_id.toString() + sku.toString()}
                                                                        data-attribute_id={option_attribute_id.toString()}
                                                                        style={{
                                                                            minWidth: '20px',
                                                                            height: '20px',
                                                                            backgroundColor: configOptionLabel,
                                                                        }}
                                                                        onMouseUp={(e) => this.handleConfigOptionSelect(e, option_attribute_id, opton_id, sku)}
                                                                        onClick={() => parent.setState({ selectedColor: opton_id })}
                                                                    >
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div key={option_entry_index}>
                                                                <Tooltip id="tooltip-option" title={configOptionLabel} placement="top">
                                                                    <div key={option_entry_index} style={{
                                                                        minWidth: '20px', height: '20px',
                                                                        display: 'inline-block', float: 'left', marginBottom: 3,
                                                                    }}
                                                                        className={classes.optionsDiv} data-label={configOptionLabel}
                                                                        data-id={opton_id.toString() + sku.toString()}
                                                                        data-attribute_id={option_attribute_id.toString()}
                                                                        onMouseUp={(e) => this.handleConfigOptionSelect(e, option_attribute_id, opton_id, sku)}
                                                                    >
                                                                        <Typography variant='caption'>{configOptionLabel}</Typography>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    )
                }
                <div className={classes.customMargin}>
                    {/* If product is in stock, display Add to Cart Button,
                        else, display Out of Stock */}
                    {
                        is_in_stock ?
                        <div>
                            {this.loading_addToCart && <CircularProgress size={24} className={classes.buttonProgress} />}
                            <Fab aria-label="Add to cart" variant="extended" size="medium" type="submit" disabled={this.state.isAddToCartButtonDisabled}
                                onClick={() => this.onAddToCartHandler(sku, this.state.configurableItemOptions)} className={classes.customAddtoCartBtn}>
                                {<GetLanguage value="ADD TO"/>}<ShoppingCartOutlinedIcon className={classes.extendedIcon}/></Fab>
                        </div>
                            :
                            <Fab aria-label="Out Of Stock" variant="extended" size="medium" onClick={() => this.notifyOutOfStock(true)}>
                                {<GetLanguage value="NOTIFY ME"/>}</Fab>
                    }
                </div>
            </React.Fragment>
        )

    }
}

ProductAddToCartList.propTypes = {
    classes: PropTypes.object.isRequired,
    config_options: PropTypes.object,
};


/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    variant_selected: state.variant_selected,
    product_add_response : state.get_minicart_details.response
});

const mapDispatchToProps = dispatch => ({
    onAddToCart: (sku, quantity, selectedUnit, selectedConfigOptions,isPremiumProduct) => {
        let ps = new ProductService();
        dispatch(ps.PostAddToCartActionCreator(sku, quantity, selectedUnit, selectedConfigOptions,isPremiumProduct));
    }
});

const ProductAddToCartListWithStyles = withStyles(styles)(ProductAddToCartList);
//export default connect(mapStateToProps, mapDispatchToProps)(ProductAddToCartListWithStyles);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductAddToCartListWithStyles));
