/**
* Component for displaying One Row of Mobile Menu.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { handleURLsForMenu } from '../../../framework/common/helpers';
import {
    TopLevelMobileSubMenuWithStyles,
    SecondLevelMobileSubMenuWithStyles
} from './SubMenus';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F0F0F0',
    },
    subLevelMenuList: {
        backgroundColor: '#D3D3D3',
        padding: 0,
        margin: 0,
    },
    secondLevelMenu: {
        paddingLeft: 0,
        paddingBottom: 5,
        paddingRight: 5,
    },
    thirdLevelMenu: {
        paddingLeft: 40,
    }
});

/*****************************************************************
 * Component class for displaying Category Menu.
 * Receives menu data from Parent Component in the form of props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ****************************************************************/
class NavigationMobileUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            expanded: null,
        }
    }

    handleToggle = (event) => {
        this.setState((prevState) => ({
            open: !prevState.open
        }));
    };

    handleClose = (event) => {
        this.setState({ open: false });
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: panel,
        });
    };

    render() {
        const { classes } = this.props;
        const { topmenu, submenu } = this.props;

        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={(submenu.length > 0) ? <ExpandMoreIcon /> : ""}>
                        <Typography>
                            <Link
                                to={handleURLsForMenu(topmenu[2])}
                                style={{ textDecoration: 'none' }}>
                                {topmenu[0]}
                            </Link>
                        </Typography>
                    </ExpansionPanelSummary>
                    {(submenu.length > 0) ?
                        <ExpansionPanelDetails className={classes.subLevelMenuList}>
                            <ul>
                                {submenu.map(function (submenuItem, index) {
                                    return (
                                        <div key={index}>
                                            <TopLevelMobileSubMenuWithStyles
                                                index={index}
                                                submenuItem={submenuItem}
                                            />
                                            {
                                                submenuItem[3].map((secondSubMenu, index) =>
                                                    <SecondLevelMobileSubMenuWithStyles
                                                        index={index}
                                                        submenuItem={secondSubMenu}
                                                    />
                                                )
                                            }
                                        </div>
                                    );
                                })}
                            </ul>

                        </ExpansionPanelDetails> : ""}
                </ExpansionPanel>
            </div>
        );
    }
}

NavigationMobileUnit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavigationMobileUnit);