/**
* Customers List Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PageTitle from '../../../../app/components/common/PageTitle';
import AccountService from '../../../../app/modules/account/AccountService';
import { setItemInLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import { removeItemFromLocalStorage,getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        marginBottom: '20px'
    },
    formElementsPosition: {
        paddingLeft: '10px',
        alignItems: 'flex-start',
        marginBottom: '.5rem',

    },
    gridStyle: {
        borderRadius: 6,
        minWidth: '320px',
        padding: '0rem 1rem'
    },
    customerListDiv: {
        marginTop: '50px',
        [theme.breakpoints.up('md')]: {
            marginTop: '120px',
        },
    }
});
class CustomersList extends React.Component {
    constructor(props) {
        super(props);
                let currentStore = getItemFromLocalStorage('sales-man');
                this.state = {
                    EntityId: currentStore && currentStore.EntityId?currentStore.EntityId:'',
          
                    company_name: currentStore && currentStore.customer?currentStore.customer:'',
            
                    // redirectTo: this.props.location.state ? (this.props.location.state.redirectTo ? this.props.location.state.redirectTo : '/') : '/',
                    // isSigninClicked: false,
                }
    }
    componentDidMount() {
        let salesmanInfo = getItemFromLocalStorage('salesman-user');
        if (salesmanInfo) {
          //Dispatch action only if token exists
          this.props.getStoreDetails(salesmanInfo);
        } 
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.customer_list !== nextProps.customer_list) {

            if (nextProps.customer_list !== undefined && nextProps.customer_list !== null && nextProps.customer_list) {
                let currentStore = getItemFromLocalStorage('sales-man');
                if (!currentStore) {
                    this.setState({
                        EntityId: nextProps.customer_list[0].EntityId,
                        company_name: nextProps.customer_list[0].Company,
                         isSigninClicked: false,
                    });
                }
            }
        }



    }


    handleChange = (event) => {
        let customerListValues = Object.values(this.props.customer_list);
       
        removeItemFromLocalStorage('shopce-user');
      
        let customObj = customerListValues.find(o => o.EntityId === event.target.value);
        
        this.setState({ 
            EntityId: customObj.EntityId,
            company_name : customObj.Company
         });
    }

    handleCustomerSelection = () => {

        let salesman = {
            salesmanName: this.props.customer_list ? this.props.customer_list.sales_representative ? this.props.customer_list.sales_representative : '' : '',
            customer: this.state.company_name,
            EntityId:this.state.EntityId,
            firstname:this.props.salesman_details ? this.props.salesman_details.firstname ? this.props.salesman_details.firstname : '' : '',
            lastname:this.props.salesman_details ? this.props.salesman_details.lastname ? this.props.salesman_details.lastname : '' : '',
        }
        setItemInLocalStorage('sales-man', JSON.stringify(salesman));

        let customerListValues = Object.values(this.props.customer_list);
        
             this.setState({ 
                isSigninClicked:true
         });
        customerListValues.map((customer, index) => {
            if (typeof customer.Company !== 'undefined' && customer.Company !== '') {
            if (customer.Company === this.state.company_name) {
               
                let users = {};
                let userKeys = Object.keys(customer.Users);
                let userValues = Object.values(customer.Users);
                userKeys.map((user, index) => {
                    users[user] = userValues[index].token;
                });
                users['Company'] = customer.token;
                this.props.getCustomerInfo(users);
            }
        }
    })
    }
    render() {
        const { customer_list, classes } = this.props;
        const { get_user_details,salesman_details } = this.props;
        var userInfo = localStorage.getItem('shopce-user');
        if (this.state.selectedCustomer !== '' && get_user_details && get_user_details.id && userInfo && this.state.isSigninClicked) {
            return <Redirect push to='/' />;
        }

        if(customer_list === null || customer_list===undefined && customer_list === '' )
        {
            return null;
        }
        // if (customer_list ) {
            let customerListValues = Object.values(customer_list);
            return (
                <div className={classes.customerListDiv}>
                    <PageTitle title="Select store from list" />
                    {customerListValues.length > 0 &&
                        <Grid container justify='center' alignItems='center'>
                            <Grid item xs={8} sm={6} md={5} lg={3}>
                                <Card className={classes.cardStyle}>
                                    <CardContent>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            Please select any one company.
                                        </Typography>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <RadioGroup aria-label="Customer List" name="customer"
                                             value={this.state.EntityId}
                                                onChange={this.handleChange}>
                                                {customerListValues.map((customer, index) => {                                                 
                                                    if (typeof customer.Company !== 'undefined' && customer.Company !== '') {
                                                        return (                                                       
                                                                <FormControlLabel
                                                                   key={index}
                                                                   value={customer.EntityId}
                                                                    control={<Radio color="primary" name='cust'/>}
                                                                    label={
                                                                        <Grid container direction="column" className={classes.gridStyle}>
                                                                            <Grid item>
                                                                                <Typography variant="subtitle1">{customer.Company}</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="caption">{customer.Address ? customer.Address.company_email : ''}</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="caption">{customer.Address ? customer.Address.street : ''}</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="caption">{customer.Address ? customer.Address.city : ''}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        }
                                                                    className={classes.formElementsPosition}>
                                                                </FormControlLabel>
                                                           
                                                       
                                                       
                                                       );
                                                    }
                                                })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl required fullWidth>
                                            <Fab size="medium" variant="extended"
                                                onClick={this.handleCustomerSelection}>Proceed</Fab>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    }
                    {customerListValues.length === 0 &&  
                       <Grid container justify='center' alignItems='center'>
                       <Grid item xs={8} sm={6} md={5} lg={3}>
                           {/* <Card className={classes.cardStyle}>
                               <CardContent> */}
                                   <Typography variant="body2" align="center" gutterBottom>
                                  No store found 
                                   </Typography>
                          
                               {/* </CardContent>
                           </Card> */}
                       </Grid>
                   </Grid>
           
                         
                         
                         }
                </div>
            )
        // }
        // else {
        //    return <div className={classes.customerListDiv}>
        //      <Card className={classes.cardStyle}>
        //                             <CardContent>
        //                             <Typography variant="caption">test</Typography>
        //                             </CardContent>
        //                         </Card>
        //     </div>
        
        // }
    }
}

const mapStateToProps = state => ({
    customer_list: state.getSalesManDetails.quoteData ? state.getSalesManDetails.quoteData : '',
    salesman_details: state.getSalesManDetails ? state.getSalesManDetails : '',
    get_user_details: state.get_user_details_state,
});
const mapDispatchToProps = dispatch => ({
    getCustomerInfo: (users) => {
        let accountService = new AccountService();
        dispatch(accountService.GetUserDetailsActionCreator(users));
    },

    getStoreDetails: (token) => {
        let as = new AccountService();
        dispatch(as.GetSalesManStores(token));
      }
});
const CustomersListWithStyles = withStyles(styles)(CustomersList);
export default connect(mapStateToProps, mapDispatchToProps)(CustomersListWithStyles);