/**
* CartButton Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MiniShoppingCart from './MiniCart';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CartService from '../../../app/modules/cart/CartService';
import ErrorBoundary from 'react-error-boundary';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  ulIcon: {
    fontSize: '25px'
  },
  badgeStyle: {
    fontSize: '12px'
  },
  cartIcon:{
    background: '#e6f4ff',
    padding: '6px',
    borderRadius: '50%',
  }
});

/***************************************************************
 * Component class for displaying Cart Button and submenu 
 * on hover. Submenu includes items added to cart.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miniShoppingCartOpen: false,
      miniCartDetails: [],
    }
  }

  componentDidMount() {
    //Initialize the Badge with number of products in the Cart.
    //this.props.displayMiniCart();
  }

  //For display of Mini Shopping Cart
  handleMiniShoppingCartToggle = () => {
    if(!window.location.href.includes('/cart')){
      this.setState({ miniShoppingCartOpen: !this.state.miniShoppingCartOpen });
    }
    //Dispatching a redux action, if shopping cart is open.
    if (!this.state.miniShoppingCartOpen) {
      // this.props.displayMiniCart();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Hidden only={['sm', 'xs']}>
        <IconButton onClick={this.handleMiniShoppingCartToggle} className={classes.ulIcon}>
          <Badge className={classes.badgeStyle} showZero={true} color="secondary"
            badgeContent={this.props.miniCartDetails  ? this.props.miniCartDetails.cart_count : 0}>
            {/* pointerEvents is set to none, to enable mouse event on the svg icon */}
            <ShoppingCartIcon color='secondary' className={classes.cartIcon} />
          </Badge>
        </IconButton>
        </Hidden>
        <Hidden only={['lg', 'md']}>
        <IconButton className={classes.ulIcon}  component={Link} to="/cart" aria-label="View Cart">
          <Badge className={classes.badgeStyle + " " + "cartsmall"} showZero={true} color="secondary"
            badgeContent={this.props.miniCartDetails  ? this.props.miniCartDetails.cart_count : 0}>
            {/* pointerEvents is set to none, to enable mouse event on the svg icon */}
            <ShoppingCartIcon color='secondary' className={classes.cartIcon} />
          </Badge>
        </IconButton>
        </Hidden>
        {/* For Mini Shopping Cart display */}
        <ErrorBoundary FallbackComponent={MiniCartErrorFallbackComponent}>
          <MiniShoppingCart
            miniCartDetails={this.props.miniCartDetails}
            cart_product_count={this.props.cart_product_count}
            cart_product_cost={this.props.cart_product_cost}
            onClose={this.handleMiniShoppingCartToggle}
            miniShoppingCartOpen={this.state.miniShoppingCartOpen}
            onCloseMiniShoppingCart={this.handleMiniShoppingCartToggle}
          />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

//returns total product count
function getProductTotalCount(state_get_minicart_details) {
  if (Object.keys(state_get_minicart_details).length) {

    return state_get_minicart_details.miniCartDetails.length
  }
  else {
    return 0;
  }
}

//returns total product cost
function getProductTotalCost(state_get_minicart_details) {
  if (state_get_minicart_details) {
    return state_get_minicart_details.cart_product_cost
  }
  else {
    return 0;
  }
}

/***Error Boundary Fallback Component */
const MiniCartErrorFallbackComponent = ({ componentStack, error }) => {
  return null;
}

const mapStateToProps = state => ({
  totalProductCount: state.result_add_to_cart_state.totalProductCount,
  miniCartDetails: state.get_minicart_details,
  cart_product_count: getProductTotalCount(state.get_minicart_details),
  cart_product_cost: getProductTotalCost(state.get_minicart_details),
});

const mapDispatchToProps = dispatch => ({
  displayMiniCart: () => {
    let cs = new CartService();
    dispatch(cs.GetDisplayMiniCartActionCreator())
  }
});

const CartButtonWithStyles = withStyles(styles)(CartButton);
export default connect(mapStateToProps, mapDispatchToProps)(CartButtonWithStyles);