/**
* Router for Magento based Pages like Category Listing and
* Product Detail Pages. Calls an API to get the REST end point.
* Uses regular expressions (in the backend) to match the path received from REST API. 
* 
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorPage from '../../components/error/ErrorPage';
import ProductListing from '../../components/category/productlisting/ProductListing';
import ProductDetail from '../../components/product/productdetail/ProductDetail';
import CMSPagesComponent from '../../../extension/components/cms/CMSPages';
import ScrollToTopOnMount from '../../components/pages/ScrollToTopOnMount';
import ApplicationService from '../../../app/modules/application/ApplicationService';
import TemplateWrapper from '../../../app/framework/hoc/templateWrapper';

/***************************************************************************** 
 * Router Component for Magento controlled URLs
 * Makes a REST API call to get the target Path.
 * If received target path is null, then nothing is displayed.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
*****************************************************************************/
class MagentoRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetURLPath: "",
            targetPathType: "",
        };
    }
    componentDidMount(){
        var strURLPath = this.props.location.pathname;
        //Redux Dispatch to get target URL path and type
        //API Request method is changed to GET.
        //The URL Path is encoded and sent to API get the targetPath.
        let strURLTargetPath = encodeURIComponent(strURLPath.substring(1));
        this.props.getTargetURLPath(strURLTargetPath);
    }
    componentWillReceiveProps(nextprops) {
        var strURLPath = nextprops.location.pathname;
        //Redux Dispatch to get target URL path and type
        //API Request method is changed to GET.
        //The URL Path is encoded and sent to API get the targetPath.
        let strURLTargetPath = encodeURIComponent(strURLPath.substring(1));
        this.props.getTargetURLPath(strURLTargetPath);
    }

    render() {
        const { targetPathType } = this.props;
        //If the target type received from REST API is null, 
        //prevent it from rendering.
        if (targetPathType == null)
            return <div style={{minHeight:'500px'}}></div>

        return (
            <div style={{minHeight:'500px'}}>
                {/* Render the Magento Controlled Component */}
                {renderMagetoComponent(targetPathType)}
            </div>
        );
    }
}

const ProductDetailComponent = props => (
    <div>
        {/* Disable scroll to top on PDP Page only */}
        <ScrollToTopOnMount />
        <ProductDetail />
    </div>
);

/***************************************************************************** 
 * For checking the type of the target URL and populating corresponding
 * component.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
let MagentoComponentSelector = [
    {
        "type": "category",
        "component": <ProductListing />,
    },
    {
        "type": "product",
        "component": <ProductDetailComponent />,
    },
    {
        "type": "cms",
        "component": <CMSPagesComponent />,
    },
];

/***************************************************************************** 
 * Function that loops through the "type" of the page and "component" pairs. 
 * and finds a match for the URL path and returns the corresponding
 * component.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
function getMagentoURLComponent(path) {
    for (let i = 0; i < MagentoComponentSelector.length; i++) {
        if (MagentoComponentSelector[i].type === path) {
            return MagentoComponentSelector[i].component;
        }
        else {
            continue;
        }
    }
    //If no path is found from Back end system, display 404 Page.
    return <ErrorPage />;
}

/****************************************************************************** 
 * Renders the selected Magento controlled Component.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ******************************************************************************/
function renderMagetoComponent(type) {
    var magentoComponent = getMagentoURLComponent(type);
    return magentoComponent;
}

/******************************************************************************* 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ******************************************************************************/
const mapStateToProps = state => ({
    targetURLPath: state.targetPathState.targetURLPath,
    targetPathType: state.targetPathState.targetPathType,
});

const mapDispatchToProps = dispatch => ({
    getTargetURLPath: (path) => {
        let applicationService = new ApplicationService();
        dispatch(applicationService.getTargetURLPathActionCreator(path));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplateWrapper(MagentoRouteComponent, 'withHeaderFooter'));