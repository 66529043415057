/**
* SearchbarMobile Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchRounded from '@material-ui/icons/SearchRounded';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },    
  },
  searchRoundedStyle: {
    zIndex: '9999', 
    color:'rgba(0, 0, 0, 0.87)'
  }
});

/****************************************************************
 * This component returns icon button which includes search icon
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class SearchbarMobile extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <IconButton onClick={this.props.searchHandler} className={classNames(classes.button, classes.root)} aria-label="Search" style={{ zIndex: 9999 }}>
        <SearchRounded aria-label="Search" className={classes.searchRoundedStyle} />
      </IconButton>
    );
  }
}

SearchbarMobile.propTypes = {
  searchHandler: PropTypes.func
};

export default withStyles(styles)(SearchbarMobile);