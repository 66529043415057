/**
* signInButton Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import SvgIcon from '@material-ui/core/SvgIcon';
import UserSvgIcon from '../../../../theme/base/assets/icons/user.svg';
import AccountService from '../../../modules/account/AccountService';
import {getItemFromLocalStorage} from '../../../framework/storage/local/localStorageHandler';
import { connect } from 'react-redux';

/***************************************************************
 * Component class for displaying Sign In Button and submenu 
 * on hover. Submenu includes My Account and Logout.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class SignInButton extends React.Component {
  state = {
    open: false,
    isSignOut: false,
    isAuthenticated: false
  };

  componentDidMount() {

    let userInfo = getItemFromLocalStorage('shopce-user');
    //check if localStorage exists
    if (typeof userInfo !== 'undefined' && userInfo !== '' && userInfo !== null) {
      this.setState({ isAuthenticated: true });
    }
    else {
      this.setState({ isAuthenticated: false });
    }
  }

  //handles submenu show/hide
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };
  //handles closing of menu
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  /***************************************************************************** 
   * Handle SignOut process.
   * Clear localStorage and Cookie
   *****************************************************************************/
  handleSignOut = (event) => {
    //reset signed in state
    this.props.resetSignedInUserSuccess();
    //Clear browser storage on User SignOut.
    AccountService.clearOnSignOut();
    //Close the menu
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
    //After SignOut, set the state to Redirect to home page.
    this.setState({ isSignOut: true });
  }

  render() {
    const { open } = this.state;
    //After SignOut, Redirect to 
    //Home Page.
    if (this.state.isSignOut) {
      return <Redirect push to="/" />;
    }

    if (!this.state.isAuthenticated) {
      return (
        <IconButton
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          component={Link} to="/account"
        >
          <SvgIcon style={{ pointerEvents: 'none' }} component="div" color="primary">
            <div>
              <img src={UserSvgIcon} alt="Sign In" />
            </div>
          </SvgIcon>
        </IconButton>
      );
    }
    else {
      return (
        <div>
          <ClickAwayListener onClickAway={this.handleClose}>
            <IconButton
              buttonRef={node => {
                this.anchorEl = node;
              }}
              aria-owns={open ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              {/* pointerEvents is set to none, to enable mouse event on the svg icon */}
              <SvgIcon style={{ pointerEvents: 'none' }} component="div" color="primary">
                <div>
                  <img src={UserSvgIcon} alt="Sign In" />
                </div>
              </SvgIcon>
            </IconButton>
          </ClickAwayListener>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem component={Link} to="/myaccount" onClick={this.handleClose}>My Account</MenuItem>
                      <MenuItem component={Link} to="/myaddresses" onClick={this.handleClose}>My Addresses</MenuItem>
                      <MenuItem component={Link} to="/wishlist" onClick={this.handleClose}>My Wishlist</MenuItem>
                      <MenuItem component={Link} to="/myprofile" onClick={this.handleClose}>My Profile</MenuItem>
                      <MenuItem component={Link} to="/newslettersubscribe" onClick={this.handleClose}>Newsletter</MenuItem>                      
                      <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({

  resetSignedInUserSuccess: () => {
    let as = new AccountService();
    dispatch(as.resetSignedInActionCreator());
  },
}
);
export default connect(mapStateToProps, mapDispatchToProps)(SignInButton);