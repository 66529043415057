/**
* Carousel Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import KeyboardArrowRight from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowLeft from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ProductCatalog from '../../../product/productcatalog/ProductCatalog';
import Slider from "react-slick";
import '../../../../../../src/assets/css/common/carousal.css';

/***************************************************************
 * Component class for displaying Carousel
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
const styles = theme => ({
  carouselHeader: {
  },
  bannerWatchStyle: {
  },
  card: {
  },
  showCursor: {
  },
  staticBlockStyle: {
  },
  bannerPadding: {

  },
  sliderdiv: {
    margin: 5,
    padding: 0,
    textAlign: 'center',
  },
  produtsliderdiv: {
    margin: "5px 0px",
    padding: 0,
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
});

class ProductCarousel extends React.Component {

  // The Carousel is not having the "next" and "previous" buttons centered.
  // On resizing the window, centering of the same is achieved.
  // Ref: https://github.com/FormidableLabs/nuka-carousel/issues/265 
  componentDidUpdate() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  render() {
    var option = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      nav: true,
      prevArrow: <KeyboardArrowLeft color='primary' fontSize='large' />,
      nextArrow: <KeyboardArrowRight color='primary' fontSize='large' />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,

          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    }
    let { classes } = this.props;
    const { cmsPageWidgetDataObject } = this.props;
    if (cmsPageWidgetDataObject && typeof cmsPageWidgetDataObject !== "undefined") {
      return (
        <div className="productslider">
          <Slider ref={slider => this.slider = slider} {...option}>
            {
              cmsPageWidgetDataObject && cmsPageWidgetDataObject.map((productTile, index) => (
                <div key={index} className={classes.produtsliderdiv}>
                  <ProductCatalog productTile={productTile} sliderView="sliderView" />
                </div>
              )
              )
            }
          </Slider>
        </div>
      );
    }
    else return null;
  }
}

export default withStyles(styles)(ProductCarousel);