/**
* SearchbarDesktop Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Bannari Raja <bannari.raja@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getLanguageValue } from '../../../../../app/framework/config/configHandler';
import MagnaAPIHandler from '../../../../../app/framework/api/MagnaAPIHandler';
import { mediaURLBasePath } from '../../../../../app/framework/common/helpers';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Quagga from 'quagga'; // ES6
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const styles = theme => ({

});

/****************************************************************
 * This component returns input field which includes search icon
 * @author    Bannari Raja <bannari.raja@embitel.com>
 ***************************************************************/
let count = 1;
class BarcodeScan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visiblemanualbox: false,
            ean: '',
            modalBoolean:false
        }
        this._onDetected = this._onDetected.bind(this);
        this.manualEnterEan = this.manualEnterEan.bind(this);
        this.submitEan = this.submitEan.bind(this);
        this.handleClose = this.handleClose.bind(this);
        // this.scanStop = this.scanStop.bind(this);
        this.redirectionHome = this.redirectionHome.bind(this);
        this.redirectionScan = this.redirectionScan.bind(this);
    }
    componentDidMount() {
        count = 1;
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    constraints: {
                        width: { min: 640 },
                        height: { min: 480 },
                        aspectRatio: { min: 1, max: 100 },
                        facingMode: "environment" // or "user" for the front camera
                    }
                },
                locator: {
                    patchSize: "medium",
                    halfSample: true
                },
                numOfWorkers: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 1),
                decoder: {
                    "readers": [
                        { "format": "ean_reader", "config": {} }
                    ]
                },
                locate: true
            },
            function (err) {
                if (err) {
                    return console.log(err);
                }
                Quagga.start();
            }
        );
        Quagga.onProcessed(function (result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
                }
            }
        });
        Quagga.onDetected(this._onDetected);
    }

    componentWillUnmount() {
        Quagga.offDetected(this._onDetected);
    }
    _onDetected(result) {
        let resultCode= result.codeResult.code.replace(/^0+/, '');
        if(count===1 && resultCode){
        let requestURL = `/ecs/searchsuggestions?fields=items[sku,id,name,custom_attributes[thumbnail,url_key,special_price],is_in_stock,name,price,regular_price,status,extension_attributes[qty]],total_count&searchCriteria[requestName]=quick_search_container&searchCriteria[filterGroups][0][filters][0][field]=search_term&searchCriteria[filterGroups][0][filters][0][value]=${resultCode}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][1][field]=visibility&searchCriteria[filterGroups][0][filters][1][value]=4&searchCriteria[filterGroups][0][filters][1][conditionType]=eq&searchCriteria[pageSize]=10&searchCriteria[currentPage]=0`;
        let requestType = 'GET';
        let requestBody = {};
            MagnaAPIHandler(requestURL, requestType, requestBody).then(response => {
                if(response && response.data[0] && response.data[0].items && response.data[0].items.length > 1){
                    this.props.history.push({
                        pathname: '/search',
                        state: { searchTerm: resultCode }
                    })
                }else if (response && response.data[0] && response.data[0].items && response.data[0].items[0].extension_attributes) {
                    this.props.history.push(response.data[0].items[0].custom_attributes[3].value + '.html')
                }else{
                    this.setState({
                            modalBoolean:true,
                            visiblemanualbox:true,
                            message:'Barcode not recognized properly.'
                    })
                }
                Quagga.stop();
            }, (error) => {

            }
            );
            count++;
        }
    }
    redirectionHome(){
        Quagga.stop();
        if(this.props.history.location.pathname ==="/"){
            window.location.reload();
        }
        this.props.history.push('/');
    }
    manualEnterEan() {
        Quagga.stop();
        this.setState({
            visiblemanualbox: true
        })
    }
    redirectionScan() {
        this.setState({
            visiblemanualbox: false,
            modalBoolean:false,
        });
        count = 1;
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    constraints: {
                        width: { min: 640 },
                        height: { min: 480 },
                        aspectRatio: { min: 1, max: 100 },
                        facingMode: "environment" // or "user" for the front camera
                    }
                },
                locator: {
                    patchSize: "medium",
                    halfSample: true
                },
                numOfWorkers: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 1),
                decoder: {
                    "readers": [
                        { "format": "ean_reader", "config": {} }
                    ]
                },
                locate: true
            },
            function (err) {
                if (err) {
                    return console.log(err);
                }
                Quagga.start();
            }
        );
        Quagga.onProcessed(function (result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
                }
            }
        });
        Quagga.onDetected(this._onDetected);
    }
    handleClose (){
        this.setState({
            modalBoolean:false
        })
    }
    submitEan() {
        if (this.state.ean) {
            let requestURL = `/ecs/searchsuggestions?fields=items[sku,id,name,custom_attributes[thumbnail,url_key,special_price],is_in_stock,name,price,regular_price,status,extension_attributes[qty]],total_count&searchCriteria[requestName]=quick_search_container&searchCriteria[filterGroups][0][filters][0][field]=search_term&searchCriteria[filterGroups][0][filters][0][value]=${this.state.ean}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][1][field]=visibility&searchCriteria[filterGroups][0][filters][1][value]=4&searchCriteria[filterGroups][0][filters][1][conditionType]=eq&searchCriteria[pageSize]=10&searchCriteria[currentPage]=0`;
            let requestType = 'GET';
            let requestBody = {};
            MagnaAPIHandler(requestURL, requestType, requestBody).then(response => {
                if(response && response.data[0] && response.data[0].items && response.data[0].items.length > 1){
                    this.props.history.push({
                        pathname: '/search',
                        state: { searchTerm: this.state.ean }
                    })
                }else if (response && response.data[0] && response.data[0].items && response.data[0].items[0].extension_attributes) {
                    this.props.history.push(response.data[0].items[0].custom_attributes[3].value + '.html')
                }else{
                    this.setState({
                        modalBoolean:true,
                        message:'Please Enter Correct Barcode.'
                    })
                }
                Quagga.stop();
            }, (error) => {

            }
            );
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="scancomp">
                <div id="interactive" className="viewport"></div>
                {
                    !this.state.visiblemanualbox &&
                    <div className="btn-action">
                        <div className="Cancel-action" onClick={this.redirectionHome}>Cancel</div>
                        <div className="manualenter" onClick={this.manualEnterEan}>Manually Enter Barcode</div>
                    </div>
                }
                {
                    this.state.visiblemanualbox &&
                    <div className="scanWrapper">
                        <input className="scaninput" onChange={(e) => this.setState({ ean: e.target.value })} value={this.state.ean} type="number" placeholder="Please Enter the Bar code" required/>
                        <div className="btn-action">
                            <div className="Cancel-action" onClick={this.redirectionScan}>Cancel</div>
                            <div className="submit manualenter" onClick={this.submitEan}>Submit</div>
                        </div>
                    </div>
                }
                <Dialog open={this.state.modalBoolean}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{color:'#000'}}>
                        {this.state.message}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.redirectionScan} color="primary" autoFocus>
                        Retry
                    </Button>
                    <Button onClick={this.handleClose} color="secondry" autoFocus>
                        Enter Manually
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
BarcodeScan.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({

}
);
const mapStateToProps = state => ({

});
const BarcodeScanWithStyles = withStyles(styles)(BarcodeScan);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarcodeScanWithStyles));