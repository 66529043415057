/**
* Requisition Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import dateformat from 'dateformat';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { NotificationManager } from 'react-notifications';
import CreateNewRequisition from './CreateNewRequisition';
import { getItemFromLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';
import RequisitionService from '../../../app/modules/requisition/RequisitionService';
import GetLanguage from '../../../library/GetLanguage'

import ViewList from '../../../assets/images/CreateNewListPorduct.svg';


const styles = theme => ({
    menuText: {
        color: '#000',
        fontSize: '12px',
        fontWeight: '500',
        height: '15px'
    },
    createReqStyle: {
        color: '#F73455',
        fontSize: '14px',
        fontWeight: '550',
        height: '15px'
    },
});

/**********************************************************
 * Component class for displaying RequisitionButton.
 * On click user can add product to existing list 
 * or else create a new list
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 **********************************************************/
class RequisitionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openModal: false,
            isAuthenticated: true,
            anchorEl: '',
            localOpen: false,
        };
    }
    //handles submenu show/hide
    handleToggle = (e) => {
        this.setState({ anchorEl: e.currentTarget })
        let CustomerExist = getItemFromLocalStorage('shopce-user');
        if (typeof CustomerExist !== 'undefined' && CustomerExist !== '' && CustomerExist !== null) {
            this.setState(state => ({ open: !state.open }));
            this.setState({ isAuthenticated: true });
        }
        else {
            this.setState({ isAuthenticated: false });
        }
    };
    handleClose = () => {
        this.setState({ open: false });
    }
    handleOpenModal = () => {
        this.setState({localOpen:true});
        this.props.openCreateReqModal(true);
    }
    //handles closing of menu
    handleUpdateRequisitionList = (item) => {
        let updatedProductDetails = [];
        let userInfo = getItemFromLocalStorage('shopce-user');
        let now = new Date();
        //convert object into array while pushing
        let productDetails = Object.values(item.items);
        if (productDetails.length) {
            //update new array with existing items
            updatedProductDetails = productDetails.map((row, i) => {
                let productObject = {};
                productObject.id = row.item_id;
                productObject.sku = row.sku;
                productObject.qty = row.qty;
                return productObject;
            });
        }
        if (this.props.buttonName === undefined) {
            let selectedQuantity = this.props.variant_selected[this.props.sku] ? this.props.variant_selected[this.props.sku].selectedQuantity: 1;
            let productObject = {};
            productObject.sku = this.props.sku ? this.props.sku : '';
            productObject.qty = selectedQuantity;
            updatedProductDetails.push(productObject);
        }
        else {
            if (this.props.selectedItemInReq.length === 0) {
                NotificationManager.info('Select atleast one Item');
                return false;
            }
            let itemsInselectedEntity = Object.values(this.props.selectedEntity);
            itemsInselectedEntity.map((row) => {
                if (this.props.selectedItemInReq.indexOf(row.item_id) !== -1) {
                    let productObject = {};
                    productObject.sku = row.sku;
                    productObject.qty = row.qty;
                    updatedProductDetails.push(productObject);
                }
            });
        }
        let createReqObject = {
            "requisitionList": {
                "id": item.entity_id,
                "customer_id": userInfo.customerId,
                "name": item.name,
                "updated_at": dateformat(now, 'isoDate') + ' ' + dateformat(now, 'isoTime'),
                "description": item.description,
                "items": updatedProductDetails
            }
        }
        this.props.createNewRequisite(createReqObject);

        if (this.props.buttonName === 'Move Selected') {
            let deleteObj = {
                "itemIds": this.props.selectedItemInReq.join(),
                "requisitionListId": this.props.getReqListId
            }
            this.props.deleteItemsInReqList(deleteObj);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.createdRequisitionList !== '' &&
            nextProps.createdRequisitionList) {
            this.setState({ open: false });
        }
    }
    render() {
        const { open,localOpen } = this.state;
        const { classes, sku, buttonName } = this.props;
        const { openReqModal, requisitionList } = this.props;
        if (!this.state.isAuthenticated) {
            return <Redirect push to='/account' />;
        }
        let requisitionValues = Object.values(requisitionList);
        return (
            <React.Fragment>
                {typeof buttonName === 'undefined' ?
                    <IconButton onClick={(e) => this.handleToggle(e)}>
                         <img  style={{ width: '20px',marginRight:'10px' }} src={ViewList}/>
                        {/* <ViewList color='secondary' fontSize='small' /> */}
                    </IconButton> :
                    <Button color='secondary' onClick={(e) => this.handleToggle(e)}>
                        {buttonName}
                    </Button>}
                <Popover open={open} anchorEl={this.state.anchorEl} onClose={this.handleClose}>
                    <Paper>
                        <MenuList>
                            {requisitionValues.length && requisitionValues.map((item, index) => {
                                if (item.name === null ||
                                    ((this.props.getReqListId === item.entity_id) && window.location.pathname.includes('myaccount'))) {
                                    return null;
                                }
                                return (
                                    <MenuItem key={index} className={classes.menuText} onClick={() => this.handleUpdateRequisitionList(item)}>{item.name}</MenuItem>
                                );
                            })}
                            <MenuItem className={classes.createReqStyle} onClick={this.handleOpenModal}>
                            {<GetLanguage value="+ Create New Requisition List"/>}</MenuItem>
                        </MenuList>
                    </Paper>
                </Popover>
                {localOpen &&
                    <CreateNewRequisition plp={true} sku={sku} buttonName={this.props.buttonName} />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    requisitionList: state.getRequisitionList,
    variant_selected: state.variant_selected,
    openReqModal: state.openCreateReqModal,
    selectedEntity: state.selectedEntity,
    selectedItemInReq: state.selectedItemInReq,
    getReqListId: state.getReqListId,
});

const mapDispatchToProps = dispatch => ({
    openCreateReqModal: (status) => {
        let reqService = new RequisitionService();
        dispatch(reqService.OpenCreateReqModalActionCreator(status));
    },
    createNewRequisite: (createReqObject) => {
        let reqService = new RequisitionService();
        dispatch(reqService.createNewRequisitionListActionCreator(createReqObject));
    },
    deleteItemsInReqList: (obj) => {
        let reqService = new RequisitionService();
        dispatch(reqService.DeleteItemsInReqListActionCreator(obj));
    },
});

const RequisitionButtonWithStyles = withStyles(styles)(RequisitionButton);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequisitionButtonWithStyles));
