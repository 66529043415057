import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
});
const Loader = ({ loader_status }) => {

    if (loader_status) {
        return (
            <div style={{ zIndex: '999999999999', top: 0, position: 'fixed', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'auto' }}>
                <CircularProgress />
            </div>
        )
    } else {

        return null;
    }
}

const mapStateToProps = (state) => ({
    loader_status: state.get_loader_status
})


const LoaderWithStyles = withStyles(styles)(Loader);
export default connect(mapStateToProps)(LoaderWithStyles);
