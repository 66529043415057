/**
* Template for preparing Pages with Header and Footer (Global).
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

});

/**************************************************************
 * This component renders a Page with the props it receives. 
 * Acts as a template with Header and Footer.
 * Includes a Header, Footer and Navigation Bar.
 * Theme gets passed as a prop.
 * Renders the page blocks passed as an array. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *************************************************************/
class Global extends Component {

    render() {        
        const { HeaderComponent, NavigationComponent, FooterComponent, blocks } = this.props;
        return (
            <Fragment>
                <HeaderComponent></HeaderComponent>
                <NavigationComponent></NavigationComponent>
                {blocks && blocks.map((Block,i) => {
                    return <Block key={i}>{this.props.children}</Block>
                })}                
                <FooterComponent></FooterComponent>
            </Fragment>
        );
    }
}
Global.propTypes = {
    classes: PropTypes.object.isRequired,
    HeaderComponent: PropTypes.func.isRequired,
    NavigationComponent : PropTypes.func.isRequired,
    FooterComponent: PropTypes.func.isRequired,
    theme: PropTypes.object,
    blocks: PropTypes.array
};

export default withStyles(styles)(Global);

