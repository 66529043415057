/**
* Magna Cookie Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

/***************************************************************
 * Component to set cookie
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
const magnaCookies = {
    
    setCookie(cookieData, isEcncrypted = false) {
        
        if(isEcncrypted){
            //encrypt cookie value
            var encryptedData = CryptoJS.AES.encrypt(cookieData.value, Math.random() + 'secret key');    
            //create instance of universal-cookie
            //set encrypted cookie value in the cookie
            Cookies.set(cookieData.key, encryptedData.toString(), { path: '/', expires: cookieData.expiry });           
        }
        else
        {
            //create instance of universal-cookie
            //set raw Cookie value in the cookie
            Cookies.set(cookieData.key, cookieData.value, { path: '/', expires: cookieData.expiry });           
        }
    },

    getCookie(name) {
        //Create instance of universal-cookie
        return Cookies.get(name);
    },

    removeCookie(name) {
        //Remove a cookie.
        Cookies.remove(name, { path: '/' });
    }
    
};
export default magnaCookies;