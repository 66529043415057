/**
* Unit Selection Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridOn from '@material-ui/icons/GridOn';
import ViewList from '@material-ui/icons/ViewList';
import { connect } from 'react-redux';
import ApplicationService from '../../../../app/modules/application/ApplicationService';

const styles = theme => ({
  gridView: {
    marginRight: '3px'
  },
  listView: {
    fontSize: "23px",
    marginTop: '-2px',
    marginRight: '8px'
  },

});
/****************************************************************
 * This component returns input field which includes search icon
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class PLPView extends React.Component {

  handleChange(index) {
    localStorage.setItem('plpview', index);
    this.props.plpViewChange(index);
  }
  render() {
    let { classes } = this.props;
    let { plpViewSelected } = this.props;

    var plpViewArray = [
      {
        icon: <GridOn color={plpViewSelected === 0 ? 'action' : 'error'} fontSize='small' className={classes.gridView} />,
      },
      {
        icon: <ViewList color={plpViewSelected === 1 ? 'action' : 'error'} fontSize='small' className={classes.listView} />,
      }]
    return (
      <Grid container direction='row'>
        {plpViewArray.map((item, index) => (
          <Grid item style={{ cursor: 'pointer' }} key={index} onClick={() => this.handleChange(index)}>
            {item.icon}
          </Grid>
        ))}
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  plpViewSelected: state.plpView,
});
const mapDispatchToProps = dispatch => ({
  plpViewChange: (index) => {
    let as = new ApplicationService();
    dispatch(as.setPLPViewActionCreator(index));
  }
});
const PLPViewStyles = withStyles(styles)(PLPView)
export default connect(mapStateToProps, mapDispatchToProps)(PLPViewStyles);