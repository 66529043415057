/**
* Logo Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import LogoImage from '../../../../theme/base/assets/icons/magna.svg';

const styles = theme => ({
    cardInTitle: {
        border: 'none'
    }
});

/***************************************************************
  * Component class for displaying Logo
  * @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class Logo extends React.Component {
    render() {
        return (
            <Button style={{ backgroundColor: 'transparent',padding:0 }} component={Link} to="/">
                <img src={LogoImage} alt="Logo" width="60" height="60" />
            </Button>
        )
    }
}

export default withStyles(styles)(Logo);