/**
* Local Storage Handler.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

/***************************************************************
 * function to get key value in the local storage.
 ***************************************************************/
export function getItemFromLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
}


/***************************************************************
 * function to set key value in the local storage.
 ***************************************************************/
export function setItemInLocalStorage(key, value) {
    localStorage.setItem(key, value);
}


/***************************************************************
 * function to remove key value in the local storage.
 ***************************************************************/
export function removeItemFromLocalStorage(key) {
    localStorage.removeItem(key);
}