/**
* Main theme configuration file
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import { createMuiTheme } from '@material-ui/core/styles';

/*****************************************************************************
 * Main theme configuration of Material UI is provided below.
 ****************************************************************************/
const palette = {
    common: {
        "black": "#000",
        "white": "#fff",
    },
    primary: {
        "main": "#F8546F",
        "light": "#F73455",
        "dark": "#F73455",
        "contrastText": "#072D77"
    },
    secondary: {
        "main": "#072D77",
        "light": "rgb(231, 51, 115)",
        "dark": "#F73455",
        "contrastText": "#fff"
    },
    error: {
        "light": "#e57373",
        "main": "#f44336",
        "dark": "#d32f2f",
        "contrastText": "#fff"
    },
    action: {
        "light": "#E6F4FF",
        "main": "#69C1FC",
        "dark": "#57B3FF",
        "contrastText": "#fff"
    },
    grey: {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        "A100": "#d5d5d5",
        "A200": "#aaaaaa",
        "A400": "#303030",
        "A700": "#616161"
    },
}
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        // fontFamily: "'Ubuntu', sans-serif"
    },
    //Border Properties to be used set at Theme level.
    borderProperties: {
        primary: {
            margin: 15,
            padding: 15,
            transition: 'transform .2s',
            border: '1px solid blue',
            boxShadow: '10px 10px 8px #888888',
            '&:hover': {
                border: '1px outset #A9A9A9',
                transform: 'scale(1.05)',
            },
        },
        secondary: {
            margin: 15,
            padding: 15,
            transition: 'transform .2s',
            border: '1px solid red',
            '&:hover': {
                border: '1px outset #A9A9A9',
                transform: 'scale(1.05)',
            },
        }
    },
    overrides: {
        MuiMenuItem:
        {
            root: {
                color: '#ffffff',
                fontSize: '13pt',
                fontWeight: 450,
            }
        },
        //Over ride for Material UI button.
        MuiButton: {
            root: {
                color: palette.primary.main,
                fontFamily: "'Open Sans', sans-serif"
            },
            flat: {
                color: palette.primary.main,
            },
            flatPrimary: {
                color: palette.grey["500"],
                textTransform: 'capitalize'
            },
            flatSecondary: {
                color: palette.secondary.main,
                textTransform: 'capitalize'
            },
            label: {
                textTransform: 'capitalize'
            },
            text: {

            },
            textPrimary: {
                color: palette.primary.main,
            },
            textSecondary: {

            },
            outlined: {

            },
            outlinedPrimary: {
                borderColor: palette.primary.main,
            },
            outlinedSecondary: {
                color: palette.secondary.main,
                borderColor: palette.secondary.main,
                borderRadius: '20px',
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            contained: {

            },
            containedPrimary: {
                backgroundColor: palette.primary.main,
                boxShadow: 'none',
            },
            containedSecondary: {
                backgroundColor: palette.action.dark,
                borderRadius: '20px',
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: palette.action.dark + '!important',
                },
                textTransform: 'capitalize'

            },
            raised: {

            },
            raisedPrimary: {

            },
            raisedSecondary: {

            },
            // fab: {

            // },
            extendedFab: {

            },
            focusVisible: {

            },
            disabled: {

            },
            colorInherit: {

            },
            mini: {

            },
            sizeSmall: {

            },
            sizeLarge: {

            },
            fullWidth: {

            }
        },
        MuiFab:
        {
            root:
            {
                '&:hover': {
                    backgroundColor: palette.primary.dark,
                    boxShadow: '0px 3px 3px',
                    '@media (hover: none)': {
                        backgroundColor: palette.primary.dark,
                    },
                },
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 'bold',
                fontSize: '14px',
                height: '42px',

            },
            secondary: {
                backgroundColor: palette.common.white,
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: palette.primary.light,
                },
                label: {
                    color: palette.secondary.dark,
                },
            },
            label: {
                color: palette.common.white,
            },
            primary: {
                backgroundColor: palette.common.white,
                color: palette.secondary.dark,
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: palette.primary.light,
                },
            },
            extended: {
                backgroundColor: palette.primary.dark,
                boxShadow: 'none',
            },

        },
        //Over ride for Material UI Icon button.
        MuiIconButton: {
            root: {
                color: palette.primary.main,
                padding: '0rem',
                '&:hover': {
                    backgroundColor: '#ffffff',
                },
            },
            colorPrimary: {
                color: palette.primary.main,
            },
            colorSecondary: {
                color: palette.secondary.main,
            },
            colorInherit: {
                color: palette.common.white,
            },
            disabled: {
            },
            label: {
                color: palette.common.black,
            }
        },
        //Over ride for Material UI Svg Icon.
        MuiSvgIcon: {
            root: {
                color: palette.primary.dark,
            },
            colorPrimary: {
                color: palette.primary.dark,
                fontSize: '16px',
                '&:hover': {
                    color: palette.secondary.main,
                },
                '&:focus': {
                    color: palette.secondary.main,
                },
            },
            colorSecondary: {
                color: palette.secondary.main,
            },
            colorAction: {
                color: palette.action.dark,
            },
            colorError: {
                color: palette.grey["500"],
            },
            colorDisabled: {
                color: palette.common.white,
                '&:hover': {
                    color: palette.secondary.main,
                },
                '&:focus': {
                    color: palette.secondary.main,
                },
            },
            fontSizeInherit: {
                fontSize: 60,
            },
        },
        MuiBadge: {
            root: {
            },
            badge: {
                width: 16,
                height: 20,
                fontSize: 12,
            },
            colorPrimary: {
                backgroundColor: palette.primary.main,
            }
        },
        MuiAppBar: {
            root: {
            },
            colorPrimary: {
                backgroundColor: palette.common.white,
                boxShadow: 'none'
            }
        }, 
        MuiPaper: {
            root: {
                
            },
            rounded:{
                borderRadius:'0',
                boxShadow:'none !important',              
            },
        }, 
        MuiList:{
            root: {
                paddingTop:'0 !important',
                paddingBottom:'0 !important',
            },
        },                   
        MuiInput: {
            root: {
            },
            underline: {
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #2196f3',
                },
                '&:after': {
                    borderBottom: '2px solid #2196f3',
                },
            }
        },
        MuiStepConnector: {
            lineHorizontal: {
                borderTopWidth: '5px',
            },
            line: {
                borderColor: '#e0e0e0',
            }
        },
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: palette.primary.main,
                },
                '&$completed': {
                    color: palette.primary.main,
                }
            }
        },
        MuiRadio: {
            root: {
                color: palette.primary.main,
            },
            colorPrimary: {
                '&$checked': {
                    color: palette.primary.main,
                },
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: palette.primary.dark,
            },
            /* Styles applied to the root element if `color="textPrimary"`. */
            colorSecondary:
            {
                color: palette.secondary.dark,
            },
            colorError: {
                color: palette.grey["500"],
            },
            colorTextPrimary: {
                color: palette.action.dark,
            },
            colorTextSecondary: {
                color: palette.common.white,
            },
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: palette.primary.main,
                }
            }
        },
        MuiCard: {
            root: {
                boxShadow: 'none',
            }
        },
        MuiSwitch: {
            root: {
                '&$checked': {
                    color: palette.primary.main,
                },
            },
            colorPrimary: {
                '&$checked': {
                    color: palette.primary.main,
                    '&+$bar': {
                        backgroundColor: palette.primary.main,
                    }
                },
            }
        },
        MuiPrivateTabIndicator: {
            colorSecondary: {
                backgroundColor: 'transparent',
            },
        },
        MuiExpansionPanel: {
            'expanded': {
                margin: '0px',
            }
        },
        MuiTextField : {
            root: {
                // fontFamily: "'Ubuntu', sans-serif"
            }
        },
        MuiTableCell : {
            head: {
                color:'#000',
                fontSize:' 0.95rem',
                fontWeight: 700,
            }
        },
    },
});
export default theme;
