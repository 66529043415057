/**
* Filter Category Chips Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Ruchi Verma <ruchi.verma@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import CategoryService from '../../../../app/modules/category/CategoryService';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginLeft:'10px',
    marginBottom: '15px'
  },
});


/***************************************************************
 * Filter Category Chips Component. Genrates Chips after  
 * clicking on Filter Category options (collapsible button).
 * @author    Ruchi Verma <ruchi.verma@embitel.com>
 ***************************************************************/
class CategoryChips extends React.Component {
  state = {
    filter_tags: []
  };

  // To delete Filter Chips
  handleDelete = data => {
    const filter_tags = [...this.props.filter_tags.filterTags];
    let chipToDelete = -1;
    let i = 0;
    filter_tags.map((item)=>{
     if(item===data){
      chipToDelete = i;
     }
     i++;
    })
    const paramToRemove = filter_tags[chipToDelete].queryArray;
    let filterLocation = this.removeParam(paramToRemove,window.location.href);
    window.history.pushState({path:filterLocation},'',filterLocation) ;//filter again after deleting the chip
   //if no filter parameters then stay in the same page
    if(window.location.search===''){
      if(window.location.href.includes('/search')){
        window.location.href = window.location.href
      }
      else{
      this.props.history.push(filterLocation.pathname)
      }
    }
    else{
    this.props.history.push(window.location.search)//url with updated params
    }
    filter_tags.splice(chipToDelete, 1);
    this.props.setFilterTags(filter_tags)
  };

//function to remove params from url based on the clicked chip to delete
  removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            if (params_arr[i] === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }  

  render() {
    const { classes,filter_tags} = this.props;
    return (
      <div>
        {filter_tags&&filter_tags.filterTags&&filter_tags.filterTags.length>0&&filter_tags.filterTags.map((data,i) => {
          return (
            <Chip
              key={data}
              icon={'icon'}
              label={data.filterName}
              onDelete={()=>this.handleDelete(data)}
              className={classes.chip}
            />
          );
        })}
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  setFilterTags: (filterTags) => {
      let cs = new CategoryService();
      dispatch(cs.getFilterTagsDataActionCreator(filterTags));
  }
});

const mapStateToProps = state => ({
  filter_tags : state.get_filter_tag_chips
})

CategoryChips.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CategoryChipsWithStyles = withStyles(styles)(CategoryChips);
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CategoryChipsWithStyles));