/**
* AccountService class with functions for handling Account related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_POST_SIGNIN_USER,
    RECEIVE_POST_SIGNIN_USER,
    REQUEST_GET_USER_DETAILS,
    RECEIVE_GET_USER_DETAILS,
    REQUEST_RESET_SIGNED_IN_STATE,
    REQUEST_RESET_SIGNED_UP_STATE,
    REQUEST_INIT_SIGNED_UP_STATE,
    REQUEST_POST_SIGNUP_USER,
    RECEIVE_POST_SIGNUP_USER,
    REQUEST_RESET_FORGOT_PASSWORD,
    RECEIVE_RESET_FORGOT_PASSWORD,
    REQUEST_VALIDATE_TOKEN,
    RECEIVE_VALIDATE_TOKEN,
    REQUEST_FORGOT_PASSWORD,
    REQUEST_VERIFY_TOKEN,
    RECEIVE_VERIFY_TOKEN,
    RECEIVE_FORGOT_PASSWORD,
    REQUEST_GET_SIGNIN_USER_DATA,
    RECEIVE_GET_SIGNIN_USER_DATA,
    REQUEST_SAVE_PROFILE_NAME,
    RECEIVE_SAVE_PROFILE_NAME,
    REQUEST_RESET_SAVE_PROFILE_NAME,
    REQUEST_PUT_CHANGE_PASSWORD,
    RECEIVE_PUT_CHANGE_PASSWORD,
    REQUEST_RESET_CHANGE_PASSWORD,
    REQUEST_PUT_SUBSCRIBE,
    RECEIVE_PUT_SUBSCRIBE,
    REQUEST_RESET_SUBSCRIBE,
    REQUEST_SET_SUBMENU,
    RECIEVE_SET_SUBMENU,
    SET_LOADER_STATUS,
    REQUEST_POST_SIGNIN_SALESMAN,
    RECEIVE_POST_SIGNIN_SALESMAN,
    REQUEST_SALESMAN_STORES,
    RECEIVE_SALESMAN_STORES,
    REQUEST_RESET_SALESMAN_SIGNED_IN_STATE,
    REQUEST_GET_USER_UPDATED_DETAILS,
    RECEIVE_GET_USER_UPDATED_DETAILS
} from '../../framework/common/ReduxConstants';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import magnaCookies from '../../framework/cookie/magnaCookies';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
import { removeItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
import { setItemInLocalStorage } from '../../framework/storage/local/localStorageHandler';
import getConfigValue from '../../framework/config/configHandler';
import CartService from './../cart/CartService';
import OrderService from '../order/OrderService';
import { NotificationManager } from 'react-notifications';
import RequisitionService from '../requisition/RequisitionService';


/**************************************************************************************
 * Account Service class which handles Sign In, Sign Up
 * Forgot password and Reset password functionalities.
 **************************************************************************************/
class AccountService {

    /**********************************************************************************
     * Action Creator for Sign In functionality.
     *********************************************************************************/
    signInActionCreator(email, password) {
        return (dispatch) => {
            dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_POST_SIGNIN_USER });
            let requestBody = {};
            requestBody.data = {
                "username": email,
                "password": password,
                "type": "email"
            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/ecs/customerlogin';
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (response) => {
                    //Get Customer details by Calling Another API
                    let userToken = response.data[0].Company;
                    let multiple = { ...response.data[0] }
                    //Get User Details and store Customer Id in localStorage.
                    dispatch({ type: REQUEST_GET_USER_DETAILS });
                    let requestURL = '/customers/me';
                    let requestType = 'GET';
                    let requestBody = {};
                    requestBody.header = ("Bearer " + userToken);
                    requestBody.apiEndPoint = 'thailand';
                    MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                        (response) => {
                            dispatch({ type: RECEIVE_GET_USER_DETAILS, success: true, response });
                            //On successful Sign In, store the user
                            //information in localStorage.
                            let user = {
                                firstname: response.data.firstname,
                                lastname: response.data.lastname,
                                email: response.data.email,
                                token: userToken,
                                multiple,
                                customerId: response.data.id,
                                companyId: response.data.extension_attributes ? response.data.extension_attributes.company_attributes 
                                            ? response.data.extension_attributes.company_attributes.company_id :'':'',
                            };
                            //Store in the local storage
                            setItemInLocalStorage('shopce-user', JSON.stringify(user));

                            //Set cookie data
                            let cookieData = {
                                key: 'ECS_AC',
                                value: response.data.email,
                                expiry: ''
                            };
                            //Store in the cookie                            
                            magnaCookies.setCookie(cookieData, true);

                            AccountService.getCustomerDetails(dispatch);
                            dispatch({ type: RECEIVE_POST_SIGNIN_USER, success: true, response });
                            dispatch({ type: SET_LOADER_STATUS });
                        },
                        (error) => {
                            dispatch({ type: SET_LOADER_STATUS });
                            dispatch({ type: RECEIVE_GET_USER_DETAILS, success: false, error });
                            let genericError = 'Error, Please try again after some time';
                            let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                            NotificationManager.error(errorMessage);
                        }
                    );
                    // dispatch(accObj.GetUserDetailsActionCreator(userToken));
                },
                (error) => {
                    dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECEIVE_POST_SIGNIN_USER, success: false, error });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }
    salesManSignActionCreator(email, password) {
        return (dispatch) => {
            dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_POST_SIGNIN_SALESMAN });
            let requestBody = {};
            requestBody.data = {
                "username": email,
                "password": password,
            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/integration/admin/token';
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (res) => {
                    //Store in the local storage
                    setItemInLocalStorage('salesman-user', JSON.stringify(res.data));
                    let requestBody = {};
                    requestBody.header = ("Bearer " + res.data);
                    let requestURL = '/salesman/stores';
                    let requestType = 'GET';
                    MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                        (response) => {
                            dispatch({ type: RECEIVE_POST_SIGNIN_SALESMAN, success: true, response });
                            dispatch({ type: SET_LOADER_STATUS });
                        },
                        (error) => {
                            dispatch({ type: SET_LOADER_STATUS });
                            dispatch({ type: RECEIVE_POST_SIGNIN_SALESMAN, success: false, error });
                            let genericError = 'Error, Please try again after some time';
                            let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                            NotificationManager.error(errorMessage);
                        }
                    );

                },
                (error) => {
                    dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECEIVE_POST_SIGNIN_SALESMAN, success: false, error });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }
    GetSalesManStores(token) {
        return (dispatch) => {
            dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_POST_SIGNIN_SALESMAN });            
            let requestBody = {};
            requestBody.header = ("Bearer " + token);
            let requestURL = '/salesman/stores';
            let requestType = 'GET';
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (response) => {
                    dispatch({ type: RECEIVE_POST_SIGNIN_SALESMAN, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECEIVE_POST_SIGNIN_SALESMAN, success: false, error });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        }
    }
    static salesManSignInReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_SIGNIN_SALESMAN:
                if (action.success && action.response && action.response.data ) {
                    return Object.assign({}, action.response.data[0], { signInSuccess: true, isSignInButtonDisabled: false, isSalesmanUser: true });
                }
                return Object.assign({}, { signInSuccess: false, isSignInButtonDisabled: false, isSalesmanUser: false });
            case REQUEST_POST_SIGNIN_SALESMAN:
                return { ...state, signInSuccess: null, isSignInButtonDisabled: true, isSalesmanUser: false };
            // To reset signInSuccess to null after Signing In                
            case REQUEST_RESET_SALESMAN_SIGNED_IN_STATE:
                return { ...state, signInSuccess: null, isSignInButtonDisabled: false, isSalesmanUser: null };
            default:
                return state;
        }
    }

    /**********************************************************************************
     * Resetting action creator for Sign In  functionality.
     *********************************************************************************/
    resetSignedInActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_SIGNED_IN_STATE });
        };
    }

    /**********************************************************************************
    * @param {*} userInfo - object stored in local storage.
    * Function to check if email, name and token are present
    * in the local Storage. All the three are to be present to
    * pre-populate email and display Switch Account.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *********************************************************************************/
    static checkLocalStorage(userInfo) {
        if (userInfo && userInfo.email && userInfo.name && userInfo.token) {
            return true;
        }
        else {
            return false;
        }
    }
    static getCustomerDetails(dispatch, type) {
        const cs = new CartService();
        const order = new OrderService();
        const rs = new RequisitionService();
        const as = new AccountService();
        dispatch(as.GetCustomerDetailsActionCreator());
        dispatch(cs.GetDisplayMiniCartActionCreator());
        dispatch(order.getOrderHistoryActionCreator(type));
        dispatch(rs.GetRequisitionListActionCreator());
    }
    /**********************************************************************************
     * Action creator for Sign Up functionality.
     *********************************************************************************/
    signUpUserActionCreator(SignUpObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_POST_SIGNUP_USER });
            let requestBody = {};
            requestBody.data = SignUpObject;
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/customers';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //On succesful Sign Up, Sign In the user
                    dispatch({ type: REQUEST_POST_SIGNIN_USER });
                    let requestBody = {};
                    requestBody.data = {
                        "username": SignUpObject.customer.email,
                        "password": SignUpObject.password,
                        "type": "email"
                    }
                    requestBody.apiEndPoint = 'thailand';
                    let requestType = 'POST';
                    let requestURL = '/ecs/customerlogin';
                    MagnaAPIHandler(requestURL, requestType, requestBody).then(
                        (response) => {
                            let userToken = response.data[0].Company;
                            let multiple = { ...response.data[0] };
                            //Get User Details and store Customer Id in localStorage.
                            dispatch({ type: REQUEST_GET_USER_DETAILS });
                            let requestURL = '/customers/me';
                            let requestType = 'GET';
                            let requestBody = {};
                            requestBody.header = ("Bearer " + userToken);
                            requestBody.apiEndPoint = 'thailand';
                            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                                (response) => {
                                    dispatch({ type: RECEIVE_GET_USER_DETAILS, success: true, response });
                                    //On successful Sign In, store the user
                                    //information in localStorage.
                                    NotificationManager.success('Registraion successful');


                                    let user = {
                                        firstname: response.data.firstname,
                                        lastname: response.data.lastname,
                                        email: response.data.email,
                                        token: userToken,
                                        multiple,
                                        customerId: response.data.id,
                                        companyId: response.data.extension_attributes ? response.data.extension_attributes.company_attributes 
                                                    ? response.data.extension_attributes.company_attributes.company_id :'':'',
                                    };
                                    //Store in the local storage
                                    setItemInLocalStorage('shopce-user', JSON.stringify(user));

                                    //Set cookie data
                                    let cookieData = {
                                        key: 'ECS_AC',
                                        value: response.data.email,
                                        expiry: ''
                                    };
                                    //Store in the cookie                            
                                    magnaCookies.setCookie(cookieData, true);

                                },
                                (error) => {dispatch({ type: RECEIVE_GET_USER_DETAILS, success: false, error })
                                let genericError = 'Error, Please try again after some time';
                                let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                                NotificationManager.error(errorMessage);
                            });
                            //End of Get User Details.
                            //Send success dispatch for Sign-In
                            dispatch({ type: RECEIVE_POST_SIGNIN_USER, success: true, response });
                            //Send success dispatch for Sign-Up
                            dispatch({ type: RECEIVE_POST_SIGNUP_USER, success: true, response });
                        },
                        (error) => dispatch({ type: RECEIVE_POST_SIGNIN_USER, success: false, error })
                    );
                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_SIGNUP_USER, success: false, error });
                }
            );
        };
    }

    /**********************************************************************************
     * Getting User(Customer) details
     *********************************************************************************/
    GetCustomerDetailsActionCreator() {
        return (dispatch) => {
            //Get User Details and store Customer Id in localStorage.
            dispatch({ type: REQUEST_GET_USER_DETAILS });
            //dispatch({ type: SET_LOADER_STATUS });
            let requestURL = '/customers/me';
            let requestType = 'GET';
            let requestBody = {};
            let CustomerExist = getItemFromLocalStorage('shopce-user');
            requestBody.header = ("Bearer " + CustomerExist.token);
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_USER_DETAILS, success: true, response });
                    //dispatch({ type: SET_LOADER_STATUS });
                    //On successful Sign In, store the user
                    //information in localStorage.
                    // let user = {
                    //     firstname: response.data.firstname,
                    //     lastname: response.data.lastname,
                    //     email: response.data.email,
                    //     token: CustomerExist.token,
                    //     customerId: response.data.id
                    // };
                    // //Store in the local storage
                    // setItemInLocalStorage('shopce-user', JSON.stringify(user));

                    //Set cookie data
                    let cookieData = {
                        key: 'ECS_AC',
                        value: response.data.email,
                        expiry: ''
                    };
                    //Store in the cookie                            
                    magnaCookies.setCookie(cookieData, true);

                },
                (error) => dispatch({ type: RECEIVE_GET_USER_DETAILS, success: false, error }),
                //dispatch({ type: SET_LOADER_STATUS })
            );
        };
    }

    /**********************************************************************************
     * Getting User(Customer on behalf of Salesman) details
     *********************************************************************************/
    GetUserDetailsActionCreator(users) {
        return (dispatch) => {
            //Get User Details and store Customer Id in localStorage.
            dispatch({ type: REQUEST_GET_USER_DETAILS });
            dispatch({ type: SET_LOADER_STATUS });
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            let userToken = users.Company;
            let requestURL = '/customers/me';
            let requestType = 'GET';
            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (response) => {
                    //On successful Sign In, store the user
                    //information in localStorage.
                    let user = {
                        firstname: response.data.firstname,
                        lastname: response.data.lastname,
                        email: response.data.email,
                        token: userToken,
                        multiple: users,
                        customerId: response.data.id,
                        companyId: response.data.extension_attributes ? response.data.extension_attributes.company_attributes 
                                    ? response.data.extension_attributes.company_attributes.company_id :'':'',
                    };
                    //Store in the local storage
                    setItemInLocalStorage('shopce-user', JSON.stringify(user));

                    //Set cookie data
                    let cookieData = {
                        key: 'ECS_AC',
                        value: response.data.email,
                        expiry: ''
                    };
                    //Store in the cookie                            
                    magnaCookies.setCookie(cookieData, true);
                    AccountService.getCustomerDetails(dispatch);
                    dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECEIVE_GET_USER_DETAILS, success: true, response });
                },
                (error) => {
                    dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECEIVE_GET_USER_DETAILS, success: false, error });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }

    /**********************************************************************************
     * Resetting the Redux state for Sign Up.
     *********************************************************************************/
    resetSignedUpActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_SIGNED_UP_STATE });
        };
    }

    /**********************************************************************************
     * Resetting the Redux state for SalesMan Sign In.
     *********************************************************************************/
    resetSalesManSignInActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_SALESMAN_SIGNED_IN_STATE });
        };
    }
    /**********************************************************************************
     * Initialising the Redux state for Sign Up
     *********************************************************************************/
    initSignedUpActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_INIT_SIGNED_UP_STATE });
        };
    }

    /**********************************************************************************
     * Clearing browser storage after User Sign Out.
     *********************************************************************************/
    static clearOnSignOut() {
        //Instead of Signing Out with Customer ID and redux dispatches,
        //delete the Local Storage object for the user.
        removeItemFromLocalStorage('shopce-user');
        removeItemFromLocalStorage('sales-man');
        removeItemFromLocalStorage('salesman-user');
        removeItemFromLocalStorage('i18nextLng');

        //Remove the customer cart cookie after Logout
        // Uses helper function.
        magnaCookies.removeCookie('ECS_AC');
        //Remove customer cart id after logout
        magnaCookies.removeCookie('customer-cart-id');
        removeItemFromLocalStorage('current_cart');
    }

    /**********************************************************************************
     * Action Creator for Reset Password.
     *********************************************************************************/
    resetPasswordActionCreator(resetPasswordObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_FORGOT_PASSWORD });
            dispatch({ type: SET_LOADER_STATUS });
            let requestBody = {};

            requestBody.data = {
                "email": resetPasswordObject.validateTokenEmail,
                "resetToken": resetPasswordObject.resetPasswordToken,
                "newPassword": resetPasswordObject.confirmResetPassword,
            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/customers/resetPassword';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch after setting the new password                   
                    dispatch({ type: RECEIVE_RESET_FORGOT_PASSWORD, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                    NotificationManager.success('Password Updated Successfully');
                },
                (error) => {
                    dispatch({ type: RECEIVE_RESET_FORGOT_PASSWORD, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }

    /**********************************************************************************
     * Validating token Action Creator for resetting the password. 
     *********************************************************************************/
    validateTokenActionCreator(validateTokenObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_VALIDATE_TOKEN });
            let requestBody = {};
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'GET';
            let requestURL = '/customers/' + validateTokenObject.customerId + '/password/resetLinkToken/' + validateTokenObject.resetToken;
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch after validating the token
                    dispatch({ type: RECEIVE_VALIDATE_TOKEN, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_VALIDATE_TOKEN, success: false, error });
                    
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }

    /*************************************************************************************
     * Action creator for forgot password.
     ************************************************************************************/
    forgotPasswordActionCreator(forgotPasswordObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_FORGOT_PASSWORD });
            let requestBody = {};
            const systemParameters = getConfigValue("system");

            requestBody.data = {
                "email": forgotPasswordObject.email,
                "template": "email_reset",
                "websiteId": window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].websiteId:systemParameters.store[1].websiteId
            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'PUT';
            let requestURL = '/customers/password';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for Forgot Password 
                    dispatch({ type: RECEIVE_FORGOT_PASSWORD, success: true, response });
                    NotificationManager.success('You will receive an email to reset your password');
                },
                (error) => {
                    dispatch({ type: RECEIVE_FORGOT_PASSWORD, success: false, error });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }

    getSignedInUserDataActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_SIGNIN_USER_DATA });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            };
            let requestBody = {};
            requestBody.data = {};
            requestBody.header = ("Bearer " + userToken);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'GET';
            let requestURL = '/customers/me';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_SIGNIN_USER_DATA, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_SIGNIN_USER_DATA, success: false, error });
                }
            );
        };
    }

    /********************************************************************************   
     * Action Creator for saving Customer name change.
     ********************************************************************************/
    updateSignedInUserProfileActionCreator(customerProfileObject) {
        return (dispatch) => {
            /*************************************************************************
             * Reset the customerNameChangeStatus to null, before saving
             * the profile name.
             *************************************************************************/
            // dispatch({ type: REQUEST_RESET_SAVE_PROFILE_NAME });
            dispatch({ type: REQUEST_GET_USER_UPDATED_DETAILS });
            //Get token from localStorage
            dispatch({ type: SET_LOADER_STATUS });
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            };
            const systemParameters = getConfigValue("system");
            let websiteId = window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].websiteId:systemParameters.store[1].websiteId
            customerProfileObject.customer["website_id"] = websiteId;
            let requestBody = {};
            requestBody.data = customerProfileObject;
            requestBody.header = ("Bearer " + userToken);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'PUT';
            let requestURL = '/customers/me';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: SET_LOADER_STATUS });
                    let userFromLocalStorage = getItemFromLocalStorage('shopce-user');

                  
                    if(userFromLocalStorage)
                    {
                        userFromLocalStorage.firstname=response.data.firstname;
                        userFromLocalStorage.lastname=response.data.lastname;
                    }
                  
                    setItemInLocalStorage('shopce-user', JSON.stringify(userFromLocalStorage));
                    NotificationManager.success('Account Updated');
                    
                    dispatch({ type: RECEIVE_GET_USER_UPDATED_DETAILS, success: true, response });
                },
                (error) => {
                    dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECEIVE_GET_USER_UPDATED_DETAILS, success: false, error });
                }
            );
        };
    }

    resetNameChangeActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_USER_UPDATED_DETAILS });
        };
    }


        /**********************************************************************************
     * Reducer for Get User Details request.
     *********************************************************************************/
    static get_updated_user_details_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_GET_USER_UPDATED_DETAILS:
                return { ...state, profileNameUpdateStatus: null };

            case RECEIVE_GET_USER_UPDATED_DETAILS:
                let retUserValue = AccountService.applyGetUpdatedUserDetails(state, action);
                return retUserValue;
            default:
                return state;
        }
    }

        /************************************************************************************
     * Helper function for reducer get_user_details_reducer
     ***********************************************************************************/
    static applyGetUpdatedUserDetails = (state, action) => {
        if (action.success && action.response.data ) {
            return Object.assign({}, action.response.data, { profileNameUpdateStatus:true });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                signInSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { profileNameUpdateStatus:false });

      
    }
}



    /***********************************************************************************
     * Resetting the Redux state for Customer profile name change.
     ***********************************************************************************/
    resetPutNameChangeActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_SAVE_PROFILE_NAME });
        };
    }

    /***********************************************************************************
     * Helper function for reducer post_sign_in_customer_reducer
     ***********************************************************************************/
    static applySignInCustomer = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                signInSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { signInSuccess: true, isSignInButtonDisabled: false });
        }
        else {
            return [];
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                signInSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { signInSuccess: false, isSignInButtonDisabled: false });
        }
    }

    /************************************************************************************
     * Sign In POST request reducer.
     ************************************************************************************/
    static post_sign_in_customer_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_SIGNIN_USER:
                let retValue = AccountService.applySignInCustomer(state, action);
                return retValue;
            // To initialize the signInSuccess to null, 
            // on next "Sign In" button click.
            case REQUEST_POST_SIGNIN_USER:
                return { ...state, signInSuccess: null, isSignInButtonDisabled: true };
            // To reset signInSuccess to null after Signing In                
            case REQUEST_RESET_SIGNED_IN_STATE:
                return { ...state, signInSuccess: null, isSignInButtonDisabled: false, isSalesmanUser: null };
            default:
                return state;
        }
    }

    /************************************************************************************
    * Helper function for reducer post_sign_up_customer_reducer
    *************************************************************************************/
    static applySignUpCustomer = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                signUpSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { signUpSuccess: true, isSignUpButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                signUpSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { signUpSuccess: false, isSignUpButtonDisabled: false });
        }
    }

    /***********************************************************************************
     * Reducer for Sign Up POST request.
     **********************************************************************************/
    static post_sign_up_customer_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_SIGNUP_USER: {
                let retValueResult = AccountService.applySignUpCustomer(state, action);
                return retValueResult;
            }
            // To initialize the signUpSuccess to null, 
            // on next "Sign Up" button click.
            case REQUEST_POST_SIGNUP_USER:
                return { ...state, signUpSuccess: null, isSignUpButtonDisabled: true };
            case REQUEST_RESET_SIGNED_UP_STATE:
                return { ...state, signUpSuccess: null, isSignUpButtonDisabled: false, isSalesmanUser: null };
            // Initial Signed Up Form State.
            case REQUEST_INIT_SIGNED_UP_STATE:
                return { ...state, signUpSuccess: null, isSignUpButtonDisabled: false };
            default: return state;
        }
    }

    /************************************************************************************
     * Helper function for reducer post_forgot_password_reducer
     ***********************************************************************************/
    static applyForgotPassword = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                forgotPasswordStatus: true,
            };
            return Object.assign({}, returnObjectSuccess, { forgotPasswordStatus: true, isForgotPasswordButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                forgotPasswordStatus: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { forgotPasswordStatus: false, isForgotPasswordButtonDisabled: false });
        }
    }

    /**********************************************************************************
     * Reducer for Forgot password POST request.
     *********************************************************************************/
    static post_forgot_password_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_FORGOT_PASSWORD:
                return { ...state, forgotPasswordStatus: null, isForgotPasswordButtonDisabled: true };
            case RECEIVE_FORGOT_PASSWORD:
                let retResetValue = AccountService.applyForgotPassword(state, action);
                return retResetValue;
            default:
                return state;
        }
    }

    /************************************************************************************
     * Helper function for reducer get_user_details_reducer
     ***********************************************************************************/
    static applyGetUserDetails = (state, action) => {
        if (action.success && action.response.data && action.response.data.extension_attributes) {
            return Object.assign({}, action.response.data, { isSalesmanUser: action.response.data.extension_attributes.is_salesman });
        }
        // else {
        //     let genericError = 'Error, Please try again after some time';
        //     let returnObjectError = {
        //         signInSuccess: false,
        //         errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
        //     };
        //     return Object.assign({}, returnObjectError, { signInSuccess: false, isSignInButtonDisabled: false });

        // if (action.success && action.response.data) {
        //     return action.response.data;
        //     //isSalesmanUser: action.response.data.extension_attributes.is_salesman 
        // }
        return null;
    }

    /**********************************************************************************
     * Reducer for Get User Details request.
     *********************************************************************************/
    static get_user_details_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_USER_DETAILS:
                let retUserValue = AccountService.applyGetUserDetails(state, action);
                return retUserValue;
            default:
                return state;
        }
    }

    /**********************************************************************************
     * Helper function for Reducer ResetPasswordReducer
     *********************************************************************************/
    static applyValidateToken = (state, action) => {
        if (action.success) {
            let validateTokenSuccess =
            {
                "validateTokenresetPasswordEmail": action.response.data[0].email,
                "validateTokenresetPasswordStatus": true,
                "validateTokenerrorMessage": null,
            };
            return validateTokenSuccess;
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let validateTokenError =
            {
                "validateTokenresetPasswordEmail": null,
                "validateTokenresetPasswordStatus": false,
                "validateTokenerrorMessage": action.error.response ? (action.error.response.data ? action.error.response.data.message : genericError) : genericError,
            }
            return validateTokenError;
        }
    }

    /***********************************************************************************
     * Helper function for ResetPasswordReducer
     **********************************************************************************/
    static applyResetPassword = (state, action) => {
        if (action.response &&
            (action.response.status === 200) &&
            (action.response.data === true)) {
            let validateResetPasswordSuccess =
            {
                resetPasswordStatus: true,
                errorMessage: null,
            };
            return validateResetPasswordSuccess;
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let validateResetPasswordError =
            {
                resetPasswordStatus: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            }
            return validateResetPasswordError;
        }
    }

    /************************************************************************************
     * Reducer for Reset Password.
     ***********************************************************************************/
    static ResetPasswordReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_VALIDATE_TOKEN:
                return state;
            case RECEIVE_VALIDATE_TOKEN:
                let retValueValidateToken = AccountService.applyValidateToken(state, action);
                return retValueValidateToken;
            case REQUEST_RESET_FORGOT_PASSWORD:
                return state;
            case RECEIVE_RESET_FORGOT_PASSWORD:
                let retValueReset = AccountService.applyResetPassword(state, action);
                return retValueReset;
            default:
                return state;
        }
    }

    /************************************************************************************
     * Helper function for ResetPasswordReducer
     ***********************************************************************************/
    static getInitialStateResetForgotPassword() {
        const INITIAL_STATE = {
            resetPasswordStatus: null,
        };
        return INITIAL_STATE;
    }

    /************************************************************************************
     * Helper function for ResetPasswordReducer
     ***********************************************************************************/
    static applyResetForgotPassword = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                resetPasswordStatus: true,
            };
            return Object.assign({}, returnObjectSuccess, { resetPasswordStatus: true, isResetButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                resetPasswordStatus: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { resetPasswordStatus: false, isResetButtonDisabled: false });
        }
    }

    /*************************************************************************************
     * Reducer for resetting the password.
     ************************************************************************************/
    static ResetPasswordReducer(state = AccountService.getInitialStateResetForgotPassword(), action) {
        switch (action.type) {
            case RECEIVE_RESET_FORGOT_PASSWORD:
                let retValueResult = AccountService.applyResetForgotPassword(state, action);
                return retValueResult;
            // To initialize the resetPasswordStatus to null, 
            // on next "Reset Password" button click.
            case REQUEST_RESET_FORGOT_PASSWORD:
                return { ...state, resetPasswordStatus: null, isResetButtonDisabled: true };
            default: return state;
        }
    }

    //Helper method for SignedInUserInfoReducer
    static applySignedInUserInfo = (state, action) => {
        let signedInUserData = [];
        if (action.response && (action.response.status === 200)) {
            signedInUserData = action.response.data;
        }
        return signedInUserData;
    }

    /*************************************************************************************
     * Reducer for getting user information after Sign In.
     ************************************************************************************/
    static SignedInUserInfoReducer(state = [], action) {
        switch (action.type) {
            case RECEIVE_GET_SIGNIN_USER_DATA:
                let retValueResult = AccountService.applySignedInUserInfo(state, action);
                return retValueResult;
            default: return state;
        }
    }

    /*************************************************************************************
     * Action creator to verify token.
     ************************************************************************************/
    IsTokenValidActionCreator(userToken) {
        return (dispatch) => {
            dispatch({ type: REQUEST_VERIFY_TOKEN });
            let requestBody = {};
            requestBody.data = {
                "token": userToken,
            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/ecs/tokenvalidate';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch after validating the token
                    dispatch({ type: RECEIVE_VERIFY_TOKEN, success: true, response });
                    AccountService.getCustomerDetails(dispatch);
                    // let cs = new CartService();
                    // let rs = new RequisitionService();
                    // let as = new AccountService();
                    // dispatch(as.GetCustomerDetailsActionCreator());
                    // dispatch(cs.GetDisplayMiniCartActionCreator());
                    // dispatch(rs.GetRequisitionListActionCreator());
                },
                (error) => {
                    dispatch({ type: RECEIVE_VERIFY_TOKEN, success: false, error });
                }
            );
        };
    }

    /***********************************************************************************
     * Helper function for IsTokenValidReducer.
     ***********************************************************************************/
    static applyTokenValid = (state, action) => {
        if (action.success && action.response) {
            return action.response.data ? action.response.data[0] : '';
        }
        else {
            let genericError = 'Error, Please try again after some time';
            return {
                errorMessage: action.error ? action.error : genericError,
            }
        }
    }

    /************************************************************************************
     * Reducer to verify token.
     ***********************************************************************************/
    static IsTokenValidReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_VERIFY_TOKEN:
                return state;
            case RECEIVE_VERIFY_TOKEN:
                let retValueValidateToken = AccountService.applyTokenValid(state, action);
                return retValueValidateToken;
            default:
                return state;
        }
    }

    /***********************************************************************************
     * Helper function for reducer put_profile_name_update_reducer
     **********************************************************************************/
    static applyProfileNameChange = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                profileNameChangeStatus: true,
            };
            return Object.assign({}, returnObjectSuccess);
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                profileNameChangeStatus: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError);
        }
    }

    /************************************************************************************          
     * Customer profile name change reducer.
     ***********************************************************************************/
    static put_profile_name_update_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_SAVE_PROFILE_NAME:
                let retValue = AccountService.applyProfileNameChange(state, action);
                return retValue;
            case REQUEST_RESET_SAVE_PROFILE_NAME:
                return { ...state, profileNameChangeStatus: null };
            default:
                return state;
        }
    }

    /************************************************************************************
     * Utility method to check for existence of User Cookie and
     * hence check for authenitcated user.
     ***********************************************************************************/
    static isAuthenticatedUser() {
        let signInStatus = magnaCookies.getCookie('ECS_AC');
        if (typeof signInStatus === 'undefined' || signInStatus === '') {
            //User not authorized
            return false;
        }
        else {
            //User is authorized
            return true;
        }
    }

    /*************************************************************************************
     * Utitlity method to check if user is signed-in
     ************************************************************************************/
    static isSignedInUser() {
        let userInfo = getItemFromLocalStorage('shopce-user');
        //check if localStorage exists
        if (typeof userInfo !== 'undefined' && userInfo !== '' && userInfo !== null) {
            return true;
        }
        else {
            return false;
        }
    }

    /************************************************************************************
    * Action creator to change password.
    *************************************************************************************/
    changePasswordActionCreator(passwordObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_PUT_CHANGE_PASSWORD });
            dispatch({ type: SET_LOADER_STATUS });
            let userInfo = getItemFromLocalStorage('shopce-user');
            let userToken = userInfo ? userInfo.token : '';
            let requestBody = {};
            requestBody.data = passwordObject;
            requestBody.header = ("Bearer " + userToken);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'PUT';
            let requestURL = '/customers/me/password';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch after validating the token
                    dispatch({ type: RECEIVE_PUT_CHANGE_PASSWORD, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    dispatch({ type: RECEIVE_PUT_CHANGE_PASSWORD, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                }
            );
        };
    }

    /**********************************************************************************
    * Resetting the Redux state for Customer password change.
    ***********************************************************************************/
    resetPutPasswordChangeActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_CHANGE_PASSWORD });
        };
    }

    /**********************************************************************************
    * Helper function for changePasswordReducer.
    **********************************************************************************/
    static applyPasswordChange = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                changePasswordSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { changePasswordSuccess: true, isChangePwdButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                changePasswordSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { changePasswordSuccess: false, isChangePwdButtonDisabled: false });
        }
    }

    /**********************************************************************************
    * Reducer to change password.
    **********************************************************************************/
    static changePasswordReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_PUT_CHANGE_PASSWORD:
                return { ...state, changePasswordSuccess: null, isChangePwdButtonDisabled: true };
            case RECEIVE_PUT_CHANGE_PASSWORD:
                let retValue = AccountService.applyPasswordChange(state, action);
                return retValue;
            case REQUEST_RESET_CHANGE_PASSWORD:
                return { ...state, changePasswordSuccess: null };
            default:
                return state;
        }
    }

    /************************************************************************************
    * Action creator to set subscription status.
    *************************************************************************************/
    isUserSubscribedActionCreator(isSubscribed) {
        return (dispatch) => {
            /***************************************************************
             * Reset the deleteMyAddressItemSuccess to null, before deleting
             * the shipping address.
             ***************************************************************/
            dispatch({ type: REQUEST_PUT_SUBSCRIBE });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only subscribe for newsletter
                let requestURL = '/customers/me';
                let requestType = 'PUT';
                let requestBody = {};
                const systemParameters = getConfigValue("system");

                requestBody.data = {
                    "customer": {
                        "id": userFromLocalStorage.customerId,
                        "email": userFromLocalStorage.email,
                        "firstname": userFromLocalStorage.firstname,
                        "lastname": userFromLocalStorage.lastname,
                        "store_id": window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].id:systemParameters.store[1].id,
                        "website_id": window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].websiteId:systemParameters.store[1].websiteId,
                        "extension_attributes": {
                            "is_subscribed": isSubscribed
                        }
                    }
                };

                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';

                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECEIVE_PUT_SUBSCRIBE, success: true, response });
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_PUT_SUBSCRIBE, success: false, error });
                    }
                );
            }
        };
    }

    /**********************************************************************************
    * Resetting the Redux state on Subscribe.
    ***********************************************************************************/
    resetSubscriptionActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_SUBSCRIBE });
        };
    }

    /***************************************************************
     * Helper function for reducer isSubscribedReducer
     ***************************************************************/
    static applySubscription = (state, action) => {

        if (action.success) {
            if (action && action.response && ("data" in action.response) &&
                typeof action.response.data.extension_attributes !== 'undefined') {
                let returnObjectSuccess = {
                    isSubscribed: action.response.data.extension_attributes.is_subscribed,
                };
                return Object.assign({}, returnObjectSuccess, { isSubscribeButtonDisabled: false });
            }
        }
        else {
            let genericError = 'Error, Please try again after some time'
            let returnObjectError = {
                isSubscribed: false,
                errorMessage: action.error.data ? action.error.data.message : genericError,
            };

            return Object.assign({}, returnObjectError, { isSubscribed: false, isSubscribeButtonDisabled: false });
        }
    }

    /***************************************************************************** 
    * Redux Reducer to subscribe/unsubscribe user
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Ruchi Verma <ruchi.verma@embitel.com>
    *****************************************************************************/
    static isSubscribedReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_PUT_SUBSCRIBE:
                return { ...state, isSubscribed: null, isSubscribeButtonDisabled: true };
            case RECEIVE_PUT_SUBSCRIBE:
                let retValue = AccountService.applySubscription(state, action);
                return retValue;
            case REQUEST_RESET_SUBSCRIBE:
                return { ...state, isSubscribed: null };
            default:
                return state;
        }
    };

    setSubMenuActionCreator(index) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_SUBMENU });
            dispatch({ type: RECIEVE_SET_SUBMENU, success: true, index });
        }
    };
    /***************************************************************************** 
* Redux Reducer to set submenu in my accounts section
* @param {*} state     - No modifications
* @param {*} action    - No Modifications
* @author    Ruchi Verma <ruchi.verma@embitel.com>
*****************************************************************************/
    static setSubMenuReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_SET_SUBMENU:
                return 0;
            case RECIEVE_SET_SUBMENU:
                if (action.success)
                    return action.index;
                else
                    return 0;
            default:
                return state;
        }
    };
}

export default AccountService;