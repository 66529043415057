/**
* ForgotPasswordForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import EmailRounded from '@material-ui/icons/EmailRounded';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import PageTitle from '../../../../app/components/common/PageTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AccountService from '../../../../app/modules/account/AccountService';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import GetLanguage from '../../../../library/GetLanguage';
const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    }
});

/***************************************************************
 * Component class for displaying Forgot Password Form.
 * On submit user will get reset link to respective account
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        // Before displaying pre-populated email, check if localStorage has 
        // data for email, name and token.
        this.state = {
            resetEmail: '',
            emailValidationText: ''
        };
    }
    //updates email and password fields with provided values
    handleChange = prop => event => {
        //  this.setState({emailValidationText:''});
        this.setState({
            [prop]: event.target.value,
            emailValidationText: ''
        });
    };
    //call dispatch function if email and password fields are not empty
    handleResetPassword = (event) => {
        event.preventDefault();
        //Dispatch action to Sign-In the user.
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {
            resetEmail,
        } = this.state;

        if ((resetEmail !== "")) {

            if (resetEmail.match(emailRegex)) {
                //Dispatch action
                this.props.onSubmitForgotPasswordDispatch(
                    resetEmail,
                );
            }
            else {
                this.setState({ emailValidationText: 'Enter valid email' });
            }

        }
    };

    render() {
        const { classes } = this.props;
        const { isForgotPasswordButtonDisabled } = this.props;

        return (
            <React.Fragment>
                <PageTitle title={<GetLanguage value="Forgot Password" />} />
                <Typography variant="body2" align="center" gutterBottom>
                    {<GetLanguage value="Please enter your email address below to receive a password reset link" />}
                </Typography>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs sm={8} md={5} lg={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleResetPassword}>
                                    <Grid container spacing={16} direction="column">
                                        <Grid item>
                                            {/* <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-resetEmail">Email</InputLabel>
                                                <Input id="adornment-resetEmail" type='text'
                                                    value={this.state.resetEmail} onChange={this.handleChange('resetEmail')}
                                                    required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple={true} style={{ cursor: 'default' }}><EmailRounded /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />                                           
                                                   
                                            </FormControl> */}

                                            <TextField
                                                InputLabelProps={{
                                                    style: { color: '#000000' },
                                                }}
                                                label={<GetLanguage value="Email/Mobile" />}
                                                fullWidth
                                                value={this.state.email}
                                                onChange={this.handleChange('resetEmail')}
                                                margin="normal"
                                                required
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton disableRipple={true} style={{ cursor: 'default' }}><EmailRounded /></IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <Typography variant="subtitle1" color="primary">
                                                {this.state.emailValidationText}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <Fab style={{ backgroundColor: '#F73455' }} size="large" aria-label="Reset" variant="extended" color="primary"
                                                    disabled={isForgotPasswordButtonDisabled} type="submit">{<GetLanguage value="Submit" />}</Fab>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/** Signup not required from front end*/}
                {/* <Card>
                    <CardContent>
                        <Typography align="center" gutterBottom>
                            <span>{<GetLanguage value="Not registered yet?" />}
                                <Link to='/signup' style={{ textDecoration: 'none', color: '#2196f3' }}>{<GetLanguage value="Sign Up" />}</Link>
                            </span>
                        </Typography>
                    </CardContent>
                </Card> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    forgotPasswordStatus: state.post_forgot_password_state.forgotPasswordStatus,
    forgotPasswordError: state.post_forgot_password_state.errorMessage ? state.post_forgot_password_state.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    onSubmitForgotPasswordDispatch: (
        resetEmail,
    ) => {
        var ForgotPasswordObject = {
            "email": resetEmail
        };
        let as = new AccountService();
        dispatch(as.forgotPasswordActionCreator(ForgotPasswordObject));
    }
}
);

const ForgotPasswordFormWithStyles = withStyles(styles)(ForgotPasswordForm);
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormWithStyles);