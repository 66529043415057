/**
* OTPForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Swathy Sagar <swathy.sagar@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import RegistrationService from '../../../../app/modules/registration/RegistrationService';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
    button: {
        borderRadius: '20px',
        width: '150px',
        alignItems: 'center',
        justify: 'center'
    },
    number: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",// to remove the up and down arrows in input field
        }
    },
    otp: {
        textAlign: "center"
    }

});

/***************************************************************
 * Component class for enetring OTP.
 * On submit user can able to create a new account
 * @author   Swathy Sagar <swathy.sagar@embitel.com>
 ***************************************************************/

class OTPForm extends React.Component {
    constructor(props) {
        super(props);
        this.textInput2 = React.createRef();
        this.textInput3 = React.createRef();
        this.textInput4 = React.createRef();
        this.state = {
            mobileNumber: this.props.location?this.props.location.state.mobileNumber:'',
            otp: "",
            customerId: this.props.location? this.props.location.state.id : '',
            resendOTPClicked: 0,
            open: false,
            confirmClicked:false,
            resetPasswordToken: this.props.location? this.props.location.state.token : '',
        };
    }
    componentDidMount() {
        if(this.props.location && this.props.location.state) {
            this.setState({customerId:this.props.location.state.id, 
                resetPasswordToken: this.props.location.state.token,
                mobileNumber:this.props.location.state.mobileNumber});
        }
    }
    handleClickOpen() {
        this.setState({ open: true });
    }

    handleClose() {
        this.setState({ open: false });
    }
    //updates email and password fields with provided values
    handleChange = prop => event => {
        event.preventDefault();
        let otp = this.state.otp;
        this.setState({ otp: otp + event.target.value });
    };

    //call dispatch function if otp is not empty
    handleOTP = (event) => {
        event.preventDefault();
        const {
            otp,
            mobileNumber,
            customerId
        } = this.state;
        if ((otp !== "") && (otp.length === 4)) {
            //Dispatch action
            this.props.onSubmitOTPDispatch(
                otp,
                mobileNumber,
                customerId
            );
        }
        this.setState({confirmClicked:true})
        this.textInput1.value = "";
        this.textInput2.value = "";
        this.textInput3.value = "";
        this.textInput4.value = "";
    };

    //call dispatch function if OTP is to be resend
    resendOTP() {
        let count = this.state.resendOTPClicked + 1;
        this.textInput1.value = "";
        this.textInput2.value = "";
        this.textInput3.value = "";
        this.textInput4.value = "";
        this.setState({confirmClicked:false})
        this.setState({ otp: "" });
        if (count <= 3) {
            this.props.onSubmitMobileNumDispatch(
                this.state.mobileNumber,
                this.state.customerId
            );
        }
        else {
            this.handleClickOpen();
        }
        this.setState({ resendOTPClicked: count });
    }
    render() {
        const { classes, OTPSuccess, isSendOTPButtonDisabled } = this.props;

        //If OTP is success, Redirect to registration page
        if (OTPSuccess) {
            return <Redirect replace to={{ pathname: '/registration', state: { id: this.state.customerId, token: this.state.resetPasswordToken } }} />;
        }

        return (
            <React.Fragment>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Contact customer care</DialogTitle>
                    <DialogContent>
                        Please contact customer care, if you still face issue related to OTP generation
        </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose.bind(this)} color="primary">
                            OK
          </Button>

                    </DialogActions>
                </Dialog>
                <PageTitle title="Enter OTP" />
                <Typography variant="body2" align="center" gutterBottom>
                    Please enter the OTP recieved on your registered mobile number here.
                </Typography>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs lg={3} md={3} sm={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleOTP}>
                                    <Grid container direction="column" alignItems='center' justify='center'>
                                        <Grid item className={classes.otp}>
                                            <Grid container direction="row">
                                                <Grid item xs={3} lg={3}>
                                                    <TextField
                                                        id="otp"
                                                        type="number"
                                                        inputRef={el => this.textInput1 = el}
                                                        className={classes.number}
                                                        onInput={(e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1);
                                                            if (e.target.value.length === 1) {
                                                                this.textInput2.focus();
                                                            }
                                                        }}
                                                        style={{ width: '25%' }}
                                                        onChange={this.handleChange('otp')}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={3} lg={3}>
                                                    <TextField
                                                        id="otp"
                                                        type="number"
                                                        min={0}
                                                        inputRef={el => this.textInput2 = el}
                                                        className={classes.number}
                                                        onInput={(e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                                                            if (e.target.value.length === 1) {
                                                                this.textInput3.focus();
                                                            }
                                                        }}
                                                        style={{ width: '25%' }}
                                                        onChange={this.handleChange('otp')}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={3} lg={3}>
                                                    <TextField
                                                        id="otp"
                                                        type="number"
                                                        min={0}
                                                        inputRef={el => this.textInput3 = el}
                                                        min={0}
                                                        className={classes.number}
                                                        onInput={(e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                                                            if (e.target.value.length === 1) {
                                                                this.textInput4.focus();
                                                            }
                                                        }}
                                                        style={{ width: '25%' }}
                                                        onChange={this.handleChange('otp')}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={3} lg={3}>
                                                    <TextField
                                                        id="otp"
                                                        type="number"
                                                        min={0}
                                                        inputRef={el => this.textInput4 = el} min={0}
                                                        className={classes.number}
                                                        onInput={(e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                                                        }}
                                                        style={{ width: '25%' }}
                                                        onChange={this.handleChange()}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                            {/* <Typography variant="subtitle1" color="primary">
                                                {this.state.mobileValidationText}
                                            </Typography> */}
                                            {this.state.otp === "" && this.state.confirmClicked &&
                                                <Grid item>
                                                    <Typography variant="subtitle1" color="primary">
                                                        Please enter OTP
                                                    </Typography>
                                                </Grid>
                                            }
                                            {this.state.otp.length<4 && this.state.confirmClicked &&
                                                <Grid item>
                                                    <Typography variant="subtitle1" color="primary">
                                                        Please enter valid OTP
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item style={{ marginTop: 30 }}>
                                            <Fab size="medium" disabled={isSendOTPButtonDisabled} variant="extended" type='submit'>ENTER OTP</Fab>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <Typography onClick={this.resendOTP.bind(this)} align="center" gutterBottom style={{ cursor: 'pointer', textDecoration: 'none', color: '#2196f3' }}>
                            Resend OTP
                        </Typography>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => ({
    OTPSuccess: state.post_registered_mobile_otp_state.mobileNumberOTPSuccess,
    isSendOTPButtonDisabled: state.post_registered_mobile_otp_state.isSendOTPButtonDisabled,
    OTPError: state.post_registered_mobile_otp_state.errorMessage ? state.post_registered_mobile_otp_state.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    onSubmitOTPDispatch: (
        otp,
        mobileNumber,
        customerId
    ) => {
        var OTPObject = {
            "otp": otp,
            "mobileNumber": mobileNumber,
            "customerId": customerId
        };
        let rs = new RegistrationService();
        dispatch(rs.OTPActionCreator(OTPObject));
    },
    
});

const OTPFormWithStyles = withStyles(styles)(OTPForm);
export default connect(mapStateToProps, mapDispatchToProps)(OTPFormWithStyles);