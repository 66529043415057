/**
* Store for Redux.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

/**************************************************************************
 * Redux constants used in Reducers, Action Creators
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *************************************************************************/
export const REQUEST_GET_TARGET_PATH = 'REQUEST_GET_TARGET_PATH';
export const RECEIVE_GET_TARGET_PATH = 'RECEIVE_GET_TARGET_PATH';
export const SET_TARGET_PATH_DEFAULT = 'SET_TARGET_PATH_DEFAULT';
//POST Sign-In User
export const REQUEST_POST_SIGNIN_USER = 'REQUEST_POST_SIGNIN_USER';
export const RECEIVE_POST_SIGNIN_USER = 'RECEIVE_POST_SIGNIN_USER';

//GET Logged In User Details
export const REQUEST_GET_USER_DETAILS = 'REQUEST_GET_USER_DETAILS';
export const RECEIVE_GET_USER_DETAILS = 'RECEIVE_GET_USER_DETAILS';

export const REQUEST_GET_USER_UPDATED_DETAILS = 'REQUEST_GET_USER_UPDATED_DETAILS';
export const RECEIVE_GET_USER_UPDATED_DETAILS = 'RECEIVE_GET_USER_UPDATED_DETAILS';

//RESET the Signed In Status
export const REQUEST_RESET_SIGNED_IN_STATE = 'REQUEST_RESET_SIGNED_IN_STATE';
export const REQUEST_GET_CATEGORIES = 'REQUEST_GET_CATEGORIES';
export const RECEIVE_GET_CATEGORIES = 'RECEIVE_GET_CATEGORIES';

//POST Sign-Up User
export const REQUEST_POST_SIGNUP_USER = 'REQUEST_POST_SIGNUP_USER';
export const RECEIVE_POST_SIGNUP_USER = 'RECEIVE_POST_SIGNUP_USER';

//RESET the Signed Up Status
export const REQUEST_RESET_SIGNED_UP_STATE = 'REQUEST_RESET_SIGNED_UP_STATE';
//RESET the Sign In Status for Retailer
export const REQUEST_RESET_SALESMAN_SIGNED_IN_STATE = 'REQUEST_RESET_SALESMAN_SIGNED_IN_STATE';

//GET Category Products
export const REQUEST_GET_CATEGORY_PRODUCTS = 'REQUEST_GET_CATEGORY_PRODUCTS';
export const RECEIVE_GET_CATEGORY_PRODUCTS = 'RECEIVE_GET_CATEGORY_PRODUCTS';

//GET Category Data
export const REQUEST_GET_CATEGORY_DATA = 'REQUEST_GET_CATEGORY_DATA';
export const RECEIVE_GET_CATEGORY_DATA = 'RECEIVE_GET_CATEGORY_DATA';

//GET Category CMS
export const REQUEST_GET_CATEGORY_CMS = 'REQUEST_GET_CATEGORY_CMS';
export const RECEIVE_GET_CATEGORY_CMS = 'RECEIVE_GET_CATEGORY_CMS';

//GET Product Detail Data
export const REQUEST_GET_PRODUCT_DETAIL = 'REQUEST_GET_PRODUCT_DETAIL';
export const RECEIVE_GET_PRODUCT_DETAIL = 'RECEIVE_GET_PRODUCT_DETAIL';

//POST Add to Shopping Cart
export const REQUEST_POST_ADD_TO_CART = 'REQUEST_POST_ADD_TO_CART';
export const RECEIVE_POST_ADD_TO_CART = 'RECEIVE_POST_ADD_TO_CART';

//Display Mini-Cart
export const REQUEST_DISPLAY_MINI_CART = 'REQUEST_DISPLAY_MINI_CART';
export const RECEIVE_DISPLAY_MINI_CART = 'RECEIVE_DISPLAY_MINI_CART';

//GET Validate Token
export const REQUEST_VALIDATE_TOKEN = 'REQUEST_VALIDATE_TOKEN';
export const RECEIVE_VALIDATE_TOKEN = 'RECEIVE_VALIDATE_TOKEN';

//POST Forgot Password
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const RECEIVE_FORGOT_PASSWORD = 'RECEIVE_FORGOT_PASSWORD';

//POST Reset the Password
export const REQUEST_RESET_FORGOT_PASSWORD = 'REQUEST_RESET_FORGOT_PASSWORD';
export const RECEIVE_RESET_FORGOT_PASSWORD = 'RECEIVE_RESET_FORGOT_PASSWORD';

//DELETE from Cart
export const REQUEST_DELETE_ITEM_FROM_CART = 'REQUEST_DELETE_ITEM_FROM_CART';
export const RECEIVE_DELETE_ITEM_FROM_CART = 'RECEIVE_DELETE_ITEM_FROM_CART';

//Empty Cart
export const REQUEST_EMPTY_CART = 'REQUEST_EMPTY_CART';
export const RECEIVE_EMPTY_CART = 'RECEIVE_EMPTY_CART';

//GET Shipping Options
export const REQUEST_GET_SHIPPING_OPTIONS_FOR_CART = 'REQUEST_GET_SHIPPING_OPTIONS_FOR_CART';
export const RECEIVE_GET_SHIPPING_OPTIONS_FOR_CART = 'RECEIVE_GET_SHIPPING_OPTIONS_FOR_CART';

//PUT Edit Quantity of product in Cart
export const REQUEST_EDIT_ITEM_QUANTITY_IN_CART = 'REQUEST_EDIT_ITEM_QUANTITY_IN_CART';
export const RECEIVE_EDIT_ITEM_QUANTITY_IN_CART = 'RECEIVE_EDIT_ITEM_QUANTITY_IN_CART';

//GET Shipping Address
export const REQUEST_SHIPPING_ADDRESS = 'REQUEST_SHIPPING_ADDRESS';
export const RECEIVE_SHIPPING_ADDRESS = 'RECEIVE_SHIPPING_ADDRESS';

//SET Shipping Address
export const REQUEST_SET_SHIPPING_ADDRESS = 'REQUEST_SET_SHIPPING_ADDRESS';
export const RECEIVE_SET_SHIPPING_ADDRESS = 'RECEIVE_SET_SHIPPING_ADDRESS';

//SET Shipping Address
export const REQUEST_DELETE_SHIPPING_ADDRESS = 'REQUEST_DELETE_SHIPPING_ADDRESS';
export const RECEIVE_DELETE_SHIPPING_ADDRESS = 'RECEIVE_DELETE_SHIPPING_ADDRESS';

//POST Billing Address
export const REQUEST_UPDATE_BILLING_DETAILS = 'REQUEST_UPDATE_BILLING_DETAILS';
export const RECEIVE_UPDATE_BILLING_DETAILS = 'RECEIVE_UPDATE_BILLING_DETAILS';

//POST Shipping Address
export const REQUEST_UPDATE_SHIPPING_DETAILS = 'REQUEST_UPDATE_SHIPPING_DETAILS';
export const RECEIVE_UPDATE_SHIPPING_DETAILS = 'RECEIVE_UPDATE_SHIPPING_DETAILS';

//POST Reset Shipping Address
export const RESET_UPDATE_SHIPPING_DETAILS = 'RESET_UPDATE_SHIPPING_DETAILS';
//POST Reset Payment Options
export const RESET_UPDATE_PAYMENT_DETAILS = 'RESET_UPDATE_PAYMENT_DETAILS';

//GET Order Summary
export const REQUEST_GET_ORDER_SUMMARY = 'REQUEST_GET_ORDER_SUMMARY';
export const RECEIVE_GET_ORDER_SUMMARY = 'RECEIVE_GET_ORDER_SUMMARY';

//SET (POST) Payment method
export const REQUEST_POST_PAYMENT_METHOD = 'REQUEST_POST_PAYMENT_METHOD';
export const RECEIVE_POST_PAYMENT_METHOD = 'RECEIVE_POST_PAYMENT_METHOD';

//GET Products from Search
export const REQUEST_GET_SEARCH_PRODUCTS = 'REQUEST_GET_SEARCH_PRODUCTS';
export const RECEIVE_GET_SEARCH_PRODUCTS = 'RECEIVE_GET_SEARCH_PRODUCTS';

//PUT Place Order
export const REQUEST_PUT_PLACE_ORDER = 'REQUEST_PUT_PLACE_ORDER';
export const RECEIVE_PUT_PLACE_ORDER = 'RECEIVE_PUT_PLACE_ORDER';

//GET CMS Page Content
export const REQUEST_GET_CMS_PAGE_CONTENT = 'REQUEST_GET_CMS_PAGE_CONTENT';
export const RECEIVE_GET_CMS_PAGE_CONTENT = 'RECEIVE_GET_CMS_PAGE_CONTENT';

//GET Category Title to display on the Category Listing Page
export const REQUEST_GET_CATEGORY_TITLE = 'REQUEST_GET_CATEGORY_TITLE';
export const RECEIVE_GET_CATEGORY_TITLE = 'RECEIVE_GET_CATEGORY_TITLE';

//GET CMS page widget data1
export const REQUEST_GET_CMS_PAGE_WIDGET_DATA1 = 'REQUEST_GET_CMS_PAGE_WIDGET_DATA1';
export const RECEIVE_GET_CMS_PAGE_WIDGET_DATA1 = 'RECEIVE_GET_CMS_PAGE_WIDGET_DATA1';

// SET FILTER_TAGS 
export const RECEIVE_GET_FILTER_TAGS ='RECEIVE_GET_FILTER_TAGS';

//GET CMS page widget data2
export const REQUEST_GET_CMS_PAGE_WIDGET_DATA2 = 'REQUEST_GET_CMS_PAGE_WIDGET_DATA2';
export const RECEIVE_GET_CMS_PAGE_WIDGET_DATA2 = 'RECEIVE_GET_CMS_PAGE_WIDGET_DATA2';

//GET CMS page widget data2
export const REQUEST_GET_CMS_PAGE_WIDGET_DATA3 = 'REQUEST_GET_CMS_PAGE_WIDGET_DATA3';
export const RECEIVE_GET_CMS_PAGE_WIDGET_DATA3 = 'RECEIVE_GET_CMS_PAGE_WIDGET_DATA3';

//GET Category Filter Options Data
export const REQUEST_GET_CATEGORY_FILTER_DATA = 'REQUEST_GET_CATEGORY_FILTER_DATA';
export const RECEIVE_GET_CATEGORY_FILTER_DATA = 'RECEIVE_GET_CATEGORY_FILTER_DATA';

//GET Countries
export const REQUEST_GET_COUNTRIES = 'REQUEST_GET_COUNTRIES';
export const RECEIVE_GET_COUNTRIES = 'RECEIVE_GET_COUNTRIES';

//Initialize Add to Cart Success to false, to hide the message.
export const REQUEST_INIT_ADD_TO_CART_SUCCESS = 'REQUEST_INIT_ADD_TO_CART_SUCCESS';

//Initialize Product Detail Data to null
export const REQUEST_INIT_PRODUCT_DETAIL = 'REQUEST_INIT_PRODUCT_DETAIL';

//Verify token is valid or not
export const REQUEST_VERIFY_TOKEN = 'REQUEST_VERIFY_TOKEN';
export const RECEIVE_VERIFY_TOKEN = 'RECEIVE_VERIFY_TOKEN';

//Initialize SignUpSuccess to null in Sign Up Form
export const REQUEST_INIT_SIGNED_UP_STATE = 'REQUEST_INIT_SIGNED_UP_STATE';

//get wishlisted products
export const REQUEST_GET_WISHLISTED_PRODUCTS = 'REQUEST_GET_WISHLISTED_PRODUCTS';
export const RECEIVE_GET_WISHLISTED_PRODUCTS = 'RECEIVE_GET_WISHLISTED_PRODUCTS';

//wishlist a product
export const REQUEST_PUT_ITEM_IN_WISHLIST = 'REQUEST_PUT_ITEM_IN_WISHLIST';
export const RECEIVE_PUT_ITEM_IN_WISHLIST = 'RECEIVE_PUT_ITEM_IN_WISHLIST';

//delete product in wishlist
export const REQUEST_DELETE_ITEM_IN_WISHLIST = 'REQUEST_DELETE_ITEM_IN_WISHLIST';
export const RECEIVE_DELETE_ITEM_IN_WISHLIST = 'RECEIVE_DELETE_ITEM_IN_WISHLIST';

//reset wishlist info
export const REQUEST_RESET_PUT_WISHLIST = 'REQUEST_RESET_PUT_WISHLIST';
export const REQUEST_RESET_DELETE_WISHLIST = 'REQUEST_RESET_DELETE_WISHLIST';

//Move to Wishlist from Cart
export const REQUEST_MOVE_TO_WISHLIST_FROM_CART = 'REQUEST_MOVE_TO_WISHLIST_FROM_CART';
export const RECEIVE_MOVE_TO_WISHLIST_FROM_CART = 'RECEIVE_MOVE_TO_WISHLIST_FROM_CART';

//Reset the status of move to Wishlist from cart
export const REQUEST_RESET_MOVE_TO_WISHLIST_FROM_CART = 'REQUEST_RESET_MOVE_TO_WISHLIST_FROM_CART';

//Get Signed In User data
export const REQUEST_GET_SIGNIN_USER_DATA = 'REQUEST_GET_SIGNIN_USER_DATA';
export const RECEIVE_GET_SIGNIN_USER_DATA = 'RECEIVE_GET_SIGNIN_USER_DATA';

//Save customer profile data
export const REQUEST_SAVE_PROFILE_NAME = 'REQUEST_SAVE_PROFILE_NAME';
export const RECEIVE_SAVE_PROFILE_NAME = 'RECEIVE_SAVE_PROFILE_NAME';

//RESET customer name change state
export const REQUEST_RESET_SAVE_PROFILE_NAME = 'REQUEST_RESET_SAVE_PROFILE_NAME';

//Save address as default
export const REQUEST_SET_ADDRESS_AS_DEFAULT = 'REQUEST_SET_ADDRESS_AS_DEFAULT';
export const RECEIVE_SET_ADDRESS_AS_DEFAULT = 'RECEIVE_SET_ADDRESS_AS_DEFAULT';

//RESET set as default address
export const REQUEST_RESET_SET_ADDRESS_AS_DEFAULT = 'REQUEST_RESET_SET_ADDRESS_AS_DEFAULT';

//RESET shipping address 
export const REQUEST_RESET_DELETE_SHIPPING_ADDRESS = 'REQUEST_RESET_DELETE_SHIPPING_ADDRESS'

//PUT Change Password
export const REQUEST_PUT_CHANGE_PASSWORD = 'REQUEST_PUT_CHANGE_PASSWORD';
export const RECEIVE_PUT_CHANGE_PASSWORD = 'RECEIVE_PUT_CHANGE_PASSWORD';
export const REQUEST_RESET_CHANGE_PASSWORD = 'REQUEST_RESET_CHANGE_PASSWORD';

//Newsletter Management
export const REQUEST_PUT_SUBSCRIBE = 'REQUEST_PUT_SUBSCRIBE';
export const RECEIVE_PUT_SUBSCRIBE = 'RECEIVE_PUT_SUBSCRIBE';
export const REQUEST_RESET_SUBSCRIBE = 'REQUEST_RESET_SUBSCRIBE';

//Update price based on selected varaint
export const REQUEST_PRODUCT_PRICE = 'REQUEST_PRODUCT_PRICE';
export const RECEIVE_PRODUCT_PRICE = 'RECEIVE_PRODUCT_PRICE';


export const RECEIVE_PRODUCT_IMAGE = 'RECEIVE_PRODUCT_IMAGE';

//Update price based on selected varaint
export const REQUEST_VARIANT_PRICE = 'REQUEST_VARIANT_PRICE';
export const RECEIVE_VARIANT_PRICE = 'RECEIVE_VARIANT_PRICE';
export const RECEIVE_SELECTED_VARIANT_DATA = 'RECEIVE_SELECTED_VARIANT_DATA';


//Order 

export const RECIEVE_ORDER_DETAILS = 'RECIEVE_ORDER_DETAILS';

//Common
export const SET_LOADER_STATUS = 'SET_LOADER_STATUS';

export const GET_CARROSEL_PRODUCTS = 'GET_CARROSEL_PRODUCTS';

export const REQUEST_BANNER_DATA = 'REQUEST_BANNER_DATA';
export const RECIEVE_BANNER_DATA = 'RECIEVE_BANNER_DATA';
//Cross-sell products for Cart page
export const REQUEST_CROSSSELL_PRODUCTS = 'REQUEST_CROSSSELL_PRODUCTS';
export const RECIEVE_CROSSSELL_PRODUCTS = 'RECIEVE_CROSSSELL_PRODUCTS';

export const SET_DEFAULT_PLACE_ORDER = 'SET_DEFAULT_PLACE_ORDER';

//GET Customer List for Salesman
export const REQUEST_CUSTOMERLIST_FORSALESMAN = 'REQUEST_CUSTOMERLIST_FORSALESMAN';
export const RECEIVE_CUSTOMERLIST_FORSALESMAN = 'RECEIVE_CUSTOMERLIST_FORSALESMAN';

//POST Sign-In User
export const REQUEST_IS_SALESMAN_USER = 'REQUEST_IS_SALESMAN_USER';
export const RECEIVE_IS_SALESMAN__USER = 'RECEIVE_IS_SALESMAN__USER';

//GET Customer List for Salesman
export const REQUEST_CUSTOMERSIGNIN_FORSALESMAN = 'REQUEST_CUSTOMERSIGNIN_FORSALESMAN';
export const RECIEVE_CUSTOMERSIGNIN_FORSALESMAN = 'RECIEVE_CUSTOMERSIGNIN_FORSALESMAN';

//GET BrandsList for Home Page
export const REQUEST_BRANDS_DATA = 'REQUEST_BRANDS_DATA';
export const RECIEVE_BRANDS_DATA = 'RECIEVE_BRANDS_DATA';

export const REQUEST_RESET_SMLOGGEDINASCUSTOMER_SIGNED_IN_STATE = 'REQUEST_RESET_SMLOGGEDINASCUSTOMER_SIGNED_IN_STATE';
//Common
export const SET_IS_SALESMAN_USER = 'SET_IS_SALESMAN_USER';


export const SET_IS_SALESMAN_ALWAYS_FALSE = 'SET_IS_SALESMAN_ALWAYS_FALSE';

//Parse csv file
export const REQUEST_GET_LANGUAGE_OBJECT = 'REQUEST_GET_LANGUAGE_OBJECT';
export const RECEIVE_GET_LANGUAGE_OBJECT = 'RECEIVE_GET_LANGUAGE_OBJECT';

//set submenu state in my accounts section
export const REQUEST_SET_SUBMENU = 'REQUEST_SET_SUBMENU';
export const RECIEVE_SET_SUBMENU = 'RECIEVE_SET_SUBMENU';

//set view type either List view or Grid view
export const REQUEST_SET_PLP_VIEW = 'REQUEST_SET_PLP_VIEW';
export const RECEIVE_SET_PLP_VIEW = 'RECEIVE_SET_PLP_VIEW';

export const REQUEST_CARROSEL_PRODUCTCATEGORIES = 'REQUEST_CARROSEL_PRODUCTCATEGORIES';
export const RECIEVE_CARROSEL_PRODUCTCATEGORIES = 'RECIEVE_CARROSEL_PRODUCTCATEGORIES';

export const GET_CARROSEL_PRODUCT_CATEGORIESIMAGES = 'GET_CARROSEL_PRODUCT_CATEGORIESIMAGES';

//set view type either List view or Grid view
export const REQUEST_SET_SORT_VIEW = 'REQUEST_SET_SORT_VIEW';
export const RECEIVE_SET_SORT_VIEW = 'RECEIVE_SET_SORT_VIEW';


//get requisition list view
export const REQUEST_GET_REQUISITION_LIST = 'REQUEST_GET_REQUISITION_LIST';
export const RECIEVE_GET_REQUISITION_LIST = 'RECIEVE_GET_REQUISITION_LIST';


//create requisition list 
export const REQUEST_CREATE_REQUISITION_LIST = 'REQUEST_CREATE_REQUISITION_LIST';
export const RECIEVE_CREATE_REQUISITION_LIST = 'RECIEVE_CREATE_REQUISITION_LIST';


//set requisition list 
export const REQUEST_SET_REQUISITION_OBJ = 'REQUEST_SET_REQUISITION_OBJ';
export const RECIEVE_SET_REQUISITION_OBJ = 'RECIEVE_SET_REQUISITION_OBJ';

//set requisition list 
export const REQUEST_FOOTER_DATA = 'REQUEST_FOOTER_DATA';
export const RECIEVE_FOOTER_DATA = 'RECIEVE_FOOTER_DATA';

//delete requisition list 
export const REQUEST_DELETE_REQUISITION_LIST = 'REQUEST_DELETE_REQUISITION_LIST';
export const RECIEVE_DELETE_REQUISITION_LIST = 'RECIEVE_DELETE_REQUISITION_LIST';

//set items in requisition list 
export const REQUEST_SET_ITEM_IN_REQUISITION_LIST = 'REQUEST_SET_ITEM_IN_REQUISITION_LIST';
export const RECIEVE_SET_ITEM_IN_REQUISITION_LIST = 'RECIEVE_SET_ITEM_IN_REQUISITION_LIST';

//delete items in requisition list 
export const REQUEST_DELETE_ITEM_IN_REQUISITION_LIST = 'REQUEST_DELETE_ITEM_IN_REQUISITION_LIST';
export const RECIEVE_DELETE_ITEM_IN_REQUISITION_LIST = 'RECIEVE_DELETE_ITEM_IN_REQUISITION_LIST';


//set create requisition modal status 
export const REQUEST_SET_REQUISITION_MODAL = 'REQUEST_SET_REQUISITION_MODAL';
export const RECIEVE_SET_REQUISITION_MODAL = 'RECIEVE_SET_REQUISITION_MODAL';

//add requisition item to cart status 
export const REQUEST_ITEM_IN_REQUISITION_TO_CART = 'REQUEST_ITEM_IN_REQUISITION_TO_CART';
export const RECIEVE_ITEM_IN_REQUISITION_TO_CART = 'RECIEVE_ITEM_IN_REQUISITION_TO_CART';

//update item in requisition list
export const REQUEST_UPDATE_REQUISITION_LIST = 'REQUEST_UPDATE_REQUISITION_LIST';
export const RECIEVE_UPDATE_REQUISITION_LIST = 'RECIEVE_UPDATE_REQUISITION_LIST';

//Notify Out of Stock
export const RECEIVE_NOTIFY_SUBSCRIPTION = 'RECEIVE_NOTIFY_SUBSCRIPTION';
export const REQUEST_NOTIFY_SUBSCRIPTION = 'REQUEST_NOTIFY_SUBSCRIPTION';

//sales man login 
export const REQUEST_POST_SIGNIN_SALESMAN = 'REQUEST_POST_SIGNIN_SALESMAN';
export const RECEIVE_POST_SIGNIN_SALESMAN = 'RECEIVE_POST_SIGNIN_SALESMAN';

//Request Delivery Date 
export const REQUEST_DELIVERY_DATE = 'REQUEST_DELIVERY_DATE';
export const RECIEVE_DELIVERY_DATE = 'RECIEVE_DELIVERY_DATE';

export const RECIEVE_SET_REQUISITION_LIST_ID = 'RECIEVE_SET_REQUISITION_LIST_ID';

//POST Registered Mobile Number
export const REQUEST_POST_MOBILE_NUMBER = 'REQUEST_POST_MOBILE_NUMBER';
export const RECEIVE_POST_MOBILE_NUMBER = 'RECEIVE_POST_MOBILE_NUMBER';

//Post OTP 
export const RECEIVE_POST_MOBILE_OTP = 'RECEIVE_POST_MOBILE_OTP';
export const REQUEST_POST_MOBILE_OTP = 'REQUEST_POST_MOBILE_OTP';

//Post Registration
export const REQUEST_POST_REGISTRATION = 'REQUEST_POST_REGISTRATION';
export const RECEIVE_POST_REGISTRATION = 'RECEIVE_POST_REGISTRATION';

//Get Footer CMS
export const REQUEST_GET_CMS = 'REQUEST_GET_CMS';
export const RECEIVE_GET_CMS = 'RECEIVE_GET_CMS';

//Get Footer CMS
export const REQUEST_SALESMAN_STORES = 'REQUEST_SALESMAN_STORES';
export const RECEIVE_SALESMAN_STORES = 'RECEIVE_SALESMAN_STORES';

//Get Salesman Profile
export const REQUEST_SALESMAN_PROFILE = 'REQUEST_SALESMAN_PROFILE';
export const RECEIVE_SALESMAN_PROFILE = 'RECEIVE_SALESMAN_PROFILE';

//Set Salesman Profile
export const REQUEST_SET_SALESMAN_PROFILE = 'REQUEST_SET_SALESMAN_PROFILE';
export const RECEIVE_SET_SALESMAN_PROFILE = 'RECEIVE_SET_SALESMAN_PROFILE';


//Set Salesman Profile
export const REQUEST_SET_SALESMAN_PWD = 'REQUEST_SET_SALESMAN_PWD';
export const RECEIVE_SET_SALESMAN_PWD = 'RECEIVE_SET_SALESMAN_PWD';


//Get USP's
export const REQUEST_GET_USP = 'REQUEST_GET_USP';
export const RECEIVE_GET_USP = 'RECEIVE_GET_USP';

//GET PDP Breadcrumbs
export const RECEIVE_GET_BREADCRUMBS_DATA_PDP = 'RECEIVE_GET_BREADCRUMBS_DATA_PDP';
export const REQUEST_GET_BREADCRUMBS_DATA_PDP = 'REQUEST_GET_BREADCRUMBS_DATA_PDP';

//GET PLP Breadcrumbs
export const RECEIVE_GET_BREADCRUMBS_DATA_PLP = 'RECEIVE_GET_BREADCRUMBS_DATA_PLP';
export const REQUEST_GET_BREADCRUMBS_DATA_PLP = 'REQUEST_GET_BREADCRUMBS_DATA_PLP';

//Get Category Name
export const REQUEST_CATEGORY_NAME = 'REQUEST_CATEGORY_NAME';
export const RECIEVE_CATEGORY_NAME = 'RECIEVE_CATEGORY_NAME';