/**
* Sample Use for templates with Header, Footer and 2 Columns
* with Left Side Bar.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TwoColumnsLeftBar from './2ColumnsLeftBar';
import Headerbar from '../../../../app/components/layouts/headerbar/HeaderBar';
import Footerbar from '../../../../app/components/layouts/footerbar/FooterBar';

import NavigationMenuDesktop from '../../../../app/components/navigation/navigationdesktop/NavigationDesktop';

const styles = theme => ({
    leftSideBar: {
        width: '20%',
        height: 200,
        background: '#aaa',
        float: 'left',
    },    
    mainContentRight: {
        width: '80%',
        height: 200,
        background: '#bbb',
        float: 'right',
    },    

});

/***************************************************************************** 
 * This component uses Template with 2 Columns and Left
 * Sidebar. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class TemplateUsage2ColumnsLeftBar extends Component {
    render() {
        const { classes } = this.props;     
           
        const LeftSideBarComponent = props => (
            <div className={classes.leftSideBar}>
            </div>
        );

        const RightMainComponent = props => (
            <div className={classes.mainContentRight}>
            </div>
        );

        return (
            <div>                
                <TwoColumnsLeftBar
                    HeaderComponent={Headerbar}
                    NavigationComponent={NavigationMenuDesktop}
                    leftSideBarComponent={[LeftSideBarComponent]}
                    mainContent={[RightMainComponent]}
                    FooterComponent={Footerbar}
                /> 
            </div>
        );
    }
}

export default withStyles(styles)(TemplateUsage2ColumnsLeftBar);

