/**
* Component for displaying salesman profile.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import PageTitle from '../../../../app/components/common/PageTitle';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import UserMessage from '../../../../app/components/messages/UserMessages';
import Person from '@material-ui/icons/Person';
import Fab from '@material-ui/core/Fab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//Redux related imports
import { connect } from 'react-redux';
import AccountService from '../../../../app/modules/account/AccountService';
import getConfigValue from '../../../../app/framework/config/configHandler';
import GetLanguage from '../../../../library/GetLanguage'
import SalesManService from '../../../../app/modules/salesman/SalesManService';
import { Redirect } from 'react-router';
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
    headerFont: {
        fontSize: '1rem',
        fontWeight: '600'
    },    
    inputWidth: {
        width: '100%'
    },
});

/***************************************************************
 * Component class for displaying My profile page.
 * @author   Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class SalesManProfile extends React.Component {
    constructor(props) {
        super(props);
        const accountParameters = getConfigValue("account");
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            currentPassword: "",
            newPassword: "",
            retypePassword: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            logoutOnPasswordChange: accountParameters.customer.logoutOnPasswordChange,
            isprofileChangeinProg:true,
        }
    }

    componentDidMount() {
        /***************************************************************************** 
         * Check if the user is authenticated. 
         * If yes, display my profile page.
         * If not, redirect to Sign-In page and once
         * signed-in, redirect back to this my profile
         * page.
         *****************************************************************************/
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        }
        //Call API to get customer data
        if (typeof (this.props.getSalesManProfileDetails) === 'undefined') {
            this.props.getUserDetails();
        } else {
            this.setState({
                firstName: this.props.getSalesManProfileDetails.firstname,
                lastName: this.props.getSalesManProfileDetails.lastname,
            });
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleNameChange = (event) => {
        event.preventDefault();
        //Dispatch action to change name.
        this.setState({isprofileChangeinProg:false});
        const {
            firstName,
            lastName,
        } = this.state;
        if (firstName !== "" &&
            lastName !== "") {
            //dispatch to save name
            this.props.onSubmitSaveNameDispatch(firstName, lastName);
        }
    };

    //call dispatch function if all fields are not empty
    handleSavePassword = (event) => {
        event.preventDefault();
        const {
            currentPassword,
            newPassword,
            retypePassword,
        } = this.state;

        if (currentPassword !== "" && newPassword !== "" && retypePassword !== "") {
            var passwordObject = {
                "currentPassword": currentPassword,
                "newPassword": retypePassword
            };
            //dispatch to change password
            this.props.onSubmitChangePassword(passwordObject);
        }
    }

    resetFormFields = () => {
        this.setState({ currentPassword: '', newPassword: '', retypePassword: '' });
    }

    componentDidUpdate(prevProps) {
        if (this.props.get_user_details !== prevProps.get_user_details) {
            this.setState({
                firstName: this.props.get_user_details.firstname,
                lastName: this.props.get_user_details.lastname,
                email: this.props.get_user_details.email,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        //if password change is sucessfull then signout and clear user data
        if (nextProps.changePasswordSuccess === true && this.state.logoutOnPasswordChange) {
            //reset signed in state
            this.props.resetSignedInUserSuccess();
            //Clear browser storage on User SignOut.
            AccountService.clearOnSignOut();
            //redirect to signin form
            this.props.history.push({
                pathname: '/account',
                state: { changePasswordSuccess: true }
            });
        }
        //reset password form only when there is a edit
        //done or api call processed
        if (nextProps.changePasswordSuccess !== null) {
            this.resetFormFields();
        }
    }

    //hide/show text current password
    handleClickShowCurrentPassword = () => {
        this.setState(state => ({ showCurrentPassword: !state.showCurrentPassword }));
    };
    //hide/show text new password
    handleClickShowNewPassword = () => {
        this.setState(state => ({ showNewPassword: !state.showNewPassword }));
    };
    //hide/show text confirm password
    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };

    render() {
        const { classes } = this.props;
        const { get_user_details,profileNameUpdateStatus } = this.props;

      
        if(profileNameUpdateStatus)
        {  
            this.props.resetNameChange();
           return <Redirect to={{ pathname: '/salesmanaccount', state: { submenu: 0,isUdated:true } }} />;
        }
  
        if (get_user_details === []) return null;
        if (get_user_details === {}) return null;
        if (typeof (get_user_details) === "undefined") return null;
        return (
            <React.Fragment>
                <PageTitle title={<GetLanguage value='My Profile'/>} />
                <Grid container alignItems="flex-start" justify="space-around">
                    <Grid item sm={6} md={4}>
                        <Typography gutterBottom align='center' className={classes.headerFont}>{<GetLanguage value='Personal Detail'/>}</Typography>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleNameChange}>
                                    <Grid container spacing={8} direction="column">
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-firstName">{<GetLanguage value='First Name'/>}</InputLabel>
                                                <Input id="adornment-firstName" type='text' value={this.state.firstName}
                                                    onChange={this.handleChange('firstName')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><Person /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-lastName">{<GetLanguage value='Last Name'/>}</InputLabel>
                                                <Input id="adornment-lastName" type='text' value={this.state.lastName}
                                                    onChange={this.handleChange('lastName')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><Person /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-email">Email</InputLabel>
                                                <Input id="adornment-email" type='text' value={this.state.email} readOnly={true}
                                                    onChange={this.handleChange('email')} disableUnderline={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><Email /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid> */}
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl required fullWidth>
                                                <Fab size="large" aria-label="Save name" variant="extended" type="submit">{<GetLanguage value="Save name"/>}</Fab>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <Typography gutterBottom align='center' className={classes.headerFont}>{<GetLanguage value='Change Password'/>}</Typography>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleSavePassword}>
                                    <Grid container spacing={8} direction="column">
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-currentPassword">{<GetLanguage value="Current Password"/>}</InputLabel>
                                                <Input id="adornment-currentPassword" type={this.state.showCurrentPassword ? 'text' : 'password'} value={this.state.currentPassword}
                                                    onChange={this.handleChange('currentPassword')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowCurrentPassword}>
                                                                {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-newPassword">{<GetLanguage value="New Password"/>}</InputLabel>
                                                <Input id="adornment-newPassword" type={this.state.showNewPassword ? 'text' : 'password'} value={this.state.newPassword}
                                                    onChange={this.handleChange('newPassword')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowNewPassword}>
                                                                {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-retypePassword">{<GetLanguage value="Retype Password"/>}</InputLabel>
                                                <Input id="adornment-retypePassword" type={this.state.showConfirmPassword ? 'text' : 'password'} value={this.state.retypePassword}
                                                    onChange={this.handleChange('retypePassword')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowConfirmPassword}>
                                                                {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl required fullWidth>
                                            <Fab size="large" aria-label="Save password" variant="extended" type="submit">{<GetLanguage value="Save Password"/>}</Fab>
                                                </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({
    get_user_details: state.get_user_details_state,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
    getSalesManProfileDetails : state.GetSalesmanProfileDetails,
    changePasswordSuccess: state.put_change_password_state.changePasswordSuccess,
    changePasswordError: state.put_change_password_state.errorMessage ? state.put_change_password_state.errorMessage : '',
    profileNameUpdateStatus:state.set_updated_salesman_details_state.profileNameUpdateStatus,
    salesmanProfileNameUpdateStatus:state.set_salesman_profile_details_state.salesmanProfileNameUpdateStatus,
});


const mapDispatchToProps = dispatch => ({
    getUserDetails: () => {
        const as = new AccountService();
        dispatch(as.GetCustomerDetailsActionCreator());
    },
    onSubmitSaveNameDispatch: (firstName, lastName) => {
        let salesService = new SalesManService();
        dispatch(salesService.SetSalesmanProfileDetailsActionCreator(firstName,lastName));
    },
    onSubmitChangePassword: (passwordObject) => {
        let salesService = new SalesManService();
        dispatch(salesService.ChangeSalesmanPasswordActionCreator(passwordObject));
    },
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    },
    resetNameChange: () => {
        let salesService = new SalesManService();
        dispatch(salesService.resetSalesmanNameChangeActionCreator());
    },
});

const SalesManProfileWithStyles = withStyles(styles)(SalesManProfile);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesManProfileWithStyles));