/**
* CartService class with functions for handling Cart and MiniCart related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import {
    REQUEST_DISPLAY_MINI_CART,
    RECEIVE_DISPLAY_MINI_CART,
    REQUEST_GET_ORDER_SUMMARY,
    RECEIVE_GET_ORDER_SUMMARY,
    REQUEST_DELETE_ITEM_FROM_CART,
    RECEIVE_DELETE_ITEM_FROM_CART,
    REQUEST_EDIT_ITEM_QUANTITY_IN_CART,
    RECEIVE_EDIT_ITEM_QUANTITY_IN_CART,
    REQUEST_MOVE_TO_WISHLIST_FROM_CART,
    RECEIVE_MOVE_TO_WISHLIST_FROM_CART,
    REQUEST_RESET_MOVE_TO_WISHLIST_FROM_CART,
    RECEIVE_SELECTED_VARIANT_DATA,
    SET_LOADER_STATUS,
    RECEIVE_EMPTY_CART,
    REQUEST_EMPTY_CART
} from '../../framework/common/ReduxConstants';
import React from 'react';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import { getGuestCartID, getSignedInCustomerCartID } from '../../framework/common/helpers';
import getConfigValue from '../../framework/config/configHandler';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
import magnaCookies from '../../framework/cookie/magnaCookies';
import { NotificationManager } from 'react-notifications';
import GetLanguage from '../../../library/GetLanguage';

/**************************************************************************************
 * Cart Service class which handles Cart list and 
 * Mini Cart functionalities.
 *************************************************************************************/
class CartService {

    /**********************************************************************************
     * Action Creators
     *********************************************************************************/

    //Action Creator for fetching MiniCart details
    BulkUpload(params, component) {
        return (dispatch) => {
            const newData = [];
            params.map(item => {
                newData.push({ sku: item.sku, qty: item.qty_ordered || item.qty, unit_of_measurement: item.unit_of_measurement ? item.unit_of_measurement :'case' });
            });
            let requestURL = '/carts/mine/customeraddtocart';
            let requestType = 'POST';
            let requestBody = {};
            let userInfo = getItemFromLocalStorage('shopce-user');
            let userToken = userInfo ? userInfo.token : null;
            const cartId = magnaCookies.getCookie('customer-cart-id');
            let salesManFromLocalStorage = getItemFromLocalStorage('sales-man');
            requestBody.data = {
                modifiedby: salesManFromLocalStorage ? salesManFromLocalStorage.salesmanName : '',
                params: newData,
            };
            requestBody.header = ("Bearer " + userToken);
            //dispatch({ type: SET_LOADER_STATUS });
            MagnaAPIHandler(requestURL, requestType, requestBody).then(res => {
                //      this.setState({isUpload:true,params});
                dispatch(this.GetDisplayMiniCartActionCreator(component));
                if(!component){
                   // dispatch({ type: SET_LOADER_STATUS });
                }
                NotificationManager.success('Product has been added to your cart');
            }).catch(error => {
                //dispatch({ type: SET_LOADER_STATUS }); 
                let genericError = 'Error while adding product to cart';
                let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;                    
                NotificationManager.error(errorMessage);
                // this.setState({isUpload:false});
            })
        }
    }
    GetDisplayMiniCartActionCreator(component, showloader,item_id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_DISPLAY_MINI_CART });
           // dispatch({ type: SET_LOADER_STATUS });
            
            let guestCartIDFromCookie;
            let signedInCustomerCartID;
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
                signedInCustomerCartID = getSignedInCustomerCartID();
            }
            else {
                guestCartIDFromCookie = getGuestCartID();
            }
            let requestBody = {};
            let requestType = 'GET';
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.LOW };
            if (signedInCustomerCartID === null && guestCartIDFromCookie !== null) {
                //Guest Customer
                let requestURL = '/ecs/guest-carts/' + guestCartIDFromCookie + "/items";
                MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                    (response) => {
                        dispatch({ type: RECEIVE_DISPLAY_MINI_CART, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        //Get Order Summary for Guest Customer
                        //CartService.getOrderSummaryForGuest(guestCartIDFromCookie, dispatch);
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DISPLAY_MINI_CART, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                    }
                );
            }
            else {
                //Logged In Customer
                requestBody.header = ("Bearer " + userToken);
                let requestURL = '/carts/mine/getcartlist';
                MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                    (response) => {
                        dispatch({ type: RECEIVE_DISPLAY_MINI_CART, success: true, response });
                        //dispatch({ type: SET_LOADER_STATUS });
                        //Get Order Summary for Logged In Customer

                        //CartService.getOrderSummaryForLoggedInCustomer(userToken, dispatch);

                        if (component && component.props) {
                            //dispatch({ type: SET_LOADER_STATUS });
                            component.setState({ open: false });
                            component.props.history.push('/cart');
                        }
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DISPLAY_MINI_CART, success: false, error });
                        //dispatch({ type: SET_LOADER_STATUS });
                    }
                );
            }
        };
    }

    // Helper method for GetDisplayMiniCartActionCreator
    static getOrderSummaryForGuest(cartId, dispatch) {
        dispatch({ type: REQUEST_GET_ORDER_SUMMARY });
        let requestBody = {};
        let requestType = 'GET';
        let requestURL = "/guest-carts/" + cartId + "/totals";
        MagnaAPIHandler(requestURL, requestType, requestBody).then(
            (response) => {
                dispatch({ type: RECEIVE_GET_ORDER_SUMMARY, success: true, response });
            },
            (error) => {
                dispatch({ type: RECEIVE_GET_ORDER_SUMMARY, success: false, error });
            }
        );
    }

    // Helper method for GetDisplayMiniCartActionCreator
    getOrderSummaryForLoggedInCustomer() {
        return (dispatch) => {
            let userToken = '';
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            let tokenType = localStorage.getItem('current_cart');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.multiple[tokenType];
                //signedInCustomerCartID = getSignedInCustomerCartID();
            }
            dispatch({ type: REQUEST_GET_ORDER_SUMMARY });
            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);
            let requestType = 'GET';
            let requestURL = "/carts/mine/totals";
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_ORDER_SUMMARY, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_ORDER_SUMMARY, success: false, error });
                }
            );
        }
    }

    //Action Creator for deleting an item from Cart
    DeleteFromCartActionCreator(item_id,cartId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_DELETE_ITEM_FROM_CART });
            dispatch({ type: SET_LOADER_STATUS });
            let guestCartIDFromCookie = getGuestCartID();
            let signedInCustomerCartID = getSignedInCustomerCartID();
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            let tokenType = localStorage.getItem('current_cart');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.multiple[tokenType];
            }
            if (signedInCustomerCartID === null && guestCartIDFromCookie !== null) {
                //Guest Customer Delete from Cart process
                let requestURL = '/guest-carts/' + guestCartIDFromCookie + '/items/' + item_id;
                let requestType = 'DELETE';
                let requestBody = {};
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {

                        dispatch({ type: RECEIVE_DELETE_ITEM_FROM_CART, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        //Update the Cart after deleting item from the Cart.
                        let cs = new CartService();
                        dispatch(cs.GetDisplayMiniCartActionCreator());
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DELETE_ITEM_FROM_CART, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                    }
                );
            }
            else {
                //Signed-in customer Delete from Cart process
                let requestURL = '/carts/mine/customerdeletecartitem';
                let requestType = 'PUT';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.data = {
                    "cartId":cartId,
                    "itemId":item_id 
                }
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECEIVE_DELETE_ITEM_FROM_CART, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        //Update the Cart after deleting item from the Cart.
                        let cs = new CartService();
                        dispatch(cs.GetDisplayMiniCartActionCreator());
                        NotificationManager.success(<GetLanguage value="Product removed successfully"/>);
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DELETE_ITEM_FROM_CART, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        };
    }
    //Action Creator for empty cart
    EmptyCartActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_EMPTY_CART });
            dispatch({ type: SET_LOADER_STATUS });
           
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            let tokenType = localStorage.getItem('current_cart');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.multiple[tokenType];
            }
           
                //Signed-in customer Empty Cart process
                let requestURL = '/carts/mine/customeremptycart';
                let requestType = 'DELETE';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                // requestBody.data = {
                //     "cartId":cartId,
                //     "itemId":item_id 
                // }
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECEIVE_EMPTY_CART, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        //Update the Cart after emptying the Cart.
                        let cs = new CartService();
                        dispatch(cs.GetDisplayMiniCartActionCreator())
                        NotificationManager.success("Products removed successfully from cart");
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_EMPTY_CART, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
        };
    }

    //Action creator for updating the quantity in Cart
    EditQuantityInCartActionCreator(item_id, quantityChanged, type, quote_id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_EDIT_ITEM_QUANTITY_IN_CART });
            //dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.multiple[type];
            }
            //Signed-in customer Edit Cart process
            let requestURL = '/carts/mine/customerupdateitem'
            let requestType = 'PUT';
            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);
            let salesManFromLocalStorage = getItemFromLocalStorage('sales-man');
            requestBody.data = {
                    "cartId": quote_id,
                    "modifiedby": salesManFromLocalStorage ? salesManFromLocalStorage.salesmanName : '',
                    "params":[{"itemid": item_id, "qty":quantityChanged, "unit_of_measurement":"case"}]
            };
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_EDIT_ITEM_QUANTITY_IN_CART, success: true, response });
                    //dispatch({ type: SET_LOADER_STATUS });
                    //Update the Cart after item quantity change.
                    let cs = new CartService();
                    dispatch(cs.GetDisplayMiniCartActionCreator())
                },
                (error) => {
                    dispatch({ type: RECEIVE_EDIT_ITEM_QUANTITY_IN_CART, success: false, error });
                    //dispatch({ type: SET_LOADER_STATUS });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }

    /***************************************************************************** 
     * Action Creator for moving an item from Cart to Wishlist.
     * item_id is passed as a parameter.
     *****************************************************************************/
    moveToWishlistActionCreator(item_id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_MOVE_TO_WISHLIST_FROM_CART });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            let requestURL = '/wishlist/movetowishlist';
            let requestType = 'POST';
            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);
            requestBody.data = {
                "itemId": item_id
            };
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_MOVE_TO_WISHLIST_FROM_CART, success: true, response });
                    //Update the Cart after deleting item from the Cart.
                    let cs = new CartService();
                    dispatch(cs.GetDisplayMiniCartActionCreator())
                },
                (error) => {
                    dispatch({ type: RECEIVE_MOVE_TO_WISHLIST_FROM_CART, success: false, error });
                }
            );
        };
    }


    /******************************************************************************
     * Resetting the Redux state for Cart List.
     *****************************************************************************/
    resetMoveToWishlistActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_MOVE_TO_WISHLIST_FROM_CART });
        };
    }
    selectedvariantActionCreator(action) {
        return (dispatch) => {
            dispatch({ type: RECEIVE_SELECTED_VARIANT_DATA, action });
        };
    }
    /****************************************************************************** 
     * Redux Reducers
     *****************************************************************************/

    /****************************************************************************** 
     * Helper method to move item from Cart to Wishlist
     *****************************************************************************/
    static applyMovetoWishlist = (state, action) => {
        let moveToWishlistStatus = false;
        //Check if data is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            moveToWishlistStatus = action.response.data;
        }
        return {
            "moveToWishlistStatus": moveToWishlistStatus
        }
    }

    /****************************************************************************** 
     * Helper method to reset status of moving to Wishlist
     *****************************************************************************/
    static resetMovetoWishlistStatus = (state, action) => {
        return {
            "moveToWishlistStatus": null
        }
    }

    /****************************************************************************** 
     * Move to Wishlist from Cart Reducer.  
     *****************************************************************************/
    static moveToWishlistReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_MOVE_TO_WISHLIST_FROM_CART:
                let retValue = CartService.applyMovetoWishlist(state, action);
                return retValue;
            case REQUEST_RESET_MOVE_TO_WISHLIST_FROM_CART:
                let retValueReset = CartService.resetMovetoWishlistStatus(state, action);
                return retValueReset;
            default:
                return state;
        }
    }

    //Helper method for delete_from_cart_reducer
    static applyDeleteFromCart = (state, action) =>
        [...state, action];

    //Helper method for delete_from_cart_reducer
    static applyEmptyCart = (state, action) =>
        [...state, action];

    //Delete from Cart reducer        
    static deleteFromCartReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_DELETE_ITEM_FROM_CART:
                let retValue = CartService.applyDeleteFromCart(state, action);
                return retValue;
            default:
                return state;
        }
    }
    //Empty Cart reducer        
    static emptyCartReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_EMPTY_CART:
                let retValue = CartService.applyDeleteFromCart(state, action);
                return retValue;
            default:
                return state;
        }
    }
    //Helper method for getMiniCartDetailsReducer
    static getProductCount = (data) => {
        let productCount = 0;
        for (var i = 0; i < data.length; i++) {
            productCount += data[i].qty;
        }
        return productCount;
    }

    //Helper method for getMiniCartDetailsReducer
    static getTotalProductCost = (data) => {
        let totalproductCost = 0;
        for (var i = 0; i < data.length; i++) {
            totalproductCost += data[i].price * data[i].qty;
        }
        return totalproductCost;
    }

    //Helper method for getMiniCartDetailsReducer
    static applyMiniCartDetails = (state, action, current_cart_index) => {
        let products_in_cart = [];
        let hpcCount = 0;
        let foodsCount = 0;
        //Check if Cart Data is present in the action variable.  
        if (action && action.response && ("data" in action.response) && action.response.data.length > 0) {
            products_in_cart = action.response.data[current_cart_index].items;
            hpcCount = action.response.data[0].items_count;
            foodsCount = action.response.data[1].items_count;
        }
        else {
            products_in_cart = [];
        }
        let cart_product_count = 0;
        let cart_product_cost = 0;
        cart_product_count = CartService.getProductCount(products_in_cart);
        cart_product_cost = CartService.getTotalProductCost(products_in_cart);
        localStorage.setItem('current_cart', current_cart_index ? 'Foods' : 'HPC');
        return {
            "miniCartDetails": products_in_cart,
            "cart_product_count": cart_product_count,
            "cart_product_cost": cart_product_cost,
            "response": action,
            "cart_count": action && action.response && action.response.data && action.response.data.length > 0 ? action.response.data[0].items.length + action.response.data[1].items.length : 0,
            current_cart: action && action.response ? action.response.data[current_cart_index] : {},
            current_cart_index,
            hpcCount,
            foodsCount
        }

    }

    /***************************************************************************** 
     * Redux Reducer for getting Cart Details
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     *****************************************************************************/
    static getMiniCartDetailsReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_DISPLAY_MINI_CART:
                return state;
            case RECEIVE_DISPLAY_MINI_CART:
                const index = localStorage.getItem('current_cart') === 'Foods' ? 1 : 0;
                let retValue = CartService.applyMiniCartDetails(state, action, index);
                return retValue;
            case 'CHANGE_CART':
                let changeCart = CartService.applyMiniCartDetails(state, state.response, action.index);
                return changeCart;
            default:
                return state;
        }
    };

    //Helper method for updateQuantityCartReducer
    static applyUpdateQuantityFromCart = (state, action) =>
        [...state, action];
    //Reducer for updating the cart Quantity.    
    static updateQuantityCartReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_EDIT_ITEM_QUANTITY_IN_CART:
                let retValue = CartService.applyUpdateQuantityFromCart(state, action);
                return retValue;
            default:
                return state;
        }
    }
    static selectedVariant = (state = {}, action) => {
        switch (action.type) {
            case RECEIVE_SELECTED_VARIANT_DATA:
                return action.action.payload;
            default:
                return state;
        }
    }
    /***************************************************************************** 
    * Helper method for GetOrderSummaryDetailsReducer.
    * @param {*} state     - No modifications
    * @param {*} action    - From action, the order summary details are returned 
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyOrderSummaryDetails = (state, action) => {
        let orderSummaryDetails = [];
        //Check if Cart Data is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            orderSummaryDetails = action.response.data;
        }
        else {
            orderSummaryDetails = [];
        }
        return {
            "orderSummaryDetails": orderSummaryDetails
        }
    }

    /***************************************************************************** 
    * Reducer - Get Order summary details.
    * @param {*} state     - No modifications
    * @param {*} action    - From action, order details are returned.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static GetOrderSummaryDetailsReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_GET_ORDER_SUMMARY:
                return state;
            case RECEIVE_GET_ORDER_SUMMARY:
                let retValue = CartService.applyOrderSummaryDetails(state, action);
                return retValue;
            default:
                return state;
        }
    };

}
export default CartService;
