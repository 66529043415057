/**
* Global configuration 
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Amaresh M <amaresh.m@embitel.com> 
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import config from '../../../config/config.json';
import language from '../locale/language.json';

/****************************************************************************** 
 * object used to define different configuration levels
 *****************************************************************************/
const configLevels =
{
    config: config,
    language: language,
 }

/****************************************************************************** 
 * constant used to get configuration level
 *****************************************************************************/
const selectedConfigLevel = configLevels.config;

/****************************************************************************** 
 * constant used to get language coonfig levels
 *****************************************************************************/
const selectedLanguageLevel = configLevels.language;

/****************************************************************************** 
 * function used to get value, based on key passed in configuration file
 *****************************************************************************/
export default  function getConfigValue(key) {
    return  selectedConfigLevel[key];
};

/****************************************************************************** 
 * function used to get value, based on key passed in Language file
 *****************************************************************************/
export const getLanguageValue = (key) =>
{
    return  selectedLanguageLevel[key];
};



