/**
* SearchbarDesktop Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchRounded from '@material-ui/icons/SearchRounded';
import { getLanguageValue } from  '../../../../framework/config/configHandler';

const styles = theme => ({
  backgroundDesktop: {
    display: 'flex',
    margin: theme.spacing.unit,
    borderRadius: 2,
    border: '0.09rem solid rgba(0, 0, 0, 0.42)',
  },
  backgroundMobile: {
    display: 'flex',
    margin: theme.spacing.unit,    
    backgroundColor: '#2196f3',
    borderRadius: 2,
    border: '0.09rem solid rgba(0, 0, 0, 0.42)',
  },
  iconPaddingLeft: {
    paddingLeft: '0.2rem'
  }
});

/****************************************************************
 * This component returns input field which includes search icon
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class SearchbarDesktop extends React.Component {
  render() {
    const { classes } = this.props;
    const language = getLanguageValue("language");
      return (
      <Input
        id="adornment-search" placeholder={language.english["Search_Store"]} type="text"
        value={this.props.searchInputValue} onChange={this.props.searchInputHandler}
        className={(this.props.isSearchMobile) ? classes.backgroundMobile : classes.backgroundDesktop}
        disableUnderline={true}
        onKeyPress={this.props.searchClick}
        startAdornment={
          <InputAdornment position="start">
            <SearchRounded className={classes.iconPaddingLeft} color="action" />
          </InputAdornment>
        }
      />
    );
  }
}

SearchbarDesktop.propTypes = {
  searchInputValue: PropTypes.string,
  searchInputHandler: PropTypes.func
};

export default withStyles(styles)(SearchbarDesktop);