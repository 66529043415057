/**
* CheckoutService class with functions for handling Checkout related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_SHIPPING_ADDRESS,
    RECEIVE_SHIPPING_ADDRESS,
    REQUEST_SET_SHIPPING_ADDRESS,
    RECEIVE_SET_SHIPPING_ADDRESS,
    RECEIVE_DELETE_SHIPPING_ADDRESS,
    REQUEST_UPDATE_SHIPPING_DETAILS,
    RECEIVE_UPDATE_SHIPPING_DETAILS,
    RESET_UPDATE_SHIPPING_DETAILS,
    REQUEST_GET_COUNTRIES,
    RECEIVE_GET_COUNTRIES,
    REQUEST_POST_PAYMENT_METHOD,
    RECEIVE_POST_PAYMENT_METHOD,
    REQUEST_UPDATE_BILLING_DETAILS,
    RECEIVE_UPDATE_BILLING_DETAILS,
    RESET_UPDATE_PAYMENT_DETAILS,
    REQUEST_PUT_PLACE_ORDER,
    RECEIVE_PUT_PLACE_ORDER,
    REQUEST_SET_ADDRESS_AS_DEFAULT,
    RECEIVE_SET_ADDRESS_AS_DEFAULT,
    REQUEST_RESET_SET_ADDRESS_AS_DEFAULT,
    REQUEST_RESET_DELETE_SHIPPING_ADDRESS,
    SET_LOADER_STATUS,
    SET_DEFAULT_PLACE_ORDER,
    REQUEST_DELIVERY_DATE,
    RECIEVE_DELIVERY_DATE
} from '../../framework/common/ReduxConstants';
import { NotificationManager } from 'react-notifications';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../../framework/storage/local/localStorageHandler';
import magnaCookies from '../../framework/cookie/magnaCookies';
import AccountService from '../account/AccountService';
import CartService from '../cart/CartService';
import { request } from 'http';
import getConfigValue from '../../framework/config/configHandler';

/***************************************************************
 * Checkout Service class which handles delivery address
 * payment options and review order functionalities.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CheckoutService {
    //Action Creator to get Shipping Address.
    getShippingAddressActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SHIPPING_ADDRESS });
            let userInfo = getItemFromLocalStorage('shopce-user');
            let tokenType = localStorage.getItem('current_cart');
            let userToken = userInfo ? userInfo.multiple[tokenType] : '';
            let requestType = 'GET';
            let requestURL = '/customers/me';
            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for Sign-In
                    dispatch({ type: RECEIVE_SHIPPING_ADDRESS, success: true, response });
                    let selected_address;
                    response.data.addresses.map(address=>{
                        if(address.id ===id){
                            selected_address = address;
                        }
                        else{
                            if(address.default_shipping){
                            selected_address = address;
                            }
                            // else{
                            //     selected_address=address;
                            // }
                        }
                    })
                    let checkoutService = new CheckoutService();
                    const { address, shipping } = checkoutService.generateShippingObject(selected_address,response.data.email);

                    dispatch(checkoutService.updateShippingDetailsActionCreator(address, shipping,userToken));
                },
                (error) => {
                    dispatch({ type: RECEIVE_SHIPPING_ADDRESS, success: false, error });
                }
            );
        };
    }

    //Helper function for reducer shipping_address_reducer
    static applyShippingAddress = (state, action) => {
        let shipping_address;
        //Check if Cart Data is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            shipping_address = action.response.data;
        }
        else {
            shipping_address = null;
        }
        return shipping_address;
    }

    //Shipping Address GET request reducer.
    static shipping_address_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_SHIPPING_ADDRESS:
                return null;
            case RECEIVE_SHIPPING_ADDRESS:
                let retResetValue = CheckoutService.applyShippingAddress(state, action);
                return retResetValue;
            default:
                return state;
        }
    }

    //Action creator to Add/Edit Shipping Address
    setShippingAddressActionCreator(shippingaddressObj) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_SHIPPING_ADDRESS });

            let userInfo = getItemFromLocalStorage('shopce-user');
            let userToken = userInfo ? userInfo.token : '';
            let requestType = 'PUT';
            let requestURL = '/ecs/customeraddress';
            let requestBody = {};
            requestBody.data = shippingaddressObj;
            requestBody.header = ("Bearer " + userToken);
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch to add address
                    dispatch({ type: RECEIVE_SET_SHIPPING_ADDRESS, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_SET_SHIPPING_ADDRESS, success: false, error });
                }
            );
        };
    }

    //Helper function for reducer put_address_reducer
    static applyPutAddress = (state, action, isSuccess) => {
        if (isSuccess) {
            let returnObjectSuccess = {
                addressSaved: true,
            };
            return Object.assign({}, returnObjectSuccess);
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                addressSaved: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError);
        }
    }

    //Reducer for Shipping Address PUT request.
    static put_address_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_SET_SHIPPING_ADDRESS:
                return { ...state, addressSaved: null };
            case RECEIVE_SET_SHIPPING_ADDRESS:
                if (action.success === true) {
                    let retValueResult = CheckoutService.applyPutAddress(state, action, true);
                    return retValueResult;
                }
                else {
                    let retValueResult = CheckoutService.applyPutAddress(state, action, false);
                    return retValueResult;
                }
            default:
                return state;
        }
    }

    /***************************************************************
     * Action Creator to delete Shipping Address 
     * from MyAddresses Page
     ***************************************************************/
    deleteShippingAddressActionCreator(id) {
        return (dispatch) => {
            /***************************************************************
             * Reset the deleteMyAddressItemSuccess to null, before deleting
             * the shipping address.
             ***************************************************************/
            dispatch({ type: REQUEST_RESET_DELETE_SHIPPING_ADDRESS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only delete adddress from addresses
                let requestURL = '/ecs/addresses/' + id;
                let requestType = 'DELETE';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECEIVE_DELETE_SHIPPING_ADDRESS, success: true, response });
                        let checkoutService = new CheckoutService();
                        dispatch(checkoutService.getShippingAddressActionCreator(id));
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DELETE_SHIPPING_ADDRESS, success: false, error });
                    }
                );
            }
        };
    }

    resetDeleteAddrActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_DELETE_SHIPPING_ADDRESS });
        };
    }

    /***************************************************************
     * Helper function for reducer DeleteMyAddressReducer
     ***************************************************************/
    static applyDeleteMyAddress = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                deleteMyAddressItemSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { deleteMyAddressItemSuccess: true, isFavButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time'
            let returnObjectError = {
                deleteMyAddressItemSuccess: false,
                errorMessage: action.error.data ? action.error.data.message : genericError,
            };

            return Object.assign({}, returnObjectError, { deleteMyAddressItemSuccess: false, isFavButtonDisabled: false });
        }
    }

    /***************************************************************************** 
    * Redux Reducer to delete Address from MyAddress page
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Ruchi Verma <ruchi.verma@embitel.com>
    *****************************************************************************/
    static DeleteMyAddresstReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_RESET_DELETE_SHIPPING_ADDRESS:
                return { ...state, deleteMyAddressItemSuccess: null, isFavButtonDisabled: true };
            case RECEIVE_DELETE_SHIPPING_ADDRESS:
                let retValue = CheckoutService.applyDeleteMyAddress(state, action);
                return retValue;
            default:
                return state;
        }
    };

    //Action creator to Get Countries List
    getCountriesListActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_COUNTRIES });

            let requestType = 'GET';
            let requestURL = '/directory/countries';
            let requestBody = {};

            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for Sign-In
                    dispatch({ type: RECEIVE_GET_COUNTRIES, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_COUNTRIES, success: false, error });
                }
            );
        };
    }

    //Helper function for reducer get_countries_list_reducer
    static applyGetCountries = (state, action) => {
        let countries = [];
        //Check if Cart Data is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            countries = action.response.data;
        }
        return countries;
    }

    //Reducer for Countries List GET request.
    static get_countries_list_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_GET_COUNTRIES:
                return state;
            case RECEIVE_GET_COUNTRIES:
                let retList = CheckoutService.applyGetCountries(state, action);
                return retList;
            default:
                return state;
        }
    }
    reorder(component, orderId,type) {
        return (dispatch) => {
            component.setState({ reorderclick: true }, () => dispatch({ type: SET_LOADER_STATUS }));

            let userInfo = getItemFromLocalStorage('shopce-user');
            //  let loggedInCustomerCartID = userInfo ? userInfo.cartID : null;
            let tokenType = localStorage.getItem('current_cart');
            let userToken = userInfo ? userInfo.token : null;
            let requestBody = {};
            requestBody.data = { orderId };
            let requestType = 'POST';
            requestBody.header = ("Bearer " + userToken);
            let requestURL = '/ecs/reorder/';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                response => {
                    if (response.data[0].status === 'success') {
                        const cs = new CartService();
                        dispatch(cs.GetDisplayMiniCartActionCreator(component));
                    }

                },
                error => {

                });
        }
    }
    //Action creator to Set Selected Shipping Address
    updateShippingDetailsActionCreator(billingAddressObject, shippingAddressObject,userToken) {
        return (dispatch) => {
            dispatch({ type: REQUEST_UPDATE_BILLING_DETAILS });
            let userInfo = getItemFromLocalStorage('shopce-user');
            let loggedInCustomerCartID = userInfo ? userInfo.cartID : null;
          //  let userToken = userInfo ? userInfo.multiple.Foods : null;
            let requestBody = {};
          
            requestBody.data = billingAddressObject;
 
            let requestType = 'POST';
            if (loggedInCustomerCartID !== null) {
                requestBody.header = ("Bearer " + userToken);
                let requestURL = '/carts/mine/billing-address';
                //update billing address, as of now billing is same as shipping
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: REQUEST_UPDATE_SHIPPING_DETAILS });
                        let requestURL = '/carts/mine/shipping-information';
                        let requestBody = {};
                        requestBody.data = shippingAddressObject;
                       
                        //  let selectedAddress = JSON.parse(selectedAddressObject);
                        //after sucessfull updation of billing address, update shipping address
                        MagnaAPIHandler(requestURL, requestType, requestBody).then(
                            (response) => {
                                dispatch({ type: RECEIVE_UPDATE_SHIPPING_DETAILS, success: true, response });
                            },
                            (error) => {
                                dispatch({ type: RECEIVE_UPDATE_SHIPPING_DETAILS, success: false, error });
                            }
                        );
                        dispatch({ type: RECEIVE_UPDATE_BILLING_DETAILS, success: true, response });
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_UPDATE_BILLING_DETAILS, success: false, error });
                    }
                );
            }
        };
    }

    //Action creator to Reset Selected Shipping Address
    resetShippingAddressUpdateActionCreator() {
        return (dispatch) => {
            dispatch({ type: RESET_UPDATE_SHIPPING_DETAILS });
        }
    }

    //Helper function for reducer post_shipping_details_reducer
    static updateShippingAddress = (state, action) => {
        //Check if payment data is present in the action variable.  
        if (action.success && action.response && ("data" in action.response)) {
            return {
                isShippingAddressUpdated: true,
                paymentMethods: action.response.data,
                selectedDeliveryAddress: action.deliveryAddress,
            }
        }
        else {
            let genericError = 'Error, Please try again after some time'
            return {
                errorMessage: action.error.response ? (action.error.response.data ? action.error.response.data.message : genericError) : genericError,
                selectedDeliveryAddress: action.deliveryAddress,
            }
        }
    }

    //Helper function for reducer post_shipping_details_reducer
    static updateBillingAddress = (state, action) => {
        //Check if billing address is updated sucessfully
        if (action.success) {
            return {
                isBillingAddressUpdated: true
            }
        }
        else {
            let genericError = 'Error, Please try again after some time'
            return {
                isBillingAddressUpdated: false,
                errorMessage: action.error.response ? (action.error.response.data ? action.error.response.data.message : genericError) : genericError,
            }
        }
    }

    //Reset Shipping Address Update Info
    static resetShippingUpdateInfo = (state, action) => {

        return {
            // selectedDeliveryAddress: state.selectedDeliveryAddress,
            // paymentMethods: state.paymentMethods,
            isShippingAddressUpdated: null,
        }
    }

    //Reducer for Shipping Options POST request.
    static post_shipping_details_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_UPDATE_BILLING_DETAILS:
                return null;
            case RECEIVE_UPDATE_BILLING_DETAILS:
                let retBillingValue = CheckoutService.updateBillingAddress(state, action);
                return retBillingValue;
            case REQUEST_UPDATE_SHIPPING_DETAILS:
                return null;
            case RECEIVE_UPDATE_SHIPPING_DETAILS:
                let retShippingValue = CheckoutService.updateShippingAddress(state, action);
                return retShippingValue;
            case RESET_UPDATE_SHIPPING_DETAILS:
                let retResetValue = CheckoutService.resetShippingUpdateInfo(state, action);
                return retResetValue;
            default:
                return state;
        }
    }

    //Action creator to set selected payment option
    setPaymentOptionsActionCreator(paymentMethod) {
        return (dispatch) => {
            dispatch({ type: REQUEST_POST_PAYMENT_METHOD });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            //Signed-in customer setting payment method
            let requestURL = '/carts/mine/set-payment-information';
            let requestType = 'POST';

            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);

            let paymentMethodObject = JSON.parse(paymentMethod);

            requestBody.data = {
                "paymentMethod": {
                    "method": paymentMethodObject.code,
                }
            };
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_POST_PAYMENT_METHOD, paymentMethod: paymentMethodObject, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_PAYMENT_METHOD, success: false, error });
                }
            );
        };
    }

    //Action creator to reset payment details
    resetPaymentUpdateActionCreator() {
        return (dispatch) => {
            dispatch({ type: RESET_UPDATE_PAYMENT_DETAILS });
        }
    }

    //Helper function for reducer post_payment_options_reducer
    static updatePaymentOption = (state, action) => {
        //Check if payment data is present in the action variable.  
        if (action.success && action.response && ("data" in action.response)) {
            return {
                isPaymentOptionsUpdated: true,
                paymnetOptionStatus: action.response.data,
                selectedPaymentOption: action.paymentMethod,
            }
        }
        else {
            let genericError = 'Error, Please try again after some time.'
            return {
                isPaymentOptionsUpdated: false,
                errorMessage: action.error.response ? (action.error.response.data ? action.error.response.data.message : genericError) : genericError,
                selectedPaymentOption: action.paymentMethod,
            }
        }
    }

    //Helper function for reducer post_payment_options_reducer
    static resetPaymentUpdateInfo = (state, action) => {

        return {
            selectedPaymentOption: state.selectedPaymentOption,
            isPaymentOptionsUpdated: null,
        }
    }

    //Reducer to Set Payment Options POST request.
    static post_payment_options_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_POST_PAYMENT_METHOD:
                return state;
            case RECEIVE_POST_PAYMENT_METHOD:
                let retPaymentOptions = CheckoutService.updatePaymentOption(state, action);
                return retPaymentOptions;
            case RESET_UPDATE_PAYMENT_DETAILS:
                let retResetValue = CheckoutService.resetPaymentUpdateInfo(state, action);
                return retResetValue;
            default:
                return state;
        }
    }

    //Action creator to place order
    placeOrderActionCreator(selectedPaymentOption,poNumber) {
        return (dispatch) => {
            //dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_PUT_PLACE_ORDER });
            let userInfo = getItemFromLocalStorage('shopce-user');
            let tokenType = localStorage.getItem('current_cart') ?  localStorage.getItem('current_cart') : 'HPC';
            let loggedInCustomerCartID = userInfo ? userInfo.cartID : null;
            let userToken = userInfo ? userInfo.multiple[tokenType] : null;
            let requestBody = {};
            requestBody.data = {
                "paymentMethod": {
                    "po_number":poNumber,
                    "method": selectedPaymentOption
                }
            };
            let requestType = 'PUT';
            if (loggedInCustomerCartID !== null) {
                requestBody.header = ("Bearer " + userToken);
                let requestURL = '/carts/mine/order';
                //place order
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: SET_LOADER_STATUS });
                        let comments = localStorage.getItem('order_notes');
                        let estimated_date = localStorage.getItem('estimatedDate');
                        let requestURL = '/ecs/orders/'+response.data+'/comments';
                        let deliveryDateURL = '/ecs/orders/deliverydate/'+response.data+'/'+estimated_date;
                        let deliveryDateRequestType ='GET';
                        let deliveryDateRequestBody ={};
                        deliveryDateRequestBody.header =("Bearer " + userToken);
                        let requestType ='POST';
                        let requestBody ={};
                        let data = {
                            "statusHistory": {
                              "comment": comments,
                              "created_at": "",
                              "entity_name": "",
                              "is_customer_notified": 0,
                              "is_visible_on_front": 0,
                              "parent_id": 0,
                              "status": "Submitted",
                              "extension_attributes": {}
                            }
                          }
                        requestBody.data = data;
                        requestBody.header = ("Bearer " + userToken);
                        MagnaAPIHandler(deliveryDateURL, deliveryDateRequestType, deliveryDateRequestBody).then(
                            (response) => {
                                localStorage.removeItem("estimatedDate");
                        });
                        MagnaAPIHandler(requestURL, requestType, requestBody).then(
                            (response) => {
                                localStorage.removeItem("order_notes")
                        });
                        magnaCookies.removeCookie('customer-cart-id');
                        dispatch({ type: RECEIVE_PUT_PLACE_ORDER, success: true, response });
                        //dispatch({ type: SET_LOADER_STATUS });
                        AccountService.getCustomerDetails(dispatch,tokenType);
                        // const order = new OrderService();
                        // dispatch(order.getOrderHistoryActionCreator());
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_PUT_PLACE_ORDER, success: false, error });
                        //dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        };
    }

    //Helper function for reducer put_place_order_reducer
    static applyPlaceOrder = (state, action) => {
        if (action.success) {
            let placeOrderSuccess =
            {
                placeOrderStatus: true,
                orderNumber: action.response.data,
            }
            return Object.assign({}, placeOrderSuccess, { placeOrderStatus: true, isPlaceOrderButtonDisabled: false });
        }
        else {
            let placeOrderError =
            {
                
                placeOrderStatus: false,
                errorMessage: action.error ?"Please try again":'Please try again'
            }
            return Object.assign({}, placeOrderError, { placeOrderStatus: false, isPlaceOrderButtonDisabled: false });
        }
    }

    //Reducer to Place Order PUT request.
    static put_place_order_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_PUT_PLACE_ORDER:
                return { ...state, isPlaceOrderButtonDisabled: true };
            case RECEIVE_PUT_PLACE_ORDER:
                let retPlaceOrder = CheckoutService.applyPlaceOrder(state, action);
                return retPlaceOrder;
            case SET_DEFAULT_PLACE_ORDER: return [];
            default:
                return { ...state, isPlaceOrderButtonDisabled: false };
        }
    }

    /*************************************************************************
     * Action creator for setting an address as default
     *************************************************************************/
    setAsDefaultAddressActionCreator(address_id, customer_id) {
        return (dispatch) => {
            /*************************************************************************
             * Reset the setAsDefaultAddressStatus to null, before setting
             * the address as default.
             *************************************************************************/
            dispatch({ type: REQUEST_RESET_SET_ADDRESS_AS_DEFAULT });
            dispatch({ type: REQUEST_SET_ADDRESS_AS_DEFAULT });

            let userInfo = getItemFromLocalStorage('shopce-user');
            let userToken = userInfo ? userInfo.token : null;
            let requestBody = {};
            requestBody.data = {
                "customerId": customer_id,
                "addressId": address_id
            };
            let requestType = 'POST';
            requestBody.header = ("Bearer " + userToken);
            let requestURL = '/ecs/addresses/setDefault';
            //Set address as default
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_SET_ADDRESS_AS_DEFAULT, success: true, response });
                    //After setting the address as default, fetch updated addresses
                    let checkoutService = new CheckoutService();
                    dispatch(checkoutService.getShippingAddressActionCreator(address_id));
                },
                (error) => {
                    dispatch({ type: RECEIVE_SET_ADDRESS_AS_DEFAULT, success: false, error });
                }
            );

        };
    }

    resetPostSetAsDefaultAddrActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_SET_ADDRESS_AS_DEFAULT });
        };
    }

    /*************************************************************************
     * Helper function for reducer post_set_as_default_address_reducer
     *************************************************************************/
    static applySetAsDefaultAddress = (state, action) => {
        if (action && action.response && action.response.data === true) {
            let returnObjectSuccess = {
                setAsDefaultAddressStatus: true,
            };
            return Object.assign({}, returnObjectSuccess);
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                setAsDefaultAddressStatus: false,
                errorMessage: genericError,
            };
            return Object.assign({}, returnObjectError);
        }
    }

    /*************************************************************************        
     * Reducer for setting a specific address as default.
     *************************************************************************/
    static post_set_as_default_address_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_SET_ADDRESS_AS_DEFAULT:
                let retValue = CheckoutService.applySetAsDefaultAddress(state, action);
                return retValue;
            case REQUEST_RESET_SET_ADDRESS_AS_DEFAULT:
                return { ...state, setAsDefaultAddressStatus: null };
            default:
                return state;
        }
    }
    generateShippingObject = (selectedAddress, email) => {
        //parse string to JSON
        let selectedAddressObj = { ...selectedAddress };
        //get user details from local storage
        var userInfo = getItemFromLocalStorage('shopce-user');
        if (selectedAddressObj && selectedAddressObj.length !== 0) {

            var address = {
                "address": {
                    "customerAddressId": selectedAddressObj.id,
                    "customerId": selectedAddressObj.customer_id ? selectedAddressObj.customer_id : '',
                    "countryId": selectedAddressObj.country_id,
                    "regionId": selectedAddressObj.region_id,
                    "regionCode": selectedAddressObj.region ? selectedAddressObj.region.region_code : '',
                    "region": selectedAddressObj.region ? selectedAddressObj.region.region : '',
                    "street": selectedAddressObj.street,
                    "telephone": selectedAddressObj.telephone,
                    "postcode": selectedAddressObj.postcode,
                    "city": selectedAddressObj.city,
                    "firstname": selectedAddressObj.first_name ? selectedAddressObj.first_name : 'Shaik',
                    "lastname": selectedAddressObj.last_name ? selectedAddressObj.last_name : 'Sameena',
                    "custom_attributes":selectedAddressObj.custom_attributes?selectedAddressObj.custom_attributes:''
                }
            }

            var shipping = {
                "addressInformation":
                {
                    "billingAddress":
                    {
                        "id": selectedAddressObj.id,
                        "region": selectedAddressObj.region ? selectedAddressObj.region.region : '',
                        "regionId": selectedAddressObj.region_id,
                        "regionCode": selectedAddressObj.region ? selectedAddressObj.region.region_code : '',
                        "countryId": selectedAddressObj.country_id,
                        "street": selectedAddressObj.street,
                        "telephone": selectedAddressObj.telephone,
                        "postcode": selectedAddressObj.postcode,
                        "city": selectedAddressObj.city,
                        "firstname": selectedAddressObj.firstname ? selectedAddressObj.firstname : '',
                        "lastname": selectedAddressObj.lastname ? selectedAddressObj.lastname : '',
                        "customer_id": selectedAddressObj.customer_id ? selectedAddressObj.customer_id : '',
                        email,
                        "same_as_billing": 1,
                        "save_in_address_book": 1,
                        "custom_attributes":selectedAddressObj.custom_attributes?selectedAddressObj.custom_attributes:'',
                    },
                    "shippingAddress":
                    {
                        "region": selectedAddressObj.region ? selectedAddressObj.region.region : '',
                        "regionId": selectedAddressObj.region_id,
                        "regionCode": selectedAddressObj.region ? selectedAddressObj.region.region_code : '',
                        "countryId": selectedAddressObj.country_id,
                        "street": selectedAddressObj.street,
                        "telephone": selectedAddressObj.telephone,
                        "postcode": selectedAddressObj.postcode,
                        "city": selectedAddressObj.city,
                        "firstname": selectedAddressObj.firstname ? selectedAddressObj.firstname : '',
                        "lastname": selectedAddressObj.lastname ? selectedAddressObj.lastname : '',
                        "customer_id": selectedAddressObj.customer_id ? selectedAddressObj.customer_id : '',
                            email,
                        "same_as_billing": 1,
                        "save_in_address_book": 1,
                        "custom_attributes":selectedAddressObj.custom_attributes?selectedAddressObj.custom_attributes:''
                    },
                    "shippingCarrierCode": "freeshipping",
                    "shippingMethodCode": "freeshipping"
                }
            };
            //   this.setState({ shippingDetails: shippingOptionsObject });
            //  this.setState({ addressDetails: addressObject });
        }
        return { address, shipping }
    }
    GetEstimatedDeliveryDateActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_DELIVERY_DATE });
            let userInfo = getItemFromLocalStorage('shopce-user');
            let userToken = userInfo ? userInfo.token : null;
            let requestBody = {};
            let requestType = 'GET';
            requestBody.header = ("Bearer " + userToken);
            const systemParameters = getConfigValue("system");
            let storeId = window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].id:systemParameters.store[1].id
            let selectedLanguage= localStorage.getItem('i18nextLng');
            if(selectedLanguage==='en'){
                storeId = window.location.hostname === 'usmileshoppro.com'? 1:1;
            }
            else{
                storeId = window.location.hostname === 'usmileshoppro.com'? 5:5;
            }
            let requestURL = '/ecs/estimated-date/'+storeId+"/"+userInfo.customerId;
            //Set address as default
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    setItemInLocalStorage('estimatedDate',response.data);
                    dispatch({ type: RECIEVE_DELIVERY_DATE, success: true, response });
                },
                (error) => {
                    let checkoutService = new CheckoutService();
                    dispatch(checkoutService.GetEstimatedDeliveryDateActionCreator());
                    dispatch({ type: RECIEVE_DELIVERY_DATE, success: false, error });
                }
            );

        };
    }
    static GetEstimateDeliveryDateReducer = (state = '', action) => {
        switch (action.type) {
            case REQUEST_DELIVERY_DATE:
                    return state;
            case RECIEVE_DELIVERY_DATE:
                    if(action.response) {
                        return action.response.data;
                    } else {
                        return '';
                    }
            default:
                return state;
        }
    }
}

export default CheckoutService;
