/**
* Components for displaying desktop Menus.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { handleURLsForMenu } from '../../../../app/framework/common/helpers';

/***************************************************************************** 
 * Desktop top first level drop-down submenu component.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const DropdownDesktopMenuItem = (props) => (
    <Link
        className="subnav_link"
        to={handleURLsForMenu(props.submenu[2])}
        style={{ textDecoration: 'none' }}>
        <p className="subnav-text">{props.submenu[0]}</p>
    </Link>
);

/***************************************************************************** 
 * Desktop second level drop-down submenu component.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const DropdownDesktopSubMenuItem = (props) => (
    <Link
        key={props.index}
        className="subnav_links"
        to={handleURLsForMenu(props.submenu["url"])}
        style={{ textDecoration: 'none', fontSize: '12px' }}>
        <img style={{ width:'80px'}} src={props.submenu["image"]}/>
    </Link>
);

export { DropdownDesktopMenuItem, DropdownDesktopSubMenuItem };