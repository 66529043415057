/**
* Axios Client Configuration
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import axios from "axios";

/******************************************************************************* 
 * Common base URL configuration for Axios Client
 * baseURL points to MageCE integration backend.
 * Base URL is changed to point to AWS Magento Backend.
 ******************************************************************************/
let locallanguage = localStorage.getItem("i18nextLng");
if(locallanguage === 'en'){
  locallanguage = 'default';
}else if(locallanguage === 'th'){
  locallanguage = 'th_th';
}else{
  locallanguage = 'default';
}

const hostName = window && window.location && window.location.hostname;
let baseURL='https://mcstaging.elmt.b2b.unileverservices.com/rest/'+locallanguage+'/V1';
if (hostName === 'usmileshoppro.com') {
  baseURL = 'https://usmileshoppro.com'+'/rest/'+locallanguage+'/V1';
}

// var axiosInstance = axios.create({
//   baseURL: '/rest/'+locallanguage+'/V1',
//   headers: {'Content-Type': 'application/json','Accept':'application/json'},
//   responseType:'json',
// });

var axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {'Content-Type': 'application/json','Accept':'application/json'},
  responseType:'json',
});

export default axiosInstance;