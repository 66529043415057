/**
* Common CMS Component for handling and rendering CMS contents.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },

});

/****************************************************************************** 
 * Component for rendering CMS Content.
 * Content is passed through props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class CMSComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let { classes } = this.props;
        const { content, title } = this.props;
        //Check for existence of CMS data
        if (content) {
            return (
                <div className={classes.root}>
                    <h2>{title}</h2>
                    <div
                        dangerouslySetInnerHTML={{ __html: content }}>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

CMSComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,    
    content: PropTypes.string,    
};

export default withStyles(styles)(CMSComponent);