/**
* signInButton Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import AccountService from '../../../../app/modules/account/AccountService';
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import { connect } from 'react-redux';
import PersonOutline from '@material-ui/icons/PersonOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'; import CartService from '../../../../app/modules/cart/CartService';
import { NotificationManager } from 'react-notifications';
import { Hidden } from '@material-ui/core';
import Store from '@material-ui/icons/Store';
import GetLanguage from '../../../../library/GetLanguage';
import User from '../../../../assets/images/User.svg';

const styles = theme => ({
  buttonText: {
    color: '#000000de',
    fontSize: '14px',
    padding: '8px 2px 8px 5px',
    height: '46px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  headerBarStylesDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mdDeviceUpDisplayNone: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuText: {
 '&:last-child': {
  borderBottom: 'none',
},
    color: '#000',
    fontSize: '12px',
    fontWeight: '500',
    position: 'relative', 
    border: 'none',
    borderBottom: '1px solid #ccc',
    margin: '5px 20px',
  },
  iconSize: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  }
});

/***************************************************************
 * Component class for displaying Sign In Button and submenu 
 * on hover. Submenu includes My Account and Logout.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class SignInButton extends React.Component {
  state = {
    open: false,
    isSignOut: false,
    isAuthenticated: false,
    salesmanLoggedInAsCustomerName: ''
  };

  componentDidMount() {
    let CustomerExist = getItemFromLocalStorage('shopce-user');
    let SalesmanExist = getItemFromLocalStorage('sales-man');
    let SalesmanLoggedIn = getItemFromLocalStorage('salesman-user');

    if (CustomerExist) {
      this.setState({ salesmanLoggedInAsCustomerName: CustomerExist.firstname + ' ' + CustomerExist.lastname });
    }
    if (SalesmanExist && SalesmanLoggedIn) {
      this.setState({ salesmanLoggedInAsCustomerName: SalesmanExist.customer });
    }
    //check if localStorage exists
    if (typeof CustomerExist !== 'undefined' && CustomerExist !== '' && CustomerExist !== null) {
      this.setState({ isAuthenticated: true });
    }
    else {
      this.setState({ isAuthenticated: false });
    }
  }

  //handles submenu show/hide
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };
  //handles closing of menu
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  /***************************************************************************** 
   * Handle SignOut process.
   * Clear localStorage and Cookie
   *****************************************************************************/
  customerSignout = (event) => {
    let CustomerExist = getItemFromLocalStorage('shopce-user');
    let salesManExist = getItemFromLocalStorage('salesman-user');
    if (CustomerExist || salesManExist) {
      //reset signed in state
      //Clear browser storage on User SignOut.
      AccountService.clearOnSignOut();
      this.props.resetSignedInUserSuccess();
      this.props.resetSalesManSignIn();
      this.props.refreshMinicart();

      this.setState({ open: false });
      this.setState({ isAuthenticated: false });
      //After SignOut, set the state to Redirect to home page.
      this.setState({ isSignOut: true });
      //Close the menu
      if (this.anchorEl.contains(event.target)) {
        return;
      }
    }
    else {
      this.setState({ open: false });
      this.setState({ isAuthenticated: false });
      //After SignOut, set the state to Redirect to home page.
      this.setState({ isSignOut: true });
    }
    localStorage.removeItem("tabName");
  }

  handleSignOut = (event) => {
    let CustomerExist = getItemFromLocalStorage('shopce-user');
    let SalesmanExist = getItemFromLocalStorage("salesman-user");
    // let customerName=CustomerExist.firstname;
    // let SalesmanName=SalesmanExist.firstname;

    if (SalesmanExist !== null && SalesmanExist !== undefined) {
      if (CustomerExist.customerId === SalesmanExist.customerId) {
        this.customerSignout(event);
        removeItemFromLocalStorage('salesman-user');

        //this.props.resetSalesManlist();
        // this.customerSignout(event);
        // removeItemFromLocalStorage('shopce-user');
        // setItemInLocalStorage('shopce-user', JSON.stringify(SalesmanExist));
        // NotificationManager.success(`Customer ${customerName} logged out. Salesman ${SalesmanName} logged in`);

      }
      else {
        // let customerName=CustomerExist.firstname;
        // let SalesmanName=SalesmanExist.firstname;
        removeItemFromLocalStorage('shopce-user');
        setItemInLocalStorage('shopce-user', JSON.stringify(SalesmanExist));
        this.props.refreshMinicart();
        NotificationManager.success(`Customer ${CustomerExist.firstname} logged out. Salesman ${SalesmanExist.firstname} logged in`);
      }
    }
    else {
      this.customerSignout(event);
    }
  }

  render() {
    const { open } = this.state;
    const { isSalesmanUser, classes, userInfo } = this.props;
    //After SignOut, Redirect to 
    //Home Page.
    let elmtUser = getItemFromLocalStorage('shopce-user');
    
    // if (elmtUser && this.state.salesmanLoggedInAsCustomerName !== elmtUser.firstname + ' ' + elmtUser.lastname) {
    //   this.setState({ salesmanLoggedInAsCustomerName: elmtUser.firstname + ' ' + elmtUser.lastname });
    // }

    let SalesmanLoggedIn = getItemFromLocalStorage('salesman-user');


    if (this.state.isSignOut && window.location.pathname !== '/elmt/') {
      return <Redirect push to="/" />;
    }
    else if (this.state.isSignOut && window.location.pathname === '/elmt/') {
      window.location.reload();
    }
    if (!this.state.isAuthenticated) {
      return (
        <React.Fragment>
          {SalesmanLoggedIn === '' || SalesmanLoggedIn === null ?
            <div>
              <div className={classes.headerBarStylesDesktop}>

                <IconButton buttonRef={node => { this.anchorEl = node; }}
                  className={classes.buttonText}
                  aria-owns={open ? 'menu-list-grow' : null}
                  aria-haspopup="true" component={Link} to="/account" disableRipple={true}>
                  {<GetLanguage value="Login " />}
                  <img className={classes.bannerbottonImg} style={{ width: '30px' }} src={User}/>
                  {/* <PersonOutline color="primary" className={classes.iconSize} /> */}
                </IconButton>
              </div>
              <div className={classes.mdDeviceUpDisplayNone}>
                {/* <IconButton buttonRef={node => { this.anchorEl = node; }}
                  className={classes.buttonText}
                  aria-owns={open ? 'menu-list-grow' : null}
                  aria-haspopup="true" component={Link} to="/account" disableRipple={true}>
                  <PersonOutline color="primary" className={classes.iconSize} />
                </IconButton> */}
              </div>
            </div>
            :

            <div>
              <ClickAwayListener onClickAway={this.handleClose}>
                {/* <IconButton buttonRef={node => { this.anchorEl = node; }} aria-haspopup="true"
                  className={classes.buttonText} onClick={this.handleToggle}
                  aria-owns={open ? 'menu-list-grow' : null}>

                  <PersonOutline color="primary" className={classes.iconSize} />
                  <Hidden only={['sm', 'xs']}> <span style={{ fontSize: '14px' }}> {this.state.salesmanLoggedInAsCustomerName}</span></Hidden>
                  {open ? <ArrowDropUpIcon color="primary" className={classes.iconSize} /> : <ArrowDropDownIcon color="primary" className={classes.iconSize} />}
                </IconButton> */}
              </ClickAwayListener>
              <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{ zIndex: '1000' }}>
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} id="menu-list-grow"
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList>
                          {/* <MenuItem className={classes.menuText} to={{ pathname: '/myaccount', state: { submenu:0 } }}
                           onClick={this.handleClose} component={Link} >{'My Account'}</MenuItem> */}


                          <MenuItem className={classes.menuText} onClick={this.customerSignout}>{<GetLanguage value="Logout" />}</MenuItem>

                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>


          }
        </React.Fragment>
      );
    }

    else {
      return (
        <div>
          <ClickAwayListener onClickAway={this.handleClose}>
            <IconButton buttonRef={node => { this.anchorEl = node; }} aria-haspopup="true"
              className={classes.buttonText} onClick={this.handleToggle}
              aria-owns={open ? 'menu-list-grow' : null}>
             
              <Hidden only={['sm', 'xs']}> <span style={{ fontSize: '14px' }}> {this.state.salesmanLoggedInAsCustomerName}</span></Hidden>
              {isSalesmanUser ? <Store color="primary" className={classes.iconSize} /> :
              <img className={classes.bannerbottonImg} style={{ width: '30px' }} src={User}/>}
              {open ? <ArrowDropUpIcon color="primary" className={classes.iconSize} /> : <ArrowDropDownIcon color="primary" className={classes.iconSize} />}
              
            </IconButton>
          </ClickAwayListener>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{ zIndex: '100' }}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem className={classes.menuText} to={{ pathname: '/myaccount', state: { submenu: 0 } }}
                        onClick={this.handleClose} component={Link} >{isSalesmanUser ? <GetLanguage value='Account' /> : <GetLanguage value='My Account' />}</MenuItem>
                      <MenuItem className={classes.menuText} to={{ pathname: '/myaccount', state: { submenu: 4 } }}
                        onClick={this.handleClose} component={Link} >{isSalesmanUser ? <GetLanguage value='Requisition List' /> : <GetLanguage value='My Requisition List' />}</MenuItem>
                      <MenuItem className={classes.menuText} component={Link} to={{ pathname: '/myaccount', state: { submenu: 3 } }}
                        onClick={this.handleClose}>{isSalesmanUser ? <GetLanguage value='Orders' /> : <GetLanguage value='My Orders' />}</MenuItem>
                      {!isSalesmanUser &&
                        <MenuItem className={classes.menuText} onClick={this.customerSignout}>{<GetLanguage value="Logout" />}</MenuItem>
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
});
const mapDispatchToProps = dispatch => ({
  resetSignedInUserSuccess: () => {
    let as = new AccountService();
    dispatch(as.resetSignedInActionCreator());
  },
  refreshMinicart: () => {
    let cart = new CartService();
    dispatch(cart.GetDisplayMiniCartActionCreator());
  },
  resetSalesManSignIn: () => {
    let as = new AccountService();
    dispatch(as.resetSalesManSignInActionCreator());
  },
}
);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignInButton));
