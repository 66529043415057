/**
* Sample Use for template without Header and Footer (Empty)
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Empty from './Empty';
import ErrorPage from '../../../../app/components/error/ErrorPage';

const styles = theme => ({
    
});

/***************************************************************************** 
 * This component uses Tempalte Empty template.
 * Uses 404 Page for display of content. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class TemplateUsageEmpty extends Component {
    render() { 
        return (
            <div>                
                <Empty blocks={[ErrorPage]} />
            </div>
        );
    }
}

export default withStyles(styles)(TemplateUsageEmpty);

