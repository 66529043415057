/**
* Template for preparing Pages with 2 Columns and Right Bar.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

/**************************************************************
 * This component renders a Page with the props it receives. 
 * Acts as a template with Header and Footer. 
 * Has 2 Columns and a Navigation Bar.
 * Theme gets passed as a prop.
 * Renders the page blocks passed as an array. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *************************************************************/
class TwoColumnsRightBar extends Component {
    render() {
                
        const { HeaderComponent,
            NavigationComponent,
            FooterComponent,
            rightSideBarComponent,
            mainContent } = this.props;

        return (
            <Fragment>
                <HeaderComponent></HeaderComponent>
                <NavigationComponent></NavigationComponent>
                <div>
                    <div>
                        {mainContent && mainContent.map((Block, i) => {
                            return <Block key={i}>{this.props.children}</Block>
                        })}
                    </div>
                    <div>
                        {rightSideBarComponent && rightSideBarComponent.map((Block, i) => {
                            return <Block key={i}>{this.props.children}</Block>
                        })}
                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </Fragment>
        );
    }
}
TwoColumnsRightBar.propTypes = {
    classes: PropTypes.object.isRequired,
    HeaderComponent: PropTypes.func.isRequired,
    NavigationComponent: PropTypes.func.isRequired,
    FooterComponent: PropTypes.func.isRequired,
    rightSideBarComponent: PropTypes.array,
    mainContent: PropTypes.array,
};

export default withStyles(styles)(TwoColumnsRightBar);

