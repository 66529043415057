/**
* Component for displaying One column of Desktop Menu.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Manager, Target, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TopLevelDesktopMenu from './TopLevelDesktopMenu';
import { DropdownDesktopMenuItem, DropdownDesktopSubMenuItem } from './DropdownDesktopMenuItem';
//Styles for Navigation menu Links
import './Navigation.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing.unit * 1,
    },
    popperClose: {
        pointerEvents: 'none',
    },
    menuBarColor: {
        backgroundColor: '#F0F0F0',
    },
    topMenuStyle: {
        border: '2px solid transparent',
        '&:hover': {
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#2196f3',
        },
    },
    menuStyle: {
        width: 'auto',
        height: 'auto',
        // backgroundColor: '#ccc',
    },
    menuRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        '&:after': {
            content: "",
            clear: 'both',
        },
    },
    menuColumn: {
        padding: '0',
        backgroundColor: '#FFFFFF',
        height: 'auto',
        '& a.subnav_link' : {
            '&:hover': {
                backgroundColor:'#e6f4ff',
                color:'#f65e5d',
            },
            '&::after': {
                content: "'>'",
                position: 'absolute',
                right: '15px',
                top: '4px',
            },
            color: '#424242',
            fontWeight:'bold',
            fontSize:'14px',
            textTransform: 'uppercase',
            display:'block',
            backgroundColor:'#fff',
            position:'relative',
            '& p':{
                margin:'2px',
                padding: '5px 15px',
                fontSize:'12px',
            },
        },
        '& .secondSubMenu' : {
            position: 'absolute',
            top: '0',
            left: '270px',
            width: '600px',
            minHeight: '400px',
            zIndex: '10',
            background: '#fff',
            '& .subnav_links' :{
                display: 'inline-flex',
                padding: '20px',
                flexDirection: 'column',
            }
        }
        
    },
    submenuColumn: {
        width:'100%',
    },
    submenuColumns: {
        padding:'0',
    },
    submenuColumnExp:{
        width:'270px',
    },
    submenuColumnExpr:{
        background:'#f8f8f8',
        padding: '0',
        borderLeft: '1px solid #ccc',
        minHeight: '25px !important',
     '& div:nth-of-type(1)':{
       margin:'0',
     }
    },
    secondSubMenu:{
        
    },
    arrowColor:{
    color: '#1d2d5f',
    fontSize:'15px' 
    }
   
    
});

/***************************************************************
 * Component class for displaying Category Menu.
 * Receives data from Parent Component in the form of props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class NavigationDesktopUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded:'Home&PersonalCare'
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleClose = (event) => {
        this.setState({ open: false });
    };
    handleChange(panel){

        if(panel == this.state.expanded){
            this.setState({
                expanded:false
            })
        }else{
            this.setState({
                expanded: panel ? panel: false
            })
        }
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        const topmenu = this.props.topmenu;
        // var isSubmenu = Object.keys(topmenu[3]).length;
        const submenu = this.props.submenu;
        return (
            <div className={classes.root} style={{margin:'0'}} >
                <ExpansionPanel style={{margin:'0'}} className={classes.submenuColumnExp} expanded={this.state.expanded === topmenu[0].replace(/ /g,'') } onChange={this.handleChange.bind(this,topmenu[0].replace(/ /g,''))}>
                    <ExpansionPanelSummary style={{margin:'0'}} className={classes.submenuColumnExpr}
                        expandIcon={this.state.expanded === topmenu[0].replace(/ /g,'')
                            ? <Remove className={classes.arrowColor}/>
                            : <Add className={classes.arrowColor}/>}
                        aria-controls={topmenu[0].replace(/ /g,'')}
                        id={topmenu[0].replace(/ /g,'') }
                    >
                        <Typography className={classes.heading} style={{margin:'0'}}  ><TopLevelDesktopMenu topmenu={topmenu} style={{margin:'0'}} /></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.submenuColumns} style={{margin:'0'}} >
                    
                        <Typography className={classes.submenuColumn} style={{margin:'0'}} >
                            {  
                                submenu.map((subMenu, index) =>
                                    <div key={index} style={{margin:'0'}} className={classes.menuColumn +" " + "parent"}>
                                        <DropdownDesktopMenuItem submenu={subMenu} />

                                        <div className="secondSubMenu">
                                            {
                                                subMenu[3].map((secondSubMenu, index) =>
                                                    <DropdownDesktopSubMenuItem key={index}
                                                        index={index} submenu={secondSubMenu} />

                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* <div onMouseEnter={this.handleToggle} onMouseLeave={this.handleClose} style={{ zIndex: 999 }}>
                    <Manager>
                        <Target>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <TopLevelDesktopMenu topmenu={topmenu}/>
                            </div>
                        </Target>
                        <Popper placement="top-start" eventsEnabled={open} positionFixed={true}
                            modifiers={{ preventOverflow: { enabled: true } }}
                            className={classNames({ [classes.popperClose]: !open })}>
                            <Grow in={open} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                                <Paper className={classes.menuStyle}>
                                    <div className={classes.menuRow}>
                                        {
                                            submenu.map((subMenu, index) =>
                                                <div key={index} className={classes.menuColumn}>
                                                    <DropdownDesktopMenuItem submenu={subMenu}/>
                                                    {
                                                        subMenu[3].map((secondSubMenu, index) =>
                                                            <DropdownDesktopSubMenuItem key={index}
                                                                index={index} submenu={secondSubMenu}/>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </Paper>
                            </Grow>
                        </Popper>
                    </Manager>
                </div> */}
            </div>
        );
    }
}

NavigationDesktopUnit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavigationDesktopUnit);