/**
* Order Success Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import ReactToPrint from 'react-to-print';
import dateformat from 'dateformat';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
// Redux related imports
import { connect } from 'react-redux';
import OrderSummary from './OrderSummary';
import PageTitle from '../../../app/components/common/PageTitle';
import {Link,withRouter} from 'react-router-dom';
import { Print } from '@material-ui/icons';
import CartService from '../../../app/modules/cart/CartService';
import GetLanguage from '../../../library/GetLanguage';
import { formatPriceOfProductforCategoryListing } from '../../../app/framework/common/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import correct from '../../../assets/images/correct.png';
import printer from '../../../assets/images/printer.png';
import dollar from '../../../assets/images/dollarsign.png';
import dollargrey from '../../../assets/images/dollarsigngrey.png';

const styles = theme => ({

    orderConfirm: {
        margin: '20px',
    },
    orderConfirmButtons: {
        width: '200px',
    },
    orderdetailmain: {
        // padding: '15px'
    },
    leftorder: {
        // backgroundColor: '#e6f4fd',
        // border: '1px solid #9ed4fd',
        // borderRadius: '3px',
        marginBottom: '5px',
    },
    orderthankyou: {
        fontSize: '14px',
        color: '#424242',
        padding: '10px 0px',
        fontWeight:'bold',
    },
    orderdetail: {
        padding: '0px 10px 10px 50px',
        color: '#515152',
        fontSize: '14px',
        lineHeight: '20px',
    },
    productimage: {
        height: '80px',
        width: '80px'
    },
    nameofcustomer: {
        fontSize: '13px',
        color: '#424242',
        fontWeight: 'normal',
        textTransform:'uppercase',
        padding: '10px 0'
    },
    addressofcustomer: {
        color: '#424242',
        fontSize: '13px',
        padding: '0'
    },
    productblock: {
        border: '1px solid #cccccc'
    },
    productheader: {
        padding: '10px 15px',
        border: '1px solid #cccccc',
        width: '100%',
        color: '#424242',
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        borderLeft: '0px',
        borderRight: '0px',
        borderTop: '0px'
    },
    productimagemain: {
        padding: '15px 15px'
    },
    producttextdec: {
        color: '#424242'
    },
    producttextquantity: {
        color: '#424242',
        fontWeight: 'bold'
    },
    subtotal: {
        border: '1px solid #f1f1f1',
        padding: '15px',
        borderLeft: '0px',
        borderRight: '0px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#424242'
    },
    total: {
        padding: '15px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#424242'
    },
    productheaderleft: {
        float: 'right',
        fontWeight: 'bold'
    },
    productrightmoney: {
        float: 'right',
        paddingTop: '10px',
        fontWeight: 'bold',
        color: '#777'
    },
    myOrdersButton: {
        borderRadius: '25px',
        textTransform:'uppercase',
    },
    headerFont: {
        fontSize: '14px',
        fontWeight: '600',
        color:'#1d2d5f',
    },
    centerdiv:{
        textAlign:'center',
    },
    thankstext:{
        color: '#767676',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    yourorder:{
        fontSize: '15px',
        color: '#767676'
    },
    yourordertext:{
        color: '#767676',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '5px',
        paddingBottom: '15px'
    },
    continuebuy:{
        background: '#e85c5b',
        fontWeight: '600',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    toporder:{
        marginTop: '80px'
    },
    toporderdiv:{
        color: '#767676',
        fontSize: '12px',
        fontWeight: '400'
    },
    itemsheader:{
        fontSize: '18px',
        color: '#273666'
    },
    listtoptext:{
        color: '#424242',
        fontSize: '14px',
        fontWeight: '500',
        paddingBottom: '10px'
    },
    listqty:{
        fontSize: '12px',
        paddingTop: '10px'
    },
    listpriceblue:{
        fontSize: '16px',
        color: '#1e2e5f',
        fontWeight: '500'
    },
    listpriceunit:{
        color: '#a4a0a0',
        fontSize: '11px'
    },
    wrapperlist:{
        background: '#fff',
        padding: '10px',
        border: '1px solid #dbdbdb',
        marginTop: '10px',
        borderRadius: '4.5px'
    },
    innerborder:{
        borderTop:'1px solid #d1d1d1',
        paddingTop: '10px'
    },
    imageleft:{
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    rightsidediv:{
        textAlign: 'right'
    },
    dollar:{

    },
    dollargrey:{
        width: '11px',
        paddingTop: '5px'
    },
    ordersummary:{
        fontSize: '13px',
        fontWeight: '600',
        color: '#424242',
        paddingBottom: '10px',
        borderBottom: '1px solid #e7e7e7'
    },
    netamount:{
        color: '#424242',
        fontSize: '13px',
        fontWeight: '500'
    },
    amount:{
        color: '#424242',
        fontWeight: '500',
        fontSize: '13px',
        textAlign: 'right'
    },
    ordersummarymain:{
        padding: '15px 10px',
        background: '#fff'
    },
    ordersummarywrapper:{
        paddingLeft: '10px',
        marginTop: '10px'
    },
    wrapperblock:{
        paddingTop: '10px'
    },
    discounttext:{
        fontSize:'12px'
    },
    discount:{
        fontSize:'12px',
        textAlign: 'right'
    },
    taxtext:{
        fontSize:'12px'
    },
    tax:{
        fontSize:'12px',
        textAlign: 'right'
    },
    totalpricewrapper:{
        paddingTop: '10px',
        borderTop: '1px solid #e7e7e7',
        marginTop: '10px'
    },
    totalprice:{
        fontSize: '13px',
        color: '#424242',
        fontWeight: '600'
    },
    totalpricetext:{
        fontSize: '14px',
        color: '#424242',
        fontWeight: '600',
        textAlign: 'right'
    },
    total:{
        fontSize: '14px',
        color: '#424242',
        fontWeight: '600'
    },
    wrappermain:{

    },
    wrappermaintext:{
        fontSize: '12px',
        color: '#424242',
        paddingTop: '8px'
    },
    orderinfo:{
        marginTop: '30px'
    },
    orderinfotext:{
        color: '#424242',
        fontSize: '13px',
        fontWeight: '600',
        borderBottom: '1px solid #e7e7e7',
        paddingBottom: '10px',
        marginBottom: '10px'
    },
    desc:{
        marginTop: '30px'
    },
    orderinfotextdesc:{
        fontSize: '12px',
        color: '#424242',
        lineHeight: '1.4'
    },
    orderinfotextinner:{
        fontSize: '12px',
        paddingTop: '5px',
        paddingBottom: '10px',
        color:'#424242'
    },
    alignprint:{
        textAlign:'right'
    },
    midwid:{
        width:'1280px',
        margin:'auto'
    }
});
/***************************************************************
 * Component class for Order Success.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class OrderSuccess extends React.Component {
    state={
        reorder:false
    }
    // componentWillMount() {
    //     if (!this.props.loader_status && this.props.ordernumber) {
    //         this.props.loader();
    //     }

    // }
    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.get_orders).length > 0 && !this.state.reorder) {
            const findIndex = nextprops.get_orders.items.findIndex(order => order.entity_id === this.props.ordernumber);
            if (nextprops.get_orders.items[findIndex] && nextprops.get_orders.items[findIndex].entity_id === this.props.ordernumber && nextprops.loader_status) {
                this.props.loader();
            }
        }
    }
    
    reorder(params){
        this.setState({reorder:true},()=>{
             this.props.reorderClick(this,params);
        });        
    }
    render() {
        const { ordernumber, get_orders } = this.props;
        const { classes,path } = this.props;
        const vieworder = path==='/vieworder';
        //If there is no order number, redirect to home page.
        if (!ordernumber) {
            return <Redirect push to="/" />
        }
        else if (get_orders.length === 0) {
            return (
                <div style={{ zIndex: '999999999999', top: 0, position: 'fixed', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'auto' }}>
                    {/* <CircularProgress /> */}
                </div>
            )
        } else {
            const findIndex = get_orders.items.findIndex(order => order.entity_id === ordernumber);
            
            if (!get_orders.items[findIndex])
                return null;
            let orderInfo = get_orders.items[findIndex];
           

            return (
                <React.Fragment>
                    <Grid container xs={12} direction="column">
                    {/* <Grid item xs={2}>s</Grid> */}
                    <Grid item xs={12} className={classes.midwid}>
                        <Grid item cs={12}>
                            
                            <Grid item className={classes.centerdiv}>
                                <img src={correct} alt="image" className={classes.thanksimage}/>
                                <Typography variant="h5" className={classes.thankstext}>
                                    Thanks for your Purchase!
                                </Typography>
                                <Typography variant="h6" className={classes.yourorder}>
                                    Your order # is: {orderInfo.increment_id}
                                </Typography>
                                <Typography variant="h6" className={classes.yourordertext}>
                                    You will receive an email with the confirmation of your detailed purchase.
                                </Typography>
                                <Button variant="contained" color="secondary" className={classes.continuebuy}> 
                                    <Link style={{textDecoration:'none',color:'#fff'}} to='/' >CONTINUE TO BUY</Link>
                                </Button>
                            </Grid>

                            <Grid item xs={12} container className={classes.toporder} direction="row">
                                <Grid item xs={10}>
                                    <Typography variant="h6" className={classes.toporderdiv}>
                                        Order No: 1116519-4370145-5077403  &nbsp; &nbsp; Placed On: 01 AUG 2019
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.alignprint}>
                                    {/* <img src={printer} alt="print"/> */}
                                    <ReactToPrint trigger={() => <Button color='primary'><Print color='error'/>
                                    {<GetLanguage value="Print Order"/>}</Button>} content={() => this.componentRef}/>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" className={classes.itemsheader}>
                                    Items in this order
                                </Typography>
                            </Grid>
                            
                        </Grid>
                        <div className={classes.orderdetailmain}>
                        {/* <Grid item xs={12} classes={classes.headerStyle}>
                            <Typography variant="h6" align="left" className={classes.pageHeading}>
                                <GetLanguage value="Order details" />
                            </Typography>
                        </Grid> */}
                        
                            {/* <ReactToPrint trigger={() => <Button color='primary'><Print color='error'/>
                            {<GetLanguage value="Print Order"/>}</Button>} content={() => this.componentRef}/> */}
                        <PrintComponentWithStyles vieworder={vieworder} orderInfo={orderInfo} ref={el => (this.componentRef = el)} />
                            {/* <Grid container direction='row' alignItems='center' justify='center'>
                                <Grid item style={{paddingTop:'6px'}}>
                                <Button onClick={()=>this.reorder(orderInfo.items)} style={{marginRight:'10px'}}  
                                    variant="contained" color="primary" className={classes.myOrdersButton}
                                    > Reorder</Button>
                                    <Link style={{textDecoration:'none'}} to={{ pathname: '/myaccount', state: { submenu:3 } }} >
                                    <Button variant="contained" color="secondary" className={classes.myOrdersButton}> {<GetLanguage value="My Orders"/>}</Button></Link>
                                    
                                </Grid>
                            </Grid> */}

                        </div>
                    </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}

class PrintComponent extends React.Component{
    render(){
        const {vieworder,orderInfo,classes} =this.props;
        let storeName =  orderInfo.extension_attributes.company_order_attributes.company_name;
        console.log("!!!!!!!!!!!!",this)
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} direction="row" container>
                        <Grid item xs={8} style={{paddingRight:'5px'}}>
                        {orderInfo.items.map((data,index)=>
                            <Grid key={index} item xs={12} direction="row" container className={classes.wrapperlist}>
                                <Grid items xs={2} className={classes.imageleft}>
                                    <img src={printer} alt="image"/>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h5" className={classes.listtoptext}>
                                        {data.name}
                                    </Typography>
                                    <Grid item container direction="row" className={classes.innerborder}>
                                        <Grid xs={6}>
                                            <Typography variant="h5" className={classes.listqty}>
                                                {data.qty_ordered} Qty.
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} className={classes.rightsidediv}>
                                            <Grid container direction="column" xs={12}>
                                                <Typography variant="h5" className={classes.listpriceblue}>
                                                    {formatPriceOfProductforCategoryListing(data.price * data.qty_ordered)}
                                                </Typography>
                                                <Typography variant="h5" className={classes.listpriceunit}>
                                                    {formatPriceOfProductforCategoryListing(data.price)}/unit
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                            
                        </Grid>
                        <Grid item xs={4} className={classes.ordersummarywrapper}>
                            <Grid item xs={12} className={classes.ordersummarymain}>
                                <Typography variant="h5" className={classes.ordersummary}>
                                    ORDER SUMMARY
                                </Typography>
                                <Grid item xs={12} direction="row" container className={classes.wrapperblock}> 
                                    <Grid item xs={8} className={classes.netamount}>Net Amount</Grid>
                                    <Grid item xs={4} className={classes.amount}>{formatPriceOfProductforCategoryListing(orderInfo.base_subtotal)}</Grid>
                                </Grid>
                                {/* <Grid item xs={12} direction="row" container className={classes.wrapperblock}> 
                                    <Grid item xs={8} className={classes.discounttext}>Discount</Grid>
                                    <Grid item xs={4} className={classes.discount}>12.12</Grid>
                                </Grid> */}
                                <Grid item xs={12} direction="row" container className={classes.wrapperblock}> 
                                    <Grid item xs={8} className={classes.taxtext}>Tax</Grid>
                                    <Grid item xs={4} className={classes.tax}>{formatPriceOfProductforCategoryListing(orderInfo.base_tax_amount)}</Grid>
                                </Grid>
                                <Grid item xs={12} direction="row" container className={classes.totalpricewrapper}> 
                                    <Grid item xs={8} className={classes.totalprice}>Total Price</Grid>
                                    <Grid item xs={4} className={classes.totalpricetext}>{formatPriceOfProductforCategoryListing(orderInfo.grand_total)}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.orderinfo}>
                                <Typography variant="h5" className={classes.orderinfotext}>
                                    Shipping Address
                                </Typography>
                                <Grid item xs={12} direction="column" container className={classes.wrappermain}>
                                    <Typography variant="h5" className={classes.wrappermaintext}>
                                        {orderInfo.customer_firstname} {orderInfo.customer_lastname}
                                    </Typography>
                                    <Typography variant="h5" className={classes.wrappermaintext}>
                                        {orderInfo.billing_address.street[0]}
                                    </Typography>
                                    <Typography variant="h5" className={classes.wrappermaintext}>
                                        {orderInfo.billing_address.street[1]}
                                    </Typography>
                                    <Typography variant="h5" className={classes.wrappermaintext}>
                                        {orderInfo.billing_address.city} {orderInfo.billing_address.postcode}
                                    </Typography>
                                    <Typography variant="h5" className={classes.wrappermaintext}>
                                        Tel: {orderInfo.billing_address.telephone}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} className={classes.desc}>
                                <Typography variant="h5" className={classes.orderinfotext}>
                                    METODOS DE ENVIO:
                                </Typography>
                                <Grid item xs={12} direction="column" container className={classes.descmain}>
                                    <Typography variant="h5" className={classes.orderinfotextinner}>
                                        ENVIO: STANDARD
                                    </Typography>
                                    <Typography variant="h5" className={classes.orderinfotextdesc}>
                                        Ssdsdfskjdf s skdhk shdfuhsd fs sdkfh sdhs fdk hsf hds fk s dfsdfsfd
                                    </Typography>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12} md={6} className={classes.leftorder}>
                        <Typography className={classes.orderthankyou}>{vieworder ? 'Order No: #' + orderInfo.increment_id : 'Thank you. Your order has been received.'}</Typography>
                    
                        <div>
                            {!vieworder ?
                                
                                    <Typography>{"Order number:"} {orderInfo.increment_id}</Typography>
                              
                                :
                                ''}
                          
                                <Typography>{"Store Name:"}{storeName}</Typography> */}
                           
                            {/* <li>
                                <Typography>{"Store Code:"}{orderInfo.extension_attributes.elmt_store_code}</Typography>
                            </li> */}
                           
                                {/* <Typography>{"Date:"} {orderInfo.created_at}</Typography>
                           
                           
                                <Typography>{"Total:"}{formatPriceOfProductforCategoryListing(orderInfo.base_grand_total)}</Typography>
                         

                            
                                <Typography> */}
                                    {/* {<GetLanguage value="Payment method: Cash on delivery"/>} */}
                                    {/* {orderInfo.status}
                                </Typography>
                            
                        </div>
                    </Grid> */}

                    {/* <Grid item xs={12} md={6} className={classes.leftorder}>
                        <Typography className={classes.orderthankyou}>{<GetLanguage value="Shipping Address" />}</Typography>
                        <Typography className={classes.nameofcustomer}>{orderInfo.customer_firstname} {orderInfo.customer_lastname}</Typography>
                        {orderInfo.billing_address &&
                            <div>
                                <Typography className={classes.addressofcustomer}>{orderInfo.billing_address.street[0]}</Typography>
                                <Typography className={classes.addressofcustomer}>{orderInfo.billing_address.street[1]}</Typography>
                                <Typography className={classes.addressofcustomer}>{orderInfo.billing_address.city} {orderInfo.billing_address.postcode}</Typography>
                                <Typography className={classes.addressofcustomer}>{orderInfo.billing_address.telephone}</Typography>
                            </div>
                        } */}
                        {/* <ul> */}
                            {/* {orderInfo.extension_attributes.ecc_order_number && orderInfo.extension_attributes.ecc_order_number != "" &&
                                <li>
                                    <Typography>U2K2 Order No: #{orderInfo.extension_attributes.ecc_order_number}</Typography>
                                </li>
                            } */}
                            {/* {orderInfo.extension_attributes.ecc_creation_date && orderInfo.extension_attributes.ecc_creation_date != "" &&
                                <li>
                                    <Typography>U2K2 Order Creation:{orderInfo.extension_attributes.ecc_creation_date}</Typography>
                                </li>
                            }
                            {orderInfo.extension_attributes.ecc_order_total && orderInfo.extension_attributes.ecc_order_total != "" &&
                                <li>
                                    <Typography>U2K2 Order Total:{parseInt(orderInfo.extension_attributes.ecc_order_total)}</Typography>
                                </li>
                            } */}
                            {/* {orderInfo.extension_attributes.utwoktwo_customer_id && orderInfo.extension_attributes.utwoktwo_customer_id !== "" &&
                                <li>
                                    <Typography>Customer ID:{orderInfo.extension_attributes.utwoktwo_customer_id}</Typography>
                                </li>
                            }
                            {orderInfo.extension_attributes.utwoktwo_address_id && orderInfo.extension_attributes.utwoktwo_address_id !== "" &&
                                <li>
                                    <Typography>Address ID:{orderInfo.extension_attributes.utwoktwo_address_id}</Typography>
                                </li>
                            }
                             {orderInfo.payment.po_number && orderInfo.payment.po_number !== "" &&
                                <li>
                                    <Typography>PO Number:{orderInfo.payment.po_number}</Typography>
                                </li>
                            }
                        </ul> */}
                    {/* </Grid> */}

                </Grid>
                {/* <OrderSummary OrderConfirmation={true} order_summary_from_confirmation={orderInfo} /> */}
            </div>
        )
    }
}
const PrintComponentWithStyles=withStyles(styles)(PrintComponent);
const mapStateToProps = state => ({
    get_orders: state.get_orders,
    loader_status: state.get_loader_status
});
const mapDispatchToProps = dispatch => ({
    loader: () => {
        dispatch({ type: 'SET_LOADER_STATUS' });
    },
    reorderClick: (component, params) => {
        const cs = new CartService();
        dispatch(cs.BulkUpload(params,component));
    },
});


const OrderSuccessWithStyles = withStyles(styles)(OrderSuccess);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderSuccessWithStyles));
