/**
* Common class with functions 
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Nirmal <nirmal.gandhi@embitel.com>
* @copyright 2019-2020 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    SET_LOADER_STATUS
} from '../../framework/common/ReduxConstants';


/**********************************************************************************
 * Order Service class which handles Order History
 *********************************************************************************/
class CommonService {

    static get_loader_status = (state = false, action) => {
        switch (action.type) {
            case SET_LOADER_STATUS:
                return !state;
            default:
                return state;
        }
    };
}

export default CommonService;