/**
* SalesMan Info Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PersonOutline from '@material-ui/icons/PersonOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CartService from '../../../../app/modules/cart/CartService';
import AccountService from '../../../../app/modules/account/AccountService';
import { Hidden } from '@material-ui/core';
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
const styles = theme => ({
    buttonText: {
        color: '#000000de',
        fontSize: '14px',
        padding: '8px 2px 8px 5px',
    },
    headerBarStylesDesktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mdDeviceUpDisplayNone: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuText: {
        color: '#000',
        fontSize: '12px',
        fontWeight: '500',
        height: '15px'
    },
    iconSize: {
        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
        },
    }
});
class SalesManInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            salesManSignedOut: false,
            selectedCustomer: '',
            salesmanName:'',
        }
    }

    componentDidMount() {
        
        let salesmanUser = getItemFromLocalStorage('sales-man');

        if(salesmanUser) 
        {
            this.setState({ salesmanName : salesmanUser.firstname + ' ' + salesmanUser.lastname });
        }
        // else
        // {
        //     this.setState({ salesmanName :SalesmanExist.salesmanName});
        //    // salesmanName=customerInfo.salesmanName;
        // }
    
 
      }

    //handles submenu show/hide
    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };
    //handles closing of menu
    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    /***************************************************************************** 
     * Handle SignOut process.
     * Clear localStorage
     *****************************************************************************/
    salesManSignout = () => {
        let SalesManExist = getItemFromLocalStorage('sales-man');
        if (SalesManExist) {
            //reset signed in state
            // this.props.resetSignedInUserSuccess();
            // this.props.resetSalesManSignIn();
            //Clear browser storage on User SignOut.
            AccountService.clearOnSignOut();   
            //I was forced to do reload because of many things         
            window.location.reload();
        }
    }

    render() {
        const { open } = this.state;
        const { classes,salesmanUser } = this.props;
    

        return (
            <div>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <IconButton buttonRef={node => { this.anchorEl = node; }} aria-haspopup="true"
                        className={classes.buttonText} onClick={this.handleToggle}
                        aria-owns={open ? 'menu-list-grow' : null}>
                        <PersonOutline color="primary" className={classes.iconSize} />
                        <Hidden only={['sm', 'xs']}> <span style={{ fontSize: '14px' }}> {salesmanUser && salesmanUser.firstname+ ' '+ salesmanUser.lastname}</span></Hidden>
                        {open ? <ArrowDropUpIcon color="primary" className={classes.iconSize} /> : <ArrowDropDownIcon color="primary" className={classes.iconSize} />}
                    </IconButton>
                </ClickAwayListener>
                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{ zIndex: '1000' }}>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <MenuItem className={classes.menuText} to={{ pathname: '/salesmanaccount', state: { submenu:0 }  }}
                                            onClick={this.handleClose} component={Link} >My Account</MenuItem>

                                            
                                        <MenuItem className={classes.menuText} onClick={this.salesManSignout}>Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        )
    }    
}

const mapStateToProps = state => ({
    customer_list: state.getSalesManDetails ? state.getSalesManDetails[0] : '',
    get_user_details: state.get_user_details_state,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
});
const mapDispatchToProps = dispatch => ({
    getCustomerInfo: (users) => {
        let accountService = new AccountService();
        dispatch(accountService.GetUserDetailsActionCreator(users));
    },
    resetSignedInUserSuccess: () => {
        let as = new AccountService();
        dispatch(as.resetSignedInActionCreator());
    },
    refreshMinicart: () => {
        let cart = new CartService();
        dispatch(cart.GetDisplayMiniCartActionCreator());
    },
    resetSalesManSignIn: () => {
        let as = new AccountService();
        dispatch(as.resetSalesManSignInActionCreator());
    },
});
const SalesManInfoWithStyles = withStyles(styles)(SalesManInfo);
export default connect(mapStateToProps, mapDispatchToProps)(SalesManInfoWithStyles);