/**
* Copyright block.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    cardInFooter: {
        backgroundColor: '#d0d0d0',
    },
});


/***************************************************************************** 
 * Footer bar with Copyright Notice.
 * @author Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
class CopyRightBar extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.cardInFooter}>
                <CardContent>
                    <Typography align="center" variant="caption">(c) 2018-19 Embitel Technologies (I) Pvt Ltd. All rights reserved.
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(CopyRightBar);