/**
* WishlistService class with functions for handling Wishlist related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_PUT_ITEM_IN_WISHLIST,
    RECEIVE_PUT_ITEM_IN_WISHLIST,
    REQUEST_GET_WISHLISTED_PRODUCTS,
    RECEIVE_GET_WISHLISTED_PRODUCTS,
    REQUEST_DELETE_ITEM_IN_WISHLIST,
    RECEIVE_DELETE_ITEM_IN_WISHLIST,
    REQUEST_RESET_PUT_WISHLIST,
    REQUEST_RESET_DELETE_WISHLIST,
    SET_LOADER_STATUS

} from '../../framework/common/ReduxConstants';
import { NotificationManager } from 'react-notifications';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
import { removeItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';

/****************************************************************
 * Wishlist Service class which handles wishlist functionalities.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class WishlistService {
    GetWishlistedProductsActionCreator(message, showloader) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_WISHLISTED_PRODUCTS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/wishlist';
                let requestType = 'GET';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        message && NotificationManager[showloader](message);
                        showloader && dispatch({ type: SET_LOADER_STATUS });;
                        dispatch({ type: RECEIVE_GET_WISHLISTED_PRODUCTS, success: true, response });
                    },
                    (error) => {
                        showloader && dispatch({ type: SET_LOADER_STATUS });;
                        dispatch({ type: RECEIVE_GET_WISHLISTED_PRODUCTS, success: false, error });
                    }
                );
            }
        }
    }

    /***************************************************************************** 
    * Helper function for reducer WishlistedProductsReducer
    *****************************************************************************/
    static applyWishlistedProducts = (state, action) => {
        let wishlisted_products = [];
        //Check if Cart Data is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            wishlisted_products = action.response.data;
            // wishlisted_products.items.map(item=>{
            //     const newItem =[];
            //     newItem.push(item.product)
            // })
            //CategoryService.reSturctureProductObject()

        }
        else {
            wishlisted_products = [];
        }
        return wishlisted_products;
    }

    /***************************************************************************** 
     * Redux Reducer to get wishlisted products
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     *****************************************************************************/
    static WishlistedProductsReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_GET_WISHLISTED_PRODUCTS:
                return { ...state };
            case RECEIVE_GET_WISHLISTED_PRODUCTS:
                let retValue = WishlistService.applyWishlistedProducts(state, action);
                return retValue;
            default:
                return state;
        }
    };

    //Action Creator to wishlist item
    WishlistProductActionCreator(sku) {

        return (dispatch) => {
            dispatch({ type: REQUEST_PUT_ITEM_IN_WISHLIST });
            dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/wishlist';
                let requestType = 'PUT';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.data = {
                    "sku": sku,
                }
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        removeItemFromLocalStorage('fav-product');
                        dispatch({ type: RECEIVE_PUT_ITEM_IN_WISHLIST, success: true, response });

                        const wishlist = new WishlistService();
                        dispatch(wishlist.GetWishlistedProductsActionCreator('Product has been added to your wishlist', 'success'));
                        // NotificationManager.success();
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_PUT_ITEM_IN_WISHLIST, success: false, error });
                        NotificationManager.error('Error while adding product to wishlist');
                    }
                );
            }
        };
    }

    /***************************************************************************** 
     * Helper function for reducer WishlistProductReducer
     *****************************************************************************/
    static applyWishlistProduct = (state, action) => {
        if (action && action.response && ("data" in action.response)) {
            let returnObjectSuccess = {
                wishlistSuccess: action.response.data[0] ? action.response.data[0].status : '',
                item_id: action.response.data[0] ? action.response.data[0].item_id : '',
            };
            return Object.assign({}, returnObjectSuccess, {
                item_id: action.response.data[0] ? action.response.data[0].item_id : '',
                wishlistSuccess: true, isFavButtonDisabled: false
            });
        }
        else {
            let genericError = 'Error, Please try again after some time'
            let returnObjectError = {
                wishlistSuccess: false,
                errorMessage: action.error.data ? action.error.data.message : genericError,
            };
            return Object.assign({}, returnObjectError, { wishlistSuccess: false, isFavButtonDisabled: false });
        }
    }

    /***************************************************************************** 
     * Redux Reducer for wishlisting products
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     *****************************************************************************/
    static WishlistProductReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_PUT_ITEM_IN_WISHLIST:
                return { ...state, wishlistSuccess: null, isFavButtonDisabled: true };
            case RECEIVE_PUT_ITEM_IN_WISHLIST:
                let retValue = WishlistService.applyWishlistProduct(state, action);
                return retValue;
            case REQUEST_RESET_PUT_WISHLIST:
                return { ...state, wishlistSuccess: null, isFavButtonDisabled: false };
            default:
                return state;
        }
    };

    //Action Creator to delete item in wishlist
    DeleteWishlistProductActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_DELETE_ITEM_IN_WISHLIST });
            dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/wishlist/' + id;
                let requestType = 'DELETE';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        const wishlist = new WishlistService();
                        dispatch(wishlist.GetWishlistedProductsActionCreator('Product has been deleted from your wishlist', 'info'));
                        dispatch({ type: RECEIVE_DELETE_ITEM_IN_WISHLIST, success: true, response });
                      //  NotificationManager.info('Product has been deleted from wishlist');

                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DELETE_ITEM_IN_WISHLIST, success: false, error });
                        NotificationManager.error('Error while deleting product in wishlist');
                    }
                );
            }
        };
    }

    /***************************************************************************** 
     * Helper function for reducer DeleteWishlistedProductReducer
     *****************************************************************************/
    static applyDeleteWishlistedProduct = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                deleteWishlistItemSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { deleteWishlistItemSuccess: true, isFavButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time'
            let returnObjectError = {
                deleteWishlistItemSuccess: false,
                errorMessage: action.error.data ? action.error.data.message : genericError,
            };
            return Object.assign({}, returnObjectError, { deleteWishlistItemSuccess: false, isFavButtonDisabled: false });
        }
    }

    /***************************************************************************** 
     * Redux Reducer to delete wishlisted products
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     *****************************************************************************/
    static DeleteWishlistedProductReducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_DELETE_ITEM_IN_WISHLIST:
                return { ...state, deleteWishlistItemSuccess: null, isFavButtonDisabled: true };
            case RECEIVE_DELETE_ITEM_IN_WISHLIST:
                let retValue = WishlistService.applyDeleteWishlistedProduct(state, action);
                return retValue;
            case REQUEST_RESET_DELETE_WISHLIST:
                return { ...state, deleteWishlistItemSuccess: null, isFavButtonDisabled: false };
            default:
                return state;
        }
    };

    //Action Creator to reset put wishlist
    ResetPutWishlistActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_PUT_WISHLIST });
        };
    }

    //Action Creator to reset delete wishlist
    ResetDeleteWishlistActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_RESET_DELETE_WISHLIST });
        };
    }

    //Action Creator to delete item in wishlist from Wishlist Page
    removeAndRefreshWishListActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_DELETE_ITEM_IN_WISHLIST });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/wishlist/' + id;
                let requestType = 'DELETE';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECEIVE_DELETE_ITEM_IN_WISHLIST, success: true, response });
                        //Update the Wishlist after deleting item from the user wishlist.
                        let wishlistService = new WishlistService();
                        dispatch(wishlistService.GetWishlistedProductsActionCreator());
                    },
                    (error) => {
                        dispatch({ type: RECEIVE_DELETE_ITEM_IN_WISHLIST, success: false, error });
                    }
                );
            }
        };
    }
}

export default WishlistService;