/**
* SignUpForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PermIdentity from '@material-ui/icons/PermIdentity';
import EmailRounded from '@material-ui/icons/EmailRounded';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import UserMessage from '../../../../app/components/messages/UserMessages';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import AccountService from '../../../../app/modules/account/AccountService';
import { connect } from 'react-redux';
import GetLanguage from '../../../../library/GetLanguage';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    }
});

/***************************************************************
 * Component class for displaying Sign Up Form.
 * On submit user can able to create a new account
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class SignUpForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            signUpEmail: "",
            signUpPassword: "",
            signUpConfirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            //  passwordValidation:{
            upperCase: false,
            lowerCase: false,
            specialCharacter: false,
            number: false,
            length: false,
            confirmPasswordValue: false,
            confirmClicked: false,
        };
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }
    //updates email and password fields with provided values
    handleChange = prop => event => {
        if (prop === 'signUpPassword') {
            var regexUpper = /^.*[A-Z].*/;
            var regexlower = /^.*[a-z].*/;
            var regexNumber = /^.*[1-9].*/;
            var specialCharacter = /^.*[^`!@#$%^&*\-_=+'\/.,]*[`!@#$%^&*\-_=+'\/.,].*/;
            var regexLength = /^.{8,}$/;

            var info = event.target.value.match(regexUpper);

            if (event.target.value.match(regexUpper) === null) {
                this.setState({ upperCase: true });
            }
            else {
                this.setState({ upperCase: false });
            }
            if (event.target.value.match(regexlower) === null) {
                this.setState({ lowerCase: true });
            }
            else { this.setState({ lowerCase: false }); }
            if (event.target.value.match(regexNumber) === null) {
                this.setState({ number: true });
            }
            else { this.setState({ number: false }); }
            if (event.target.value.match(specialCharacter) === null) {
                this.setState({ specialCharacter: true });
            }
            else { this.setState({ specialCharacter: false }); }
            if (event.target.value.match(regexLength) === null) {
                this.setState({ length: true });
            }
            else { this.setState({ length: false }); }
        }
        this.setState({ confirmClicked: false });
        this.setState({ [prop]: event.target.value });
    };
    //hide/show text in password, confirm password field
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    //hide/show text in password field
    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };
    //call dispatch function if email and password fields are not empty
    handleSignUp = (event) => {
        event.preventDefault();
        //Dispatch action to Register(Sign-Up) the user.
        const {
            firstName,
            lastName,
            signUpEmail,
            signUpPassword,
            signUpConfirmPassword,
        } = this.state;

        if ((firstName !== "") && (lastName !== "") && (signUpEmail !== "")
            && (signUpPassword !== "") && (signUpConfirmPassword !== "")) {
            if (!this.state.upperCase && !this.state.lowerCase && !this.state.specialCharacter && !this.state.number && !this.state.length) {
                if (signUpPassword !== signUpConfirmPassword) {
                    return this.setState({ confirmPasswordValue: true });
                }
                else {
                    this.setState({ confirmPasswordValue: false });
                }
                //Dispatch action
                this.props.onSubmitSignUpDispatch(
                    firstName,
                    lastName,
                    signUpEmail,
                    signUpPassword, 
                    signUpConfirmPassword,
                );
            }
        }
        this.setState({ confirmClicked: true, signUpConfirmPassword: '' });

    };

    // Initialize signedUpUserSuccess to null before the form loads.
    componentWillMount() {
        this.props.initSignedUpUserSuccess();
    }

    render() {
        const { classes } = this.props;
        const { signedUpUserSuccess, isSignUpButtonDisabled } = this.props;

        //If User Sign In is success, Redirect to home page
        if (signedUpUserSuccess) {
            return <Redirect push to="/" />;
        }
        //If User Sign In is not success, stay in same page
        if (signedUpUserSuccess === false) {
            const object = { messageType: 'error', message: this.props.signedUpUserError }
            var signUpErrorMessage = (signedUpUserSuccess !== null) ? <UserMessage {...object} /> : null;
        }

        return (
            <React.Fragment>
                <PageTitle title={<GetLanguage value="Sign Up" />} />
                {signUpErrorMessage}
                <Grid container alignItems="center" justify="center">
                    <Grid item xs sm={8} md={5} lg={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleSignUp}>
                                    <Grid container spacing={16} direction="column">
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-firstName">{<GetLanguage value="First Name" />}</InputLabel>
                                                <Input id="adornment-firstName" type='text' value={this.state.firstName}
                                                    onChange={this.handleChange('firstName')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><PermIdentity /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-lastName">{<GetLanguage value="Last Name" />}</InputLabel>
                                                <Input id="adornment-lastName" type='text' value={this.state.lastName}
                                                    onChange={this.handleChange('lastName')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><PermIdentity /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item >
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-signUpEmail">{<GetLanguage value="Email" />}</InputLabel>
                                                <Input id="adornment-signUpEmail" type='text' value={this.state.signUpEmail}
                                                    onChange={this.handleChange('signUpEmail')} required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple style={{ cursor: 'default' }}><EmailRounded /></IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item >
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-signUpPassword">{<GetLanguage value="Password" />}</InputLabel>
                                                <Input id="adornment-signUpPassword" type={this.state.showPassword ? 'text' : 'password'}
                                                    value={this.state.signUpPassword} onChange={this.handleChange('signUpPassword')}
                                                    required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.state.upperCase && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Uppercase is required
                                        </Typography>
                                            </Grid>
                                        }
                                        {this.state.lowerCase && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Lowercase is required
                                        </Typography>
                                            </Grid>
                                        }
                                        {this.state.number && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Number is required
                                                 </Typography>
                                            </Grid>
                                        }
                                        {this.state.specialCharacter && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Special character is required
                                        </Typography>
                                            </Grid>
                                        }
                                        {this.state.length && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    Password is minimum of 8 characters
                                        </Typography>
                                            </Grid>
                                        }
                                        <Grid item >
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-signUpConfirmPassword">{<GetLanguage value="Confirm Password" />}</InputLabel>
                                                <Input id="adornment-signUpConfirmPassword" type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                    value={this.state.signUpConfirmPassword} onChange={this.handleChange('signUpConfirmPassword')}
                                                    required={true} endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton disableRipple aria-label="Toggle password visibility" onClick={this.handleClickShowConfirmPassword}>
                                                                {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.state.confirmPasswordValue &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    Password and confirm Password must match
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item >
                                            <FormControl margin="normal" required fullWidth>
                                                <Fab size="large" aria-label="Sign Up" variant="extended" color="primary"
                                                    onClick={() => { this.scrollToTop(); }} disabled={isSignUpButtonDisabled} type="submit">{<GetLanguage value="CREATE AN ACCOUNT" />}</Fab>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <Typography align="center" gutterBottom>
                            <span>{<GetLanguage value="Already have an account?" />}
                                <Link to='/account' style={{ textDecoration: 'none', color: '#2196f3' }}>{<GetLanguage value="  Sign In" />}</Link>
                            </span>
                        </Typography>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
    componentWillUnmount() {
        this.props.resetSignedUpUserSuccess();
    }
}

const mapStateToProps = state => ({
    signedUpUserSuccess: state.post_sign_up_customer_state.signUpSuccess,
    signedUpUserError: state.post_sign_up_customer_state.errorMessage ? state.post_sign_up_customer_state.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    onSubmitSignUpDispatch: (
        firstName,
        lastName,
        signUpEmail,
        signUpPassword,
        signUpConfirmPassword,
    ) => {
        var SignUpObject = {
            "customer": {
                "email": signUpEmail,
                "firstname": firstName,
                "lastname": lastName,
            },
            "password": signUpPassword
        };
        let as = new AccountService();
        dispatch(as.signUpUserActionCreator(SignUpObject));
    },
    resetSignedUpUserSuccess: () => {
        let as = new AccountService();
        dispatch(as.resetSignedUpActionCreator());
    },
    initSignedUpUserSuccess: () => {
        let as = new AccountService();
        dispatch(as.initSignedUpActionCreator());
    }
}
);

const SignUpFormWithStyles = withStyles(styles)(SignUpForm);
export default connect(mapStateToProps, mapDispatchToProps)(SignUpFormWithStyles);