/**
* Page Title Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

const styles = theme => ({
    cardInTitle: {
        border: 'none',
        padding: '0.5rem'
    }
});

/***************************************************************
  * Component class for displaying Page Title
  * @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class PageTitle extends React.Component {
    render() {
        const { classes } = this.props;
        const { title } = this.props;
        return (
            <Card className={classes.cardInTitle}>
                    <Typography variant="h6" align="center">
                        {title}
                    </Typography>
            </Card>
        )
    }
}


export default withStyles(styles)(PageTitle);