/**
* Component for displaying newsletter subscrptions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../../../app/components/common/PageTitle';
import { withRouter } from "react-router";
//Redux related imports
import { connect } from 'react-redux';
import AccountService from '../../../app/modules/account/AccountService';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import UserMessage from '../../../app/components/messages/UserMessages';
import GetLanguage from '../../../library/GetLanguage';
const styles = theme => ({
});

/****************************************************************************** 
 * Class Component for newsletter subscriptions
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
class SubscribeNewsletter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubscribed: false,
        }
    }

    //updates newsletter subcription
    handleChange = prop => event => {
        //set state of isSubscribed
        this.setState({ [prop]: event.target.checked });
        //call api to set subscription status
        this.props.isUserSubscribed(event.target.checked);
    };

    componentDidMount() {
        /***************************************************************************** 
        * Check if the user is authenticated. 
        * If yes, display subscribe newsletter page.
        * If not, redirect to Sign-In page and once
        * signed-in, redirect to subscribe newsletter page.
        *****************************************************************************/
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        }
        this.props.signInUserStatus();
    }

    componentWillReceiveProps(nextProps) {
        if(typeof nextProps.signInCustomerDetails !== 'undefined' &&
            typeof nextProps.signInCustomerDetails.extension_attributes !== 'undefined' ) {

            this.setState(
                {isSubscribed:nextProps.signInCustomerDetails.extension_attributes.is_subscribed? true: false});
        }

        if(typeof nextProps.subcriptionStatus !== 'undefined' && nextProps.subcriptionStatus != null) {

            this.setState(
                {isSubscribed:nextProps.subcriptionStatus? true: false});
        }

    }

    render() {
        const { subcriptionStatus, subcriptionError, isSubscribeButtonDisabled} = this.props;
        var subscriptionStatusMsg;
        if(subcriptionStatus === true) {
            const object = { messageType: 'sucess', message: 'You are subscribed!', autoHideDuration: 10000 }
            subscriptionStatusMsg = <UserMessage {...object} />
        }
        if(subcriptionStatus === false) {
            const object = { messageType: 'sucess', message: 'You are unsubscribed!', autoHideDuration: 10000 }
            subscriptionStatusMsg = <UserMessage {...object} />
        }
        if (subcriptionError) {
            const object = { messageType: 'error', message: subcriptionError, autoHideDuration: 10000}
            subscriptionStatusMsg = <UserMessage {...object} />
        }

        return (
            <React.Fragment>
                {subscriptionStatusMsg}
                <PageTitle title={<GetLanguage value='Newsletter'/>} />
                <Grid container spacing={16}>
                    <Grid item >
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked = {this.state.isSubscribed}
                                        onChange = {this.handleChange('isSubscribed')}
                                        value="isSubscribed"
                                        color="primary"
                                        disabled={isSubscribeButtonDisabled}
                                    />
                                }
                                label={<GetLanguage value="Subscribe to Newsletter"/>}
                                labelPlacement={<GetLanguage value="start"/>}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    componentWillUnmount() {
        this.props.resetSubscription();
    }
}

const mapStateToProps = state => ({
    subcriptionStatus: state.subscriptionState.isSubscribed,
    isSubscribeButtonDisabled: state.subscriptionState.isSubscribeButtonDisabled,
    subcriptionError: state.subscriptionState.errorMessage,
    signInCustomerDetails: state.signedInUserInfoState,
});


const mapDispatchToProps = dispatch => ({
    isUserSubscribed: (value) => {
        let accountService = new AccountService();
        dispatch(accountService.isUserSubscribedActionCreator(value));
    },
    signInUserStatus: () => {
        let accountService = new AccountService();
        dispatch(accountService.getSignedInUserDataActionCreator());
    },
    resetSubscription: () => {
        let accountService = new AccountService();
        dispatch(accountService.resetSubscriptionActionCreator());
    }
});

SubscribeNewsletter.propTypes = {
    classes: PropTypes.object.isRequired,
};

const SubscribeNewsletterWithStyles = withStyles(styles)(SubscribeNewsletter);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscribeNewsletterWithStyles));