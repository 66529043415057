/**
* Sample Use for Global template (with Header, Footer and Navigation Bar)
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Global from './Global';
import Headerbar from '../../../../app/components/layouts/headerbar/HeaderBar';
import Footerbar from '../../../../app/components/layouts/footerbar/FooterBar'; 

import NavigationMenuDesktop from '../../../../app/components/navigation/navigationdesktop/NavigationDesktop';

const styles = theme => ({
    mainComponentStyle: {
        width: '100%',
        height: 200,
        background: '#aaa',
        float: 'left',
    },
});

/***************************************************************************** 
 * This component uses Tempalte with Header and Footer.  
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class TemplateUsageGlobal extends Component {
    render() {
        const { classes } = this.props;
        // Sample Components for display of Template Usage.
        const MainComponent = props => (
            <div className={classes.mainComponentStyle}>
            </div>
        );
        return (
            <div>   
                <Global
                    HeaderComponent={Headerbar}
                    NavigationComponent={NavigationMenuDesktop}
                    blocks={[MainComponent]}
                    FooterComponent={Footerbar}
                />
            </div> 
        );
    }
}

export default withStyles(styles)(TemplateUsageGlobal);

