/**
* Component for displaying One column of Desktop Menu.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Manager, Target, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TopLevelDesktopMenu from './TopLevelDesktopMenu';
import {DropdownDesktopMenuItem, DropdownDesktopSubMenuItem} from './DropdownDesktopMenuItem';
//Styles for Navigation menu Links
import './Navigation.css';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    },
    popperClose: {
        pointerEvents: 'none',
    },
    menuBarColor: {
        backgroundColor: '#F0F0F0',
    },
    topMenuStyle: {
        border: '2px solid transparent',
        '&:hover': {
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#2196f3',
        },
    },
    menuStyle: {
        width: 'auto',
        height: 'auto',
        backgroundColor: '#ccc',
    },
    menuRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        '&:after': {
            content: "",
            clear: 'both',
        },
    },
    menuColumn: {
        padding: '20px',
        backgroundColor: '#FFFFFF',
        height: 'auto'
    },
});

/***************************************************************
 * Component class for displaying Category Menu.
 * Receives data from Parent Component in the form of props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class NavigationDesktopUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    handleToggle = (event) => {
        this.setState((prevState) => ({
            open: !prevState.open
        }));
    };

    handleClose = (event) => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        const topmenu = this.props.topmenu;
        const submenu = this.props.submenu;
        return (
            <div className={classes.root}>
                <div onMouseEnter={this.handleToggle} onMouseLeave={this.handleClose} style={{ zIndex: 999 }}>
                    <Manager>
                        <Target>
                            <TopLevelDesktopMenu
                                topmenu={topmenu}
                            />
                        </Target>
                        <Popper
                            placement="bottom-start"
                            eventsEnabled={open}
                            positionFixed={true}
                            modifiers={{ preventOverflow: { enabled: true } }}
                            className={classNames({ [classes.popperClose]: !open })}
                        >
                            <Grow in={open} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                                <Paper className={classes.menuStyle}>
                                    <div className={classes.menuRow}>
                                        {
                                            submenu.map((subMenu, index) =>
                                                <div key={index} className={classes.menuColumn}>                                                    
                                                    <DropdownDesktopMenuItem
                                                        submenu={subMenu}
                                                    />
                                                    {
                                                        subMenu[3].map((secondSubMenu, index) =>
                                                            <DropdownDesktopSubMenuItem
                                                                key={index}
                                                                index={index}
                                                                submenu={secondSubMenu}
                                                            />                                                           
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </Paper>
                            </Grow>
                        </Popper>
                    </Manager>
                </div>
            </div>
        );
    }
}

NavigationDesktopUnit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavigationDesktopUnit);