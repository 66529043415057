import React from 'react';
import dateformat from 'dateformat';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Button, Hidden, Typography, Divider } from '@material-ui/core';
//Price display formatting
import { formatPriceOfProductforCategoryListing } from '../../../../app/framework/common/helpers';
import AccountService from '../../../../app/modules/account/AccountService';
import CheckoutService from '../../../../app/modules/checkout/CheckoutService';
import WishlistService from '../../../../app/modules/wishlist/WishlistService';
import CartService from '../../../../app/modules/cart/CartService';
import GetLanguage from '../../../../library/GetLanguage'
import ContentLoader from '../../layouts/blocks/noncarousel/NonCarousel';
const styles = theme => ({
    cardInTitle: {
        border: 'none',
        padding: '0.5rem',
        border:'none',
    },
    headerFont: {
        fontSize: '14px',
        fontWeight: '600',
        color:'#1d2d5f',
    },
    headerStyle:{
        background:'none !important',
    },
    linkFont: {
        fontSize: '0.75rem',
        color: '#20B4E7',
        textDecoration: 'none'
    },
    viewMoreFont: {
        fontSize: '12px',
        color: '#1d2d5f',
        border:'1px solid #1d2d5f',
        borderRadius:'20px',
        padding:'5px 20px',
        marginRight:'15px',
        cursor: 'pointer',
        textTransform:'uppercase',
        display:'inline-block',
    },
    dividerStyle: {
        margin: '0.5rem 0rem',
    },
    viewOrderStyle: {
        cursor: 'pointer',
        color: '#1d2d5f',
        textDecoration: 'none',
        fontWeight:'bold',
        fontSize:'12px',
    },
    MarginTop:{
        margin:'25px 0 0 0',
    },
    accountHeading:{
        textTransform:'uppercase',
        margin:'10px 0 25px 0',
        padding:'10px 0',
        borderBottom:'1px solid #ccc',
    },
    orderHeading:{
        textTransform:'uppercase',
        margin:'10px 0 25px 0',
        padding:'10px 0',
        display:'inline',
        float:'left',
    },
    viewMoreOrder:{
        padding:'10px 0',
        margin:'5px 0',
        float:'right',
        fontSize:'12px',
        textDecoration:'underline',
    },
    myordermain:{
        background:'none',
        color:'#1d2d5f',
    },
    noPadding:{
        padding:'0px',
        fontWeight:'bold',
    },
    tableHeading:{
        textTransform:'uppercase',
        color:'#1d2d5f',
        fontSize:'12px',
    },
    pageHeading:{
        color:'#1d2d5f',
        margin:'10px 0 0 0',
    }
});


/***************************************************************
 * Component class for displaying Account info.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class MyAccount extends React.Component {

    componentDidMount() {
        //    if (typeof (this.props.get_user_details) === 'undefined' || this.props.get_user_details.length === 0) {
        this.props.getUserDetails();
        // this.props.loader();
        //  }
    }
    // componentWillReceiveProps(nextprops) {
    //     if (nextprops.loader_status && nextprops.get_user_details
    //         && Object.keys(nextprops.get_user_details).length) {
    //         this.props.loader();
    //     }
    // }
    reorderClick(params) {
        // this.props.loader(); 
        this.setState({ reorderclick: true }, () => {
            // alert();
            this.props.reorderClick(this, params);
        })
    }
    handleViewMore = (index) => {
        this.props.setSubMenu(index);
    };
    render() {
        if(!this.props.get_user_details){
            return (<ContentLoader isLoading={true} />)
        }
        let { classes, get_user_details, get_orders, isSalesmanUser } = this.props;
        let addressesLength = get_user_details && get_user_details.addresses && get_user_details.addresses.length > 0;
        let ordersLength = get_orders && get_orders.items && get_orders.items.length > 0;
        return (
            <Grid container justify='center'>
                <Grid item xs={12} classes={classes.headerStyle}>
                <Typography variant="h6" align="left" className={classes.pageHeading}>
                {isSalesmanUser ? <GetLanguage value="Account" /> : <GetLanguage value="My Account" />}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                <Grid item xs={10} className={classes.dividerStyle}>
                <Typography gutterBottom className={classes.accountHeading}>{<GetLanguage value="Account Information" />}</Typography>               
                
                    <Typography gutterBottom className={classes.headerFont}>{<GetLanguage value="Contact Information" />}</Typography>
                    <Typography>{Object.keys(get_user_details).length ? get_user_details.firstname + ' ' + get_user_details.lastname : ''}</Typography>
                    
                    <Typography>{Object.keys(get_user_details).length ? get_user_details.email : ''}</Typography>
                    <Grid className={classes.MarginTop}>
                    <Typography onClick={() => this.handleViewMore(2)} className={classes.viewMoreFont}>{<GetLanguage value="Edit" />}</Typography>
                    <Typography onClick={() => this.handleViewMore(2)} className={classes.viewMoreFont}>{<GetLanguage value="Change Password?" />}</Typography>
                    
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                
                <Grid item xs={10}>
                <Typography gutterBottom className={classes.accountHeading}>{<GetLanguage value="Address Book" />}</Typography>               
                
                    <Grid container direction='row' spacing={16} alignItems='baseline'>
                        <Grid item>
                            <Typography gutterBottom className={classes.headerFont}>{<GetLanguage value="Default Billing Address" />}</Typography>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.dividerStyle}>
                    {addressesLength ? <AddressLayout {...this.props} /> : <Typography>{<GetLanguage value="No addresses added yet!" />}</Typography>}
                </Grid>
                {addressesLength &&
                            <Grid item className={classes.MarginTop}>
                                <Typography onClick={() => this.handleViewMore(1)} className={classes.viewMoreFont}>{<GetLanguage value="Change" />}</Typography>
                            </Grid>
                        }
                </Grid>
                
                {/* <Grid item xs={12}>
                    <Divider className={classes.dividerStyle} />
                </Grid> */}
                
                {/* <Grid item xs={12}>
                    <Divider className={classes.dividerStyle} />
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container direction='row' spacing={16} alignItems='baseline'>
                        <Grid item xs={12}>
                            <Typography gutterBottom className={classes.orderHeading}>{<GetLanguage value="Recent Orders" />}</Typography>
                            {ordersLength &&
                           
                           <Typography onClick={() => this.handleViewMore(3)} className={classes.viewMoreOrder}>{<GetLanguage value="View More" />}</Typography>
                       
                   }
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {ordersLength ? <RecentOrdersLayout {...this.props} /> : <Typography>{<GetLanguage value="No orders yet!" />}</Typography>}
                </Grid>
            </Grid>
        );
    }
}

class AddressLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let { get_user_details } = this.props;
        return (
            <React.Fragment>
                <Grid container>
                    {get_user_details && get_user_details.addresses && get_user_details.addresses.map((item, index) => {
                        if (item.default_billing === true) {
                            return (
                                <Grid item xs={6} key={index}>
                                    <Typography>{item.firstname + ' ' + item.lastname}</Typography>
                                    <Typography></Typography>
                                    <Typography>{item.city + ' ' + item.country_id + ' ' + item.postcode}</Typography>
                                    <Typography>{<GetLanguage value="Phone number:" />} {item.telephone}</Typography>
                                </Grid>
                            )
                        }
                    })}
                </Grid>
            </React.Fragment>
        )
    }
}
class RecentOrdersLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let { classes } = this.props;

        if (this.props.get_orders && this.props.get_orders.items) {
            const orders = { ...this.props.get_orders };
            const items = orders.items;
            return (
                <React.Fragment>
                    <Hidden only={['xs', 'sm']}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Paper className={classes.myordermain}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.noPadding+' '+classes.tableHeading}>{<GetLanguage value="Order #" />}</TableCell>
                                                <TableCell className={classes.tableHeading}>{<GetLanguage value="Date" />}</TableCell>
                                                <TableCell className={classes.tableHeading}>{<GetLanguage value="Order Total" />}</TableCell>
                                                <TableCell className={classes.tableHeading}>{<GetLanguage value="Status" />}</TableCell>
                                                <TableCell className={classes.tableHeading}>{<GetLanguage value="Action" />}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.map((row, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.noPadding} component="th" scope="row">#{row.increment_id}</TableCell>
                                                            <TableCell>{row.created_at}</TableCell>
                                                            <TableCell>{formatPriceOfProductforCategoryListing(row.base_grand_total)}</TableCell>
                                                            <TableCell>{row.status}</TableCell>
                                                            <TableCell>
                                                                <Link to={{ pathname: '/vieworder', state: { ordernumber: row.entity_id } }} className={classes.viewOrderStyle}
                                                                    ordernumber={row.entity_id} onClick={() => this.setState({ row })}>{<GetLanguage value="View Order" />}</Link>
                                                                &nbsp; | <span className={classes.viewOrderStyle} onClick={() => this.reorderClick(row.items)}>{<GetLanguage value="Reorder" />}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Grid container>
                            {items.map((row, index) => {
                                if (index === 0) {
                                    return (
                                        <Paper className={classes.card} key={index}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography style={{ fontWeight: 'bold' }}>{<GetLanguage value="Order" />} : #{row.increment_id}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{<GetLanguage value="Date" />} : {row.created_at}</Typography>
                                                </Grid >
                                                <Grid item>
                                                    <Typography>{<GetLanguage value="Order Total" />} : {formatPriceOfProductforCategoryListing(row.base_grand_total)}</Typography>
                                                    <Typography>{<GetLanguage value="Ship To" />} : {row.customer_lastname}</Typography>
                                                </Grid >
                                                <Grid item>
                                                    <Typography>{<GetLanguage value="Order Total" />} : {formatPriceOfProductforCategoryListing(row.base_grand_total)}</Typography>
                                                </Grid >
                                                <Grid item>
                                                    <Typography>{<GetLanguage value="Status" />} : {row.status}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Button variant="contained" color="secondary" className={classes.myOrdersButton}>
                                                <Link to={{ pathname: '/vieworder', state: { ordernumber: row.entity_id } }} ordernumber={row.entity_id} style={{ color: "#fff", textDecoration: 'none' }} onClick={() => this.setState({ row })}>
                                                    {<GetLanguage value="View Order" />}
                                                </Link>
                                            </Button>
                                            <Button onClick={() => this.reorderClick(row.items)} style={{ marginLeft: '10px' }} variant="contained" color="secondary" className={classes.myOrdersButton}
                                            > {<GetLanguage value="Reorder" />}</Button>
                                        </Paper>
                                    );
                                }
                            })}
                        </Grid>
                    </Hidden>
                </React.Fragment>
            )
        }
        return null;
    }
}
const mapStateToProps = state => ({
    get_orders: state.get_orders,
    loader_status: state.get_loader_status,
    get_user_details: state.get_user_details_state,
    customerWishlistedProducts: state.get_wishlisted_products,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
});

const mapDispatchToProps = dispatch => ({
    loader: () => {
        dispatch({ type: 'SET_LOADER_STATUS' })
    },
    getUserDetails: () => {
        const as = new AccountService();
        dispatch(as.GetCustomerDetailsActionCreator());
    },
    displayWishlist: () => {
        let wishlistService = new WishlistService();
        dispatch(wishlistService.GetWishlistedProductsActionCreator());
    },
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    },
    reorderClick: (component, params) => {
        const cs = new CartService();
        dispatch(cs.BulkUpload(params, component));
    },
}
);

const MyAccountInformationWithStyles = withStyles(styles)(MyAccount);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountInformationWithStyles));