/**
* MyOrders Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Fragment } from 'react';
// import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import PageTitle from '../../../../app/components/common/PageTitle';
import AccountService from '../../../../app/modules/account/AccountService';
import OrderService from '../../../../app/modules/order/OrderService';
import { connect } from 'react-redux';
import { withStyles,MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import dateformat from 'dateformat';
import ViewOrder from './ViewOrder';
import { Button, Hidden, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import createHistory from "history/createBrowserHistory";
//Price display formatting
import { formatPriceOfProductforCategoryListing } from '../../../../app/framework/common/helpers';
import { Link } from 'react-router-dom';
import CartService from '../../../../app/modules/cart/CartService';
import GetLanguage from '../../../../library/GetLanguage';
import ContentLoader from '../../layouts/blocks/noncarousel/ContentLoader';
import { orange, pink, green } from "@material-ui/core/colors";

const styles = theme => ({
    myordermain: {
        marginBottom: '20px',
        overflowX: 'auto',
        background:'none',
    },
    viewOrderStyle: {
        cursor: 'pointer',
        color: '#1d2d5f',
        textDecoration: 'none',
        fontWeight:'bold',
        fontSize:'12px',
    },
    card: {
        width: '100%',
        padding: '10px',
        margin: "8px"
    },
    myOrdersButton: {
        borderRadius: '25px',
    },
    noPadding:{
        padding:'0px',
        fontWeight:'bold',
    },
    tableHeading:{
        textTransform:'uppercase',
        color:'#1d2d5f',
        fontSize:'12px',
    },
    pageHeading:{
        color:'#1d2d5f',
        margin:'10px 0 0 0',
    },
    tapBar:{
        background:'none',
    },
    tabSection:{
        background:'#fff',
        color:'#a4a0a0',
        marginLeft:'10px',
    }
});
const theme = createMuiTheme({
    overrides: {
        MuiPaper:{
            root:{
                boxShadow:'none !important',
            },
        },
      MuiTabs: {
        indicator: {
          backgroundColor: '#f65e5d',
          height:'3px',
        },
        flexContainer:{
            float:'right',
        }
      },
      MuiTab: {        
        selected: {          
          color: '#1d2d5f',         
        }
      }
    }
  });
/************************************************************************************
 * Component class for displaying My Orders Form.
 * On submit user can able to view respective orders
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ************************************************************************************/
class MyOrders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOrder: 0,
            orderId: undefined
        }
        this.handleClick = this.handleClick.bind(this);
        const history = createHistory();
        this.unlisten = history.listen((location, action) => {
            if (this.state.row) {
                this.setState({ row: null });
                props.history.push('/myorders');
            }
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    componentDidMount() {
        /***************************************************************************** 
         * Check if the user is authenticated. 
         * If yes, display my orders page.
         * If not, redirect to Sign-In page and once
         * signed-in, redirect back to wishlist page.
         *****************************************************************************/
        this.props.myOrder();
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        } else if (!this.props.loader_status) {
            this.props.loader();
        }
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.get_orders.items && nextprops.loader_status && !this.state.reorderclick) {
            this.props.loader();
        }
    }
    reorderClick(params) {
        this.setState({ reorderclick: true }, () => {
            this.props.reorderClick(this, params);
        })
    }
    handleClick = (data, orderId) => {
        this.setState({
            productdata: data,
            orderId: orderId
        })
    }
    componentWillMount(){
        localStorage.removeItem("tabName");
    }
    render() {
        if(this.props.loader_status){
            return (<ContentLoader isLoading={true} />)
        }
        const { classes, isSalesmanUser } = this.props;
        if (this.props.get_orders.items) {
            const orders = { ...this.props.get_orders };
            const items = orders.items;
            if (this.state.row) {
                return (<Fragment>
                    <Button onClick={() => this.setState({ row: null })}>Go back</Button>
                    <ViewOrder data={this.state.row} />
                </Fragment>);
            } else
                return (
                    <React.Fragment>
                        <Hidden only={['xs', 'sm']}>
                            <Grid container>
                                <Grid item xs={12}>
                                <Typography variant="h6" align="left" className={classes.pageHeading}>
                {isSalesmanUser ? <GetLanguage value="Orders" /> : <GetLanguage value="My Orders" />}
                    </Typography>
                    <MuiThemeProvider theme={theme}>
                                    <AppBar position="static" className={classes.tapBar}>
                                        <Tabs classes={{
                                            indicator: classes.indicator
                                        }} value={this.props.get_orders.current === "HPC" ? 0 : 1} onChange={(e, selectedOrder) => this.props.current_order(selectedOrder)} aria-label="simple tabs example">
                                            <Tab onClick={()=>localStorage.setItem('tabName','HPC')} className={classes.tabSection} label={<GetLanguage value="HPC" />} />
                                            <Tab onClick={()=>localStorage.setItem('tabName','Foods')} className={classes.tabSection} label={<GetLanguage value="FOODS" />} />
                                        </Tabs>
                                    </AppBar>
                                    </MuiThemeProvider>
                                    <Paper className={classes.myordermain}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.noPadding+' '+classes.tableHeading}>{<GetLanguage value="Order #" />}</TableCell>
                                                    <TableCell className={classes.tableHeading}>{<GetLanguage value="Date" />}</TableCell>
                                                    {/* <TableCell >Ship To</TableCell> */}
                                                    <TableCell className={classes.tableHeading}>{<GetLanguage value="Order Total" />}</TableCell>
                                                    <TableCell className={classes.tableHeading}>{<GetLanguage value="Status" />}</TableCell>
                                                    <TableCell className={classes.tableHeading}>{<GetLanguage value="Action" />}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className={classes.noPadding+' '+classes.tableHeading} component="th" scope="row">#{row.increment_id}</TableCell >
                                                        <TableCell>{row.created_at}</TableCell >
                                                        {/* <TableCell>{row.customer_firstname+' '+row.customer_lastname}</TableCell > */}
                                                        <TableCell>{formatPriceOfProductforCategoryListing(row.base_grand_total)}</TableCell >
                                                        <TableCell>{row.status}</TableCell >
                                                        {/* <TableCell>Pending</TableCell > */}
                                                        <TableCell>
                                                            <Link to={{ pathname: '/vieworder', state: { ordernumber: row.entity_id } }} className={classes.viewOrderStyle} ordernumber={row.entity_id} onClick={() => this.setState({ row })}>
                                                                {<GetLanguage value="View Order" />}
                                                            </Link>
                                                            &nbsp; | <span className={classes.viewOrderStyle} onClick={() => this.reorderClick(row.items)}>
                                                                {<GetLanguage value="Reorder" />}</span></TableCell >
                                                    </TableRow >
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <AppBar position="static">
                                <Tabs classes={{
                                    indicator: classes.indicator
                                }} value={this.props.get_orders.current === "HPC" ? 0 : 1} onChange={(e, selectedOrder) => this.props.current_order(selectedOrder)} aria-label="simple tabs example">
                                    <Tab className={classes.TabStyle} style={{ background: "#20B4E7" }} label={<GetLanguage value="HPC" />} id="HPC-tabs" />
                                    <Tab className={classes.TabStyle} style={{ background: "#20B4E7" }} label={<GetLanguage value="FOODS" />} id="Food-tabs" />
                                </Tabs>
                            </AppBar>
                            <Grid container>
                                {items.map((row, index) => (
                                    <Paper className={classes.card} key={index}>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography style={{ fontWeight: 'bold' }}>{<GetLanguage value="Order" />} : #{row.increment_id}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>{<GetLanguage value="Date" />} : {row.created_at}</Typography>
                                            </Grid >
                                            {/* <Grid item>
                                            <Typography>Ship To : {row.customer_lastname}</Typography>
                                        </Grid > */}
                                            <Grid item>
                                                <Typography>{<GetLanguage value="Order Total" />} : {formatPriceOfProductforCategoryListing(row.base_grand_total)}</Typography>
                                            </Grid >
                                            <Grid item>
                                                <Typography>{<GetLanguage value="Status" />} : {row.status}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Button variant="contained" color="secondary" className={classes.myOrdersButton}>
                                            <Link data-test={row.entity_id} to={{ pathname: '/vieworder', state: { ordernumber: row.entity_id } }}
                                                ordernumber={row.entity_id} style={{ color: "#fff", textDecoration: 'none' }}
                                                onClick={() => this.setState({ row })}>{<GetLanguage value="View Order" />}</Link>
                                        </Button>
                                        <Button onClick={() => this.reorderClick(row.items)} style={{ marginLeft: '10px' }} variant="contained" color="secondary" className={classes.myOrdersButton}
                                        >{<GetLanguage value="Reorder" />}</Button>
                                    </Paper>
                                ))}
                            </Grid>
                        </Hidden>
                    </React.Fragment>
                );
        }
        else
            return null;

    }
}

const mapStateToProps = state => ({
    get_orders: state.get_orders,
    loader_status: state.get_loader_status,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
});

const mapDispatchToProps = dispatch => ({
    myOrder: () => {
        const order = new OrderService();
        dispatch(order.getOrderHistoryActionCreator());
    },
    reorderClick: (component, params) => {
        const cs = new CartService();
        dispatch(cs.BulkUpload(params, component));
    },
    loader: () => {
        dispatch({ type: 'SET_LOADER_STATUS' });
    },
    current_order: (index) => {
        dispatch({ type: 'CURRENT_ORDER', current: index === 0 ? "HPC" : "Foods" });
    }
}
);

// const MyOrdersWithStyles = withStyles(MyOrders);
const MyOrdersWithStyles = withStyles(styles)(MyOrders);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrdersWithStyles));
