/**
* SignInForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import UserMessage from '../../../../app/components/messages/UserMessages';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import AccountService from '../../../../app/modules/account/AccountService';
import { connect } from 'react-redux';
import magnaCookies from '../../../../app/framework/cookie/magnaCookies';
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import { removeItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import TemplateWrapper from '../../../../app/framework/hoc/templateWrapper';
import TextField from '@material-ui/core/TextField';
import GetLanguage from '../../../../library/GetLanguage'

const styles = theme => ({
    switchForm: {
        color: '#2196f3',
        textAlign: 'right',
        cursor: 'pointer',
    },
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        marginBottom: '20px'
    },
    floatingLabelFocusStyle: {
        color: "#eeeeee"
    },
    inputWidth: {
        width: '100%'
    },
    divStyle: {
        [theme.breakpoints.up('md')]: {
            marginTop: '2rem'
        },
    }
});

/***************************************************************
 * Component class for displaying Sign In Form.
 * On submit user can able to sign into respective account
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class SignInForm extends React.Component {
    constructor(props) {
        super(props);
        //get user details from local storage
        var userInfo = getItemFromLocalStorage('shopce-user');


        // Before displaying pre-populated email, check if localStorage has 
        // data for email, name and token.
        this.state = {
            email: AccountService.checkLocalStorage(userInfo) ? userInfo.email : '',
            password: '',
            showPassword: false,
            redirectTo: this.props.location.state ? (this.props.location.state.redirectTo ? this.props.location.state.redirectTo : '/') : '/',
            changePasswordSuccess: this.props.location.state ? (this.props.location.state.changePasswordSuccess ? true : false) : null,
        };
    }
    //handles closing of menu
    handleClose = () => {
        this.setState({ open: false });
    };
    //updates email and password fields with provided values
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    //hide/show text in password field
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    //call dispatch function if email and password fields are not empty
    handleSignIn = (event) => {
        event.preventDefault();
        //Dispatch action to Sign-In the user.
        const { email, password } = this.state;
        this.props.onSubmitSignInDispatch(email, password);
    };
    handleAccounts = () => {
        //delete the Local Storage object for the user.
        removeItemFromLocalStorage('shopce-user');

        //Remove the customer cookie
        magnaCookies.removeCookie('ECS_AC');
        //clear email,password
        this.setState({ email: '', password: '' });
    }

    render() {
        const { classes } = this.props;
        const { signedInUserSuccess, isSignInButtonDisabled, isSalesmanUser } = this.props;

        let salesmanInfo = getItemFromLocalStorage('salesman-user');
        if (salesmanInfo && isSalesmanUser) {
            return <Redirect push to='/customersList' />;
        }


        //If User Sign In is success, Redirect to home page
        if (signedInUserSuccess) {
            return <Redirect replace to={this.state.redirectTo} />;
        }

        //If user signed out after password change, display related message
        if (this.props.history.action === 'PUSH' &&
            typeof this.state.changePasswordSuccess !== 'undefined'
            && this.state.changePasswordSuccess) {
            const object = { messageType: 'sucess', message: 'Password updated successfully', autoHideDuration: 10000 }
            var changePasswordStatus = <UserMessage {...object} />
        }

        return (
            <div style={{ minHeight: '400px' }}>
                <div className={classes.divStyle}>
                    <PageTitle title={<GetLanguage value="Sign In" />} />
                </div>
                {changePasswordStatus}
                <Grid container alignItems="center" justify="center">
                    <Grid item xs sm={8} md={5} lg={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleSignIn}>
                                    <Grid container spacing={16} direction="column" justify='center' alignItems='stretch' >
                                        <Grid item className={classes.inputWidth}>
                                            {/* <FormControl margin="normal"  fullWidth>
                                                <InputLabel htmlFor="adornment-Username">Email/Mobile</InputLabel>
                                                <Input id="adornment-Username" type='text' value={this.state.email}
                                                    onChange={this.handleChange('email')} required={true} autoFocus/>
                                            </FormControl> */}
                                            <TextField autoFocus InputLabelProps={{ style: { color: '#000000' }, }}
                                                label={<GetLanguage value="Email/Mobile" />} fullWidth required margin="normal"
                                                value={this.state.email} onChange={this.handleChange('email')} />
                                        </Grid>
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl fullWidth>
                                                <TextField InputLabelProps={{ style: { color: '#000000' } }}
                                                    label={<GetLanguage value="Password" />} fullWidth required
                                                    value={this.state.password} onChange={this.handleChange('password')}
                                                    type={this.state.showPassword ? 'text' : 'password'} />
                                                <FormHelperText id="component-helper-text" className={classes.switchForm}>
                                                    <Link to='/forgotpassword' style={{ textDecoration: 'none', color: '#2196f3' }}>{<GetLanguage value="Forgot Password?" />}</Link>
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item className={classes.inputWidth}>
                                            <FormControl required fullWidth>
                                                <Fab size="medium" variant="extended" type="submit"
                                                    disabled={isSignInButtonDisabled}>{<GetLanguage value="Sign In" />}</Fab>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* Removing Registration
                <Card>
                    <CardContent>
                        <Grid container alignItems="center" justify="center" direction='row' spacing={8}>
                            <Grid item>
                                <Typography gutterBottom>Don't have Account?</Typography>
                            </Grid>
                            <Grid item>
                                <Fab size="large" variant="extended" href='/registerMobile'>Register</Fab>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card> */}
            </div>
        );
    }
    componentWillUnmount() {
        this.props.resetSignedInUserSuccess();
    }
}

const mapStateToProps = state => ({
    isSignInButtonDisabled: state.post_sign_in_customer_state.isSignInButtonDisabled || state.getSalesManDetails.isSignInButtonDisabled,
    signedInUserSuccess: state.post_sign_in_customer_state.signInSuccess || state.getSalesManDetails.isSalesmanUser,
    signedInUserError: state.post_sign_in_customer_state.errorMessage ? state.post_sign_in_customer_state.errorMessage : '',
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
});

const mapDispatchToProps = dispatch => ({
    onSubmitSignInDispatch: (email, password) => {
        let accountService = new AccountService();
        dispatch(accountService.signInActionCreator(email, password));
    },
    resetSignedInUserSuccess: () => {
        let accountService = new AccountService();
        dispatch(accountService.resetSignedInActionCreator());
    },
}
);

const SignInFormWithStyles = withStyles(styles)(SignInForm);
export default connect(mapStateToProps, mapDispatchToProps)(TemplateWrapper(SignInFormWithStyles, 'withHeaderFooter'));
