/**
* Carousel Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import KeyboardArrowRight from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowLeft from '@material-ui/icons/ArrowBackIos';
import { withStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import '../../../../../../src/assets/css/common/carousal.css';
import { Link } from "react-router-dom";
/***************************************************************
 * Component class for displaying Carousel
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
const styles = theme => ({
  sliderdiv: {
    margin: 5,
    padding: 0,
    textAlign: 'center',
  },
  produtsliderdiv: {
    margin: "5px 0px",
    padding: 0,
    textAlign: 'center',
  }
});

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nav: true,
      prevArrow: <KeyboardArrowLeft color='primary' fontSize='large' />,
      nextArrow: <KeyboardArrowRight color='primary' fontSize='large' />,
    }

  }
  state = {
    slideIndex: 0
  };



  // The Carousel is not having the "next" and "previous" buttons centered.
  // On resizing the window, centering of the same is achieved.
  // Ref: https://github.com/FormidableLabs/nuka-carousel/issues/265 
  componentDidUpdate() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  //on clicking the banner, redirect to
  //respective category
  onBannerImageClick = (val) => {
    //redirect to url
    this.props.history.push(val);
  }
  styleArrow(disabled) { return { opacity: disabled ? 0 : 1 }; }

  render() {

    let { classes } = this.props;
    const { bannerObject } = this.props;
    const { cmsPageWidgetDataObject } = this.props;
    if (cmsPageWidgetDataObject && typeof cmsPageWidgetDataObject !== "undefined") {
      return (
        <div className="single-banner">
          <Slider  {...this.settings}>
            {
              bannerObject && bannerObject.map((item, index) =>
                <div key={index}>
                  {item.target === "_self" ?
                    <img key={index} src={item.imageSrc} alt={item.alt_text}
                      onClick={() => this.onBannerImageClick(item.imageUrL ? item.imageUrL : item.imageUrl)} />

                    :
                    <Link to={item.imageUrL ? item.imageUrL : item.imageUrl} target="_blank">
                      <img key={index} src={item.imageSrc} alt={item.alt_text} />
                    </Link>
                  }
                </div>


              )
            }
          </Slider>
        </div>
      );
    }
    else return null;
  }
}

export default withStyles(styles)(Carousel);