/**
* Sample Use for templates with 2 columns with
* Right Sidebar, Header, Navigation Bar and Footer.
*
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TwoColumnsRightBar from './2ColumnsRightBar';

import Headerbar from '../../../../app/components/layouts/headerbar/HeaderBar';
import Footerbar from '../../../../app/components/layouts/footerbar/FooterBar';

import NavigationMenuDesktop from '../../../../app/components/navigation/navigationdesktop/NavigationDesktop';

const styles = theme => ({
    rightSideBar: {
        width: '20%',
        height: 200,
        background: '#aaa',
        float: 'right',
    },    
    mainContentLeft: {
        width: '80%',
        height: 200,
        background: '#bbb',
        float: 'left',
    },    

});

/***************************************************************************** 
 * This component uses Template with 2 Columns and Right
 * Sidebar. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class TemplateUsage2ColumnRightBar extends Component {
    render() {
        const { classes } = this.props;
        const LeftMainComponent = props => (
            <div className={classes.mainContentLeft}>
            </div>
        );
        const RightSideBarComponent = props => (
            <div className={classes.rightSideBar}>
            </div>
        );

        return (
            <div>
                <TwoColumnsRightBar
                    HeaderComponent={Headerbar}
                    NavigationComponent={NavigationMenuDesktop}
                    rightSideBarComponent={[RightSideBarComponent]}
                    mainContent={[LeftMainComponent]}
                    FooterComponent={Footerbar}
                />                
            </div>
        );
    }
}

export default withStyles(styles)(TemplateUsage2ColumnRightBar);

