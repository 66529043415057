/**
* Common Pagination Component to be used in multiple places .
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Pagination from 'rc-pagination';
//CSS for the pagination component.
import './rc-paginate.css';

const styles = theme => ({

});

/***************************************************************************** 
 * Common Pagination Component.
 * Props:
 * pageSize - Number of items per page rendering
 * current  - current page number being displayed.
 * total    - total Number of items being paginated.
 * locale   - text tooltips being used.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/

class PaginationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
        }
    }

    onChange = (pageNumber, pageSize) => {
        //passing page number to parent component via callback.
        this.props.onPageChange(pageNumber);

        this.setState({
            current: pageNumber,
        });
    }

    render() {
        return <Pagination
            pageSize={this.props.itemsPerPage}
            onChange={this.onChange}
            current={this.state.current}
            total={this.props.totalItems}
            locale={localeInfo}
        />;
    }
}

/***************************************************************************** 
 * This basis for this configuration is taken from 
 * http://react-component.github.io/pagination/examples/locale.html
 * The imported localInfo is made as object as below and is passed
 * pagination component above.
 *****************************************************************************/
const localeInfo = {
    items_per_page: '/ page',
    jump_to: 'Goto',
    jump_to_confirm: 'confirm',
    page: '',
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
};

PaginationComponent.propTypes = {
    onPageChange: PropTypes.func,
    itemsPerPage: PropTypes.number,
    totalItems: PropTypes.number,
};

export default withStyles(styles)(PaginationComponent);