import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Typography } from '@material-ui/core';
import GetLanguage from '../../../../library/GetLanguage';
import SalesManService from '../../../../app/modules/salesman/SalesManService';
import AccountService from '../../../../app/modules/account/AccountService';
const styles = theme => ({
    headerFont: {
        fontSize: '1rem',
        fontWeight: '600'
    },
    linkFont: {
        fontSize: '0.75rem',
        color: '#20B4E7',
        textDecoration: 'none'
    },
    viewMoreFont: {
        fontSize: '0.75rem',
        color: '#20B4E7',
        cursor: 'pointer'
    },
    dividerStyle: {
        margin: '0.5rem 0rem',
    },
    viewOrderStyle: {
        cursor: 'pointer',
        color: '#57B3FF',
        textDecoration: 'none'
    },
});


/***************************************************************
 * Component class for displaying Account info.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class SalesManAccount extends React.Component {

    componentDidMount() {
        this.props.getSalesManProfile();
    } 
    handleViewMore = (index) => {
        this.props.setSubMenu(index);
    };
    render() {
        let { classes, getSalesManProfileDetails, isSalesmanUser } = this.props;
        return (
            <Grid container justify='center'>
                <Grid item xs={12}>
                    <PageTitle title={isSalesmanUser ? <GetLanguage value="My Account"/> : <GetLanguage value="Account"/>} />
                </Grid>
                <Grid item xs={12} className={classes.dividerStyle}>
                    <Typography gutterBottom className={classes.headerFont}>{<GetLanguage value="Contact Information"/>}</Typography>
                    <Typography>{getSalesManProfileDetails ? getSalesManProfileDetails.firstname + ' ' + getSalesManProfileDetails.lastname : ''}</Typography>
                    <Typography onClick={() => this.handleViewMore(1)} className={classes.viewMoreFont}>{<GetLanguage value="Edit"/>}</Typography>
                    <Typography onClick={() => this.handleViewMore(1)} className={classes.viewMoreFont}>{<GetLanguage value="Change Password?"/>}</Typography>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
    getSalesManProfileDetails : state.GetSalesmanProfileDetails,
});

const mapDispatchToProps = dispatch => ({
    getSalesManProfile: () => {
        const as = new SalesManService();
        dispatch(as.GetSalesmanProfileDetailsActionCreator());
    },
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    }
}
);

const SalesManAccountWithStyles = withStyles(styles)(SalesManAccount);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesManAccountWithStyles));