/**
* Unit Selection Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CartService from './../../../app/modules/cart/CartService';
import '../../../../src/assets/css/common/formAttributes.css';

const styles = theme => ({
  selectStyle: {
    padding: '7px 0px',
    borderRadius: '18px',
    backgroundColor: 'white',
    outline: 'none'
  },
  fontStyle: {
  }
});
/****************************************************************
 * This component returns input field which includes search icon
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class UnitSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedUOM: 0,
    }
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };
  handleChange = (event) => {
    this.setState({ selectedUOM: event.target.value });
    const variant_selected_data = { ...this.props.variantSelected };
    const { product } = { ...this.props };
    const { sku } = { ...product };
    variant_selected_data[sku] = variant_selected_data[sku] || product;
    if (variant_selected_data[sku].selectedUnit !== event.target.value) {
      variant_selected_data[sku].selectedUnit = event.target.value;
      variant_selected_data[sku].selectedQuantity = variant_selected_data[sku].selectedQuantity || 1;
      this.props.unitSelectedChange(variant_selected_data);
    }
  }
  //handles closing of menu
  handleClose = () => {
    this.setState({ open: false });
  };
  changeReduxVariant(index) {
    const variant_selected_data = { ...this.props.variantSelected };
    const { product } = { ...this.props };
    const { sku } = { ...product };
    variant_selected_data[sku] = variant_selected_data[sku] || product;
    if (variant_selected_data[sku].selectedUnit !== index) {
      variant_selected_data[sku].selectedUnit = index;
      variant_selected_data[sku].selectedQuantity = variant_selected_data[sku].selectedQuantity || 1;
      this.props.unitSelectedChange(variant_selected_data);
    }
  }
  render() {
    let { classes, product, variantSelected } = this.props;
    var uomArray = [
      {
        value: 'Case'
      }
    ]
    return (
      // <Grid container direction='row' className={classes.counterDiv} justify='center' alignItems='center'>
      //   {uomArray.map((item, index) => (
      //     <Grid item xs={6} key={index} onClick={() => this.changeReduxVariant(index)}
      //       className={classNames(unitSelector === index ? classes.selectedUnit : classes.allIconBorders)}>
      //       <Grid container direction='column' alignItems='center' justify='center'>
      //         <Grid item>
      //           <Button disableRipple={true} style={{ padding: '0px' }}>
      //             {item.icon}
      //           </Button>
      //         </Grid>
      //         <Grid item>
      //           <Typography color={unitSelector === index ? 'textPrimary' : 'error'}>{item.value}</Typography>
      //         </Grid>
      //       </Grid>
      //     </Grid>
      //   ))}
      // </Grid>
      <Grid container direction="row" className="pdpCase">
        <Grid item >
          <div className="select-box">
            <select value={this.state.selectedUOM} onChange={this.handleChange} className={classes.selectStyle}>
              {uomArray.map((item, index) => (
                <option value={index} key={index} className={classes.fontStyle}>
                  {item.value}
                  {/* {<GetLanguage value="item.value"/>} */}
                </option>
              ))}
            </select>
          </div>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  variantSelected: state.variant_selected,
});
const mapDispatchToProps = dispatch => ({
  unitSelectedChange: (data) => {
    let cs = new CartService();
    dispatch(cs.selectedvariantActionCreator({ payload: data }));
  }
});
const UnitSelectorStyles = withStyles(styles)(UnitSelector)
export default connect(mapStateToProps, mapDispatchToProps)(UnitSelectorStyles);