import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import placeholderimage from '../../../../assets/images/Placeholder.jpg';
import GetLanguage from '../../../../library/GetLanguage';

class ViewOrder extends React.Component {
render(){
    const {items}=this.props.data;
return(
<div>
    <h3 style={{padding:'20px 0px'}}>{<GetLanguage value="Product details"/>}</h3>
    <Paper >
    <Table >
        <TableHead>
        <TableRow>
            <TableCell>{<GetLanguage value="Product Name"/>}</TableCell>
            <TableCell >{<GetLanguage value="Quantity Purchase"/>}</TableCell>
            <TableCell >{<GetLanguage value="Total Amount"/>}</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
         { items.map(item => ( 
            <TableRow  >
                <TableCell style={{display:'flex',alignItems:'center'}} component="th" scope="row"><img style={{height:'50px',width:'50px',marginRight:'10px'}} src={placeholderimage} alt="placeholder"/> <span>{item.name}</span></TableCell>
                <TableCell>{item.qty_ordered}</TableCell >
                <TableCell>{item.base_row_total}</TableCell >
            </TableRow >
         ))}
        </TableBody>        
    </Table>
    </Paper>
</div>
)
}
}

export default ViewOrder;