/**
* Common CMS Pages component for displaying CMS Pages.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../../../app/components/common/PageTitle';
//Redux related
import { connect } from 'react-redux';
import CategoryService from '../../../app/modules/category/CategoryService';
import FooterService from '../../../app/modules/footer/FooterService';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 10,
        wordBreak:'break-word'
    },

});

/***************************************************************************** 
 * Component for rendering CMS Page Content.
 * Page ID is passed through props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class CMSPagesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let { targetURLPath } = this.props;
        if(this.props.location && this.props.location.state) {
            this.props.GetFooterCMS(this.props.location.state.footerId);
        }
        if(targetURLPath) {
            let pageId = extractPageId(targetURLPath);
            //Dispatch with Page Id to get page Content.
            this.props.getCMSPageContent(pageId);
        }
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.location && this.props.location.state !== nextProps.location.state) {
            this.props.GetFooterCMS(nextProps.location.state.footerId);
        }
    }
    render() {
        let { classes } = this.props;
        const { cmsPageContent,getFooterCMS } = this.props;
        let title;
        let content ;
        if(cmsPageContent && cmsPageContent.content) {
            title = cmsPageContent.title;
            content = cmsPageContent.content;
        }
        if(getFooterCMS && getFooterCMS.content) {
            title = getFooterCMS.title;
            content = getFooterCMS.content;
        }
        if (title !== '' && content !=='') {
            return (
                <div className={classes.root}>
                    <PageTitle title={title} />
                    <div dangerouslySetInnerHTML={{ __html: content }}>
                    </div>
                </div>
            );
        }
        else {
            return (
                <PageTitle title='No Results Found' />
            );
        }

    }
}

/***************************************************************************** 
 * @param {*} pageURL - Input Page URL received via footer links
 * The last digits in the URL path is Page ID.
 * 
 *****************************************************************************/
function extractPageId(pageURL) {
    // For example: In the string like "cms/page/view/page_id/5",
    // extract "5".
    return pageURL.split("/")[4];
}

CMSPagesComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    pageId: PropTypes.number,
};

/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    targetURLPath: state.targetPathState.targetURLPath,
    cmsPageContent: state.cms_page_content_state.cmsPageContent,
    getFooterCMS : state.GetFooterCMSReducer,
});

const mapDispatchToProps = dispatch => ({
    getCMSPageContent: (pageId) => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getCMSPageContentActionCreator(pageId));
    },
    GetFooterCMS: (block_id) => {
        let footerService = new FooterService();
        dispatch(footerService.GetFooterCMS(block_id));
    }
});

const CMSPagesComponentWithStyles = withStyles(styles)(CMSPagesComponent);
export default connect(mapStateToProps, mapDispatchToProps)(CMSPagesComponentWithStyles);

