/**
* Sample Use for templates with Header and Footer and 
* Three Columns.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ThreeColumns from './3Columns';
import Headerbar from '../../../../app/components/layouts/headerbar/HeaderBar';
import Footerbar from '../../../../app/components/layouts/footerbar/FooterBar'; 

import NavigationMenuDesktop from '../../../../app/components/navigation/navigationdesktop/NavigationDesktop';

const styles = theme => ({
    threeColumnLeftSidebar:{
        width:'20%',
        height:200,
        float:'left',        
        background:'#aaa'
    },
    threeColumnCenter:{
        width:'60%',
        height:200,
        float:'left',   
        background:'#ff0'     
    },
    threeColumnRightSidebar:{
        width:'20%',
        height:200,
        float:'left',   
        background:'#bbb'     
    }
});

/****************************************************************************** 
 * This component uses Tempalte 3 Columns.
 * Has Header, Footer and Navigation Bar.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class TemplateUsage3Columns extends Component {
    render() {
        const { classes } = this.props;
        const ThreeColumnLeftComponent = props => (
            <div className={classes.threeColumnLeftSidebar}>
            </div>
        );
        const ThreeColumnCenterComponent = props => (
            <div className={classes.threeColumnCenter}>
            </div>
        );
        const ThreeColumnRightComponent = props => (
            <div className={classes.threeColumnRightSidebar}>
            </div>
        );
        return (
            <div>                
                <ThreeColumns
                    HeaderComponent={Headerbar}
                    NavigationComponent={NavigationMenuDesktop}
                    leftSideBarComponent={[ThreeColumnLeftComponent]}
                    rightSideBarComponent={[ThreeColumnRightComponent]}
                    mainContent={[ThreeColumnCenterComponent]}
                    FooterComponent={Footerbar}
                />
            </div>
        );
    }
}

export default withStyles(styles)(TemplateUsage3Columns);

