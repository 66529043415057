/**
* Component for displaying Mobile Menu.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import NavigationMobileUnit from './NavigationMobileUnit';
import CategoryService from '../../../modules/category/CategoryService';
import { withErrorBoundary } from 'react-error-boundary';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F0F0F0',
    },          
});

/***************************************************************
 * Component class for displaying Category Menu.
 * Passes data to Child Component to render the Mobile Category
 * Menu.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class NavigationMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // Dispatch action to Redux to fetch category data
    componentDidMount() {
         /***************************************************************************** 
         * When the app is loaded for first time, the category
         * navigation menu data is fetched. Subsequent page transitions
         * will not fetch the category data again and the redux stored
         * menu data is used. 
         *****************************************************************************/
        if(typeof(this.props.categoryData) === 'undefined')
        {
            this.props.getCategoryData();
        }        
    }

    //Get the submenu at Level 3 for the given menu.
    handleSubMenu = (cat) => {
        if (cat["children_data"] !== []) {
            let subCat = [];
            cat["children_data"] && cat["children_data"].map((sub_cat_item, index) => {                
                return subCat.push([sub_cat_item.name, sub_cat_item.id, sub_cat_item.url, sub_cat_item.children_data]);
            })
            return subCat;
        }
        else {
            return [];
        }
    }

    render() {
        const { classes, mobileOpen, onClose } = this.props;
        const { categoryData } = this.props;                

        return (
            <div>
                <Drawer open={mobileOpen} onClose={onClose}>
                    <div className={classes.root}>
                        {
                            categoryData && categoryData.map((cat, index) => (
                                <NavigationMobileUnit
                                    key={index}
                                    panelID={index}
                                    topmenu={[cat.name, cat.id, cat.url, cat.children_data]}
                                    submenu={this.handleSubMenu(cat)}
                                    {...this.props}
                                >
                                </NavigationMobileUnit>
                            ))
                        }
                    </div>
                </Drawer>
            </div>
        );
    }
}

NavigationMobile.propTypes = {
    classes: PropTypes.object.isRequired,
};

const NavigationErrorFallbackComponent = ({ componentStack, error }) => {
    return null;
}

const NavigationMobileWithErrorBoundary = withErrorBoundary(
    NavigationMobile,
    NavigationErrorFallbackComponent    
);

/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    categoryData: state.categoryDataState.categoryData
});

const mapDispatchToProps = dispatch => ({
    getCategoryData: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getCategoryDataForMenuActionCreator());
    }
});

const NavigationMobileWithConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationMobileWithErrorBoundary);

export default withStyles(styles)(NavigationMobileWithConnect);