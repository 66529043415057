/**
* Search Results display Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import UserMessage from '../../../../app/components/messages/UserMessages';
import PaginationComponent from '../../pagination/PaginationComponent';
import ContentLoader from '../../../components/layouts/blocks/noncarousel/ContentLoader';
import PageTitle from '../../../../app/components/common/PageTitle';
import getConfigValue from '../../../../app/framework/config/configHandler';
import CategoryService from '../../../../app/modules/category/CategoryService';
import ProductCatalog from '../../../components/product/productcatalog/ProductCatalog';
import PLPView from '../../../components/category/productlisting/PLPView';
import SortView from '../../../components/category/productlisting/SortView';
import { withErrorBoundary } from 'react-error-boundary';
import FilterInSearch from './FilterInSearch';
import { Button } from '@material-ui/core';
import GetLanguage from '../../../../library/GetLanguage';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CategoryChips from '../../category/filters/CategoryChips';
import $ from 'jquery';
const styles = theme => ({
    root: {
        flexGrow: 1,
        minHeight: '500px',
        background:'#fff',
    },
    productListingGrid: {
        marginTop: 0,
        marginBottom: 20,
        display: 'flex',
        width: '100%'
    },
    sliderdiv: {
        margin: 0,
        padding: 0,
    },
    paper: {
        boxShadow: 'none',
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    priceText: {
        marginBottom: 5,
    },
    shoppingOptionsText: {
        textAlign: 'left',
    },
    noProductsParentDiv: {
        marginTop: 20,
        textAlign: 'center',
    },
    noProductsDiv: {
        display: 'inline-block',
    },
    normal_price: {
        fontWeight: 'bold',
        color: '#1979c3',
    },
    special_price: {
        marginRight: 5,
        color: '#1979c3',
        fontWeight: 'bold',
    },
    strike_through_price: {
        color: '#A9A9A9',
    },
    titleAnchor: {
        textDecoration: 'none',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progressDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    pageNumbersHeader: {
        //paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 1,
    },
    progressStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    mobileAppBar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    noResult:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
        minHeight: '500px',
        flexDirection:'column',
    }
});

//used for transition of filter options in mobile view
function Transition(props) {
    return <Slide direction="right" {...props} />;
}

/*****************************************************************************
 * Search Results Display Component. Gets the products after searching   
 * from Redux Store and populate the responsive page for
 * Search Results display.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
let _this = this;
window.scrolled = false;
class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemFrom: 0,
            itemTo: 0,
            total_count: 0,
            pageNumber: 0,
            // Flag for displaying loading indicator in child component.
            isLoading: false,
            searchTerm: this.props.location.state ? this.props.location.state.searchTerm : '',
            isfilterLoading:true,
            addremovenumber:1
        };
        this.lazyload = this.lazyload.bind(this);
        _this = this;
        // window.onscroll = function(ev) {
        //     if((window.srpTotalCount&&window.stopSrpLazyLoadCount)&&(window.srpTotalCount ==window.stopSrpLazyLoadCount)){
        //         _this.setState({
        //             addremovenumber:_this.state.addremovenumber-1
        //         })
        //     }
        //     if ((window.innerHeight + window.scrollY + _this.state.addremovenumber) >= document.body.offsetHeight) {
        //         if(!window.srpTotalCount || !window.stopSrpLazyLoadCount){
        //             _this.setState({
        //                 pageNumber:_this.state.pageNumber+1
        //             })
        //             if(_this.props.searchProducts){
        //                 _this.lazyload(_this.state.pageNumber);
        //             }
        //         }else if(window.srpTotalCount != window.stopSrpLazyLoadCount){
        //             _this.setState({
        //                 pageNumber:_this.state.pageNumber+1
        //             })
        //             if(_this.props.searchProducts){
        //                 _this.lazyload(_this.state.pageNumber);
        //             }
        //         }
        //     }
        // };
        if(!this.props.searchProducts){ 
            this.lazyload(this.state.pageNumber);
        }
        
        $(window).on("scroll", function () {
            if(window.location.href.includes('/search')){
            if($(window).scrollTop() + $(window).height() > $(document).height() -100) {
                if(window.scrolled===false&&window.srpTotalCount !== window.stopSrpLazyLoadCount){
                    _this.setState({
                        pageNumber:_this.state.pageNumber+1
                    })
                    if(_this.props.searchProducts){
                        _this.lazyload(_this.state.pageNumber);
                    }
                    window.scrolled = true;
                }
            }
        }
        });
    }
    lazyload(pageNumber){
        const productParameters = getConfigValue("product");
        if(this.props.searchProducts){
            this.getPaginatedProductsFromSearch(pageNumber, productParameters.productsPerPage, this.props);
        }
    }
    // Use a single method (getPaginatedProductsFromSearch) to fetch and 
    // display products
    componentDidMount() {
        this.setState({ isLoading: true,isfilterLoading:true });
        const productParameters = getConfigValue("product");
        //Since the API has Page Numbers starting from 0.
        this.getPaginatedProductsFromSearch(0, productParameters.productsPerPage, this.props);
    }
    
    // After pagination happens, scroll to top of the page.
    // Also, render the range of products being displayed.
    componentDidUpdate(prevProps) {
        // window.scrollTo(0, 0);
        const productParameters = getConfigValue("product");
        
        //render the product ranges (Example: 1 to 8 of 32)
        if (this.props.searchProducts !== prevProps.searchProducts) {
            this.getProductDisplayRanges(this.state.pageNumber, productParameters.productsPerPage);
            this.setState({ isLoading: false });
        }
        //render the product by sorting
        if (this.props.selectedSortOption !== prevProps.selectedSortOption) {
            this.getPaginatedProductsFromSearch(0, productParameters.productsPerPage, this.props);
        }
        //if(this.props.location)
    }
    
    componentWillReceiveProps(nextprops) {
        if(this.props.location!==nextprops.location){
            this.setState({isfilterLoading:true})
        }
        if (nextprops.searchTerm !== this.props.searchTerm) {
            this.setState({ isLoading: true });
            const productParameters = getConfigValue("product");
            this.getPaginatedProductsFromSearch(0, productParameters.productsPerPage, nextprops);
        }
        else if(nextprops.location.search && nextprops.location.search !== undefined&&this.state.isfilterLoading){
                this.setState({ isLoading: true,isfilterLoading:false });
                const productParameters = getConfigValue("product");
                this.getPaginatedProductsFromSearch(0, productParameters.productsPerPage, nextprops);
        }
        

    }
    // Method to render the page range. Pagination starts from 0.
    getProductDisplayRanges = (pageNumber, pageSize) => {
        if (!(typeof this.props.searchProducts === 'undefined')) {
            this.setState({ itemFrom: (pageNumber) * pageSize + 1 });
            this.setState({ itemTo: ((pageNumber + 1) * pageSize > this.props.searchProducts.total_count) ? this.props.searchProducts.total_count : (pageNumber + 1) * pageSize })
            this.setState({ total_count: this.props.searchProducts.total_count ? this.props.searchProducts.total_count : 0 });
        }
    }

    //Callback function to get Page Number from Common Pagination Component
    getPaginatedProductsFromSearch = (pageNumberIn, pageSize, props) => {
        let filterOption ='';
        if(typeof props === 'undefined') {
            props = this.props;
        }
        if (props.searchTerm && props.searchTerm === ''){
            return;
        }
            
        //Pass Search Term and page number to be displayed as a parameter  
        this.setState({ isLoading: true });
        //if clicked any filter get the filter value from URL params here and filterOption is passed to dispatch
        if (props.location.search && props.location.search !== undefined) {
            let queryParams = new URLSearchParams(props.location.search);
            if (queryParams.get('Size' === 'null')) {
                filterOption = queryParams.get('Brand');
                
            }
            else {
                filterOption = queryParams.get('Size');            
            }
        }
        //filterOption={'filterOptionSize':filterOptionSize,'filterOptionBrand':filterOptionSize}
        //Redux dispatch.
        this.props.getProductsFromSearch(props.searchTerm ? props.searchTerm:props.location.state.searchTerm, pageNumberIn, props.selectedSortOption,filterOption,this);

        //Since the API has Page Numbers starting from 0.
        this.setState({ pageNumber: pageNumberIn });
    }
    toggleDrawer = () => {
        this.setState((prevState) => ({
            leftOverlay: !prevState.leftOverlay
        }));
    };
    render() {
        if (!this.props.searchProducts) {
            return (
                <ContentLoader isLoading={true} />
            );
        }
        window.stopSrpLazyLoadCount = (this.props.searchProducts) && this.props.searchProducts.items.length;
        let { classes } = this.props;
        let { searchProducts } = this.props;
        const productParameters = getConfigValue("product");
        return (
            <div className={classes.root}>
                 <Grid container>
                 <CategoryChips/>
                 <Grid container justify="center">
                    {/* For devices with less width, hide the Left Shopping Options */}
                    <Hidden only={['sm', 'xs']}>
                        <Grid item md={2} >
                            <FilterInSearch filterForSearch={searchProducts&&searchProducts.filters} />
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item xs={9} style={{textAlign:'center'}}>
                            <Button variant="outlined" color="primary"  onClick={this.toggleDrawer} style={{width:'50%',marginTop:'10px',marginBottom:'10px'}}>
                                {<GetLanguage value="Filters"/>}
                            </Button>
                            <Dialog fullScreen open={this.state.leftOverlay}
                                onClose={this.toggleDrawer} TransitionComponent={Transition}>
                                <AppBar className={classes.appBar} color="default">
                                    <Toolbar disableGutters={true} className={classes.mobileAppBar}>
                                        <Typography variant="h6" color="inherit" className={classes.flex}>
                                        {<GetLanguage value="Filters"/>}
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.toggleDrawer} aria-label="Close">
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <FilterInSearch filterForSearch={searchProducts&&searchProducts.filters}/>
                            </Dialog>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={12} lg={9}>
                        <ProductListingGrid
                            itemFrom={this.state.itemFrom}
                            itemTo={this.state.itemTo}
                            isLoading={this.state.isLoading}
                            searchTerm={this.state.searchTerm}
                            {...this.props}>
                        </ProductListingGrid>
                        <div className={classes.pagination}>
                            {/* {
                                searchProducts &&
                                // If the number of products are less than items per page, 
                                // do not display Pagination Component
                                // (this.props.searchProducts[0].total_count > productParameters.productsPerPage) &&
                                (this.props.searchProducts.total_count > productParameters.productsPerPage) &&
                                <PaginationComponent
                                    // Since the pagination starts from 0.
                                    onPageChange={(pageNumber, pageSize) => this.getPaginatedProductsFromSearch(pageNumber - 1, pageSize)}
                                    itemsPerPage={productParameters.productsPerPage}
                                    totalItems={this.state.total_count}
                                >
                                </PaginationComponent>
                            } */}
                        </div>
                    </Grid>
                </Grid>
                </Grid>
            </div>
        );
    }
}

/***************************************************************************
 * Product Listing Grid that populates the products from the search result.
 * Responsive Layout. 
 * In case, there are no products, an information message 
 * is displayed (using common message component.)
 * Product Image dimensions are taken from common configuration.
 **************************************************************************/
class ProductListingGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: this.props.location.state ? this.props.location.state.searchTerm : '',
        }
    }
    componentWillReceiveProps(nextprops){
        if(this.props.searchTerm!==nextprops.searchTerm){
            this.setState({searchTerm:nextprops.searchTerm})
        }
    }
    render() {
        let { classes } = this.props;
        const { searchProducts, plpViewSelected } = this.props;
        const { itemFrom, itemTo } = this.props;
        const { isLoading } = this.props;
        const { searchTerm } = this.state;

        // On initial load of the component, display progress bar
        // as overlay.
        // if (!searchProducts) {
        //     return (
        //         <ContentLoader isLoading={true} />
        //     );
        //     // return null;
        // }

        //In case, there are no products for this category, display a message.
        if (searchProducts && searchProducts.length === 0) {
            return (
                // <PageTitle title='No Results Found' />
                <Grid text className={classes.noResult}>
                        <h3>
                            Sorry,no results found!
                        </h3>
                        <p>
                            Please check the spelling or try<br></br>
                            searching for something else
                        </p>

                </Grid>

            )
        }
                
        return (
            <div>
                <Grid container justify='space-between' alignItems='baseline'>
                    <Grid item className={classes.pageNumbersHeader}>
                        {
                            searchProducts &&
                            <Typography variant="body2">
                                {this.props.itemTo&&this.props.itemTo >= 1 ? '1' : '0'} - {this.props.searchProducts&&this.props.searchProducts.items.length} of 
                                {this.props.searchProducts.total_count&&this.props.searchProducts.total_count} results for '{this.props.searchTerm }'
                                {/* {itemFrom && 
                                    itemFrom.toString()
                                    .concat(" - ")
                                    .concat(itemTo.toString())
                                    .concat(" of ")
                                    .concat(searchProducts.total_count.toString())
                                    .concat(" results for ")
                                    .concat('"')
                                    .concat(searchTerm)
                                    .concat('"')} */}
                            </Typography>
                        }
                    </Grid>
                    {/* <Grid item>
                        <PageTitle title={this.state.searchTerm} />
                    </Grid> */}
                    <Grid item>
                        <Grid container direction='row' alignItems='flex-end'>
                            <Grid item>
                                <PLPView />
                            </Grid>
                            <Grid item>
                                <SortView />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {
                    <ContentLoader isLoading={isLoading} />
                } */}
                <Grid container className={classes.productListingGrid} justify="flex-start" alignItems="flex-start" >
                    {
                        searchProducts && searchProducts.items && searchProducts.items.map((productTile, index) =>
                            <Grid key={index} item xs={plpViewSelected ? 12 : 6} sm={plpViewSelected ? 12 : 4} md={plpViewSelected ? 12 : 4} lg={plpViewSelected ? 12 : 3} className={classes.sliderdiv}>
                                <Paper className={classes.paper}>
                                    <ProductCatalog productTile={productTile} />
                                </Paper>
                            </Grid>
                        )}
                </Grid>
            </div>
        );
    }
}


SearchResults.propTypes = {
    classes: PropTypes.object.isRequired,
    searchProducts: PropTypes.object,
};


const SearchResultsErrorFallbackComponent = ({ componentStack, error }) => {
    return (
        <PageTitle title="Failed to load search results, please try again." />
    );
}

const SearchResultsWithErrorBoundary = withErrorBoundary(
    SearchResults,
    SearchResultsErrorFallbackComponent
);


/*****************************************************************************
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    searchProducts: state.get_products_search.searchProductsData,
    plpViewSelected: state.plpView,
    selectedSortOption: state.selectedSort,
});

const mapDispatchToProps = dispatch => ({
    getProductsFromSearch: (searchTerm, currentPage, selectedSortOption,filterOption,props) => {
        let cs = new CategoryService();
        dispatch(cs.getProductsFromSearchActionCreator(searchTerm, currentPage, selectedSortOption,filterOption,props));
    }
});

const SearchResultsWithStyles = withStyles(styles)(SearchResultsWithErrorBoundary);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResultsWithStyles));