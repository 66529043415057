/**
* MiniCart Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Counter from  '../common/Counter';
import Button from '@material-ui/core/Button';
import { formatPriceOfProduct } from  '../../framework/common/processString';
import { Link } from 'react-router-dom';
//Redux related
import { connect } from 'react-redux';
import { decodeHTMLEntities } from  '../../framework/common/helpers';
//Image dimensions fetched from configuration.
import getConfigValue from  '../../framework/config/configHandler';
import { mediaURLBasePath } from  '../../framework/common/helpers';
//Price display formatting
import { formatPriceOfProductforCategoryListing } from  '../../framework/common/helpers';
import CartService from '../../modules/cart/CartService';

const styles = theme => ({
    root: {
        padding: '0.5rem',
        minWidth: '350px',
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    viewminiCartButton: {
        whiteSpace: 'pre',
    },
    miniCartRoot: {
        padding: '0.5rem 0rem 1rem 0rem',
        borderBottom: '1px solid #D3D3D3',
    },
    miniCartSubTotal: {
        padding: '0.4rem 0rem',
    },
    miniCartButtons: {
        padding: '0rem 0.4rem 0rem 0.4rem',
    },
    image: {
        width: 75,
        height: 80,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    noItems: {
        padding: '1rem',
    },
    textDecorationNone: {
        textDecoration: 'none',        
    },    
    productDescription : {
        maxWidth : '200px'
    },
    cartContent : {
        maxHeight:'280px',
        overflowY:'scroll',
    }
});
/***************************************************************
 * Component class for displaying Mini Cart
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class MiniCart extends React.Component {
    handleMiniShoppingCartClose = () => {
        this.props.onCloseMiniShoppingCart();
    }
    render() {
        const { classes, miniShoppingCartOpen, onClose } = this.props;        
        const { miniCartDetails, cart_product_count } = this.props;
        return (
            <div>
                <Drawer xs={12} sm={8} md={6}
                    anchor="right"
                    open={miniShoppingCartOpen}
                    onClose={onClose}                                        
                    >
                    <div className={classes.root}>
                        <div className={classes.closeIcon}>
                            <IconButton onClick={this.handleMiniShoppingCartClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        {
                            miniCartDetails && miniCartDetails["miniCartDetails"] && miniCartDetails["miniCartDetails"].map((product, index) =>

                                <ShoppingCartItemsMiniCart
                                    key={index}
                                    productName={product.name}
                                    image_url={product.extension_attributes.image_url}
                                    product_option={product.product_option ? product.product_option : null}
                                    product_url={product.product_url}
                                    sku={product.sku}
                                    item_id={product.item_id}
                                    itemPrice={formatPriceOfProduct(product.price)}
                                    quantity={product.qty}
                                    onDeleteItemFromCart={() => { }}
                                    {...this.props}>
                                </ShoppingCartItemsMiniCart>
                            )
                        }
                        <MiniCartSubTotal {...this.props} />
                        {(cart_product_count > 0) ? <ViewAndEditCart {...this.props} /> : ""}
                    </div>
                </Drawer>
            </div>
        );
    }
}

/***************************************************************
 * Component class for displaying Mini Cart Buttons
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ViewAndEditCart extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid item xs={12} sm container className={classes.miniCartButtons}>
                <Grid item xs={6} container direction="column" alignItems="flex-start">
                    <Grid item xs>
                        <Button
                            size="large"
                            variant="outlined"
                            component={Link}
                            to="/cart"
                            color="primary" aria-label="View Cart"
                            className={classes.viewminiCartButton}
                        >
                            View Cart
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={6} container direction="column" alignItems="flex-end" spacing={8}>
                    <Grid item xs>
                        <Button size="large" variant="contained"
                            component={Link}
                            to="/checkout"
                            color="primary" aria-label="Checkout">Checkout</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

/***************************************************************
 * Component class for displaying Mini Cart Subtotal
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class MiniCartSubTotal extends React.Component {
    render() {
        const { classes } = this.props;
        const { cart_product_count, cart_product_cost } = this.props;
        if (cart_product_count && cart_product_count > 0) {
            return (
                <Grid item xs={12} sm container className={classes.miniCartSubTotal}>
                    <Grid item xs={10} container direction="column" spacing={16} alignItems="flex-start">
                        <Grid item xs>
                            <Typography variant="subtitle1">{cart_product_count} items</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} container direction="column" alignItems="flex-end">
                        <Grid item xs>
                            <Typography variant="subtitle1">{formatPriceOfProductforCategoryListing(cart_product_cost)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container direction="row" alignItems="flex-start" justify="center">
                <Typography className={classes.noItems}>No items in Cart</Typography>
            </Grid>
        );
    }
}

/***************************************************************
 * Component class for displaying Mini Cart items
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ShoppingCartItemsMiniCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productQuantity: 1,
        };
    }

    //Callback after setting the value in Counter Component
    onQuantiyCounterChange = (quantityChanged, item_id) => {
        this.setState({ productQuantity: quantityChanged });

        //Dispatch to change the quantity in the cart.
        this.props.onEditItemFromCart(quantityChanged, item_id);
    }

    processProductOptions = (product_option) => {
        if (product_option === null) return "";
        let configurable_item_options_list = product_option.extension_attributes.configurable_item_options;
        let productOptionsString = [];
        for (let index = 0; index < configurable_item_options_list.length; index++) {
            productOptionsString.push(configurable_item_options_list[index].value);
        }
        //Return product option string with separators.
        return productOptionsString.join(" / ");
    }

    render() {
        const { classes } = this.props;
        const {
            productName,
            itemPrice,
            quantity,
            item_id,
            image_url,
            product_url,
            product_option,
            onDeleteItemFromCart
        } = this.props;
        const orderParameters = getConfigValue("order");
        const productImgDimensions = getConfigValue("product");

        return (
            <Grid container className={classes.miniCartRoot} spacing={8}>
                <Grid item className={classes.cartContent}>
                    <ButtonBase className={classes.image}>
                        <img
                            className={classes.img}
                            width={productImgDimensions.image.dimension.width}
                            height={productImgDimensions.image.dimension.height}
                            alt={decodeHTMLEntities(productName)}
                            src={mediaURLBasePath + image_url} />
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid className={classes.productDescription} item xs={9} container direction="column" spacing={8} alignItems="flex-start">
                        <Grid item xs>
                            <Link className={classes.textDecorationNone} to={product_url}>
                            <Typography variant="body1">
                          {decodeHTMLEntities(productName)}
                        </Typography>
                        </Link>
                            <ProductOptions
                                product_options={this.processProductOptions(product_option)} />
                        </Grid>
                        <Grid item>
                            <Counter
                                value={quantity}
                                min_number={1}
                                max_number={orderParameters.maxQuantity}
                                onCounterChange={(quantityChanged) => this.onQuantiyCounterChange(quantityChanged, item_id)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column" justify="flex-end">
                        <Grid item xs>
                            <Typography variant="body1">${itemPrice}</Typography>
                        </Grid>
                        <Grid item>
                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => onDeleteItemFromCart(item_id)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

/***************************************************************
 * Component class for displaying configurable options
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ProductOptions extends React.Component {
    render() {
        let { product_options } = this.props;
        return (
            <div>
                <Typography variant="caption" color="textSecondary">{product_options}</Typography>
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    onDeleteItemFromCart: (item_id) => {
        let cs = new CartService();
        dispatch(cs.DeleteFromCartActionCreator(item_id));
    },
    onEditItemFromCart: (quantityChanged, item_id) => {
        let cs = new CartService();
        dispatch(cs.EditQuantityInCartActionCreator(item_id, quantityChanged));
    },
}
);

const MiniCartWithStyles = withStyles(styles)(MiniCart);
export default connect(mapStateToProps, mapDispatchToProps)(MiniCartWithStyles);
