/**
* ErrorPage Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Logo from '../../../theme/base/assets/icons/magna.svg';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import TemplateWrapper from '../../../app/framework/hoc/templateWrapper';
import GetLanguage from '../../../library/GetLanguage';
const styles = theme => ({
    textDecoration: {
        textDecoration: 'none',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
    },
    flexItem: {
        margin: '0rem 1.25rem 0rem 1.25rem',
    }
});

/***************************************************************
 * Component to display error page
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ErrorPage extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.flexContainer}>
                <div className={classes.flexItem}>
                    <Grid container align="center" direction ="column">
                        <Grid item>
                            <img src={Logo} alt="logo" width="60" height="60" />
                        </Grid>
                        <Grid item>
                            <h1>{this.props.header}</h1>
                        </Grid>
                        <Grid item>
                            <Card>
                                <Typography variant="h5" align="center" gutterBottom>
                                {this.props.text}
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card>
                                <CardContent>
                                    <CardActions className={classes.actions}>
                                        <Typography variant="subtitle2" gutterBottom >
                                            <b>{<GetLanguage value="VISIT THE"/>}</b>
                                        </Typography>
                                        {/* Force Update is used to go to Home Page */}
                                        <Link to='/' onClick={this.forceUpdate} className={classes.textDecoration}>
                                            <Typography variant="subtitle2"  color="primary" ><b>{<GetLanguage value="HOMEPAGE"/>}</b></Typography>
                                        </Link>
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(TemplateWrapper(ErrorPage,'empty'));