/**
* Component for displaying Mobile Menu.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import NavigationMobileUnit from './NavigationMobileUnit';
import CategoryService from '../../../../app/modules/category/CategoryService';
import Grid from '@material-ui/core/Grid';
import UpdateIcon from '@material-ui/icons/Update';
import AlarmOn from '@material-ui/icons/AlarmOn';
import Typography from '@material-ui/core/Typography';
import Languages from '../../layouts/headerbar/Languages';
import { Link } from 'react-router-dom';
import BulkOrder from '../../layouts/headerbar/BulkOrder';
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import Call from '@material-ui/icons/Call';
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemsStyle: {
        display: 'flex',
        padding: '0 24px 0 24px',
        minHeight: '48px'
    },
    ulIcon: {
        fontSize: '25px'
    },
    customFont: {
        fontSize: '16px'
    },
    phoneNumber: {
        fontSize: '12px',
        textDecoration: 'none',
        color: '#000000',
    },
});

/***************************************************************
 * Component class for displaying Category Menu.
 * Passes data to Child Component to render the Mobile Category
 * Menu.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class NavigationMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // Dispatch action to Redux to fetch category data
    componentDidMount() {
        /***************************************************************************** 
        * When the app is loaded for first time, the category
        * navigation menu data is fetched. Subsequent page transitions
        * will not fetch the category data again and the redux stored
        * menu data is used. 
        *****************************************************************************/
        if (typeof (this.props.categoryData) === 'undefined') {
            //previously we need to call API over here but moved it to headerbar
            // this.props.getCategoryData();
        }
    }

    //Get the submenu at Level 3 for the given menu.
    handleSubMenu = (cat) => {
        if (cat["children_data"] !== []) {
            let subCat = [];
            cat["children_data"] && cat["children_data"].map((sub_cat_item, index) => {
                return subCat.push([sub_cat_item.name, sub_cat_item.id, sub_cat_item.url, sub_cat_item.children_data]);
            })
            return subCat;
        }
        else {
            return [];
        }
    }

    getSelected(id, cat_url) {
        let url = window.location.href.split('/');
        let caturl = cat_url.split('/');
        if (url[url.length - 1] === caturl[caturl.length - 1]) {
            return (true)
        }
        else if (url[url.length - 1] === '' && caturl[caturl.length - 1] === 'home.html') {
            return (true);
        }
        else {
            return (false)
        }
    }
    render() {

        let userInfo = getItemFromLocalStorage('shopce-user');
        let isSalesManUser = getItemFromLocalStorage('sales-man');
        
        const { classes, mobileOpen, onClose } = this.props;
        const { categoryData } = this.props;
        return (
            <Drawer open={mobileOpen} onClose={onClose}>
                <div className={classes.root}>
                    {
                        categoryData && categoryData.map((cat, index) => {
                            if (cat.is_active && cat.include_in_menu) {
                                return (
                                    <div key={index} >
                                        {cat.name !== 'Premium Goods' ?
                                               
                                    <NavigationMobileUnit key={index} panelID={index}
                                        topmenu={[cat.name, cat.id, cat.url, cat.children_data]}
                                        submenu={this.handleSubMenu(cat)}
                                        selected={this.getSelected(cat.id, cat.url)}
                                        {...this.props}>
                                    </NavigationMobileUnit>
                                    :
                                    cat.name === 'Premium Goods' && isSalesManUser !== null && isSalesManUser !== undefined &&
                                    <NavigationMobileUnit key={index} panelID={index}
                                        topmenu={[cat.name, cat.id, cat.url, cat.children_data]}
                                        submenu={this.handleSubMenu(cat)}
                                        selected={this.getSelected(cat.id, cat.url)}
                                        {...this.props}>
                                    </NavigationMobileUnit>
                                        }
                                    </div>
                                );
                            }
                        })
                    }
                    <Grid container direction="column" alignItems="flex-start" style={{ background: '#fff' }}>
                        <Grid item className={classes.itemsStyle}>
                            <Languages />
                        </Grid>
                        <Grid item className={classes.itemsStyle}>
                            <Grid container justify="center" alignItems="center">
                                <Grid item>
                                    <UpdateIcon color="secondary" className={classes.ulIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.customFont}>
                                        <Link style={{ textDecoration: 'none', color: '#000' }} to='/myorders'>Reorder</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {userInfo &&
                            <Grid item className={classes.itemsStyle}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item >
                                        <AlarmOn color="secondary" className={classes.ulIcon} />
                                    </Grid>
                                    <Grid item>
                                        <BulkOrder />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Drawer>
        );
    }
}

NavigationMobile.propTypes = {
    classes: PropTypes.object.isRequired,
};

/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    is_salesman_user: state.is_salesman_user,
    categoryData: state.categoryDataState.categoryData
});

const mapDispatchToProps = dispatch => ({
    getCategoryData: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getCategoryDataForMenuActionCreator());
    }
});

const NavigationMobileWithConnect = connect(mapStateToProps, mapDispatchToProps)(NavigationMobile);
export default withStyles(styles)(NavigationMobileWithConnect);
