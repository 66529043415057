/**
* Logo Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
//import LogoImage from '../../../../theme/th/assets/images/ULTranparentLogo.png';
import NewLogoImage from './../../../../theme/th/assets/images/NewLogo.png';



/***************************************************************
  * Component class for displaying Logo
  * @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class Logo extends React.Component {

    handleMovetoTop() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Link to="/" onClick={() => this.handleMovetoTop()}>
            <img src={NewLogoImage} alt="Logo" style={{width:'63%',marginTop:'40px'}}  />
            </Link>
        )
    }
}

export default Logo;