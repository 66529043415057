/**
* CartButton Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import GetLanguage from '../../../library/GetLanguage';

const styles = theme => ({
    viewminiCartButton: {
        whiteSpace: 'pre',
        height: '42px',
        fontSize:'12px',
        background:'#F73455 !important',
        color:'#fff',
        boxShadow:'none !important',
    },
});

/***************************************************************
 * Component class for displaying Cart Button and submenu 
 * on hover. Submenu includes items added to cart.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CheckoutButton extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Fab variant="extended" className={classes.viewminiCartButton}
            component={Link} to="/checkout" aria-label="Checkout">
                <Check style={{color:'#fff'}} />{<GetLanguage value="Checkout"/>}</Fab>
        );
    }
}
const CheckoutButtonWithStyles = withStyles(styles)(CheckoutButton);
export default CheckoutButtonWithStyles;