/**
* Store for Redux.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../api/ReduxReducers';
//Redux Dev Tools
import { composeWithDevTools } from 'redux-devtools-extension';
//Logging Middleware
const logger = (store) => (next) => (action) => {
    if (typeof action !== "function") {
        // console.log('dispatching-->:', action);
    }
    return next(action);
}

/*********************************************************************
 * Calling createStore to setup store for Redux with the reducers.
 * Thunk middleware is applied.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ********************************************************************/
const magnaStore = createStore(
    reducers,
    // To enable Redux DevTools
    // createCookieMiddleware(Cookies) is to enable cookie access in Redux
    composeWithDevTools(
        applyMiddleware(logger, thunk),
    )
);
export default magnaStore;