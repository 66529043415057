/**
* Redux Reducer. 
* Lists the reducer functions and corresponding 
* sub-state in the Store.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import { combineReducers } from 'redux';
import AccountService from '../../../app/modules/account/AccountService';
import CategoryService from '../../modules/category/CategoryService';
import CheckoutService from '../../modules/checkout/CheckoutService';
import ProductService from '../../../app/modules/product/ProductService';
import CartService from '../../modules/cart/CartService';
import ApplicationService from '../../modules/application/ApplicationService';
import WishlistService from '../../../app/modules/wishlist/WishlistService';
import OrderService from '../../../app/modules/order/OrderService';
import CommonService from '../../modules/common/CommonService';
import SalesManService from '../../modules/salesman/SalesManService';
import RequisitionService from '../../modules/requisition/RequisitionService';

import FooterService from '../../modules/footer/FooterService';
import RegistrationService from '../../modules/registration/RegistrationService';

const rootReducer = combineReducers({
    current_key_image: ProductService.current_key_image,
    targetPathState: ApplicationService.PostTargetPathReducer,
    //Sign In Customer
    post_sign_in_customer_state: AccountService.post_sign_in_customer_reducer,
    //User details
    get_user_details_state: AccountService.get_user_details_reducer,

    get_updated_user_details_state: AccountService.get_updated_user_details_reducer,
    //set submenu state
    set_sub_menu_state: AccountService.setSubMenuReducer,
    //Sign Up Customer
    post_sign_up_customer_state: AccountService.post_sign_up_customer_reducer,
    // Category Data for the navigation menu
    categoryDataState: CategoryService.GetCategoryDataReducer,
    //Get Category Products for a category.
    get_category_products: CategoryService.GetCategoryProductsReducer,
    //Get Category CMS
    get_category_cms: CategoryService.GetCategoryCMSReducer,
    //Get Product Detail Data
    get_product_detail: ProductService.GetProductDetailDataReducer,
    //Post Add to Cart Reducer
    post_add_to_cart_state: ProductService.post_add_to_cart_reducer,
    //Get Mini Cart
    get_minicart_details: CartService.getMiniCartDetailsReducer,
    //Add to Cart total and result
    result_add_to_cart_state: ProductService.addToCartTotalReducer,
    //Forgot Password
    post_forgot_password_state: AccountService.post_forgot_password_reducer,
    //Reset password
    reset_password_state: AccountService.ResetPasswordReducer,
    //Result Reset Password State
    result_reset_forgot_password_state: AccountService.ResetPasswordReducer,
    //DELETE from Cart 
    delete_from_cart_state: CartService.deleteFromCartReducer,
    //Empty Cart 
    empty_cart_state: CartService.emptyCartReducer,
    //PUT for updating quantity of an item in Cart
    put_update_quantity_in_cart: CartService.updateQuantityCartReducer,
    //Shipping Address
    get_shipping_address: CheckoutService.shipping_address_reducer,
    //post shipping details
    post_update_shipping_details: CheckoutService.post_shipping_details_reducer,
    //GET Order Summary Details
    get_order_summary_details: CartService.GetOrderSummaryDetailsReducer,
    //GET products from Search
    get_products_search: CategoryService.GetProductsFromSearchReducer,
    //POST Payment Option
    post_payment_options_state: CheckoutService.post_payment_options_reducer,
    //PUT Place Order
    put_place_order_state: CheckoutService.put_place_order_reducer,
    //GET CMS Page Content
    cms_page_content_state: CategoryService.cms_page_content_reducer,
    //GET Category Title
    category_title_state: CategoryService.category_title_reducer,
     //GET PDP Breadcrumbs
     pdp_breadcrumbs_state: CategoryService.pdp_breadcrumbs_reducer,
     //GET PLP Breadcrumbs
     plp_breadcrumbs_state: CategoryService.plp_breadcrumbs_reducer,
    //GET CMS Page Widget Data for display on Home Page
    get_cms_page_widget_data1_state: CategoryService.getCMSPageWidgetData1Reducer,
    //GET CMS Page Widget Data for display on Home Page
    get_cms_page_widget_data2_state: CategoryService.getCMSPageWidgetData2Reducer,
    //GET CMS Page Widget Data for display on Home Page
    get_cms_page_widget_data3_state: CategoryService.getCMSPageWidgetData3Reducer,
    //Get Category Products for a category.
    get_category_filter_options: CategoryService.GetCategoryFilterOptionsReducer,
    //Get Countries List.
    get_countries_list: CheckoutService.get_countries_list_reducer,
    //Put Address in the List.
    put_address_list: CheckoutService.put_address_reducer,
    //Verify whether token is valid.
    is_token_valid: AccountService.IsTokenValidReducer,
    //get wishlisted products.
    get_wishlisted_products: WishlistService.WishlistedProductsReducer,
    //wishlist a product.
    put_product_in_wishlist: WishlistService.WishlistProductReducer,
    //delete wishlisted product.
    delete_product_in_wishlist: WishlistService.DeleteWishlistedProductReducer,
    // Move to Wishlist from Cart
    move_to_wishlist_from_cart_state: CartService.moveToWishlistReducer,
    // Send Data based on sku 
    variant_selected: CartService.selectedVariant,
    // Get Signed In User Info
    signedInUserInfoState: AccountService.SignedInUserInfoReducer,
    // PUT Profile name change reducer
    signedInUserNameChangeState: AccountService.put_profile_name_update_reducer,
    // POST Set an address as default address
    setAsDefaultAddressState: CheckoutService.post_set_as_default_address_reducer,
    // Delete an address from address page
    deleteAddressState: CheckoutService.DeleteMyAddresstReducer,
    //Change Customer Password
    put_change_password_state: AccountService.changePasswordReducer,
    // newsletter subscription
    subscriptionState: AccountService.isSubscribedReducer,
    // price update
    pdpPriceState: ProductService.product_price_reducer,
    //Order
    get_orders: OrderService.get_order_history,
    get_loader_status: CommonService.get_loader_status,
    get_carrosel_products: CategoryService.GetCarroselProducts,
    //get crosssell products for cart
    get_crosssell_products: CategoryService.GetCrossSellProductsReducer,
    get_filter_tag_chips : CategoryService.getFilterTagsDataReducer,
    get_carrosel_productcategories: CategoryService.GetCarroselProductCategories,

    get_carrosel_productcategory_images: CategoryService.GetCarroselProduCategorieImages,
    
    //Get List of customers for sales man
    get_customerlist_for_salesman: SalesManService.get_customerlist_for_salesman_reducer,
    //Get List of customers for sales man
    salesman_signin_as_customer: SalesManService.salesman_sign_in_ascustomer_reducer,
    is_salesman_user: SalesManService.is_salesman_user,
    languageObject: ApplicationService.PostLanguageReducer,
    //Set PLP View
    plpView: ApplicationService.SetPLPViewReducer,
    //Set Sort Option
    selectedSort: CategoryService.SetSortOptionReducer,
    //Get Requisition List
    getRequisitionList: RequisitionService.RequisitionListReducer,
    //Create Requisition List
    createdRequisitionList: RequisitionService.CreateRequisitionListReducer,
    //Set Selected Entity
    selectedEntity: RequisitionService.SetEnitityReducer,
    //Delete Selected Req List
    deleteRequisitionList: RequisitionService.DeleteRequisitionListReducer,
    //Set Item in Selected Req List
    selectedItemInReq: RequisitionService.SelectedItemInReqReducer,
    //Get Id of Selected Req List
    getReqListId: RequisitionService.SetReqListIdReducer,    
    //Delete Item in Selected Req List
    deleteItemInReqList: RequisitionService.DeleteItemInReqReducer,
    //Open/Close Req modal
    openCreateReqModal: RequisitionService.OpenCreateReqModalReducer,
    //Add Req Items to Cart
    addedReqItemsToCart: RequisitionService.AddItemInReqToCartReducer,
    get_homepage_banners: CategoryService.GetHomePageBannersReducer,
    // get home page brands
    get_homepage_brands: CategoryService.getBrandsDataReducer,
    // get footer details
    get_footer_details:FooterService.GetFooterDetailsReducer,
    // subscribe notification for out of stock 
    post_notify_outofstock_state:ProductService.post_notify_out_of_stock_reducer,
    // get sales man details
    getSalesManDetails:AccountService.salesManSignInReducer,
    // get sales man details
    getEstimatedDate: CheckoutService.GetEstimateDeliveryDateReducer,
    //Registered Mobile Number
    post_registered_mobnum_state: RegistrationService.post_registered_mobile_number_reducer,
    //OTP Reducer
    post_registered_mobile_otp_state :RegistrationService.post_registered_mobile_otp_reducer,
    //Registration reducer
    post_registered_details_state:RegistrationService.post_registered_details_reducer,
    //Footer CMS content
    GetFooterCMSReducer: FooterService.GetFooterCMSReducer,
    //Get Salesman Details
    GetSalesmanProfileDetails: SalesManService.GetSalesmanProfileDetailsReducer,
    //Get USP's Details
    GetUSPDetails: ApplicationService.GetUSPReducer,
    //Get Category Name
    GetCategoryName: CategoryService.GetCategoryNameReducer,

      //Get Salesman Details
      set_updated_salesman_details_state: SalesManService.set_updated_salesman_details_reducer,

      set_salesman_profile_details_state: SalesManService.set_salesman_profile_details_reducer,
 
});

export default rootReducer;
