/**
* RequisitionService class with functions for handling Requisition related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import {
    REQUEST_GET_REQUISITION_LIST,
    RECIEVE_GET_REQUISITION_LIST,
    REQUEST_CREATE_REQUISITION_LIST,
    RECIEVE_CREATE_REQUISITION_LIST,
    REQUEST_SET_REQUISITION_OBJ,
    RECIEVE_SET_REQUISITION_OBJ,
    REQUEST_DELETE_REQUISITION_LIST,
    RECIEVE_DELETE_REQUISITION_LIST,
    REQUEST_SET_ITEM_IN_REQUISITION_LIST,
    RECIEVE_SET_ITEM_IN_REQUISITION_LIST,
    REQUEST_DELETE_ITEM_IN_REQUISITION_LIST,
    RECIEVE_DELETE_ITEM_IN_REQUISITION_LIST,
    REQUEST_SET_REQUISITION_MODAL,
    RECIEVE_SET_REQUISITION_MODAL,
    REQUEST_ITEM_IN_REQUISITION_TO_CART,
    RECIEVE_ITEM_IN_REQUISITION_TO_CART,
    RECIEVE_SET_REQUISITION_LIST_ID,
    REQUEST_UPDATE_REQUISITION_LIST,
    RECIEVE_UPDATE_REQUISITION_LIST,
    SET_LOADER_STATUS
} from '../../framework/common/ReduxConstants';
import Cookies from 'js-cookie';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import { NotificationManager } from 'react-notifications';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
class RequisitionService {

    /**********************************************************************************
     * Action creator to get Requisition List.
     *********************************************************************************/
    GetRequisitionListActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_REQUISITION_LIST });
            //Get token from localStorage
            let userToken = "";
            let customerId = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
                customerId = userFromLocalStorage.customerId;
            }
            if (userToken !== null && customerId !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/requisitionlist/' + customerId;
                let requestType = 'GET';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_GET_REQUISITION_LIST, success: true, response });
                    },
                    (error) => {
                        dispatch({ type: RECIEVE_GET_REQUISITION_LIST, success: false, error });
                    }
                );
            }
        }
    }
    /***************************************************************************** 
     * Redux Reducer to get RequisitionList
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     *****************************************************************************/
    static RequisitionListReducer = (state = '', action) => {
        switch (action.type) {
            case REQUEST_GET_REQUISITION_LIST:
                return '';
            case RECIEVE_GET_REQUISITION_LIST:
                if (action.success && action.response && action.response.data && action.response.data.length) {
                    return action.response.data[0].requisition_list;
                    // return RequisitionService.reSturctureRequisiteObject(action.response.data[0].requisition_list);
                }
            default:
                return state;
        }
    };
    //Update fields in the response to avoid rework in render method    
    static reSturctureRequisiteObject = (items) => {
        let newItems = {};
        let itemValues = Object.values(items);
        let itemKeys = Object.keys(items);
        itemValues && itemValues.length && itemValues.map((item, k) => {
            let products = Object.values(item.items);
            let keyValue = itemKeys[k];
            newItems[keyValue] = item;
            products.length && products.map((row, i) => {
                newItems[keyValue].items[row.item_id].qty = parseFloat(row.qty);
                newItems[keyValue].items[row.item_id].subtotal = parseFloat(row.qty * row.price);
                if (typeof row.custom_attributes !== 'undefined' && row.custom_attributes.length) {
                    row.custom_attributes.map(attr => {
                        if (attr.attribute_code === 'image') {
                            newItems[keyValue].items[row.item_id].imageSrc = attr.value;
                        } else if (attr.attribute_code === 'special_price') {
                            newItems[keyValue].items[row.item_id].price = attr.value;
                            newItems[keyValue].items[row.item_id].subtotal = (attr.value) * (row.qty);
                        }
                    })
                }
            })

        })

        return newItems;
    }
    /**********************************************************************************
     * Action creator to create Requisition List.
     *********************************************************************************/
    createNewRequisitionListActionCreator(createReqObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_CREATE_REQUISITION_LIST });
            dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let customerId = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
                customerId = userFromLocalStorage.customerId;
            }
            if (userToken !== null && customerId !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/requisitionlist';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.data = createReqObject;
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_GET_REQUISITION_LIST, success: true, response });
                        dispatch({ type: RECIEVE_CREATE_REQUISITION_LIST, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        NotificationManager.success('Requisition List Sucessfully Updated');
                    },
                    (error) => {
                        dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        }
    }
    /***************************************************************************** 
     * Redux Reducer to create RequisitionList
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     *****************************************************************************/
    static CreateRequisitionListReducer = (state = '', action) => {
        switch (action.type) {
            case REQUEST_CREATE_REQUISITION_LIST:
                return state;
            case RECIEVE_CREATE_REQUISITION_LIST:
                return action.response && action.response.status === 200;
            default:
                return state;
        }
    };
    /**********************************************************************************
     * Action creator to set Requisition List Enity.
     *********************************************************************************/
    SetEnitityActionCreator(obj) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_REQUISITION_OBJ });
            // dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let customerId = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
                customerId = userFromLocalStorage.customerId;
            }
            if (userToken !== null && customerId !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/requisitionlistitems';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.data = {
                    "customerId": customerId,
                    "reqlistId": obj.entity_id
                }
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_SET_REQUISITION_LIST_ID, success: true, Id:obj.entity_id });
                        dispatch({ type: RECIEVE_SET_REQUISITION_OBJ, success: true, response });
                        dispatch({ type: REQUEST_SET_ITEM_IN_REQUISITION_LIST });
                        // dispatch({ type: SET_LOADER_STATUS });
                    },
                    (error) => {
                        dispatch({ type: RECIEVE_SET_REQUISITION_LIST_ID, success: true, Id:obj.entity_id });
                        dispatch({ type: RECIEVE_SET_REQUISITION_OBJ, success: false, error });
                        dispatch({ type: REQUEST_SET_ITEM_IN_REQUISITION_LIST });
                        // dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        }
    }
    //Update fields in the response to avoid rework in render method    
    static reSturctureRequisiteItem = (items) => {
        let newItems = {};
        let itemValues = Object.values(items);
        let itemKeys = Object.keys(items);
        itemValues && itemValues.length && itemValues.map((item, k) => {
            newItems[itemKeys[k]] = item;
            newItems[itemKeys[k]].qty = parseFloat(item.qty);
            newItems[itemKeys[k]].subtotal = parseFloat(item.qty * item.price);
                if (typeof item.custom_attributes !== 'undefined' && item.custom_attributes.length) {
                    item.custom_attributes.map(attr => {
                        if (attr.attribute_code === 'image') {
                            newItems[itemKeys[k]].imageSrc = attr.value;
                        } else if (attr.attribute_code === 'special_price') {
                            newItems[itemKeys[k]].price = attr.value;
                            newItems[itemKeys[k]].subtotal = (attr.value) * (item.qty);
                        }
                    })
                }
        })
        return newItems;
    }
    //update existing selected enitity object
    UpdateEntityActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_REQUISITION_OBJ });
            //Get token from localStorage
            let userToken = "";
            let customerId = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
                customerId = userFromLocalStorage.customerId;
            }
            if (userToken !== null && customerId !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/requisitionlist/' + customerId;
                let requestType = 'GET';
                let requestBody = {};
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_GET_REQUISITION_LIST, success: true, response });
                        if (response.data && response.data.length && response.data[0].requisition_list) {
                            let updatedResponse = RequisitionService.reSturctureRequisiteObject(response.data[0].requisition_list);
                            let values = Object.values(updatedResponse);
                            values.length && values.map((row) => {
                                if (row.entity_id === id) {
                                    dispatch({ type: SET_LOADER_STATUS });
                                    NotificationManager.success('Removed Items Sucessfully');
                                    
                                    dispatch({ type: RECIEVE_SET_REQUISITION_LIST_ID, success: true, Id:id });
                                    dispatch({ type: RECIEVE_SET_REQUISITION_OBJ, success: true, obj: row });
                                }
                            })
                        }
                    },
                    (error) => {
                        dispatch({ type: RECIEVE_SET_REQUISITION_OBJ, success: false, error });
                        dispatch({ type: RECIEVE_SET_REQUISITION_LIST_ID, success: true, Id:id });
                    }
                );
            }
        }
    }
    /***************************************************************************** 
    * Redux Reducer to set RequisitionList
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static SetEnitityReducer = (state = '', action) => {
        switch (action.type) {
            case REQUEST_SET_REQUISITION_OBJ:
                return state;
            case RECIEVE_SET_REQUISITION_OBJ:
                if(action.success && action.response && action.response.data) {
                    return RequisitionService.reSturctureRequisiteItem(action.response.data[0].items);
                }
                return []; 
            default:
                return state;
        }
    };
    static SetReqListIdReducer = (state = '', action) => {
        switch (action.type) {
            case RECIEVE_SET_REQUISITION_LIST_ID:
                if(action.success && action.Id) {
                    return action.Id;
                }
            default:
                return state;
        }
    }
    /**********************************************************************************
     * Action creator to delete Requisition List.
     *********************************************************************************/
    DeleteRequisitionListActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_DELETE_REQUISITION_LIST });
            dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/requisitionlist/removereqid';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.data = {
                    "customerId": userFromLocalStorage.customerId,
                    "requisitionListId": id
                }
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_GET_REQUISITION_LIST, success: true, response });
                        dispatch({ type: RECIEVE_DELETE_REQUISITION_LIST, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        NotificationManager.info('Deleted Requisition List sucessfully');
                    },
                    (error) => {
                        // dispatch({ type: RECIEVE_DELETE_REQUISITION_LIST, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        }
    };
    /***************************************************************************** 
    * Redux Reducer to delete RequisitionList
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static DeleteRequisitionListReducer = (state = false, action) => {
        switch (action.type) {
            case REQUEST_DELETE_REQUISITION_LIST:
                return false;
            case RECIEVE_DELETE_REQUISITION_LIST:
                return action.response && action.response.data;
            default:
                return false;
        }
    };
    /**********************************************************************************
     * Action creator to update items in Requisition List.
     *********************************************************************************/
    UpdateRequisitionListActionCreator(createReqObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_UPDATE_REQUISITION_LIST });
            dispatch({ type: SET_LOADER_STATUS });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = '/requisitionlist/update';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.data = createReqObject
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_SET_REQUISITION_OBJ, success: true, response });
                        dispatch({ type: RECIEVE_UPDATE_REQUISITION_LIST, success: true, response });
                        dispatch({ type: SET_LOADER_STATUS });
                        NotificationManager.success('Updated Quantity sucessfully');
                    },
                    (error) => {
                        // dispatch({ type: RECIEVE_UPDATE_REQUISITION_LIST, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        }
    };
    /***************************************************************************** 
    * Redux Reducer to update RequisitionList
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static UpdateRequisitionListReducer = (state = false, action) => {
        switch (action.type) {
            case RECIEVE_UPDATE_REQUISITION_LIST:
                return action.response && action.response.data;
            default:
                return false;
        }
    };
    /**********************************************************************************
     * Action creator to delete Requisition List.
     *********************************************************************************/
    SelectedItemInReqActionCreator(items) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_ITEM_IN_REQUISITION_LIST });
            dispatch({ type: RECIEVE_SET_ITEM_IN_REQUISITION_LIST, success: true, items });
        }
    };
    /***************************************************************************** 
    * Redux Reducer to delete RequisitionList
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static SelectedItemInReqReducer = (state='', action) => {
        switch (action.type) {
            case REQUEST_SET_ITEM_IN_REQUISITION_LIST:
                return [];
            case RECIEVE_SET_ITEM_IN_REQUISITION_LIST:
                return action.items;
            default:
                return state;
        }
    };
    static getReqList(dispatch) {
        const rs = new RequisitionService();
        dispatch(rs.GetRequisitionListActionCreator());
    }
    DeleteItemsInReqListActionCreator(obj) {
        return (dispatch) => {
            dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_DELETE_ITEM_IN_REQUISITION_LIST });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            }
            if (userToken !== null) {
                //Signed-in user can only wishlist products
                let requestURL = 'requisitionlist/removeselecteditems';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.data = obj;
                requestBody.header = ("Bearer " + userToken);
                requestBody.apiEndPoint = 'thailand';
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        dispatch({ type: RECIEVE_DELETE_ITEM_IN_REQUISITION_LIST, success: true, response });
                        dispatch({ type: RECIEVE_SET_REQUISITION_OBJ, success: true, response });
                        dispatch({ type: REQUEST_SET_ITEM_IN_REQUISITION_LIST });
                        dispatch({ type: SET_LOADER_STATUS });
                        NotificationManager.success('Removed Items Sucessfully');
                    },
                    (error) => {
                        dispatch({ type: RECIEVE_DELETE_ITEM_IN_REQUISITION_LIST, success: false, error });
                        dispatch({ type: REQUEST_SET_ITEM_IN_REQUISITION_LIST });
                        dispatch({ type: SET_LOADER_STATUS });
                        RequisitionService.getReqList(dispatch);
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
        }
    }
    /***************************************************************************** 
    * Redux Reducer to delete RequisitionList
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static DeleteItemInReqReducer = (state = false, action) => {
        switch (action.type) {
            case REQUEST_DELETE_ITEM_IN_REQUISITION_LIST:
                return state;
            case RECIEVE_DELETE_ITEM_IN_REQUISITION_LIST:
                return action.success;
            default:
                return state;
        }
    };
    static updateReqList(dispatch, id) {
        const rs = new RequisitionService();
        dispatch(rs.UpdateEntityActionCreator(id));
    }

    /**********************************************************************************
     * Action creator to open modal.
     *********************************************************************************/
    OpenCreateReqModalActionCreator(status) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_REQUISITION_MODAL });
            dispatch({ type: RECIEVE_SET_REQUISITION_MODAL, success: true, status });
        }
    };
    /***************************************************************************** 
    * Redux Reducer to set modal
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static OpenCreateReqModalReducer = (state = false, action) => {
        switch (action.type) {
            case REQUEST_SET_REQUISITION_MODAL:
                return state;
            case RECIEVE_SET_REQUISITION_MODAL:
                return action.status;
            default:
                return state;
        }
    };
    /**********************************************************************************
     * Action creator to place items in Cart.
     *********************************************************************************/
    AddItemInReqToCartModalActionCreator(itemObj) {
        return (dispatch) => {
            dispatch({ type: REQUEST_ITEM_IN_REQUISITION_TO_CART });
            dispatch({ type: SET_LOADER_STATUS });

            let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
            if (userFromLocalStorage) {
                let requestURL = '/carts/mine';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.data = {
                    "customerId": userFromLocalStorage.customerId
                };
                requestBody.header = ("Bearer " + userFromLocalStorage.token);
                let salesManFromLocalStorage = getItemFromLocalStorage('sales-man');
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                        Cookies.set('customer-cart-id', response.data, { expires: 7, path: '/' });
                        //got cart id from response, now adding items to cart
                        let requestURL = '/requisitionlist/placeitemsincart';
                        let requestType = 'POST';
                        let requestBody = {};
                        requestBody.header = ("Bearer " + userFromLocalStorage.token);
                        requestBody.data = {
                            "cartId": response.data,
                            "items": itemObj,
                            "extensionAttributes": {
                                "unit_of_measurement": 'Unit',
                                "modified_by":salesManFromLocalStorage ? salesManFromLocalStorage.salesmanName: null,
                            },
                        };
                        MagnaAPIHandler(requestURL, requestType, requestBody).then(
                            (response) => {
                                dispatch({ type: RECIEVE_ITEM_IN_REQUISITION_TO_CART, success: true, response });
                                dispatch({ type: SET_LOADER_STATUS });
                                NotificationManager.success('Sucessfully Added Items to Cart');
                            },
                            (error) => {
                                dispatch({ type: RECIEVE_ITEM_IN_REQUISITION_TO_CART, success: false, error });
                                dispatch({ type: SET_LOADER_STATUS });
                                let genericError = 'Error, Please try again after some time';
                                let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                                NotificationManager.error(errorMessage);
                            }
                        );
                    },
                    (error) => {
                        dispatch({ type: RECIEVE_ITEM_IN_REQUISITION_TO_CART, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                        let genericError = 'Error, Please try again after some time';
                        let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                        NotificationManager.error(errorMessage);
                    }
                );
            }
            else {
                return null;
            }
        }
    };
    /***************************************************************************** 
    * Redux Reducer to set modal
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static AddItemInReqToCartReducer = (state = '', action) => {
        switch (action.type) {
            case REQUEST_ITEM_IN_REQUISITION_TO_CART:
                return state;
            case RECIEVE_ITEM_IN_REQUISITION_TO_CART:
                return action.success && action.response && action.response.data;
            default:
                return state;
        }
    };
}
export default RequisitionService;