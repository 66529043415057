/**
* Product Detail Display Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ProductMetaInformation from './ProductMetaInformation';
import ProductGallery from './ProductGallery';
import ProductService from '../../../../app/modules/product/ProductService';
import ProductAddToCart from '../../../../app/components/common/ProductAddToCart';
import { withRouter } from "react-router-dom";
import { withErrorBoundary } from 'react-error-boundary';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Typography, withWidth } from '@material-ui/core';
import CategoryService from './../../../../app/modules/category/CategoryService';
import ProductCarousel from '../../layouts/blocks/carousel/ProductCatalogSlider';
import toRenderProps from 'recompose/toRenderProps';
import Breadcrumbs from '../../../../app/components/common/Breadcrumbs';
import GetLanguage from '../../../../library/GetLanguage';
import { Button } from '@material-ui/core';
import UnitSelector from '../../uom/UnitSelector';
import ContentLoader from '../../layouts/blocks/noncarousel/ContentLoader';
import NavigationMenuDesktop from '../../navigation/navigationdesktop/NavigationDesktop'
let tabOne = "Revel in luxurious, fragrant skin with LUX Scarlet Blossom body wash. Crafted with exotic fragrances of Egyptian Violet Leaf and Elemi oil, it is a treat that leaves an alluring.. of intrigue. Weve all grown up using bars of soap and then came body washes that claimed to be a better choice to make. Here are reasons why a body wash are better than a bar of soap-I. A body wash has a lovely, potent feel as compared to a regular soap bar. From long lasting fragrances to essential oils, a body wash could very well be your new best friend in the bathroom. It attends to hygiene as well as cosmetic needs: while soap generally just helps you clean Una A lot of body washes are popular for their aroma-therapeutic and effervescent properties with scintillating fragrances that are 550.015 missing from a soap bars life.4. We all love good lather while showering. A body wash does the trick with minimal effort as compared to a bar of soap, when used with a loofah. With minimal rubbing a more foamy,. relaxing bathing experience awaks you with a body wash... A bar of soap has a drying effect on the skin as compared to a body wash which contains essentials oils that will form an invisible layer. lock the moisture within and keep your skin moisturized. Certain soaps can also instigate allergic reactions due to the dryness, musing unpleasant redness and an itchy feeling on your skin."
let tabTwo = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
let tabThree = "Eu volutpat odio facilisis mauris sit amet massa. Blandit aliquam etiam erat velit scelerisque in. Laoreet id donec ultrices tincidunt arcu non. Metus dictum at tempor commodo ullamcorper a lacus. Ut lectus arcu bibendum at varius vel pharetra vel turpis. In arcu cursus euismod quis viverra nibh cras. Mollis nunc sed id semper risus in hendrerit gravida rutrum. At auctor urna nunc id cursus metus. Libero id faucibus nisl tincidunt eget. Proin sed libero enim sed faucibus. Aenean et tortor at risus. Sed elementum tempus egestas sed sed risus. Sed viverra tellus in hac habitasse platea. Diam vulputate ut pharetra sit amet aliquam."



const styles = theme => ({
    productDetailpage: {
        backgroundColor: '#f8f8fa',
    },
    gridPosition:{
        width:'1280px',
        margin:'0 auto',
    },
    productDetailDiv:{
        width:'1280px',
        margin:'0 auto',
    },
    productDescription: {
        margin: '10px',
    },
    productImageGallery: {
        margin: '10px',
    },
    productMetaInfo: {
        margin: '10px',
    },
    productDetailSpec: {
        margin: '10px',
        paddingLeft: '3%',
        paddingRight: '3%',
        paddingTop: '3%',
        paddingBottom: '5%',
    },
    productSummaryTitle: {
        backgroundColor: '#E7E7E7',
        padding: '1rem',
        marginTop: '1rem'
    },
    productSummaryContent: {
        backgroundColor: '#E7E7E7',
        padding: '1.5rem',
        marginBottom: '1rem'
    },
    tabButton: {
        color: '#1d2d5f',
        fontSize: '12px',
        fontWeight: 'solid',
        fontWeight:'bold',
        borderBottom:'2px solid #1d2d5f',
        minWidth:'350px'

    },
    tabcontent: {
        background: '#f5f5f5',
        padding: '30px 40px',
    }, headerBarStylesDesktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        background: '#f5f5f5',
        padding: '20px 15px',
    },
    mdDeviceUpDisplayNone: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        background: '#f5f5f5',
    },

    tabcontentMobileView: {
        background: '#f5f5f5',
        padding: '10px 10px',
    },

    tabButtonMobileView: {
        borderRadius: '15px',
        backgroundColor: '#69C1FC',
        color: '#ffffff',
    },
    tabsbutton:{
        minWidth:'350px',
        color:'#424242',
        fontSize:'12px',
        borderBottom:'1px solid #a4a0a0'
    },
    tabsContent:{
        padding:'25px 250px 0px 25px'
    },
    tabsHeading:{
        fontSize:'16px',
        color:'#1d2d5f',
        fontWeight:'bold',
        textAlign:'center',
        textTransform:'uppercase',
        margin:'10px 20px'
    },
    pdpSlider:{
        background:'#e6f4ff',
        padding:'40px 50px 0px',
        '& h5':{
            fontSize:'18px',
            color:'#1d2d5f',
            fontWeight:'bold',
            marginLeft: '15px'
        }
    },
    Butoonspon:{
       display:'flex',
    }
});

/***************************************************************************** 
 * Product Detail Component. Gets the product data based on
 * Id of the product. This component is displayed on navigation
 * from Product Listing Page.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class ProductDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            configurableItemOptions: [],
            flagToDisplayConfigOptions: false,
            tabValue: 0,
            tabContent: tabOne,
            viewMoreObject: [{ height: '100px', name: 'View More' }, { height: 'auto', name: 'View Less' }],
            viewMoreObjectIndex: 0
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    //Dispatch action to get product detail data.
    componentDidMount() {

        let { targetURLPath } = this.props;
        let productId = extractProductId(targetURLPath);
        //Dispatch action to get product detail data based on Product Id.
        this.props.getProudctDetailData(productId);
        this.props.getPDPBreadCrumbs(productId);

        if (!(this.props.carrousel_products && this.props.carrousel_products.relatedproducts && this.props.carrousel_products.relatedproducts[13])) {
            this.props.getCarrosel(productId);
        }
        document.querySelector('body').classList.add('pdp-page');
    }
    
      componentWillUnmount(){
        document.querySelector('body').classList.remove('pdp-page');
      }

    getSlidesToShow = (width) => {
        switch (width) {
            case 'xs':
                return 2;
            case 'sm':
                return 3;
            case 'md':
                return 5;
            case 'lg':
                return 5;
            default:
                return 6;
        }
    }
    isProductOptionExists(configOptions, optionId) {
        var checkCondition = false;
        configOptions.forEach(function (item) {
            if (item["optionId"] === optionId) {
                checkCondition = true;
            }
        });
        return checkCondition;
    }

    handleConfigurableOptions = (optionId, id) => {
        //Adding Configurable Options to State.        
        var configOptions = this.state.configurableItemOptions;
        if (this.isProductOptionExists(configOptions, optionId)) {
            //Replace existing Product Option of Same Type
            configOptions.forEach(function (item) {
                if (optionId === item.optionId) {
                    item.optionValue = id
                }
            });
        }
        else {
            configOptions.push({ "optionId": optionId, "optionValue": id, "extensionAttributes": {} });
        }
    }
    handleTabChange(event, newValue) {
        this.setState({ tabValue: newValue });
        if (newValue === 0) {
            this.setState({ tabContent: tabOne })
        }
        if (newValue === 1) {
            this.setState({ tabContent: tabTwo })
        }
        if (newValue === 2) {
            this.setState({ tabContent: tabThree })
        }
    }
    getSlidesToShow = (width) => {
        switch (width) {
            case 'xs':
                return 2;
            case 'sm':
                return 3;
            case 'md':
                return 5;
            case 'lg':
                return 6;
            default:
                return 10;
        }
    }
    viewMore = () => {
        if (this.state.viewMoreObjectIndex) {
            this.setState({ viewMoreObjectIndex: 0 });
        }
        else {
            this.setState({ viewMoreObjectIndex: 1 });
        }
    }
    render() {
        if(!this.props.productDetail){
            return (<ContentLoader isLoading={true} />)
        }
        let { classes } = this.props;
        let { productDetail } = this.props;
        const { selectedColor } = this.state;
        const WithWidth = toRenderProps(withWidth());
        if (productDetail === null) {
            return null;
        }
      
        else if (productDetail && productDetail.length)
            return (
                <div className={classes.productDetailpage}>
                    <NavigationMenuDesktop/>
                    <Grid container className={classes.productDetailDiv + " " + classes.gridPosition}>
                    <Breadcrumbs label={productDetail[0].product_name} />
                        <Grid item xs={12} sm={12} md={12} lg={7}>
                            <div className={classes.productImageGallery}>
                                {
                                    productDetail[0].media_gallery_entries &&
                                    <ProductGallery
                                        selectedVariant={productDetail[0].currentVariant}
                                        selectedColor={selectedColor}
                                        videoData={productDetail[0].video_data}
                                        product_gallery_entries={productDetail[0].media_gallery_entries}
                                        simple_product_gallery={productDetail[0] && productDetail[0].simple_products ? productDetail[0].simple_products : null} />
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} className="productDetailDiv">
                            <Grid container direction='column'>
                                <Grid item>
                                    {productDetail && productDetail.length &&
                                        <ProductMetaInformation
                                            productDetail={productDetail[0]}
                                            currentVariant={this.state.currentVariant}
                                            name={productDetail[0].product_name}
                                            price={productDetail[0].price}
                                            s_price={productDetail[0].special_price}
                                            sku={productDetail[0].sku}
                                            is_in_stock={productDetail[0] ? productDetail[0].is_in_stock : false}
                                        />
                                    }
                                </Grid>
                                <Grid item className={classes.Butoonspon} >
                                    <UnitSelector productDetailPage={true} product={productDetail}  />
                                    {productDetail && productDetail.length &&
                                        <ProductAddToCart parent={this} display_mode="pdp"
                                            isProductConfigOptions={
                                                productDetail && productDetail[0] &&
                                                    productDetail[0].configurable_options ? true : false
                                            }
                                            config_options={
                                                productDetail &&
                                                    productDetail[0] &&
                                                    productDetail[0].configurable_options ?
                                                    productDetail[0].configurable_options :
                                                    null
                                            }
                                            sku={productDetail[0].sku}
                                            is_in_stock={productDetail[0] ? productDetail[0].is_in_stock : false}
                                            productDetail={productDetail}
                                            isPremiumProduct={getPremiumProduct(productDetail[0].custom_attributes)}
                                        />
                                    }
                                     
                                </Grid>
                                {productDetail[0].description ?
                                    <Grid item>
                                        <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                            <Grid item>
                                                <Typography gutterBottom style={{ color: '#69C1FC', fontSize: '16px', fontWeight: 'solid' }}>
                                                    {<GetLanguage value="DESCRIPTION" />}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div dangerouslySetInnerHTML={{ __html: productDetail[0] ? productDetail[0].description : '' }}></div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justify='center' alignItems='center' style={{ margin: '20px 0px' }}>
                        <Grid item>
                            <div className={classes.tabsHeading}>Product Information</div>
                            <Tabs indicatorColor='secondary' centered value={this.state.tabValue} onChange={this.handleTabChange}>
                                <Tab xs={12} className={this.state.tabValue === 0 ? classes.tabButton : classes.tabsbutton} label="Description" />
                                <Tab xs={12} className={this.state.tabValue === 1 ? classes.tabButton : classes.tabsbutton} label="Addition Information" />
                                <Tab xs={12} className={this.state.tabValue === 2 ? classes.tabButton : classes.tabsbutton} label="Promotions" />
                            </Tabs>
                            {this.state.tabValue === 0 && <TabContainer><Typography>{productDetail[0].description}</Typography></TabContainer>}
                            {this.state.tabValue === 1 && <TabContainer><Typography>{productDetail[0].description}</Typography></TabContainer>}
                            {this.state.tabValue === 2 && <TabContainer><Typography>{productDetail[0].description}</Typography></TabContainer>}
                            {productDetail[0].description ?
                                <React.Fragment>
                                    <div className={classes.tabsContent}>
                                        <Typography style={{ height: this.state.viewMoreObject[this.state.viewMoreObjectIndex].height, overflow: 'hidden' }}
                                            dangerouslySetInnerHTML={{ __html: productDetail[0].description }}></Typography>
                                    </div>
                                    {/* <Button variant={this.state.viewMoreObjectIndex ? 'outlined' : 'contained'} color='secondary' size="small"
                                        onClick={() => this.viewMore()}>{this.state.viewMoreObject[this.state.viewMoreObjectIndex].name}</Button> */}
                                </React.Fragment> : <Typography>No description available for this product!</Typography>
                            }
                        </Grid>
                    </Grid>
                    {this.props.carrousel_products && this.props.carrousel_products.relatedproducts &&
                        this.props.carrousel_products.relatedproducts[productDetail[0].id] && this.props.carrousel_products.relatedproducts[productDetail[0].id].related.items.length > 0 ?
                        <React.Fragment>
                            <div className={classes.pdpSlider}>
                            <Typography variant="h5" align='left'>
                                {<GetLanguage value="Related Products" />}</Typography>
                            <WithWidth>
                                {({ width }) =>
                                    <ProductCarousel
                                        autoplay={false}
                                        slidesToShow={this.getSlidesToShow(width)}
                                        bannerObject={[]} framePadding="0"
                                        cellSpacing={0} slideWidth={1.0}
                                        renderBottomCenterControls={() => { }}
                                        cmsPageWidgetDataObject={this.props.carrousel_products.relatedproducts[productDetail[0].id].related.items}
                                    />}
                            </WithWidth>
                            </div>
                        </React.Fragment>
                        : ''
                    }
                    {this.props.carrousel_products && this.props.carrousel_products.relatedproducts &&
                        this.props.carrousel_products.relatedproducts[productDetail[0].id] && this.props.carrousel_products.relatedproducts[productDetail[0].id].upsell.items.length > 0 ?
                        <React.Fragment>
                            <div className={classes.pdpSlider}>
                            <Typography variant="h5" align='left'>
                                {<GetLanguage value="Upsell Products" />}</Typography>
                            <WithWidth>
                                {({ width }) =>
                                    <ProductCarousel
                                        autoplay={false}
                                        slidesToShow={this.getSlidesToShow(width)}
                                        bannerObject={[]} framePadding="0"
                                        cellSpacing={0} slideWidth={1.0}
                                        renderBottomCenterControls={() => { }}
                                        cmsPageWidgetDataObject={this.props.carrousel_products.relatedproducts[productDetail[0].id].upsell.items}
                                    />}
                            </WithWidth>
                            </div>
                        </React.Fragment>
                        : ''
                    }
                    {/* <Grid container>
                        {this.props.carrousel_products && this.props.carrousel_products.relatedproducts &&
                            this.props.carrousel_products.relatedproducts[productDetail[0].id] && this.props.carrousel_products.relatedproducts[productDetail[0].id].items.length > 0 ?
                            <React.Fragment>
                                <Typography variant="h5">Related Products</Typography>
                                <WithWidth>
                                    {({ width }) =>
                                        <Carousel
                                            autoplay={false}
                                            slidesToShow={this.getSlidesToShow(width)}
                                            bannerObject={[]} framePadding="0"
                                            cellSpacing={0} slideWidth={1.0}
                                            renderBottomCenterControls={() => { }}
                                            cmsPageWidgetDataObject={this.props.carrousel_products.relatedproducts[productDetail[0].id].items}
                                        />}
                                </WithWidth>
                            </React.Fragment>
                            : ''
                        }
                    </Grid> */}
                </div>

            )
        else
            return (
                <Grid container justify='center' style={{ margin: '20px' }}>
                    <Grid item>
                        <Typography variant='h4'>
                            {<GetLanguage value="Product Detail not found" />}</Typography>
                    </Grid>
                </Grid>
            );
    }
}

function TabContainer(props) {
    if (props.children)
        return null;
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

/****************************************************************************** 
 * @param {*} categoryURL - Input Category URL received via menu
 * The last digits in the URL path are category IDs.
 *****************************************************************************/
function extractProductId(productURL) {
    // In the string like "catalog/product/view/id/2002",
    // extract "2002".
    return productURL.split("/")[4];
}

function getPremiumProduct(customAttributes) {
    for (let index = 0; index < customAttributes.length; index++) {
        if (customAttributes[index].attribute_code === "premium_goods" && customAttributes[index].value === "1") {
            return customAttributes[index].value;
        }
        else {
            continue;
        }
    }
    //if there's no image path
    return '';
}

ProductDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    targetURLPath: PropTypes.string,
};

/***Error Boundary Fallback Component */
const ProductDetailErrorFallbackComponent = ({ componentStack, error }) => {
    return (
        <PageTitle title="Failed to load product detail, please try again." />
    );
}

const ProductListingWithErrorBoundary = withErrorBoundary(
    ProductDetail,
    ProductDetailErrorFallbackComponent
);


/****************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    targetURLPath: state.targetPathState.targetURLPath,
    productDetail: state.get_product_detail && state.get_product_detail.productDetailData,
    displayAddToCartSuccess: state.result_add_to_cart_state.displayAddToCartSuccess,
    totalProductCount: state.result_add_to_cart_state.totalProductCount,
    loader_status: state.get_loader_status,
    carrousel_products: state.get_carrosel_products,
});

const mapDispatchToProps = dispatch => ({
    getProudctDetailData: (productId) => {
        let ps = new ProductService();
        dispatch(ps.getProductDetailDataActionCreator(productId));
    },
    getCarrosel: (id) => {
        let categoryService = new CategoryService();
        dispatch(categoryService.GetCarroselProductsActionCreator(id, 'relatedproducts'));
    },
    getPDPBreadCrumbs: (productId) => {
        let cs = new CategoryService();
        dispatch(cs.getPDPBreadcrumbsActionCreator(productId));
    }
});

const ProductDetailWithStyles = withStyles(styles)(ProductListingWithErrorBoundary);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetailWithStyles));