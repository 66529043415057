/**
* Theme selection from available themes
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import themeMui from './mui/ThemeConfig';
import themeBase from './base/ThemeConfig';
import themeIndia from './in/ThemeConfig';
import themeThailand from './th/ThemeConfig';
//active theme name fetched from configuration.
import getConfigValue from '../app/framework/config/configHandler';

/****************************************************************************** 
 * Configuration of themes to be applied.
 * The theme with "active" : true will be applied for the app.
 *****************************************************************************/
let themes = [
    {
        "name": "theme-mui",
        "themeconfig": themeMui, 
    },
    {
        "name": "theme-base",
        "themeconfig": themeBase,
    },
    {
        "name": "theme-india",
        "themeconfig": themeIndia,
    },
    {
        "name": "theme-thailand",
        "themeconfig": themeThailand,
    }
]

/******************************************************************************
 * Function to check the active theme and return it.
 * Input parameter: None
 * Output: Active theme configuration
 *****************************************************************************/
function getActiveTheme() {
    for (let i = 0; i < themes.length; i++) 
    {
        const systemParameters = getConfigValue("system");
        if (themes[i].name === systemParameters.theme.active)
        {
            return themes[i].themeconfig;
        }
        else
        {
            continue;
        }
    }
    return null;
}

let activeTheme = getActiveTheme();

export default activeTheme;