/**
* OrderService class with functions for handling Order History  related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Amaresh <amaresh.m@embitel.com>
* @copyright 2019-2020 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_FOOTER_DATA,
    RECIEVE_FOOTER_DATA,
    REQUEST_GET_CMS,
    RECEIVE_GET_CMS,
    SET_LOADER_STATUS
} from '../../framework/common/ReduxConstants';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import getConfigValue from '../../framework/config/configHandler';

// import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
// import magnaCookies from '../../framework/cookie/magnaCookies';
// import { setItemInLocalStorage } from '../../framework/storage/local/localStorageHandler';
// import CartService from './../cart/CartService';
// import OrderService from '../order/OrderService';
// import WishlistService from '../wishlist/WishlistService';
// import {getSignedInCustomerCartID} from '../../framework/common/helpers'


/**********************************************************************************
 * Order Service class which handles Order History
 *********************************************************************************/
class FooterService {


    GetFooterDetailsActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_FOOTER_DATA });
            // dispatch({ type: SET_LOADER_STATUS });
            let requestBody = {};
            requestBody.data = {
            }
            const systemParameters = getConfigValue("system");
            let storeId = window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].id:systemParameters.store[1].id
            let selectedLanguage= localStorage.getItem('i18nextLng');
            if(selectedLanguage==='en'){
                storeId = window.location.hostname === 'usmileshoppro.com'? 1:1;
            }
            else{
                storeId = window.location.hostname === 'usmileshoppro.com'? 5:5;
            }
            let requestType = 'GET';
            //let requestURL = 'ecs/' + carrouseltype + '/' + id;
            let requestURL = 'ecs/footer/links/'+storeId;
       
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                       * Handle call to REST API to get config options and prepare a merge
                       * of the response.
                       */
                    dispatch({ type: RECIEVE_FOOTER_DATA, payload: response.data });
                    // dispatch({ type: SET_LOADER_STATUS });
                    // CategoryService.getConfigurableOptionsAndMergeResponse(dispatch, response, RECEIVE_GET_CMS_PAGE_WIDGET_DATA3);
                },
                (error) => {
                    // dispatch({ type: SET_LOADER_STATUS });
                    dispatch({ type: RECIEVE_FOOTER_DATA, success: false, error });
                }
            );
        }
    }

    static applyGetFooterDetails = (action, state) => {
        let footerDetails = [];
        if (action.payload) {
            let footerDetails =
            {
                footerDetails: action.payload,
            }
            return Object.assign({}, footerDetails, { footerDetails: action.payload });
        }
        else {
            // let footerDetails =
            // {
            //     errorMessage: action.payload.data.message,
            // }
            // return Object.assign({}, footerDetails, {footerDetails:null });
            return footerDetails;
        }
    }
    static GetFooterDetailsReducer = (state = {}, action) => {
        switch (action.type) {
            case REQUEST_FOOTER_DATA:
                return { ...state, footerDetails: null };
            case RECIEVE_FOOTER_DATA:
                return FooterService.applyGetFooterDetails(action, state);
            default:
                return state;
        }
    };

    /**************************************************************
     * Utility method to get CMS content of the category
     **************************************************************/
    GetFooterCMS(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CMS });
            dispatch({ type: SET_LOADER_STATUS });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = '/cmsBlock/'+id;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_CMS, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CMS, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                }
            );
        }
    }
    static GetFooterCMSReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CMS:
                return action.response ? action.response.data : '';
            default:
                return state;
        }
    };
}



export default FooterService;