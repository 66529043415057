/**
* Category Carousel Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
 * @author   Amaresh M<amaresh.m@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import Slider from "react-slick";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import CategoryBgImage7 from '../../../../../theme/th/assets/images/categories/maskGroup.jpg';
import ImagePlaceHolder from '../../../../../assets/images/Placeholder.jpg';
import CategoryService from '../../../../../app/modules/category/CategoryService';
import { connect } from 'react-redux';
// import { withRouter } from "react-router";
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import GetLanguage from '../../../../../library/GetLanguage';

import './carousal.css';
const styles = theme => ({
    carouselTitleText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1d2d5f',
        wordSpacing: '3px',
        paddingBottom: '40px',
    },
    categoryBg: {
        background: '#e6f4ff',
        padding: '1rem',
        textAlign: 'center',
        borderRadius: '10px',
        width: '80% !important',
        height:'195px',
        display: 'flex !important',
        justifyContent: 'center',
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem',
            width: '80% !important',
        },
        cursor: "pointer",
        borderRadius: '50%',
        flexWrap: 'wrap',
        webkitAlignContent: 'center',
        alignContent: 'center',
        margin:'0 auto',
    },
    imageStyle:
    {
        textDecoration: 'none',
    },
    imageNameStyle:
    {
        fontSize: '17px',
        fontWeight: 'bold',
        color: '#1d2d5f',
        width: '120px',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '30px',
    },
    sectioncontainer: {
        width:'1280px',
        margin:'0 auto',
        padding:'60px 0',
        boxSizing: 'border-box',
    }
});


/***************************************************************
 * Component class for displaying Brands Container.
 * which contains recently viewed images and carousel
 * @author   Amaresh M<amaresh.m@embitel.com>
 ***************************************************************/

class CategoryCarousel extends React.Component {

    componentDidMount() {
        this.props.getCarrosel();
    }

    getImageURL = (url) => {
        let newURL = new URL(url);
        return newURL.pathname;
    }

    render() {
        const { classes, productCategoriesData } = this.props;

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows:true,
            prevArrow: <KeyboardArrowLeft color='disabled' fontSize='large' />,
            nextArrow: <KeyboardArrowRight color='disabled' fontSize='large' />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }
            ]
        };
        return (
            <React.Fragment>
                {productCategoriesData && productCategoriesData.length > 0 &&
                    <Grid container direction='column' alignItems='stretch' className="Carouselslider" >
                       <Grid container  className={classes.sectioncontainer} >
                        <Grid item xs={12}>
                            <Typography className={classes.carouselTitleText}
                                color='textSecondary' gutterBottom>{<GetLanguage value="Recommended Categories" />}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Slider ref={slider => this.slider = slider} {...settings}>
                                {
                                    productCategoriesData && productCategoriesData.length > 0 && productCategoriesData.map((item, index) =>
                                        <Link key={index} className={classes.categoryBg} to={this.getImageURL(item.url)}>
                                            <div className={classes.imageStyle}>
                                                <img alt="Category" style={{maxWidth: '80px',margin: 'auto'}}
                                                    src={item.image ? item.image : ImagePlaceHolder} />
                                                <Typography className={classes.imageNameStyle}>{item.name}</Typography>
                                            </div>
                                        </Link>

                                    )
                                }
                            </Slider>
                        </Grid>
                    </Grid>
                    </Grid> 
                }
            </React.Fragment>

        )
    }
}



const mapStateToProps = state => ({

    productCategoriesData: state.get_carrosel_productcategories,

});

const mapDispatchToProps = dispatch => ({
    getCarrosel: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.GetCarroselProductCategoriesActionCreator());
    },

});

const CategoryCarouselWithStyles = withStyles(styles)(CategoryCarousel);
export default connect(mapStateToProps, mapDispatchToProps)(CategoryCarouselWithStyles);
