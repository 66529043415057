/**
* Footer bar including copyright block.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Email from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
import Card from '@material-ui/core/Card';
import CopyRightBar from './CopyRightBar';
import Logo from '../headerbar/Logo';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux';
import FooterService from './../../../../app/modules/footer/FooterService';

const styles = theme => ({
    footerDivStyle: {
        backgroundColor: '#F8F8F8',
        padding: '20px',
    },
    showCursor: {
        cursor: 'pointer',
    },
    noDecoration: {
        fontSize: '0.75rem',
        textDecoration: 'none',
        color: '#000000',
    }
});

/***************************************************************************** 
 * Footer bar along with Copyright Notice.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
class Footerbar extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    
    componentDidMount() {
       
            this.props.getFooterDetails();
        
    }

    render() {
        const { classes,get_footer_details } = this.props;
        return (
            <React.Fragment>
                <Hidden only={['sm', 'xs','md','lg','xl']}>
                    <Card className={classes.footerDivStyle} >
                        <Grid container alignItems="center" justify="space-between" direction="row">
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container alignItems="flex-start" direction="column" spacing={8}>
                                    <Grid item>
                                        <Logo />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">Unilever is a website that connects
                                        buyers with any local traditional shops. The concept allows consumers to order
                                        various household products to be sent by the nearest traditional store
                                        directly to their home or office.
                                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="flex-start" direction="row" spacing={8}>
                                            <Grid item>
                                                <Call color="action" fontSize="small" />
                                            </Grid>
                                            <Grid item>
                                                <a className={classes.noDecoration} href="tel:+015570055006">01 (55) 7005 5006</a>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="flex-start" direction="row" spacing={8}>
                                            <Grid item>
                                                <Email color="action" fontSize="small" />
                                            </Grid>
                                            <Grid item>
                                                <a className={classes.noDecoration} href="mailto:customercare@unilever.com?Subject=Hello"
                                                    target="_top">customercare@unilever.com</a>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <Grid container alignItems="flex-start" direction="column">
                                    <Grid item>
                                        <Typography variant="subtitle1" color="textPrimary">About Buy at Unilever
                                </Typography>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Payments and transactions</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Definitions</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Terms of use</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Privacy Policy</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Doubts or suggestions</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <Grid container alignItems="flex-start" direction="column">
                                    <Grid item>
                                        <Typography color="textPrimary" variant="subtitle1">How to buy
                                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">First income</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Frequent questions</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Product availability guarantee</a>
                                    </Grid>
                                    <Grid item>
                                        <a className={classes.noDecoration} href="/">Return & Exchanges</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Hidden>
                <CopyRightBar />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    get_footer_details: state.get_footer_details? state.get_footer_details.footerDetails : '',
});

const mapDispatchToProps = dispatch => ({
    getFooterDetails: () => {
        let footerService = new FooterService();
        dispatch(footerService.GetFooterDetailsActionCreator());
    },
   
});

const FooterbarWithStyles = withStyles(styles)(Footerbar);
export default connect(mapStateToProps, mapDispatchToProps) (FooterbarWithStyles);

// export default withStyles(styles)(Footerbar);