/**
* Headerbar Component with Logo.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import SearchInputDesktop from '../../search/searchbar/searchbardesktop/SearchbarDesktop';
import SearchInputMobile from '../../search/searchbar/searchbarmobile/SearchbarMobile';
import SignInButton from '../../myaccount/signin/SignInButton';
import CartButton from '../../cart/CartButton';
import Logo from './Logo';
import { Redirect } from 'react-router';
import NavigationMobile from '../../navigation/navigationmobile/NavigationMobile';

const styles = theme => ({
    grow: {
        flex: '1 1 auto',
    },
    headerBarStylesDesktop: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
    },
    MenuIconStyle: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    mdDeviceDownDisplayNone: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    mdDeviceUpDisplayNone: {
        marginTop: '70px',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    root: {
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
        opacity: 1,
    },
    appBarPadding: {
        padding: '0rem 1.25rem 0rem 1.25rem',
    },
    stickyHeader: {
        position: 'fixed !important',
        top: 0,
        minHeight: '10vh',
        opacity: 1,
        zIndex: 1000,
    },
    appBarStyles: {
        backgroundColor: '#ffffff',
    },
    mobileCategoryNavigation: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    searchOpen: {
        display: 'block',
        background: '#000000',
    },
    searchClose: {
        display: 'none',
        position: 'absolute',
        left: 0,
        right: 0,
        padding: '20px 15px',
        marginTop: '50px',
        background: '#000000',
    }
});

/***************************************************************
 * Component class for Header Bar
 * @author   Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class Headerbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSearchInputShown: false,
            searchInputValue: '',
            mobileCategoryMenuOpen: false,
            termToBeSearched: false,
        };
    }

    //For Category Navigation in mobile mode
    handleCategoryNavigationToggle = () => {
        this.setState((prevState) => ({
            mobileCategoryMenuOpen: !prevState.mobileCategoryMenuOpen
        }));
    };

    //get value from search input 
    handleInputChange = (event) => {
        this.setState({ searchInputValue: event.target.value });
    }

    //toggle searchbar in devices down md
    toggleSearch = () => {
        this.setState((prevState) => ({
            isSearchInputShown: !prevState.isSearchInputShown
        }));
    }

    // On Enter Key pressed, change the state variable 'termToBeSearched'.
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            //If the search term entered by user is empty, return.
            if (event.target.value === "") return;
            this.setState({ termToBeSearched: true });
        }
    }

    render() {
        const { classes, showfields } = this.props;
        //logoExists varaible will define whether logo should display or not
        let logoExists =showfields.includes('logo');
        //searchExists varaible will define whether search should display or not
        let searchExists =showfields.includes('search');
        //profileExists varaible will define whether profile should display or not
        let profileExists =showfields.includes('profile');
        //cartExists varaible will define whether cart should display or not
        let cartExists =showfields.includes('cart');

        if (this.state.termToBeSearched) {
            return (
                <Redirect push to={{ pathname: '/search', state: { searchTerm: this.state.searchInputValue } }} />
            );
        }

        return (
            <div className={classes.root}>
                <div className={classes.stickyHeader}>
                    <AppBar position="fixed" className={classes.appBarPadding}>
                        <Toolbar disableGutters={true}>
                            <IconButton
                                className={classes.mobileCategoryNavigation}
                                color="default"
                                onClick={this.handleCategoryNavigationToggle}
                                aria-label="open mobile category navigation">
                                <MenuIcon className={classes.MenuIconStyle} />
                            </IconButton>
                            {searchExists ?
                                <SearchInputMobile searchHandler={this.toggleSearch} /> :''
                            }
                            {logoExists ?
                                <div className={classes.headerBarStylesDesktop}>
                                    <Logo />
                                </div>
                                : ''}
                            <div className={classes.grow} />
                            {searchExists? <div className={classes.mdDeviceDownDisplayNone}>
                                <SearchInputDesktop
                                    isSearchMobile={false}
                                    searchInputValue={this.state.searchInputValue}
                                    searchInputHandler={this.handleInputChange}
                                    searchClick={this.handleKeyPress}
                                />
                            </div> : ''}
                            {profileExists? <SignInButton />:''}
                            {cartExists? <CartButton />:''}
                        </Toolbar>
                    </AppBar>

                    {/* For Category Navigation in mobile mode */}
                    <NavigationMobile style={{background:'#69C1FC'}}
                        onClose={this.handleCategoryNavigationToggle}
                        mobileOpen={this.state.mobileCategoryMenuOpen}
                    />
                    {searchExists ?<div className={classes.mdDeviceUpDisplayNone}>
                        {this.state.isSearchInputShown ?
                            <SearchInputDesktop
                                isSearchMobile={true}
                                searchInputValue={this.state.searchInputValue}
                                searchInputHandler={this.handleInputChange}
                                searchClick={this.handleKeyPress}
                            /> :
                            ""
                        }
                    </div> : ''}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Headerbar);

