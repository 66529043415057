/**
* Icon Based on type Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/WarningRounded';
import SucessIcon from '@material-ui/icons/CheckBoxRounded';
import PropTypes from 'prop-types';

const styles = theme => ({
    snackbarIcon: {
        marginRight: '8px',
    },
});

/**************************************************************
 * This component return icon based on message type 
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 *************************************************************/
class IconBasedOnType extends React.Component {
    render() {
        const { classes, messageType} = this.props;
        switch(messageType) {
            case 'error':
                return <ErrorIcon className={classes.snackbarIcon} color='secondary'/>;
            case 'warning':
                return <WarningIcon className={classes.snackbarIcon} color='secondary'/>;
            case 'sucess':
                return <SucessIcon className={classes.snackbarIcon} color='secondary'/>;
            case 'info':
                return <ErrorIcon className={classes.snackbarIcon} color='secondary'/>;
            default:
                return <ErrorIcon className={classes.snackbarIcon} color='secondary'/>;
        }
      }
}

IconBasedOnType.propTypes = {
    messageType: PropTypes.string.isRequired,
}
export default withStyles(styles)(IconBasedOnType);