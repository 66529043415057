/**
* Unit Selection Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

//Redux related imports
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CategoryService from '../../../../app/modules/category/CategoryService';
import GetLanguage from '../../../../library/GetLanguage';

const styles = theme => ({
  
});
/****************************************************************
 * This component returns input field which includes search icon
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class SortView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedSortOrder: this.props.selectedSortOption ? this.props.selectedSortOption.type ==='product_position'?'product_position-ASC':this.props.selectedSortOption.type:'product_position-ASC'
        };
    }
    componentDidMount(){
        let sortObject;
        if (this.state.selectedSortOrder.includes('-')) {
            let splitString = this.state.selectedSortOrder.split('-')
            sortObject = {
                type: splitString[0],
                direction: splitString[1]
            }
        }
        else {
            sortObject = {
                type: this.state.selectedSortOrder,
                direction: ''
            }
        }
        this.props.sortView(sortObject);
    }
    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };
    handleChange = e => {
        let sortObject;
        if (e.target.value.includes('-')) {
            let splitString = e.target.value.split('-')
            sortObject = {
                type: splitString[0],
                direction: splitString[1]
            }
        }
        else {
            sortObject = {
                type: e.target.value,
                direction: ''
            }
        }
        this.setState({ selectedSortOrder: e.target.value });
        this.props.sortView(sortObject);
    }
    //handles closing of menu
    handleClose = () => {
        this.setState({ open: false });
    };
    render() {
        let { classes } = this.props;
        return (
            <Grid container direction="row" justify="center" alignItems="center" className="sortViewSec">
              <Grid item className="sortTitle">Sort by:</Grid>
                <Grid item className="sortOption">
                    <FormControl>                        
                        <Select IconComponent = {KeyboardArrowDownIcon} className="sortValue" MenuProps={{getContentAnchorEl: null,anchorOrigin: {vertical: "bottom",horizontal: "left",}}} open={this.state.open} onClose={this.handleClose} onOpen={this.handleToggle} value={this.state.selectedSortOrder!==''?this.state.selectedSortOrder:'Position'}
                            onChange={this.handleChange} input={<Input id="sort-view" />}>
                            <MenuItem className="listing" value='product_position-ASC'>
                                <Typography className={classes.languagesFontSize}>{<GetLanguage value="Position" />}</Typography>
                            </MenuItem>
                            <MenuItem className="listing" value='Popularity'>
                                <Typography className={classes.languagesFontSize}>{<GetLanguage value="Popularity"/>}</Typography>
                            </MenuItem>
                            {localStorage.getItem('shopce-user') &&
                                    <MenuItem className="listing" value='Price-ASC'>
                                        <Typography className={classes.languagesFontSize}>{<GetLanguage value="Price (Low to High)"/>}</Typography>
                                    </MenuItem>
                            }
                            {localStorage.getItem('shopce-user') &&
                                    <MenuItem className="listing" value='Price-DESC'>
                                        <Typography className={classes.languagesFontSize}>{<GetLanguage value="Price (High to Low)"/>}</Typography>
                                    </MenuItem>
                            }
                            <MenuItem className="listing" value='Name-ASC'>
                                <Typography className={classes.languagesFontSize}>{<GetLanguage value="Name (A-Z)"/>}</Typography>
                            </MenuItem>
                            <MenuItem className="listing" value='Name-DESC'>
                                <Typography className={classes.languagesFontSize}>{<GetLanguage value="Name (Z-A)"/>}</Typography>
                            </MenuItem>
                            {localStorage.getItem('shopce-user') &&
                            <MenuItem className="listing" value='Soq-DESC'>
                                <Typography className={classes.languagesFontSize}>{<GetLanguage value="Soq (High to Low)"/>}</Typography>
                            </MenuItem>
                            }
                            {localStorage.getItem('shopce-user') &&
                            <MenuItem className="listing" value='Soq-ASC'>
                                <Typography className={classes.languagesFontSize}>{<GetLanguage value="Soq (Low to High)"/>}</Typography>
                            </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
}


const mapStateToProps = state => ({
    selectedSortOption: state.selectedSort,
});
const mapDispatchToProps = dispatch => ({
    sortView: (sortOption) => {
        let cs = new CategoryService();
        dispatch(cs.SetSortOptionActionCreator(sortOption));
    }
});
const SortViewStyles = withStyles(styles)(SortView)
export default connect(mapStateToProps, mapDispatchToProps)(SortViewStyles);