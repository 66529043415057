import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Grid } from "@material-ui/core";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
/**
* Breadrcumbs Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Nirmal G <nirmal.gandhi@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
const styles = theme => ({
    linkStyle: {
        fontSize: '12px',
        textDecoration: 'none',
        color:'#1d2d5f',  
        fontWeight:'normal'     
    },
    spanFontStyle: {
        fontSize: '12px',
        color:'#1d2d5f',
        fontWeight:'bold'
    }
});

class Breadcrumbs extends Component {
    navigate(url) {
        let newURL = new URL(url);
        let path = newURL.pathname
        return (path);
    }
    render() {
        let { classes, breadcrumbsPDP, breadcrumbsPLP } = this.props;
        let breadcrumbs;
        if(breadcrumbsPLP===undefined){
            breadcrumbs = breadcrumbsPDP;
        }
        else if(breadcrumbsPDP===undefined){
            breadcrumbs= breadcrumbsPLP;
        }
        return (
            <Grid style={{ padding: '20px 0px 0px 10px' }} container justify='flex-start'  >
                <Grid item>
                    <Link to='/' className={classes.linkStyle} >Home</Link>
                    <span style={{ padding: '0 3px', fontSize: '12px' }}>/</span>
                </Grid>
                {/* <span className={classes.spanFontStyle}>{this.props.label}</span>  */}
                {breadcrumbs !== undefined ?
                    breadcrumbs.map((breadcrumb, index) => (
                        breadcrumbs.length === 1 || index === breadcrumbs.length - 1 ?
                            <Grid item><span className={classes.spanFontStyle}>{breadcrumb.name}</span></Grid>
                            :
                            <Grid item>
                                <Link to={this.navigate(breadcrumb.url)} className={classes.linkStyle}>{breadcrumb.name}</Link>
                                <span style={{ padding: '0 3px', fontSize: '12px' }}>/</span>
                            </Grid>
                    ))
                    : <Grid item><span className={classes.spanFontStyle}>{this.props.label}</span></Grid>
                }

            </Grid>
        )
    }
}
const mapStateToProps = state => ({
    breadcrumbs: state.category_title_state.breadcrumbs,
    breadcrumbsPDP: state.pdp_breadcrumbs_state.breadcrumbs,
    breadcrumbsPLP: state.plp_breadcrumbs_state.breadcrumbs
});

const BreadcrumbsWithStyles = withStyles(styles)(Breadcrumbs);
export default connect(mapStateToProps)(BreadcrumbsWithStyles);