/**
* Template for preparing Pages with Header and Footer.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '../../../../app/components/common/Breadcrumbs';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
const styles = theme => ({
    containerDiv : {
        margin : '0px 0px 0px 0px',        
    }
});

/**************************************************************
 * This component renders a Page with the props it receives. 
 * Acts as a template with Header, Navigation Bar and Footer.   
 * Renders the page blocks passed as an array.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com> 
 *************************************************************/
class WithHeaderAndFooter extends Component {
    render() {
        
        const { HeaderComponent,
            NavigationComponent,
            FooterComponent,
            MainContent,label } = this.props;

        const { classes } = this.props;
   
     let CustomerExist = getItemFromLocalStorage('shopce-user');
     let SalesmanExist = getItemFromLocalStorage("salesman-user");

     if(SalesmanExist)
     {
      if(!this.props.is_salesman_user)
      {
          this.props.resetsalesman();
        //  dispatch({ type: SET_IS_SALESMAN_USER });

      }
     if(this.props.is_salesman_user)
     {          

         if (SalesmanExist !== null && SalesmanExist !== undefined) {
             if (CustomerExist.customerId === SalesmanExist.customerId) {

         return <Redirect push to='/salesman' />;
             }
         }
     }
    
  }
        
        return (
            <div className={classes.containerDiv}>
                <HeaderComponent></HeaderComponent>
                <NavigationComponent></NavigationComponent>
                {label ? <Breadcrumbs label={label} /> : ""} 
                {MainContent}
                <FooterComponent></FooterComponent>
            </div>
        );
    }
}
WithHeaderAndFooter.propTypes = {
    HeaderComponent: PropTypes.func.isRequired,
    NavigationComponent: PropTypes.func,
    FooterComponent: PropTypes.func.isRequired,    
    MainContent: PropTypes.object,
};

const mapStateToProps = state => ({
    is_salesman_user:state.is_salesman_user,
});

const mapDispatchToProps = dispatch => ({
    
    resetsalesman: () => {
        dispatch({ type: 'SET_IS_SALESMAN_USER' })
    },
});



const WithHeaderAndFooterStyles = withStyles(styles)(WithHeaderAndFooter);
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(WithHeaderAndFooterStyles));

