/**
* Common Error Handler for all API calls.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import ErrorPage from '../../components/error/ErrorPage'
import getConfigValue from '../../framework/config/configHandler';

/*******************************************************************************************
 * Common Error Handler for API calls. Applied to App Component in index.js
 * Based on the error level being passed, in case Error Level for
 * the API is critical, an error page is displayed when there is error in the
 * response of API.
 * @param {*} WrappedComponent - If there is no API error,WrappedComponent is returned  
 * @param {*} axiosClient      - Error Level is extracted from interceptors if axiosClient
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 *******************************************************************************************/
const errorHandler = (WrappedComponent, axiosClient) => {
    return class EH extends Component {
        state = {
            error: null,
            apiResponse: null
        };

        componentDidMount() {
            // Set axios interceptors
            this.requestInterceptor = axiosClient.interceptors.request.use(config => {
                //this.setState({ error: null });
                return config;
            });
            this.responseInterceptor = axiosClient.interceptors.response.use(
                response => {
                    return response;
                },
                error => {
                    const systemParameters = getConfigValue("system");
                    //If the API level is critical, set the error in state and show 404/50x page.
                    if (error.response.config && error.response.config.apiLevelConfigParam && 
                        error.response.config.apiLevelConfigParam.apiLevel === systemParameters.api.error.levels.CRITICAL) {
                        this.setState({ error });
                    }
                    else {   // Return the response, in case of non-critical APIs                        
                        return Promise.reject(error.response);
                    }
                }
            );
        }

        componentWillUnmount() {
            // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed 
            axiosClient.interceptors.request.eject(this.requestInterceptor);
            axiosClient.interceptors.response.eject(this.responseInterceptor);
        }

        render() {
            if (this.state.error && this.state.error.response) {
                return <RenderErrorPage errorCode={this.state.error.response.status} {...this.props} />
            }
            else {
                return <WrappedComponent {...this.props} />;
            }
        }
    };
};

/******************************************************************************************** 
 * Error Component selection
 *******************************************************************************************/
const RenderErrorPage = (props) => {
    const { errorCode } = props;
    if (errorCode === 404) {
        return <ErrorPage header="PAGE NOT FOUND"
            text="The link you clicked may be broken or the page may have been removed." />;
    }
    else if (errorCode >= 500) {
        return <ErrorPage
            header="Oops!"
            text="Something went wrong. We are working on the problem." />;
    }
    else {
        return <ErrorPage
            header="Oops!"
            text="Something went wrong. We are working on the problem." />;
    }
}

export default errorHandler;
