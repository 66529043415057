/**
* Category Product Listing Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import UserMessage from '../../../../app/components/messages/UserMessages';
import CMSComponent from '../../cms/CMSComponent';
import PaginationComponent from '../../pagination/PaginationComponent';
import PageTitle from '../../../../app/components/common/PageTitle';
import ContentLoader from '../../../components/layouts/blocks/noncarousel/ContentLoader';
import ShoppingOptions from '../filters/ShoppingOptions';
import { Button } from '@material-ui/core';
import CategoryChips from '../filters/CategoryChips';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
//Number of products per API call and Product Image dimensions are taken 
//from configuration.
import getConfigValue from '../../../../app/framework/config/configHandler';
import CategoryService from '../../../../app/modules/category/CategoryService';
import ProductCatalog from '../../../components/product/productcatalog/ProductCatalog';
import PLPView from '../../../components/category/productlisting/PLPView';
import SortView from '../../../components/category/productlisting/SortView';
import { withErrorBoundary } from 'react-error-boundary';
import BannerImage1 from '../../../../theme/th/assets/images/homePage/Slider_Section.jpg';
import BannerImage2 from '../../../../theme/th/assets/images/homePage/Slider_Section.jpg';
import BannerImage3 from '../../../../theme/th/assets/images/homePage/Slider_Section.jpg';
import Carousel from '../../../components/layouts/blocks/carousel/Carousel';
import Breadcrumbs from '../../../../app/components/common/Breadcrumbs';
import GetLanguage from '../../../../library/GetLanguage';
import '../../../../../src/assets/css/common/plp.css';
import $ from 'jquery';
import NavigationMenuDesktop from '../../navigation/navigationdesktop/NavigationDesktop'

const styles = theme => ({
    root: {
        flexGrow: 1,
        minHeight: '500px',
        background: '#f8f8fa',
    },
    filterSec:{},
    productListingGrid: {
        marginTop: 0,
        marginBottom: 20,
        display: 'flex',
        width: '100%'
    },
    sliderdiv: {
        margin: 0,
        padding: 0,
    },
    paper: {
        boxShadow: 'none',
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background:'none',
    },
    priceText: {
        marginBottom: 5,

    },
    noProductsParentDiv: {
        marginTop: 20,
        textAlign: 'center',
    },
    noProductsDiv: {
        display: 'inline-block',
    },
    normal_price: {
        fontWeight: 'bold',
        color: '#1979c3',
    },
    special_price: {
        marginRight: 5,
        color: '#1979c3',
        fontWeight: 'bold',
    },
    strike_through_price: {
        color: '#A9A9A9',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progressDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    
    pageNumbersHeader: {
        paddingRight: theme.spacing.unit * 1,
        paddingTop: '5px',
        paddingLeft:'10px',
        paddingBottom:'5px',
    },
    progressStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    mobileAppBar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fliterButton: {
        width: '50%!important',
        marginTop: '10px!important',
        marginBottom: '10px!important',
        textAlign: 'center'
    },
});

//used for transition of filter options in mobile view
function Transition(props) {
    return <Slide direction="right" {...props} />;
}

/***************************************************************************** 
 * Main Product Listing Component. Gets the Category data 
 * from Redux Store and populate the responsive page for
 * Category Listing.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
let _this = this;
window.scrolled = false;
class ProductListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemFrom: 0,
            itemTo: 0,
            total_count: 0,
            pageNumber: 1,
            // Flag for displaying loading indicator in child component.
            isLoading: false,
            leftOverlay: false,
            listData: [],
            addremovenumber: 1,
            sortBy: this.props.selectedSortOption && this.props.selectedSortOption
        };
        this.lazyload = this.lazyload.bind(this);
        document.body.classList.add('plp_page');
        _this = this;
        if (!this.props.categoryProducts) {
            this.lazyload(this.state.pageNumber);
        }
        $(window).on("scroll", function () {
            if (document.querySelector('body.plp_page')) {
                if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                    if (window.scrolled === false && window.plpTotalCount !== window.stopLazyLoadCount) {
                        _this.setState({
                            pageNumber: _this.state.pageNumber + 1
                        })
                        if (_this.props.categoryProducts) {
                            _this.lazyload(_this.state.pageNumber);
                        }
                        window.scrolled = true;
                    }
                }
            }
        });
    }
    lazyload(pageNumber) {
        let queryParams = new URLSearchParams(this.props.location.search);
        if (this.props.categoryProducts) {
            this.getPaginatedProducts(pageNumber, queryParams, undefined, this);
        }
    }
    // Use a single method (getPaginatedProducts) to fetch and 
    // display products
    componentDidMount() {

        this.setState({ isLoading: true });
        let queryParams = new URLSearchParams(this.props.location.search);
        this.getPaginatedProducts(1, queryParams, 'urlchanged', this);
        // const productParameters = getConfigValue("product");
        //this.getPaginatedProducts(1);
        this.props.getCategoryFilters(extractCategoryId(this.props.targetURLPath));
        // this.props.getCategoryFilters();
        this.setState({
            pageNumber: 1
        })
    }
    componentWillUnmount() {
        document.body.classList.remove('plp_page');
    }
    // After pagination happens, scroll to top of the page.
    // Also, render the range of products being displayed.
    componentDidUpdate(prevProps) {
        // window.scrollTo(0, 0);
        //render the product ranges (Example: 1 to 8 of 32)
        if (this.props.categoryProducts !== prevProps.categoryProducts) {
            const productParameters = getConfigValue("product");

            this.getProductDisplayRanges(this.state.pageNumber, productParameters.productsPerPage);
            this.setState({ isLoading: false });
        }
        //render the product by sorting
        if (this.props.selectedSortOption !== prevProps.selectedSortOption) {
            let queryParams = new URLSearchParams(this.props.location.search);
            this.getPaginatedProducts(1, queryParams, undefined, this);
        }
    }
    componentWillReceiveProps(nextProps) {
        //render the product by sorting
        if (nextProps.location.search !== this.props.location.search) {
            let queryParams = new URLSearchParams(nextProps.location.search);
            this.getPaginatedProducts(1, queryParams, undefined, this);
        }
    }
    // Method to render the page range
    getProductDisplayRanges = (pageNumber, pageSize) => {
        if (!(typeof this.props.categoryProducts === 'undefined')) {
            this.setState({ itemFrom: (pageNumber - 1) * pageSize + 1 });
            this.setState({ itemTo: ((pageNumber) * pageSize > this.props.categoryProducts.total_count) ? this.props.categoryProducts.total_count : (pageNumber) * pageSize })
            this.setState({ total_count: this.props.categoryProducts.total_count ? this.props.categoryProducts.total_count : 0 });
        }
    }

    //Callback function to get Page Number from Common Pagination Component
    getPaginatedProducts = (pageNumberIn, queryParams, urlchanged, component) => {
        if (typeof queryParams === 'undefined') {
            queryParams = new URLSearchParams(this.props.location.search);
        }
        let { targetURLPath, selectedSortOption } = this.props;

        //Extract category Id through a local function and dispatch the action.
        //Pass category Id and page number to be displayed as a parameter  
        this.setState({ isLoading: true });
        //if category exists in query param i.e, category is 
        //one of the filter option selected.
        if (queryParams && queryParams.has('Category')) {
            //get category Id from query params
            let categoryIdInQueryParam = queryParams.get('Category');
            // delete category param as categoryIdInQueryParam 
            //is being sent as input to the dispatcher
            queryParams.delete('Category');
            this.props.getCategoryProducts(categoryIdInQueryParam, pageNumberIn, queryParams, selectedSortOption, urlchanged, component);
        }
        else {
            this.props.getCategoryProducts(extractCategoryId(targetURLPath), pageNumberIn, queryParams, selectedSortOption, urlchanged, component);
        }
        this.setState({ pageNumber: pageNumberIn });
    }

    toggleDrawer = () => {
        this.setState((prevState) => ({
            leftOverlay: !prevState.leftOverlay
        }));
    };

    render() {
        if(!this.props.categoryProducts || !this.props.categoryFilter){
            return (<ContentLoader isLoading={true} />)
        }
        window.stopLazyLoadCount = (this.props.categoryProducts) && this.props.categoryProducts.items.length;
        let { classes } = this.props;
        let { categoryCMS, categoryProducts, targetURLPath } = this.props;
        const { categoryTitle } = this.props;
        var titleText = '';
        if (categoryTitle !== undefined) {
            var str = categoryTitle.replace(/[^A-Z0-9]/ig, '').toLowerCase();
            var subStr = this.props.location.pathname.replace(/[^A-Z0-9]/ig, '').replace('html', '');
            if ((this.props.history.action === 'PUSH' && (subStr.includes(str) || str.includes(subStr))) || this.props.history.action === 'POP') {
                titleText = categoryTitle;
            }
            //    var result = this.props.location.pathname.replace(/-/g, '').includes(categoryTitle.replace(' ', '').toLowerCase());
            //    if(this.props.history.action === 'PUSH' && result  || this.props.history.action === 'POP' && result)
            //    {
            //      titleText=categoryTitle;
            //    }

        }
        const promotion = categoryTitle === "Promotions";
        const productParameters = getConfigValue("product");
        let bannerImagesObject1 =
        {
            imageSrc: BannerImage1,
            imageUrL: '/',
        }
        let bannerImagesObject2 =
        {
            imageSrc: BannerImage2,
            imageUrL: '/',
        }
        let bannerImagesObject3 =
        {
            imageSrc: BannerImage3,
            imageUrL: '/',
        }
        let bannerImages = [bannerImagesObject1, bannerImagesObject2, bannerImagesObject3];
        return (
            <div className={classes.root}>
                <NavigationMenuDesktop/>
                <CategoryChips />
                <Grid container justify="center" className="gridPosition">
                    {/* For devices with less width, hide the Left Shopping Options */}
                    <Hidden only={['sm', 'xs']}>
                        <Grid item md={2} className="filtersec">
                            {/* Temporarily Commented. */}
                            <ShoppingOptions categoryId={extractCategoryId(targetURLPath)} filters={this.props.categoryFilter} />
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item xs={9} style={{ textAlign: 'center' }}>
                            <Button variant="outlined" color="primary" onClick={this.toggleDrawer} style={{ width: '50%', marginTop: '10px', marginBottom: '10px' }}>
                                {<GetLanguage value="Filters" />}
                            </Button>
                            <Dialog fullScreen open={this.state.leftOverlay}
                                onClose={this.toggleDrawer} TransitionComponent={Transition}>
                                <AppBar className={classes.appBar} color="default">
                                    <Toolbar disableGutters={true} className={classes.mobileAppBar}>
                                        <Typography variant="h6" color="inherit" className={classes.flex}>
                                            {<GetLanguage value="Filters" />}
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.toggleDrawer} aria-label="Close">
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <ShoppingOptions categoryId={extractCategoryId(targetURLPath)} />
                            </Dialog>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={10}>
                        {promotion ?
                            <div style={{ padding: '10px' }}>
                                <Carousel
                                    autoplay={true}
                                    slidesToShow={1}
                                    bannerObject={bannerImages}
                                    renderBottomCenterControls={() => { }}
                                    cmsPageWidgetDataObject={[]} {...this.props} />
                            </div>
                            : ''}
                        {/* <CategoryChips component={this} listData={this.state.listData} /> */}
                        {categoryCMS ? <CMSComponent content={categoryCMS.content} title={categoryCMS.title} /> : null}

                        <ProductListingGrid itemFrom={this.state.itemFrom} itemTo={this.state.itemTo}
                            isLoading={this.state.isLoading} titleText={titleText}
                            promotion={promotion} {...this.props}>
                        </ProductListingGrid>
                        <div className={classes.pagination}>

                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

/*****************************************************************************
 * @param {*} categoryURL - Input Category URL received via menu
 * The last digits in the URL path are category IDs.
 *****************************************************************************/
function extractCategoryId(categoryURL) {
    // In the string like "catalog/category/view/id/25",
    // extract "25".
    return categoryURL.split("/")[4];
}

/***************************************************************************** 
 * Product Listing Grid that populates the products of the category.
 * Responsive Layout. 
 * In case, there are no products for the category, an information message 
 * is displayed (using common message component.)
 * Product Image dimensions are taken from common configuration.
 *****************************************************************************/
class ProductListingGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    /***************************************************************************** 
     * Pagination breadcrumb and Screen Flickering issue fix
     * @author    Amaresh M <amaresh.m@embitel.com>
     *****************************************************************************/
    productListPaginationBreadcrumb = (itemFrom, itemTo, total_count) => {
        if (itemFrom > 0) {
            return `${itemFrom} - ${itemTo} of ${total_count}`;
        }
        else {
            return <Typography component={'span'} variant={'body2'}>&nbsp;</Typography>
        }
    }

    render() {
        
        let { classes } = this.props;
        const { categoryProducts, titleText } = this.props;
        const { itemFrom, itemTo, promotion } = this.props;
        const { isLoading, plpViewSelected } = this.props;

        // On initial load of the component, display progress bar
        // as overlay.
        if (!categoryProducts) {
            // return (
            //     <ContentLoader isLoading={true} />
            // );
            return null;
        }
        //In case, there are no products for this category, display a message.
        if (categoryProducts && (categoryProducts.items == null)) {
            const messageObject = { messageType: 'info', message: "No products found for this category" }
            return (
                <UserMessage {...messageObject} />
            )
        }
        
        return (
            <React.Fragment>
                <Grid container justify='space-between'>
                    
                    {/* <Grid item>
                        {!promotion ?
                            <PageTitle title={titleText} />
                            : ''}
                    </Grid> */}
                    <Grid item><Breadcrumbs show={true} label={titleText} /></Grid>
                    <Grid item style={{ paddingRight: '1rem' }} >
                        <Grid direction='row' alignItems='flex-end'>
                            {/* <Grid item>
                                <PLPView />
                            </Grid> */}
                            <Grid item >
                                <SortView />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {
                    <ContentLoader isLoading={isLoading} />
                } */}
                <Grid container className={classes.productListingGrid} justify="flex-start" alignItems="flex-start" >
                    {categoryProducts && categoryProducts.items && categoryProducts.total_count > 0 ?
                        categoryProducts.items.map((productTile, index) =>
                            (
                                productTile.status === '1' | 1 && (
                                    <Grid key={index} item xs={plpViewSelected ? 12 : 6} sm={plpViewSelected ? 12 : 4} md={plpViewSelected ? 12 : 4} lg={plpViewSelected ? 12 : 3} className={classes.sliderdiv}>
                                        <Paper className={classes.paper}>
                                            <ProductCatalog productTile={productTile} />
                                        </Paper>
                                    </Grid>))
                        )
                        :
                        <Typography variant="subtitle1"> No products to display </Typography>
                    }
                </Grid>
            </React.Fragment>
        );
    }
}


ProductListing.propTypes = {
    classes: PropTypes.object.isRequired,
    targetURLPath: PropTypes.string,
    targetPathType: PropTypes.string,
    categoryProducts: PropTypes.object,

};


const ProductListingErrorFallbackComponent = ({ componentStack, error }) => {
    return (
        <PageTitle title="Failed to load product list, please try again." />
    );
}

const ProductListingWithErrorBoundary = withErrorBoundary(
    ProductListing,
    ProductListingErrorFallbackComponent
);

/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    targetURLPath: state.targetPathState.targetURLPath,
    targetPathType: state.targetPathState.targetPathType,
    categoryCMS: state.get_category_cms.categoryCMS,
    categoryProducts: state.get_category_products.categoryProductsData,
    categoryTitle: state.category_title_state.categoryTitle,
    categoryFilter: state.get_category_filter_options,
    plpViewSelected: state.plpView,
    selectedSortOption: state.selectedSort,
});

const mapDispatchToProps = dispatch => ({
    getCategoryProducts: (categoryId, currentPage, queryParams, selectedSortOption, urlchanged, component) => {
        let cs = new CategoryService();
        dispatch(cs.getCategoryDataActionCreator(categoryId, currentPage, queryParams, selectedSortOption, urlchanged, component));
        dispatch(cs. getCategoryFiltersActionCreator(categoryId, currentPage,queryParams,selectedSortOption,urlchanged,component));
    },
    getCategoryFilters: (categoryId) => {
        let cs = new CategoryService();
        //dispatch(cs.getCategoryFilterOptionsActionCreator(categoryId));
        dispatch(cs.getPLPBreadcrumbsActionCreator(categoryId));
    }
});

const ProductListingWithStyles = withStyles(styles)(ProductListingWithErrorBoundary);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListingWithStyles));