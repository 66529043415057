/**
* Component for displaying One Row of Mobile Menu.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { handleURLsForMenu } from '../../../../app/framework/common/helpers';
import {
    TopLevelMobileSubMenuWithStyles,
    SecondLevelMobileSubMenuWithStyles
} from './SubMenus';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor:'#69C1FC',
        borderRadius:0 +' !important'
    },
    rootSelected: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor:'#2196f3'
    },
    subLevelMenuList: {
        backgroundColor: '#ffffff',
        padding: 0,
        margin: 0,
    },
    menuItem: {
        color: '#ffffff',
        textDecoration:'none',
        fontSize:'18px',
        fontWeight: 'bold'
    },
    expansionSummaryStyle: {
        // padding:'1rem 1.5rem'
        backgroundColor:'#2196f3'
    }
});

/*****************************************************************
 * Component class for displaying Category Menu.
 * Receives menu data from Parent Component in the form of props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ****************************************************************/
class NavigationMobileUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            expanded: false,
        }
    }

    handleToggle = (event) => {
        this.setState((prevState) => ({
            open: !prevState.open
        }));
    };

    handleClose = (event) => {
        this.setState({ open: false });
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: panel,
        });
    };
    
    render() {
        const { classes } = this.props;
        const { topmenu, submenu,selected } = this.props;
        const {expanded} = this.state;
        return (
            <ExpansionPanel 
            className={selected?classes.rootSelected:classes.root}
            expanded={expanded}
            
            >
                <ExpansionPanelSummary 
                  onClick={()=>this.setState({expanded:submenu.length ? !expanded :expanded })}
                  className={classes.expansionSummaryStyle}
                    expandIcon={(submenu.length > 0) ? <ExpandMoreIcon color='disabled'/> : ""}>
                    <Typography>
                        <Link className={classes.menuItem} to={handleURLsForMenu(topmenu[2])}>
                            {topmenu[0]}
                        </Link>
                    </Typography>
                </ExpansionPanelSummary>               
                {(submenu.length > 0) ?
                    <ExpansionPanelDetails className={classes.subLevelMenuList}>
                        <ul>
                            {submenu.map(function (submenuItem, index) {
                                return (
                                    <div key={index}>
                                        <TopLevelMobileSubMenuWithStyles
                                            index={index}
                                            submenuItem={submenuItem}
                                        />
                                        {
                                            submenuItem[3].map((secondSubMenu, index) =>
                                                <SecondLevelMobileSubMenuWithStyles
                                                    index={index} key={index}
                                                    submenuItem={secondSubMenu}
                                                />
                                            )
                                        }
                                    </div>
                                );
                            })}
                        </ul>

                    </ExpansionPanelDetails> : ""}
            </ExpansionPanel>
        );
    }
}

NavigationMobileUnit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavigationMobileUnit);