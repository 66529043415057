/**
* CategoryService class with functions for handling category display
* related functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_GET_CATEGORIES,
    REQUEST_GET_CATEGORY_DATA,
    RECEIVE_GET_CATEGORY_DATA,
    REQUEST_GET_CATEGORY_CMS,
    RECEIVE_GET_CATEGORY_CMS,
    REQUEST_GET_CMS_PAGE_CONTENT,
    REQUEST_GET_CATEGORY_PRODUCTS,
    RECEIVE_GET_CATEGORY_PRODUCTS,
    RECEIVE_GET_CATEGORY_TITLE,
    REQUEST_GET_CATEGORY_FILTER_DATA,
    RECEIVE_GET_CATEGORY_FILTER_DATA,
    RECEIVE_GET_CATEGORIES,
    RECEIVE_GET_CMS_PAGE_CONTENT,
    RECEIVE_GET_SEARCH_PRODUCTS,
    REQUEST_GET_SEARCH_PRODUCTS,
    REQUEST_GET_CMS_PAGE_WIDGET_DATA1,
    RECEIVE_GET_CMS_PAGE_WIDGET_DATA1,
    REQUEST_GET_CMS_PAGE_WIDGET_DATA2,
    RECEIVE_GET_CMS_PAGE_WIDGET_DATA2,
    REQUEST_GET_CMS_PAGE_WIDGET_DATA3,
    RECEIVE_GET_CMS_PAGE_WIDGET_DATA3,
    GET_CARROSEL_PRODUCTS,
    REQUEST_CARROSEL_PRODUCTCATEGORIES,
    RECIEVE_CARROSEL_PRODUCTCATEGORIES,
    GET_CARROSEL_PRODUCT_CATEGORIESIMAGES,
    REQUEST_SET_SORT_VIEW,
    RECEIVE_SET_SORT_VIEW,
    SET_LOADER_STATUS,
    REQUEST_BANNER_DATA,
    RECIEVE_BANNER_DATA,
    REQUEST_BRANDS_DATA,
    RECIEVE_BRANDS_DATA,
    RECEIVE_GET_BREADCRUMBS_DATA_PDP,
    REQUEST_GET_BREADCRUMBS_DATA_PDP,
    RECEIVE_GET_BREADCRUMBS_DATA_PLP,
    REQUEST_GET_BREADCRUMBS_DATA_PLP,
    REQUEST_CATEGORY_NAME,
    RECIEVE_CATEGORY_NAME,
    RECIEVE_CROSSSELL_PRODUCTS,
    REQUEST_CROSSSELL_PRODUCTS,
    RECEIVE_GET_FILTER_TAGS,
} from '../../framework/common/ReduxConstants';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import getConfigValue from '../../framework/config/configHandler';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';

/*************************************************************************
 * Category Service Class which handles product listing for
 * categories and filter options present in Categories.
 *************************************************************************/
class CategoryService {
    /*************************************************************************
     * Action Creator for navigation menu population.
     * Will be called on application first load only.
     *************************************************************************/
    getCategoryDataForMenuActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CATEGORIES });
            let requestURL = '/ecs/categories';
            let requestType = 'GET';
            let requestBody = {};
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_CATEGORIES, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CATEGORIES, success: false, error });
                }
            );
        };
    }

    /*************************************************************************
     * Action creator for getting Category Data
     *************************************************************************/
    getCategoryDataActionCreator(categoryId, currentPage, queryParams, selectedSortOption,urlchanged,component) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CATEGORY_DATA });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = '/categories/' + categoryId;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_CATEGORY_DATA });
                    let categoryDisplayType = CategoryService.getCategoryDisplayType(response.data.custom_attributes);
                    // In case of PAGE Category Display
                    if (categoryDisplayType === "PAGE") {
                        let CMSContentId = CategoryService.getCMSContentId(response.data.custom_attributes);

                        if (CMSContentId !== null) {
                            CategoryService.getCategoryCMS(dispatch, categoryId, CMSContentId);
                        }
                    }
                    // In case of PRODUCTS_AND_PAGE Category Display.
                    else if (categoryDisplayType === "PRODUCTS_AND_PAGE") {
                        //Get CMS content for Category
                        let CMSContentId = CategoryService.getCMSContentId(response.data.custom_attributes);
                        if (CMSContentId !== null) {
                            CategoryService.getCategoryCMS(dispatch, categoryId, CMSContentId);
                        }
                        //Get data for Category Products.
                        CategoryService.getCategoryProducts(dispatch, categoryId, currentPage, queryParams, selectedSortOption, urlchanged,component);
                    }
                    // In case of PRODUCTS only Category Display.
                    else {
                        CategoryService.getCategoryProducts(dispatch, categoryId, currentPage, queryParams, selectedSortOption, urlchanged,component);
                    }
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CATEGORY_DATA, success: false, error });
                }
            );
        }
    }

    /**************************************************************************
     * Thunk Action creator
     *************************************************************************/

    /**************************************************************************
     * Action creator for getting CMS Page Content.
     *************************************************************************/
    getCMSPageContentActionCreator(pageId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CMS_PAGE_CONTENT });
            let requestType = 'GET';
            let requestURL = '/cmsPage/' + pageId;
            let requestBody = {};
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for getting CMS Page Content
                    dispatch({ type: RECEIVE_GET_CMS_PAGE_CONTENT, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CMS_PAGE_CONTENT, success: false, error });
                }
            );
        };
    }

    /*************************************************************************
     * Utility method for getting category type.
     *************************************************************************/
    static getCategoryDisplayType(customAttributes) {
        let categoryDisplayType = "PRODUCTS";
        for (let index = 0; index < customAttributes.length; index++) {
            if (customAttributes[index].attribute_code === "display_mode") {
                return customAttributes[index].value;
            }
            else {
                continue;
            }
        }
        return categoryDisplayType;
    }

    /*****************************************************************
     * Utlity method to get CMS content Id.
     *****************************************************************/
    static getCMSContentId(customAttributes) {
        let CMSContentId = null;
        for (let index = 0; index < customAttributes.length; index++) {
            if (customAttributes[index].attribute_code === "landing_page") {
                return customAttributes[index].value;
            }
            else {
                continue;
            }
        }
        return CMSContentId;
    }

    /**************************************************************
     * Utility method to get CMS content of the category
     **************************************************************/
    static getCategoryCMS(dispatch, categoryId, CMSContentId) {
        dispatch({ type: REQUEST_GET_CATEGORY_CMS });
        let requestBody = {};
        requestBody.data = {
        }
        let requestType = 'GET';
        let requestURL = '/cmsBlock/' + CMSContentId;
        requestBody.apiEndPoint = 'thailand';
        MagnaAPIHandler(requestURL, requestType, requestBody).then(
            (response) => {
                dispatch({ type: RECEIVE_GET_CATEGORY_CMS, success: true, response });
            },
            (error) => {
                dispatch({ type: RECEIVE_GET_CATEGORY_CMS, success: false, error });
            }
        );
    }

    /*****************************************************************************
    * Helper method for getting data for Category Products.
    * @param {*} dispatch      - The dispatch of redux
    * @param {*} categoryId    - categoryId being sent from the menu.
    * productsPerPage          - Number of products to be fetched per API call.
    * (configured in config/local.js)
    ******************************************************************************/
    static getCategoryProducts(dispatch, categoryId, currentPage, queryParams, selectedSortOption, urlchanged,component) {
        let reqURLWithFilterOptions = '';
        let reqURLWithSortOptions = '';
        let count = 2;
        let query = false;
        for (var pair of queryParams.entries()) {
            query = true;
            let valueArray = pair[1].split('_');
            if (pair[0].includes('_')) {
                let filterGroup = pair[0].split('_');
                reqURLWithFilterOptions = reqURLWithFilterOptions + '&searchCriteria[filterGroups][' + count + '][filters][0][field]=' +
                    filterGroup[0] + '&searchCriteria[filterGroups][' + count + '][filters][0][value]=' + valueArray[0] +
                    '&searchCriteria[filterGroups][' + count + '][filters][0][conditionType]=' + filterGroup[1];
            }
            else {
                const variant = pair[0] === 'Size' ? 'Size' : pair[0];
                reqURLWithFilterOptions = reqURLWithFilterOptions + '&searchCriteria[filterGroups][' + count + '][filters][0][field]=' +
                    variant + '&searchCriteria[filterGroups][' + count + '][filters][0][value]=' + valueArray[0] +
                    '&searchCriteria[filterGroups][' + count + '][filters][0][conditionType]=eq';
            }
            count++;
        }
        if (selectedSortOption) {
            reqURLWithSortOptions = '&searchCriteria[sortOrders][0][field]=' + selectedSortOption.type;
            reqURLWithSortOptions = selectedSortOption.direction ? reqURLWithSortOptions + '&searchCriteria[sortOrders][0][direction]=' + selectedSortOption.direction : reqURLWithSortOptions;
        }
        dispatch({ type: REQUEST_GET_CATEGORY_PRODUCTS });
        // Query Parameters. Only the required data fields are mentioned in the 
        // fields column.
        const productParameters = getConfigValue("product");
        // Page Size (number of products per page) is taken from configuration (productParameters.productsPerPage).
        // current Page is passed from Product Listing Page.
        let requestURLQuery = "?fields=items[sku,id,extension_attributes,custom_attributes,name,price,special_price,regular_price,status],total_count" +
            "&searchCriteria[filterGroups][0][filters][0][field]=category_id" +
            "&searchCriteria[filterGroups][0][filters][0][value]=" + categoryId +
            "&searchCriteria[filterGroups][0][filters][0][conditionType]=eq" +
            reqURLWithFilterOptions + reqURLWithSortOptions +
            "&searchCriteria[pageSize]= " + productParameters.productsPerPage +
            "&searchCriteria[currentPage]=" + currentPage;
        let requestBody = {};
        requestBody.data = {
        }
        let requestType = 'GET';
        // Values for visibility of products to be displayed in the catalog
        // 2 -> Catalog
        // 4 -> Catalog,Search
        // The Query is for categoryId "eq" categoryId (input) and
        // visibility "in" 4 (Only comma separated values to be used, if there are mutliple.)
        let reqUrlWithVisibility = '&searchCriteria[filterGroups][1][filters][0][field]=visibility' +
            '&searchCriteria[filterGroups][1][filters][0][value]=4' +
            '&searchCriteria[filterGroups][1][filters][0][conditionType]=eq';

        let userInfo = getItemFromLocalStorage('shopce-user');
        let companyId = userInfo ? userInfo.companyId ? '&companyId=' + userInfo.companyId : '' : '';
        let requestURL = (query ? '/ecs/filters' : '/company/products') + requestURLQuery + reqUrlWithVisibility + companyId;

        requestBody.apiEndPoint = 'thailand';
        MagnaAPIHandler(requestURL, requestType, requestBody).then(
            (response) => {
                /**
                 * For normal category listing product data is present in 
                 * response.data.items and for filters response product data 
                 * is present in response.data[0].items.
                 */
                window.scrolled = false;
                let items = (query ? (response.data[0] && response.data[0].items) : response.data.items) || [];
                let total_count = (query ? response.data[0].total_count : response.data.total_count) || 0;

                let mergedResponse = Object.assign(response, { data: { items, total_count } });
                response = mergedResponse;
                dispatch({ type: RECEIVE_GET_CATEGORY_PRODUCTS, success: true, response, urlchanged, component });
            },
            (error) => {
                window.scrolled = false;
                dispatch({ type: RECEIVE_GET_CATEGORY_PRODUCTS, success: false, error });
            }
        );
    }

    /*************************************************************
     * Action Creator for getting filter options for Category.
     * @param   - categoryId    - Category ID.
     *************************************************************/
    // getCategoryFilterOptionsActionCreator(categoryId) {
    //         return (dispatch) => {
    //             dispatch({ type: REQUEST_GET_CATEGORY_FILTER_DATA });
    //             let requestBody = {};
    //             requestBody.data = {
    //             }
    //             let requestType = 'GET';
    //             let requestURL = '/ecs/search?searchCriteria[requestName]=quick_search_container' +
    //             "&searchCriteria[filterGroups][0][filters][0][field]=category_ids" +
    //             "&searchCriteria[filterGroups][0][filters][0][value]=" + categoryId 
    //             requestBody.apiEndPoint = 'thailand';
    //             MagnaAPIHandler(requestURL, requestType, requestBody).then(
    //                 (response) => {
    //                     //const title = { data: [{ category_name: Object.values(response.data[0].ParentCategory).slice(-1)[0] }] };
    //                     //title.status = 200;
    //                     //title.breadrumbs = response.data[0].ParentCategory;
    //                     //dispatch({ type: RECEIVE_GET_CATEGORY_TITLE, success: true, response: title });
    //                     dispatch({ type: RECEIVE_GET_CATEGORY_FILTER_DATA, success: true, response });
    //                 },
    //                 (error) => {
    //                     dispatch({ type: RECEIVE_GET_CATEGORY_FILTER_DATA, success: false, error });
    //                 }
    //             );
    //         }
       
    // }

    getCategoryFiltersActionCreator(categoryId, currentPage,queryParams,selectedSortOption,urlchanged,component) {
        var filterOptionBrand =''; var filterOptionSize ='';
        if(queryParams.get('Brand')!==null){
            filterOptionBrand = queryParams.get('Brand');
        }
        
        if(queryParams.get('Size')!==null){
            filterOptionSize = queryParams.get('Size'); 
        }
        return (dispatch) => {
        dispatch({ type: REQUEST_GET_CATEGORY_FILTER_DATA });
        dispatch({type:SET_LOADER_STATUS})
        // Query Parameters. Only the required data fields are mentioned in the 
        // fields column.

        // Values for visibility of products to be displayed in the catalog
        // 1 -> Not visible individually
        // 2 -> Catalog
        // 3 -> Search
        // 4 -> Catalog,Search
        // The Query is for categoryId "eq" categoryId (input) and
        // visibility "in" 4 (Only comma separated values to be used, if there are mutliple.)
        let visibilityValues = [4];
        let reqURLWithSortOptions='';
        if (selectedSortOption) {
            reqURLWithSortOptions = '&searchCriteria[sortOrders][0][field]=' + selectedSortOption.type;
            reqURLWithSortOptions = selectedSortOption.direction ? reqURLWithSortOptions + '&searchCriteria[sortOrders][0][direction]=' + selectedSortOption.direction : reqURLWithSortOptions;
        }
        const productParameters = getConfigValue("product");
        // Page Size (number of products per page) is taken from configuration (productParameters.productsPerPage).
        // current Page is passed from Product Listing Page.
        let requestURLQuery = "?searchCriteria[requestName]=quick_search_container" +
            "&searchCriteria[filterGroups][0][filters][0][field]=category_ids" +
            "&searchCriteria[filterGroups][0][filters][0][value]=" + categoryId +
            "&searchCriteria[filterGroups][0][filters][1][field]=visibility" +
            "&searchCriteria[filterGroups][0][filters][1][value]=" + visibilityValues[0] + reqURLWithSortOptions+
            "&searchCriteria[filterGroups][0][filters][2][field]=size" +
            "&searchCriteria[filterGroups][0][filters][2][value]=" + filterOptionSize + reqURLWithSortOptions+
            "&searchCriteria[filterGroups][0][filters][2][field]=brand" +
            "&searchCriteria[filterGroups][0][filters][2][value]=" + filterOptionBrand + reqURLWithSortOptions+
            "&searchCriteria[pageSize]= " + productParameters.productsPerPage +
            "&searchCriteria[currentPage]=" + currentPage;
        let requestBody = {};
        requestBody.data = {
        }
        let requestType = 'GET';
        let userInfo = getItemFromLocalStorage('shopce-user');
        let companyId = userInfo? userInfo.companyId? '&companyId='+userInfo.companyId: '': '';
        let requestURL = '/ecs/search' + requestURLQuery+ companyId;
        requestBody.apiEndPoint = 'thailand';
        MagnaAPIHandler(requestURL, requestType, requestBody).then(
            (response) => {
                /**
                 * 
                * For normal category listing product data is present in 
                * response.data.items and for filters response product data 
                * is present in response.data[0].items.
                */
            // if(response.data[0].total_count!==0|'0'){
                let searchResponse = response.data.items || response.data[0].items;
                let mergedResponse = Object.assign(response, { "data": { "items": searchResponse, "total_count": response.data.total_count || response.data[0].total_count, "filters": response.data.filters || response.data[0].filters } });

                response = mergedResponse;
                dispatch({ type: RECEIVE_GET_CATEGORY_FILTER_DATA, success: true, response,component });
                dispatch({type:SET_LOADER_STATUS})
              
            //}
            
            },
            (error) => {
                dispatch({ type: RECEIVE_GET_CATEGORY_FILTER_DATA, success: false,error});
                dispatch({type:SET_LOADER_STATUS})
            }
        );
        }
    
}
    /*************************************************************
     * Action Creator for getting breadcrumbs for PDP.
     * @param   - productId    - Product ID.
     *************************************************************/
    getPDPBreadcrumbsActionCreator(productId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_BREADCRUMBS_DATA_PDP });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = 'ecs/breadcrumb/' + productId;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    const title = { data: [{ category_name: Object.values(response.data[0].ParentCategory).slice(-1)[0] }] };
                    title.status = 200;
                    title.breadrumbs = response.data[0].ParentCategory;
                    dispatch({ type: RECEIVE_GET_BREADCRUMBS_DATA_PDP, success: true, response: title });
                    dispatch({ type: RECEIVE_GET_BREADCRUMBS_DATA_PLP, success: true, response: undefined });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_BREADCRUMBS_DATA_PDP, success: false, error });
                }
            );
        }
    }
    /*************************************************************
         * Action Creator for getting breadcrumbs for PLP.
         * @param   - productId    - Product ID.
         *************************************************************/
    getPLPBreadcrumbsActionCreator(categoryId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_BREADCRUMBS_DATA_PLP });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = 'ecs/plp/breadcrumb/' + categoryId;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    const title = { data: [{ category_name: Object.values(response.data[0].ParentCategory).slice(-1)[0] }] };
                    title.status = 200;
                    title.breadrumbs = response.data[0].ParentCategory;
                    dispatch({ type: RECEIVE_GET_BREADCRUMBS_DATA_PLP, success: true, response: title });
                    dispatch({ type: RECEIVE_GET_BREADCRUMBS_DATA_PDP, success: true, response: undefined });
                    
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_BREADCRUMBS_DATA_PLP, success: false, error });
                }
            );
        }
    }
    /******************************************************************* 
    * Action Creator for getting products by Search
    * Actions for getting products from Search.
    * Calls REST API 
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *******************************************************************/
    getProductsFromSearchActionCreator(searchTerm, currentPage, selectedSortOption, filterOption, component) {
        return (dispatch) => {
            CategoryService.getCategoryProductsForSearch(dispatch, searchTerm, currentPage, selectedSortOption, filterOption, component);
        }
    }

    /**************************************************************************
     * 
     * @param {*} dispatch      - The dispatch of redux
     * @param {*} searchTerm    - searchTerm entered by the user.
     *  productsPerPage      - Number of products to be fetched per API call.
     *  (configured in config/local.js)
     **************************************************************************/
    static getCategoryProductsForSearch(dispatch, searchTerm, currentPage, selectedSortOption,filterOption,component) {
        
            dispatch({ type: REQUEST_GET_SEARCH_PRODUCTS });
            dispatch({type:SET_LOADER_STATUS})
            // Query Parameters. Only the required data fields are mentioned in the 
            // fields column.

            // Values for visibility of products to be displayed in the catalog
            // 1 -> Not visible individually
            // 2 -> Catalog
            // 3 -> Search
            // 4 -> Catalog,Search
            // The Query is for categoryId "eq" categoryId (input) and
            // visibility "in" 4 (Only comma separated values to be used, if there are mutliple.)
            let visibilityValues = [4];
            let reqURLWithSortOptions='';
            if (selectedSortOption) {
                reqURLWithSortOptions = '&searchCriteria[sortOrders][0][field]=' + selectedSortOption.type;
                reqURLWithSortOptions = selectedSortOption.direction ? reqURLWithSortOptions + '&searchCriteria[sortOrders][0][direction]=' + selectedSortOption.direction : reqURLWithSortOptions;
            }
            const productParameters = getConfigValue("product");
            // Page Size (number of products per page) is taken from configuration (productParameters.productsPerPage).
            // current Page is passed from Product Listing Page.
            let requestURLQuery = "?searchCriteria[requestName]=quick_search_container" +
                "&searchCriteria[filterGroups][0][filters][0][field]=search_term" +
                "&searchCriteria[filterGroups][0][filters][0][value]=" + searchTerm +
                "&searchCriteria[filterGroups][0][filters][1][field]=visibility" +
                "&searchCriteria[filterGroups][0][filters][1][value]=" + visibilityValues[0] + reqURLWithSortOptions+
                "&searchCriteria[filterGroups][0][filters][2][field]=size" +
                "&searchCriteria[filterGroups][0][filters][2][value]=" + filterOption + reqURLWithSortOptions+
                "&searchCriteria[filterGroups][0][filters][2][field]=brand" +
                "&searchCriteria[filterGroups][0][filters][2][value]=" + filterOption + reqURLWithSortOptions+
                "&searchCriteria[pageSize]= " + productParameters.productsPerPage +
                "&searchCriteria[currentPage]=" + currentPage;
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let userInfo = getItemFromLocalStorage('shopce-user');
            let companyId = userInfo? userInfo.companyId? '&companyId='+userInfo.companyId: '': '';
            let requestURL = '/ecs/search' + requestURLQuery+ companyId;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /**
                    * For normal category listing product data is present in 
                    * response.data.items and for filters response product data 
                    * is present in response.data[0].items.
                    */
                dispatch({type:SET_LOADER_STATUS})
                if(response.data[0].total_count!==0|'0' && response.data[0].items){
                    let searchResponse = response.data.items || response.data[0].items;
                    let productIds = searchResponse && searchResponse.map((product) => { return product.id });
                    let commaSeparatedProductIds = productIds.toString();

                    let requestTypeConfigOptions = 'GET';
                    let requestURLConfigOptions = '/ecs/configoptionslist?configProductIdsList=' + commaSeparatedProductIds;
                    let requestBodyConfigOptions = {};
                    requestBodyConfigOptions.apiEndPoint = 'thailand';
                    MagnaAPIHandler(requestURLConfigOptions, requestTypeConfigOptions, requestBodyConfigOptions).then(
                        (responseConfigOptions) => {
                            window.scrolled = false;
                            //Merge the category response and config options data
                            let mergedCategoryListingData = searchResponse.map((categoryProduct) => {
                                //Access by key
                                let configOptionsById = responseConfigOptions.data[0][categoryProduct.id.toString()];
                                if (configOptionsById === null) {
                                    return Object.assign(categoryProduct, { "configurable_options": null, "simple_products": null });
                                }
                                else {
                                    return Object.assign(categoryProduct, { "configurable_options": configOptionsById.configurable_options, "simple_products": configOptionsById.simple_products });
                                }
                            });

                            /**
                             * For normal category listing total count is present in 
                             * response.data.total_count and for filters, it is present in
                             * response.data[0].total_count
                             */
                            let mergedResponse = Object.assign(response, { "data": { "items": mergedCategoryListingData, "total_count": response.data.total_count || response.data[0].total_count, "filters": response.data.filters || response.data[0].filters } });

                            response = mergedResponse;

                            dispatch({ type: RECEIVE_GET_SEARCH_PRODUCTS, success: true, response,component });

                        },
                        (error) => {
                        }
                    );
                }
                else {
                    window.scrolled = false;
                    let error = "No items found"
                    dispatch({ type: RECEIVE_GET_SEARCH_PRODUCTS, success: false, error });
                }
                },
                (error) => {
                    window.scrolled = false;
                    dispatch({ type: RECEIVE_GET_SEARCH_PRODUCTS, success: false, error });
                }
            );
            
    }

    /***************************************************************************** 
    * Helper method for CMS Page Widget Data1
    * @param {*} state     - No modifications
    * @param {*} action    - Based on action, required data for the carousel is
    *                        returned.
    * @author    Amaresh M <amaresh.m@embitel.com>
    *****************************************************************************/
    static applyCMSPageWidgetData1Details = (state, action) => {
        let cmsPageWidgetData1Details = [];
        //Check if CMS Page Widget Data1 in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            cmsPageWidgetData1Details = action.response.data;
        }
        else {
            cmsPageWidgetData1Details = [];
        }
        return {
            "cmsPageWidgetData1Details": cmsPageWidgetData1Details
        }
    }

    /***************************************************************************** 
    * Reducer for getting CMS Page widget data.
    * @param {*} state     - No modifications
    * @param {*} action    - Based on action, required data for the carousel is
    *                        fetched.
    * @author    Amaresh M <amaresh.m@embitel.com>
    *****************************************************************************/
    static getCMSPageWidgetData1Reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CMS_PAGE_WIDGET_DATA1:
                let retValue = CategoryService.applyCMSPageWidgetData1Details(state, action);
                return retValue;
            default:
                return state;
        }
    };
    /***************************************************************************** 
        * Reducer for getting Filter Tags/Chips data.
        * 
        * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    static getFilterTagsDataReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_FILTER_TAGS:
                let retValue = CategoryService.applyFilterTagsData(state, action);
                return retValue;
            default:
                return state;
        }
    };

    /***************************************************************************** 
        * Helper Method for getting Filter Tags/Chips data.
        * 
        * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    static applyFilterTagsData = (state, action) => {
        let filterTags = [];
        filterTags = action.tagArray;
        //Check if CMS Page Widget Data1 in the action variable.  
        return {
            filterTags
        }
    }

    /***************************************************************************** 
        * Action Creator for getting Filter Tags/Chips data.
        * 
        * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    getFilterTagsDataActionCreator(tagArray) {
        return (dispatch) => {
            dispatch({ type: RECEIVE_GET_FILTER_TAGS, tagArray });

        }
    }
    /********************************************************************************
     * Common function to get Configurable options by calling another API and merge
     * the responses from both the API's
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     *********************************************************************************/
    static getConfigurableOptionsAndMergeResponse = (dispatch, response, dispatchType) => {
        //***  */
        // Fetching data required for Add to Cart (configurable options)


        let dataArray = response.data;
        if (typeof dataArray[0] !== 'undefined') {
            let dataArrayValues = Object.values(dataArray[0]);
            let contentArray = Object.values(dataArrayValues[0])[0];
            let productIds = contentArray.map((product) => { return (product.id) });
            let commaSeparatedProductIds = productIds.toString();

            let requestTypeConfigOptions = 'GET';
            let requestURLConfigOptions = '/ecs/configoptionslist?configProductIdsList=' + commaSeparatedProductIds;
            let requestBodyConfigOptions = {};
            requestBodyConfigOptions.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURLConfigOptions, requestTypeConfigOptions, requestBodyConfigOptions).then(
                (responseConfigOptions) => {

                    //Merge the category response and config options data
                    let mergedProductListingData = contentArray.map((productListingItem) => {
                        //Access by key
                        let configOptionsById = responseConfigOptions.data[0][productListingItem.id.toString()];
                        if (configOptionsById === null) {
                            return Object.assign(productListingItem, { "configurable_options": null, "simple_products": null });
                        }
                        else {
                            return Object.assign(productListingItem, { "configurable_options": configOptionsById.configurable_options, "simple_products": configOptionsById.simple_products });
                        }
                    });

                    let mergedResponse = Object.assign(response, { "data": { "content": mergedProductListingData, "content_heading": response.data.content_heading } });

                    response = mergedResponse;
                    dispatch({ type: dispatchType, success: true, response });
                },
                (error) => {
                    dispatch({ type: dispatchType, success: false, error });
                }
            );
        }
    }

    /*************************************************************************************** 
    * Action Creator for getting CMS Page widget data1.
    * @param     cmsPageId - cmsPageId for carousel display
    * @author    Amaresh M <amaresh.m@embitel.com>
    ****************************************************************************************/
    getCMSPageWidgetData1ActionCreator(cmsPageId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CMS_PAGE_WIDGET_DATA1 });

            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = '/cmsPage/' + cmsPageId;
            // let requestURL = '/cmsPage/1' + cmsPageId;

            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                     * Handle call to REST API to get config options and prepare a merge
                     * of the response.
                     */
                    CategoryService.getConfigurableOptionsAndMergeResponse(dispatch, response, RECEIVE_GET_CMS_PAGE_WIDGET_DATA1);
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA1, success: false, error });
                }
            );
        }
    }

    /***************************************************************************** 
    * Helper method for getting CMS Page Widget Data 2
    * @param {*} state     - No modifications
    * @param {*} action    - Based on action, required data for the carousel is
    *                        returned.
    * @author    Amaresh M <amaresh.m@embitel.com>
    *****************************************************************************/
    static applyCMSPageWidgetData2Details = (state, action) => {
        let cmsPageWidgetData2Details = [];
        //Check if CMS widget data 2 is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            cmsPageWidgetData2Details = action.response.data;
        }
        else {
            cmsPageWidgetData2Details = [];
        }
        return {
            "cmsPageWidgetData2Details": cmsPageWidgetData2Details
        }
    }

    /***************************************************************************** 
    * Reducer for  getting CMS Page Widget Data 2
    * @param {*} state     - No modifications
    * @param {*} action    - Based on action, required data for the carousel is
    *                        fetched.
    * @author    Amaresh M <amaresh.m@embitel.com>
    *****************************************************************************/
    static getCMSPageWidgetData2Reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CMS_PAGE_WIDGET_DATA2:
                let retValue = CategoryService.applyCMSPageWidgetData2Details(state, action);
                return retValue;
            default:
                return state;
        }
    };
    /***************************************************************************** 
       * Reducer for  getting Brands Data for Home Page
       * @param {*} state     - No modifications
       * @param {*} action    - Based on action, required data for the carousel is
       *                        fetched.
       * @author    Swathy Sagar <swathy.sagar@embitel.com>
       *****************************************************************************/
    static getBrandsDataReducer = (state = [], action) => {
        switch (action.type) {
            case RECIEVE_BRANDS_DATA:
                return CategoryService.applyGetHomePageBrands(action, state);
            default:
                return state;
        }
    };
    static applyGetHomePageBrands = (action, state) => {
        if (action.payload) {
            let homepageBrands =
            {
                homepageBrands: action.payload,
            }
            return Object.assign({}, homepageBrands, { homepageBrands: action.payload });
        }
        else {
            let homepageBrands = { homepageBrands: [] }
            return Object.assign({}, homepageBrands, { homepageBrands: [] });
        }
    }
    /*************************************************************************************** 
    * Action Creator for getting CMS Page Widget Data 2
    * @param     cmsPageId  - cmsPageId for carousel display
    * @author    Amaresh M <amaresh.m@embitel.com>
    ****************************************************************************************/
    getCMSPageWidgetData2ActionCreator(cmsPageId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CMS_PAGE_WIDGET_DATA2 });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = '/cmsPage/' + cmsPageId;
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                     * Handle call to REST API to get config options and prepare a merge
                     * of the response.
                     */
                    CategoryService.getConfigurableOptionsAndMergeResponse(dispatch, response, RECEIVE_GET_CMS_PAGE_WIDGET_DATA2);
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA2, success: false, error });
                }
            );
        }
    }

    /***************************************************************************** 
   * Helper method for getting CMS Page Widget Data 2
   * @param {*} state     - No modifications
   * @param {*} action    - Based on action, required data for the carousel is
   *                        returned.
   * @author    Amaresh M <amaresh.m@embitel.com>
   *****************************************************************************/
    static applyCMSPageWidgetData3Details = (state, action) => {
        let cmsPageWidgetData3Details = [];
        //Check if CMS widget data 2 is present in the action variable.  
        if (action && action.response && ("data" in action.response)) {
            cmsPageWidgetData3Details = CategoryService.reSturctureProductObject(action.response.data[0].items);
        }
        else {
            cmsPageWidgetData3Details = [];
        }
        return {
            "cmsPageWidgetData3Details": cmsPageWidgetData3Details
        }
    }

    /***************************************************************************** 
    * Reducer for  getting CMS Page Widget Data 2
    * @param {*} state     - No modifications
    * @param {*} action    - Based on action, required data for the carousel is
    *                        fetched.
    * @author    Amaresh M <amaresh.m@embitel.com>
    *****************************************************************************/
    static getCMSPageWidgetData3Reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CMS_PAGE_WIDGET_DATA3:
                let retValue = CategoryService.applyCMSPageWidgetData3Details(state, action);
                return retValue;
            default:
                return state;
        }
    };

    /*************************************************************************************** 
    * Action Creator for getting CMS Page Widget Data 2
    * @param     cmsPageId  - cmsPageId for carousel display
    * @author    Amaresh M <amaresh.m@embitel.com>
    ****************************************************************************************/
    getCMSPageWidgetData3ActionCreator(cmsPageId) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_CMS_PAGE_WIDGET_DATA3 });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = 'ecs/crosssellproducts/13';
            // let requestURL = '/cmsPage/' + cmsPageId;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                     * Handle call to REST API to get config options and prepare a merge
                     * of the response.
                     */
                    dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA3, success: true, response });

                    // CategoryService.getConfigurableOptionsAndMergeResponse(dispatch, response, RECEIVE_GET_CMS_PAGE_WIDGET_DATA3);
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA3, success: false, error });
                }
            );
        }
    }

    GetCarroselProductsActionCreator(id, carrouseltype) {
        return (dispatch) => {
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = '';
            let userInfo = getItemFromLocalStorage('shopce-user');
            let companyId = userInfo ? userInfo.companyId ? userInfo.companyId : '' : '';
            let companyURL = '';
            if (carrouseltype === 'crosssellproducts') {
                companyURL = companyId !== '' ? '&companyId=' + companyId : '';
                requestURL = 'company/products?fields=items[sku,id,custom_attributes,is_in_stock,name,price,' +
                    'regular_price,status,extension_attributes[is_in_stock]],total_count' +
                    '&searchCriteria[filterGroups][0][filters][0][field]=category_id' +
                    '&searchCriteria[filterGroups][0][filters][0][value]=' + id +
                    '&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&' +
                    'searchCriteria[sortOrders][0][field]=created_at&' +
                    'searchCriteria[sortOrders][0][direction]=DESC&' +
                    'searchCriteria[pageSize]=%2012&%20searchCriteria[currentPage]=1&' +
                    'searchCriteria[filterGroups][1][filters][0][field]=visibility&' +
                    'searchCriteria[filterGroups][1][filters][0][value]=4&' +
                    'searchCriteria[filterGroups][1][filters][0][conditionType]=eq';
            }
            else {
                companyURL = companyId !== '' ? '?companyId=' + companyId : '';
                requestURL = 'ecs/relatedupsell/' + id;
            }
            requestURL = requestURL + companyURL;
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: GET_CARROSEL_PRODUCTS, payload: response.data[0] ? response.data[0] : response.data, carrouseltype, id });
                },
                (error) => {
                    // dispatch({ type: SET_LOADER_STATUS });
                    // dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA3, success: false, error });
                }
            );
        }
    }

    //Action creator for obtaining crossell products in cart page
    GetCrossSellProductsActionCreator(){
        return(dispatch) =>{
            dispatch({ type: REQUEST_CROSSSELL_PRODUCTS });
            dispatch({type:SET_LOADER_STATUS})
            let requestBody = {};
            let userInfo = getItemFromLocalStorage('shopce-user');
            let userToken = '';
            let tokenType = localStorage.getItem('current_cart');
            if(tokenType === 'Foods'){
                userToken = userInfo ? userInfo.multiple.Foods : null;
            }
            else{
                userToken = userInfo ? userInfo.multiple.HPC : null;
            }
            requestBody.header = ("Bearer " + userToken);
            let requestType = 'GET';
            let requestURL = 'ecs/crosssellproducts';
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                       * Handle call to REST API to get config options and prepare a merge
                       * of the response.
                       */
                    dispatch({ type: RECIEVE_CROSSSELL_PRODUCTS, payload: response.data });
                    dispatch({type:SET_LOADER_STATUS})
                 },
                (error) => {
                    dispatch({ type: RECIEVE_CROSSSELL_PRODUCTS, success: false, error });
                    dispatch({type:SET_LOADER_STATUS})
                }
            );
        }
    }
    GetHomePageBannerDetailsActionCreator(id) {
        return (dispatch) => {
            dispatch({ type: REQUEST_BANNER_DATA });
            let requestBody = {};
            requestBody.data = {
            }
            const systemParameters = getConfigValue("system");
            let websiteId = window.location.hostname === 'usmileshoppro.com' ? systemParameters.store[0].websiteId : systemParameters.store[1].websiteId

            let requestType = 'GET';
            let requestURL = 'ecs/bannerslider/' + id + "/" + websiteId;

            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                       * Handle call to REST API to get config options and prepare a merge
                       * of the response.
                       */
                    dispatch({ type: RECIEVE_BANNER_DATA, payload: response.data });
                },
                (error) => {
                    dispatch({ type: RECIEVE_BANNER_DATA, success: false, error });
                }
            );
        }
    }

    /*************************************************************************
     * Action Creator for brands menu population.
     * Will be called on application first load only.
     *************************************************************************/
    getBrandsDataForHomePageActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_BRANDS_DATA });

            const systemParameters = getConfigValue("system");
            let websiteId = window.location.hostname === 'usmileshoppro.com' ? systemParameters.store[0].websiteId : systemParameters.store[1].websiteId

            let requestURL = '/ecs/brand/' + websiteId;
            let requestType = 'GET';
            let requestBody = {};
            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECIEVE_BRANDS_DATA, success: true, payload: response.data });
                },
                (error) => {
                    dispatch({ type: RECIEVE_BRANDS_DATA, success: false, error });
                }
            );
        };
    }

    static applyGetHomePageBanners = (action, state) => {
        let homepageBanners = [];

        if (action.payload) {
            let homepageBanners =
            {
                homepageBanners: action.payload,
            }
            return Object.assign({}, homepageBanners, { homepageBanners: action.payload });
        }
        return homepageBanners;
    }
    
    //helper method for obtaining cross-sell products
    static applyGetCrossSellProducts = (action, state) => {
        let crosssell_products = [];

        if (action.payload) {
            crosssell_products = action.payload;
        }
        return crosssell_products;
    }
    static GetHomePageBannersReducer = (state = {}, action) => {
        switch (action.type) {
            case REQUEST_BANNER_DATA:
                return { ...state, homepageBanners: null };
            case RECIEVE_BANNER_DATA:
                return CategoryService.applyGetHomePageBanners(action, state);
            default:
                return state;
        }
    };
    // Reducer for obtaining cross-sell products
    static GetCrossSellProductsReducer = (state = {}, action) => {
        switch (action.type) {
            case REQUEST_CROSSSELL_PRODUCTS:
                return { ...state, crosssellproducts: null };
            case RECIEVE_CROSSSELL_PRODUCTS:
                return CategoryService.applyGetCrossSellProducts(action, state);
            default:
                return state;
        }
    };
    GetCarroselProductCategoriesActionCreator() {
        var menuid;

        if (window.location.hostname === "usmileshoppro.com") {
            menuid = 702;
        } else {
            menuid = 134;
        }
        return (dispatch) => {
            // dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_CARROSEL_PRODUCTCATEGORIES });

            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            // let requestURL = 'ecs/categories?rootCategoryId=702';
            let requestURL = 'ecs/categories?rootCategoryId=' + menuid;

            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {

                    /***
                       * Handle call to REST API to get config options and prepare a merge
                       * of the response.
                       */
                    dispatch({ type: RECIEVE_CARROSEL_PRODUCTCATEGORIES, payload: response.data.children_data });
                    // dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    // dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA3, success: false, error });
                    // dispatch({ type: SET_LOADER_STATUS });
                }
            );
        }
    }
    static applyGetProductCategoriesData = (action, state) => {
        let productCategoriesData = [];

        if (action.payload) {

            productCategoriesData = action.payload;
        }
        return productCategoriesData;
    }
    static GetCarroselProductCategories = (state = {}, action) => {
        switch (action.type) {
            case REQUEST_CARROSEL_PRODUCTCATEGORIES:
                return { ...state, productCategoriesData: null };
            case RECIEVE_CARROSEL_PRODUCTCATEGORIES:
                return CategoryService.applyGetProductCategoriesData(action, state);
            default:
                return state;
        }
    };


    GetCarroselProductCategoriesImagesActionCreator(id, carrouseltype) {
        return (dispatch) => {
            dispatch({ type: SET_LOADER_STATUS });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = '';

            requestURL = 'categories/' + id;

            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    /***
                       * Handle call to REST API to get config options and prepare a merge
                       * of the response.
                       */
                    dispatch({ type: GET_CARROSEL_PRODUCT_CATEGORIESIMAGES, payload: response.data, carrouseltype, id });
                    dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    // dispatch({ type: RECEIVE_GET_CMS_PAGE_WIDGET_DATA3, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                }
            );
        }
    }

    static setCarrouselForProductImage(action, state) {
        const newState = { ...state };
        newState[action.carrouseltype] = newState[action.carrouseltype] || {};
        newState[action.carrouseltype][action.id] = newState[action.carrouseltype][action.id] || action.payload;
        // newState[action.carrouseltype][action.id].items = CategoryService.reSturctureProductImageObject(newState[action.carrouseltype][action.id].items);
        return newState;
    }
    static GetCarroselProduCategorieImages = (state = {}, action) => {
        switch (action.type) {
            case GET_CARROSEL_PRODUCT_CATEGORIESIMAGES:
                return CategoryService.setCarrouselForProductImage(action, state);
            default:
                return state;
        }
    };



    /***************************************************************************** 
     * Redux Reducers
     *****************************************************************************/

    /***************************************************************************** 
    * Helper function for GetCategoryCMSReducer
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyGetCategoryCMS = (state, action) => {
        let categoryCMS = null;
        if (action.response && (action.response.status === 200)) {
            categoryCMS = action.response.data;
        }
        return {
            "categoryCMS": categoryCMS,
        }
    }

    /**********************************************************************
    * Category CMS Reducer
    * @param {*} state     - No modifications
    * @param {*} action    - No modifications
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    **********************************************************************/
    static GetCategoryCMSReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CATEGORY_CMS:
                let retValue = CategoryService.applyGetCategoryCMS(state, action);
                return retValue;
            default:
                return state;
        }
    };

    /***************************************************************************** 
    * Helper function for GetCategoryDataReducer
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyGetCategoryData = (state, action) => {
        let categoryData = [];
        if (action.response && (action.response.status === 200)) {
            categoryData = action.response.data ? action.response.data.children_data : '';
        }
        return {
            "categoryData": categoryData,
        }
    }

    /**********************************************************************
     * Reducer for Category Data.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static GetCategoryDataReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CATEGORIES:
                let retValue = CategoryService.applyGetCategoryData(state, action);
                return retValue;
            default:
                return state;
        }
    };

    /***************************************************************************** 
    * Helper method for GetCategoryFilterOptionsReducer
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static applyGetCategoryFilterData = (state, action) => {
        let categoryFiltersData = [];
        if (action.response && (action.response.status === 200)) {
            categoryFiltersData = action.response.data.filters;
        }
        return categoryFiltersData;
    }

    /**********************************************************************
     * Reducer for getting filter options.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Sameena Shaik <shaik.sameena@embitel.com>
     **********************************************************************/
    static GetCategoryFilterOptionsReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CATEGORY_FILTER_DATA:
                let retFilterValue = CategoryService.applyGetCategoryFilterData(state, action);
                return retFilterValue;
            default:
                return state;
        }
    };
    static reSturctureProductObject = (items) => {
        let newItems = [];

        items && items.length && items.map((item, k) => {
            newItems.push(item);
            newItems[k].media_gallery_entries = [];
            if (typeof item.custom_attributes !== undefined && item.custom_attributes.length) {
                item.custom_attributes.map(attr => {
                    if (attr.attribute_code === 'image' || attr.attribute_code === 'small_image') {
                        newItems[k].media_gallery_entries.push({ file: attr.value });
                    } else if (attr.attribute_code === 'url_key') {
                        newItems[k].url_key = attr.value + '.html';
                    } else {
                        newItems[k][attr.attribute_code] = attr.value;
                    }
                })
            }
        })
        return newItems;
    }
    /***************************************************************************** 
    * Helper method for GetCategoryProductsReducer
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyGetCategoryProductsData = (state, action) => {
        let categoryProductsData = [];
        if (action.response && (action.response.status === 200)) {
            if (action.response.data instanceof Array) {
                categoryProductsData = action.response.data[0];
            }
            else {
                categoryProductsData = action.response.data;
            }
        }
        categoryProductsData.items = CategoryService.reSturctureProductObject(categoryProductsData.items)

        return {
            "categoryProductsData": categoryProductsData,
        }
    }

    /**********************************************************************
     * Reducer for getting category products.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static GetCategoryProductsReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CATEGORY_PRODUCTS:
                let retValue = CategoryService.applyGetCategoryProductsData(state, action);
                window.plpTotalCount = retValue.categoryProductsData.total_count;
                if(state.categoryProductsData){
                    if(action.urlchanged === "urlchanged"){
                        return retValue;
                    }
                    //sort a categories by asc or dsc
                    if(action&&action.component&&action.component.props.selectedSortOption&&action.component.props.selectedSortOption !==""&&action.component.state.pageNumber===1){
                        return retValue;
                    }
                    //for category filter for the first time when page number is zero
                    if(action&&action.component&&action.component.state&&action.component.state.pageNumber&&(action.component.state.pageNumber===1&&action.component.props.location.search !=="")){
                        return retValue;
                    }
                    return{
                            ...state,
                            categoryProductsData:{
                                items:[...state.categoryProductsData.items,...retValue.categoryProductsData.items],
                                total_count:retValue.categoryProductsData.total_count
                            }
                        }
                }else{
                    return retValue;
                }
                // if(state.categoryProductsData){ 
                    
                //     if(action.urlchanged == "urlchanged"){
                //         return retValue;
                //     } 
                //     if(retValue.categoryProductsData.total_count <= 32){
                //         return retValue;
                //     }
                //     if((retValue.categoryProductsData.items.length > 0)&&state&&state.categoryProductsData&&state.categoryProductsData.total_count != retValue.categoryProductsData.total_count){
                //         if(state&&state.categoryProductsData&&state.categoryProductsData.items[0].short_description != retValue.categoryProductsData.items[0].short_description){
                //             return retValue;
                //         }
                //     }
                //     if((retValue.categoryProductsData.items.length > 0)&&state&&state.categoryProductsData&&state.categoryProductsData.total_count == retValue.categoryProductsData.total_count){
                //         if(state&&state.categoryProductsData&&state.categoryProductsData.items[0].sku != retValue.categoryProductsData.items[0].sku && action.component.state.pageNumber ==1){
                //             return retValue;
                //         }
                //         if(state&&state.categoryProductsData&&state.categoryProductsData.items[0].sku == retValue.categoryProductsData.items[0].sku && action.component.props.selectedSortOption !=""){
                //             return{
                //                 ...state,
                //                 categoryProductsData:{
                //                     items:[...state.categoryProductsData.items,...retValue.categoryProductsData.items],
                //                     total_count:retValue.categoryProductsData.total_count
                //                 }
                //             }
                //         }
                //     }
                //     if((action.component&&action.component.props.location.search !="")&&(retValue.categoryProductsData.items.length > 0)&&state&&state.categoryProductsData&&state.categoryProductsData.total_count == retValue.categoryProductsData.total_count&& action.component.state.pageNumber ==1){
                //         if(state&&state.categoryProductsData&&state.categoryProductsData.items[0].sku != retValue.categoryProductsData.items[0].sku){
                //             return retValue;
                //         }
                //     }
    
                //     if(action.component&&action.component.props.location.search !=""&&retValue.categoryProductsData.total_count != state.categoryProductsData.total_count&& action.component.state.pageNumber ==1){
                //         return retValue;
                //     }
                    
                //     return{
                //         ...state,
                //         categoryProductsData:{
                //             items:[...state.categoryProductsData.items,...retValue.categoryProductsData.items],
                //             total_count:retValue.categoryProductsData.total_count
                //         }
                //     }
                // }else{
                //     return retValue;
                // }
                // return retValue;
            default:
                return state;
        }
    };

    /***************************************************************************** 
    * Helper method for category_title_reducer
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications, returns extracted data from action.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyCategoryTitle = (state, action) => {
        let categoryTitle = [];
        let breadcrumbs;
        if (action.response && (action.response.status === 200)) {
            categoryTitle = action.response.data[0].category_name;
            breadcrumbs = action.response.breadrumbs;
        }
        return {
            categoryTitle,
            breadcrumbs
        }
    }
    /***************************************************************************** 
        * Helper method for pdp_breadcrumbs_reducer
        * @param {*} state     - No modifications
        * @param {*} action    - No Modifications, returns extracted data from action.
       * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    static applyBreadcrumbsPdp = (state, action) => {
        //let categoryTitle = [];
        let breadcrumbs = undefined;
        if (action.response && (action.response.status === 200)) {
            //categoryTitle = action.response.data[0].category_name;
            breadcrumbs = action.response.breadrumbs;
        }
        return {
            //categoryTitle,
            breadcrumbs
        }
    }
    /***************************************************************************** 
        * Helper method for plp_breadcrumbs_reducer
        * @param {*} state     - No modifications
        * @param {*} action    - No Modifications, returns extracted data from action.
        * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    static applyBreadcrumbsPlp = (state, action) => {
        //let categoryTitle = [];
        let breadcrumbs = undefined;
        if (action.response && (action.response.status === 200)) {
            //categoryTitle = action.response.data[0].category_name;
            breadcrumbs = action.response.breadrumbs;
        }
        return {
            //categoryTitle,
            breadcrumbs
        }
    }
    /***************************************************************************** 
    * Reducer for getting Category Title
    * @param {*} state     - No modifications
    * @param {*} action    - No Modifications, returns extracted data from action.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static category_title_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CATEGORY_TITLE:
                let retCategoryTitle = CategoryService.applyCategoryTitle(state, action);
                return retCategoryTitle;
            default:
                return state;
        }
    };
    /***************************************************************************** 
        * Reducer for getting PDP Breadcrumbs
        * @param {*} state     - No modifications
        * @param {*} action    - No Modifications, returns extracted data from action.
        * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    static pdp_breadcrumbs_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_BREADCRUMBS_DATA_PDP:
                let retBreadCrumbsPdp = CategoryService.applyBreadcrumbsPdp(state, action);
                return retBreadCrumbsPdp;
            default:
                return state;
        }
    };
    /***************************************************************************** 
        * Reducer for getting PLP Breadcrumbs
        * @param {*} state     - No modifications
        * @param {*} action    - No Modifications, returns extracted data from action.
         * @author    Swathy Sagar <swathy.sagar@embitel.com>
        *****************************************************************************/
    static plp_breadcrumbs_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_BREADCRUMBS_DATA_PLP:
                let retBreadCrumbsPlp = CategoryService.applyBreadcrumbsPlp(state, action);
                return retBreadCrumbsPlp;
            default:
                return state;
        }
    };
    /***************************************************************************** 
     * Helper method for reducer cms_page_content_reducer
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications, returns extracted data from action.
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     *****************************************************************************/
    static applyCMSPageContent = (state, action) => {
        let cmsPageContent = [];
        if (action.response && (action.response.status === 200)) {
            cmsPageContent = action.response.data;
        }
        return {
            "cmsPageContent": cmsPageContent,
        }
    }

    /**********************************************************************
     * Reducer for getting products from CMS Page Info.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static cms_page_content_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_CMS_PAGE_CONTENT:
                let retCMSPageContent = CategoryService.applyCMSPageContent(state, action);
                return retCMSPageContent;
            default:
                return state;
        }
    };

    /***************************************************************************** 
     * Helper method for GetProductsFromSearchReducer
     * @param {*} state     - No modifications
     * @param {*} action    - No Modifications
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     *****************************************************************************/
    static applyGetProductsFromSearchData = (state, action) => {
        let searchProductsData = [];

        if (action.response && (action.response.status === 200)) {
            searchProductsData = action.response.data;
        }
        searchProductsData.items = CategoryService.reSturctureProductObject(searchProductsData.items)
        return {
            "searchProductsData": searchProductsData,
        }
    }

    /**********************************************************************
     * Reducer for getting products from search.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static GetProductsFromSearchReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_SEARCH_PRODUCTS:
                let retValue = CategoryService.applyGetProductsFromSearchData(state, action);
                window.srpTotalCount = retValue.searchProductsData.total_count;        
                if(state.searchProductsData&&retValue.searchProductsData.items.length>0){
                    
                    //sort a categories by asc or dsc
                    if(action&&action.component&&action.component.props.selectedSortOption&&action.component.props.selectedSortOption !==""&&action.component.state.pageNumber===0){
                        return retValue;
                    }
                    //for size filter for the first time when page number is zero
                    if(action&&action.component.state&&(action.component.state.pageNumber===0&&action.component.props.location.search !=="")){
                        return retValue;
                    }
                    //if you search something you go some page come back
                    if(action.component&&action.component.state&&action.component.state.pageNumber === 0){
                        return retValue;
                    }
                    return{
                            ...state,
                            searchProductsData:{
                                filters:retValue.searchProductsData.filters,
                                items:[...state.searchProductsData.items,...retValue.searchProductsData.items],
                                total_count:retValue.searchProductsData.total_count
                            }
                        }
                }else{
                    return retValue;
                }        
                // if(state.searchProductsData&&retValue&&retValue.searchProductsData&&retValue.searchProductsData.items.length>0){
                //     if(retValue.searchProductsData.items.length == 0){
                //         return retValue;
                //     }
                //     if((retValue.searchProductsData.items.length > 0)&&state&&state.searchProductsData&&state.searchProductsData.total_count != retValue.searchProductsData.total_count){
                //         if(state&&state.searchProductsData&&state.searchProductsData.items.length>0&&state.searchProductsData.items[0].sku != retValue.searchProductsData.items[0].sku){
                //             return retValue;
                //         }
                //     }
                //     if((retValue.searchProductsData.items.length > 0)&&state&&state.searchProductsData&&state.searchProductsData.total_count == retValue.searchProductsData.total_count){
                //         if(state&&state.searchProductsData&&state.searchProductsData.items[0].sku != retValue.searchProductsData.items[0].sku && action.component.state.pageNumber ==0){
                //             return retValue;
                //         }
                //         if(state&&state.searchProductsData&&state.searchProductsData.items[0].sku == retValue.searchProductsData.items[0].sku && action.component.state.pageNumber ==0){
                //             return retValue;
                //         }
                //         if(state&&state.searchProductsData&&state.searchProductsData.items[0].sku == retValue.searchProductsData.items[0].sku && action.component.props.selectedSortOption !=""){
                //             // return retValue;
                //             return{
                //                 ...state,
                //                 searchProductsData:{
                //                     filters:retValue.searchProductsData.filters,
                //                     items:[...state.searchProductsData.items,...retValue.searchProductsData.items],
                //                     total_count:retValue.searchProductsData.total_count
                //                 }
                //             }
                //         }
                //     }
                //     if((action.component&&action.component.props.location.search !="")&&(retValue.searchProductsData.items.length > 0)&&state&&state.searchProductsData&&state.searchProductsData.total_count == retValue.searchProductsData.total_count){
                //         if(state&&state.searchProductsData&&state.searchProductsData.items[0].sku != retValue.searchProductsData.items[0].sku){
                //             return retValue;
                //         }
                //     }
                //     if(action.component&&action.component.props.location&&action.component.props.location.state&&action.component.props.location.state.searchTerm !=""){
                //         if(state&&state.searchProductsData&&state.searchProductsData.items.length>0&&state.searchProductsData.items[0].sku == retValue.searchProductsData.items[0].sku){
                //             return retValue;
                //         }
                //     }

                //     return{
                //         ...state,
                //         searchProductsData:{
                //             filters:retValue.searchProductsData.filters,
                //             items:[...state.searchProductsData.items,...retValue.searchProductsData.items],
                //             total_count:retValue.searchProductsData.total_count
                //         }
                //     }
                // }else{
                //     return retValue;
                // }
                // return retValue;
            default:
                return state;
        }
    };

    /************************************************************************
    * @param {*} customAttributes - Custom attribute received in API Response.
    * Getting Product Detail URL from custom attributes.
    * Utility function.
    *************************************************************************/
    static getProductDetailURL(customAttributes) {
        for (let index = 0; index < customAttributes.length; index++) {
            if (customAttributes[index].attribute_code === "url_key") {
                return "/" + customAttributes[index].value + ".html";
            }
            else {
                continue;
            }
        }
        return "";
    }
    static setCarrousel(action, state) {
        const newState = { ...state };
        newState[action.carrouseltype] = newState[action.carrouseltype] || {};
        newState[action.carrouseltype][action.id] = newState[action.carrouseltype][action.id] || action.payload;
        newState[action.carrouseltype][action.id].items = CategoryService.reSturctureProductObject(newState[action.carrouseltype][action.id].items);
        return newState;
    }
    static GetCarroselProducts = (state = {}, action) => {
        switch (action.type) {
            case GET_CARROSEL_PRODUCTS:
                return CategoryService.setCarrousel(action, state);
            default:
                return state;
        }
    };
    SetSortOptionActionCreator(sortOption) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_SORT_VIEW });
            dispatch({ type: RECEIVE_SET_SORT_VIEW, success: true, sortOption });
        }
    }
    /**********************************************************************
     * Reducer for setting sort option.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Shaik Sameena <shaik.sameena@embitel.com>
     **********************************************************************/
    static SetSortOptionReducer = (state = '', action) => {
        switch (action.type) {
            case RECEIVE_SET_SORT_VIEW:
                return action.sortOption;
            default:
                return state;
        }
    };
    GetCategoryNameActionCreator(id) {
        return (dispatch) => {
            // dispatch({ type: SET_LOADER_STATUS });
            dispatch({ type: REQUEST_CATEGORY_NAME });
            let requestBody = {};
            requestBody.data = {
            }
            let requestType = 'GET';
            let requestURL = 'categories/' + id;

            requestBody.apiEndPoint = 'thailand';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECIEVE_CATEGORY_NAME, success: true, payload: response.data, id });
                    // dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    // dispatch({ type: SET_LOADER_STATUS });
                }
            );
        }
    }
    static setCategoryName(action, state) {
        const newState = { ...state };
        newState[action.id] = action.payload ? action.payload.name : '';
        return newState;
    }
    static GetCategoryNameReducer = (state = '', action) => {
        switch (action.type) {
            case RECIEVE_CATEGORY_NAME:
                return CategoryService.setCategoryName(action, state);
            default:
                return state;
        }
    };
}
export default CategoryService;