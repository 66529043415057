/**
* PDP Page Component for adding a product to cart.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Counter from '../../../../app/components/common/Counter';
//For getting maximum quantiy allowed for Adding to Cart.
import getConfigValue from '../../../../app/framework/config/configHandler';
import ProductService from '../../../../app/modules/product/ProductService';
import { connect } from 'react-redux';
import NotifyOutOfStock from '../notifyOutofStock/NotifyPopup';
import GetLanguage from '../../../../library/GetLanguage'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const styles = theme => ({
    configOptionsRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    configOptionsSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    configOptionsTitle: {
        textAlign: 'left',
        marginTop: 20,
        marginBottom: 5,
    },
    optionsDiv: {
        padding: '5px',
        margin: '3px',
        minWidth: '22px',
        textAlign: 'center',
        display: 'inline-block',
        float: 'left',
        color: '#949494',
        backgroundColor: '#f0f0f0',
        marginBottom: 18,
        border: '2px solid #D3D3D3',
        borderRadius: '5px',
        cursor: 'pointer',
        '&:hover': {
            outline: '0px solid transparent',
            border: '2px outset #D3D3D3',
            borderRadius: '5px',
        },
    },
    outofStockMessage: {
        textAlign: 'left',
    },
    productOptionSelectionMessage: {
        textAlign: 'left',
    },
    productQtyCounterDiv: {
        margin: '0.5rem 0rem',
    },
    productDetailLink: {
        textDecoration: 'none',
        color: '#1979c3',
    },
    customPadding: {
        padding:'0.5rem 0rem',
        alignItems: 'center',
        flexBasis: '50%',
        justifyContent: 'space-around',
    },
    customMargin: {
        paddingBottom: '0.5rem',
        marginTop:'5px'
    },
    extendedIcon: {
        marginLeft: '5px',
        color:'#fff',
      },
});

/***************************************************************
  * Component class for PDP Page Add to Cart
  * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
***************************************************************/
class ProductAddToCartPDP extends React.Component {
    constructor(props) {
        super(props);
        this.notifyOutOfStock = this.notifyOutOfStock.bind(this);
        this.state = {
            productQuantity: 1,
            isProductOptionsSelected: true,
            productConfigSelected: [],
            configurableItemOptions: [],
            open:false,
            productId:""
        }
    }
    componentDidMount() {
        this.setState({productId:this.props.productDetail[0].id})
    }
    notifyOutOfStock(isOpen) {
        if (localStorage.getItem('shopce-user')) {
            this.setState({
                open: isOpen
            })
        }
        else {
            this.props.history.push('/account');
        }
    }
    onAddToCartHandler = (sku, selectedConfigOptions) => {
        let { config_options } = this.props;
        let numberOfConfigOptions = config_options ? Object.keys(config_options).length : 0;
        let numberOfSelectedConfigOptions = selectedConfigOptions ? selectedConfigOptions.length : 0;
        if (localStorage.getItem('shopce-user')) {
            if (numberOfConfigOptions !== numberOfSelectedConfigOptions) {
                this.setState({ isProductOptionsSelected: false });
            }
            else {
                //Set the flag to true if all product options are selected.
                this.setState({ isProductOptionsSelected: true });
                //Send the dispath to Add the Product to Cart.
                const selectedQuantity = this.props.variant_selected[sku] ?
                this.props.variant_selected[sku].selectedQuantity * (this.props.variant_selected[sku].selectedUnit === 1 ?
                        parseInt(this.props.variant_selected[sku].conversion_factor,10) : 1) : 1;
                const selectedUnit = this.props.variant_selected[sku] ? (this.props.variant_selected[sku].selectedUnit === 1 ? 'Case' : 'Palette') : 'Case'; 
                
                let isPremiumProduct=false;
        
                if(this.props.isPremiumProduct!== '')
                {
                    isPremiumProduct=true;
                }
                this.props.onAddToCart(sku, selectedQuantity, selectedUnit, selectedConfigOptions,isPremiumProduct);
            }
        } else {
            this.props.history.push('/account');
        }

    }

    isProductOptionExists(configOptions, optionId) {
        var checkCondition = false;
        configOptions.forEach(function (item) {
            if (item["optionId"] === optionId) {
                checkCondition = true;
            }
        });
        return checkCondition;
    }

    handleConfigurableOptions = (optionId, id) => {
        //Adding Configurable Options to State.        
        var configOptions = this.state.configurableItemOptions;
        if (this.isProductOptionExists(configOptions, optionId)) {
            //Replace existing Product Option of Same Type
            configOptions.forEach(function (item) {
                if (optionId === item.optionId) {
                    item.optionValue = id
                }
            });
        }
        else {
            configOptions.push({ "optionId": optionId, "optionValue": id, "extensionAttributes": {} });
        }
    }

    handleConfigOptionSelect = (e, optionId, id) => {
        var configOptions = this.state.productConfigSelected;
        this.props.parent.setState({ currentVariant: id });
        const newData = { ...this.props.productDetail };
        newData[0].currentVariant = id;
        this.props.onVariantSelect(newData);

        if (this.isProductOptionExists(configOptions, optionId)) {
            //Replace existing Product Option of Same Type
            configOptions.forEach(function (item) {
                if (optionId === item.optionId) {
                    item.optionValue = id;
                }
            });
        }
        else {
            configOptions.push({ "optionId": optionId, "optionValue": id, "extensionAttributes": {} });
        }

        //Set all Product Option borders to "No-Border"
        // This is dependent on data-* attributes of Product Options
        var a = document.querySelectorAll('[data-id]');
        for (let i = 0; i < a.length; i++) {
            a[i].style.border = "2px solid #D3D3D3";
        }

        //Set the Product Options which are selected and in the state of this Component.
        // This is dependent on data-* attributes of Product Options
        for (let i = 0; i < configOptions.length; i++) {
            let productOption = configOptions[i].optionValue;
            var productOptionElement = document.querySelectorAll("[data-id=" + CSS.escape(productOption) + "]");
            productOptionElement[0].style.border = "2px solid #0011FF";
        }

        //Send the selected option to Parent Component
        //this.props.onSelectConfigurableOptions(optionId, id);

        //Set the state with new config options.
        this.handleConfigurableOptions(optionId, id);
    }

    onQuantiyCounterChange = (quantity) => {
        this.setState({ productQuantity: quantity });
    }


    render() {
        const { classes } = this.props;
        const {
            config_options,
            is_in_stock,
            isAddToCartButtonDisabled,
            sku,
            parent,
        } = this.props;
        const orderParameters = getConfigValue("order");
        return (
            <React.Fragment>
                {this.state.open===true&&<NotifyOutOfStock productId={this.state.productId} open={this.state.open} notifyOutOfStock={this.notifyOutOfStock} />}
                {
                    Object.keys(config_options || []).map((option_key, index) => {
                        return (
                            <div key={index} className={classes.configOptionsSection}>
                                <div className={classes.configOptionsRow}>
                                    {
                                        Object.keys(config_options[option_key] || []).map((option_detail_key, index_detail) => {
                                            return (
                                                Object.keys(config_options[option_key][option_detail_key] || []).map((option_entry_key, option_entry_index) => {
                                                    let configOptionLabel = config_options[option_key][option_detail_key][option_entry_key].toString();
                                                    // Specific Option Id, like the Id for Color "Blue"

                                                    let opton_id = option_entry_key;
                                                    // Option type Id, like the Id for Color type
                                                    let option_attribute_id = option_detail_key;
                                                    if (option_key === "Color") {
                                                        return (
                                                            <div key={option_entry_index}>
                                                                <Tooltip key={option_entry_index} id="tooltip-icon" title={configOptionLabel.toString()} placement="top">
                                                                    <div key={option_entry_index}
                                                                        className={classes.optionsDiv}
                                                                        data-label={configOptionLabel}
                                                                        data-id={opton_id.toString()}
                                                                        data-attribute_id={option_attribute_id.toString()}
                                                                        style={{
                                                                            padding: '5px', margin: '3px', minWidth: '20px',
                                                                            height: '20px', display: 'inline-block', float: 'left',
                                                                            backgroundColor: configOptionLabel, marginBottom: 3,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onMouseUp={(e) => this.handleConfigOptionSelect(e, option_attribute_id, opton_id)}
                                                                        onClick={() => parent.setState({ selectedColor: opton_id })}
                                                                    >
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div key={option_entry_index}>
                                                                <Tooltip id="tooltip-option" title={configOptionLabel} placement="top">
                                                                    <div key={option_entry_index} style={{
                                                                        minWidth: '20px', display: 'inline-block',
                                                                        height: '20px', float: 'left', marginBottom: 3, cursor: 'pointer',
                                                                    }}
                                                                        className={classes.optionsDiv} data-label={configOptionLabel}
                                                                        data-id={opton_id.toString()} data-attribute_id={option_attribute_id.toString()}
                                                                        onMouseUp={(e) => this.handleConfigOptionSelect(e, option_attribute_id, opton_id)}
                                                                        onClick={() => console.log()}
                                                                    >
                                                                        {configOptionLabel}
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    )
                }
                <Grid container direction="row" alignItems="flex-start"  className={classes.customPadding}>
                    <Grid item >
                        <Counter min_number={1} id={'product_' + sku}
                            max_number={orderParameters.maxQuantity}
                            onCounterChange={this.onQuantiyCounterChange} />
                    </Grid>
                    {/* <Grid item xs={5} sm={4} className={classes.customMargin}>
                        {
                            is_in_stock ?
                                <Fab aria-label="Add to cart" variant="extended" style={{ width: '100%' }}
                                    type="submit" disabled={isAddToCartButtonDisabled} size="small"
                                    onClick={() => this.onAddToCartHandler(sku, this.state.productQuantity, this.state.configurableItemOptions)}>
                                    ADD TO CART</Fab>
                                :
                                <Typography color="error" className={classes.outofStockMessage} variant="subtitle1">
                                    Out of Stock
                                        </Typography>
                        }
                    </Grid> */}

                    {/* <div className={classes.customMargin}> */}
                    {
                        is_in_stock ?
                            <Fab aria-label="Add to cart" variant="extended" size="medium" type="submit" disabled={isAddToCartButtonDisabled}
                                onClick={() => this.onAddToCartHandler(sku, this.state.configurableItemOptions)}>
                                {<GetLanguage value="ADD TO"/>}<ShoppingCartOutlinedIcon className={classes.extendedIcon} /></Fab>
                            :
                            <Fab aria-label="Out Of Stock" variant="extended" size="medium" onClick={() => this.notifyOutOfStock(true)}>
                                {<GetLanguage value="NOTIFY ME"/>}</Fab>
                    }
                    {/* </div>  */}
                </Grid>
            </React.Fragment>
        )
    }
}

ProductAddToCartPDP.propTypes = {
    classes: PropTypes.object.isRequired,
    config_options: PropTypes.object,
};


/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    variant_selected: state.variant_selected
});

const mapDispatchToProps = dispatch => ({
    onAddToCart: (sku, quantity, selectedUnit, selectedConfigOptions,isPremiumProduct) => {
        let ps = new ProductService();
        dispatch(ps.PostAddToCartActionCreator(sku, quantity, selectedUnit, selectedConfigOptions,isPremiumProduct));
    },
    onVariantSelect: (productObj) => {
        let ps = new ProductService();
        dispatch(ps.getProductPriceActionCreator(productObj));
    },
    newVariantSelect: () => {

    }
});

const ProductAddToCartPDPWithStyles = withStyles(styles)(ProductAddToCartPDP);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductAddToCartPDPWithStyles));