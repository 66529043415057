/**
* Checkout Layout Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../../../app/components/common/PageTitle';

/***************************************************************
 * Component class for creating layout for checkout flow.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class CheckoutLayout extends React.Component {
    render() {
        const { OrderDetails, OrderSummary, ViewName } = this.props;
        return (
            <React.Fragment>
                {/* <PageTitle title={ViewName} /> */}
                <Grid container alignItems="flex-start" justify="center">
                    <Grid item xs={12} md={7} lg={8}>
                        {OrderDetails}
                    </Grid>
                    <Grid item xs={12} md={5} lg={4}>
                        {OrderSummary}
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    }
}

export default CheckoutLayout;