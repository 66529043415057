/**
* Create Requisition List Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { connect } from 'react-redux';
import dateformat from 'dateformat';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import { NotificationManager } from 'react-notifications';
import { Divider, Typography, TextField, Button } from '@material-ui/core';
import { getItemFromLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';
import RequisitionService from '../../../app/modules/requisition/RequisitionService';
import GetLanguage from '../../../library/GetLanguage';
import { withRouter } from "react-router-dom";
import CreateNewList from '../../../assets/images/Create_New_List.svg'


const styles = theme => ({
    menuText: {
        color: '#000',
        fontSize: '16px',
        fontWeight: '500'
    },
    paper: {
        position:'absolute',
        width:350,
        backgroundColor: '#fff',
        padding: '10px',
        float: 'left',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    backDrop: {
        background: 'rgba(0,0,0,0.1)',
      },
      bannerbotton:{
        padding: '30px',
        background: 'white',
        width: '33%',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        '& a':{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            color: '#f65e5d',
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 'bold',
            position:'relative',
            '&::before':{
                content: "''",
                borderRadius: '50%',
                background: '#F73455',
                position: 'absolute',
                height: '50px',
                width: '50px',
            },

        }
    },
    bannerbottonImg :{
        color:  'white',
        padding: '10px',
        margin: '0px 15px 0px 3px',
        zIndex:'1',
        width:'40px',
        
        
    }
});


/**********************************************************
 * Component class for displaying RequisitionButton.
 * On click user can add product to existing list 
 * or else create a new list
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 **********************************************************/

class CreateNewRequisition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reqListName: '',
            reqDescription: '',
        };
    }
    handleModal = (action,e) => {
        e.preventDefault();
        if(action === 'cancel') {
            this.props.openCreateReqModal(!this.props.openReqModal);
            return false;
        }
        let userInfo = getItemFromLocalStorage('shopce-user');
        let now = new Date();
        let items = [];
        let selectedQuantity = this.props.variant_selected[this.props.sku]? this.props.variant_selected[this.props.sku].selectedQuantity:1;
        if(this.props.sku) {
            let productObject = {};
                productObject.sku = this.props.sku ? this.props.sku: '';
                productObject.qty = selectedQuantity;
                items[0]=productObject;
        }
        else if(this.props.buttonName) {
            if (this.props.selectedItemInReq && this.props.selectedItemInReq.length === 0) {
                NotificationManager.info('Select atleast one Item');
                return false;
            }
            let itemsInselectedEntity = Object.values(this.props.selectedEntity);
            itemsInselectedEntity.map((row) => {
                if(this.props.selectedItemInReq.indexOf(row.item_id) !== -1) {
                    let productObject = {};
                    productObject.sku = row.sku;
                    productObject.qty = row.qty;
                    items.push(productObject);
                }
            });
        }
        if ((action === 'save') && this.state.reqListName !== ''&& this.state.reqDescription !== '') {
            let createReqObject = {
                "requisitionList": {
                    "customer_id": userInfo.customerId,
                    "name": this.state.reqListName,
                    "updated_at": dateformat(now, 'isoDate') + ' ' + dateformat(now, 'isoTime'),
                    "description": this.state.reqDescription,
                    "items":items
                }
            }     
            this.props.createNewRequisite(createReqObject);
            if(this.props.buttonName === <GetLanguage value='Move Selected'/>) {
                if (this.props.getReqListId) {
                    let deleteObj = {
                        "itemIds": this.props.selectedItemInReq.join(),
                        "requisitionListId": this.props.getReqListId
                    }
                    this.props.deleteItemsInReqList(deleteObj);
                }
            }
        }
        else {
            this.props.openCreateReqModal(!this.props.openReqModal);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.getRequisitionList !== this.props.getRequisitionList && this.props.openReqModal) {
                this.props.openCreateReqModal(false);
        }
    }
    //updates Name and Description fields with provided values
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    render() {
        const { classes, plp, openReqModal } = this.props;
        let homePageUrl =  this.props.location.pathname == "/";
        return (
            <React.Fragment>
                {!plp && !homePageUrl &&
                    
                    <Button onClick={(e) => this.handleModal('open',e)}>Create New Requisition List</Button>
                }
                {homePageUrl &&
                    <a href="" className="home-reqlist" onClick={(e) => this.handleModal('open',e)}>
                        <img className={classes.bannerbottonImg} style={{ width: '30px' }} src={CreateNewList}/>
                        Create a New  List</a>
                }
                 
                <Modal  aria-labelledby="requisition-title" onClose={(e) => this.handleModal('close',e)}
                    aria-describedby="requisition-description" open={openReqModal}  BackdropProps= {{
                        classes: {
                            root: classes.backDrop
                        }
                    }}>
                    <form onSubmit={(e) => this.handleModal('save',e)} className={classes.paper}>
                        <Typography>{<GetLanguage value='Create Requisition List'/>}</Typography>
                        <Divider />
                        <TextField id="requisition-name" fullWidth value={this.state.reqListName}
                            label={<GetLanguage value="Requisition List Name"/>} autoFocus InputLabelProps={{ shrink: true, }}
                            margin="normal" required onChange={this.handleChange('reqListName')} />

                        <TextField id="requisition-desc" fullWidth multiline required rows="1"
                            label={<GetLanguage value="Description"/>} margin="normal" value={this.state.reqDescription}
                            InputLabelProps={{ shrink: true, }} onChange={this.handleChange('reqDescription')} />

                        <Button variant="contained" color="secondary" type='submit'>{<GetLanguage value="Save"/>}</Button>
                        <Button onClick={(e) => this.handleModal('cancel',e)}>{<GetLanguage value="Cancel"/>}</Button>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    variant_selected: state.variant_selected,
    createdRequisitionList : state.createdRequisitionList,
    openReqModal: state.openCreateReqModal,
    selectedEntity: state.selectedEntity,
    selectedItemInReq: state.selectedItemInReq,
    getRequisitionList: state.getRequisitionList,
    getReqListId: state.getReqListId,
});

const mapDispatchToProps = dispatch => ({
    openCreateReqModal: (status) => {
        let reqService = new RequisitionService();
        dispatch(reqService.OpenCreateReqModalActionCreator(status));
    },
    createNewRequisite: (createReqObject) => {
        let reqService = new RequisitionService();
        dispatch(reqService.createNewRequisitionListActionCreator(createReqObject));
    },
    deleteItemsInReqList: (obj) => {
        let reqService = new RequisitionService();
        dispatch(reqService.DeleteItemsInReqListActionCreator(obj));
    },
});

const CreateNewRequisitionWithStyles = withStyles(styles)(CreateNewRequisition);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewRequisitionWithStyles));