/**
* Components for displaying desktop Menus.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { handleURLsForMenu } from '../../../framework/common/helpers';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    topMenuStyle: {
        border: '2px solid transparent',
        '&:hover': {            
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#2196f3',
        },
    }
});

/***************************************************************************** 
 * Desktop Top Level menu Component.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class TopLevelDesktopMenu extends React.Component {

    render() {
        const { classes } = this.props;
        const {topmenu} = this.props;              
        return (
            <div
                ref={node => {
                    this.target1 = node;
                }}
            >
                <div className={classes.topMenuStyle}>
                    <MenuItem
                        component={Link}
                        to={handleURLsForMenu(topmenu[2])}>
                        {topmenu[0]}
                    </MenuItem>
                </div>
            </div>
        );
    }
}

TopLevelDesktopMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TopLevelDesktopMenu);
