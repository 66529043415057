/**
* ProductService class with functions for handling product display and
* related functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_POST_ADD_TO_CART,
    RECEIVE_POST_ADD_TO_CART,
    REQUEST_GET_PRODUCT_DETAIL,
    RECEIVE_GET_PRODUCT_DETAIL,
    REQUEST_INIT_ADD_TO_CART_SUCCESS,
    REQUEST_INIT_PRODUCT_DETAIL,
    REQUEST_PRODUCT_PRICE,
    RECEIVE_PRODUCT_PRICE,
    RECEIVE_PRODUCT_IMAGE,
    SET_LOADER_STATUS,
    RECEIVE_NOTIFY_SUBSCRIPTION,
    REQUEST_NOTIFY_SUBSCRIPTION
} from '../../framework/common/ReduxConstants';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import React from 'react';
import { getGuestCartID, getSignedInCustomerCartID } from '../../framework/common/helpers';
import CartService from '../cart/CartService';
import getConfigValue from '../../framework/config/configHandler';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
import CategoryService from '../category/CategoryService';
import { NotificationManager } from 'react-notifications';
import GetLanguage from '../../../library/GetLanguage';
/**********************************************************************************
 * Product Service class which handles Product Detail Page
 * and Add to Cart functionality.
 *********************************************************************************/
class ProductService {

    /****************************************************************************** 
     * Action Creators
     *****************************************************************************/

    /****************************************************************************** 
    * Action Creator for Adding to Cart - POST Request.
    * @param    sku                     - SKU of the product to be added to cart
    * @param    quantity                - Quantity of the product added to cart
    * @param    selectedConfigOptions   -   Configurable Options 
    *                                       in case of configurable prodcuts 
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
   PostAddToCartActionCreator(sku, quantity, unit, selectedConfigOptions,isPremiumProduct) {
    return (dispatch) => {
        dispatch({ type: REQUEST_POST_ADD_TO_CART });
       // dispatch({ type: SET_LOADER_STATUS });
        let guestCartIDFromCookie = getGuestCartID();
        let signedInCustomerCartID = getSignedInCustomerCartID();

        //Get token from localStorage
        let userToken = "";
        let userFromLocalStorage = getItemFromLocalStorage('shopce-user');
        if (userFromLocalStorage) {
            userToken = userFromLocalStorage.token;
        }
        if (signedInCustomerCartID === null && guestCartIDFromCookie !== null) {
           
            //Guest Customer Add to Cart process
            let requestURL = '/guest-carts/' + guestCartIDFromCookie + '/items';
            let requestType = 'POST';
            let requestBody = {};
            requestBody.data = {
                "cartItem": {
                    "quoteId": guestCartIDFromCookie,
                    "sku": sku,
                    "qty": quantity,
                    "productOption": {
                        "extensionAttributes": {
                            "configurableItemOptions": selectedConfigOptions,
                            "unit_of_measurement": unit
                        }
                    }
                }
            };
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_POST_ADD_TO_CART, success: true, response });
                    //Update the Cart after adding item to the Cart.

                    let cartService = new CartService();
                    dispatch(cartService.GetDisplayMiniCartActionCreator(null, 'showloader'));

                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_ADD_TO_CART, success: false, error });
                }
            );
        }
        else {
           
            //Handling of preminum goods product for sales man logic starts here
            let isSalesmanUser = getItemFromLocalStorage('sales-man');
            if(isSalesmanUser && isPremiumProduct)
            {
               
                // dispatch({ type: REQUEST_SALESMAN_PREMIUMGOODS_REDEEM });

                let salesmanToken = getItemFromLocalStorage('salesman-user');
                let requestURL = '/salesman/premiumgoods/redeem';
                let requestType = 'POST';
                let requestBody = {};
                requestBody.header = ("Bearer " + salesmanToken);
                             
                requestBody.data = {"sku": sku, "salesmanname": isSalesmanUser.salesmanName}                   
               
                MagnaAPIHandler(requestURL, requestType, requestBody).then(
                    (response) => {
                       // dispatch({ type: RECEIVE_SALESMAN_PREMIUMGOODS_REDEEM, success: true, response });

                        if(response && response.data && response.data[0] === true)
                        {
                            let requestURL = '/carts/mine/customeraddtocart';
                            let requestType = 'POST';
                            let requestBody = {};
                            requestBody.header = ("Bearer " + userToken);
                            let salesManFromLocalStorage = getItemFromLocalStorage('sales-man');
                          
                            requestBody.data = {
                            "modifiedby":salesManFromLocalStorage ? salesManFromLocalStorage.salesmanName: '',
                            "params":[
                                    {"sku": sku, "qty": quantity, "unit_of_measurement":"case"}                    
                                ]}
                            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                                (response) => {
                                    dispatch({ type: RECEIVE_POST_ADD_TO_CART, success: true, response });
                                    //Update the Cart after adding item to the Cart.
                                    let cartService = new CartService();
                                    dispatch(cartService.GetDisplayMiniCartActionCreator(null, true));
                                    
                                    //dispatch({ type: SET_LOADER_STATUS });                      
                                    NotificationManager.success('Premium goods product has been added to your cart');
                                },
                                (error) => {
                                    dispatch({ type: RECEIVE_POST_ADD_TO_CART, success: false, error });
                                   // dispatch({ type: SET_LOADER_STATUS });  
                                    let genericError = 'Error while adding premium goods product to cart';
                                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;                    
                                    NotificationManager.error(errorMessage);
                                }
                            );

                        }
                        else
                        {
                            NotificationManager.error('Premium goods quota has been execeed');
                            dispatch({ type: SET_LOADER_STATUS });
                        }
                    
                        
                    },
                    (error) => {
                      //  dispatch({ type: RECEIVE_SALESMAN_PREMIUMGOODS_REDEEM, success: false, error });
                        dispatch({ type: SET_LOADER_STATUS });
                
                    }
                );


            }
            else
            {
            let requestURL = '/carts/mine/customeraddtocart';
            let requestType = 'POST';
            let requestBody = {};
            requestBody.header = ("Bearer " + userToken);
            let salesManFromLocalStorage = getItemFromLocalStorage('sales-man');
          
            requestBody.data = {
            "modifiedby":salesManFromLocalStorage ? salesManFromLocalStorage.salesmanName: '',
            "params":[
                    {"sku": sku, "qty": quantity, "unit_of_measurement":"case"}                    
                ]}
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_POST_ADD_TO_CART, success: true, response });
                    //Update the Cart after adding item to the Cart.
                    let cartService = new CartService();
                    dispatch(cartService.GetDisplayMiniCartActionCreator(null, true));
                    
                    //dispatch({ type: SET_LOADER_STATUS });                      
                    NotificationManager.success(<GetLanguage value='Product has been added to your cart'/>);
                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_ADD_TO_CART, success: false, error });
                   // dispatch({ type: SET_LOADER_STATUS });  
                    let genericError = <GetLanguage value='Error while adding product to cart'/>;
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;                    
                    NotificationManager.error(errorMessage);
                }
            );
            }
        }
    };
}

    /********************************************************************************** 
    * Action Creator for getting product detail data for PDP.
    * @param     productId               - Product Id for getting product detail data
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    ***********************************************************************************/
    getProductDetailDataActionCreator(productId) {
        return (dispatch) => {
            //dispatch({ type: SET_LOADER_STATUS });
            //Initialize productDetailData to null            
            dispatch({ type: REQUEST_INIT_PRODUCT_DETAIL });
            dispatch({ type: REQUEST_GET_PRODUCT_DETAIL });
            //Initialize Add to Cart Success to false, to hide the message.
            dispatch({ type: REQUEST_INIT_ADD_TO_CART_SUCCESS });
            let userInfo = getItemFromLocalStorage('shopce-user');
            let companyId = userInfo ? userInfo.companyId? '?companyId='+userInfo.companyId: '' : '';
            let requestURL = '/ecs/products/' + productId+ companyId;
            let requestType = 'GET';
            let requestBody = {};
            requestBody.apiEndPoint = 'thailand';
            /***********************************************************************
             * Setting the API level to "critical", so that when the
             * product is disabled, 404 page is displayed.
             **********************************************************************/
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_PRODUCT_DETAIL, success: true, response });
                    //dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_PRODUCT_DETAIL, success: false, error });
                    //dispatch({ type: SET_LOADER_STATUS });
                }
            );
        };
    }
/********************************************************************************** 
    * Action Creator for subscribing for notification when product is out of stock.
   
    * @author    Swathy Sagar <swathy.sagar@embitel.com>
    ***********************************************************************************/
   subscribeNotifyActionCreator(NotifyObject) {
    return (dispatch) => {
        //Initialize productDetailData to null            
        dispatch({ type: REQUEST_NOTIFY_SUBSCRIPTION });
       
        let requestURL = '/ecs/outofstockalert';
        let requestType = 'POST';
        let requestBody = {};
        requestBody.data = {
            productId:NotifyObject.productId,
            customerId:NotifyObject.customerId
        }
        requestBody.apiEndPoint = 'thailand';

        MagnaAPIHandler(requestURL, requestType, requestBody).then(
            (response) => {
                dispatch({ type: RECEIVE_NOTIFY_SUBSCRIPTION, success: true, response });
                NotificationManager.success('Your subscription for notification is successfull');
            },
            (error) => {
                dispatch({ type: RECEIVE_NOTIFY_SUBSCRIPTION, success: false, error });
                let genericError = 'Error, Please try again after some time';
                let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                NotificationManager.error(errorMessage);

                //dispatch({ type: SET_LOADER_STATUS });
            }
        );
    };
}
    /*****************************************************************************
     * Redux Reducers
     ****************************************************************************/

    /***************************************************************************** 
    * Helper method for GetProductDetailDataReducer
    * @param {*} state     - No modifications
    * @param {*} action    - Required data is extracted
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyGetProductData = (state, action) => {
        let productDetailData = [];
        if (action.response && (action.response.status === 200)&&action.response.data[0]!==null&&action.response.data[0]!==undefined) {
            productDetailData = action.response.data;
            if (productDetailData[0].configurable_options) {
                var defaultVariant = Object.keys(productDetailData[0].configurable_options.Variant)[0];
                defaultVariant = Object.keys(productDetailData[0].configurable_options.Variant[defaultVariant])[0];
                productDetailData[0].currentVariant = defaultVariant;
            } else {
                productDetailData[0].currentVariant = 0;
            }

            productDetailData = CategoryService.reSturctureProductObject(productDetailData);
        }
        return {
            "productDetailData": productDetailData
        }
    }
 /***************************************************************************** 
    * Helper method for post_notify_out_of_stock_reducer 
    * @param {*} state     - No modifications
    * @param {*} action    - Required data is extracted
    * @author    Swathy Sagar <swathy.sagar@embitel.com>
    *****************************************************************************/
   static applyNotificationOutofStock = (state, action) => {
    if (action.success) {
        let returnObjectSuccess = {
            subscribeNotificationStatus: true,
        };
        return Object.assign({}, returnObjectSuccess, { subscribeNotificationStatus: true, isSubscribeButtonDisabled: false });
    }
    else {
        let genericError = 'Error, Please try again after some time';
        let returnObjectError = {
            resetPasswordStatus: false,
            errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
        };
        return Object.assign({}, returnObjectError, { subscribeNotificationStatus: false, isSubscribeButtonDisabled: false });
    }
}
    /**********************************************************************
     * Reducer for getting the product detail data into Redux Store.
     * @param {*} state     - No modifications
     * @param {*} action    - No modifications
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static GetProductDetailDataReducer = (state = null, action) => {
        switch (action.type) {
            case RECEIVE_GET_PRODUCT_DETAIL:
                let retValue = ProductService.applyGetProductData(state, action);
                return retValue;
            //Initialise product detail to null before fetchning fresh product data.            
            case REQUEST_INIT_PRODUCT_DETAIL:
                return { ...state, "productDetailData": null };
            default:
                return state;
        }
    };

    /***************************************************************************** 
    * Helper method for post_add_to_cart_reducer
    * @param {*} state     - No modifications
    * @param {*} action    - No modifications
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyAddToCart = (state, action) =>
        [...state, action];

    /***************************************************************************** 
    * Reducer for Add to Cart
    * @param {*} state     - No modifications
    * @param {*} action    - No modifications
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static post_add_to_cart_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_ADD_TO_CART:
                let retValue = ProductService.applyAddToCart(state, action);
                return retValue;
            default:
                return state;
        }
    };
/***************************************************************************** 
    * Reducer for Notify when stock is empty
    * @param {*} state     - No modifications
    * @param {*} action    - No modifications
    * @author    Swathy Sagar <swathy.sagar@embitel.com>
    *****************************************************************************/
   static post_notify_out_of_stock_reducer = (state = [], action) => {
    switch (action.type) {
        case REQUEST_NOTIFY_SUBSCRIPTION:
            return { ...state, notifyOutofStockStatus: null, isSubscribeButtonDisabled: true };
        case RECEIVE_NOTIFY_SUBSCRIPTION:
            let retValue = ProductService.applyNotificationOutofStock(state, action);
            return retValue;
        default:
            return state;
    }
};
    /***************************************************************************** 
    * Initial state for AddToCartTotalReducer.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static getInitialStateAddToCartTotalReducer() {
        const INITIAL_STATE = {
            totalProductCount: 0,
            displayAddToCartSuccess: false,
        };
        return INITIAL_STATE;
    }

    /***************************************************************************** 
    * Helper method for addToCartTotalReducer.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static applyAddToCartTotalQuantity = (state, action, isSuccess) => {

        if (isSuccess) {
            let returnObjectSuccess = {
                totalProductCount: action.response.data.qty,
                displayAddToCartSuccess: true,
            };

            return Object.assign({}, returnObjectSuccess, { isAddToCartButtonDisabled: false });
        }
        else {
            let returnObjectError = {
                totalProductCount: state.totalProductCount,
                displayAddToCartSuccess: false,
            };

            return Object.assign({}, returnObjectError, { isAddToCartButtonDisabled: false });
        }
    }

    /***************************************************************************** 
    * Reducer - Add to Cart Total
    * @param {*} state     - No modifications
    * @param {*} action    - Based on action, total product quantity in cart is 
    *                        is calculated.
    * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
    *****************************************************************************/
    static addToCartTotalReducer(state = ProductService.getInitialStateAddToCartTotalReducer(), action) {
        switch (action.type) {
            case RECEIVE_POST_ADD_TO_CART: {
                if (action.success === true) {
                    let retValueCount = ProductService.applyAddToCartTotalQuantity(state, action, true);
                    return retValueCount;
                }
                else {
                    let retValueCount = ProductService.applyAddToCartTotalQuantity(state, action, false);
                    return retValueCount;
                }
            }
            // To initialize the displayAddToCartSuccess to false, 
            // on next "Add To Cart" button click.
            case REQUEST_POST_ADD_TO_CART:
                return { ...state, displayAddToCartSuccess: false, isAddToCartButtonDisabled: false };
            // To initialise displayAddToCartSuccess to false, so that the "Product added to Cart"
            // message is hidden.
            case REQUEST_INIT_ADD_TO_CART_SUCCESS:
                return { ...state, displayAddToCartSuccess: false, isAddToCartButtonDisabled: false };
            default: return state;
        }
    }
    /********************************************************************************** 
    * Action Creator for getting price of product based on varaint.
    * @param     productObject-   Product detail data
    * @param     selectedVaraint- Product detail data
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    ***********************************************************************************/
    getProductPriceActionCreator(productObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_PRODUCT_PRICE });
            dispatch({ type: RECEIVE_PRODUCT_PRICE, success: true, productObject });
        };
    }
    /***************************************************************************** 
    * Helper method for Product Price Reducer.
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static applyProductPrice = (state, action) => {
        if (typeof action.productObject !== 'undefined') {
            let simpleProducts = action.productObject[0].simple_products;
            //navigate through simpleProducts
            simpleProducts.map((productItem, index) => {
                let customOptions = productItem.custom_options ? productItem.custom_options : '';
                //validate selected variant with variant in simple product
                if (typeof customOptions !== 'undefined' && typeof customOptions.variant !== 'undefined') {
                    let variantKey = Object.keys(customOptions.variant);
                    if (action.selectedVaraint !== '' && variantKey[0] === action.selectedVaraint) {
                        return productItem.price;
                    }
                }
                return simpleProducts[0].price;
            });
        }
        return null;
    }
    /***************************************************************************** 
    * Reducer for Product Price
    * @param {*} state     - No modifications
    * @param {*} action    - No modifications
    * @author    Sameena Shaik <shaik.sameena@embitel.com>
    *****************************************************************************/
    static product_price_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_PRODUCT_PRICE:
                let retValue = ProductService.applyProductPrice(state, action);
                return retValue;
            default:
                return state;
        }
    };

    // getProductImageActionCreator() {
    //     return (dispatch) => {
    //         dispatch({ type: REQUEST_PRODUCT_PRICE });
    //         dispatch({ type: RECEIVE_PRODUCT_IMAGE, success: true,  });
    //     };
    // }

    static current_key_image = (state = 0, action) => {
        switch (action.type) {
            case RECEIVE_PRODUCT_IMAGE:
                return action.payload;
            default:
                return state;
        }
    };

}
export default ProductService;