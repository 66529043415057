/**
* Components for displaying mobile Menus.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { handleURLsForMenu } from '../../../../app/framework/common/helpers';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({    
    secondLevelMenu: {
        paddingLeft: 0,
        paddingBottom: 5,
        paddingRight: 5,
    },
    thirdLevelMenu: {
        paddingLeft: 40,
    },
    menuItem: {
        color: '#69C1FC',
        textDecoration:'none',
        fontSize:'16px',
        fontWeight: 'bold'
    }
});

/***************************************************************************** 
 * Top Level Mobile Menu component
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const TopLevelMobileSubMenu = (props) => {
    const { classes } = props;
    const { submenuItem } = props;
    return (
        <div key={props.index} className={classes.secondLevelMenu}>
            <Typography>
                <Link className={classes.menuItem} to={handleURLsForMenu(submenuItem[2])}>
                    {submenuItem[0]}
                </Link>
            </Typography>
        </div>
    );
};

/****************************************************************************** 
 * Top Level Mobile Sub Menu component
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const SecondLevelMobileSubMenu = (props) => {
    const { classes } = props;
    const { submenuItem } = props;
    return (
        <div key={props.index} className={classes.thirdLevelMenu}>
            <Typography>
                <Link className={classes.menuItem}
                    to={handleURLsForMenu(submenuItem["url"])}>
                    {submenuItem["name"]}
                </Link>

            </Typography>
        </div>
    );
};

const TopLevelMobileSubMenuWithStyles = withStyles(styles)(TopLevelMobileSubMenu);
const SecondLevelMobileSubMenuWithStyles = withStyles(styles)(SecondLevelMobileSubMenu);

export { TopLevelMobileSubMenuWithStyles, SecondLevelMobileSubMenuWithStyles };