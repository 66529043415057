/**
* SearchbarDesktop Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchRounded from '@material-ui/icons/SearchRounded';
import { getLanguageValue } from '../../../../../app/framework/config/configHandler';
import MagnaAPIHandler from '../../../../../app/framework/api/MagnaAPIHandler';
import { mediaURLBasePath } from '../../../../../app/framework/common/helpers';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
require("./styles.css");

const styles = theme => ({

  backgroundDesktop: {
    display: 'flex',
    width: '460px',
    height: '40px',
    borderRadius: '1px',
    border: '1px solid #1d2d5f',
    backgroundColor: '#f8f8fa',
  },
  backgroundMobile: {
    display: 'flex',
    backgroundColor: '#DFF3FA',
    flexGrow: '1',
    height: '32px',
    borderColor: '#94BEFA',
    borderRadius: '16px',
    border: '2px solid #94BEFA',
  },
  iconPaddingLeft: {
    paddingLeft: '0.2rem'
  },
  desktopBtn: {
    height: '20px',
    background: '#1d2d5f',
    padding: '10px',
    width: '25px',
    cursor: 'pointer'
  }
});

/****************************************************************
 * This component returns input field which includes search icon
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/

class SearchbarDesktop extends React.Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };
  constructor(props) {
    super(props);
    this.state = {
      searchInputValue: '',
      termToBeSearched: false,
      // The active selection's index
      // The suggestions that match the user's input
      filterItems: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      msgSuggestions: true,
    };

  }

  //get value from search input
  handleInputChange(searchInputValue) {
    if (!searchInputValue.includes('  ') && searchInputValue.length > 2) {
      this.props.loader();
      let requestURL = `/ecs/searchsuggestions?fields=items[sku,id,name,custom_attributes[thumbnail,url_key,special_price],is_in_stock,name,price,regular_price,status,extension_attributes[qty]],total_count&searchCriteria[requestName]=quick_search_container&searchCriteria[filterGroups][0][filters][0][field]=search_term&searchCriteria[filterGroups][0][filters][0][value]=${searchInputValue}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][1][field]=visibility&searchCriteria[filterGroups][0][filters][1][value]=4&searchCriteria[filterGroups][0][filters][1][conditionType]=eq&searchCriteria[pageSize]=10&searchCriteria[currentPage]=0`;
      let requestType = 'GET';
      let requestBody = {};
      MagnaAPIHandler(requestURL, requestType, requestBody).then(response => {
        this.props.loader();
        if (response && response.data && response.data[0] && response.data[0].items && response.data[0].items.length) {
          let newItems = [];
          const items = response.data[0].items;
          items.map((item, k) => {
            newItems.push(item);
            newItems[k].media_gallery_entries = [];
            if (typeof item.custom_attributes !== undefined && item.custom_attributes && Object.keys(item.custom_attributes).length) {

              Object.values(item.custom_attributes).map(attr => {
                if (attr.attribute_code === 'image' || attr.attribute_code === 'small_image') {
                  newItems[k].image = attr.value;
                } else if (attr.attribute_code === 'url_key') {
                  newItems[k].url_key = '/' + attr.value + '.html';
                } else {
                  newItems[k][attr.attribute_code] = attr.value;
                }
              })
            }
          })
          this.setState({ filterItems: response.data[0].items, showSuggestions: true });
        }
        else this.setState({ msgSuggestions: false, showSuggestions: false })
      }, (error) => {
        this.props.loader();
        this.setState({ msgSuggestions: false, showSuggestions: false });
      }
      );
    }
    else {
      this.setState({ filterItems: [], showSuggestions: false });
    }
    this.setState({ searchInputValue });
  }
  // On Enter Key pressed, change the state variable 'termToBeSearched'.
  handleKeyPress = (event) => {
    var isSpaceOnly = false;
    if (!event.target.value.replace(/\s/g, '').length) {
      isSpaceOnly = true;
    }
    localStorage.removeItem('searchTerm');
    if (event.key === 'Enter') {
      //If the search term entered by user is empty, return.
      if (event.target.value === "" || event.target.value.length <= 1 || isSpaceOnly) return;
      else {
        this.setState({ showSuggestions: false });
        localStorage.setItem('searchTerm', this.state.searchInputValue);
        this.props.history.push({
          pathname: '/search',
          state: { searchTerm: this.state.searchInputValue }
        });
      }
    }
  }
  handleIconClick = () => {
    var isSpaceOnly = false;
    if (!this.state.searchInputValue.replace(/\s/g, '').length) {
      isSpaceOnly = true;
    }
    localStorage.removeItem('searchTerm');
    if (this.state.searchInputValue === "" || this.state.searchInputValue.length <= 1 || isSpaceOnly)
      return;
    else {
      localStorage.setItem('searchTerm', this.state.searchInputValue);
      this.props.history.push({
        pathname: '/search',
        state: { searchTerm: this.state.searchInputValue }
      });
      this.setState({ termToBeSearched: true, showSuggestions: false });
    }
  }
  render() {
    // if (this.state.termToBeSearched) {
    //   return (
    //     <Redirect push to={{ pathname: '/search', state: { searchTerm: this.state.searchInputValue } }} />
    //   );
    // }
    const userInfo = localStorage.getItem('shopce-user');
    const { classes } = this.props;
    const language = getLanguageValue("language");
    const {
      onChange,
      state: {
        showSuggestions,
        searchInputValue,
        filterItems,
        msgSuggestions
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && filterItems.length) {

      suggestionsListComponent = (

        <Grid text className="searchsuggsection">
          {/* <div className="popularRecent" >
        <ul className="popular" >
          <li>
            Popular Search
          </li>
          <li>Knnor</li>
        </ul>
        </div> */}
          <Scrollbars className="search-scroll" autoHeight autoHeightMax={250}>
            <ul className="suggestions">
              {filterItems.map((product, index) => (
                <li key={index}>

                  <Link to={product.url_key}>
                    {userInfo ?
                      <div>
                        <img style={{ width: '75px', height: '80px' }} src={mediaURLBasePath + product.thumbnail} /> {product.name} - ฿ {product.price ? product.price.toString().split(".")[0] : ''}
                      </div>
                      :
                      <div>
                        <img style={{ width: '75px', height: '80px' }} src={mediaURLBasePath + product.thumbnail} /> {product.name}
                      </div>
                    }
                  </Link>
                </li>
              ))}
            </ul>
          </Scrollbars>
        </Grid>

      );
    }
    else if (searchInputValue && !msgSuggestions && !showSuggestions) {
      suggestionsListComponent = (
        <Grid text className="searchsuggsection">
          {/* <div className="popularRecent" >
        <ul className="popular" >
          <li>
            Popular Search
          </li>
          <li>Knnor</li>
        </ul>
        </div> */}
          <ul className="suggestions" >
            <li className="noresult">
              No search suggestions found. Please try with a different keyword.
            </li>
          </ul>
        </Grid>
      );
    }

    return (
      <div style={{ position: 'relative' }} >
        <Input style={{ paddingLeft: '15px' }}
          //id="adornment-search1"
          placeholder={language.english["Search_Store"]} type="text"
          value={this.state.searchInputValue} onChange={(e) => this.handleInputChange(e.target.value)}
          className={(this.props.isSearchMobile) ? classes.backgroundMobile : classes.backgroundDesktop}
          disableUnderline={true} onKeyPress={this.handleKeyPress}
          endAdornment={
            <InputAdornment position="end" className={classes.desktopBtn}  >
              {/* <Link to={this.handleIconClick()}> */}
              <SearchRounded color="action" onClick={() => this.handleIconClick()} />
              {/* </Link> */}
            </InputAdornment>
          }
        />
        {suggestionsListComponent}
      </div>
    );
  }
}

SearchbarDesktop.propTypes = {
  searchInputValue: PropTypes.string,
  searchInputHandler: PropTypes.func
};
const mapDispatchToProps = dispatch => ({
  loader: () => {
    dispatch({ type: 'SET_LOADER_STATUS' });
  }
}
);
const mapStateToProps = state => ({
  loader_status: state.get_loader_status
});
const SearchbarDesktopWithStyles = withStyles(styles)(SearchbarDesktop);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchbarDesktopWithStyles));
