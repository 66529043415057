/**
* Component for displaying Desktop Menu.
* Uses NavigationDesktopUnit child component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NavigationDesktopUnit from './NavigationDesktopUnit';
import CategoryService from '../../../../app/modules/category/CategoryService';
//import ErrorBoundary from 'react-error-boundary';
import { withErrorBoundary } from 'react-error-boundary';
import { removeItemFromLocalStorage, getItemFromLocalStorage } from '../../../../app/framework/storage/local/localStorageHandler';
import squareImg from '../../../../../src/assets/images/Square.svg';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    },
    popperClose: {
        pointerEvents: 'none',
    },
    contentLoader: {
        height: '48px',
        backgroundColor: '#F0F0F0',
    },
    navbackground: {
        background: '#e6f4ff',
        display: 'block',
        float: 'left',
        width: '100%',
        padding: '0px',
    },
    gridContainer: {
        width: '1280px!important',
        margin: '0 auto',
        position: 'relative',
    },
    menuCategory: {
        padding: '10px 140px 10px 10px',
        background: '#fff',
        display: 'inline-block',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '600',
        color: '#f65e5d',
        position: 'relative',
    },
    plpMenu: {
        position: 'absolute',
        left: '0px',
        top: '35px',
    },
    menuSection: {
        position: 'relative',
    },
    squareImgClass: {
        position: 'absolute',
        width: '12px',
        right: '10px',
        top: '13px',
    }

});

/***************************************************************
 * Component class for displaying Category Menu.
 * Receives data from redux state in the form of props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class NavigationDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    // Dispatch action to Redux to fetch category data
    componentWillMount() {
        let homepage = document.querySelector('.home-page');
       
    }
    componentDidMount() {
        let homepage = document.querySelector('.home-page');
        if (homepage) {
            this.setState({ visible: true });
        }
        /**
         * When the app is loaded for first time, the category
         * navigation menu data is fetched. Subsequent page transitions
         * will not fetch the category data again and the redux stored
         * menu data is used. 
         */
        if (typeof (this.props.categoryData) === 'undefined') {
            // this.props.getCategoryData();
        }
    }

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    //Get the submenu at Level 3 for the given menu.
    handleSubMenu = (cat) => {
        if (cat["children_data"] !== []) {
            let subCat = [];
            cat["children_data"] && cat["children_data"].map((sub_cat_item, index) => {
                return subCat.push([sub_cat_item.name, sub_cat_item.id, sub_cat_item.url, sub_cat_item.children_data]);
            })
            return subCat;
        }
        else {
            return [];
        }
    }

    render() {
        console.log("hskhka", this.state.visible);
        const { classes } = this.props;
        const { categoryData } = this.props;
        let isSalesManUser = getItemFromLocalStorage('sales-man');
        let homepage = document.querySelector('.home-page');
        if (!categoryData) {
            return (
                <div className={classes.contentLoader}></div>
            );
        }

        return (
            <div className={classes.menuSection}>
                    <div className={classes.navbackground + "home-bg-rem"}>
                        <div className={classes.gridContainer}>
                            <div className={homepage ?classes.menuCategory + " " +" navstip" : classes.menuCategory} onClick={this.toggleMenu}>categories menu <img src={squareImg} className={classes.squareImgClass} /></div>
                            {this.state.visible &&
                                <div className={homepage ? classes.root + " " + "nav_new " : classes.root + " " + "nav_new " + " " + classes.plpMenu} >
                                    {
                                        categoryData && categoryData.map((cat, index) => {
                                            if (cat.is_active && cat.include_in_menu)
                                                return (
                                                    <div key={index}>
                                                        {cat.name !== 'Premium Goods' ?
                                                            <NavigationDesktopUnit key={index} topmenu={[cat.name, cat.id, cat.url, cat.children_data]} submenu={this.handleSubMenu(cat)}></NavigationDesktopUnit>
                                                            :
                                                            cat.name === 'Premium Goods' && isSalesManUser !== null && isSalesManUser !== undefined &&
                                                            <NavigationDesktopUnit key={index} topmenu={[cat.name, cat.id, cat.url, cat.children_data]} submenu={this.handleSubMenu(cat)}></NavigationDesktopUnit>
                                                        }
                                                    </div>
                                                );
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>

            </div>
        );
    }
}


NavigationDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    categoryData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ])
};

const NavigationErrorFallbackComponent = ({ componentStack, error }) => {
    return null;
}

const NavigationDesktopWithErrorBoundary = withErrorBoundary(
    NavigationDesktop,
    NavigationErrorFallbackComponent
);



/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    categoryData: state.categoryDataState.categoryData
});

const mapDispatchToProps = dispatch => ({
    getCategoryData: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getCategoryDataForMenuActionCreator());
    }
});

const NavigationDesktopWithConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationDesktopWithErrorBoundary);

export default withStyles(styles)(NavigationDesktopWithConnect);