/**
* Address List Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Amaresh M <amaresh.m@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router';
import magnaCookies from '../../../app/framework/cookie/magnaCookies';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../../../app/components/common/PageTitle';
// import AddButton from '../../components/address/AddButton';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import CheckoutService from '../../../app/modules/checkout/CheckoutService';
import AccountService from '../../../app/modules/account/AccountService';
import { withRouter } from "react-router";
import UserMessage from '../../../app/components/messages/UserMessages';

const styles = theme => ({
    cardContainer:
    {
        // border: '1px solid #C1C7D0',
        padding:0,
        background:'none',
    },
    cardContainerAddSection:
    {
        textAlign: 'center',
        padding: '3rem',
        border: '1px solid #C1C7D0',
    },
    btnStyle:
    {
        color: '#20B4E7',
        cursor: 'pointer',
    },
    subTitleStyle:
    {
        fontWeight: 500
    },
    headerStyle:{
        background:'none !important',
    },
    accountHeading:{
        textTransform:'uppercase',
        margin:'10px 0 25px 0',
        padding:'10px 0',
        borderBottom:'1px solid #ccc',
    },
    headerFont: {
        fontSize: '14px',
        fontWeight: '600',
        color:'#1d2d5f',
    },
});

/***************************************************************
 * Component class for displaying Customer Addresses.
 * @author   Amaresh M <amaresh.m@embitel.com>
 ***************************************************************/
class MyAddresses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddressAdded: false,
        }
    }

    componentDidMount() {
        /***************************************************************************** 
        * Check if the user is authenticated. 
        * If yes, display myaddress page.
        * If not, redirect to Sign-In page and once
        * signed-in, redirect back to myaddress page.
        *****************************************************************************/
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        }

        /***************************************************************************** 
         * check if address is saved or edited 
         * if yes and status is success / true,  show address status message
         * if not address status message is invisible 
         *****************************************************************************/
        if (this.props.history.action === 'PUSH' && this.props.location.state && this.props.location.state.addressSavedStatus) {
            if (this.props.location.state.addressSavedStatus !== this.state.showAddressAdded) {
                this.setState({ showAddressAdded: true });
            }
        }

        this.props.getShippingAddress();

    }

    onDeleteClick = (addressId) => {
        this.props.onDeleteItemFromMyAddresses(addressId);
    }

    onSetAsDefaultAddress = (address_id, customer_id) => {
        this.props.onSetDefaultAddress(address_id, customer_id);
        this.setState({ showAddressAdded: false });
    }

    getDefaultShippingAddress(shippingAddressList, defaultShippingID) {
        return shippingAddressList.filter(item => {
            return item.id === defaultShippingID
        })
    };

    render() {
        const { addressSaved } = this.props.location.state ? this.props.location.state : false;
        const { classes } = this.props;
        let signInStatus = magnaCookies.getCookie('ECS_AC');
        const { setAsDefaultAddressStatusProp, setAsDefaultAddressMessageProp,
            deleteAddressStatusProp, deleteAddressMessageProp } = this.props;
        //const {shippingaddress} = this.props;
        let shippingaddress;
        if (this.props.getAddress && Object.keys(this.props.getAddress).length) {
             shippingaddress = this.props.getAddress;
        }
        var addressItem = {};
        var addressAddedMessage;
        if (shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) && (shippingaddress.length !== 0) && (shippingaddress.addresses.length !== 0)) {
            addressItem = this.getDefaultShippingAddress(shippingaddress.addresses, parseInt(shippingaddress.default_shipping, 10));
            addressItem = addressItem[0];
        }
        if (typeof signInStatus === 'undefined' || signInStatus === '') {
            return (
                <Redirect push to="/account" />
            );
        }

        var setAsDefaultAddressMessage;
        //If the address is set as default successfully 
        if (setAsDefaultAddressStatusProp === true) {
            const object = { messageType: 'sucess', message: "Selected address is set as default" }
            setAsDefaultAddressMessage = <UserMessage {...object} />
        }
        //If address is NOT set as default, display error message
        if (setAsDefaultAddressMessageProp) {
            const object = { messageType: 'error', message: setAsDefaultAddressMessageProp }
            setAsDefaultAddressMessage = <UserMessage {...object} />
        }
        if (addressSaved) {
            const object = { messageType: 'sucess', message: "Address is saved successfully" }
            addressAddedMessage = <UserMessage {...object} />
        }

        //if address is added successfully
        if (this.state.showAddressAdded === true) {
            const object = { messageType: 'sucess', message: "Address is saved successfully" }
            addressAddedMessage = <UserMessage {...object} />
        }

        var deleteAddressMessage;
        // If the address is deleted successfully
        if (deleteAddressStatusProp === true) {
            const object = { messageType: 'sucess', message: "Selected address is deleted" }
            deleteAddressMessage = <UserMessage {...object} />
        }
        //If address is NOT deleted, display error message
        if (deleteAddressMessageProp) {
            const object = { messageType: 'error', message: deleteAddressMessageProp }
            deleteAddressMessage = <UserMessage {...object} />
        }

        return (
            <React.Fragment>
                {setAsDefaultAddressMessage}
                {addressAddedMessage}
                {deleteAddressMessage}
                <Grid item xs={12} classes={classes.headerStyle}>
                <Typography variant="h6" align="left" className={classes.pageHeading}>
                Address Book
                    </Typography>
                </Grid>
                <Grid item xs={6}>                
                <Typography gutterBottom className={classes.accountHeading}>Address Book</Typography>
                <Typography gutterBottom className={classes.headerFont}>Default Billing Address</Typography>               
                </Grid>
                <Grid container spacing={40}>
                    {/* Not Required as of now
                    <Grid item lg={4} md={4} sm={12} xs={12} >
                        <Card className={classes.cardContainerAddSection}>
                            <CardContent >
                                <AddButton color="action" fontSize="inherit" redirectTo="/address" />
                                <Typography variant="subtitle1" className={classes.subTitleStyle}>Add Address </Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}

                    {(shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) &&
                        (shippingaddress.length !== 0) && (shippingaddress.addresses.length !== 0)) ? (
                            <AddressItem onDeleteLinkClick={(addressId) => this.onDeleteClick(addressId)} addressItem={addressItem} classes={classes} isDefaultAddress={true} />) : ""
                    }

                    {shippingaddress && (typeof shippingaddress === 'object' && shippingaddress !== null) && (shippingaddress.length !== 0) && (shippingaddress.addresses.length !== 0) && shippingaddress !== null && shippingaddress.length !== 0 && shippingaddress.addresses.map((addressItem, index) => {
                        if (parseInt(shippingaddress.default_shipping, 10) !== addressItem.id) {
                            return (
                                <React.Fragment key={index}>
                                    <AddressItem onDeleteLinkClick={(addressId) => this.onDeleteClick(addressId)} addressItem={addressItem} classes={classes} onSetAsDefaultInParent={(address_id, customer_id) => this.onSetAsDefaultAddress(address_id, customer_id)} />
                                    <AddressListItem onDeleteLinkClick={(addressId) => this.onDeleteClick(addressId)} addressItem={addressItem} classes={classes} onSetAsDefaultInParent={(address_id, customer_id) => this.onSetAsDefaultAddress(address_id, customer_id)} />
                                </React.Fragment>
                            );
                        }
                        else { return null }
                    })}
                </Grid>
            </React.Fragment>
        );
    }

    componentWillMount() {
        this.setState({ showAddressAdded: null });
    }

    componentWillUnmount() {
        /****************************************************************************** 
         * Reset the setAsDefaultAddressStatus to null so that the success message 
         * is displayed properly. 
         *****************************************************************************/
        this.props.resetPostSetAsDefaultAddress();
        /****************************************************************************** 
         * Reset the deleteMyAddressItemSuccess to null so that the success message 
         * is displayed properly. 
         *****************************************************************************/
        this.props.resetDeleteAddress();
    }
}

class AddressItem extends React.Component {

    state = {
        editClicked: false
    }

    onEditLinkClick = (e) => {
        e.preventDefault();
        this.setState({ editClicked: true });
    }

    onSetDefaultLinkClick = (e, address_id, customer_id) => {
        e.preventDefault();
        //Call the prop function passed from parent
        this.props.onSetAsDefaultInParent(address_id, customer_id);
    }

    render() {
        const { classes, addressItem, isDefaultAddress } = this.props;
        // const { id } = this.props.addressItem;
        // const addressId = id;

        if (this.state.editClicked === true) {
            return <Redirect to={{ pathname: "/address", state: { redirectTo: "/myaddresses", editaddressItem: addressItem } }} />;
        }
        return (
            <React.Fragment>
                {!isDefaultAddress ? '' :
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card className={classes.cardContainer}>
                        <CardContent>
                        
                            <Grid container>
                                <Grid item xs>
                                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={16}>
                                        <Grid item xs>
                                            <Typography variant="subtitle1" className={classes.subTitleStyle}>{addressItem.firstname + ' ' + addressItem.lastname}</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="subtitle1" noWrap={true} >{addressItem.street}</Typography>
                                            <Typography variant="subtitle1">{addressItem.city}</Typography>
                                            <Typography variant="subtitle1">{addressItem.region.region + '-' + addressItem.postcode}</Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="subtitle1" gutterBottom>Ph: {addressItem.telephone} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
     
                            <Grid item xs>
                                <Grid container>
                                    {isDefaultAddress ? '' :
                                        <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onSetDefaultLinkClick(event, addressItem.id, addressItem.customer_id)} >Set Default
                                    </Typography>
                                    }
                                    {/* {(isDefaultAddress) ? (
                                        <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onEditLinkClick(event)} >Edit</Typography>) :

                                        <React.Fragment>
                                            <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onEditLinkClick(event)} >Edit</Typography>
                                            <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.props.onDeleteLinkClick(addressId)}>Delete
                                            </Typography>
                                            <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onSetDefaultLinkClick(event, addressItem.id, addressItem.customer_id)} >Set Default
                                            </Typography>
                                        </React.Fragment>
                                    } */}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
    }
    
            </React.Fragment>            
        );
        
    }
}

class AddressListItem extends React.Component {

    state = {
        editClicked: false
    }

    onEditLinkClick = (e) => {
        e.preventDefault();
        this.setState({ editClicked: true });
    }

    onSetDefaultLinkClick = (e, address_id, customer_id) => {
        e.preventDefault();
        //Call the prop function passed from parent
        this.props.onSetAsDefaultInParent(address_id, customer_id);
    }

    render() {
        const { classes, addressItem, isDefaultAddress } = this.props;
        // const { id } = this.props.addressItem;
        // const addressId = id;

        if (this.state.editClicked === true) {
            return <Redirect to={{ pathname: "/address", state: { redirectTo: "/myaddresses", editaddressItem: addressItem } }} />;
        }
        return (
            <React.Fragment>
                <Grid item xs={12}>                
                <Typography className={classes.accountHeading}>My Address</Typography>
                 </Grid>
                 {isDefaultAddress ? '' :
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card className={classes.cardContainer}>
                        <CardContent>                        
                            <Grid container>
                                <Grid item xs>
                                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={16}>
                                        <Grid item xs>
                                            <Typography variant="subtitle1" className={classes.subTitleStyle}>{addressItem.firstname + ' ' + addressItem.lastname}</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="subtitle1" noWrap={true} >{addressItem.street}</Typography>
                                            <Typography variant="subtitle1">{addressItem.city}</Typography>
                                            <Typography variant="subtitle1">{addressItem.region.region + '-' + addressItem.postcode}</Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="subtitle1" gutterBottom>Ph: {addressItem.telephone} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
     
                            <Grid item xs>
                                <Grid container>
                                    {isDefaultAddress ? '' :
                                        <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onSetDefaultLinkClick(event, addressItem.id, addressItem.customer_id)} >Set Default
                                    </Typography>
                                    }
                                    {/* {(isDefaultAddress) ? (
                                        <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onEditLinkClick(event)} >Edit</Typography>) :

                                        <React.Fragment>
                                            <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onEditLinkClick(event)} >Edit</Typography>
                                            <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.props.onDeleteLinkClick(addressId)}>Delete
                                            </Typography>
                                            <Typography variant="caption" className={classes.btnStyle} onClick={(event) => this.onSetDefaultLinkClick(event, addressItem.id, addressItem.customer_id)} >Set Default
                                            </Typography>
                                        </React.Fragment>
                                    } */}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
    }
    
            </React.Fragment>            
        );
        
    }
}

const mapStateToProps = state => ({
    shippingaddress: state.get_user_details_state,
    getAddress: state.get_shipping_address,
    setAsDefaultAddressStatusProp: state.setAsDefaultAddressState.setAsDefaultAddressStatus,
    setAsDefaultAddressMessageProp: state.setAsDefaultAddressState.errorMessage,
    deleteAddressStatusProp: state.deleteAddressState.deleteMyAddressItemSuccess,
    deleteAddressMessageProp: state.deleteAddressState.errorMessage,

});
const mapDispatchToProps = dispatch => ({
    getShippingAddress: () => {
        let accountService = new AccountService();
        dispatch(accountService.GetCustomerDetailsActionCreator());
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.getShippingAddressActionCreator());
    },
    onDeleteItemFromMyAddresses: (addressId) => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.deleteShippingAddressActionCreator(addressId));
    },
    resetDeleteAddress: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.resetDeleteAddrActionCreator());
    },
    onSetDefaultAddress: (address_id, customer_id) => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.setAsDefaultAddressActionCreator(address_id, customer_id));
    },
    resetPostSetAsDefaultAddress: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.resetPostSetAsDefaultAddrActionCreator());
    },
});

const MyAddressesWithStyles = withStyles(styles)(MyAddresses);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAddressesWithStyles));