/**
* Component for displaying customer requisition list.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import dateformat from 'dateformat';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
//Redux related imports
import { connect } from 'react-redux';
import PageTitle from '../../../app/components/common/PageTitle';
import CreateNewRequisition from './CreateNewRequisition';
import AccountService from '../../../app/modules/account/AccountService';
import RequisitionService from '../../../app/modules/requisition/RequisitionService';
import { Hidden } from '@material-ui/core';
import GetLanguage from '../../../library/GetLanguage'
import ContentLoader from '../layouts/blocks/noncarousel/ContentLoader';
const styles = theme => ({
    myordermain: {
        marginBottom: '20px',
        overflowX: 'auto',
        background:'none',
    },
    tableFontStyle: {
        fontSize: '13px',
        color:'#1d2d5f',
    },
    noBorder: {
        border: 'none',
    },
    viewOrderStyle: {
        cursor: 'pointer',
        color: '#57B3FF',
        textDecoration: 'none',
        fontSize: '1rem'
    },
    card: {
        width: '100%',
        padding: '10px',
        margin: "8px"
    },
    myOrdersButton: {
        borderRadius: '25px',
    },
    pageHeading:{
        color:'#1d2d5f',
        margin:'10px 0 0 0',
    },
    noPadding:{
        padding:'0px',
        fontWeight:'bold',
    },
    tableHeading:{
        textTransform:'uppercase',
        color:'#1d2d5f',
        fontSize:'12px',
    },
});

/***************************************************************
 * Component to display customer requisition list
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 **************************************************************/
class RequisitionList extends React.Component {

    componentDidMount() {
        /**********************************************************
         * Check if the user is authenticated. 
         * If yes, display wishlist page.
         * If not, redirect to Sign-In page and once
         * signed-in, redirect back to wishlist page.
         *********************************************************/
        if (!(AccountService.isAuthenticatedUser())) {
            this.props.history.push({
                pathname: '/account',
                state: { redirectTo: this.props.location.pathname }
            });
        }
    }
    handleViewReqList=(entity_id) => {
        this.props.setEnitity(entity_id);
        this.props.setSubMenu(5);
    }
    render() {
        if(!this.props.requisitionList){
            return (<ContentLoader isLoading={true} />)
        }
        const { classes } = this.props;
        const { requisitionList,isSalesmanUser } = this.props;

        if (requisitionList === '' || requisitionList === {}) return null;
        let requisitionKeys = Object.keys(requisitionList);
        let requisitionValues = requisitionKeys.map((index) => {
            return requisitionList[index];
        });
        
        if (requisitionValues && requisitionValues.length) {
            return (
                <React.Fragment>
                    <Grid container direction='row' alignItems='center' justify='space-between'
                        style={{ 'paddingBottom': '0.5rem' }}>
                        <Grid item>
                        <Typography variant="h6" align="left" className={classes.pageHeading}>
                {isSalesmanUser ? <GetLanguage value="Requisition List" /> : <GetLanguage value="My Requisition List" />}
                    </Typography>
                                 </Grid>
                        <Grid item>
                            <CreateNewRequisition />
                        </Grid>
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Paper className={classes.myordermain}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.noPadding+' '+classes.tableHeading}>Name & Description</TableCell>
                                        <TableCell className={classes.tableHeading}>No of Items</TableCell>
                                        <TableCell className={classes.tableHeading}>Latest Activity</TableCell>
                                        <TableCell className={classes.tableHeading}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {requisitionValues.map((row, index) => {
                                        if (row.name === null)
                                            return null;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableFontStyle+' '+classes.noPadding}>{row.name}</TableCell>
                                                <TableCell className={classes.tableFontStyle}>{Object.values(row.items).length}</TableCell>
                                                <TableCell className={classes.tableFontStyle}>{row.updated_at}</TableCell>
                                                <TableCell><Button onClick={()=>this.handleViewReqList(row)}><GetLanguage value="View"/></Button></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Grid container>
                            {requisitionValues.map((row, index) => (
                                <Paper className={classes.card} key={index}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography style={{ fontWeight: 'bold' }}><GetLanguage value="Name"/> : {row.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography><GetLanguage value="Description"/> : {row.description}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography><GetLanguage value="Items"/> : {Object.values(row.items).length}</Typography>
                                        </Grid >
                                        <Grid item>
                                            <Typography><GetLanguage vlaue="Latest Activity"/> : {row.updated_at}</Typography>
                                        </Grid >
                                    </Grid>
                                    <Button variant="contained" color="secondary" className={classes.myOrdersButton}
                                    onClick={()=>this.handleViewReqList(row)}>View</Button>
                                </Paper>
                            ))}
                        </Grid>
                    </Hidden>
                </React.Fragment>
            )
        }
        else if (requisitionValues && requisitionValues.length === 0) {
            return (
                <React.Fragment>
                    <Grid container direction='row' alignItems='center' justify='space-between'
                        style={{ 'paddingBottom': '0.5rem' }}>
                        <Grid item>
                            <PageTitle title={isSalesmanUser?<GetLanguage value='Requisition List'/>:<GetLanguage value='My Requisition List'/>} />
                        </Grid>
                        <Grid item>
                            <CreateNewRequisition />
                        </Grid>
                    </Grid>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                                YOUR REQUISITION LIST IS EMPTY</Typography>
                        </Grid>
                        <Grid item>
                            <Button size="large" component={Link} to="/" variant="contained" color="primary" aria-label="Checkout">CONTINUE SHOPPING</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        else
            return null;
    }
}

const mapStateToProps = state => ({
    loader_status: state.get_loader_status,
    requisitionList: state.getRequisitionList,
    isSalesmanUser: state.getSalesManDetails.isSalesmanUser,
});


const mapDispatchToProps = dispatch => ({
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    },
    setEnitity: (Obj) => {
        let reqService = new RequisitionService();
        dispatch(reqService.SetEnitityActionCreator(Obj));
    },
});

RequisitionList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const RequisitionListWithStyles = withStyles(styles)(RequisitionList);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequisitionListWithStyles));
