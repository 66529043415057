/**
* Template for wrapping Main Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    mainContentStyle : {
        padding: '1rem 1rem 1.5rem 1rem',
        minHeight: '500px'
    }
});

/**************************************************************
 * This component wraps the Main Content Component. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *************************************************************/
class MainComponentWrapper extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.mainContentStyle}>
                {this.props.children}
            </div>
        );
    }
}
MainComponentWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainComponentWrapper);

