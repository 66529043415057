/**
* RegistrationForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Swathy Sagar <swathy.sagar@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PageTitle from '../../../../app/components/common/PageTitle';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import RegistrationService from '../../../../app/modules/registration/RegistrationService';
import AccountService from '../../../../app/modules/account/AccountService';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    }
});

/***************************************************************
 * Component class for displaying Sign Up Form.
 * On submit user can able to create a new account
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class RegistrationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: '',
            confirmPassword: '',
            editFirstName: true,
            editLastname: true,
            editEmail: true,
            showPassword: false,
            showConfirmPassword: false,
            //  passwordValidation:{
            upperCase: false,
            lowerCase: false,
            specialCharacter: false,
            number: false,
            length: false,
            confirmPasswordValue: false,
            confirmClicked: false,
        };
    }
    componentDidMount(){
        this.setState({
            firstName:this.props.customerData?this.props.customerData.firstname:"",
            lastName:this.props.customerData?this.props.customerData.lastname:"",
            email:this.props.customerData?this.props.customerData.email:""
        });
        
        //Dispatch action
        //this.props.validateTokenDispatch(this.props.location.state.id, this.props.location.state.token);
    }
    //updates registration form fields with provided values
    handleChange = prop => event => {
        if (prop === 'password') {
            var regexUpper = /^.*[A-Z].*/;
            var regexlower = /^.*[a-z].*/;
            var regexNumber = /^.*[1-9].*/;
            var specialCharacter = /^.*[^`!@#$%^&*\-_=+'\/.,]*[`!@#$%^&*\-_=+'\/.,].*/;
            var regexLength = /^.{8,}$/;

            var info = event.target.value.match(regexUpper);

            if (event.target.value.match(regexUpper) === null) {
                this.setState({ upperCase: true });
            }
            else {
                this.setState({ upperCase: false });
            }
            if (event.target.value.match(regexlower) === null) {
                this.setState({ lowerCase: true });
            }
            else { this.setState({ lowerCase: false }); }
            if (event.target.value.match(regexNumber) === null) {
                this.setState({ number: true });
            }
            else { this.setState({ number: false }); }
            if (event.target.value.match(specialCharacter) === null) {
                this.setState({ specialCharacter: true });
            }
            else { this.setState({ specialCharacter: false }); }
            if (event.target.value.match(regexLength) === null) {
                this.setState({ length: true });
            }
            else { this.setState({ length: false }); }
        }
        this.setState({ confirmClicked: false });
        this.setState({ [prop]: event.target.value });
    };
    //hide/show text in password field
    handleClickShowPassword = () => {

        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    //hide/show text in password field
    handleClickShowConfirmPassword = () => {

        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };
    //call dispatch function if email and password fields are not empty
    handleRegistration = (event) => {
        event.preventDefault();
        //Dispatch action to Register(Sign-Up) the user.
        const {
            firstName,
            lastName,
            email,
            password
        } = this.state;

        if ((firstName !== "") && (lastName !== "") && (email !== "")) {
            if (!this.state.upperCase && !this.state.lowerCase && !this.state.specialCharacter && !this.state.number && !this.state.length) {

                if (this.state.password !== this.state.confirmPassword) {
                    return this.setState({ confirmPasswordValue: true });
                }
                else {
                    this.setState({ confirmPasswordValue: false });
                }
            }
            let token = this.props.location ? this.props.location.state.token: ''
            //Dispatch action
            this.props.onSubmitRegistrationDispatch(
                email,
                password,
                token

            );
        }
        this.setState({ confirmClicked: true,password:"",confirmPassword:"" });
    };

    render() {
        const { classes } = this.props;
        const { registrationFormSuccess, resetPasswordError ,resetPasswordStatus} = this.props;
        //If Registration is success, Redirect to home page
        if (resetPasswordStatus) {
            return <Redirect push to="/" />;
        } else if(!resetPasswordStatus && resetPasswordError !== '') {
            return <Redirect push to="/account" />;
        }
        return (
            <React.Fragment>
                <PageTitle title="Registration Details" />
                <Grid container alignItems="center" justify="center">
                    <Grid item xs sm={8} md={5} lg={3}>
                        <Card className={classes.cardStyle}>
                            <CardContent>
                                <form onSubmit={this.handleRegistration}>
                                    <Grid container spacing={16} direction="column" justify='center' alignItems='center'>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-firstName">First Name</InputLabel>
                                                <Input id="adornment-firstName" disabled={this.state.editFirstName} type='text' value={this.state.firstName}
                                                   fullWidth onChange={this.handleChange('firstName')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton onClick={() => this.setState({ editFirstName: false })} disableRipple style={{ cursor: 'default' }}><EditIcon /></IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="adornment-lastName">Last Name</InputLabel>
                                                <Input id="adornment-lastName" type='text' value={this.state.lastName}
                                                    fullWidth onChange={this.handleChange('lastName')} disabled={this.state.editLastname} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton onClick={() => this.setState({ editLastname: false })} disableRipple style={{ cursor: 'default' }}><EditIcon /></IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item >
                                            <FormControl  required fullWidth>
                                                <InputLabel htmlFor="adornment-signUpEmail">Email</InputLabel>
                                                <Input id="adornment-signUpEmail" disabled={this.state.editEmail} type='text' value={this.state.email}
                                                    fullWidth onChange={this.handleChange('email')} required={true} 
                                                    // endAdornment={
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton onClick={() => this.setState({ editEmail: false })} disableRipple style={{ cursor: 'default' }}><EditIcon /></IconButton>
                                                    //     </InputAdornment>
                                                    // }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl  required fullWidth>
                                                <InputLabel htmlFor="adornment-resetPassword" style={{ color: '#000000' }}>Password</InputLabel>
                                                <Input id="adornment-resetPassword" type={this.state.showPassword ? 'text' : 'password'}
                                                    value={this.state.password} onChange={this.handleChange('password')}
                                                    required={true}
                                                    
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.state.upperCase && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Uppercase is required
                                        </Typography>
                                            </Grid>
                                        }
                                        {this.state.lowerCase && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Lowercase is required
                                        </Typography>
                                            </Grid>
                                        }
                                        {this.state.number && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Number is required
                                                 </Typography>
                                            </Grid>
                                        }
                                        {this.state.specialCharacter && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    1 Special character is required
                                        </Typography>
                                            </Grid>
                                        }
                                        {this.state.length && this.state.confirmClicked &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    Password is minimum of 8 characters
                                        </Typography>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="adornment-confirmResetPassword" style={{ color: '#000000' }}>Confirm Password</InputLabel>
                                                <Input id="adornment-confirmResetPassword" type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                    value={this.state.confirmPassword} onChange={this.handleChange('confirmPassword')}
                                                    
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowConfirmPassword}>
                                                                {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.state.confirmPasswordValue &&
                                            <Grid item>
                                                <Typography variant="subtitle1" color="primary">
                                                    Password and confirm Password must match
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item justify="center" alignItems="center">
                                            <FormControl required margin={'normal'} fullWidth>
                                                <Fab size="large"  variant="extended" color="primary"
                                                    type="submit">CONFIRM</Fab>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    registrationFormSuccess: state.post_registered_details_state.registrationSuccess,
    registrationFormError: state.post_registered_details_state.errorMessage ? state.post_registered_details_state.errorMessage : '',
    customerData: state.post_registered_mobile_otp_state.customerData,
    resetPasswordStatus: state.result_reset_forgot_password_state.resetPasswordStatus,
    resetPasswordError: state.reset_password_state.errorMessage ? state.reset_password_state.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    onSubmitRegistrationDispatch: (email,password,token) => {
        var ResetPasswordObject = {
            "confirmResetPassword": password,
            "resetPasswordToken": token,
            "validateTokenEmail": email
        };
        let as = new AccountService();
        dispatch(as.resetPasswordActionCreator(ResetPasswordObject));
    },
    validateTokenDispatch: (customerId,resetToken,) => {
        var ValidateTokenObject = {
            "customerId": customerId,
            "resetToken": resetToken
        };
        let as = new AccountService();
        dispatch(as.validateTokenActionCreator(ValidateTokenObject));
    }
}
);

const RegistrationFormWithStyles = withStyles(styles)(RegistrationForm);
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormWithStyles);