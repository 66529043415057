/**
* List of PWA controlled URL Paths and respective Components
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { Route } from 'react-router-dom';
import Headerbar from '../../components/layouts/headerbar/HeaderBar';
import Footerbar from '../../components/layouts/footerbar/FooterBar';
import SignInForm from '../../components/myaccount/signin/SignInForm';
import SalesManLogin from '../../components/myaccount/signin/SalesManLogin';
import CustomersList from '../../components/myaccount/signin/CustomersList';
import SignUpForm from '../../components/myaccount/signin/SignUpForm';
import AccountInfo from '../../components/myaccount/accountinformation/AccountInformation';
import Address from '../../components/address/AddressForm.js';
import MyAddresses from '../../components/address/AddressList.js';
import ForgotPasswordForm from '../../components/myaccount/signin/ForgotPasswordForm';
import CMSPagesComponent from '../../components/cms/CMSPages';
import CartList from '../../components/cart/CartList';
import ShippingAddress from '../../components/checkout/ShippingAddress';
import CheckoutStepper from '../../components/checkout/CheckoutStepper';
import BorderPropertiesDisplay from '../../components/pages/BorderPropertiesDisplay';
import TemplateUsageEmpty from '../../../theme/base/layouts/templates/TemplateUsageEmpty';
import TemplateUsageGlobal from '../../../theme/base/layouts/templates/TemplateUsageGlobal';
import TemplateUsage2ColumnsLeftBar from '../../../theme/base/layouts/templates/TemplateUsage2ColumnsLeftBar';
import TemplateUsage2ColumnRightBar from '../../../theme/base/layouts/templates/TemplateUsage2ColumnsRightBar';
import TemplateUsage3Columns from '../../../theme/base/layouts/templates/TemplateUsage3Columns';
import TemplateConfigUsage from '../../../theme/base/layouts/templates/TemplateConfigUsage';

import NavigationMenuDesktop from '../../components/navigation/navigationdesktop/NavigationDesktop';
import SearchResults from '../../components/search/searchresults/SearchResults';
import OrderSuccess from '../../components/checkout/OrderConfirmation';
import OrderSuccessComp from '../../components/checkout/orderSuccess';
import WithHeaderAndFooter from '../../../theme/base/layouts/templates/WithHeaderAndFooter';
import MainComponentWrapper from '../../../theme/base/layouts/templates/MainComponentWrapper';
import CustomerWishlist from '../../components/wishlist/CustomerWishlist';
import MyProfile from '../../components/myaccount/myprofile/MyProfile';
import MyOrders from '../../components/myaccount/myorders/MyOrders';
import SubscribeNewsletter from '../../components/newslettersubscriptions/NewsletterSubscriptions.js';
import MobileNumberForm from '../../components/myaccount/registration/MobileNumber';
import OTPForm from '../../components/myaccount/registration/OTP';
import ResetPasswordForm from '../../components/myaccount/signin/ResetPasswordForm';
import RegistrationForm from '../../components/myaccount/registration/RegistrationForm';
import SalesmanAccountInformation from '../../components/myaccount/accountinformation/SalesmanAccountInformation';
import BarcodeScanner from '../../components/search/searchbar/barcodeScan/barcodeScanMobile';
/***************************************************************************** 
 * Router Component for PWA controlled URLs
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const PWARouteComponent = route => {
    return <Route exact
        path={route.path}
        render={props => (
            <route.component {...props} path={route.path} />
        )}
    />
};


const SampleComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={
            <h1>Sample Content for PWA path {props.path}</h1>}
        FooterComponent={Footerbar}
    />
);

const ForgotPasswordComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <ForgotPasswordForm />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const SalesManLoginComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <SalesManLogin {...props} />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const SignUpFormComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <SignUpForm />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const AccountInfoComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <AccountInfo />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const AddressComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <Address />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const MyAddressesComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <MyAddresses />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const CartListComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        label={'Cart'}
        MainContent={<MainComponentWrapper>
            <CartList />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />

);

const CheckoutComponent = (props) => (
    <>
        <div className="grid-container">
            <Headerbar />
            <CheckoutStepper {...props} />

        </div>
        <Footerbar />
    </>
);

const ShippingComponent = (props) => (
    <div>
        <Headerbar />
        <NavigationMenuDesktop></NavigationMenuDesktop>
        <h1>Sample Content for PWA path {props.path}</h1>
        <ShippingAddress />
        <Footerbar />
    </div>
);

const SearchResultsDisplayComponent = (props) => (
    /*Temporarily added localstorage concept, will do detailed R&D*/
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <SearchResults
                searchTerm={localStorage.getItem('searchTerm')}>
            </SearchResults>
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const WishlistComponent = (props) => (
    <WithHeaderAndFooter

        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        label={"Wishlist"}
        MainContent={<MainComponentWrapper>
            <CustomerWishlist />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const NewsletterSubscribeComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <SubscribeNewsletter>
            </SubscribeNewsletter>
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const MyProfileComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <MyProfile />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const MyOrdersComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <MyOrders />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);
const RegistrationFormComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <RegistrationForm {...props} />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);
const ResetPasswordFormComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <ResetPasswordForm {...props} />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);
const OTPFormComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <OTPForm {...props} />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);

const MobileNumberFormComponent = (props) => (
    <WithHeaderAndFooter
        HeaderComponent={Headerbar}
        NavigationComponent={NavigationMenuDesktop}
        MainContent={<MainComponentWrapper>
            <MobileNumberForm {...props} />
        </MainComponentWrapper>}
        FooterComponent={Footerbar}
    />
);
const OrderSuccessDisplayComponentWithTemplate = (props) => (
    <div>
        <WithHeaderAndFooter
            HeaderComponent={Headerbar}
            NavigationComponent={NavigationMenuDesktop}
            MainContent={<MainComponentWrapper>
                <OrderSuccess
                    ordernumber={props.location.state && props.location.state.ordernumber} {...props} />
            </MainComponentWrapper>}
            FooterComponent={Footerbar}
        />
    </div>
);
const OrderSuccessCompDisplayComponentWithTemplate = (props) => (
    <div>
        <WithHeaderAndFooter
            HeaderComponent={Headerbar}
            NavigationComponent={NavigationMenuDesktop}
            MainContent={<MainComponentWrapper>
                <OrderSuccessComp
                    ordernumber={props.location.state && props.location.state.ordernumber} {...props} />
            </MainComponentWrapper>}
            FooterComponent={Footerbar}
        />
    </div>
);

const CMSPagesComponentWithTemplate = (props) => (
    <div>
        <WithHeaderAndFooter
            HeaderComponent={Headerbar}
            NavigationComponent={NavigationMenuDesktop}
            MainContent={<MainComponentWrapper>
                <CMSPagesComponent {...props} />
            </MainComponentWrapper>}
            FooterComponent={Footerbar}
        />
    </div>
);

const SalesmanAccountComponent = (props) => (
    <div>
        <WithHeaderAndFooter
            HeaderComponent={Headerbar}
            NavigationComponent={NavigationMenuDesktop}
            MainContent={<MainComponentWrapper>
                <SalesmanAccountInformation {...props} />
            </MainComponentWrapper>}
            FooterComponent={Footerbar}
        />
    </div>
);

const BarcodeScannerComponent = (props) => (
    <div>
        <BarcodeScanner {...props} />
    </div>
);

const CustomersListComponent = (props) => (
    <div>
        <Headerbar />
        <CustomersList />
    </div>
);



/****************************************************************************** 
 * List of PWA controlled Paths and respective Components
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const routes = [
    {
        path: '/newslettersubscribe',
        component: NewsletterSubscribeComponent
    },
    {
        path: '/wishlist',
        component: WishlistComponent
    },
    {
        path: '/myprofile',
        component: MyProfileComponent
    },
    {
        path: '/home',
        component: SampleComponent
    },
    {
        path: '/border',
        component: BorderPropertiesDisplay
    },
    {
        path: '/account',
        component: SignInForm
    },
    {
        path: '/signup',
        component: SignUpFormComponent
    },
    {
        path: '/registerMobile',
        component: MobileNumberFormComponent
    },
    {
        path: '/mobileOTP',
        component: OTPFormComponent
    },
    {
        path: '/registration',
        component: RegistrationFormComponent
    },
    {
        path: '/templatedisplay1',
        component: TemplateUsageEmpty
    },
    {
        path: '/templatedisplay2',
        component: TemplateUsageGlobal
    },
    {
        path: '/templatedisplay3',
        component: TemplateUsage2ColumnsLeftBar
    },
    {
        path: '/templatedisplay4',
        component: TemplateUsage2ColumnRightBar
    },
    {
        path: '/templatedisplay5',
        component: TemplateUsage3Columns
    },
    {
        path: '/templateconfigusage',
        component: TemplateConfigUsage
    },
    {
        path: '/myaccount',
        component: AccountInfoComponent
    },
    {
        path: '/address',
        component: AddressComponent
    },
    {
        path: '/myaddresses',
        component: MyAddressesComponent
    },
    {
        path: '/forgotpassword',
        component: ForgotPasswordComponent
    },
    {
        path: '/customer/account/createPassword/',
        component: MobileNumberFormComponent
    },
    {
        path: '/customer/account/newPassword/',
        component: ResetPasswordFormComponent
    },
    {
        path: '/cart',
        component: CartListComponent
    },
    {
        path: '/checkout',
        component: CheckoutComponent
    },
    {
        path: '/shipping',
        component: ShippingComponent
    },
    {
        path: '/search',
        component: SearchResultsDisplayComponent
    },
    {
        path: '/ordersuccess',
        component: OrderSuccessCompDisplayComponentWithTemplate
    },
    {
        path: '/myorders',
        component: MyOrdersComponent
    },
    {
        path: '/vieworder',
        component: OrderSuccessDisplayComponentWithTemplate
    },
    {
        path: '/salesmanlogin',
        component: SalesManLogin
    },
    {
        path: '/customersList',
        component: CustomersListComponent
    },
    {
        path: '/cmsBlocks',
        component: CMSPagesComponentWithTemplate
    },
    {
        path: '/salesmanaccount',
        component: SalesmanAccountComponent
    },
    {
        path: '/barcodescanner',
        component: BarcodeScannerComponent
    }
];
export { PWARouteComponent, routes };  