import React, { Component } from 'react'

import { connect } from 'react-redux';
import { formatPriceOfProductforCategoryListing } from '../../../app/framework/common/helpers';
import { withStyles, Typography, Grid } from '@material-ui/core';

const styles = theme => ({
    pdp_priceStyle: {
        fontWeight: 'bold',
        color: '#1d2d5f',
        fontSize: '20px',
        paddingBottom: '10px',
    },
    plp_priceStyle: {
        fontWeight: 'bold',
        color: '#1d2d5f',
        fontSize: '15px',
        paddingBottom: '10px',
    },

});
class PriceDisplay extends Component {
    render() {
        let { variantSelected, productDetail, productDetailPage, classes } = this.props;
        const { sku } = productDetail;
        let price, delprice;
        if (Object.keys(variantSelected).includes(sku) && variantSelected[sku].selectedUnit === 1) {
            let unit_price = variantSelected[sku].special_price || variantSelected[sku].price;
            price = variantSelected[sku].conversion_factor * unit_price * 1;
            if (productDetail.special_price) {
                delprice = variantSelected[sku].conversion_factor * variantSelected[sku].price * 1;
            }
        } else {
            price = productDetail.special_price || productDetail.price;
            if (productDetail.special_price) {
                delprice = productDetail.price;
            }
        }
        if (!localStorage.getItem('shopce-user')) {
            return null;
        }
        return (
            <Grid container alignItems='center'>
                <Grid item>
                    <Typography className={productDetailPage ? classes.pdp_priceStyle : classes.plp_priceStyle}>
                        {formatPriceOfProductforCategoryListing(price)}
                    </Typography>
                </Grid>
                {delprice &&
                    <Grid item>
                        <Typography style={{ color: '#A9A9A9', marginLeft: '4px',fontSize:'12px' }}>
                            <del>{formatPriceOfProductforCategoryListing(delprice)}</del>
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    variantSelected: state.variant_selected,
});
const PriceDisplayWithStyles = withStyles(styles)(PriceDisplay);
export default connect(mapStateToProps)(PriceDisplayWithStyles);
