/**
* UserMessages Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import IconBasedOnType from './IconBasedOnType';
import PropTypes from 'prop-types';

const styles = theme => ({
    snackbarMainDiv: {
        display: 'flex',
        justifyContent: 'center',
        zIndex: '1000',
        left: '0',
        right: '0',
        margin:'10px'
    },
    snackbarDiv: {
        color: '#fff',
        display: 'flex',
        padding: '6px 24px',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderRadius: '4px',
    },
    snackbarSpan: {
        display: 'flex',
        alignItems: 'center',
    },
    snackbarCloseDiv: {
        marginLeft: 'auto',
    },
    closeSnackbarDiv: {
        display: 'none',
    },
});

const IconSelector = [
    {
        "type": "error",
        "color": "#d32f2f",
    },
    {
        "type": "warning",
        "color": "#ffa000",
    },
    {
        "type": "sucess",
        "color": "#43a047",
    },
    {
        "type": "info",
        "color": "#1976d2",
    }
];

/**************************************************************
 * Component for user messages 
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 *************************************************************/
class UserMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closeSnackBar: false
        }
    }
    
    componentDidMount() {
        if(typeof this.props.autoHideDuration !== 'undefined' && this.props.autoHideDuration !== '') {
            this.setAutoHideTimer(this.props.autoHideDuration);
        }
    }

    //get the message bg color based on message type
    handleMessageBg = () => {
        
        for (let i = 0; i < IconSelector.length; i++) {
            if (IconSelector[i].type === this.props.messageType) {
                return IconSelector[i];
            }
            else {
                continue;
            }
        }
    }

    //close the message
    handleSnackBarClose = () => {
        this.setState({ closeSnackBar: true });
    }

    //run timer and hide message after the duration
    //input time should be given in milliseconds
    setAutoHideTimer = (autoHideDuration) => {
        var self = this;
  
        var autoHideDurationBefore = (autoHideDuration != null) ? autoHideDuration : this.props.autoHideDuration;
  
        if (autoHideDurationBefore == null) {
          return;
        }
  
        clearTimeout(this.timerAutoHide);
        this.timerAutoHide = setTimeout(function () {
          var autoHideDurationAfter = (autoHideDuration != null) ? autoHideDuration : this.props.autoHideDuration;

          if (autoHideDurationAfter == null) {
            return;
          }
          self.setState({ closeSnackBar: true });
  
        }, autoHideDurationBefore);
    }

    //returns snackbar like component which includes icon,message with a close button
    render() {
        const { classes } = this.props;
        var messageTypeObject = this.handleMessageBg();
        return (
            <div className={classNames(classes.snackbarMainDiv,this.state.closeSnackBar? classes.closeSnackbarDiv:'')}>
            <div className={classNames(classes.snackbarDiv)} style={{backgroundColor: messageTypeObject.color}}
            role="alertdialog" aria-describedby="client-snackbar">
                <span id="client-snackbar" className={classes.snackbarSpan}>
                <IconBasedOnType messageType={this.props.messageType}/>
                {this.props.message}
                </span>
                <IconButton className={classes.snackbarCloseDiv} aria-label="Close" onClick={this.handleSnackBarClose}>
                    <CloseIcon  color='error' className={classes.snackbarIcon}/>
                </IconButton>
            </div>
            </div>
        );
      }
}

UserMessages.propTypes = {
        messageType: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        autoHideDuration: PropTypes.number,
}
  
export default withStyles(styles)(UserMessages);