/**
* Main Router Switch Router Component for the App
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import { PWARouteComponent } from './PWARouterConfig';
import MagentoRouteComponent from './MagentoRouterConfig';
import {
  Route,
  BrowserRouter,
  Switch,
} from 'react-router-dom';
import { withRouter } from 'react-router';
import React, { Component } from 'react';
import { routes } from './PWARouterConfig';
import HomeContainer from '../../../extension/components/layouts/containers/HomeContainer';

/***************************************************************************** 
 * Main Content Area component that renders based on whether the 
 * URL is PWA controlled or Magento controlled.
 * First checks if it is a PWA Controlled Component and renders it if it is. 
 * If it is not a PWA Component, Magento Controlled Component is rendered.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ****************************************************************************/
class URLbasedRouteComponent extends Component {
  render() {
    return (
      <BrowserRouter basename={`${process.env.REACT_APP_BASE_URL}`}>
        <Switch>
          {/* Home Page Path Component */}
          <Route exact path="/" component={HomeContainer} />
          <Route exact path="/index.html" component={HomeContainer} />
         
          {/* PWA Controlled Paths */}
          {
            routes.map((route, i) => {
              // Check if browser URL Path is same as route config path.
              // If yes, render PWARouteComponent.
              // Otherwise return false, to fall through and search for 
              // Backend (Magento) controlled paths.
         
                return <PWARouteComponent key={i} {...route} />
            })
          }
          {/* Render props are being used. onChangeMagentoURLPath is being used to pass any
          Magento path from MagentoRouterConfig. */}
          {/* Magento Controlled Paths */}
          <Route path="*"
            render={(props) => <MagentoRouteComponent {...props} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }

}

export default withRouter(URLbasedRouteComponent);
