/**
* OrderService class with functions for handling Order History  related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Nirmal <nirmal.gandhi@embitel.com>
* @copyright 2019-2020 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    RECEIVE_CUSTOMERLIST_FORSALESMAN,
    REQUEST_CUSTOMERSIGNIN_FORSALESMAN,
    RECIEVE_CUSTOMERSIGNIN_FORSALESMAN,
    REQUEST_RESET_SMLOGGEDINASCUSTOMER_SIGNED_IN_STATE,
    SET_IS_SALESMAN_USER,
    SET_IS_SALESMAN_ALWAYS_FALSE,
    REQUEST_SALESMAN_PROFILE,
    RECEIVE_SALESMAN_PROFILE,
    REQUEST_SET_SALESMAN_PROFILE,
    RECEIVE_SET_SALESMAN_PROFILE,
    REQUEST_SET_SALESMAN_PWD,
    RECEIVE_SET_SALESMAN_PWD,
    SET_LOADER_STATUS
} from '../../framework/common/ReduxConstants';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import { getItemFromLocalStorage } from '../../framework/storage/local/localStorageHandler';
import magnaCookies from '../../framework/cookie/magnaCookies';
import { setItemInLocalStorage } from '../../framework/storage/local/localStorageHandler';
import CartService from './../cart/CartService';
import OrderService from '../order/OrderService';
import { NotificationManager } from 'react-notifications';
import {getSignedInCustomerCartID} from '../../framework/common/helpers'


/**********************************************************************************
 * Order Service class which handles Order History
 *********************************************************************************/
class SalesManService {
    GetSalesmanProfileDetailsActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_SALESMAN_PROFILE });
            dispatch({ type: SET_LOADER_STATUS });
            let token = getItemFromLocalStorage('salesman-user');
            let requestBody = {};
            requestBody.header = ("Bearer " + token);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'GET';
            let requestURL = '/salesman/profile/getname';

            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_SALESMAN_PROFILE, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                },
                (error) => {
                    dispatch({ type: RECEIVE_SALESMAN_PROFILE, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                }
            );
        }
    }
    //Helper function for reducer shipping_address_reducer
    static GetSalesmanProfileDetailsReducer = (state='', action) => {
        switch (action.type) {
            case RECEIVE_SALESMAN_PROFILE:
                if(action.response && action.response.data) {
                    return action.response.data[0];
                }
                return null;
            default:
                return state;
        }
    }

    SetSalesmanProfileDetailsActionCreator(firstname, lastname) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_SALESMAN_PROFILE });
            dispatch({ type: SET_LOADER_STATUS });
            let token = getItemFromLocalStorage('salesman-user');
            let requestBody = {};
            requestBody.header = ("Bearer " + token);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/salesman/profile/setname';
            requestBody.data = {
                "firstName":firstname,
                "lastName": lastname
            }
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    var responseObj = JSON.parse(response.config.data);
                    let salesmanFromLocalStorage = getItemFromLocalStorage('sales-man');
                    if(salesmanFromLocalStorage)
                    {
                        salesmanFromLocalStorage.firstname=responseObj.firstName;
                        salesmanFromLocalStorage.lastname=responseObj.lastName;
                    }
                  
                    setItemInLocalStorage('sales-man', JSON.stringify(salesmanFromLocalStorage));
                    dispatch({ type: RECEIVE_SET_SALESMAN_PROFILE, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                    NotificationManager.success('Profile Updated Sucessfully');
                },
                (error) => {
                    dispatch({ type: RECEIVE_SET_SALESMAN_PROFILE, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        }
    }

    resetSalesmanNameChangeActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_SALESMAN_PROFILE });
        };
    }


        /**********************************************************************************
     * Reducer for Get User Details request.
     *********************************************************************************/
    static set_updated_salesman_details_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_SET_SALESMAN_PROFILE:
                return { ...state, profileNameUpdateStatus: null };

            case RECEIVE_SET_SALESMAN_PROFILE:
                let retUserValue = SalesManService.applyGetUpdatedSalesmanDetails(state, action);
                return retUserValue;
            default:
                return state;
        }
    }

        /************************************************************************************
     * Helper function for reducer get_user_details_reducer
     ***********************************************************************************/
    static applyGetUpdatedSalesmanDetails = (state, action) => {
        if (action.success && action.response.data ) {
            return Object.assign({}, action.response.data, { profileNameUpdateStatus:true });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                signInSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { profileNameUpdateStatus:false });

      
    
        }
    }
 
   static set_salesman_profile_details_reducer = (state = [], action) => {
       switch (action.type) {
           case REQUEST_SET_SALESMAN_PROFILE:
               return { ...state, salesmanProfileNameUpdateStatus: null };

           case RECEIVE_SET_SALESMAN_PROFILE:
               let retUserValue = SalesManService.applySetUpdatedSalesmanDetailsDetails(state, action);
               return retUserValue;
           default:
               return state;
       }
   }

    /************************************************************************************
    * Helper function for reducer get_user_details_reducer
    ***********************************************************************************/
   static applySetUpdatedSalesmanDetailsDetails = (state, action) => {
       if (action.success && action.response.data ) {
           return Object.assign({}, action.response.data, { salesmanProfileNameUpdateStatus:true });
       }
       else {
           let genericError = 'Error, Please try again after some time';
           let returnObjectError = {
               signInSuccess: false,
               errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
           };
           return Object.assign({}, returnObjectError, { salesmanProfileNameUpdateStatus:false });

     
   }
}

    static SetSalesmanProfileDetailsReducer = (state='', action) => {
        switch (action.type) {
            case RECEIVE_SALESMAN_PROFILE:
                
            case RECEIVE_SALESMAN_PROFILE:
                if(action.response && action.response.data) {
                    return action.response.data;
                }
                return null;
            default:
                return state;
        }
    }

    ChangeSalesmanPasswordActionCreator(passwordObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_SALESMAN_PWD });
            dispatch({ type: SET_LOADER_STATUS });
            let token = getItemFromLocalStorage('salesman-user');
            let requestBody = {};
            requestBody.data = passwordObject;
            requestBody.header = ("Bearer " + token);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/salesman/profile/change-password';

            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_SET_SALESMAN_PWD, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                    NotificationManager.success('Password Updated Sucessfully');
                },
                (error) => {
                    dispatch({ type: RECEIVE_SET_SALESMAN_PWD, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        }
    }
    
    static ChangeSalesmanPasswordReducer = (state='', action) => {
        switch (action.type) {
            case RECEIVE_SALESMAN_PROFILE:
                if(action.response && action.response.data) {
                    return action.response.data;
                }
                return null;
            default:
                return state;
        }
    }

    /****************************************************************************** 
        * Action Creators
        *****************************************************************************/
       getCustomerListForSalesmanActionCreator(customerProfileObject) {
        return (dispatch) => {
  
            // dispatch({ type: REQUEST_CUSTOMERLIST_FORSALESMAN });
            //Get token from localStorage
            let userToken = "";
            let userFromLocalStorage = getItemFromLocalStorage('salesman-user');
            if (userFromLocalStorage) {
                userToken = userFromLocalStorage.token;
            };
            let requestBody = {};
          //  requestBody.data = customerProfileObject;
            requestBody.header = ("Bearer " + userToken);
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'GET';
            let requestURL = '/customer/type/salesman';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    dispatch({ type: RECEIVE_CUSTOMERLIST_FORSALESMAN, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_CUSTOMERLIST_FORSALESMAN, success: false, error });
                }
            );
        };
    }
    
    static get_customerlist_for_salesman_reducer = (state = [], action) => {
        switch (action.type) {
            // case REQUEST_CUSTOMERLIST_FORSALESMAN:
            //         return null;
            case RECEIVE_CUSTOMERLIST_FORSALESMAN:
                    let retResetValue = SalesManService.applySalesManDetails(state, action);
                    return retResetValue;
            default:
                return state;
        }
    };

        //Helper function for reducer shipping_address_reducer
        static applySalesManDetails = (state, action) => {
            let salesman_list;
            //Check if Cart Data is present in the action variable.  
            if (action && action.response && ("data" in action.response)) {
                salesman_list = action.response.data;
            }
            else {
                salesman_list = null;
            }
            return salesman_list;
        }



        salesmanSignInAsCustomerActionCreator(SignInObject) {
            return (dispatch) => {
       
                        dispatch({ type: REQUEST_CUSTOMERSIGNIN_FORSALESMAN });
                        let requestURL = `/customer/login/${SignInObject.email}`;
                        let requestType = 'POST';
                        let requestBody = {};
                        requestBody.apiEndPoint = 'thailand';
                        // requestBody.header = ("Bearer " + userToken);
                        MagnaAPIHandler(requestURL, requestType, requestBody).then(
                            (response) => {
                             
                                //On successful Sign In, store the user
                                //information in localStorage.
                                let user = {
                                    firstname: SignInObject.firstname,
                                    lastname: SignInObject.lastname,
                                    email: SignInObject.email,
                                    token: response.data,
                                    
                                    //token: SignInObject.rp_token,
                                    customerId: SignInObject.entity_id,
                                   
                                };

                                setItemInLocalStorage('shopce-user', JSON.stringify(user));
                                   
                                //Set cookie data
                                let cookieData = {
                                    key: 'ECS_AC',
                                    value: response.data.email,
                                    expiry: ''
                                };
                       
                                //Store in the cookie                            
                                magnaCookies.setCookie(cookieData, true);
                                //Send success dispatch for Sign-Ins
                                magnaCookies.removeCookie('customer-cart-id');
                                getSignedInCustomerCartID();
                                dispatch({ type: RECIEVE_CUSTOMERSIGNIN_FORSALESMAN, success: true, response });

                                SalesManService.getCustomerDetails(dispatch);
                         
                    },
                    (error) => {

                         dispatch({ type: RECIEVE_CUSTOMERSIGNIN_FORSALESMAN, success: false, error });
                       // dispatch({ type: REQUEST_IS_SALESMAN_USER, success: false, error });
                    }
                );
            };
        }

       

           /************************************************************************************
     * Sign In POST request reducer.
     ************************************************************************************/
    static salesman_sign_in_ascustomer_reducer = (state = [], action) => {
        switch (action.type) {
            case REQUEST_CUSTOMERSIGNIN_FORSALESMAN:
                return { ...state, salesmanSignedinAsCustomerSuccess: false, isSignInButtonDisabled: true };
            case RECIEVE_CUSTOMERSIGNIN_FORSALESMAN:
                    let retValue = SalesManService.applySignInCustomer(state, action);
                     return retValue;    
                    case REQUEST_RESET_SMLOGGEDINASCUSTOMER_SIGNED_IN_STATE:
                        return { ...state, salesmanSignedinAsCustomerSuccess: null, isSignInButtonDisabled: false };          
    
            default:
                return state;
        }
    }


   static applySignInCustomer = (state, action) => {


       if (action.success) {

           return Object.assign({},  { salesmanSignedinAsCustomerSuccess: true, isSignInButtonDisabled: true });
       }
       else {
           let genericError = 'Error, Please try again after some time';
           let returnObjectError = {
         
               errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
           };
           return Object.assign({}, returnObjectError, { salesmanSignedinAsCustomerSuccess: false, isSignInButtonDisabled: false });
       }
   }


   resetSignedInActionCreator() {
    return (dispatch) => {
        dispatch({ type: REQUEST_RESET_SMLOGGEDINASCUSTOMER_SIGNED_IN_STATE });
    };
}

static getCustomerDetails(dispatch) {

    const cs = new CartService();
    const order = new OrderService();
    dispatch(cs.GetDisplayMiniCartActionCreator());
    dispatch(order.getOrderHistoryActionCreator());
}

/**********************************************************************************
 * Order Service class which handles Order History
 *********************************************************************************/


    static is_salesman_user = (state = false, action) => {
        switch (action.type) {
            case SET_IS_SALESMAN_USER:
                return true;

                case SET_IS_SALESMAN_ALWAYS_FALSE:
                    return false;
            default:
                return state;
        }
    };

}



export default SalesManService;