/**
* Review Order Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import dateformat from 'dateformat';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import CheckoutLayout from '../../components/layouts/checkoutflow/CheckoutLayout';
import OrderSummary from './OrderSummary';
import UserMessage from '../../../app/components/messages/UserMessages';
//Redux related imports
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import CheckoutService from '../../../app/modules/checkout/CheckoutService';
import { decodeHTMLEntities } from '../../../app/framework/common/helpers';
import { mediaURLBasePath } from '../../../app/framework/common/helpers';
//Image dimensions fetched from configuration.
import getConfigValue from '../../../app/framework/config/configHandler';
import { formatPriceOfProduct } from '../../../app/framework/common/processString';
import { removeCustomerCartFromCookie } from '../../../app/framework/common/helpers';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import CartService from '../../../app/modules/cart/CartService';
import GetLanguage from '../../../library/GetLanguage';
import { stat } from 'fs';
import { setItemInLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';

const styles = theme => ({
    maincheckout: {
        backgroundColor: '#e6f4fd',
        padding: '25px 40px',
        border: '1px solid #74c7fd',
        borderRadius: '5px',
        marginTop: '20px',
        fontSize: '14px'
    },
    cursorpointer: {
        cursor: 'pointer',
    },
    havecoupon: {
        color: '#5b5e5f'
    },
    entercodetex: {
        color: '#5db6fd'
    },
    secondhalf: {
        marginTop: '20px'
    },
    reviewHeaderText: {
        color: '#424242',
        fontWeight: 'bold',
        fontSize: '16px',
        marginTop: '40px'
    },
    billingText: {
        [theme.breakpoints.up('md')]: {
            height: '40px',
        },
    },
    billingdefault: {
        border: '2px solid #5db6fd',
        borderRadius: '3px',
    },
    addressdefault: {
        border: '2px solid #dbdbdb',
        borderRadius: '3px',
    },
    defaulttextinner: {
        padding: '20px'
    },
    defaulttext: {
        padding: '10px 20px',
        background: '#e6f4fd',
        color: '#5db6fd',
        borderBottom: '2px solid #5db6fd',
        fontWeight: '500'
    },
    addresstext: {
        padding: '10px 20px',
        background: '#f5f5f5',
        color: '#747474',
        borderBottom: '2px solid #dbdbdb',
        fontWeight: '500'
    },
    defaulttextheader: {
        color: '#424242',
        fontWeight: 'bold',
        fontSize: '13px',
        paddingBottom: '10px'
    },
    defaulttextheaderinnertext: {
        fontSize: '13px',
        paddingBottom: '10px'
    },
    setasdefault: {
        float: 'right',
        color: '#5db6fd',
        textDecoration: 'underline'
    },
    addadress: {
        marginTop: '30px',
        marginBottom: '20px',
        paddingLeft: '20px'
    },
    addaddressmain: {
        background: '#5bb3fd',
        color: '#fff',
        borderRadius: '20px',
        padding: '10px',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    backaddaddress: {
        paddingLeft: '5px'
    },
    textField: {

    },
    image: {
        width: 75,
        height: 80,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    showCursor: {
        cursor: 'pointer',
    },
    customMarginBottom: {
        marginBottom: '1rem',
    },
    section1: {
        margin: `${theme.spacing.unit * 2}px 0px ${theme.spacing.unit * 2}px 0px`,
        [theme.breakpoints.down('sm')]: {
            margin: `0px 0px ${theme.spacing.unit * 2}px 0px`,
        },
    },
    textDecorationNone: {
        textDecoration: 'none',
    },
    shoppingCartGrid: {
        padding: '0rem 1rem',
    },
    couponCodeText: {
        cursor: 'pointer',
        color: '#2196f3',
    },
    productcouponMobile: {
        margin: '10px 0px',
        backgroundColor: '#DFF3FA',
        border: '1px solid #94BEFA',
        borderRadius: 2,
        padding: '5px'
    },
    bgWhite: {
        background: '#fff',

    },
    addressWrapper: {
        height: '300px',
        overflowY: 'auto'
    },
    bldTxt: {
        fontWeight: 'bold',
        color: '#1d2d5f',
        fontSize:'16px'
    },
    marginBtm20: {
        marginBottom: '30px',
    },
    poNumertext:{
        width:'50%'
    }
});

/***************************************************************
 * Component class for reviewing order in checkout flow.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ReviewOrder extends React.Component {

    //on submit, order will be placed
    activeStepSubmit = () => {
        let selectedPaymentOptionObject = this.props.postUpdatePaymentOptionDetails;
        //In case payment option is not set, default to "cash on delivery"
        let selectedPaymentOption = selectedPaymentOptionObject.code ?
            selectedPaymentOptionObject.code :
            "purchaseorder";
        let poNumber = localStorage.getItem('poNumber')
        //Pass the payment method.            
        this.props.placeOrder(selectedPaymentOption, poNumber !== null ? poNumber : '1234567890');

    }
    componentDidMount() {
        localStorage.removeItem('order_notes');
        localStorage.removeItem('poNumber');
        //if(this.props.getAddress) {
        this.props.getShippingAddress();
        this.props.getCheckoutDetails();
        //}
        if (!this.props.loader_status && this.props.get_order_summary.length === 0) {
            this.props.loader();
        }
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.get_order_summary.orderSummaryDetails && nextprops.loader_status) {
            this.props.loader();
        } else if (nextprops.miniCartDetails && nextprops.miniCartDetails.current_cart !== this.props.miniCartDetails.current_cart) {
            // this.props.getShippingAddress();
            // this.props.getCheckoutDetails();
        }
    }
    render() {
        //Get the place order status from redux state.
        const { putPlaceOrderDetails } = this.props;
        const { get_order_summary } = this.props;

        //If the order placement is successful, redirect to order success page.
        if (putPlaceOrderDetails &&
            (putPlaceOrderDetails.placeOrderStatus === true)) {
            //Call helper function to remove customer cart id from Cookies.
            removeCustomerCartFromCookie();
            return <Redirect push to={{ pathname: '/ordersuccess', state: { ordernumber: putPlaceOrderDetails.orderNumber } }} />
        }
        if (Object.keys(get_order_summary).length === 0) {
            return null
        } else if (get_order_summary.orderSummaryDetails.items && get_order_summary.orderSummaryDetails.items.length === 0) {
            return <Redirect push to="/cart" />
        }
        return (
            <React.Fragment>
                {/*<Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.productcouponMobile}>
                    <Grid item xs={12} style={{ padding: '1rem 0rem' }}>
                        <Grid container>
                            <Grid item>
                                <Typography>Have a coupon?</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.couponCodeText} >Click here to enter your code
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
        </Grid> */}
                <CheckoutLayout OrderDetails={<ReviewOrderLayout {...this.props} />} OrderSummary={<OrderSummary nextStep={this.activeStepSubmit} targetStep={this.props.targetStep} />} ViewName={this.props.activeStepName} />
            </React.Fragment>
        );

    }
    componentWillUnmount() {
        // this.props.resetShippingAddressUpdate();
        // this.props.resetPaymentUpdate();
    }
}

/***************************************************************
* Component class for Review Order layout
* @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class ReviewOrderLayout extends React.Component {

    render() {
        const { classes } = this.props;
        const { miniCartDetails } = this.props;
        const { postupdateshippingdetails } = this.props;

        if (this.props.getAddress && Object.keys(this.props.getAddress).length) {
            return <CheckoutWithStyles updatenewAddress={this.props.onShippingOptionsSelect} address={this.props.getAddress} />
        }
        return "Loading...";
        let selectedDeliveryAddress = postupdateshippingdetails.selectedDeliveryAddress ? postupdateshippingdetails.selectedDeliveryAddress : '';

        return (
            <React.Fragment>
                <Grid container alignItems="flex-start" className={classes.customMarginBottom}>
                    <Grid item xs={6} md={7} lg={5}>
                        <Typography variant="body2" gutterBottom>{<GetLanguage value="Delivery Address" />}</Typography>
                        <Grid container spacing={8}>
                            <Grid item>
                                <Typography variant="body1">
                                    {selectedDeliveryAddress.firstname ? selectedDeliveryAddress.firstname : ''}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    {selectedDeliveryAddress.lastname ? selectedDeliveryAddress.lastname : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="caption">
                            {selectedDeliveryAddress.street ? selectedDeliveryAddress.street : ''}
                        </Typography>
                        <Typography variant="caption">
                            {selectedDeliveryAddress.city ? selectedDeliveryAddress.city : ''}
                            {selectedDeliveryAddress.postcode ? selectedDeliveryAddress.postcode : ''}</Typography>
                        <Typography variant="caption">
                            {selectedDeliveryAddress.region ? selectedDeliveryAddress.region.region : ''}
                        </Typography>
                        <Typography variant="caption">
                            {selectedDeliveryAddress.country_id ? selectedDeliveryAddress.country_id : ''}
                        </Typography>
                        {/* <Typography className={classes.showCursor} variant="caption" color="primary" gutterBottom onClick={() => previousAction('2')}>Change</Typography> */}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" gutterBottom>{<GetLanguage value="Expected Delivery Date" />}</Typography>
                        <Typography variant="body1">{<GetLanguage value="30th September'19" />}</Typography>
                        {/* <Typography className={classes.showCursor} variant="caption" color="primary" gutterBottom onClick={() => previousAction('1')}>Change</Typography> */}
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" className={classes.customMarginBottom}>
                    <Grid item  md={10} lg={12}>
                        {
                            miniCartDetails && miniCartDetails["miniCartDetails"] && miniCartDetails["miniCartDetails"].map((product, index) =>
                                <ShoppingCartItemsMiniCart
                                    key={index}
                                    itemIndex={index}
                                    item_id={product.item_id}
                                    productName={product.name}
                                    image_url={product.extension_attributes.image_url}
                                    product_option={product.product_option ? product.product_option : null}
                                    product_url={product.product_url}
                                    sku={product.sku}
                                    itemPrice={formatPriceOfProduct(product.price)}
                                    quantity={product.qty}
                                    onDeleteItemFromCart={() => { }}
                                    {...this.props}>
                                </ShoppingCartItemsMiniCart>
                            )
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

/*********************************************************************
  * Component class for displaying Cart items in Review Order Page.
  * @author   Sameena Shaik <shaik.sameena@embitel.com>
 *********************************************************************/
class ShoppingCartItemsMiniCart extends React.Component {

    processProductOptions = (product_option) => {
        if (product_option === null) return "";
        let configurable_item_options_list = product_option.extension_attributes.configurable_item_options;
        let productOptionsString = [];
        for (let index = 0; index < configurable_item_options_list.length; index++) {
            productOptionsString.push(configurable_item_options_list[index].value);
        }
        //Return product option string with separators.
        return productOptionsString.join(" / ");
    }
    render() {
        const { classes } = this.props;
        const { miniCartDetails } = this.props;
        const {
            itemIndex,
            productName,
            itemPrice,
            image_url,
            product_url,
            product_option,
            quantity
        } = this.props;
        let productCost = itemPrice * quantity;

        //calculate products length
        let itemsInCart = miniCartDetails["miniCartDetails"].length;
        //check if iterating item is not last item in the cart
        let nextItemExists = (itemIndex < itemsInCart - 1);
        //create separator between items
        if (nextItemExists) {
            var itemsDivider = <Divider className={classes.section1} />;
        }
        const productImgDimensions = getConfigValue("product");

        return (
            <Grid container direction="column" className={classes.shoppingCartGrid}>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12} md={10} ls={9}>
                            <Grid container>
                                <Grid item xs={4} md={3} lg={2}>
                                    <ButtonBase className={classes.image}>
                                        <img
                                            className={classes.img}
                                            width={productImgDimensions.image.dimension.width}
                                            height={productImgDimensions.image.dimension.height}
                                            alt={decodeHTMLEntities(productName)}
                                            src={mediaURLBasePath + image_url} />
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction="column" spacing={16}>
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Link className={classes.textDecorationNone} to={product_url}>
                                                        <Typography variant="subtitle1">
                                                            {decodeHTMLEntities(productName)}
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                                <Grid item>
                                                    <ProductOptions product_options={this.processProductOptions(product_option)} />
                                                </Grid>
                                                <Grid item>
                                                    <Hidden mdUp>
                                                        <Typography variant="body2">{<GetLanguage value="Price:" />} ฿{itemPrice}</Typography>
                                                    </Hidden>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={1} lg={1}>
                            <Grid container justify="flex-start" alignItems="flex-start">
                                <Grid item>
                                    <Hidden only={['sm', 'xs']}>
                                        <Typography variant="body2">฿{productCost}</Typography>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    {itemsDivider}
                </Grid>
            </Grid>
        );
    }
}

/***************************************************************
* Component class for displaying configurable options
* @author   Sameena Shaik <shaik.sameena@embitel.com>
***************************************************************/
class ProductOptions extends React.Component {
    render() {
        let { product_options } = this.props;
        return (
            <div>
                <Typography color="textSecondary">{product_options}</Typography>
            </div>
        );
    }
}

//returns total product count
function getProductTotalCount(state_get_minicart_details) {
    if (state_get_minicart_details) {
        return state_get_minicart_details.cart_product_count
    }
    else {
        return 0;
    }
}
//returns total product cost
function getProductTotalCost(state_get_minicart_details) {
    if (state_get_minicart_details) {
        return state_get_minicart_details.cart_product_cost
    }
    else {
        return 0;
    }
}

const mapStateToProps = state => ({
    //get cart info from redux state
    miniCartDetails: state.get_minicart_details,
    getAddress: state.get_shipping_address,
    cart_product_count: getProductTotalCount(state.get_minicart_details),
    cart_product_cost: getProductTotalCost(state.get_minicart_details),
    //get selected payment info from redux state
    postUpdatePaymentOptionDetails: state.post_payment_options_state,
    //get selected address info from redux state
    postupdateshippingdetails: state.post_update_shipping_details,
    //place order status
    putPlaceOrderDetails: state.put_place_order_state,
    loader_status: state.get_loader_status,
    get_order_summary: state.get_order_summary_details,
    getEstimatedDate: state.getEstimatedDate,
});
const mapDispatchToProps = dispatch => ({
    getShippingAddress: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.getShippingAddressActionCreator());
        dispatch(checkoutService.GetEstimatedDeliveryDateActionCreator());
    },
    onShippingOptionsSelect: (address_id, customer_id) => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.setAsDefaultAddressActionCreator(address_id, customer_id));
    },
    resetPaymentUpdate: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.resetPaymentUpdateActionCreator());
    },
    resetShippingAddressUpdate: () => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.resetShippingAddressUpdateActionCreator());
    },
    //Dispatch for placing the order. Selected Payment Option gets passed.
    placeOrder: (selectedPaymentOption, poNumber) => {
        let checkoutService = new CheckoutService();
        dispatch(checkoutService.placeOrderActionCreator(selectedPaymentOption, poNumber));
    },
    loader: () => {
        dispatch({ type: 'SET_LOADER_STATUS' });
    },
    getCheckoutDetails: () => {
        let cs = new CartService();
        dispatch(cs.getOrderSummaryForLoggedInCustomer());
    }
});

class Checkoutnewcomonent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.address.addresses,
            default_billing: props.address.addresses.findIndex(address => address.default_billing),
            order_notes: '',
            poNumber: ''
        }
    }
    componentDidMount() {
        if (localStorage.getItem('order_notes') !== '' | undefined | null) {
            this.setState({ order_notes: localStorage.getItem('order_notes') ? localStorage.getItem('order_notes') : '' });
        }
    }
    getAddress(default_billing) {
        this.setState({ default_billing });
        this.props.updatenewAddress(this.state.address[default_billing].id, this.props.address.id);
    }
    orderNotesSave = (event) => {
        this.setState({ order_notes: event.target.value });
        setItemInLocalStorage('order_notes', event.target.value);
    }
    handlePONumber = (value) => {
        this.setState({ poNumber: value });
        setItemInLocalStorage('poNumber', value);
    }
    render() {
        let { classes } = this.props;
        const { address, default_billing } = this.state;
        const height = 100;
        var regex = new RegExp("^[a-zA-Z0-9]*$");
        return (
            <React.Fragment>
                <Grid item container direction='row'>
                    <Grid item xs={12} style={{ paddingRight: '25px' }}>
                        <Grid container alignItems='flex-end' style={{ paddingBottom: '18px' }}>
                            <Grid item>
                                <Typography className={classes.reviewHeaderText}>{<GetLanguage value="Shipping Address" />}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container wrap='wrap' className={classes.marginBtm20 + ' ' + classes.bgWhite + ' ' + classes.addressWrapper}>
                            {
                                address.map((row, index) => (
                                    <Grid key={index} item xs={12} style={{ padding: '0px 10px 10px 0px', }} sm={6} md={6}>
                                        <div>
                                            {/* {
                                                default_billing === index ? <Typography className={classes.defaulttext}>{row.firstname + '' + row.lastname}</Typography> :
                                                    <Typography className={classes.addresstext}>
                                                        <span>{row.firstname + '' + row.lastname}</span>
                                                   </Typography>
                                            } */}
                                            <div className={classes.defaulttextinner}>
                                                <Grid container wrap='nowrap' alignItems='flex-start'>
                                                    <Grid className="address-list">
                                                        <Radio
                                                            checked={default_billing === index}
                                                            //   onChange={handleChange}
                                                            color="primary"
                                                            onClick={() => this.getAddress(index)}
                                                            value="a"
                                                            style={{ marginRight: '10px' }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                        /></Grid>
                                                    <Grid>
                                                        <Typography>
                                                            {row.firstname + '' + row.lastname}
                                                        </Typography>
                                                        <Typography className={classes.defaulttextheaderinnertext}>{row.street}, {row.city} {row.postcode}, {row.region ? row.region.region : ''}, {row.country_id}</Typography>
                                                        <Typography>{<GetLanguage value="Phone number" />}: {row.telephone}</Typography>
                                                        {/* {row.custom_attributes[0].value && row.custom_attributes[0].attribute_code && row.custom_attributes[0].value !== "" &&
                                                            <Typography>{<GetLanguage value="Address ID" />}: {row.custom_attributes[0].value}</Typography>
                                                        } */}
                                                    </Grid>
                                                </Grid>

                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Grid container direction='column'>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBtm20}>
                                <Typography className={classes.bldTxt}>
                                    {<GetLanguage value="PO number" />}:
                                </Typography>
                                <TextField className={classes.bgWhite + " " + classes.poNumertext}
                                    onInput={(e) => { e.target.value = e.target.value.toString().slice(0, 15) }}
                                    margin="dense" variant="outlined" value={this.state.poNumber} onChange={(e) => this.handlePONumber(e.target.value)}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBtm20}>
                                <Typography className={classes.bldTxt}>{<GetLanguage value="Order notes (optional)" />}:</Typography>
                                <TextField className={classes.bgWhite} style={{ width: '100%' }} id="outlined-multiline-flexible" multiline
                                    placeholder="Notes about your order, e.g. special notes for delivery."
                                    rowsMax="8" margin="dense" variant="outlined"
                                    inputProps={{
                                        maxLength: 50,
                                        style: {
                                            height,
                                        },
                                    }}
                                    onChange={this.orderNotesSave}
                                    value={this.state.order_notes}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBtm20}>
                                <Typography gutterBottom>
                                    {<GetLanguage value="Estimate Delivery" />}:
                                    <span style={{ fontWeight: 'bold' }}>{this.props.getEstimatedDate}</span></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </React.Fragment>
        )
    }
}
const CheckoutWithStyles = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Checkoutnewcomonent));
const ReviewOrderWithStyles = withStyles(styles)(ReviewOrder);
export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrderWithStyles);