/**
* ResetPasswordForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import UserMessage from '../../../../app/components/messages/UserMessages';
import Card from '@material-ui/core/Card';
import PageTitle from '../../../../app/components/common/PageTitle';
import CardContent from '@material-ui/core/CardContent';
import AccountService from '../../../../app/modules/account/AccountService';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import GetLanguage from '../../../../library/GetLanguage';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    }
});

/***************************************************************
 * Component class for displaying Reset Password Form.
 * On submit user can reset password of respective account
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        // Before displaying pre-populated email, check if localStorage has 
        // data for email, name and token.
        this.state = {
            resetPassword: '',
            confirmResetPassword: '',
            resetPasswordToken: null,
            showPassword: false,
            showConfirmPassword: false,
            //  passwordValidation:{
            upperCase: false,
            lowerCase: false,
            specialCharacter: false,
            number: false,
            length: false,
            confirmPassword: false,
            resetClicked: false,
            // isPasswordValid:false,
            // isPasswordValid:false,
            // isConfirmPasswordValid:false,
            // }

        };
    }

    // Check if the password reset token is valid.
    componentDidMount() {
        var queryParams = new URLSearchParams(this.props.location.search);
        let resetPasswordToken = queryParams.get('token');
        this.setState({ resetPasswordToken: resetPasswordToken });
        //Dispatch action
        this.props.validateTokenDispatch(
            queryParams.get('id'),
            queryParams.get('token')
        );
    }
    //updates email and password fields with provided values
    handleChange = prop => event => {
        var regexUpper = /^.*[A-Z].*/;
        var regexlower = /^.*[a-z].*/;
        var regexNumber = /^.*[1-9].*/;
        var specialCharacter = /^.*[^`!@#$%^&*\-_=+'\/.,]*[`!@#$%^&*\-_=+'\/.,].*/;
        //var specialCharacter=/^.*[$&+,:;=?@#|'<>.-^*()%!].*/;
        var regexLength = /^.{8,}$/;

        var info = event.target.value.match(regexUpper);

        if (event.target.value.match(regexUpper) === null) {
            this.setState({ upperCase: true });
        }
        else {
            this.setState({ upperCase: false });
        }
        if (event.target.value.match(regexlower) === null) {
            this.setState({ lowerCase: true });
        }
        else { this.setState({ lowerCase: false }); }
        if (event.target.value.match(regexNumber) === null) {
            this.setState({ number: true });
        }
        else { this.setState({ number: false }); }
        if (event.target.value.match(specialCharacter) === null) {
            this.setState({ specialCharacter: true });
        }
        else { this.setState({ specialCharacter: false }); }
        if (event.target.value.match(regexLength) === null) {
            this.setState({ length: true });
        }
        else { this.setState({ length: false }); }

        this.setState({ resetClicked: false });



        this.setState({ [prop]: event.target.value });
    };

    handleChange1 = prop => event => {

        this.setState({ [prop]: event.target.value });
    };
    //hide/show text in password field
    handleClickShowPassword = () => {

        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    //hide/show text in password field
    handleClickShowConfirmPassword = () => {

        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };
    //call dispatch function if email and password fields are not empty
    handleResetPassword = (event) => {
        event.preventDefault();





        // confirmPassword:false,
        // resetClicked:false,

        if (!this.state.upperCase && !this.state.lowerCase && !this.state.specialCharacter && !this.state.number && !this.state.length ) {
      
            if (this.state.resetPassword !== this.state.confirmResetPassword) {
                return this.setState({ confirmPassword: true });
                
            }
            else {
                this.setState({ confirmPassword: false });
                //   event.preventDefault();
                //Dispatch action to Sign-In the user.
                const {
                    confirmResetPassword,
                    resetPasswordToken
                } = this.state;

                const { validateTokenEmail } = this.props;

                if ((confirmResetPassword !== "")) {
                    //Dispatch action to reset the password
                    this.props.onSubmitResetPasswordDispatch(
                        confirmResetPassword,
                        resetPasswordToken,
                        validateTokenEmail
                    );
                }
            }

        }

        this.setState({ resetClicked: true });
    };

    render() {
        const { classes } = this.props;
        const { resetPasswordStatus, isResetButtonDisabled } = this.props;
        //If Error while resetting the password, display error message.
        if (resetPasswordStatus === false) {
            return (
                <Redirect push to="/account" />
            );
        }
        //If Success while resetting the password, redirect to sign-in page.
        if (resetPasswordStatus === true) {
            return (
                <Redirect push to="/account" />
            );
        }
        //If validating token for Reset Password fails, display Message
        if (this.props.validateTokenStatus === false) {
            return (
                <Redirect push to="/account" />
            );
        }
        //If validating token for Reset Password is success, display form for entry
        //of new password
        else if (this.props.validateTokenStatus === true) {
            return (
                <React.Fragment>
                    <PageTitle title={<GetLanguage value="Reset Password"/>} />
                    <Typography variant="body2" align="center" gutterBottom>
                        {<GetLanguage value="Please enter your password below to reset."/>}
                    </Typography>
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs sm={8} md={5} lg={3}>
                            <Card className={classes.cardStyle}>
                                <CardContent>
                                    <form onSubmit={this.handleResetPassword}>
                                        <Grid container spacing={16} direction="column">
                                            <Grid item>
                                                <Grid container spacing={8} direction="column">
                                                    <Grid item>
                                                        <FormControl margin="normal" required fullWidth>
                                                            <InputLabel htmlFor="adornment-resetPassword" style={{ color: '#000000' }}>
                                                            {<GetLanguage value="Password"/>}
                                                            </InputLabel>
                                                            <Input id="adornment-resetPassword" type={this.state.showPassword ? 'text' : 'password'}
                                                                InputLabelProps={{

                                                                }}
                                                                value={this.state.resetPassword} onChange={this.handleChange('resetPassword')}
                                                                required={true}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                                                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>

                                                    </Grid>
                                                    {this.state.upperCase && this.state.resetClicked &&
                                                        <Grid item>
                                                            <Typography variant="subtitle1" color="primary">
                                                            {<GetLanguage value="1 Uppercase is required"/>}
                                        </Typography>
                                                        </Grid>
                                                    }
                                                    {this.state.lowerCase && this.state.resetClicked &&
                                                        <Grid item>
                                                            <Typography variant="subtitle1" color="primary">
                                                            {<GetLanguage value="1 Lowercase is required"/>}
                                        </Typography>
                                                        </Grid>
                                                    }
                                                    {this.state.number && this.state.resetClicked &&
                                                        <Grid item>
                                                            <Typography variant="subtitle1" color="primary">
                                                            {<GetLanguage value="1 Number is required"/>}
                                                 </Typography>
                                                        </Grid>
                                                    }
                                                    {this.state.specialCharacter && this.state.resetClicked &&
                                                        <Grid item>
                                                            <Typography variant="subtitle1" color="primary">
                                                            {<GetLanguage value="1 Special character is required"/>}
                                        </Typography>
                                                        </Grid>
                                                    }
                                                    {this.state.length && this.state.resetClicked &&
                                                        <Grid item>
                                                            <Typography variant="subtitle1" color="primary">
                                                            {<GetLanguage value="Password is minimum of 8 characters"/>}
                                        </Typography>
                                                        </Grid>
                                                    }
                                                </Grid>

                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={8} direction="column">
                                                    <Grid item>

                                                        <FormControl margin="normal" fullWidth>
                                                            <InputLabel htmlFor="adornment-confirmResetPassword" style={{ color: '#000000' }}>
                                                            {<GetLanguage value="Confirm Password"/>}
                                                            </InputLabel>
                                                            <Input id="adornment-confirmResetPassword" type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                                value={this.state.confirmResetPassword} onChange={this.handleChange1('confirmResetPassword')}
                                                               endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowConfirmPassword}>
                                                                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {this.state.confirmPassword && 
                                                        <Grid item>
                                                            <Typography variant="subtitle1" color="primary">
                                                            {<GetLanguage value="Password and confirm Password must match"/>} 
                                        </Typography>
                                                        </Grid>
                                                    }

                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button fullWidth type="submit" color="primary" aria-label="Reset" variant="contained"
                                                    disabled={isResetButtonDisabled} size="large">{<GetLanguage value="Reset Password"/>} </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        //Default render
        else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    validateTokenStatus: state.reset_password_state.validateTokenresetPasswordStatus,
    validateTokenEmail: state.reset_password_state.validateTokenresetPasswordEmail,
    validateTokenError: state.reset_password_state.validateTokenerrorMessage ? state.reset_password_state.validateTokenerrorMessage : '',

    resetPasswordStatus: state.result_reset_forgot_password_state.resetPasswordStatus,
    resetPasswordError: state.reset_password_state.errorMessage ? state.reset_password_state.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    onSubmitResetPasswordDispatch: (
        confirmResetPassword,
        resetPasswordToken,
        validateTokenEmail
    ) => {
        var ResetPasswordObject = {
            "confirmResetPassword": confirmResetPassword,
            "resetPasswordToken": resetPasswordToken,
            "validateTokenEmail": validateTokenEmail
        };
        let as = new AccountService();
        dispatch(as.resetPasswordActionCreator(ResetPasswordObject));
    },
    validateTokenDispatch: (
        customerId,
        resetToken,
    ) => {
        var ValidateTokenObject = {
            "customerId": customerId,
            "resetToken": resetToken
        };
        let as = new AccountService();
        dispatch(as.validateTokenActionCreator(ValidateTokenObject));
    }
}
);

const ResetPasswordFormWithStyles = withStyles(styles)(ResetPasswordForm);
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordFormWithStyles);


