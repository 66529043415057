/**
* Main application component
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';

import { getItemFromLocalStorage } from './app/framework/storage/local/localStorageHandler';
import URLbasedRouteComponent from './extension/framework/router/URLbasedRouteComponent';
import AccountService from './app/modules/account/AccountService';
const mainDivStyle = {
  display: 'block',
  width: '100%',
  marginTop: '100px',
  backgroundColor: '#ffffff',
}

/***************************************************************************** 
 * Main App Component. Child Components include Headerbar, Main
 * Content Area and Footer. <BaseContainer /> is used to wrap the 
 * Content in order to apply styles like margins.
 * If there is a Magento Path for the route, it is provided in 
 * the state as magento_path.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      magento_path: "/",
    };
    //get shopce-user from localstorage
    let userInfo = getItemFromLocalStorage('shopce-user');
    //get salesman-user from localstorage
     let salesmanInfo = getItemFromLocalStorage('salesman-user');
    if (salesmanInfo) {
      //Dispatch action only if token exists
      this.props.getStoreDetails(salesmanInfo);
    }
    //check if token exists or not
    let userToken = userInfo ? userInfo.token : '';
    if (userToken) {
      //Dispatch action only if token exists
      this.props.isTokenValidDispatch(userToken);
    }
  }
  setMagentoURLPath = magento_path => {
    this.setState({ magento_path });
  };
  render() {
    // clear localStorage and cookies if token is not valid
    if (this.props.isTokenValid && typeof this.props.isTokenValid.status !== 'undefined'
      && !(this.props.isTokenValid.status)) {
      AccountService.clearOnSignOut();
    }
    const MainComponent = (props) => (
      <div id="pageMain" className="pageMain">
        <URLbasedRouteComponent></URLbasedRouteComponent>
      </div>
    );
    return (
      <MainComponent />
    );
  }
}

const mapStateToProps = state => ({
  targetURLPath: state.targetPathState.targetURLPath,
  targetPathType: state.targetPathState.targetPathType,
  isTokenValid: state.is_token_valid,
});

const mapDispatchToProps = dispatch => ({
  isTokenValidDispatch: (userToken) => {
    let as = new AccountService();
    dispatch(as.IsTokenValidActionCreator(userToken));
  },
  getStoreDetails: (token) => {
    let as = new AccountService();
    dispatch(as.GetSalesManStores(token));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(App);
