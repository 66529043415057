/**
* Shopping Options Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SubmenuFilter from '../../category/filters/SubmenuFilter';
//import SliderFilter from './SliderFilter';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import CategoryService from '../../../../app/modules/category/CategoryService';
import { log } from 'util';
import CheckboxFilter from '../../category/filters/CheckboxFilter';

const styles = theme => ({
    cardStyle: {
        borderRadius: 2,
        border: '1px solid #E6E7ED',
        margin: '1rem 0rem',
    },
    paper: {
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    shoppingOptionsText: {
        paddingTop: "2rem",
    },
    priceSlider: {
        padding: "2rem 0rem",
    }
});

/***************************************************************
 * Component class for displaying all Shopping Options.
 * @author   Swathy Sagar <shaik.sameena@embitel.com>
 ***************************************************************/
class FilterInSearch extends React.Component {

    render() {
        let { classes } = this.props;
       
        var category =  this.props.filterForSearch!==undefined && this.props.filterForSearch;
     // if (this.props.categoryFilter[0]) {
            var filterOptions = category && Object.keys(category);
        // }
        return (
            <Paper className={classes.paper} style={{ boxShadow: "none" }}>
                {filterOptions && filterOptions.map((option, index) => {
                    //data of filter type will be stored in optionsObj
                    let optionObj = category[option];
                    //array of filter type Id's will be stored in filterOptionIds
                    let filterOptionIds = Object.keys(optionObj);
                    //filterObj is sent to respective component which is used for selection purpose
                    let filterObj = { filterOptionName: option, filterOptionId: filterOptionIds[0], categoryId: this.props.categoryId };
                    return (
                    (option === 'Price') ? (""):
                        option === 'Category' ? '' :
                            <React.Fragment key={index}>
                                <Typography variant="h6" align="left" className={classes.shoppingOptionsText} gutterBottom>
                                    {option}
                                </Typography>
                                <CheckboxFilter filterObject={filterObj} optionValues={optionObj} />
                            </React.Fragment>);
                }
                )
                }
            </Paper>
        );
    }
}

/****************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    categoryFilter: state.get_category_filter_options
});

const mapDispatchToProps = dispatch => ({
    getCategoryFilters: (categoryId) => {
        let cs = new CategoryService();       
        dispatch(cs.getCategoryFilterOptionsActionCreator(categoryId));
    }
});

const ShoppingOptionsWithStyles = withStyles(styles)(FilterInSearch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingOptionsWithStyles));