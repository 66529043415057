/**
* Component for displaying Desktop Menu.
* Uses NavigationDesktopUnit child component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NavigationDesktopUnit from './NavigationDesktopUnit';
import CategoryService from '../../../modules/category/CategoryService';
//import ErrorBoundary from 'react-error-boundary';
import { withErrorBoundary } from 'react-error-boundary';


const styles = theme => ({
    root: {
        display: 'flex',
        marginTop: '-5px',
        padding: '0rem 0rem 0rem 1.25rem',
        maxWidth: '100% !important',
        backgroundColor: '#F0F0F0',
        borderBottom: '1px solid #eeeeee',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    },
    popperClose: {
        pointerEvents: 'none',
    },
    contentLoader: {
        height: '48px',
        backgroundColor: '#F0F0F0',
    }
});

/***************************************************************
 * Component class for displaying Category Menu.
 * Receives data from redux state in the form of props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ***************************************************************/
class NavigationDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // Dispatch action to Redux to fetch category data
    componentDidMount() {
        /**
         * When the app is loaded for first time, the category
         * navigation menu data is fetched. Subsequent page transitions
         * will not fetch the category data again and the redux stored
         * menu data is used. 
         */
        if (typeof (this.props.categoryData) === 'undefined') {
            this.props.getCategoryData();
        }
    }

    //Get the submenu at Level 3 for the given menu.
    handleSubMenu = (cat) => {
        if (cat["children_data"] !== []) {
            let subCat = [];
            cat["children_data"] && cat["children_data"].map((sub_cat_item, index) => {
                return subCat.push([sub_cat_item.name, sub_cat_item.id, sub_cat_item.url, sub_cat_item.children_data]);
            })
            return subCat;
        }
        else {
            return [];
        }
    }

    render() {
        const { classes } = this.props;
        const { categoryData } = this.props;                

        // If there is no category data (or data is being fetched), display
        // placeholder for category menu.
        if (!categoryData) {
            return (
                <div className={classes.contentLoader}></div>
            );
        }

        return (
                <div className={classes.root}>
                    {
                        categoryData && categoryData.map((cat, index) => (
                            <NavigationDesktopUnit key={index} topmenu={[cat.name, cat.id, cat.url, cat.children_data]} submenu={this.handleSubMenu(cat)}></NavigationDesktopUnit>
                        ))
                    }
                </div>            
        );
    }
}


NavigationDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    categoryData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ])
};

const NavigationErrorFallbackComponent = ({ componentStack, error }) => {
    return null;
}

const NavigationDesktopWithErrorBoundary = withErrorBoundary(
    NavigationDesktop,
    NavigationErrorFallbackComponent    
);



/***************************************************************************** 
 * Redux mapping of state and dispatches to props.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
const mapStateToProps = state => ({
    categoryData: state.categoryDataState.categoryData
});

const mapDispatchToProps = dispatch => ({
    getCategoryData: () => {
        let categoryService = new CategoryService();
        dispatch(categoryService.getCategoryDataForMenuActionCreator());
    }
});

const NavigationDesktopWithConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationDesktopWithErrorBoundary);

export default withStyles(styles)(NavigationDesktopWithConnect);




