/**
* Application Service class with functions for methods for
* handling app level APIs.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import {
    REQUEST_GET_TARGET_PATH,
    RECEIVE_GET_TARGET_PATH,
    SET_TARGET_PATH_DEFAULT,
    REQUEST_GET_LANGUAGE_OBJECT,
    RECEIVE_GET_LANGUAGE_OBJECT,
    REQUEST_SET_PLP_VIEW,
    RECEIVE_SET_PLP_VIEW,
    REQUEST_GET_USP,
    RECEIVE_GET_USP,
} from '../../framework/common/ReduxConstants';
import getConfigValue from '../../framework/config/configHandler';
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import Axios from 'axios';

class ApplicationService {

    /******************************************************************* 
     * Actions for Redux Dispatch for getting target Path.
     * Calls REST API
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     *******************************************************************/
    getTargetURLPathActionCreator(sourcePath) {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_TARGET_PATH });
            let requestURL = '/requesturl'.concat("/", sourcePath);
            let requestType = 'GET';
            let requestBody = {};
            requestBody.data = {
            };
            requestBody.apiEndPoint = 'thailand';
            const systemParameters = getConfigValue("system");
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_TARGET_PATH, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_TARGET_PATH, success: false, error });
                }
            );
        };
    }

    /***************************************************************************** 
     * @param {*} state - No modifications
     * @param {*} action - targetURLPath and target targetPathType are returned
     * In case the REST API response does not contain the data, returns null.
     * In case "failure" response is received from API, targetPathType is returned
     * with 404 (PageNotFound).
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     *****************************************************************************/
    static applyTargetPath = (state, action) => {
        let targetPath = null;
        let targetPathType = null;
        if (action.response && (action.response.data["0"].status === "success")) {
            targetPath = action.response.data["0"].data["0"].target_path;
            targetPathType = action.response.data["0"].type;
        }
        return {
            "targetURLPath": targetPath,
            "targetPathType": targetPathType,
        }
    }

    /**********************************************************************
     * @param {*} state - No modifications
     * @param {*} action - action is processed to get targetPath Details
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static PostTargetPathReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_TARGET_PATH:
                let retValue = ApplicationService.applyTargetPath(state, action);
                return retValue;
            case SET_TARGET_PATH_DEFAULT: return [];
            default:
                return state;
        }
    };

    /******************************************************************* 
     * Actions for Redux Dispatch for getting csv parsed.
     * @author  Sameena Shaik<sameena.shaik@embitel.com>
     *******************************************************************/
    parseLanguageCSVActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_LANGUAGE_OBJECT });

            Axios.get('/languageIndia.csv',
                { responseType: 'text' }).then(response => dispatch({ type: RECEIVE_GET_LANGUAGE_OBJECT, success: true, response }));
            // let requestURL = '/languageIndia.csv';
            // let requestType = 'GET';
            // let requestBody = {};
            // requestBody.data = {
            // };
            // requestBody.apiEndPoint = 'thailand';
            // const systemParameters = getConfigValue("system");
            // let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.LOW };
            // MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
            //     (response) => {
            //         dispatch({ type: RECEIVE_GET_LANGUAGE_OBJECT, success: true, response });
            //     },
            //     (error) => {
            //         dispatch({ type: RECEIVE_GET_LANGUAGE_OBJECT, success: false, error });
            //     }
            // );
        };
    }

    /***************************************************************************** 
     * @param {*} state - No modifications
     * @param {*} action - return parsed response
     * @author  Sameena Shaik<sameena.shaik@embitel.com>
     *****************************************************************************/
    static applyLanguageParser = (state, action) => {
        if (action.response && action.success) {
            let dataArray = action.response.data.replace(/\n/g, ' ').split(' ');
            let dataKeys = dataArray[0].split(',');
            let dataValues = dataArray[1].split(',');
            let dataObject = [];
            for (let i = 0; i < dataKeys.length; i++) {
                dataObject.push({ key: dataKeys[i], value: dataValues[i] });
            }
            return dataObject;
        }
        return null;
    }

    /**********************************************************************
     * @param {*} state - No modifications
     * @param {*} action - action is processed to get targetPath Details
     * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
     **********************************************************************/
    static PostLanguageReducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_GET_LANGUAGE_OBJECT:
                let retValue = ApplicationService.applyLanguageParser(state, action);
                return retValue;
            default:
                return state;
        }
    };

    /******************************************************************* 
     * Actions for Redux Dispatch for setting PLP View.
     * @author  Sameena Shaik<sameena.shaik@embitel.com>
     *******************************************************************/
    setPLPViewActionCreator(index) {
        return (dispatch) => {
            dispatch({ type: REQUEST_SET_PLP_VIEW });
            dispatch({ type: RECEIVE_SET_PLP_VIEW, success: true, index });
        };
    }

    /**********************************************************************
     * @param {*} state - No modifications
     * @param {*} action - action is processed to set index
     * @author  Sameena Shaik<sameena.shaik@embitel.com>
     **********************************************************************/    

    static SetPLPViewReducer = (state = [], action) => {
        const index=localStorage.getItem('plpview');
        switch (action.type) {
            case REQUEST_SET_PLP_VIEW:
                return 0;
            case RECEIVE_SET_PLP_VIEW:
                if (action.success)
                    return action.index;
                else
                    return index || index ? parseInt(index) : 0;
            default:
                return index || index ? parseInt(index) : 0;
        }
    };

    GetUSPActionCreator() {
        return (dispatch) => {
            dispatch({ type: REQUEST_GET_USP });

            const systemParameters = getConfigValue("system");
            let websiteId = window.location.hostname === 'usmileshoppro.com'? systemParameters.store[0].websiteId:systemParameters.store[1].websiteId;

            let requestURL = '/ecs/ups/'+websiteId;
            let requestType = 'GET';
            let requestBody = {};
            requestBody.data = {
            };
            requestBody.apiEndPoint = 'thailand';
            let apiLevelConfig = { apiLevel: systemParameters.api.error.levels.CRITICAL };
            MagnaAPIHandler(requestURL, requestType, requestBody, apiLevelConfig).then(
                (response) => {
                    dispatch({ type: RECEIVE_GET_USP, success: true, response });
                },
                (error) => {
                    dispatch({ type: RECEIVE_GET_USP, success: false, error });
                }
            );
        };
    }
    static GetUSPReducer = (state ='',action) => {
        switch (action.type) {
            case RECEIVE_GET_USP:
                if (action.success) {
                    return action.response? action.response.data :'';
                }
                else
                    return state;
            default:
                return state;
        }
    }
}

export default ApplicationService;