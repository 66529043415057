/**
* Price Filter Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import '../../../../../src/assets/css/react-input-range/index.css';
import { withRouter } from "react-router";
import InputRange from 'react-input-range';

/***************************************************************************** 
 * Price Filter Component. To display price filter option
 * in the form of slider on left side.
 * @author Sameena Shaik <shaik.sameena@embitel.com>
 *****************************************************************************/
class SliderFilter extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.optionValues) {
      let priceRangeValues = Object.values(this.props.optionValues);

      this.state = {
        initialPrice: {
          max: this.getPriceRange('price_to') ? this.getPriceRange('price_to'): parseInt(priceRangeValues[0].max_price,10),
          min: this.getPriceRange('price_from') ? this.getPriceRange('price_from'): parseInt(priceRangeValues[0].min_price,10),
        },
        value: { 
          max: this.getPriceRange('price_to') ? this.getPriceRange('price_to'):
                parseInt(priceRangeValues[0].max_price,10), 
          min: this.getPriceRange('price_from') ? this.getPriceRange('price_from'):
                parseInt(priceRangeValues[0].min_price,10),
        },
      };
    }
  }
  //handle change in price slider and add/append/update 
  //price range values to query params
  handleChange = (value) => event => {

    let queryParams = new URLSearchParams(this.props.location.search);
    queryParams.set('price_from', value.min);
    queryParams.set('price_to', value.max);

    // Push the key/value pairs in an array
    let queryArray = [];
    for (var pair of queryParams.entries()) {
      queryArray.push(pair[0] + '=' + pair[1]);
    }

    let filterLocation = '?' + queryArray.join('&');
    this.props.history.push(filterLocation);

  };
  //get price mix and max values from url if exists
  getPriceRange(key) {
    let url = new URL(window.location.href);
    return parseInt(url.searchParams.get(key),10);
  }
  render() {
    //calculate range based on min and max values
    let range = Math.floor((this.state.initialPrice.max-this.state.initialPrice.min)/10);
    return (
      <div style={{padding:'0rem 0.5rem'}}>
      <InputRange
        draggableTrack  step={range? range: 1} formatLabel={value => `฿${value}`}
        maxValue= {this.state.initialPrice.max} minValue= {this.state.initialPrice.min} 
        onChange={value => this.setState({ value })}
        onChangeComplete={this.handleChange(this.state.value)}
        value={this.state.value} />
      </div>
    );
  }
}


export default withRouter(SliderFilter);