/**
* AccountInformation Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import magnaCookies from '../../../../app/framework/cookie/magnaCookies';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import FormControl from '@material-ui/core/FormControl';

import MyAccount from './MyAccount';
import MyOrders from '../myorders/MyOrders';
import MyProfile from '../myprofile/MyProfile';
import MyAddresslist from '../../address/AddressList';
import RequisitionList from '../../requisition/RequisitionList';
import ViewRequisition from '../../requisition/ViewRequisition';
import AccountService from '../../../../app/modules/account/AccountService';
import GetLanguage from '../../../../library/GetLanguage';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    mobileAppBar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    defaultMenuItemStyle: {
        color: '#000000',
        fontSize:'14px',
        fontWeight: '500',
        // '&:hover': {
        //     background: '#f50057',
        //     color: '#ffffff',
        // },
        // '&:focus': {
        //     background: '#f50057',
        //     color: '#ffffff',
        // },
    },
    activeMenuItemStyle: {
        background: '#f50057',
        color: '#ffffff',
        fontSize:'14px',
        fontWeight: '500',
        '&:hover': {
            background: '#f50057',
        },
        '&:focus': {
            background: '#f50057',
        },
    }
});
/***************************************************************
 * Component class for displaying Account info.
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class AccountInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: '',
        };
    }
    componentDidMount() {
        if(this.props.location && this.props.location.state) {
            //set passed prop as default index        
            this.props.setSubMenu(this.props.location.state.submenu);
        }
        else {
            //set my accounts as default index        
            this.props.setSubMenu(0);
        }
    }
    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    }
    //handles closing of menu
    handleClose = event => {
        this.setState({ open: false });
    };
    handleChange = (event) => {
        this.props.setSubMenu(event.target.value);
    };
    componentWillReceiveProps(nextProps) {
        if(nextProps &&nextProps.location.state 
            && this.props.subMenuIndex !== nextProps.location.state.submenu) {
            //set passed prop as default index  
            if(this.props.subMenuIndex==='')      
            this.props.setSubMenu(nextProps.location.state.submenu);
            else if(nextProps.location.state.submenu!==this.props.location.state.submenu){
                this.props.setSubMenu(nextProps.location.state.submenu)
            }
        }
        else if(nextProps.location.state.submenu!==this.props.subMenuIndex&&this.props.location.state.submenu===nextProps.location.state.submenu){
            this.props.setSubMenu(nextProps.location.state.submenu)
        }
        // else if(nextProps.location.state.submenu===this.props.location.state.submenu&&nextProps.location.state.submenu!==this.props.subMenuIndex){
        //     this.props.setSubMenu(nextProps.location.state.submenu)
        // }
        if (nextProps.createdRequisitionList !== '' &&
            nextProps.createdRequisitionList && this.state.open) {
                this.props.setSubMenu(4);
        }
    }
    render() {
        let { classes, subMenuIndex } = this.props;
        let signInStatus = magnaCookies.getCookie('ECS_AC');

        const AccountComponentSelector = [
            {
                "type": "My Account",
                "component": <MyAccount />,
            },
            {
                "type": "Address Book",
                "component": <MyAddresslist />,
            },
            {
                "type": "My Profile",
                "component": <MyProfile />,
            },
            {
                "type": "My Orders",
                "component": <MyOrders />,
            },
            {
                "type": "My Requisition List",
                "component": <RequisitionList />,
            },
            {
                "type": "View Requisition Details",
                "component": <ViewRequisition />,
            },
        ];
        if (typeof signInStatus === 'undefined' || signInStatus === '') {
            return (
                <Redirect push to="/account" />
            );
        }
        return (
            <Grid container justify='center' spacing={16}>
                {/* For devices with less width, hide the Left Shopping Options */}
                <Hidden only={['sm', 'xs']}>
                    <Grid item md={2}>
                        <Paper>
                            <MenuList>
                                <MenuItem value={0} onClick={this.handleChange} 
                                className={(subMenuIndex === 0 ||subMenuIndex === 2 || subMenuIndex === 1) ? 
                                classes.activeMenuItemStyle : classes.defaultMenuItemStyle}>{<GetLanguage value="My Account"/>}</MenuItem>
                                <MenuItem value={3} onClick={this.handleChange} 
                                className={subMenuIndex === 3 ? classes.activeMenuItemStyle : classes.defaultMenuItemStyle}>{<GetLanguage value="My Orders"/>}</MenuItem>
                                <MenuItem value={4} onClick={this.handleChange}
                                className={(subMenuIndex === 4 || subMenuIndex === 5) ? classes.activeMenuItemStyle : classes.defaultMenuItemStyle}>{<GetLanguage value="My Requisition List"/>}</MenuItem>
                            </MenuList>
                        </Paper>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={12}>
                        <Grid container justify='center'>
                            <Grid item>
                                <FormControl>
                                    <Select open={this.state.open} onClose={this.handleClose} onOpen={this.handleToggle}
                                        MenuProps={{getContentAnchorEl: null,anchorOrigin: {vertical: "bottom",horizontal: "left",}}}
                                        onChange={this.handleChange} value={(subMenuIndex === 2 || subMenuIndex === 1)? 0 :  subMenuIndex === 5 ? 4 :subMenuIndex}>
                                        <MenuItem value={0} 
                                        className={(subMenuIndex === 0 ||subMenuIndex === 2 || subMenuIndex === 1) ? 
                                        classes.activeMenuItemStyle : classes.defaultMenuItemStyle}>{<GetLanguage value="My Account"/>}</MenuItem>
                                        <MenuItem value={3} className={subMenuIndex === 3 ? 
                                            classes.activeMenuItemStyle : classes.defaultMenuItemStyle}>{<GetLanguage value="My Orders"/>}</MenuItem>
                                        <MenuItem value={4} className={(subMenuIndex === 4 || subMenuIndex === 5) ? 
                                            classes.activeMenuItemStyle : classes.defaultMenuItemStyle}>{<GetLanguage value="My Requisition List"/>}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                {AccountComponentSelector.map((obj, index) => {
                    if (index === subMenuIndex) {
                        return (
                            <Grid item xs={12} md={10} key={index}>{obj.component}</Grid>
                        );
                    }
                })}
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    subMenuIndex: state.set_sub_menu_state,
});

const mapDispatchToProps = dispatch => ({
    setSubMenu: (index) => {
        const as = new AccountService();
        dispatch(as.setSubMenuActionCreator(index));
    }
}
);
const AccountInformationWithStyles = withStyles(styles)(AccountInformation);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInformationWithStyles));