/**
* RegistrationService class with functions for handling Registration related
* functions.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Swathy Sagar <swathy.sagar@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/
import MagnaAPIHandler from '../../framework/api/MagnaAPIHandler';
import getConfigValue from '../../framework/config/configHandler';
import { NotificationManager } from 'react-notifications';
import { RECEIVE_POST_MOBILE_NUMBER, REQUEST_POST_MOBILE_NUMBER,
RECEIVE_POST_MOBILE_OTP, REQUEST_POST_MOBILE_OTP, REQUEST_POST_REGISTRATION, 
RECEIVE_POST_REGISTRATION,SET_LOADER_STATUS } from '../../framework/common/ReduxConstants';
class RegistrationService{
    /**********************************************************************************
     * Action Creator for Registration-MobileNumber functionality.
     *********************************************************************************/
    RegisteredMobNumActionCreator(RegisteredMobNumObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_POST_MOBILE_NUMBER });
            dispatch({ type: SET_LOADER_STATUS });
            let requestBody = {};
            requestBody.data = {
                "number": RegisteredMobNumObject.mobileNumber,
                "customerId": RegisteredMobNumObject.customerId
            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/ecs/sendotp';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for Post OTP Request  
                    dispatch({ type: RECEIVE_POST_MOBILE_NUMBER, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                    NotificationManager.success('You will receive an OTP on your registered mobile number');
                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_MOBILE_NUMBER, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }
    /**********************************************************************************
     * Action Creator for Registration-MobileNumber functionality.
     *********************************************************************************/
    OTPActionCreator(OTPObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_POST_MOBILE_OTP });
            dispatch({ type: SET_LOADER_STATUS });
            let requestBody = {};
            requestBody.data = {
                "customerId":OTPObject.customerId,
                "number":OTPObject.mobileNumber,
                "otp": OTPObject.otp,
            }
            // requestBody.data = {
            //     "customerId":'5',
            //     "number":'9876543210',
            //     "otp":'1234',
            // }
            
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/ecs/verifyotp';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for Forgot Password 
                    dispatch({ type: RECEIVE_POST_MOBILE_OTP, success: true, response });
                    dispatch({ type: SET_LOADER_STATUS });
                    NotificationManager.success('OTP verification successful');
                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_MOBILE_OTP, success: false, error });
                    dispatch({ type: SET_LOADER_STATUS });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }
    /**********************************************************************************
     * Action Creator for Registration functionality.
     *********************************************************************************/
    registrationActionCreator(RegistrationObject) {
        return (dispatch) => {
            dispatch({ type: REQUEST_POST_REGISTRATION });
            let requestBody = {};
            requestBody.data = {
                "firstname": RegistrationObject.customer.firstname,
                "lastname": RegistrationObject.customer.lastname,
                "email": RegistrationObject.customer.email,
                "password": RegistrationObject.password,

            }
            requestBody.apiEndPoint = 'thailand';
            let requestType = 'POST';
            let requestURL = '/customers/resetPassword';
            MagnaAPIHandler(requestURL, requestType, requestBody).then(
                (response) => {
                    //Send success dispatch for Forgot Password 
                    dispatch({ type: RECEIVE_POST_REGISTRATION, success: true, response });
                    //NotificationManager.success('You will receive an OTP on your registered mobile number');
                },
                (error) => {
                    dispatch({ type: RECEIVE_POST_REGISTRATION, success: false, error });
                    let genericError = 'Error, Please try again after some time';
                    let errorMessage = error.data ? (error.data.message ? error.data.message : genericError) : genericError;
                    NotificationManager.error(errorMessage);
                }
            );
        };
    }
    /************************************************************************************
     * Registered Mobile Number POST request reducer.
     ************************************************************************************/
    static post_registered_mobile_number_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_MOBILE_NUMBER:
                let retValue = RegistrationService.applyRegisteredMobile(state, action);
                return retValue;
            case REQUEST_POST_MOBILE_NUMBER:
                return { ...state, registeredMobileNumberSuccess: null, isGetOTPButtonDisabled: true };
            default:
                return state;
        }
    }
    /************************************************************************************
     * Registered OTP POST request reducer.
     ************************************************************************************/
    static post_registered_mobile_otp_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_MOBILE_OTP:
                let retValue = RegistrationService.applyOTP(state, action);
                return retValue;
            case REQUEST_POST_MOBILE_OTP:
                return { ...state, OTPSuccess: null, isSendOTPButtonDisabled: true };
            default:
                return state;
        }
    }
    /************************************************************************************
     * Registration Details POST request reducer.
     ************************************************************************************/
    static post_registered_details_reducer = (state = [], action) => {
        switch (action.type) {
            case RECEIVE_POST_REGISTRATION:
                let retValue = RegistrationService.applyRegistration(state, action);
                return retValue;
            case REQUEST_POST_REGISTRATION:
                return { ...state, registrationSuccess: null, sendRegistrationButtonDisabled: true };
            default:
                return state;
        }
    }
    /************************************************************************************
     * Helper function for reducer post_registered_mobile_number_reducer
     ***********************************************************************************/
    static applyRegisteredMobile = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                registeredMobileNumberSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { registeredMobileNumberSuccess: true, isGetOTPButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                registeredMobileNumberSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { registeredMobileNumberSuccess: false, isGetOTPButtonDisabled: false });
        }
    }

    /************************************************************************************
     * Helper function for reducer post_registered_mobile_number_reducer
     ***********************************************************************************/
    static applyOTP = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                mobileNumberOTPSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { mobileNumberOTPSuccess: true, isSendOTPButtonDisabled: false,customerData:action.response.data[0].customer_data });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                mobileNumberOTPSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { mobileNumberOTPSuccess: false, isSendOTPButtonDisabled: false,customerData:"" });
        }
    }
    /************************************************************************************
     * Helper function for reducer post_registration_reducer
     ***********************************************************************************/
    static applyRegistration = (state, action) => {
        if (action.success) {
            let returnObjectSuccess = {
                registrationSuccess: true,
            };
            return Object.assign({}, returnObjectSuccess, { registrationSuccess: true, isSendRegistrationButtonDisabled: false });
        }
        else {
            let genericError = 'Error, Please try again after some time';
            let returnObjectError = {
                registrationSuccess: false,
                errorMessage: action.error.data ? (action.error.data.message ? action.error.data.message : genericError) : genericError,
            };
            return Object.assign({}, returnObjectError, { registrationSuccess: false, isSendRegistrationButtonDisabled: false });
        }
    }
}


export default RegistrationService;