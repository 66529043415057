/**
* Scroll to Top (only On Mount) component.
* Applied on Product Detail Page Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import { Component } from 'react';

/************************************************************************************************************************ 
 * This component which is used in Product Detail Page Component, enables
 * scrolling to the top of view only on mounting.
 * If an anchor link in the page is clicked, this component prevents
 * going to the top of the page.
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md  
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 ************************************************************************************************************************/
class ScrollToTopOnMount extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

export default ScrollToTopOnMount;