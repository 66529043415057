/**
* SignInForm Component.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Sameena Shaik <shaik.sameena@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import WishlistService from '../../../app/modules/wishlist/WishlistService';
import { setItemInLocalStorage } from '../../../app/framework/storage/local/localStorageHandler';
const styles = theme => ({
});

/***************************************************************
 * Component class for displaying Sign In Form.
 * On submit user can able to sign into respective account
 * @author   Sameena Shaik <shaik.sameena@embitel.com>
 ***************************************************************/
class WishlistButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavorite: false,
            currentWishlistItem: ''
        }
    }

    handleFavorite = (isFavorite, sku, delateIndex) => {
        if (!isFavorite) {
            if (localStorage.getItem('shopce-user')) {
                //dispatcher to wishlist a product
                this.props.wishlistProduct(sku);
            }
            else {
                //Store product sku in the local storage
                setItemInLocalStorage('fav-product', sku);
                //redirect to login 
                this.props.history.push({
                    pathname: '/account',
                    state: { redirectTo: this.props.location.pathname }
                })
            }
        }
        else {
            const { id } = this.props.wishlistedProducts.items[delateIndex];
            this.props.deleteWishlistedProduct(id);
        }
    }
    render() {
        let { sku } = this.props;
        let { isFavButtonDisabled, wishlistedProducts } = this.props;

        const matchsku = wishlistedProducts && wishlistedProducts.items ? wishlistedProducts.items.findIndex(item => item.product.sku === sku) : -1;
        const isFavorite = matchsku >= 0;
        return (
            <React.Fragment>
                <IconButton disabled={isFavButtonDisabled} aria-label='Favorite'
                    onClick={() => this.handleFavorite(isFavorite, sku, matchsku)} size='large'>
                    {isFavorite ? <Favorite color='secondary' fontSize='small' />
                        : <FavoriteBorder color='secondary' fontSize='small' />}
                </IconButton>
            </React.Fragment>
        )
    }
    componentWillUnmount() {
        // this.props.resetPutWishlistInfo();
        // this.props.resetDeleteWishlistInfo();
    }
}

const mapStateToProps = state => ({
    wishlistedProducts: state.get_wishlisted_products,
    isFavButtonDisabled: state.put_product_in_wishlist.isFavButtonDisabled ?
        state.put_product_in_wishlist.isFavButtonDisabled :
        (state.delete_product_in_wishlist.isFavButtonDisabled ? state.delete_product_in_wishlist.isFavButtonDisabled : false),
    wishlistSuccess: state.put_product_in_wishlist.wishlistSuccess,
    item_id: state.put_product_in_wishlist.item_id,
    wishlistError: state.put_product_in_wishlist.errorMessage ? state.put_product_in_wishlist.errorMessage : '',
    isWishlistedItemDeleted: state.delete_product_in_wishlist.deleteWishlistItemSuccess,
    deleteWishlistError: state.delete_product_in_wishlist.errorMessage ?
        state.delete_product_in_wishlist.errorMessage : '',
});

const mapDispatchToProps = dispatch => ({
    wishlistProduct: (sku) => {
        let wishlistServiceObject = new WishlistService();
        // dispatch(wishlistServiceObject.ResetDeleteWishlistActionCreator());
        dispatch(wishlistServiceObject.WishlistProductActionCreator(sku));
    },
    deleteWishlistedProduct: (id) => {
        let wishlistServiceObject = new WishlistService();
        // dispatch(wishlistServiceObject.ResetPutWishlistActionCreator());
        dispatch(wishlistServiceObject.DeleteWishlistProductActionCreator(id));
    },
    resetPutWishlistInfo: () => {
        let wishlistServiceObject = new WishlistService();
        dispatch(wishlistServiceObject.ResetPutWishlistActionCreator());
    },
    resetDeleteWishlistInfo: () => {
        let wishlistServiceObject = new WishlistService();
        dispatch(wishlistServiceObject.ResetDeleteWishlistActionCreator());
    }

});

const WishlistButtonWithStyles = withStyles(styles)(WishlistButton);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WishlistButtonWithStyles));