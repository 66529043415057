/**
* Component for Product meta information.
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Ruchi Verma <ruchi.verma@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { decodeHTMLEntities } from '../../../../app/framework/common/helpers';
import UnitSelector from '../../uom/UnitSelector';
import PriceDisplay from '../../container/PriceDisplay';
import WishlistButton from '../../wishlist/WishlistButton';
import RequisitionButton from '../../requisition/RequisitionButton';
import GetLanguage from '../../../../library/GetLanguage';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        maxWidth: 500,
    },
    s_priceStyle: {
        fontWeight: 'bold',
        color: '#1d2d5f',
        marginTop:'15px',
        '& p':{
            fontSize: '16px'
        }
    },
    delStyle: {
        color: '#808080',
    },
    normal_price: {
        color: '#1979c3',
    },
    titleText: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#1d2d5f',
        lineHeight: '14px',
        textTransform: 'uppercase',
    },
    stockStyle: {

    },
    outOfStockStyle: {
        backgroundColor: '#F73455',
        borderRadius: '20px',
    },
    buttonText: {
        color: '#072D77 !important',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '0.3rem'
    }
});

/***************************************************************
 * Class component for displaying product meta information on 
 * product detail page
 * @author    Ruchi Verma <ruchi.verma@embitel.com>
 **************************************************************/
class ProductMetaInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {

        let { classes } = this.props;
        let { name, sku, is_in_stock } = this.props;
        let { productDetail } = this.props;
        let custom_attributes = productDetail.custom_attributes;
        var conversion_units = '';
        custom_attributes.map((item) => {
            if (item.attribute_code === 'conversion_units') {
                conversion_units = item.value;
            }
        })
        let price;
        let currentPriceGet = productDetail.currentVariant;
        if (currentPriceGet && productDetail.simple_products) {
            productDetail.simple_products.map(product => {
                if (Object.keys(product.custom_options.variant).includes(currentPriceGet)) {
                    price = product.price;
                    return price;
                }
            })
        } else {
            price = productDetail.price || productDetail.custom_price;
        }

        return (
            <React.Fragment>
                <Grid container direction="row" justify='space-between'>
                    <Grid item xs={10}>
                        <Typography variant="caption" className={classes.titleText} >
                            {decodeHTMLEntities(name)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid>
                            {is_in_stock ? <RequisitionButton plp={true} sku={sku} /> : ''}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='column' >
                    <Grid item xs={12} >
                        <Grid container direction='row' justify='space-between' alignItems='flex-end' style={{ marginTop: '10px' }}>
                            <Grid item>
                                <Grid item className={is_in_stock ? classes.stockStyle : classes.outOfStockStyle}>
                                    <IconButton className={classes.buttonText} disabled>
                                        {is_in_stock ?
                                           '' : <Cancel color='error' />}
                                        {is_in_stock ? <GetLanguage value="IN STOCK" /> : <GetLanguage value='OUT OF STOCK' />}
                                    </IconButton>
                                </Grid>
                                <Grid item className={classes.s_priceStyle}>
                                     <PriceDisplay productDetailPage={true} productDetail={productDetail} />
                                </Grid>
                                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                    SKU: {decodeHTMLEntities(sku.replace(/^0+/, ''))}
                                </Typography>
                                {
                                    localStorage.getItem('shopce-user') && typeof productDetail.soq !== 'undefined' &&
                                    <Grid item>
                                        <Typography style={styles.soqStyle}>SOQ: {productDetail.soq !== 'NA' ? productDetail.soq + ' Cases' : productDetail.soq}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                            {this.props.variantSelected && this.props.variantSelected[productDetail.sku] && this.props.variantSelected[productDetail.sku].selectedUnit === 1 &&
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        {<GetLanguage value="1 case = " />}
                                        {productDetail.conversion_factor}
                                        {<GetLanguage value="units" />}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {conversion_units !== '' ?
                        <Grid item xs={12}>
                            <Typography style={styles.uomStyle}>1 CS = {conversion_units} PC</Typography>
                        </Grid>
                        : ''
                    }
                </Grid>
            </React.Fragment>
        )
    }

}

ProductMetaInformation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    item_id: state.put_product_in_wishlist.item_id,
    priceOfSelectedVariant: state.pdpPriceState,
    variantSelected: state.variant_selected
});

const mapDispatchToProps = dispatch => ({

});

const ProductMetaInformationWithStyles = withStyles(styles)(ProductMetaInformation);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductMetaInformationWithStyles));