/**
* Template Configuration Usage for Pages.  
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Headerbar from '../../../../app/components/layouts/headerbar/HeaderBar';
import Footerbar from '../../../../app/components/layouts/footerbar/FooterBar';
import NavigationMenuDesktop from '../../../../app/components/navigation/navigationdesktop/NavigationDesktop';


import getTemplateComponent from './TemplateConfig';

const styles = theme => ({
    leftSideBar: {
        width: '20%',
        height: 200,
        background: '#aaa',
        float: 'left',
    },
    mainContentRight: {
        width: '80%',
        height: 200,
        background: '#bbb',
        float: 'right',
    },
});

/************************************************************** 
 * This Component shows the usuage of template.   
 * Fetches the template component with the usage of function
 * getTemplateComponent() and uses the template component to 
 * render the Page. The required props are passed to the Template
 * Component.
 * Renders the page blocks passed as an array. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>   
 *************************************************************/
class TemplateConfigUsage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            TemplateComponent: null,
        }
    }

    // Get the required template component on loading of the component, 
    // passing the template name (string).
    componentDidMount() {
        var template = getTemplateComponent("two_columns_leftbar");
        this.setState({ TemplateComponent: template });
    }

    render() {
        const { classes } = this.props;
        const { TemplateComponent } = this.state;
        const LeftSideBarComponent = props => (
            <div className={classes.leftSideBar}>
            </div>
        );

        const RightMainComponent = props => (
            <div className={classes.mainContentRight}>
            </div>
        );

        if (!TemplateComponent)
            return null;

        return (
            <Fragment>
                <TemplateComponent
                    HeaderComponent={Headerbar}
                    NavigationComponent={NavigationMenuDesktop}
                    leftSideBarComponent={[LeftSideBarComponent]}
                    mainContent={[RightMainComponent]}
                    FooterComponent={Footerbar}
                />
            </Fragment>
        );
    }
}

TemplateConfigUsage.propTypes = {

};

export default withStyles(styles)(TemplateConfigUsage);