/**
* Template Configuration for Pages of the App.
* 
*
* NOTICE: All information contained herein is, and remains
* the property of Embitel Technologies (I) Pvt. Ltd.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Embitel Technologies (I) Pvt. Ltd.
*
* @category  Embitel
* @package   Embitel\Core
* @author    Yaramala Reddy <yaramala.reddy@embitel.com>
* @copyright 2018-2019 Embitel technologies (I) Pvt. Ltd. All rights reserved.
*/

import React from 'react';

import Empty from './Empty';
import Global from './Global';
import TwoColumnsLeftBar from './2ColumnsLeftBar';
import TwoColumnsRightBar from './2ColumnsRightBar';
import ThreeColumns from './3Columns';
import WithHeaderAndFooter from './WithHeaderAndFooter';


/***************************************************************************** 
 * Template type (string) and corresponding Template Component Type Pairs.
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
let TemplateSelector = [
    {        
        "templateType" : "empty",
        "templateComponent": Empty,
    },
    {        
        "templateType" : "global",
        "templateComponent": Global,
    },
    {        
        "templateType" : "two_columns_leftbar",
        "templateComponent": TwoColumnsLeftBar,
    },
    {        
        "templateType" : "two_columns_rightbar",
        "templateComponent": TwoColumnsRightBar,
    },   
    {        
        "templateType" : "three_columns",
        "templateComponent": ThreeColumns,
    }, 
    {        
        "templateType" : "with_header_footer",
        "templateComponent": WithHeaderAndFooter,
    },       
];


/***************************************************************************** 
 * Function that loops through the "templateType" of the page 
 * and "templateComponent" pairs and returns the matching template componenet. 
 * @author    Yaramala Reddy <yaramala.reddy@embitel.com>
 *****************************************************************************/
function getTemplateComponent(templateType) {    
    for (let i = 0; i < TemplateSelector.length; i++) {
        if (TemplateSelector[i].templateType === templateType) {
            return TemplateSelector[i].templateComponent;
        }
        else {
            continue;
        }
    }
    //If no template matches, use the default "Global" template.
    return <Global />;
}

// Export the function that selects the Template Component for  a given template type.
export default getTemplateComponent;

